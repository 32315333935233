import { useEffect, useState } from 'react';
// import ReactVisibilitySensor from "react-visibility-sensor";

//LIBS
import { Img } from 'react-image';

//CONFIG
import config from 'utils/constants';

//HOOKS
import useSafeState from 'hooks/useSafeState';

function Image(props) {
    let loader = null;
    const [isLink, setIsLink] = useState(false);
    if (props.loader) loader = <Img src={`${config.serverUrl + props.loader}`} className={`grayscale ${props.className}`} />;
    const [currentSrc, setSrc] = useSafeState(null);

    useEffect(() => {
        if (props.src) setSrc(props.src);
        //chek if currentSrc contain https://
        //eslint-disable-next-line
    }, [props.src]);

    useEffect(() => {
        return () => {
            setSrc(null);
        };
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (currentSrc && currentSrc.indexOf('https://') !== -1) {
            setIsLink(true);
        }
    }, [currentSrc]);

    if (currentSrc === null && loader === null) return null;

    return (
        // <ReactVisibilitySensor>
        <>
            { isLink ? (
                <img className={props.className} src={currentSrc} alt={props.alt} />
            ) : props.dynamic ? (
                <img className={props.className} src={`${!isLink && config.serverUrl + currentSrc}`} alt={props.alt} />
            ) : (
                <Img {...props} loader={loader} src={`${config.serverUrl + currentSrc}`} alt={props.alt} />
            )}
        </>
        // </ReactVisibilitySensor>
    );
}

export default Image;
