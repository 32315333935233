import API from './axios.config';
import authHeader from 'services/Auth.Header';

async function postReport(data) {
    const response = await API.post('reports/create', data, {
        headers: authHeader(),
    });
    return response;
}

export { postReport };
