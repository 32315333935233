import { useImperativeHandle, forwardRef } from "react";
//LIBS
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
//API
import { postService } from "api/services";
//COMPONENTS
import FieldInput from "components/forms/fields/input";
//FORMS
import { simple } from "components/forms/yup/service";

function FormAddService(props, ref) {
    //HOOKS
    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(simple),
    });

    useImperativeHandle(ref, () => ({
        handleSubmit: (data) => {
            handleSubmit(onSubmit)();
        },
    }));

    //FUNCTIONS
    const onSubmit = (data) => {
        if (errors.length > 0) return false;

        const post = postService(data);
        post.then((res) => {
            if (res.status === 200) {
                //toast("Service créé", { type: "success" });
                // console.log(res.data);
                navigate("/dashboard/services/" + res.data.id);
            } else {
                toast("Une erreur est survenue", { type: "warning" });
            }
        });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="form" autoComplete="off" encType="multipart/form-data">
            <div className="flex w-full gap-0">
                <FieldInput name="title" label="Quel est le nom de votre service / produit ?" errors={errors} register={register} />
            </div>
        </form>
    );
}

export default forwardRef(FormAddService);
