//COMPONENTS
import FieldPlace from 'components/forms/fields/Places';
import { useEffect, useState } from 'react';
import {isMobile} from 'utils/functions';

function SearchFilterPlace(props) {
    const [placeholder, setPlaceholder] = useState("Où ? Ville, CP, région, adresse, ...");
    useEffect(() => {
        if (props?.isMainSearch === true && isMobile() === true) {
            setPlaceholder("Où ?");
        }
        //eslint-disable-next-line
    }, []);

    return (
        <div className="w-full mainSearchBar">
            <FieldPlace
                defaultValue={props?.defaultValue}
                placeholder={placeholder}
                isJobsPage={props?.isJobsPage}
                onChange={(latlng, value) => {
                    props.refetch(latlng, value);
                }}
            />
        </div>
    );
}

export default SearchFilterPlace;
