function LaughIcon(props) {
    return (
        <svg id="emoji" viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg">
            <g id="color">
                <circle cx="36" cy="36" r="24" fill="#fcea2b"/>
                <path fill="#fff" d="M50.595,41.64a11.5554,11.5554,0,0,1-.87,4.49c-12.49,3.03-25.43.34-27.49-.13a11.4347,11.4347,0,0,1-.83-4.36h.11s14.8,3.59,28.89.07Z"/>
                <path fill="#ea5a47" d="M49.7251,46.13c-1.79,4.27-6.35,7.23-13.69,7.23-7.41,0-12.03-3.03-13.8-7.36C24.2951,46.47,37.235,49.16,49.7251,46.13Z"/>
                <path fill="#92d3f5" d="M65.7563,47.3378a4.2418,4.2418,0,0,1-6.001.001c-2.9718-2.9717-3.0059-8.961-3.0063-9.0208.2314.0131,6.0508.0625,9.0073,3.019a4.2411,4.2411,0,0,1,0,6.0008Z"/>
                <path fill="#92d3f5" d="M6.2392,47.3385a4.242,4.242,0,0,1-.0011-6.0011c2.9717-2.9717,8.9611-3.0059,9.0208-3.0063-.0131.2314-.0625,6.0509-3.019,9.0074a4.2412,4.2412,0,0,1-6.0007,0Z"/>
            </g>
            <g id="hair"/>
            <g id="skin"/>
            <g id="skin-shadow"/>
            <g id="line">
                <path fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M58.8479,33.3406q-.01-.0839-.02-.1675A23.0031,23.0031,0,0,0,13.2041,32.92c-.0148.1069-.0386.3127-.052.42"/>
                <path fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17.1832,49.229A23.5314,23.5314,0,0,0,36,59a23.3428,23.3428,0,0,0,18.7572-9.6871"/>
                <path fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20.6948,28.2a6.3062,6.3062,0,0,1,3.3907-3,6.3038,6.3038,0,0,1,4.53-.4209"/>
                <path fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M51.3052,28.2A7.19,7.19,0,0,0,43.396,24.77"/>
                <path fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M50.595,41.64a11.5554,11.5554,0,0,1-.87,4.49c-12.49,3.03-25.43.34-27.49-.13a11.4347,11.4347,0,0,1-.83-4.36h.11s14.8,3.59,28.89.07Z"/>
                <path fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M49.7251,46.13c-1.79,4.27-6.35,7.23-13.69,7.23-7.41,0-12.03-3.03-13.8-7.36C24.2951,46.47,37.235,49.16,49.7251,46.13Z"/>
                <path fill="none" stroke="#000" strokeLinejoin="round" strokeWidth="2" d="M65.7563,47.3378a4.2418,4.2418,0,0,1-6.001.001c-2.9718-2.9717-3.0059-8.961-3.0063-9.0208.2314.0131,6.0508.0625,9.0073,3.019a4.2411,4.2411,0,0,1,0,6.0008Z"/>
                <path fill="none" stroke="#000" strokeLinejoin="round" strokeWidth="2" d="M6.2392,47.3385a4.242,4.242,0,0,1-.0011-6.0011c2.9717-2.9717,8.9611-3.0059,9.0208-3.0063-.0131.2314-.0625,6.0509-3.019,9.0074a4.2412,4.2412,0,0,1-6.0007,0Z"/>
                <path fill="none" stroke="#000" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2" d="M31.6941,35.1642a4.7263,4.7263,0,0,0-8.6382,0"/>
                <path fill="none" stroke="#000" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2" d="M48.9441,35.1642a4.7263,4.7263,0,0,0-8.6382,0"/>
            </g>
        </svg>
    );
}

export default LaughIcon;