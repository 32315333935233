import { useContext, useEffect, useState } from 'react';

//LIBS
import _ from 'lodash';

//API
import { getUsersByListIds } from 'api/counters';

//CONTETXS
import { AuthContext } from 'context/authContext';

export default function useHasCounter(counters, type, to) {
    const [authState] = useContext(AuthContext);
    const [hasCounter, setHasCounter] = useState(false);

    useEffect(() => {
        if (counters) {
            let has = false;
            for (let i = 0; i < counters.length; i++) {
                if (counters[i].type === type) {
                    if (
                        (counters[i].to === to && counters[i].user?.id === authState?.me?.id) ||
                        (counters[i].to === to && counters[i].user === authState?.me?.id)
                    ) {
                        has = true;
                        break;
                    }
                }
            }
            setHasCounter(has);
        }
        //eslint-disable-next-line
    }, [authState.isLogged, authState.me, counters]);

    return hasCounter;
}

export function useGetReactions(counters, target, targetType, type) {
    let tmpCounters = [];
    const reactions = {
        reactionsNb: 0,
        like: [],
        likeMuch: [],
        mdr: [],
        interesting: [],
        dislike: [],
    };

    if (counters?.length > 0) {
        for (let i = 0; i < counters.length; i++) {
            if (counters[i]?.length > 0) {
                for (let j = 0; j < counters[i].length; j++) {
                    if (counters[i][j]?.length > 0) {
                        for (let k = 0; k < counters[i][j].length; k++) {
                            tmpCounters.push(counters[i][j][k]);
                        }
                    } else {
                        tmpCounters.push(counters[i][j]);
                    }
                }
            } else {
                tmpCounters.push(counters[i]);
            }
        }
    }
    if (tmpCounters?.length > 0) {
        for (let i = 0; i < tmpCounters.length; i++) {
            if (tmpCounters[i] !== null) {
                if (tmpCounters[i].type === type) {
                    if (tmpCounters[i].to === target) {
                        switch (tmpCounters[i].value) {
                            case 'like':
                                reactions.like.push(tmpCounters[i]);
                                reactions.reactionsNb++;
                                break;
                            case 'likeMuch':
                                reactions.likeMuch.push(tmpCounters[i]);
                                reactions.reactionsNb++;
                                break;
                            case 'mdr':
                                reactions.mdr.push(tmpCounters[i]);
                                reactions.reactionsNb++;
                                break;
                            case 'interesting':
                                reactions.interesting.push(tmpCounters[i]);
                                reactions.reactionsNb++;
                                break;
                            case 'dislike':
                                reactions.dislike.push(tmpCounters[i]);
                                reactions.reactionsNb++;
                                break;
                            default:
                                break;
                        }
                    }
                }
            }
        }
    }
    return reactions;
}

export function useGetTop3Reactions(counters, target, targetType, type) {
    const reactions = useGetReactions(counters, target, targetType, type);
    const reactionIcons = {
        type: [],
        value: [],
        total: reactions.reactionsNb,
    };

    for (var reaction in reactions) {
        if (reaction !== 'reactionsNb') {
            if (reactions[reaction].length > 0) {
                reactionIcons.value.push(reactions[reaction]);
            }
        }
    }

    reactionIcons.value.sort((a, b) => {
        return b.length - a.length;
    });

    //get top3 icons from reaction
    for (let i = 0; i < reactionIcons.value.length; i++) {
        if (i === 3) break;
        if (reactionIcons.value[i].length > 0) {
            reactionIcons.type.push(reactionIcons.value[i][0].value);
        }
    }

    return reactionIcons;
}

export const useGetListCounter = (counters, type, to) => {
    const [authState] = useContext(AuthContext);
    const [users, setUsers] = useState([]);

    useEffect(() => {
        if (authState.isLogged) {
            const userIds = _.filter(counters, { type, to });
            getUsersByListIds({ userIds }).then((data) => {
                setUsers(data.users);
            });
        }
        //eslint-disable-next-line
    }, [authState.isLogged, authState.me, counters]);

    return users;
};

export function useHasCurrentReaction(counters = [], type, to) {
    const [counterDetails, setCounterDetails] = useState(null);
    const [authState] = useContext(AuthContext);

    useEffect(() => {
        const foundCounter = counters.find(
            (counter) => counter.type === type && counter.to === to && (counter.user?.id === authState?.me?.id || counter.user === authState?.me?.id)
        );

        setCounterDetails(foundCounter || null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [counters, authState?.me?.id]);

    return counterDetails;
}
