import { useContext, useEffect } from 'react';

//CONFIG
import config from 'utils/constants';
//CONTEXTS
import { AuthContext } from 'context/authContext';

//STYLES
import 'components/forms/forms.css';
import Avatar from '../../../containers/profils/Avatar';

function FormProfilStepNext(props) {
    //CONTEXTS
    const [authState, , refresh] = useContext(AuthContext);

    //EFFECTS
    useEffect(() => {
        refresh();
        //eslint-disable-next-line
    }, []);

    return (
        <>
            <div className="flex flex-wrap lg:flex-nowrap justify-center">
                <div className="flex flex-col gap-6 w-full">
                    <div className="flex flex-col">
                        <span className="inline-block font-bold text-xl text-purpleSkills ml-4">Vous avez obtenu</span>
                        <span className="inline-block text-warning-400 text-2xl md:text-3xl font-bold"> Vos premiers points de réputation !</span>
                    </div>
                    <div className="flex justify-start w-full gap-8">
                        <div>
                            <div className="relative">
                                <Avatar className={'w-24'} user={authState?.me} gamification={false} onClick={() => {}} />
                                <div
                                    className="absolute flex items-center justify-center h-20 w-20 bg-center bg-cover -right-6 -bottom-4"
                                    style={{
                                        backgroundImage: `url(${config.publicDir}logo_gamification.png)`,
                                    }}
                                >
                                    <span className="text-lg font-bold text-white pt-1 pl-1">+{authState?.me?.gamification?.profile}</span>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col gap-3 sm:p-0 text-mainText">
                            <div>
                                <h2 className="font-bold text-xl">Gagnez en visibilité et en réputation</h2>
                                <p>Plus vous aidez les autres, plus vous gagnez des points.</p>
                            </div>
                        </div>
                    </div>
                    <div className="text-sm text-mainText mt-4">
                        <h2 className="font-bold text-lg">Comment gagner des points ?</h2>
                        <ul className="list-disc list-inside mt-2 ml-4">
                            <li>Complétez votre profil</li>
                            <li>Aidez les autres en répondant à leurs questions</li>
                            <li>Postez des shoots !</li>
                        </ul>
                    </div>
                    <div className="w-full flex justify-end">
                        <button
                            onClick={(e) => {
                                e.preventDefault();
                                props.setPage(props.page + 1);
                            }}
                            className="bg-blueMain rounded-full font-bold button-border-gray text-white hover:text-graySkills hover:bg-white justify-end w-auto px-8 py-2"
                        >
                            Compris
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default FormProfilStepNext;
