import { useRef, useEffect, useState, Fragment, useContext, useCallback } from 'react';

//LIBS
import _ from 'lodash';
import { /* Link, */ useLocation, useNavigate } from 'react-router-dom';
import { Swiper } from 'swiper/react';

//STYLES
import './tabsShoot.css';
import useSwipe from 'hooks/useSwipe';

//UTILS
import { isMobile } from 'utils/functions';
import { BellIcon, NewspaperIcon, SearchIcon, UserGroupIcon } from '@heroicons/react/outline';

import { AuthContext } from 'context/authContext';
import { StoreContext } from 'context/storeContext';
//HOOKS
import useNotifications from 'hooks/useNotifications';

//API
import { getNotifications } from 'api/discussions';
//import config from 'utils/constants';

function TabsShoot(props) {
    const refTabs = useRef(null);
    const swipeContainerRef = useRef(null);

    const [authState] = useContext(AuthContext);
    const store = useContext(StoreContext);
    const navigate = useNavigate();
    const discussionNotifications = useNotifications(getNotifications);

    const { direction } = useSwipe({ ref: swipeContainerRef, threshold: 90 });
    const location = useLocation();

    const [allTabs, setAllTabs] = useState(props.tabs);
    const [currentTab, setCurrentTab] = useState(props.initPosition ?? 0);

    function handleClick(e, tab) {
        e.stopPropagation();
        e.preventDefault();

        if (tab?.url) {
            window.history.replaceState(null, null, `${tab.url}`);
        }
        const items = refTabs.current.getElementsByClassName('Tabs_mobile_shoot_item');
        _.forEach(items, (item) => {
            item.classList.remove('active');
        });
        moveSlider(e.target.parentNode);

        if (typeof props?.refetchFav === 'function') {
            props?.refetchFav();
        }
    }

    function handleSwipe(direction) {
        const items = refTabs.current.getElementsByClassName('Tabs_mobile_shoot_item');
        const arrItems = Array.from(items);

        arrItems.forEach((item) => {
            if (item.classList.contains('active')) {
                setCurrentTab(arrItems.indexOf(item));
            }
        });

        if (currentTab > 0 && direction === 'right') {
            items[currentTab].classList.remove('active');
            moveSlider(items[currentTab - 1]);
            if (isMobile()) {
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
            }
        } else if (currentTab < items.length - 1 && direction === 'left') {
            items[currentTab].classList.remove('active');
            moveSlider(items[currentTab + 1]);
            if (isMobile()) {
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
            }
        }

        if (typeof props?.refetchFav === 'function') {
            props?.refetchFav();
        }
    }

    function moveSlider(elem) {
        elem.classList.add('active');
        const slider = getChildNodeIndex(elem);
        const width = parseInt(getComputedStyle(elem).width, 10);

        const sliderElem = refTabs.current.getElementsByClassName('Tabs_mobile_shoot_slider');

        sliderElem[0].style.left = slider.left + 'px';
        sliderElem[0].style.width = width + 'px';
    }

    function getChildNodeIndex(elem) {
        let left = 0;
        let position = 0;
        while ((elem = elem.previousSibling) != null) {
            if (elem.nodeType !== Node.TEXT_NODE) {
                const style = getComputedStyle(elem);

                left += parseFloat(style.width, 10);
                position++;
            }
        }

        setCurrentTab(position);
        return { position, left };
    }

    function init(tabPosition) {
        const items = refTabs.current.getElementsByClassName('Tabs_mobile_shoot_item');

        _.forEach(items, (item, k) => {
            if (k === tabPosition) {
                const timeout = setTimeout(() => {
                    moveSlider(item);
                    clearTimeout(timeout);
                }, 300);
            }
            /* {direction ? `${direction}` : 'Swipe'} */
        });
    }

    useEffect(() => {
        if (props.tabs) {
            _.remove(props.tabs, (n) => {
                return n.visible === false;
            });
            setAllTabs(props.tabs);
        }
        //eslint-disable-next-line
    }, [props.tabs]);

    useEffect(() => {
        if (allTabs.length !== props.tabs.length) init(props.initPosition ?? 0);
        //eslint-disable-next-line
    }, [allTabs]);

    useEffect(() => {
        init(props.initPosition ?? 0);
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        setCurrentTab(0);
        init(props.initPosition ?? 0);
        //eslint-disable-next-line
    }, [location.key]);

    useEffect(() => {
        if (direction) handleSwipe(direction);
        //eslint-disable-next-line
    }, [direction]);

    const handleGoToNotifications = useCallback(() => {
        navigate('/dashboard/notifications');
    }, [navigate]);

    const handleGoToHome = useCallback(() => {
        navigate(isMobile() ? '/home' : '/');
        //eslint-disable-next-line
    }, [navigate]);

    const handleGoToForum = useCallback(() => {
        navigate('/forum');
    }, [navigate]);

    const handleGoToSearch = useCallback(() => {
        navigate('/search');
    }, [navigate]);

    //const absoluteTabs = `w-[50%] left-[25%] absolute transition-all duration-300 ease-out top-[0px]`;

    return (
        <>
            {/* <div className="asbolute top-0 left-0 right-0 flex flex-row text-center h-auto p-2"> */}
            {/* <div className={`block z-20`}>
                        <Link to={isMobile() ? '/home' : '/'}>
                            <img src={`${config.publicDir}logo_blanc.png`} alt="logo" className="h-8 w-auto" />
                        </Link>
                    </div> */}

            <div className="relative w-full h-auto md:mx-auto bg-transparent z-10" ref={refTabs}>
                {!isMobile() ? (
                    <ul className="Tabs_mobile_shoot text-sm sm:flex overflow-x-auto scrollbar-hide" /* ref={refTabs} */>
                        {allTabs
                            ?.filter((i) => i.visible !== false)
                            ?.map((tab, k) => (
                                <Fragment key={k}>
                                    {tab.visible !== false && (
                                        <li className="Tabs_mobile_shoot_item">
                                            <a className="relative" href={`#${tab.key}`} onClick={(e) => handleClick(e, tab)}>
                                                {tab.label}
                                            </a>
                                        </li>
                                    )}
                                </Fragment>
                            ))}
                        <li className="Tabs_mobile_shoot_slider" role="presentation"></li>
                    </ul>
                ) : (
                    <>
                        <div className="absolute top-0 left-1/2 transform -translate-x-1/2 mt-3 flex space-x-6">
                            <button onClick={handleGoToHome} className="text-white flex flex-col items-center">
                                <NewspaperIcon className="w-7 h-7" />
                            </button>

                            <button onClick={handleGoToForum} className="text-white flex flex-col items-center">
                                <UserGroupIcon className="w-7 h-7" />
                            </button>

                            <button onClick={handleGoToSearch} className="text-white flex flex-col items-center">
                                <SearchIcon className="w-7 h-7" />
                            </button>
                        </div>

                        {authState.isLogged && (
                            <button
                                className="absolute top-0 right-0 mt-3 mr-4 text-white flex flex-col items-center"
                                id="button-notifications"
                                onClick={handleGoToNotifications}
                            >
                                <BellIcon className="w-7 h-7" />
                                {authState.isLogged && (store.get('notifications')?.length > 0 || discussionNotifications) && (
                                    <div className="absolute -top-2 -right-2 text-xs font-semibold text-white bg-red rounded-full h-5 w-5 flex items-center justify-center">
                                        {discussionNotifications + store.get('notifications').length}
                                    </div>
                                )}
                            </button>
                        )}
                    </>
                )}
            </div>
            {/*   </div> */}

            <div className="absolute top-0 left-0 w-full h-full" ref={swipeContainerRef}>
                {allTabs
                    ?.filter((i) => i.visible !== false)
                    ?.map((tab, k) => (
                        <Fragment key={k}>
                            {tab.visible !== false && tab.key !== 'tab_favoris' ? (
                                <>{k === currentTab && <div className="Tabs_mobile_shoot_content h-full">{tab.content}</div>}</>
                            ) : (
                                <>
                                    <Swiper
                                        style={{ height: '100%' }}
                                        direction="vertical"
                                        slidesPerView="1"
                                        mousewheel={true}
                                        pagination={{ clickable: true }}
                                        scrollbar={{ draggable: true }}
                                        autoHeight={true}
                                    >
                                        {tab.content}
                                    </Swiper>
                                </>
                            )}
                        </Fragment>
                    ))}
            </div>
        </>
    );
}

export default TabsShoot;
