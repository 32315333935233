import { useState, useEffect, useRef, useContext, useCallback } from 'react';
//CONFIG
import config from 'utils/constants';
//STYLES
import './ForumSearchBar.css';
//API
import { postForum } from 'api/forum';
import { getTags } from 'api/search';
//CONTEXTS
import { AuthContext } from 'context/authContext';
//LIBS
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
//FORMS
import schema from 'components/forms/yup/forum';
import FieldFiles from 'components/forms/fields/Files';
// import AddressOption from "components/forms/options/address/AddressOption";
//COMPONENTS
import Modal from 'components/common/Modal';
// import MainSearch from "components/common/mainSearch/MainSearch";
import FieldInput from 'components/forms/fields/input';
// import Tags from "components/forms/fields/Tags";
// import Textarea from "components/forms/fields/Textarea";
// import roomOptions from "components/forms/options/room";
import FormConnexion from 'components/forms/Connexion';

function ForumSearchBar(props) {
    const currentForm = useRef(null);
    //CONTEXTS
    const [authState] = useContext(AuthContext);
    const [openModal, setOpenModal] = useState(false);
    const [foundTags, setFoundTags] = useState([]);
    const [isQuestionValidate, setIsQuestionValidate] = useState(false);
    const [modalConfirmLabel, setModalConfirmLabel] = useState('Valider');

    //HOOKS
    const [isInscriptionOpen, setIsInscriptionOpen] = useState(false);
    const [isLogged, setIsLogged] = useState(false);

    //REFS
    const loginRef = useRef(null);

    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        setValue,
        // getValues,
        reset,
    } = useForm({
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            handleChange({ name, value: value[name] });
        });
        return () => subscription.unsubscribe();
        //eslint-disable-next-line
    }, [authState.isLogged, watch]);

    const getSuggestedTags = useCallback(
        async (value) => {
            getTags(value).then((res) => {
                setFoundTags(res);
            });
        },
        //eslint-disable-next-line
        []
    );

    function handleFindTags(value) {
        if (value && value.length >= 3) {
            getSuggestedTags(value);
        } else {
            setFoundTags([]);
        }
    }

    function handleAddTag(tag) {
        let newTags = [];

        var res = {};
        foundTags.forEach(function (v) {
            res[v.selected] = (res[v.selected] || 0) + 1;
        });

        foundTags.forEach((t) => {
            if (tag.name === t.name) {
                if (res.false === foundTags.length || res.true < 3) tag.selected = !t.selected;
                else tag.selected = false;
                newTags.push(tag);
            } else {
                newTags.push(t);
            }
        });
        setFoundTags(newTags);
        // setFoundTags(foundTags.filter((t) => t === tag));
    }
    useEffect(() => {
        setValue(
            'tags',
            foundTags.filter((t) => t.selected === true)
        );
        // console.log("foundTags", foundTags);
        //eslint-disable-next-line
    }, [foundTags]);

    function handleChange(input) {
        if (input.name === 'forum') {
            // if (authState.isLogged) {
            setValue('question', input.value);
            setOpenModal(true);
            document.getElementById('input_question')?.focus();
            // } else {
            //     document.getElementById("button-login")?.click();
            // }
        }
        if (input.name === 'question') {
            handleFindTags(input.value);
        }
    }

    useEffect(() => {
        if (openModal) {
            setValue('tags', []);
            setValue('address', '');
        }
        //eslint-disable-next-line
    }, [openModal]);

    function handleSubmitOnClick() {
        currentForm.current.click();
    }

    const handleOpenInscription = useCallback((e) => {
        setIsInscriptionOpen(true);
        setIsLogged(false);
    }, []);

    const handleLogin = useCallback(async () => {
        const promiseLogin = new Promise((resolve, reject) => {
            loginRef.current.login();
            resolve('Success');
        });

        promiseLogin.then(setTimeout(async () => await handleSubmitOnClick(), 600)).catch((error) => {
            console.log('Error: ' + error);
        });
    }, []);
    function onLogged(status) {
        setIsLogged(status);
    }

    const handleOpenTypeQuestion = useCallback((e) => {
        setIsQuestionValidate(true);
    }, []);

    function onSubmit(data) {
        if (errors.length > 0) return false;

        setOpenModal(false);

        // for (var k in data) {
        //     if (data[k] !== undefined) {
        //         let item = null;
        //         item = data[k];
        //         if (k === "tags") data[k] = JSON.stringify(item);
        //     }
        // }
        const fd = new FormData();
        for (var k in data) {
            if (data[k] !== undefined) {
                let item = null;
                item = data[k];

                if (k === 'tags') {
                    item = JSON.stringify(data[k]);
                }
                if (k === 'medias') {
                    let files = data[k];
                    for (let i = 0; i < files.length; i++) {
                        fd.append(k, files[i]);
                    }
                } else fd.append(k, item);
            }
        }

        if (authState.isLogged) {
            const post = postForum(fd, authState.isLogged);
            post.then((res) => {
                if (res.status === 200) {
                    if (props.refetchRooms) props.refetchRooms();
                    toast('Vous avez créé une Room !', { type: 'success' });
                    reset();
                    setTimeout(async () => await navigate('room/' + res.data.slug), 1000);
                } else {
                    toast('Une erreur est survenue', { type: 'danger' });
                }
            });
        } else {
            handleOpenTypeQuestion();
        }
        // console.log(data);
    }

    //OPTIONS
    // function dispatchOptionsActions(action, name, value) {
    //     switch (action) {
    //         case "onChangeOptionAddress":
    //             return onChangeOptionAddress(name, value);
    //         default:
    //     }
    // }
    // function onChangeOptionAddress(name, value) {
    //     setValue(name, value);
    // }

    return (
        <>
            <Modal
                // title="Obtenez LA réponse à VOTRE question par des experts !"
                open={openModal}
                fixed={true}
                onConfirm={{
                    label: 'Créer un Salon',

                    onClick: () => {
                        if (!authState.isLogged) {
                            handleSubmitOnClick();
                        } else {
                            handleSubmitOnClick();
                        }
                    },
                }}
                onClose={{
                    onClick: () => {
                        setOpenModal(false);
                    },
                }}
            >
                <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex flex-col">
                        <p className="p-4 bg-blue-100 border-blue-300 rounded-xl text-blue-800 text-sm">
                            <span className="inline-block">Votre recherche sera envoyée au skillers compétents.</span>
                            {!authState.isLogged && <span className="inline-block">Soyez informé des réponses par email en vous créant un compte !</span>}
                        </p>

                        <FieldInput
                            onChange={handleFindTags}
                            id="input_question"
                            className="rounded-3xl p-6 inline-block"
                            name="question"
                            label={'Précisez bien votre besoin, pour des réponses de qualités'}
                            placeholder="Bien détailler votre besoin pour de meilleures réponses"
                            register={register}
                            errors={errors}
                        />

                        {/* <FieldInput className="rounded-3xl p-6" disabled={true} name="address" label="Limiter à une zone géographique" errors={errors} register={register} option={roomOptions.address} dispatchOptionsActions={dispatchOptionsActions} getValues={getValues} readyToWatch={readyToWatch} /> */}

                        {foundTags.length > 0 && (
                            <>
                                <label className="input_label px-2 mt-8" style={{ marginTop: 20 }}>
                                    Choisissez des tags dans la liste ci-dessous (3 max)
                                </label>
                                {errors && errors['tags'] && <span className={`mt-0 text-sm px-2 text-danger-400`}>{errors['tags'].message}</span>}
                                <div className="flex flex-wrap flex-row gap-1 mt-2 px-2 relative max-h-24 overflow-auto">
                                    {foundTags.map((tag, index) => (
                                        <button
                                            key={index}
                                            type="button"
                                            title={`${tag.name}`}
                                            className={`${tag.selected ? 'bg-secondary text-white' : 'bg-gray-100 text-blue-800'} rounded-lg p-1 text-sm`}
                                        >
                                            <span className="flex flex-row items-center px-1">
                                                <span onClick={() => handleAddTag(tag)}>
                                                    <>{tag.name}</>
                                                </span>
                                            </span>
                                        </button>
                                    ))}
                                </div>
                            </>
                        )}
                        {authState.isLogged && (
                            <div className="w-full">
                                <FieldFiles
                                    name="medias"
                                    label="Déposez ou cliquez ici pour uploader une image"
                                    type="multiple"
                                    multiple={true}
                                    register={register}
                                    initValue={[]}
                                    readyToWatch={true}
                                    onChange={(value) => {
                                        setValue('medias', value);
                                    }}
                                    accepts={['image/png', 'image/jpeg', 'image/jpg']}
                                />
                            </div>
                        )}

                        {authState.isLogged && (
                            <div className="flex items-center text-blue-800">
                                <div className="mr-1">
                                    <input
                                        type="checkbox"
                                        id="anonymous_question"
                                        name="anonymousQuestion"
                                        onChange={(value) => {
                                            setValue('anonymousQuestion', value.target.checked);
                                        }}
                                    />
                                </div>
                                <label htmlFor="anonymous_question">Question anonyme</label>
                            </div>
                        )}
                        {/*   {!authState.isLogged && (
                            <FieldInput id="input_email" className="rounded-3xl p-6 inline-block" name="email" label={"Adresse email:"} placeholder="Rentrer votre adresse email pour avoir une réponse à votre question" register={register} errors={errors} />
                        )} */}
                        {/* <label className="input_label px-2">Description facultative pour mieux aider la communauté</label> */}
                        {/* <textarea name="description" defaultValue={getValues("description")} className="input py-4 mx-2 w-auto" errors={errors} {...register("description")} rows="3" style={{ height: "auto" }}> */}
                        {/* </textarea> */}
                        {/* <Textarea toolbar="small" name="description" label="Description facultative pour mieux aider la communauté" errors={errors} register={register} onChange={(value) => setValue("description", value)} /> */}
                        {/* <AddressOption /> */}
                    </div>
                </form>
            </Modal>
            <Modal
                /*   title={`${!isLogged ? 'Connexion à SkillsMarket' : ''}`} */
                open={isInscriptionOpen}
                onClose={{
                    onClick: () => {
                        setIsInscriptionOpen(false);
                    },
                }}
                onConfirm={
                    !isLogged
                        ? {
                              label: modalConfirmLabel,
                              onClick: async () => {
                                  await handleLogin();
                              },
                          }
                        : false
                }
            >
                <FormConnexion
                    ref={loginRef}
                    onLogged={() => {
                        setIsInscriptionOpen(false);
                        setTimeout(async () => await handleSubmitOnClick(), 600);
                    }}
                    isLogged={(status) => onLogged(status)}
                    modalConfirmLabel={(label) => setModalConfirmLabel(label)}
                />
            </Modal>

            <Modal
                title={`Statut de publication`}
                open={isQuestionValidate}
                onClose={{
                    onClick: () => {
                        setIsQuestionValidate(false);
                    },
                }}
                onConfirm={{
                    label: 'Valider',
                    onClick: () => {
                        setIsQuestionValidate(false);
                        handleOpenInscription();
                    },
                }}
            >
                <div className="flex flex-col p-4 bg-blue-100 border-blue-300 rounded-xl text-blue-800 text-sm">
                    <div className="pb-2">
                        <p className="pb-5 font-bold">Votre pseudo est par défaut visible</p>
                        <p>Cochez ci-dessous si vous souhaitez anonymiser votre question:</p>
                    </div>
                    <div className="flex items-center text-blue-800">
                        <div className="mr-1">
                            <input
                                type="checkbox"
                                id="anonymous_question"
                                name="anonymousQuestion"
                                register={register}
                                onChange={(value) => {
                                    setValue('anonymousQuestion', value.target.checked);
                                }}
                            />
                        </div>
                        <label htmlFor="anonymous_question">Question anonyme</label>
                    </div>
                </div>
            </Modal>

            <div className="container-fluid py-32 md:py-48 px-2 -mx-4 bg-blueMain shadow-xl">
                <div className="container mx-auto px-4">
                    <img src={`${config.publicDir}logo_blanc.svg`} alt="logo" className="m-auto h-16 md:h-24 mb-10" />
                </div>
                <form className="forumSearchBar" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                    <div className="forumSearchBar__suggest w-full md:w-7/12 rounded-3xl">
                        <FieldInput
                            className="w-auto rounded-3xl px-6 shadow-xl"
                            name="forum"
                            label={false}
                            placeholder="Posez votre question"
                            register={register}
                            errors={errors}
                            option={{}}
                            onClickOption={(input) => {
                                handleChange({
                                    name: 'forum',
                                    value: input.target.value,
                                });
                            }}
                        />
                    </div>
                    <input ref={currentForm} type="submit" className="hidden" />
                </form>
                <div className="container mx-auto py-4 text-center text-white md:text-xl font-semibold">
                    <p className="font-bold">Obtenez des réponses par ds professionels</p>
                    <p className="font-bold">Gratuitement</p>
                </div>
            </div>
        </>
    );
}

export default ForumSearchBar;
