import React from 'react';

import { openShareWindow, isPromise } from './shareUtils';

const TwitterShareButton = ({ url, children, className, beforeOnClick, onShareWindowClose, windowWidth = 600, windowHeight = 400 }) => {
    const handleClick = async () => {
        if (beforeOnClick) {
            const result = beforeOnClick();
            if (isPromise(result)) {
                await result;
            }
        }

        const shareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=`;
        const newWindow = openShareWindow(shareUrl, { width: windowWidth, height: windowHeight }, onShareWindowClose);

        if (!newWindow) {
            alert("La fenêtre de partage n'a pas pu être ouverte.");
        }
    };

    return (
        <button onClick={handleClick} className={`twitter-share-button ${className}`}>
            {children}
        </button>
    );
};

export default TwitterShareButton;
