import { useState, useRef, useEffect, useContext, useCallback } from 'react';
//CONTEXTS
import { FormContext } from 'context/formContext';
import Modal from 'components/common/Modal';
//COMPONENTS
import FormAddService from 'components/forms/addService';

function AddService() {
    const [service, setService] = useState(false);
    //CONTEXTS
    const [formContextState, formContextDispatch] = useContext(FormContext);
    //REFS
    const formServiceRef = useRef(null);

    //DISPATCH
    const dispatchAction = useCallback(() => {
        switch (formContextState.action) {
            case 'add':
                setService(true);
                break;
            default:
        }
        /* console.log(formContextState.action); */
        // eslint-disable-next-line
    }, [formContextState]);

    useEffect(() => {
        dispatchAction();
        //eslint-disable-next-line
    }, [dispatchAction]);

    function handleSubmitService() {
        formServiceRef.current.handleSubmit();
    }

    return (
        <Modal
            title="Commençons par le titre"
            open={service}
            onClose={{
                onClick: () => {
                    setService(false);
                    formContextDispatch({ type: 'reset' });
                },
            }}
            onConfirm={{
                label: 'Suivant',
                onClick: () => {
                    setService(false);
                    formContextDispatch({ type: 'reset' });
                    handleSubmitService();
                },
            }}
        >
            <FormAddService ref={formServiceRef} />
        </Modal>
    );
}

export default AddService;
