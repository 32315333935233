//COMPONENTS
import Header from 'components/common/Header';
import { useParams } from 'react-router-dom';
import ListOptionsNotifications from 'containers/notifications/ListOptionsNotifications';

//LAYOUT
import DashboardLayout from 'pages/layouts/Dashboard';

//STYLES
import 'App.css';

//UTILS
import { PageContentFadingAnimation } from 'utils/animations';

function OptionsNotification(props) {
    const params = useParams();

    return (
        <DashboardLayout>
            <PageContentFadingAnimation duration={250}>
                <div className="App">
                    <Header>
                        <h1 className="h1 mx-4 sm:mx-0">Gérer les notifications</h1>
                    </Header>
                    <main>
                        <div className="mx-4 sm:mx-0 mb-14 mt-4 sm:mb-0">{!params.slug && <ListOptionsNotifications />}</div>
                    </main>
                </div>
            </PageContentFadingAnimation>
        </DashboardLayout>
    );
}

export default OptionsNotification;
