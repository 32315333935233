import { createPortal } from "react-dom";

function ProgressBar(props) {
    return createPortal(
        <>
            {props.progress > 0 && props.progress < 100 && (
                <div className="fixed bottom-0 left-0 w-screen h-6 text-xs bg-white z-10 shadow">
                    <div className="shadow w-full h-full bg-success-500 text-white font-bold px-4 flex justify-start items-center rounded-tr-xl rounded-br-xl" style={{ width: `${props.progress}%`, minWidth: "5%" }}>
                        {Math.ceil(props.progress)}%
                    </div>
                </div>
            )}
        </>,
        document.body
    );
}

export default ProgressBar;
