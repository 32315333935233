//import { useEffect, useState, useContext } from 'react';
import { useContext } from 'react';
//LIBS
import { useLocation } from 'react-router-dom';

//API
import { postCounter } from 'api/counters';

//CONTEXTS
import { AuthContext } from 'context/authContext';

//CONTAINERS
import OptionsMenu from 'components/common/OptionsMenu';

//ICONS
import { BellIcon, ArrowNarrowLeftIcon, HeartIcon } from '@heroicons/react/solid';
import { BellIcon as BellIconOutline, DotsVerticalIcon } from '@heroicons/react/outline';

//COMPONENTS
import Share from 'components/share/Share';
import useHasCounter from '../../hooks/useHasCounter';

function HeadRoomMobile(props) {
    /* const [subscribed, setSubscribed] = useState(false); */
    const isSubscribed = useHasCounter(props.room?.counters, 'subscribe_room', props.room?._id);

    const [authState] = useContext(AuthContext);
    const location = useLocation();

    const hasBookmark = useHasCounter(props.room?.counters, 'bookmark_job', props.room?.id ? props.room?.id : props.room?._id);

    const actions = {
        roomSubscription: () => {
            /*  console.log(props.room?.data?._id); */
            postCounter({ type: 'subscribe_room', to: props.room?._id }).then(() => {
                props.refetch();
            });
        },
    };

    function closeOptionsMenu(room) {
        const els = document.getElementsByClassName('optionsMenu');
        [].forEach.call(els, function (el) {
            if (el.id !== `optionsMenu_${room._id}`) {
                el.classList.add('hidden');
            }
        });
    }

    function handleOptionsMenu(room) {
        closeOptionsMenu(room);
        document.getElementById(`optionsMenu_${room._id}`).classList.toggle('hidden');
    }
    /*
    useEffect(() => {
        if (props.room.isSuccess && props.room.data) {
            if (props.room.data.counters) {
                setSubscribed(false);
                props.room.data.counters.map((counter) => {
                    if (counter.type === 'subscribe_room' && counter.user === authState.me?.id && authState.isLogged) {
                        setSubscribed(true);
                    }
                    return void 0;
                });
            }
        }

        //eslint-disable-next-line
    }, [props.room.data]); */
    function handleBookmark(jobId) {
        if (authState.isLogged) {
            postCounter({ type: 'bookmark_job', to: jobId }).then(() => {
                props.refetch();
            });
        } else document.getElementById('button-login')?.click();
    }

    return (
        <div className="sm:hidden -mx-4 sticky top-0 z-10 container-fluid bg-white sm:bg-blueMain">
            <div className="py-3 px-4 flex items-center justify-between">
                <div className="flex">
                    {props?.goBack && (
                        <button className="py-0 px-0 text-purpleSkills link_basic" onClick={props?.goBack}>
                            <ArrowNarrowLeftIcon className="w-6 h-6" />
                        </button>
                    )}
                </div>
                <div className="flex items-center gap-3">
                    {props.room?.type === 'job' && (
                        <div className="flex">
                            <button
                                onClick={() => {
                                    handleBookmark(props.room?._id);
                                }}
                            >
                                <HeartIcon className={`w-6 h-6 ${hasBookmark ? 'text-red' : 'text-graySkills'} sm:hover:text-blueMain`} />
                            </button>
                        </div>
                    )}
                    {props.room?.user !== null && (
                        <button
                            onClick={() => actions.roomSubscription()}
                            className={`flex flex-row items-center gap-1 text-purpleSkills ${isSubscribed ? 'active' : ''}`}
                        >
                            {isSubscribed ? (
                                <>
                                    <BellIcon className="h-6 w-6 p-0 text-blueMain" />
                                </>
                            ) : (
                                <>
                                    <BellIconOutline className="h-6 w-6 p-0" />
                                </>
                            )}
                        </button>
                    )}
                    <Share type="outline" url={location.pathname} />
                    <div className="relative flex">
                        <button onClick={() => handleOptionsMenu(props.room)}>
                            <DotsVerticalIcon className="w-6 h-6 text-purpleSkills" />
                        </button>
                        <OptionsMenu room={props.room} refresh={props.refetch} type="room" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HeadRoomMobile;
