import React, { createContext, useReducer } from 'react';

const CloneContext = createContext();

const initialState = {
    cloneCreationState: false,
    dialogueStates: {}, // Remplacer cloneDialogueState par dialogueStates
};

const CloneProvider = (props) => {

    const reducer = (state, action) => {
        switch (action.type) {
            case 'setCloneDialogueState':
                return {
                    ...state,
                    dialogueStates: {
                        ...state.dialogueStates,
                        [action.discussionId]: action.cloneDialogueState,
                    },
                };
            case 'setCloneCreationState':
                return { ...state, cloneCreationState: action.cloneCreationState };
            default:
                return state;
        }
    };

    const [cloneState, cloneDispatch] = useReducer(reducer, initialState);

    return (
        <CloneContext.Provider value={[cloneState, cloneDispatch]}>
            {props.children}
        </CloneContext.Provider>
    );
};

export { CloneContext, CloneProvider };