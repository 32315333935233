//COMPONENTS
import Header from 'components/common/Header';
import ListAskedVisios from 'containers/visios/ListAskedVisios';
import Tabs from 'components/tabs/Tabs';

//LAYOUT
import DashboardLayout from 'pages/layouts/Dashboard';

//LIBS
import { useParams } from 'react-router-dom';

//PAGES
import Visios from './Visios';

//STYLES
import 'App.css';

//UTILS
import { isMobile } from 'utils/functions';
import { PageContentFadingAnimation } from 'utils/animations';

function AskedVisios(props) {
    //HOOKS
    const params = useParams();

    const tabAskedVisios = (
        <PageContentFadingAnimation duration={250} className="App">
            <Header>
                <h1 className="h1 mx-4 sm:mx-0">Mes visio-consultations demandées</h1>
            </Header>
            <main>{!params.slug && <ListAskedVisios />}</main>
        </PageContentFadingAnimation>
    );
    
    const tabReceivedVisios = <Visios inTabs={true} />;

    const tabs = [];
    tabs['desktop'] = [
        { label: 'Visios reçues', key: 'tab_receivedVisios', content: tabReceivedVisios },
        { label: 'Visios demandées', key: 'tab_askedVisios', content: tabAskedVisios },
    ];

    tabs['mobile'] = [
        { label: 'Visios reçues', key: 'tab_receivedVisios', content: tabReceivedVisios },
        { label: 'Visios demandées', key: 'tab_askedVisios', content: tabAskedVisios },
    ];

    return <DashboardLayout>{isMobile() ? <Tabs tabs={tabs.mobile} /> : <Tabs tabs={tabs.desktop} />}</DashboardLayout>;
}

export default AskedVisios;