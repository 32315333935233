import { useEffect, useState, useMemo } from 'react';

//LIBS
import _ from 'lodash';
import { useForm } from 'react-hook-form';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';

//COMPONENTS
import FieldInput from 'components/forms/fields/input';
import FieldPlace from 'components/forms/fields/Places';

//ICONS
import { ArrowNarrowLeftIcon } from '@heroicons/react/solid';

function AddressOption(props) {
    const [focus, setFocus] = useState(false);
    const [map, setMap] = useState(null);
    const [lat, setLat] = useState(46);
    const [lng, setLng] = useState(2);

    //HOOKS
    const { register, watch, setValue } = useForm();

    useEffect(() => {
        if (props.readyToWatch) {
            watch((value, { name, type }) => {
                if (value !== '') props.dispatchOption(props.dispatch, name, value[name]);
            });

            const address = props.getValues()?.address ?? '';
            const point = props.getValues()?.point;

            setValue('address', address);
            setValue('point', point);

            if (point) {
                const splittedPoint = point.split(',');
                setLat(splittedPoint[0]);
                setLng(splittedPoint[1]);
            }
        }
        //eslint-disable-next-line
    }, [props.readyToWatch]);

    useEffect(() => {
        setFocus(props.open);
        //eslint-disable-next-line
    }, [props.open]);

    useEffect(() => {
        if (map) {
            map.setView([lat, lng], props.props.zoom ? props.props.zoom : 7);
        }
        //eslint-disable-next-line
    }, [map, lat, lng]);

    function onSearchStateChange(searchState, address) {
        const latLng = _.split(searchState, ',');

        setLat(parseFloat(latLng[0]));
        setLng(parseFloat(latLng[1]));

        setValue('point', searchState);
        setValue('address', address);

        map.setView([latLng[0], latLng[1]], 13);
    }

    const displayMap = useMemo(
        () => (
            <div id="map" className="z-10 mt-4 rounded-lg border border-gray-300" style={{ height: '380px' }}>
                <MapContainer
                    style={{ height: '380px' }}
                    className="rounded-lg"
                    center={{ lat: lat, lng: lng }}
                    zoom={props.props.zoom ? props.props.zoom : 5}
                    scrollWheelZoom={false}
                    whenCreated={setMap}
                >
                    <TileLayer
                        url="https://api.mapbox.com/styles/v1/djibdjib/cjpjrthxq0i1f2qpndy1nqmtd/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiZGppYmRqaWIiLCJhIjoiY2pwanJvdXJtMDI0ZDN2bDRzOTVxcGRiOCJ9.fqa2x0ddU1bkQ2SlvFc4ag"
                        attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> Imagery &copy; <a href="https://www.mapbox.com/">Mapbox</a>'
                    />
                    <Marker position={[lat, lng]}>{/* <Popup>Mon adresse</Popup> */}</Marker>
                </MapContainer>
            </div>
        ),
        //eslint-disable-next-line
        [lat, lng]
    );

    return (
        <div className="w-full p-4 relative">
            <div className="z-50 flex flex-col" style={{ zIndex: 99999 }}>
                {/* back button */}
                <button
                    className="flex items-center px-0 link_basic mb-4"
                    onClick={() => {
                        props.setOpen(false);
                    }}
                >
                    <ArrowNarrowLeftIcon className="w-6 h-6 mr-2 relative" />
                </button>
                <FieldPlace placeholder="Trouvez votre adresse" id="address" isClearable={false} onChange={onSearchStateChange} />
                <div className="relative addressOptionn z-20">{displayMap}</div>
                <div className="addressOptionn relative z-20">
                    <FieldInput type="hidden" name="point" errors={props.errors} register={register} autoFocus={focus} />
                </div>
            </div>
        </div>
    );
}

export default AddressOption;
