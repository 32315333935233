import API from "./axios.config";
import authHeader from "services/Auth.Header";

async function getProducts() {
    const response = await API.get("products");
    return response.data;
}

async function getProductsByMe() {
    const response = await API.get("products/me", { headers: authHeader() });
    return response.data;
}

async function getProduct(id) {
    const response = await API.get("products/" + id, {
        headers: authHeader(),
    });
    return response.data;
}

async function postProduct(data) {
    const response = await API.post("products/create", data, {
        headers: authHeader(),
    });
    return response;
}

async function putProduct(id, data) {
    const response = await API.put("products/" + id, data, {
        headers: authHeader(),
    });
    return response;
}

async function putProductPublish(id) {
    const response = await API.put(
        "products/togglePublish/" + id,
        { publish: true },
        {
            headers: authHeader(),
        }
    );
    return response.data;
}

async function deleteProduct(id) {
    const response = await API.delete("products/" + id, {
        headers: authHeader(),
    });
    return response.data;
}

export {
    getProducts,
    getProductsByMe,
    getProduct,
    postProduct,
    putProduct,
    putProductPublish,
    deleteProduct,
};
