//STYLES
import 'App.css';
import '../containers/searchBar/ForumSearchBar.css';
//LAYOUT
import FullLayout from 'pages/layouts/Landing';
//CONFIG
import config from 'utils/constants';
import { useCallback, useRef, useState } from 'react';
import Modal from 'components/common/Modal';
import FormConnexion from 'components/forms/Connexion';
//COMPONENTS

function Profil() {
    const [isInscriptionOpen, setIsInscriptionOpen] = useState(false);
    const [isLogged, setIsLogged] = useState(false);
    const [modalConfirmLabel, setModalConfirmLabel] = useState('Valider');

    //REFS
    const loginRef = useRef(null);

    const handleOpenInscription = useCallback(() => {
        setIsInscriptionOpen(true);
        setIsLogged(false);
    }, []);

    const handleLogin = useCallback(() => {
        loginRef.current.login();
    }, []);

    function onLogged(status) {
        setIsLogged(status);
    }
    return (
        <>
            <FullLayout>
                <main>
                    <div className="pb-24">
                        <div className="container-fluid mx-auto md:px-24 px-4 pt-32 bg-blueMain shadow-xl rounded-b-2xl">
                            <div className="container flex flex-col md:px-24 px-4">
                                <div className="mb-16">
                                    <h1 className="text-white font-semibold text-xl sm:text-3xl md:text-4xl xl:text-5xl 2xl:text-7xl" style={{ fontFamily: 'Nunito' }}>
                                        Pourquoi s'inscrire ?
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="">
                        <div className="container-md w-full h-full ">
                            <div className="relative">
                                <div
                                    className="bg-no-repeat bg-left-bottom bg-23rem  md:bg-40rem lg:bg-40rem xl:bg-45rem 2xl:bg-auto"
                                    style={{
                                        backgroundImage: `url(${config.publicDir}landingpage/tache1.svg)`,
                                    }}
                                >
                                    <div
                                        className="xl:pt-36 bg-no-repeat bg-right bg-cover bg-16 lg:bg-70rem 2xl:bg-auto lg:h-70rem xl:h-100rem 2xl:h-115rem"
                                        style={{
                                            backgroundImage: `url(${config.publicDir}landingpage/flux1.svg)`,
                                        }}
                                    >
                                        <div className="px-5 sm:px-10 md:px-20 lg:px-24 xl:px-40 2xl:px-56">
                                            <h1 className="pb-6 lg:pt-24 text-xl sm:text-3xl md:text-4xl xl:text-5xl 2xl:text-6xl" style={{ fontFamily: 'Nunito', color: '#46519f' }}>
                                                Rayonnez par vos<br></br>compétences
                                            </h1>
                                            <p className="text-gray-500 text-sm md:text-lg">
                                                <strong>Répondez et échangez</strong> sur des sujets que vous<br></br>maîtrisez pour créer une proximité avec vos clients.
                                            </p>
                                        </div>

                                        <div className="flex flex-col-reverse pt-20 lg:flex-row lg:pl-20 lg:pr-5 sm:pr-10 md:pr-20 lg:pr-24 lg:pt-28 xl:pr-40 xl:pt-32 2xl:pr-56 2xl:pt-40">
                                            <div className="md:px-20 lg:w-4/6">
                                                <img className="" src={`${config.publicDir}landingpage/visuel_room1.svg`} alt="" />
                                            </div>
                                            <div className="px-5 lg:px-0 lg:w-1/2 pb-24 text-center lg:text-right 2xl:mt-20 md:pt-20 lg:pt-60 xl:pt-96">
                                                <h1 className="pb-6 text-xl sm:text-3xl md:text-4xl xl:text-5xl 2xl:text-6xl" style={{ fontFamily: 'Nunito', color: '#46519f' }}>
                                                    Et si les clients<br></br>venaient à vous ?
                                                </h1>
                                                <p className="text-gray-500 text-sm md:text-lg">
                                                    En aidant la communauté, vous tissez <strong>des liens de confiance</strong>
                                                    <br></br>avec <strong>vos prospects</strong> et <strong>des points de référencement</strong> pour <strong>plus de visibilités.</strong>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="px-5 pt-20 h-80 bg-20rem sm:px-10 md:h-30rem md:px-20 lg:px-24 xl:px-40 2xl:px-56 lg:pt-20 lg:h-40rem xl:h-30rem 2xl:h-xl">
                                        <h1 className="pb-6 text-xl sm:text-3xl md:text-4xl xl:text-5xl 2xl:text-6xl" style={{ fontFamily: 'Nunito', color: '#46519f' }}>
                                            Votre smartprofil,<br></br>votre univers
                                        </h1>
                                        <p className="text-gray-500 text-sm md:text-lg">
                                            Montrez tout ce que vous proposez en un coup d’oeil, <br></br>
                                            <strong>vos services</strong>, <strong>votre portfolio</strong>, <strong>votre expertise.</strong>
                                        </p>
                                    </div>
                                </div>
                                <div className="relative lg:h-80rem xl:h-100rem">
                                    <div className="lg:absolute right-0 bottom-32">
                                        <div className="relative h-0 lg:h-auto invisible lg:visible">
                                            <div>
                                                <img className="" src={`${config.publicDir}landingpage/chemin.svg`} alt="" />
                                            </div>
                                            <div className="absolute top-40%  right-0 h-16 text-right pr-5 sm:pr-10 md:pr-20 lg:pr-24 xl:pr-40 pl-20 2xl:pr-56">
                                                <h1 className="pb-6 text-xl sm:text-3xl md:text-4xl xl:text-5xl 2xl:text-6xl" style={{ fontFamily: 'Nunito', color: '#46519f' }}>
                                                    Gagnez en crédibilité<br></br>et référencement
                                                </h1>
                                                <p className="text-gray-500 text-sm md:text-lg">
                                                    Plus vous avez de points, plus vous êtes <strong>visible sur vos tags.</strong>
                                                    <br></br>
                                                    Nos pages sont <strong>référencées sur Google</strong> et vos réponses servirons<br></br>à <strong>ceux qui se posent les mêmes questions.</strong>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="visible lg:invisible lg:h-0">
                                    <div className="flow-root">
                                        <img className="lg:h-0 float-right" src={`${config.publicDir}landingpage/chemin_decompose1.svg`} alt="" />
                                    </div>
                                    <h1 className="text-center pb-6 text-xl sm:text-3xl md:text-4xl xl:text-5xl 2xl:text-6xl" style={{ fontFamily: 'Nunito', color: '#46519f' }}>
                                        Gagnez en crédibilité<br></br>et référencement
                                    </h1>
                                    <div className="">
                                        <img className="lg:h-0" src={`${config.publicDir}landingpage/chemin_decompose2.svg`} alt="" />
                                    </div>
                                    <p className="px-5 lg:px-0 pt-10 text-gray-500 text-center text-sm md:text-lg">
                                        {' '}
                                        Plus vous avez de points,<br></br>plus vous êtes <strong>visible sur vos tags.</strong>
                                        <br></br>
                                        Nos pages sont <strong>référencées sur Google</strong> et vos réponses servirons à <strong>ceux qui se posent les mêmes questions.</strong>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section>
                        <div className="text-center pt-20 lg:pt-0 pb-24">
                            <button onClick={() => handleOpenInscription()} className="bg-secondary hover:bg-blue-600 text-white rounded-2xl text-2xl xl:text-3xl 2xl:text-4xl h-2/4 p-5 md:p-10">
                                S'inscrire gratuitement
                            </button>
                        </div>
                    </section>
                </main>
            </FullLayout>
            <Modal
                title={`${!isLogged ? 'Connexion à SkillsMarket' : ''}`}
                open={isInscriptionOpen}
                onClose={{
                    onClick: () => {
                        setIsInscriptionOpen(false);
                    },
                }}
                onConfirm={
                    !isLogged
                        ? {
                              label: modalConfirmLabel,
                              class: 'font-bold',
                              onClick: handleLogin,
                          }
                        : false
                }
            >
                <FormConnexion ref={loginRef} onLogged={() => setIsInscriptionOpen(false)} isLogged={(status) => onLogged(status)} modalConfirmLabel={(label) => setModalConfirmLabel(label)}/>
            </Modal>
        </>
    );
}

export default Profil;
