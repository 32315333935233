import API from "./axios.config";
import authHeader from "services/Auth.Header";

async function createPayment(items) {
    const response = await API.post("stripe/create-payment-intent/", items, {
        headers: authHeader(),
    });
    return response.data;
}

async function createSubscription(items) {
    const response = await API.post("stripe/create-subscription/", items, {
        headers: authHeader(),
    });
    return response.data;
}

async function getProductById(id) {
    const response = await API.get('stripe/product/' + id);
    return response.data;
}

async function getPlans() {
    const response = await API.get('stripe/subscriptions', { headers: authHeader() });
    return response.data;
}

async function updateSubscription(id, items) {
    const response = await API.put('stripe/update-subscription/' + id, items, { headers: authHeader() });
    return response.data;
}

async function terminatePlan(id) {
    const response = await API.get('stripe/terminate/' + id, { headers: authHeader() });
    return response.data;
}

export { 
    createPayment,
    createSubscription,
    getProductById,
    getPlans,
    updateSubscription,
    terminatePlan,
};
