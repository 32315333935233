import { useRef, Fragment, useImperativeHandle, useEffect } from 'react';

//LIBS
import { useInfiniteQuery } from 'react-query';
import Masonry from 'react-masonry-component';

//HOOKS
import UseIntersectionObserver from 'hooks/useIntersectionObserver';

function useInfinite(query, key, options) {
    const page = useRef(0);
    const hasNextPage = useRef(false);
    const loadMoreButtonRef = useRef();

    const { data, fetchNextPage, isFetchingNextPage, refetch } = useInfiniteQuery(
        key,
        ({ pageParam = 0 }) => query(`?&p=${pageParam}${options.limit ? `&l=${options.limit}` : ''}${options.user ? `&u=${options.user}` : ''}`),
        {
            getNextPageParam: (lastPage) => {
                if (lastPage.hasNextPage) {
                    hasNextPage.current = lastPage.hasNextPage;

                    return (page.current = lastPage.page);
                } else {
                    hasNextPage.current = false;
                    page.current = 0;
                }
                return;
            },
            enabled: options.enabled ?? true,
        }
    );

    useImperativeHandle(options?.ref, () => ({
        refetch: () => {
            refetch();
        },
    }));

    UseIntersectionObserver({
        target: loadMoreButtonRef,
        onIntersect: fetchNextPage,
        enabled: !!hasNextPage.current,
    });

    useEffect(() => {
        options?.onData?.(data);
        //eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        if (options.listen) refetch();
        //eslint-disable-next-line
    }, [options.listen]);

    const masonryOptions = {
        transitionDuration: '0.2s',
        columnWidth: '.grid-sizer',
        itemSelector: '.grid-item',
        percentPosition: true,
    };

    return (
        <>
            {data?.pages && (
                <>
                    {options.masonry && (
                        <Masonry options={masonryOptions}>
                            <div className="grid-sizer"></div>
                            <div className="gutter-sizer"></div>
                            {data?.pages?.map((group, index) => (
                                <Fragment key={index}>
                                    {group.docs.map((data, k) => (
                                        <Fragment key={k}>{options?.onItem(data, refetch)}</Fragment>
                                    ))}
                                </Fragment>
                            ))}
                        </Masonry>
                    )}
                    {!options.masonry && (
                        <>
                            {data?.pages?.map((group, index) => (
                                <Fragment key={index}>
                                    {group.docs.map((data, k) => (
                                        <Fragment key={k}>{options?.onItem(data, refetch)}</Fragment>
                                    ))}
                                </Fragment>
                            ))}
                        </>
                    )}
                    {hasNextPage.current && (
                        <div className="text-center mt-4">
                            <button
                                ref={loadMoreButtonRef}
                                onClick={() => fetchNextPage()}
                                className="button button-secondary"
                                disabled={!hasNextPage.current || isFetchingNextPage}
                            >
                                {isFetchingNextPage ? 'Chargement...' : options.msgToSee ?? 'Voir plus'}
                            </button>
                        </div>
                    )}
                    {data?.pages?.[0]?.docs?.length <= 0 && <>{options?.onNoResult?.()}</>}
                </>
            )}
        </>
    );
}

export default useInfinite;
