import { useState, useContext, useEffect } from 'react';

//API
import API from 'api/axios.config';

//LIBS
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';

//CONTEXTS
import { AuthContext } from 'context/authContext';
import { SocketContext } from 'context/socketContext';
import { StoreContext } from 'context/storeContext';
import { isMobile } from '../utils/functions';

function UseAuth() {
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isForgotSubmitted, setIsForgotSubmitted] = useState(false);
    const [isSignupSubmitted, setIsSignupSubmitted] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    //CONTEXTS
    const [authState, setAuthState, refresh] = useContext(AuthContext);
    const socket = useContext(SocketContext);
    const store = useContext(StoreContext);

    //HOOKS
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        // console.log('init auth');
        const currentTime = moment();
        const timeSession = moment(localStorage.getItem('timeSession'));

        // console.log(currentTime.format('YYYY-MM-DD HH:mm'), timeSession.format('YYYY-MM-DD HH:mm'));
        if (localStorage.getItem('user') && (currentTime.isAfter(timeSession, 'minute') || !localStorage.getItem('timeSession'))) {
            logout();
        }
        //eslint-disable-next-line
    }, []);

    const login = async (inputsConnexion) => {
        try {
            var mailformat = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z ]{2,4}$/i;
            if (!inputsConnexion.email.match(mailformat)) {
                setHasError(true);
                setIsSubmitted(false);
                return false;
            } else setHasError(false);
            const response = await API.post('users/login', {
                email: inputsConnexion.email,
                password: inputsConnexion.password,
            });
            if (response.status === 200) {
                setConnexion(response);

                if (!isMobile()) {
                    if (location.pathname !== '/inscription-clone-ai') {
                        store.set({ type: 'add', key: 'discussionType', value: 'standard' });
                        store.set({ type: 'add', key: 'openChat', value: { open: true } });
                    } else {
                        store.set({ type: 'add', key: 'discussionType', value: 'ai' });
                        store.set({ type: 'add', key: 'openChat', value: { open: true } });
                    }
                }
            }
        } catch (err) {
            setIsSubmitted(true);
            setHasError(true);
        }
    };

    const loginByOauth = async (tokenId, type) => {
        const response = await API.post('users/loginByOauth', {
            tokenId,
            type,
        });
        if (response.status === 200) {
            setIsSubmitted(true);
            setHasError(false);
            setConnexion(response);

            if (!isMobile()) {
                if (location.pathname !== '/inscription-clone-ai') {
                    store.set({ type: 'add', key: 'discussionType', value: 'standard' });
                    store.set({ type: 'add', key: 'openChat', value: { open: true } });
                } else {
                    store.set({ type: 'add', key: 'discussionType', value: 'ai' });
                    store.set({ type: 'add', key: 'openChat', value: { open: true } });
                }
            }
        }
    };

    const setConnexion = (response) => {
        if (response.data.error) {
            setIsSubmitted(true);
        } else {
            if (response.data.token) {
                localStorage.setItem('user', JSON.stringify(response.data));
                localStorage.setItem('timeSession', moment().add(150, 'days'));
            }
            if (!isMobile()) {
                toast('Vous êtes connecté(e)', { type: 'success' });
            }

            // setAuthState({
            //     ...authState,
            //     me: null,
            //     isLogged: true,
            // });
            setIsSubmitted(true);
            setHasError(false);
            refresh();
        }
    };

    const forgotPassword = async (inputsConnexion) => {
        try {
            const response = await API.post('users/forgotPassword', {
                email: inputsConnexion.email,
            });
            if (response.status === 200) {
                toast('Un email de réinitialisation vous a été envoyé', { type: 'success' });
                setIsForgotSubmitted(true);
                setHasError(false);
                return true;
            } else if (response.status === 201) {
                setHasError(true);
                setErrorMsg('Un mail vous a déjà été envoyé il y a moins de 1 heure');
                setIsForgotSubmitted(true);
            }
        } catch (err) {
            setHasError(true);
            setErrorMsg("Aucun compte n'existe avec cette adresse email");
            setIsForgotSubmitted(true);
        }
    };

    const signUp = async (inputsConnexion) => {
        try {
            /*  const response = await API.post('users/signup', {
                 email: inputsConnexion.email,
                 password: inputsConnexion.password,
                 confirm: inputsConnexion.confirm,
                 pseudo: inputsConnexion.pseudo,
             }); */

            setIsSignupSubmitted(true);
            const response = await API.post('users/signup', {
                email: inputsConnexion.email,
                password: inputsConnexion.password,
                confirm: inputsConnexion.confirm,
                firstname: inputsConnexion.firstname,
                lastname: inputsConnexion.lastname,
                society: inputsConnexion.society,
                typeInscription: inputsConnexion.typeInscription,
                type: inputsConnexion.type,
            });
            if (response.status === 201) {
                //is mobile
                if (!isMobile()) {
                    toast('Vous avez créé un compte SkillsMarket !', {
                        type: 'success',
                    });
                }
                login(inputsConnexion);
                return true;
            } else {
                toast('Une erreur est survenue', { type: 'error' });
                return false;
            }
        } catch (err) {
            console.error(err);
            toast('Un compte existe déjà avec cette adresse email', { type: 'error' });
            setHasError(true);
            setIsSignupSubmitted(false);
        }
    };

    const logout = () => {
        localStorage.removeItem('user');
        localStorage.removeItem('timeSession');

        setAuthState({
            ...authState,
            me: null,
            isLogged: false,
        });

        socket.send('userLogout', { id: authState?.me?.id });
        socket.off('userConnect');
        // socket.disconnect();
        // socket.connect();
        toast('Vous êtes déconnecté(e)', { type: 'success' });
        store.set({ type: 'add', key: 'isConnexionOpen', value: false });

        refresh();
        reload();
    };

    const reload = () => {
        navigate(location.pathname + location.search);
    };

    return {
        isSubmitted,
        isForgotSubmitted,
        isSignupSubmitted,
        hasError,
        errorMsg,
        logout,
        login,
        loginByOauth,
        forgotPassword,
        signUp,
        reload,
    };
}

export default UseAuth;
