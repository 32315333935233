import { useCallback, useContext, useEffect, useState } from 'react';

//API
import { postDisableAccount } from 'api/users';
import { getPlans } from 'api/stripe';

//COMPONENTS
import Header from 'components/common/Header';
import Modal from 'components/common/Modal';

//CONTEXTS
import { AuthContext } from 'context/authContext';
import { Link } from 'react-router-dom';

//HOOKS
import UseAuth from 'hooks/useAuth';

//LAYOUT
import DashboardLayout from 'pages/layouts/Dashboard';

//LIBS
import { toast } from 'react-toastify';

//STYLES
import 'App.css';

//UTILS
import { PageContentFadingAnimation } from 'utils/animations';
import PlanCard from './PlanCard';

function Account(props) {
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
    const [openAskMoney, setOpenAskMoney] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [displayMore, setDisplayMore] = useState(true);
    const { logout } = UseAuth();

    // eslint-disable-next-line no-unused-vars
    const [authState, , authRefresh] = useContext(AuthContext);
    const [userPlans, setUserPlans] = useState([]);
    const [refresh, setRefresh] = useState(false);

    function handleDelete() {
        postDisableAccount().then((data) => {
            setOpenConfirmDelete(false);
            toast(data.message, { type: 'success' });
            logout();
        });
    }

    const handleLogout = useCallback(() => {
        // setOpenedUserMenu(false);
        // setOpenedShootsMenu(false);
        logout();
        //eslint-disable-next-line
    }, []);

    function handleAskMoney() {
        //console.log('asked');
        setOpenAskMoney(false);
    }

    useEffect(() => {
        authRefresh();
        getPlans()
            .then((data) => {
                setUserPlans(data);
            })
            .catch((err) => {
                console.log(err);
            });
        //eslint-disable-next-line
    }, [refresh]);

    return (
        <DashboardLayout>
            <PageContentFadingAnimation duration={225} className="App">
                <Modal
                    title="Supprimer mon compte"
                    open={openConfirmDelete}
                    onClose={{
                        onClick: () => {
                            setOpenConfirmDelete(false);
                        },
                    }}
                    onConfirm={{
                        label: 'Supprimer',
                        onClick: handleDelete,
                    }}
                >
                    <p>Voulez-vous vraiment supprimer votre compte SkillsMarket ?</p>
                </Modal>
                <Modal
                    title="Demander un virement"
                    open={openAskMoney}
                    onClose={{
                        onClick: () => {
                            setOpenAskMoney(false);
                        },
                    }}
                    onConfirm={{
                        label: 'Envoyer',
                        onClick: handleAskMoney,
                    }}
                >
                    <p>Veuillez entrer ou mettre à jour vos coordonnées bancaires</p>
                </Modal>
                <Header>
                    <div className="mx-4 sm:mx-0">
                        <div className="mb-8">
                            <Link to="/dashboard/options">
                                <h1 className="h1">Mes notifications</h1>
                                <button
                                    className={`mt-4 sm:block linkCard`}
                                    onClick={() => {
                                        setOpenConfirmDelete(true);
                                    }}
                                >
                                    <span className="text-black">Paramètres des notifications</span>
                                </button>
                            </Link>
                        </div>
                        <div className="mb-8">
                            <h1 className="h1">Mes Abonnements</h1>
                            {userPlans.length > 0 ? (
                                userPlans?.map((plan, index) => (
                                    <div key={index} className="mt-4">
                                        <PlanCard plan={plan} refresh={() => setRefresh(true)} />{' '}
                                    </div>
                                ))
                            ) : (
                                <p className="mt-4 text-mainText">
                                    Vous n'avez pas d'abonnement en cours. <br />
                                </p>
                            )}
                        </div>
                        {/*
                        <div className="mb-8">
                            <h1 className="h1">Mon solde</h1>
                            <p className="mt-4 text-mainText">
                                Votre solde est de <strong>{authState?.me?.bank?.credits ?? 0} €</strong>
                            </p>
                            <p className="mt-2 text-mainText">
                                Vous pouvez demander un virement sur votre compte si votre solde est au moins égal à <strong>10 €</strong>.
                            </p>
                            {authState?.me?.bank?.credits >= 10 && (
                                <button onClick={() => setOpenAskMoney(true)} className="button button-small button mt-4 ml-0">
                                    Demander un virement de mon solde sur mon compte
                                </button>
                            )}
                        </div>
                        */}
                        <div className="mb-24">
                            <h1 className="h1">Mon compte</h1>

                            <button onClick={handleLogout} className="button button-small button-danger mt-4 ml-0">
                                Me déconnecter
                            </button>

                            <div className="mt-32 w-full flex flex-col">
                                {/*
                                <button onClick={() => setDisplayMore(!displayMore)} className="button button-small mt-4 ml-0 text-left">
                                    {displayMore ? 'Voir moins' : 'Voir plus'}
                                </button>
                                */}
                                {displayMore && (
                                    <>
                                        <p className="mt-4 text-mainText text-left">
                                            Si vous n'êtes pas satisfait des services de SkillsMarket, vous pouvez supprimer votre compte en cliquant sur le
                                            bouton ci-dessous
                                        </p>
                                        <button onClick={() => setOpenConfirmDelete(true)} className="button button-small mt-4 ml-0 w-fit">
                                            Supprimer mon compte
                                        </button>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>

                    {/* <p className="mt-4 text-blue-800">Vous serez prévenu en temps réel d'un appel d'offre ou d'une question posée sur une Room afin d'être dans les premiers à intéragir.</p> */}
                </Header>

                <main>{/* <FormTagSubscription /> */}</main>
            </PageContentFadingAnimation>
        </DashboardLayout>
    );
}

export default Account;
