import { useEffect, useState } from "react";
//LIBS
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
//API
import { putProductPublish, deleteProduct } from "api/products";
//ICONS
import {
    EyeIcon,
    EyeOffIcon,
    PencilIcon,
    TrashIcon,
} from "@heroicons/react/solid";
import Modal from "components/common/Modal";

function CardProduct({ product, refetch }) {
    const [item, setItem] = useState(product);
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

    function handleTogglePublish() {
        putProductPublish(item.id).then((data) => {
            setItem({ ...item, publish: data.publish });
            const msg = data.publish ? "Produit publié" : "Produit dépublié";
            toast(msg, { type: "success" });
        });
    }
    function handleDelete() {
        deleteProduct(item.id).then((data) => {
            toast("Produit supprimé", { type: "success" });
            refetch();
            setOpenConfirmDelete(false);
        });
    }

    useEffect(() => {
        setItem(product);
        //eslint-disable-next-line
    }, [, product]);

    return (
        <>
            <Modal
                title="Supprimer le produit"
                open={openConfirmDelete}
                onClose={{
                    onClick: () => {
                        setOpenConfirmDelete(false);
                    },
                }}
                onConfirm={{
                    label: "Supprimer",
                    onClick: handleDelete,
                }}
            >
                <p>Voulez-vous vraiment supprimer ce produit ?</p>
            </Modal>
            <div className="card card-service w-12/12">
                <div className="card-header">
                    <span>{item.title}</span>
                    <span>{item.price}</span>
                </div>

                <div className="flex flex-nowrap justify-end">
                    <div className="card-actions">
                        <button
                            onClick={handleTogglePublish}
                            className={`${
                                item.publish
                                    ? "text-success-400"
                                    : "text-warning-300"
                            }`}
                        >
                            <>
                                {item.publish ? (
                                    <EyeIcon className="w-5 h-5" />
                                ) : (
                                    <EyeOffIcon className="w-5 h-5" />
                                )}
                            </>
                        </button>
                        <Link to={`${item.slug}`} className="flex">
                            <PencilIcon className="w-5 h-5" />
                        </Link>
                        <button
                            onClick={() => setOpenConfirmDelete(true)}
                            className="text-danger-500"
                        >
                            <TrashIcon className="w-5 h-5 " />
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CardProduct;
