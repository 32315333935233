import Slider from 'react-slick';
import Button from 'components/common/Button';
//LIBS
import { Fragment, useRef, useState } from 'react';

//CSS
import './customSlider.css';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

//UTILS
import config from 'utils/constants';
import { ChevronLeftIcon, ChevronRightIcon, PlayIcon } from '@heroicons/react/solid';
import PlayerMedia from '../common/PlayerMedia';
import { isSmartphone } from '../../utils/functions';

function CustomSlider(props) {
    const [currentMedia, setCurrentMedia] = useState(0);

    const sliderRef = useRef(null);

    const goToSlide = (index) => {
        if (sliderRef && sliderRef.current) {
            sliderRef.current.slickGoTo(index);
        }
    };

    const CustomDot = ({ i, data }) => {
        if (!data || !data[i]) {
            return null;
        }

        const currentStyle = i === currentMedia ? { border: '2px solid #0031ff' } : {};

        const isVideo = data[i].type?.includes('video');

        return (
            <div
                key={i}
                onClick={() => {
                    goToSlide(i);
                }}
                className={`bg-black`}
                style={{ position: 'relative', width: '80px', height: '50px', borderRadius: '10px', overflow: 'hidden', ...currentStyle }}
            >
                {isVideo ? (
                    <>
                        {data[i].encoded === true ? (
                            <img
                                src={`${config.serverUrl}/downloads/videos/${props?.user?.id}/screenshot_${data[i]._id}_1.jpg`}
                                alt={`${data[i].name}`}
                                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                            />
                        ) : (
                            <div></div>
                        )}
                        <div className="absolute top-0 left-0 right-0 bottom-0 font-bold p-0 rounded-full flex items-center justify-center">
                            <Button label={<PlayIcon className="w-5 h-5" />} justIcon={true} css="p-0" />
                        </div>
                    </>
                ) : (
                    <img src={config.serverUrl + data[i]?.url} alt={`slide-${i}`} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                )}
            </div>
        );
    };

    const arrowStyles = {
        position: 'absolute',
        /* top: '50%', */
        transform: 'translateY(-50%)',
        zIndex: 2,
        /*  background: 'rgba(255, 255, 255, 0.6)', */
        borderRadius: '50%',
        cursor: 'pointer',
    };

    const ArrowLeft = ({ currentSlide, slideCount, ...props }) => (
        <button {...props} className={'w-5 h-8 top-[50%] sm:top-[40%] bg-[#FFFFFF99] sm:w-8 sm:h-14'} style={{ ...arrowStyles, left: '10px' }}>
            <ChevronLeftIcon className="text-graySkills hover:text-white" />
        </button>
    );

    const ArrowRight = ({ currentSlide, slideCount, ...props }) => (
        <button {...props} className={'w-5 h-8 top-[50%] sm:top-[40%] bg-[#FFFFFF99] sm:w-8 sm:h-14'} style={{ ...arrowStyles, right: '10px' }}>
            <ChevronRightIcon className="text-graySkills hover:text-white" />
        </button>
    );

    const settings = {
        className: `custom-slider flex flex-col gap-1 ${props.width ? props.width : 'w-full'} rounded-3xl`,
        dots: isSmartphone() ? false : true,
        infinite: true,
        variableWidth: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        customPaging: (i) => <>{!isSmartphone() && <CustomDot i={i} data={props?.medias} />}</>,
        prevArrow: <ArrowLeft />,
        nextArrow: <ArrowRight />,
        beforeChange: (oldIndex, newIndex) => {
            setCurrentMedia(newIndex);
        },
    };

    return (
        <Slider ref={sliderRef} {...settings}>
            {props.medias.map((media, index) => (
                <Fragment key={index}>
                    {media.type.includes('video') ? (
                        <PlayerMedia index={index} currentIndex={currentMedia} media={media} user={props?.user} />
                    ) : (
                        <img
                            className="relative flex flex-col justify-center w-full h-[20rem] max-h-[20rem] sm:h-auto sm:max-h-[30rem] sm:rounded-3xl"
                            src={config.serverUrl + media.url}
                            alt={`slide-${index}`}
                        />
                    )}
                </Fragment>
            ))}
        </Slider>
    );
}

export default CustomSlider;
