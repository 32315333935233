import { useCallback, useEffect, useRef, Fragment, useContext, useState } from 'react';

import { SwiperSlide } from 'swiper/react';

import 'swiper/swiper.min.css';
//CONTEXTS
import { AuthContext } from 'context/authContext';

//API
import { useInfiniteQuery } from 'react-query';
import { getShootsP } from 'api/shoots';

//HOOKS
import UseIntersectionObserver from 'hooks/useIntersectionObserver';

import { v4 as uuidv4 } from 'uuid';

//UTILS
import { isMobile } from 'utils/functions';
//CONTAINERS
import CardShoot from 'containers/shoots/CardShoot';
import CardShootMobile from 'containers/shoots/CardShootMobile';

function useInfiniteShoots(isForMe, isNew, isEnabled = true) {
    const [authState] = useContext(AuthContext);
    //REFS
    const page = useRef(0);
    const hasNextPage = useRef(false);
    const loadMoreButtonRef = useRef();

    const [muted, setMuted] = useState(false);

    const [tab, setTab] = useState(null);

    // eslint-disable-next-line no-unused-vars
    const handleMuted = () => {
        setMuted(!muted);
    };

    //API
    const globalShoots = useInfiniteQuery(
        ['infiniteShoots', isForMe, isNew],
        ({ pageParam = 0 }) => getShootsP(`?p=${pageParam}&l=10${authState?.me?.id ? '&u=' + authState?.me?.id : ''}`, isForMe, isNew),
        {
            getNextPageParam: (lastPage) => {
                if (lastPage.hasNextPage) {
                    hasNextPage.current = lastPage.hasNextPage;

                    return (page.current = lastPage.page);
                } else {
                    hasNextPage.current = false;
                    page.current = 0;
                }
                return;
            },
            enabled: false,
        }
    );

    //HOOKS
    UseIntersectionObserver({
        target: loadMoreButtonRef,
        onIntersect: (isVisible) => {
            if (isVisible) globalShoots.fetchNextPage();
        },
        enabled: !!hasNextPage.current,
    });

    useEffect(() => {
        if (!isEnabled) return;

        if (isForMe && authState.isLogged) globalShoots.refetch();
        if (!isForMe) globalShoots.refetch();
        // globalRooms.refetch();
        //eslint-disable-next-line
    }, [authState.isLogged, isEnabled]);

    const tabGlobal = useCallback(() => {
        return (
            <>
                {globalShoots.data?.pages && (
                    <>
                        {!isMobile() ? (
                            <>
                                {globalShoots.data?.pages?.map((pages, pageIndex) => (
                                    <Fragment key={pageIndex}>
                                        {pages?.docs.map((shoot, shootIndex, array) => (
                                            <div className="h-full" key={`${pageIndex}_${shootIndex}`}>
                                                <CardShoot
                                                    shoot={shoot}
                                                    /* nextShoot={array[shootIndex + 1]} */ muted={muted}
                                                    handleMuted={handleMuted}
                                                    refetch={globalShoots.refetch}
                                                />
                                            </div>
                                        ))}
                                    </Fragment>
                                ))}
                                {hasNextPage.current && (
                                    <div className="text-center mt-4">
                                        <button
                                            ref={loadMoreButtonRef}
                                            className="button button-secondary"
                                            onClick={globalShoots.fetchNextPage}
                                            disabled={!hasNextPage.current || globalShoots.isFetchingNextPage}
                                        >
                                            {globalShoots.isFetchingNextPage ? 'Chargement...' : 'Voir plus'}
                                        </button>
                                    </div>
                                )}
                                {isForMe && globalShoots.data?.pages.length === 0 && (
                                    <p className="text-center mt-8 text-sm text-graySkills">Vous pouvez renseigner plus de tags pour améliorer cette section</p>
                                )}
                            </>
                        ) : (
                            <>
                                {globalShoots.data?.pages?.map((pages, pageIndex) => (
                                    <Fragment key={pageIndex}>
                                        {pages?.docs.map((shoot, shootIndex, array) => (
                                            <SwiperSlide key={`${pageIndex}_${shootIndex}_${shoot._id}_${uuidv4()}`}>
                                                <CardShootMobile
                                                    shoot={shoot}
                                                    /* nextShoot={array[shootIndex + 1]} */ muted={muted}
                                                    handleMuted={handleMuted}
                                                    refetch={globalShoots.refetch}
                                                />

                                                {/* Si c'est le dernier élément de la liste, afficher le bouton */}
                                                {shootIndex === array.length - 1 && hasNextPage.current && (
                                                    <div className={`text-center mt-4`}>
                                                        <button
                                                            ref={loadMoreButtonRef}
                                                            className="button button-secondary bg-blueMain rounded-full"
                                                            onClick={globalShoots.fetchNextPage}
                                                            disabled={!hasNextPage.current || globalShoots.isFetchingNextPage}
                                                        >
                                                            {globalShoots.isFetchingNextPage ? 'Chargement...' : 'Chargement...'}
                                                        </button>
                                                    </div>
                                                )}
                                            </SwiperSlide>
                                        ))}
                                    </Fragment>
                                ))}
                                {isForMe && globalShoots.data?.pages.length === 0 && (
                                    <p className="text-center mt-8 text-sm text-graySkills">Vous pouvez renseigner plus de tags pour améliorer cette section</p>
                                )}
                            </>
                        )}
                    </>
                )}
            </>
        );
        //eslint-disable-next-line
    }, [globalShoots.data, globalShoots.isSuccess, muted]);

    /*    useEffect(() => {
        setMuted(false);
      }, [globalShoots.data]);
 */

    /*   useEffect(() => {   
        if(fetchNextPage && hasNextPage.current) { 
            console.log("load next page")
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchNextPage]);  */

    useEffect(() => {
        if (globalShoots?.data?.pages[0].totalDocs === 0) {
            return setTab(null);
        }
        setTab(tabGlobal);
    }, [tabGlobal, globalShoots.data, authState.isLogged]);

    return tab;
}

export default useInfiniteShoots;
