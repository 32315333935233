import React from "react";

//COMPONENTS
import SectionTitle from "components/common/SectionTitle";

//CONTEXTS
// import { AuthProvider } from "context/authContext";

//LAYOUT
import FullLayout from "pages/layouts/Full";

//LIBS
// import { Link } from "react-router-dom";

//STYLES
import "App.css";

//UTILS
import { PageContentFadingAnimation } from 'utils/animations';

function Cgu() {
    return (
        <FullLayout>
            <main>
                <PageContentFadingAnimation duration={225}>
                    <div className="m-auto xl:container">
                        <SectionTitle>
                            <h1 className="h2 mx-0 my-2 mt-4 mb-2">
                                <span className="text-secondary">Conditions générales d'utilisation</span> - Utilisateurs
                            </h1>
                        </SectionTitle>
                    </div>
                    <div className="w-full m-auto xl:container">
                        <div className="content">
                            <div>
                                <div className="articles">
                                    <h2 className="mb-2 mt-4 font-semibold">ARTICLE 1. OBJET</h2>
                                </div>
                            </div>
                            <p>
                                La plate-forme SKILLSMARKET (la « Plate-forme ») a pour vocation de mettre en relation des UTILISATEURS professionnels ou non professionnels (les « UTILISATEURS ») préalablement enregistrés sur le site Internet www.skillsmarket.tv et des SKILLERS, professionnels ou non
                                (les « SKILLERS »), également inscrits sur le site Internet www.skillsmarket.tv afin de permettre aux UTILISATEURS d&apos;acheter aux SKILLERS, par l&apos;intermédiaire de la Plate-forme, des contenus et produits proposés à la vente sur le site Internet
                                www.skillsmarket.tv (les « Produits »).
                            </p>
                            <p>
                                Les présentes Conditions Générales d&apos;Utilisation (les « CGU ») définissent les conditions dans lesquelles la Plate-forme met à la disposition des UTILISATEURS les outils technologiques leur permettant d&apos;acquérir, par l&apos;intermédiaire de la Plate-forme, qui
                                n&apos;est pas le SKILLER, Produits proposés à la vente sur le site Internet www.skillsmarket.tv.
                            </p>
                            <p>
                                Elles complètent les Conditions Générales de Vente qui régissent les ventes réalisées entre les UTILISATEURS et les SKILLERS par l&apos;intermédiaire de la Plate-forme et doivent être acceptées par l’UTILISATEUR pour utiliser les services de la Plate-forme, au moyen
                                d&apos;une validation lors de son enregistrement sur le site Internet www.skillsmarket.tv et à l&apos;occasion de chaque achat réalisé par l&apos;intermédiaire de la Plate-forme.
                            </p>
                        </div>
                        <div>
                            <div className="articles">
                                <h2 className="mb-2 mt-4 font-semibold">ARTICLE 2. CHAMP D&apos;APPLICATION</h2>
                            </div>
                            <p>Les présentes CGU s&apos;appliquent, sans restriction ni réserve à l&apos;utilisation des services et outils technologiques mis à disposition des UTILISATEURS par la Plate-forme.</p>
                            <p>
                                Ces outils permettent aux UTILISATEURS de s&apos;inscrire sur le site Internet www.skillsmarket.tv d&apos;être mis en relation avec les SKILLERS, de commander les Produits, d&apos;en régler le prix, d&apos;en confirmer la réception et de communiquer avec les SKILLERS si
                                nécessaire.
                            </p>
                            <p className="gras">Les ventes effectuées par l&apos;intermédiaire de la Plate-forme sont directement conclues entre l’UTILISATEUR et le SKILLER.</p>
                            <p>Les présentes CGU sont accessibles à tout moment sur le site Internet www.skillsmarket.tv et prévaudront, le cas échéant, sur toute autre version ou tout autre document contradictoire.</p>
                            <p>Ces CGU pouvant faire l&apos;objet de modifications ultérieures, la version applicable à l&apos;achat de l’UTILISATEUR est celle en vigueur sur le site internet à la date de passation de la commande.</p>
                            <p>Les modifications de ces CGU sont opposables aux UTILISATEURS du site Internet www.skillsmarket.tv à compter de leur mise en ligne et ne peuvent s&apos;appliquer aux transactions conclues antérieurement.</p>
                        </div>
                        <div>
                            <div className="articles">
                                <h2 className="mb-2 mt-4 font-semibold">ARTICLE 3. SERVICES OFFERTS PAR LA PLATE-FORME</h2>
                            </div>
                            <p>
                                L&apos;accès la Plate-forme est strictement réservé à des UTILISATEURS s&apos;engageant à effectuer de bonne foi, toutes les opérations d&apos;achat conclues avec les SKILLERS par l&apos;intermédiaire de la Plate-forme.
                                <br />
                                L&apos;utilisation de la Plate-forme est gratuite pour les UTILISATEURS (hors coûts éventuels de connexion facturés par l&apos;opérateur) et sans obligation d&apos;achat.
                            </p>
                            <p>Seul l&apos;achat de Produits à des SKILLERS est payant, selon les conditions prévues aux CGV Plate-forme, que l’UTILISATEUR accepte également lorsqu&apos;il passe une commande par l&apos;intermédiaire de la Plate-forme.</p>
                            <p>Pour utiliser les services de la Plate-forme, l’UTILISATEUR doit accepter les présentes CGU et s&apos;enregistrer sur le site Internet www.skillsmarket.tv.</p>
                            <p>En cas de non-respect des présentes CGU par l’UTILISATEUR, la Plate-forme se réserve le droit d&apos;interrompre de manière temporaire ou définitive ses accès, en suspendant ou en résiliant les présentes sans préavis ni indemnité.</p>
                            <p className="gras">
                                Les éventuelles formations proposées ne sont pas sauf mention contraire des formations certifiantes et sont notamment s’agissant de leur contenu de la seule responsabilité des SKILLERS. La proposition de Produit ne vaut aucunement reconnaissance de leur qualité par
                                SKILLSMARKET.
                            </p>
                            <p className="gras">Le SKILLER a bien évidemment le choix du format du Produit qu’il propose notamment s’agissant des formations ou contenus proposés.</p>
                            <p className="gras">
                                En cas de rencontre d’une offre et d’une demande, un contrat sera conclu directement entre l’UTILISATEUR et le SKILLER. En tout état de cause, SKILLSMARKET n’est pas et ne devient pas partie au contrat, n’est pas impliqué dans la relation contractuelle entre les parties
                                au contrat.
                            </p>
                        </div>
                        <div>
                            <div className="articles">
                                <h2 className="mb-2 mt-4 font-semibold"> ARTICLE 4. OBLIGATION DE CHAQUE UTILISATEUR</h2>
                            </div>
                            <p>L’UTILISATEUR a l’obligation : </p>
                            <ul className="point">
                                <li>de renseigner les différents champs de création du compte et de réservation d’un Produit de bonne foi, et à fournir des données exactes, à jour et complètes ; </li>
                                <li>de procéder au paiement de toutes les sommes dues dans le cadre de la réservation d’une prestation au moyen de la solution STRIPE proposée sur la Plate-forme ;</li>
                                <li>de se rendre disponible au jour et à l’heure de mise en ligne des contenus par le SKILLERS ;</li>
                                <li>de payer les éventuels frais d’annulation.</li>
                            </ul>
                        </div>
                        <div>
                            <div className="articles">
                                <h2 className="mb-2 mt-4 font-semibold">ARTICLE 5. CREATION ET GESTION DES COMPTES</h2>
                            </div>
                            <ol className="lettre" start="1" type="1">
                                <li>Pour pouvoir accéder, réserver ou proposer des Produits l’UTILISATEUR doit s’inscrire et créer un Compte utilisateur et au préalable accepter les présentes CGU.</li>
                            </ol>
                            <div className="marge">
                                <p>Un formulaire d’inscription lui est proposé. Il doit être rempli de bonne foi. Les données qu’ils renseignent doivent être exactes, à jour et complètes.</p>
                                <p>
                                    L’UTILISATEUR n’est autorisé à créer qu’un seul et unique Compte utilisateur. Un UTILISATEUR ne peut pas transférer son compte à quelqu&apos;un d&apos;autre ni utiliser le compte d’un tiers. L’UTILISATEUR porte l&apos;entière responsabilité de son compte et de tout ce
                                    qui s&apos;y passe, y compris de tout préjudice ou dommage causé par un tiers utilisant son compte sans son autorisation.
                                </p>
                                <p>Tout UTILISATEUR doit mettre à jour régulièrement son compte afin que ses informations restent exactes et complètes. </p>
                            </div>
                            <ol className="lettre" start="2" type="1">
                                <li>
                                    Des identifiants et mots de passe strictement confidentiels et personnels sont attribués à l’UTILISATEUR. L’UTILISATEUR inscrit est seul autorisé à utiliser ses identifiants et mot de passe, qu’il s’oblige à conserver confidentiels, sous sa seule responsabilité.
                                    L’UTILISATEUR s’engage à prendre toutes mesures utiles pour en assurer une parfaite confidentialité.
                                </li>
                            </ol>
                            <div className="marge">
                                <p>L’UTILISATEUR s’engage à ne pas communiquer, céder, vendre ou sous-louer son identifiant et/ou son mot de passe à un tiers.</p>
                                <p>L’UTILISATEUR est seul responsable de l&apos;utilisation de ces codes et de l&apos;accès à la Plate-forme, sauf preuve d&apos;une utilisation frauduleuse qui ne pourrait lui être imputée.</p>
                                <p>En cas de perte des identifiants, l’UTILISATEUR est en mesure de les modifier directement via la Plate-forme.</p>
                                <h2 className="mb-2 mt-4 font-semibold">
                                    L’UTILISATEUR est dans l’obligation de signaler sans délai à SKILLSMARKET toute utilisation détournée ou non autorisée de ses identifiants, mot de passe et donc de son Compte Utilisateur dont il aurait connaissance. Cette notification devra être adressée à
                                    SKILLSMARKET par e-mail à l’adresse contact@skillsmarket.fr qui interdira alors tout accès à la Plate-Forme, au moyen de cet identifiant et de ce mot de passe, dans un délai maximum de vingt-quatre (24) heures suivant la réception de cette notification.
                                    </h2>
                            </div>
                            <div className="marge">
                                <p>L’UTILISATEUR est libre de se désinscrire à tout moment.</p>
                            </div>
                            <div>
                                <div>
                                    <div className="articles">
                                        <h2 className="mb-2 mt-4 font-semibold">ARTICLE 6. DIFFUSION DE PRODUITS</h2>
                                    </div>
                                    <p>La Plate-forme propose à ses UTILISATEUR les propositions de Produits des SKILLERS sur la base de plusieurs critères :</p>
                                    <ul className="point">
                                        <li>Format du Contenu; </li>
                                        <li>Matière proposée ; </li>
                                        <li>Prix du Contenu ; </li>
                                        <li>Notation / Avis ;</li>
                                        <li>Taux d’interaction ;</li>
                                        <li>Lieu.</li>
                                    </ul>
                                    <p>
                                        L’insertion et le classNameement des Produits dans les résultats de recherche sur la Plate-forme peuvent varier et dépendre de différents facteurs à savoir les paramètres de recherche de l’UTILISATEUR : la matière, le prix, le format, la Notation/Avis, les
                                        interactions du SKILLER (commentaires, ventes, information/transparence, recherches,….)
                                    </p>
                                </div>
                                <div>
                                    <div className="articles">
                                        <h2 className="mb-2 mt-4 font-semibold">ARTICLE 7. PROCESSUS DE RÉSERVATION DES PRODUITS</h2>
                                    </div>
                                    <p>L’UTILISATEUR souhaitant réserver un Produit se connecte à son compte et renseigne dans la barre de recherche le contenu souhaité, la thématique souhaitée, le format souhaité, le lieu ou le nom du SKILLER. </p>
                                    <p>Une liste de Produits est proposée à l’UTILISATEUR qui peut, s’il le souhaite, ajuster la recherche via plusieurs filtres de recherche. </p>
                                    <p>L’UTILISATEUR sélectionne ensuite le Produit de son choix, réserve le cas échéant une place ou un horaire selon la nature du Produit (live payant , consultation,….) et confirme sa présence à la date et au créneau horaire proposé. </p>
                                    <p>L’UTILISATEUR a la possibilité de vérifier le détail de sa demande de réservation via le récapitulatif de réservation et corriger d’éventuelles erreurs avant de confirmer sa demande. </p>
                                    <p>L’UTILISATEUR renseigne également à ce stade ses coordonnées bancaires. </p>
                                    <p>L’enregistrement de la demande de réservation sur la Plate-forme est réalisé lorsque le SKILLER valide la demande de réservation. </p>
                                    <p>Ainsi</p>
                                    <ul>
                                        <li className="text">
                                            <h2 className="mb-2 mt-4 font-semibold">Pour les Lives / Formations / Consultations / Contenus / Autres Produits</h2>
                                        </li>
                                        <p>
                                            le SKILLER qui reçoit une demande de réservation s’engage à faire ses meilleurs efforts pour répondre dans un délai raisonnable soit au
                                            maximum de quarante-huit (48) heures et confirmer ou refuser la demande de réservation. L’absence de réponse pendant ce délai ne vaut en aucun cas acceptation tacite de la réservation.</p>
                                    </ul>
                                    <ul>
                                        <li className="text">
                                            <h2 className="mb-2 mt-4 font-semibold">Pour les Lives / Formations Consultations / se réalisant dans un délai inférieur à vingt-quatre (24) heures</h2>
                                        </li>
                                    </ul>
                                    <p>
                                        Une réponse immédiate est générée en fonction des places disponibles.<br />
                    Lorsque la demande de réservation est acceptée, l’UTILISATEUR et le SKILLER reçoivent automatiquement un courrier électronique de confirmation.</p>
                                </div>
                                <div>
                                    <div className="articles">
                                        <h2 className="mb-2 mt-4 font-semibold">ARTICLE 8. SYSTÈME DE NOTATION DES SKILLERS / AVIS DES UTILISATEURS</h2>
                                    </div>
                                    <ol start="1" type="1">
                                        <li> La Plate-forme propose un système de notation allant, du niveau le plus bas à savoir une première gradation au niveau (1), et un niveau le plus haut, à un niveau de gradation de (5)</li>
                                    </ol>
                                    <p className="marge2">À l’issue de chaque formation, contenu et après livraison d’un produit, l’UTILISATEUR se voit proposer de noter son SKILLER.</p>
                                    <p className="marge2">Un avis peut être déposé concomitamment. Il est limité en nombre à 300 caractère. Ces avis ne doivent pas être diffamant, injuriant ou blessant.</p>
                                    <ol start="2" type="1">
                                        <li>Un UTILISATEUR peut laisser un avis. Conformément à l’article L 111-7-2 du code de la consommation, chaque avis est publié avec sa date de publication et ses éventuelles mises à jour.</li>
                                    </ol>
                                    <p>L’UTILISATEUR est informé que SKILLSMARKET n’effectue aucune vérification ni aucune modération vis à vis des commentaires publiés par les UTILISATEURS. L’UTILISATEUR est donc tenu d’une obligation de modération et s’engage à être le plus objectif possible.
                                    Néanmoins SKILLSMARKET se réserve le droit de modifier ou supprimer des commentaires pouvant être injurieux, menaçant et /ou incitant à la haine raciale en cas de signalement par un UTILISATEUR à l’adresse
                                        <span className="text-blue-400"> contact@skillsmarket.fr </span>ou en utilisant le bouton signaler prévu à cet effet.</p>
                                    <p className="marge2">
                                        L’UTILISATEUR ne doit pas indiquer son identité et ses coordonnées dans le contenu d’un avis. Les avis sont classNameés selon un ordre antéchronologique, par typologie de mission et sont publiés pendant la durée d’inscription de l’UTILISATEUR sur la Plate-forme.
                                        L’UTILISATEUR est informé que SKILLSMARKET peut réutiliser les avis à des fins promotionnelles (ex : publication en page d'actualités). Le SKILLER pourra également utiliser cet avis. Cet usage ne pourra se faire en tout état de cause qu’en anonymisant le message.{" "}
                                    </p>
                                </div>
                                <div>
                                    <div className="articles">
                                        <h2 className="mb-2 mt-4 font-semibold">ARTICLE 9. PROPRIETE INTELLECTUELLE</h2>
                                    </div>
                                    <ol start="1" type="1">
                                        <li>
                                            <h2 className="mb-2 mt-4 font-semibold">
                                                <em className="text"> PROPRIETE INTELECTUELLE DE SKILLSMARKET</em>
                                            </h2>
                                        </li>
                                    </ol>
                                    <p className="marge2">
                                        Les Services et contenus proposés et publiés par la Plateforme (notamment les informations, données, bases de données, logiciels, API, vidéos, bandes sons, photographies, chartes graphiques, illustrations, logos, dessins, modèles et marques, etc ...) sont et
                                        demeurent la propriété pleine, entière et exclusive de SKILLSMARKET.{" "}
                                    </p>
                                    <p className="marge2">
                                        La Plate-forme est la propriété de SKILLSMARKET et est exploitée par SKILLSMARKET. Sauf indication contraire, tous les contenus, informations et autres éléments sur la Plate-forme ou ses fonctionnalités à l&apos;exclusion du contenu Utilisateur, y compris, sans
                                        s&apos;y limiter, les marques et logos de SKILLSMARKET, les interfaces visuelles, le graphisme, la conception, la compilation, les informations, les logiciels, le code informatique (y compris le code source ou le code objet), les services, les textes, les images,
                                        les données, les fichiers audio, les autres fichiers, …. et la disposition de ceux-ci (collectivement, les « éléments ») sont protégés par les droits de propriété intellectuelle et les lois ainsi que les droits de propriété intellectuelle pertinents. Tous les
                                        éléments sont la propriété de SKILLSMARKET, de ses filiales ou sociétés affiliées, ou de tiers concédants de licence. Sauf indication contraire expresse et écrite de SKILLSMARKET, en acceptant les présentes Conditions d&apos;utilisation, le SKILLERS et
                                        l’UTILISATEUR bénéficie d&apos;une licence limitée et non cessible (c&apos;est-à-dire un droit personnel et limité) pour accéder aux services de la Plateforme et les utiliser pour un usage personnel ou professionnel interne uniquement.
                                    </p>
                                    <p className="marge2">
                                        SKILLSMARKET se réserve tous les droits qui ne sont pas expressément accordés dans les présentes Conditions d&apos;utilisation. Cette licence est soumise aux présentes Conditions générales d&apos;utilisation et ne vous permet pas de vous engager dans l&apos;une ou
                                        l&apos;autre des activités suivantes : (a) la revente ou l&apos;utilisation commerciale de la Plate-forme, de ses fonctionnalités ou de leurs éléments ; (b) la distribution, la représentation publique ou l&apos;affichage public des éléments ; (c) la modification
                                        ou toute autre utilisation dérivée de la Plateforme ou des éléments, ou toute partie de ceux-ci ; (d) l&apos;utilisation de toute méthode d&apos;extraction de données, robot ou méthode similaire de collecte ou d&apos;extraction de données ; (e) le téléchargement
                                        (à l&apos;exception des téléchargements autorisés et payant) de toute partie des services de la Plate-forme, des éléments ou de toute information qu&apos;ils contiennent, sauf dans les cas expressément autorisés sur la Plate-forme ; ou (f) toute utilisation de la
                                        Plate-forme applicative ou des éléments, sauf aux fins prévues. Toute utilisation de la Plate-forme ou des éléments, à l&apos;exception de ce qui est expressément autorisé dans les présentes Conditions d&apos;utilisation, sans l&apos;autorisation écrite préalable
                                        du Prestataire, est strictement interdite et peut constituer une atteinte aux droits de propriété intellectuelle ou à d&apos;autres lois. Sauf mention expresse dans les présentes Conditions générales d&apos;utilisation, rien dans celles-ci ne doit être interprété
                                        comme conférant une licence de droits de propriété intellectuelle, que ce soit par préclusion, implication ou autres principes juridiques. SKILLSMARKET peut résilier cette licence, comme décrit aux articles résiliations prévues au présente ou dans les CGVs.
                                    </p>
                                    <ol start="2" type="1">
                                        <li className="text">
                                            <h2 className="mb-2 mt-4 font-semibold">
                                                <em> PROPRIETE INTELECTUELLE DU SKILLER</em>
                                            </h2>
                                        </li>
                                    </ol>
                                    <p className="marge2">
                                        Le SKILLER reste propriétaire de ses contenus (contenus, formation). Une licence limitée, non exclusive, non transférable est accordée par le SKILLER à l’UTILISATEUR ayant acquitté les frais et prix convenus. Cette licence ne peut être utilisée qu’à des fins
                                        personnelles, non commerciales et pédagogiques via la Plate-forme, conformément à ses Conditions générales d’utilisation et à toute condition ou restriction associée à un Produit.{" "}
                                    </p>
                                    <p className="marge2">
                                        Les autres utilisations sont strictement interdites. L’UTILISATEUR ne peut pas reproduire, redistribuer, transmettre, céder, vendre, diffuser, louer, partager, prêter, modifier, adapter, éditer, ou autrement transférer ou utiliser un quelconque cours ni créer des
                                        œuvres dérivées ou accorder une sous-licence de celui-ci sans avoir reçu une autorisation explicite du SKILLER, ou le cas échéant de la Plate-forme sous forme écrite et signée par un représentant habilité.
                                    </p>
                                    <p className="marge2">L’UTILISATEUR n’acquiert aucunement la propriété d’un Produit notamment d’un contenu, d’une formation, d’une consultation ou d’un accessoire de ce Produit (script, exercice, ou de cette formation). </p>
                                    <p className="marge2">
                                        L’UTILISATEUR ne peut revendre le cours de quelque manière que ce soit (y compris en partageant les informations du compte avec un tiers ou en téléchargeant illégalement le cours et en le partageant ensuite sur des sites de téléchargement illégal.)
                                    </p>
                                </div>
                                <div>
                                    <div className="articles">
                                        <h2 className="mb-2 mt-4 font-semibold">ARTICLE 10. RESPONSABILITES</h2>
                                    </div>
                                    <p>
                                        Il est rappelé que la Plate-forme n&apos;est pas le vendeur des Produit. Elle ne peut donc en aucun cas être tenue pour responsable des ventes ou autres opérations réalisées entre les UTILISATEURS et les SKILLERS, auxquelles elle demeure étrangère. Les Produits ne
                                        peuvent être ni repris ni échangés par la Plate-forme et toute contestation relative à une opération notamment de vente effectuée par l&apos;intermédiaire de la Plate-forme doit être directement réglée entre l’UTILISATEUR et le SKILLER.
                                    </p>
                                    <p>La responsabilité de la Plate-forme à l&apos;égard de l’UTILISATEUR ne peut être engagée que pour des faits qui lui seraient directement imputables et qui causeraient à l’UTILISATEUR un préjudice direct, à l&apos;exclusion de tout préjudice indirect.</p>
                                    <p>La responsabilité de la Plate-forme est exclue en cas de mauvaise utilisation des services de la Plate-forme par l’UTILISATEUR ou de faute de sa part. Elle ne saurait pas plus être engagée à raison de faits imputables à un tiers.</p>
                                    <p>
                                        Conformément à la réglementation en vigueur, la responsabilité de la Plate-forme ne peut être engagée ni retenue en raison des contenus rendus disponibles sur le site Internet www.skillsmarket.tv notamment en ce qui concerne la description des Produits, sauf si
                                        elle ne les rendait pas promptement inaccessibles après avoir été informée de leur illicéité dans les conditions prévues par cette loi.
                                    </p>
                                    <p>
                                        L’UTILISATEUR est seul responsable de l&apos;utilisation des services de la Plate-forme, notamment des appréciations qu&apos;il fait sur le site Internet www.skillsmarket.tv, et s&apos;engage à garantir à première demande, à indemniser et dédommager la Plate-forme
                                        de tout dommage, perte, manque à gagner, qu&apos;elle pourrait subir si sa responsabilité se trouvait engagée par un tiers, du fait d&apos;une action liée à cette utilisation par l’UTILISATEUR.
                                    </p>
                                    <div className="articles">
                                        <h2 className="mb-2 mt-4 font-semibold">ARTICLE 11. NULLITE PARTIELLE</h2>
                                    </div>
                                    <p>
                                        Si une ou plusieurs stipulations des présentes sont tenues pour non valides ou déclarées comme telles en application d&apos;une loi, d&apos;un règlement ou d&apos;une décision définitive d&apos;une juridiction compétente, les autres stipulations garderont toute
                                        leur force et toute leur portée.
                                    </p>
                                    <div className="articles">
                                        <h2 className="mb-2 mt-4 font-semibold">ARTICLE 13. DROIT APPLICABLE</h2>
                                    </div>
                                    <p>Les présentes Conditions générales d&apos;utilisation et les opérations qui en découlent sont régies par le droit français.</p>
                                    <p>Elles sont rédigées en langue française. Dans le cas où elles seraient traduites en une ou plusieurs langues, seul le texte français ferait foi en cas de litige.</p>
                                </div>
                                <div>
                                    <h2 className="mb-2 mt-4 font-semibold">
                                        ARTICLE 14. INFORMATION PRECONTRACTUELLE - ACCEPTATION DU UTILISATEUR
                                    </h2>
                                    <ol start="1" type="1">
                                        <li className="text">
                                            <h2 className="mb-2 mt-4 font-semibold"> Acceptation de l’UTILISATEUR</h2>
                                        </li>
                                    </ol>
                                    <p className="marge2">
                                        Le fait pour une personne physique (ou morale), de commander sur le site Internet www.skillsmarket.tv emporte adhésion et acceptation pleine et entière des présentes Conditions Générales de Vente, ce qui est expressément reconnu par l’UTILISATEUR, qui renonce,
                                        notamment, à se prévaloir de tout document contradictoire qui serait inopposable à la Plate-forme.
                                    </p>
                                    <ol start="2" type="1">
                                        <li className="text">
                                            <h2 className="mb-2 mt-4 font-semibold"> Identification</h2>
                                        </li>
                                    </ol>
                                    <p className="marge2">Nos informations de contacts sont les suivants : </p>
                                    <ul>
                                        <li>
                                            <a href="http://www.skillsmarket.fr/">www.skillsmarket</a>
                                        </li>
                                        <li>Désigne la société SKILLSMARKET SAS</li>
                                        <li>{"Adresse du siège social : 1 place de l'homme de fer: 67000 STRASBOURG"}</li>
                                        <li>Capital social : cent euros</li>
                                        <li>Numéro RCS : 892 787 821 </li>
                                        <li>Numéro de TVA intracommunautaire : FR57892787821</li>
                                        <li>Adresse E-mail : contact@skillsmarket.fr</li>
                                    </ul>
                                    <div className="articles">
                                        <h2 className="mb-2 mt-4 font-semibold">ARTICLE 15. LITIGES</h2>
                                    </div>
                                    <p>Tout litige relatif à l&apos;interprétation et/ou à l&apos;exécution des présentes CGU relève des juridictions françaises.</p>
                                    <p>
                                        Les réclamations doivent être adressées sur le site Internet{" "}
                                        <a href="http://www.skillsmarket.fr/">
                                            <em>www.skillsmarket</em>
                                        </a>
                                    </p>
                                    .<p>Il est rappelé que tout litige ou toute réclamation concernant la vente, le descriptif ou la livraison des Produits doit être réglée directement entre l’UTILISATEUR et le SKILLER.</p>
                                    <p>
                                        L’UTILISATEUR est informé qu&apos;il peut en tout état de cause recourir à une médiation conventionnelle, notamment auprès de la Commission de la médiation de la consommation (art. L 612-1 C. consom.) ou auprès des instances de médiation sectorielles existantes,
                                        et dont les références figurent sur le site Internet www.skillsmarket.tv ou à tout mode alternatif de règlement des différends (conciliation, par exemple) en cas de contestation.
                                    </p>
                                    <p>
                                        L’UTILISATEUR, constatant qu&apos;une violation au règlement général sur la protection des données personnelles aurait été commise, a la possibilité de mandater une association ou un organisme mentionné au IV de l&apos;article 43 ter de la loi informatique et
                                        liberté de 1978, afin d&apos;obtenir contre le responsable de traitement ou sous-traitant, réparation devant une juridiction civile ou administrative ou devant la commission nationale de l&apos;informatique et des libertés.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </PageContentFadingAnimation>

                <PageContentFadingAnimation duration={225}>
                    <div className="m-auto xl:container">
                        <SectionTitle>
                            <h1 className="h2 mx-0 my-2 mt-4 mb-2">
                                <span className="text-secondary">Conditions générales d'utilisation</span> - Skillers
                            </h1>
                        </SectionTitle>
                    </div>
                    <div className="w-full m-auto xl:container">
                        <div className="content">
                            <div className="articles">
                                <h2 className="mb-2 mt-4 font-semibold">ARTICLE 1. OBJET</h2>
                            </div>

                            <p>
                                La plateforme skillsmarket.tv (la <em>« Plate-forme »</em>) a pour vocation de mettre en relation des utilisateurs professionnels ou non professionnels (les <em>« UTILISATEURS »</em>) préalablement enregistrés sur le site Internet www.skillsmarket.tv et des professionnels
                                ou non (les <em>« SKILLERS »</em>), également inscrits sur le site Internet www.skillsmarket.tv afin de permettre aux UTILISATEURS d&apos;acheter aux SKILLERS, par l&apos;intermédiaire de la Plate-forme, des prestations (formation, conseil, live,…) et des produits neufs
                                ou d&apos;occasion proposés à la vente sur le site Internet www.skillsmarket.tv (les <em>« Produits »).</em>
                            </p>

                            <p>
                                Les présentes Conditions Générales d&apos;Utilisation (les « CGU ») définissent les conditions dans lesquelles la Plate-forme met à la disposition des SKILLERS les outils technologiques leur permettant de vendre ou de mettre à disposition gratuitement les Produits aux
                                Utilisateurs, tels que présentés sur le site Internet www.skillsmarket.tv par l&apos;intermédiaire de la Plate-forme.
                            </p>

                            <p>
                                Elles complètent les Conditions Générales de Vente qui régissent les ventes réalisées entre les UTILISATEURS et les SKILLERS par l&apos;intermédiaire de la Plate-forme et doivent être acceptées par le SKILLER pour utiliser les services de la Plate-forme, au moyen
                                d&apos;une validation lors de son enregistrement sur le site Internet SKILLSMARKET et à l&apos;occasion de chaque achat réalisé par un UTILISATEUR par l&apos;intermédiaire de la Plate-forme.
                            </p>

                            <div>
                                <div>
                                    <div className="articles">
                                        <h2 className="mb-2 mt-4 font-semibold">ARTICLE 2. CHAMP D&apos;APPLICATION</h2>
                                    </div>

                                    <p>Les présentes CGU s&apos;appliquent, sans restriction ni réserve, à l&apos;utilisation des services et outils technologiques mis à disposition des SKILLERS par la Plate-forme.</p>

                                    <p>
                                        Ces outils permettent aux SKILLERS de s&apos;inscrire sur le site Internet www.skillsmarket.tv d&apos;être mis en relation avec les UTILISATEURS, de référencer et de décrire les Formations, Contenus et Produits pédagogiques qu&apos;ils proposent à la vente sur le
                                        site Internet www.skillsmarket.tv d&apos;accepter et d&apos;enregistrer leurs commandes de Produits, d&apos;en percevoir le prix et de communiquer avec les UTILISATEURS si nécessaire.
                                    </p>

                                    <p className="gras">Les ventes effectuées par l&apos;intermédiaire de la Plate-forme sont directement conclues entre l’UTILISATEUR et le SKILLER.</p>

                                    <p>Les présentes CGU sont accessibles à tout moment sur le site Internet www.skillsmarket.tv et prévaudront, le cas échéant, sur toute autre version ou tout autre document contradictoire.</p>

                                    <p>Ces CGU pouvant faire l&apos;objet de modifications ultérieures, la version applicable à une transaction effectuée avec un UTILISATEUR est celle en vigueur sur le site internet à la date de passation de la commande.</p>

                                    <p>Les modifications de ces CGU sont opposables aux utilisateurs du site Internet www.skillsmarket.tv à compter de leur mise en ligne et ne peuvent s&apos;appliquer aux transactions conclues antérieurement.</p>
                                </div>
                                <div>
                                    <div className="articles">
                                        <h2 className="mb-2 mt-4 font-semibold">ARTICLE 3. SERVICES OFFERTS PAR LA PLATE-FORME</h2>
                                    </div>

                                    <p>Pour utiliser les services de la Plate-forme, le SKILLER doit accepter les présentes CGU et s&apos;enregistrer sur le site Internet www.skillsmarket.tv.</p>

                                    <p>Des identifiants et mots de passe strictement confidentiels et personnels lui sont alors attribués.</p>

                                    <p>
                                        Le SKILLER s&apos;engage à conserver la confidentialité de ses codes et s&apos;interdit de les divulguer à quelque personne que ce soit. Le SKILLER est seul responsable de l&apos;utilisation de ses codes et de l&apos;accès à la Plate-forme, sauf preuve d&apos;une
                                        utilisation frauduleuse qui ne pourrait lui être imputée.
                                    </p>

                                    <p>Toute utilisation frauduleuse de ces codes dont aurait connaissance le SKILLER doit immédiatement être notifiée par écrit à la Plate-forme.</p>

                                    <p>En cas de non-respect des présentes CGU par le SKILLER, la Plate-forme se réserve le droit d&apos;interrompre de manière temporaire ou définitive ses accès, en suspendant ou en résiliant les présentes sans préavis ni indemnité.</p>

                                    <p>
                                        Le fait pour un SKILLER, de s&apos;enregistrer sur le site Internet www.skillsmarket.tv emporte adhésion et acceptation pleine et entière des présentes CGU, ce qui est expressément reconnu par le SKILLER, qui renonce, notamment, à se prévaloir de tout document
                                        contradictoire, qui serait inopposable à la Plate-forme.
                                    </p>
                                </div>
                                <div>
                                    <div className="articles">
                                        <h2 className="mb-2 mt-4 font-semibold">ARTICLE 4. OBLIGATIONS DES SKILLERS DANS LE CADRE DE LA VENTE DE FORMATIONS, CONTENUS ET PRODUITS PEDAGOGIQUES</h2>
                                    </div>

                                    <h2 className="mb-2 mt-4 font-semibold">1.</h2>Les contrats de vente (ou de prestation) des Produits proposés par le SKILLER sur le site Internet www.skillsmarket.tv sont conclus exclusivement entre le SKILLER et l’UTILISATEUR.

                                    <p>Les SKILLERS s&apos;engagent à mettre en œuvre, de bonne foi, tous les moyens nécessaires afin de remplir leurs obligations en délivrant des Produits et un service de qualité aux Utilisateurs.</p>

                                    <p>
                                        Les SKILLERS professionnels, qui sont tenus de s&apos;identifier comme tels auprès des UTILISATEURS sur le site Internet www.skillsmarket.tv s&apos;engagent à respecter la législation applicable en matière d&apos;exercice d&apos;une activité commerciale (notamment
                                        immatriculation, obligations comptables, sociales et fiscales) ainsi que les lois et règlements applicables aux Produits vendus par l&apos;intermédiaire du site Internet www.skillsmarket.tv.
                                    </p>

                                    <h2 className="mb-2 mt-4 font-semibold">2. </h2>Les SKILLERS déclarent être propriétaires des Produits (et notamment de leur contenu) proposés gratuitement ou à la vente sur le site Internet www.skillsmarket.tv ou être dûment autorisés à le vendre.

                                    <h2 className="mb-2 mt-4 font-semibold">
                                        Les SKILLERS s&apos;interdisent de proposer notamment à la vente des Produits, des Services, des Supports de formations, ou tout autre documents, visuels, etc… contrefaisants au sens du Code la propriété intellectuelle ou tout Produit dont la commercialisation
                                        est réglementée en vertu de dispositions législatives, réglementaires ou contractuelles interdisant ou restreignant la vente des Produits par l&apos;intermédiaire de la Plate-forme.
                                        </h2>

                                    <p>Les SKILLERS sont seuls responsables de la mise en vente ou en ligne des Produits qu&apos;ils proposent sur le site Internet www.skillsmarket.tv.</p>

                                    <h2 className="mb-2 mt-4 font-semibold">3. </h2>Les SKILLERS s&apos;engagent à établir, de bonne foi, des descriptifs précis, exacts et ne risquant pas d&apos;induire en erreur les UTILISATEURS des Produits proposés par l&apos;intermédiaire de la Plate-forme, tant
                                        en ce qui concerne leurs caractéristiques essentielles (pour les prestations notamment les formations ou live<em> :</em> nature de l’action, intitulé, objectif pédagogique et contenu de l’action, moyens prévus pour la réalisation de l’action, durée et période de
                                        réalisation, modalités de déroulement, prix ; pour les produits : origine, composition, accessoires, conditions de mise en service, pays d&apos;expédition, etc.), que le prix et les garanties accordées ou l&apos;état des Produits s&apos;il s&apos;agit de produits
                                        d&apos;occasion. Les descriptifs doivent également mentionner les délais de livraison et les conditions d&apos;expédition.

                                    <p>Ils garantissent l&apos;exactitude des mentions figurant sur les descriptifs de Produits mis en ligne sur le site Internet SKILLSMARKET.</p>

                                    <p>Les visuels, notamment devront être conformes aux Produits mis en vente ou présenter et respecter les droits des tiers, les SKILLERS garantissant à la Plate-forme qu&apos;ils disposent de tous les droits nécessaires pour utiliser ces éléments de description.</p>

                                    <p>
                                        Les SKILLERS s&apos;interdisent, notamment, d&apos;utiliser des visuels ou autres éléments descriptifs ou de tenir des propos ou autres messages qui seraient injurieux, contraires à l‘ordre public ou aux bonnes mœurs, qui porteraient atteinte aux droits de
                                        personnes ou aux droits de propriété intellectuelle de tiers, aux lois et règlements et à l&apos;image de marque de la Plate-forme, qui pourrait alors, interrompre de manière temporaire ou définitive l&apos;accès à ses services, en suspendant ou en résiliant
                                        ceux-ci dans les conditions prévues à l&apos;article <em>“Résiliation de l&apos;accès aux services de la Plate-forme”</em>.
                                    </p>

                                    <h2 className="mb-2 mt-4 font-semibold">4. </h2>Les SKILLERS s&apos;engagent à s&apos;assurer de la disponibilité des Produits qu&apos;ils proposent à la vente ou gratuitement par l&apos;intermédiaire de la Plate-forme, particulièrement pour les formations, lives
                                        …. à être ponctuel et respecter les sessions convenues, et à supprimer immédiatement les Produits qui ne sont plus disponibles. Les SKILLERS sont seuls responsables en cas d&apos;indisponibilité des Produits dont ils ont seuls la maîtrise.

                                    <p>Ils s’engagent à réaliser les sessions garanties conformément à leurs engagements prévus aux CGVs. Ils reconnaissent que toutes non tenues de formation préjudicierait à l’image de la Plate-forme et donc de la société SKLLSMARKET SAS.</p>

                                    <p>
                                        Les SKILLERS, qui sont informés des commandes des UTILISATEURS par la Plate-forme, doivent confirmer la commande concernée dans un délai de quarante-huit (48) heures suivant la réception de l&apos;information visée à
                                        l&apos;alinéa précédent. A défaut de réponse dans ce délai, la vente est résiliée dans les conditions prévues aux CGVs.
                                    </p>

                                    <p>La confirmation de la commande par le SKILLER implique l&apos;engagement ferme de fournir les Produits commandés.</p>

                                    <h2 className="mb-2 mt-4 font-semibold">5. Le prix de vente des Produits est librement défini par les SKILLERS, dans le respect des lois et règlements en vigueur.</h2>

                                    <p>Ce prix doit être mentionné sur le site Internet www.skillsmarket.tv toutes taxes et frais compris (notamment TVA, frais d&apos;emballage, eco-taxe etc.). </p>

                                    <h2 className="mb-2 mt-4 font-semibold">
                                        Les frais de livraison seront mis à la charge du client selon les dispositions figurant dans l’offre du SKILLERS. Elles devront y être affichées clairement par le SKILLERS. A défaut, le SKILLER pourrait être considéré comme devoir les supporter pleinement.{" "}
                                    </h2>
                                    <h2 className="mb-2 mt-4 font-semibold">6. </h2>

                                    <h2 className="mb-2 mt-4 font-semibold">Pour les services proposés : </h2>Les modalités de fournitures suivantes des services peuvent être proposées aux Utilisateurs par les SKILLERS :

                                        ● <h2 className="mb-2 mt-4 font-semibold">Live Streaming interactif : </h2> Une date et un horaire sont fixés par le SKILLERS. Il renseigne également : le nom du Live, sa description et les cas échéant les produits attachés, des illustrations, le nombre de places
                                        disponibles et le prix.

                                        ● <h2 className="mb-2 mt-4 font-semibold">Visioconsultation à la demande : </h2> Les Utilisateurs peuvent demander un créneau privé au SKILLER moyennant un tarif à la demi-heure. Le SKILLER pourra lui confirmer sa disponibilité.{" "}

                                        ● <h2 className="mb-2 mt-4 font-semibold">Prestations : </h2>Un SKILLER peut référencer toutes les prestations qu’ils proposent sur le site sur des pages dédiés. Il renseigne pour chaque prestation proposée : le type de prestation, le nom de la prestation, le lieu
                                        de réalisation, la durée, les modalités de réalisation, le public concerné, l’objectif du contenu et le cas échéant la date et l’heure convenu, le nombre de place disponibles, ses tarifs (le cas échéant la nécessité d’un devis préalable), des illustrations par
                                        vidéo ou image et un tag.{" "}

                                    <p>Les SKILLERS s&apos;obligent à respecter le mode de fourniture convenu avec les UTILISATEURS.</p>

                                    <p>La Plate-forme ne saurait être responsable d’aucune façon de services vendus hors de la Plate-forme.</p>

                                    <p>La Plate-forme s&apos;engage à communiquer aux SKILLERS, dans un délai raisonnable à compter de la passation de la commande de l’UTILISATEUR, toutes les informations leur permettant de procéder à la fourniture des services commandés.</p>

                                    <p>Une facture d’achat doit être adressée à l’Utilisateur par le SKILLER (ou tout autre document permettant à l’UTILISATEUR de faire jouer la garantie) s&apos;il s&apos;agit d&apos;un SKILLER professionnel.</p>

                                    <h2 className="mb-2 mt-4 font-semibold">Pour les produits vendus :</h2>

                                    <p>Les modalités de livraison suivantes doivent être proposées aux UTILISATEURS par les SKILLERS : livraison par un tiers, mise à disposition du produit au siège du SKILLER.</p>

                                    <p>Les SKILLERS s&apos;obligent à respecter le mode de livraison sélectionné ou convenu avec les UTILISATEURS. </p>

                                    <p>La Plate-forme ne saurait être responsable d’aucune façon des produits vendus hors de la Plate-forme.</p>

                                    <p>La Plate-forme s&apos;engage à communiquer aux SKILLERS, dans un délai raisonnable à compter de la passation de la commande de l’UTILISATEUR, toutes les informations leur permettant de procéder à la fourniture des Produits commandés.</p>

                                    <p>Les SKILLERS s&apos;engagent à expédier les produits commandés dans un délai de quarante (40) jours à compter de la confirmation de la commande du Produit.</p>

                                    <p>Un bon de livraison doit être adressé à l’UTILISATEUR par le SKILLER, ainsi qu&apos;une facture d&apos;achat (ou tout autre document permettant à l’UTILISATEUR de faire jouer la garantie) s&apos;il s&apos;agit d&apos;un SKILLER professionnel.</p>

                                    <p>
                                        Les produits sont expédiés aux UTILISATEURS aux frais et risques du SKILLER, qui s&apos;engage à apporter ou à faire apporter tout le soin nécessaire à leur conditionnement, emballage et expédition, afin d&apos;éviter que les Produits ne soient endommagés durant
                                        le transport.
                                    </p>
                                </div>
                                <div>
                                    <div className="articles">
                                        <h2 className="mb-2 mt-4 font-semibold">ARTICLE 5. RÉMUNÉRATION DES SERVICES DE LA PLATE-FORME</h2>
                                    </div>

                                    <p>
                                        En rémunération de ses services de mise en relation et de la mise à disposition des outils technologiques nécessaires à cet effet, la Plate-forme percevra, de la part du SKILLER, pour chaque commande de Produits effectuée par un UTILISATEUR sur le site Internet
                                        une commission égale à un pourcentage du montant du prix de vente, (frais de port inclus et TVA en sus), calculée comme suit : 8 % du montant de la vente.
                                    </p>

                                    <p>Il est rappelé que la Plate-forme encaisse le prix de vente des Produits commandés, au nom et pour le compte des SKILLERS.</p>

                                    <p>Le montant du prix de vente est ensuite reversé par la Plate-forme au SKILLER, déduction faite de la commission, après confirmation par l’UTILISATEUR de la bonne réception des Produits commandés.</p>

                                    <p>Le règlement est effectué dans les conditions suivantes : </p>

                                    <p>
                                        A défaut de confirmation de la réception du Produit par l’UTILISATEUR et en l&apos;absence de contestation portée à la connaissance du SKILLER dans les délais requis, le Produit sera réputé avoir été reçu et le règlement sera effectué au profit du SKILLER dans un
                                        délai de 30 jours ouvrés au plus à compter du débit du compte bancaire de l’UTILISATEUR.
                                    </p>

                                    <p>La Plate-forme se réserve le droit de suspendre le paiement des sommes dues au SKILLER en cas de manquement à ses obligations.</p>

                                    <p>
                                        Le SKILLER dispose, en cas d’erreur sur une facture émise par SKILLSMARKET à son égard, d’un délai de trente (30) jours à compter de la réception de la facture pour en demander la correction. Cette demande peut s’effectuer par écrit ou par tout moyen à disposition
                                        du SKILLER (lettre ou message électronique). Après vérification par SKILLSMARKET, et en cas d’erreur avérée, un avoir de régularisation sera émis par SKILLSMARKET. Les litiges relatifs à un enlèvement, une livraison ou à une facturation ne sont en aucun cas
                                        suspensifs du paiement des autres enlèvements, livraisons et facturations.
                                    </p>
                                </div>
                                <div>
                                    <div className="articles">
                                        <h2 className="mb-2 mt-4 font-semibold">ARTICLE 6. CONTESTATIONS RELATIVES AUX FORMATIONS, CONTENUS ET PRODUITS PÉDAGOGIQUES</h2>
                                    </div>

                                    <p>Les UTILISATEURS peuvent, à tout moment, contacter les SKILLERS et leur adresser toutes réclamations concernant les Produits commandés en utilisant l&apos;outil de messagerie mis à disposition à cet effet par la Plate-forme.</p>

                                    <p>Les UTILISATEURS sont tenus de signaler, dans un délai de quarante-huit (48) heures au plus à compter du débit de leur compte bancaire, toute réclamation concernant les Produits commandés.</p>

                                    <p>Les ventes étant directement conclues entre l’UTILISATEUR et le SKILLER, celui-ci fait son affaire personnelle des réclamations des UTILISATEURS.</p>

                                    <p>Les SKILLERS doivent privilégier la résolution amiable des litiges.</p>

                                    <p>Si la contestation est valable, le SKILLER est tenu de rembourser ou de renvoyer à l’UTILISATEUR les Produits commandés.</p>

                                    <p>Il est rappelé que, conformément à la réglementation en vigueur, la Plate-forme propose aux Utilisateurs un service de médiation pour les litiges de consommation liés aux transactions réalisées par l&apos;intermédiaire du site Internet SKILLSMARKET.</p>

                                    <p>Les frais de médiation sont à la charge du SKILLER.</p>

                                    <p>
                                        En cas d&apos;exercice de son droit de rétractation par l’UTILISATEUR, la Plate-forme procède au remboursement, selon le même mode de paiement que celui utilisé lors de l&apos;achat, des sommes versées (y compris les frais de livraison) au plus tard dans les 14
                                        jours suivant la date à laquelle l’UTILISATEUR a exercé son droit de rétractation.
                                    </p>

                                    <p>Ce montant sera ensuite déduit des sommes dues au SKILLER par la Plate-forme.</p>
                                </div>
                                <div>
                                    <div className="articles">
                                        <h2 className="mb-2 mt-4 font-semibold">ARTICLE 7. INFORMATIONS POUR LES SKILLERS</h2>
                                    </div>

                                    <p>
                                        En qualité de plateforme de mise en relation, en application des dispositions de l’article 242 bis du Code Général des Impôts et de l’article L. 114-19-1 du Code de la Sécurité Sociale, la Plate-forme a l’obligation d’informer ses UTILISATEURS sur leurs
                                        obligations fiscales et sociales résultant des contrats conclus entre SKILLERS et UTILISATEURS.{" "}
                                    </p>

                                    <p>À l’occasion de la conclusion de chaque contrat, la Plate-forme communique aux SKILLERS les informations relatives : </p>

                                    <p className="marge1">● aux régimes fiscaux et à la réglementation sociale applicables aux sommes que le SKILLER perçoit au titre de la prestation de Contenu ;</p>

                                    <p className="marge1">● aux obligations déclaratives et de paiement qui en résultent auprès des administrations fiscales et des organismes de recouvrement des cotisations sociales ;</p>

                                    <p className="marge1">● aux sanctions encourues en cas de manquement à ces obligations. </p>

                                    <p>En janvier de chaque année, la Plate-forme adresse à chaque SKILLER un document récapitulant le montant brut des transactions dont il a connaissance et que le SKILLER a perçu par l’intermédiaire de la Solution au cours de l’année précédente. </p>

                                    <p>
                                        Enfin, la Plate-forme doit faire certifier chaque année, avant le 15 mars, le respect au titre de l’année précédente de ses obligations d’information rappelées au présent article, par un commissaire aux comptes, un cabinet d&apos;audit ou toute autre entité,
                                        personne physique ou morale, ayant son siège sur le territoire de l&apos;Union européenne et respectant une méthode d&apos;audit assurant un examen impartial et exhaustif.{" "}
                                    </p>

                                    <p className="marge1">● En cas de dépassement du seuil fiscal soit mille deux cent quinze (1215€)le SKILLER s’engage à déclarer ses revenus.</p>
                                </div>
                                <div>
                                    <div className="articles">
                                        <h2 className="mb-2 mt-4 font-semibold">ARTICLE 8. RÉSILIATION DE L&apos;ACCES AUX SERVICES DE LA PLATE-FORME</h2>
                                    </div>

                                    <p>Les SKILLERS peuvent cesser d&apos;accéder aux services de la Plate-forme, sans avoir à justifier d&apos;un motif particulier, et sans préavis mais sous réserve stricte d&apos;honorer les commandes en cours.</p>

                                    <p>
                                        En cas de manquement à l&apos;une quelconque de ses obligations par le SKILLER des présentes CGU, la Plate-forme pourra, après mise en demeure envoyée par tout moyen resté sans effet pendant une durée de cinq (5) jours ouvrés, couper définitivement l&apos;accès à
                                        ses services au SKILLER, par lettre recommandée avec demande d&apos;avis de réception, sans que le SKILLER puisse prétendre à une quelconque indemnité.
                                    </p>

                                    <p>
                                        En cas de manquement grave et/ou répété par le SKILLER à ses obligations ou affectant ses comptes, tel que notamment, fraude, propos injurieux, contrefaçon, atteinte aux droits des tiers, etc., la Plate-forme pourra priver le SKILLER de tout accès à ses services,
                                        sans que le SKILLER puisse prétendre à une quelconque indemnité.
                                    </p>
                                </div>
                                <div>
                                    <div className="articles">
                                        <h2 className="mb-2 mt-4 font-semibold">ARTICLE 9. PROTECTION DES DONNEES PERSONNELLES</h2>
                                    </div>

                                    <p>
                                        En application de la loi 78-17 du 6 janvier 1978 modifiée par la loi n°2018-493 du 20 juin 2018, il est rappelé que les données nominatives demandées au SKILLER par la Plate-forme sont nécessaires, tant pour lui garantir l&apos;accès aux services de la Plate-forme
                                        que pour le traitement des commandes de Produits.
                                    </p>

                                    <p>La Plate-forme s&apos;engage à assurer la sécurité des données à caractère personnel qu&apos;elle conserve pour les besoins de la réalisation et du suivi des commandes.</p>

                                    <p>
                                        Le traitement des informations communiquées par l&apos;intermédiaire du site internet www.skillsmarket.tv répond aux exigences légales en matière de protection des données personnelles, le système d&apos;information utilisé assurant une protection optimale de ces
                                        données.
                                    </p>

                                    <p>
                                        Le SKILLER dispose, conformément aux réglementations nationales et européennes en vigueur d&apos;un droit d&apos;accès permanent, de modification, de rectification, d&apos;opposition de portabilité et de limitation du traitement s&apos;agissant des informations le
                                        concernant.
                                    </p>

                                    <p>Ce droit peut être exercé dans les conditions et selon les modalités définies sur le site internet www.skillsmarket.tv.</p>

                                    <p>
                                        Il est rappelé que les SKILLERS ont connaissance des données des Utilisateurs relatives aux commandes de Produits collectées par la Plate-forme. Les UTILISATEURS sont informés par celle-ci que ces données et en particulier les informations nécessaires à la
                                        livraison des Produits sont transférées, à cette seule fin, aux SKILLERS, qui s&apos;engagent à n&apos;utiliser ces données que pour les besoins de la livraison des Produits et pour leur permettre de s&apos;acquitter des obligations légales qui s&apos;y attachent.
                                        Ils s&apos;engagent à n&apos;en conserver aucune copie dès qu&apos;ils auront procédé à l&apos;expédition des Produits commandés, toute autre utilisation étant strictement interdite.
                                    </p>
                                </div>
                                <div>
                                    <div className="articles">
                                        <h2 className="mb-2 mt-4 font-semibold">ARTICLE 10. PROPRIÉTÉ INTELLECTUELLE</h2>
                                    </div>

                                    <ol start="1" type="1">
                                        {" "}
                                        <li>
                                            <h2 className="mb-2 mt-4 font-semibold">
                                                <em>PROPRIÉTÉ INTELLECTUELLE DE SKILLSMARKET</em>
                                            </h2>
                                        </li>
                                    </ol>
                                    <p>
                                        Les Services et contenus proposés et publiés par la Plate-forme (notamment les informations, données, bases de données, logiciels, API, vidéos, bandes sons, photographies, chartes graphiques, illustrations, logos, dessins, modèles et marques, etc ...) sont et
                                        demeurent la propriété pleine, entière et exclusive de SKILLSMARKET.{" "}
                                    </p>

                                    <p>
                                        La Plate-forme est la propriété de SKILLSMARKET et est exploitée par SKILLSMARKET. Sauf indication contraire, tous les contenus, informations et autres éléments sur la Plate-forme ou ses fonctionnalités à l&apos;exclusion du contenu UTILISATEUR, y compris, sans
                                        s&apos;y limiter, les marques et logos de SKILLSMARKET, les interfaces visuelles, le graphisme, la conception, la compilation, les informations, les logiciels, le code informatique (y compris le code source ou le code objet), les services, les textes, les images,
                                        les données, les fichiers audios, les autres fichiers, …. et la disposition de ceux-ci (collectivement, les « éléments ») sont protégés par les droits de propriété intellectuelle et les lois ainsi que les droits de propriété intellectuelle pertinents. Tous les
                                        éléments sont la propriété de SKILLSMARKET, de ses filiales ou sociétés affiliées, ou de tiers concédants de licence. Sauf indication contraire expresse et écrite de SKILLSMARKET, en acceptant les présentes Conditions d&apos;utilisation, le SKILLERS et
                                        l’UTILISATEUR bénéficie d&apos;une licence limitée et non cessible (c&apos;est-à-dire un droit personnel et limité) pour accéder aux services de la Plate-forme et les utiliser pour un usage personnel ou professionnel interne uniquement.
                                    </p>

                                    <p>
                                        SKILLSMARKET se réserve tous les droits qui ne sont pas expressément accordés dans les présentes Conditions d&apos;utilisation. Cette licence est soumise aux présentes Conditions générales d&apos;utilisation et ne vous permet pas de vous engager dans l&apos;une ou
                                        l&apos;autre des activités suivantes : (a) la revente ou l&apos;utilisation commerciale de la Plate-forme, de ses fonctionnalités ou de leurs éléments ; (b) la distribution, la représentation publique ou l&apos;affichage public des éléments ; (c) la modification
                                        ou toute autre utilisation dérivée de la Plate-forme ou des éléments, ou toute partie de ceux-ci ; (d) l&apos;utilisation de toute méthode d&apos;extraction de données, robot ou méthode similaire de collecte ou d&apos;extraction de données ; (e) le téléchargement
                                        (à l&apos;exception des téléchargements autorisés et payant) de toute partie des services de la Plate-forme, des éléments ou de toute information qu&apos;ils contiennent, sauf dans les cas expressément autorisés sur la Plate-forme ; ou (f) toute utilisation de la
                                        Plate-forme applicative ou des éléments, sauf aux fins prévues. Toute utilisation de la Plate-forme ou des éléments, à l&apos;exception de ce qui est expressément autorisé dans les présentes Conditions d&apos;utilisation, sans l&apos;autorisation écrite préalable
                                        de la Plate-forme, est strictement interdite et peut constituer une atteinte aux droits de propriété intellectuelle ou à d&apos;autres lois. Sauf mention expresse dans les présentes Conditions générales d&apos;utilisation, rien dans celles-ci ne doit être
                                        interprété comme conférant une licence de droits de propriété intellectuelle, que ce soit par préclusion, implication ou autres principes juridiques. SKILLSMARKET peut résilier cette licence, comme décrit aux articles résiliations prévus aux CGU SKILLERS,
                                        UTILISATEUR et aux CGVs.
                                    </p>

                                    <ol start="2" type="1">
                                        {" "}
                                        <li>
                                            <h2 className="mb-2 mt-4 font-semibold">
                                                {" "}
                                                <em>PROPRIETE INTELECTUELLE DU SKILLERS</em>
                                            </h2>
                                        </li>
                                    </ol>
                                    <p>
                                        Le SKILLER reste propriétaire de ses contenus (Contenus, Formation). Une licence limitée, non exclusive, non transférable est accordée par le SKILLER à l’UTILISATEUR ayant acquitté les frais et prix convenus. Cette licence ne peut être utilisée qu’à des fins
                                        personnelles, non commerciales et pédagogiques via la Plate-forme, conformément à ses Conditions générales d’utilisation et à toute condition ou restriction associée à une Formation ou un Contenu.{" "}
                                    </p>

                                    <p>
                                        Les autres utilisations sont strictement interdites. L’UTILISATEUR ne peut pas reproduire, redistribuer, transmettre, céder, vendre, diffuser, louer, partager, prêter, modifier, adapter, éditer, ou autrement transférer ou utiliser un quelconque cours ni créer des
                                        œuvres dérivées ou accorder une sous-licence de celui-ci sans avoir reçu une autorisation explicite du SKILLER, ou le cas échéant de la Plate-forme, sous forme écrite et signée par un représentant habilité.
                                    </p>

                                    <p>
                                        L’UTILISATEUR n’acquiert aucunement la propriété d’un Produit, et à ce titre d’un contenu, d’une formation ou d’un accessoire de ce contenu (script, exercice, ... ou de cette formation).{" "}
                                    </p>

                                    <p>L’UTILISATEUR ne peut revendre le cours de quelque manière que ce soit (y compris en partageant les informations du compte avec un tiers ou en téléchargeant illégalement le cours et en le partageant ensuite sur des sites de téléchargement illégal).</p>
                                </div>
                                <div>
                                    <div className="articles">
                                        <h2 className="mb-2 mt-4 font-semibold">ARTICLE 11. RESPONSABILITES</h2>
                                    </div>

                                    <h2 className="mb-2 mt-4 font-semibold">10-1</h2>

                                    <p>Le SKILLER comprend et accepte que :</p>

                                    <p>La Plate-forme est mise à sa disposition « en l’état ». En outre, SKILLSMARKET ne peut garantir les conséquences de l’utilisation de la Plate-forme ; </p>

                                    <p className="marge1">En particulier, SKILLSMARKET ne garantit pas : </p>

                                    <p className="marge1">Que la Plate-forme correspond aux exigences du SKILLERS ; </p>

                                    <p className="marge1">Que la Plate-forme fonctionne sans interruption, de manière sécurisée ou sans erreur de fonctionnement ; </p>

                                    <p className="marge1">Que la qualité de tout service notamment des résultats obtenus via la Plate-forme répond aux attentes du SKILLER.</p>

                                    <h2 className="mb-2 mt-4 font-semibold">10-2</h2>

                                    <p>
                                        Il est rappelé que la Plate-forme n&apos;est pas le vendeur des Produits. Elle ne peut donc en aucun cas être tenue pour responsable des ventes réalisées entre les UTILISATEURS et les SKILLERS, auxquelles elle demeure étrangère. Les Produits ne peuvent être ni
                                        repris ni échangés par la Plate-forme et toute contestation relative à une opération de vente ou autre effectuée par l&apos;intermédiaire de la Plate-forme doit être directement réglée entre l’UTILISATEUR et le SKILLER.
                                    </p>

                                    <p>La responsabilité de la Plate-forme à l&apos;égard du SKILLER ne peut être engagée que pour des faits qui lui seraient directement imputables et qui causeraient au SKILLER un préjudice direct, à l&apos;exclusion de tout préjudice indirect.</p>

                                    <p>La responsabilité de la Plate-forme est exclue en cas de mauvaise utilisation des services de la Plate-forme par le SKILLER ou de faute de sa part. Elle ne saurait pas plus être engagée à raison de faits imputables à un tiers.</p>

                                    <p>
                                        Le SKILLER garantit et indemnisera à première demande la Plate-forme de tout préjudice et contre toute action en responsabilité qui serait engagée à l&apos;encontre de la Plate-forme du fait de la violation par le SKILLER d&apos;un droit quelconque d&apos;un
                                        tiers, y compris d&apos;un UTILISATEUR, que ce préjudice résulte de la vente de Produits, de l&apos;utilisation faite par le SKILLER des services de la Plate-forme ou de tout autre fait qui lui serait imputable.
                                    </p>
                                </div>
                                <div>
                                    <div className="articles">
                                        <h2 className="mb-2 mt-4 font-semibold">ARTICLE 12. NULLITÉ PARTIELLE</h2>
                                    </div>

                                    <p>
                                        Si une ou plusieurs stipulations des présentes sont tenues pour non valides ou déclarées comme telles en application d&apos;une loi, d&apos;un règlement ou d&apos;une décision définitive d&apos;une juridiction compétente, les autres stipulations garderont toute
                                        leur force et toute leur portée.
                                    </p>
                                </div>
                                <div className="articles">
                                    <h2 className="mb-2 mt-4 font-semibold">ARTICLE 13. DROIT APPLICABLE</h2>
                                </div>

                                <p>Les présentes Conditions générales d&apos;utilisation et les opérations qui en découlent sont régies par le droit français.</p>

                                <p>Elles sont rédigées en langue française. Dans le cas où elles seraient traduites en une ou plusieurs langues, seul le texte français ferait foi en cas de litige.</p>
                            </div>
                            <div className="articles">
                                <h2 className="mb-2 mt-4 font-semibold">ARTICLE 14. IDENTIFICATION - CONTACT</h2>
                            </div>

                            <p className="marge2">Nos informations de contacts sont les suivants : </p>

                            <ul>
                                <li>
                                    <a href="http://www.skillsmarket.fr/">www.skillsmarket</a>
                                    <a href="http://www.skillsmarket.fr/">.tv</a>
                                </li>
                                <li>Désigne la société SKILLSMARKET SAS</li>
                                <li>{"Adresse du siège social : 1 place de l'homme de fer: 67000 STRASBOURG"}</li>
                                <li>Capital social : cent euros</li>
                                <li>Numéro RCS : 892 787 821 </li>
                                <li>Numéro de TVA intracommunautaire : FR57892787821</li>
                                <li>Adresse E-mail : contact@skillsmarket.fr</li>
                            </ul>
                            <div>
                                <div className="articles">
                                    <h2 className="mb-2 mt-4 font-semibold">ARTICLE 15. LITIGES</h2>
                                </div>

                                <p>Tout litige relatif à l&apos;interprétation et/ou à l&apos;exécution des présentes CGU relève des juridictions françaises.</p>

                                <p>Les réclamations doivent être adressées sur le site Internet SKILLSMARKET</p>

                                <p>Il est rappelé que tout litige ou toute réclamation concernant la vente, le descriptif ou la livraison des Produits doit être réglé directement entre l’UTILISATEUR et le SKILLER.</p>
                            </div>
                        </div>
                    </div>
                </PageContentFadingAnimation>
            </main>
        </FullLayout>
    );
}

export default Cgu;
