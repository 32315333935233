import React, { useState, useEffect } from 'react';
//COMPONENTS
import OptionWrapper from 'components/common/OptionWrapper';
//ICONS
import { DotsVerticalIcon, SearchIcon } from '@heroicons/react/outline';
import { SearchCircleIcon } from '@heroicons/react/solid';
import PinIcon from 'components/svgs/Pin';

function capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

function FieldInput(props) {
    const [open, setOpen] = useState(false);
    const [disabled, setDisabled] = useState(props.disabled);
    const [childrenWithProps, setChildrenWithProps] = useState(null);
    const [hasOptionError, setHasOptionError] = useState(false);

    const { ref, ...register } = props.register(props.name);

    function toggleOption() {
        setOpen(!open);
    }

    function dispatchOption(action, name, value, closeOnTrue) {
        const returns = props.dispatchOptionsActions(action, name, value);
        if (returns && closeOnTrue === true) {
            setOpen(false);
        }
    }

    function handleFocus() {
        props.onFocus?.();
        if (props.option?.openOnFocus) {
            setOpen(true);
            setDisabled(true);
        }
    }

    useEffect(() => {
        if (props.option?.openOnFocus) {
            if (!open && !props.disabled) setDisabled(false);
            else setDisabled(true);
        }
        if (props.option?.element) {
            setChildrenWithProps(
                React.cloneElement(props.option.element.jsx, {
                    open: open,
                    setOpen: setOpen,
                    getValues: props.getValues,
                    readyToWatch: props.readyToWatch,
                    errors: props.errors,
                    dispatch: props.option.element.props?.dispatchEvent,
                    autoFocus: props.option?.openOnFocus,
                    props: props.option?.element?.props,
                    dispatchOption: (action, name, value, closeOnTrue) => dispatchOption(action, name, value, closeOnTrue),
                })
            );

            setHasOptionError(
                props.option.element?.requiredFields?.find((element) => {
                    for (const error in props.errors) {
                        if (error === element) {
                            return true;
                        }
                    }
                    return false;
                })
            );
        }
        //eslint-disable-next-line
    }, [props.readyToWatch, props.errors, props.autoFocus, open]);

    return (
        <div className={`${props.size ? props.size : 'w-full'} ${props.inputForm === 'floating' ? 'z-0 group' : ''} relative`}>
            <div>
                {props.type !== 'hidden' && props.inputForm !== 'floating' && props.label !== false && props.type !== 'checkbox' && (
                    <label
                        className={`${props.inputForm === 'ai' ? 'inputAi_label' : 'input_label'} ${
                            props.errors && (props.errors[props.name] || props.errors[props.depends]) ? 'input_error' : ''
                        }`}
                        htmlFor={`input_${props.name}`}
                    >
                        {props.label ? capitalize(props.label) : capitalize(props.name)}
                    </label>
                )}
                <div className="relative w-auto" onClick={handleFocus}>
                    {props.option !== undefined && (
                        <div
                            className={`${props.type === 'ai' ? 'text-white' : ''} input_option 
                            ${props.name === 'search' ? 'w-10 flex h-full right-0 p-2 rounded-full bg-blancSkills' : ''}
                            ${props.name === 'jobs' ? 'w-10 flex h-full right-0 p-2 cursor-pointer' : ''} `}
                        >
                            {props.name === 'forum' && props.inputForm !== 'bubble' && (
                                <SearchCircleIcon
                                    onClick={props.onClickOption || toggleOption}
                                    className={`big-icon transform -rotate-90 icon_left ${props.errors && hasOptionError && 'text-danger-500'} text-blueMain`}
                                />
                            )}
                            {props.name === 'search' &&
                                (props?.option?.isSearchPage ? (
                                    <SearchIcon
                                        onClick={props.onClickOption || toggleOption}
                                        className={`text-graySkills w-full ${props.errors && hasOptionError && 'text-danger-500'}`}
                                    />
                                ) : (
                                    <SearchIcon
                                        onClick={props.onClickOption || toggleOption}
                                        className={`text-graySkills ${props?.searchMain ? 'medium-icon icon_left_MainSeach' : 'small-icon icon_left'} ${
                                            props.errors && hasOptionError && 'text-danger-500'
                                        }`}
                                    />
                                ))}
                            {props.name === 'place' && (
                                <PinIcon
                                    onClick={props.onClickOption || toggleOption}
                                    className={`text-graySkills small-icon icon_left ${props.errors && hasOptionError && 'text-danger-500'}`}
                                />
                            )}
                            {props.name === 'jobs' && (
                                <SearchIcon
                                    onClick={props.onClickOption || toggleOption}
                                    className={`text-graySkills w-full ${props.errors && hasOptionError && 'text-danger-500'}`}
                                />
                            )}
                            {props.name !== 'search' && props.name !== 'forum' && props.name !== 'place' && props.name !== 'jobs' && (
                                <DotsVerticalIcon
                                    onClick={props.onClickOption || toggleOption}
                                    className={`${props.inputForm === 'flat' ? 'mt-4' : ''} h-6 w-6 icon_right ${
                                        props.errors && hasOptionError && 'text-danger-500'
                                    }`}
                                />
                            )}
                        </div>
                    )}
                    <div
                        className={`w-full ${
                            props.type === 'checkbox'
                                ? 'flex justify-start'
                                : `${props.name === 'search' ? 'rounded-full bg-blancSkills' : ''} ${props?.searchMain ? 'bg-blancSkills p-2' : ''} ${
                                      props.name === 'jobs' ? 'rounded-l-full bg-blancSkills' : ''
                                  }`
                        }`}
                    >
                        {props.inputForm !== 'floating' && props.inputForm !== 'flat' && (
                            <>
                                <input
                                    // ref={props.currentRef}
                                    ref={(e) => {
                                        ref(e);
                                        if (props.currentRef) props.currentRef.current = e;
                                    }}
                                    placeholder={props.placeholder}
                                    disabled={disabled}
                                    defaultValue={props.defaultValue}
                                    {...register}
                                    className={`input p-2 input_${props.inputForm} ${
                                        props.inputForm === 'ai' ? 'border-white text-white' : 'focus:border-blueMain'
                                    } placeholder-graySkills 
                                    ${props.name === 'address' ? 'pointer-events-none' : ''}
                                            ${props.name === 'jobs' && 'w-[85%] truncate'}
                                            placeholder:text-sm sm:placeholder:text-base ${props.errors[props.name] && 'input_error'} ${props.className}`}
                                    type={props.type ? props.type : 'text'}
                                    /* input_${props.type} */
                                    id={`input_${props.name}`}
                                    onFocus={handleFocus}
                                    style={{ background: 'none' }}
                                    // onChange={props.onChange}
                                    autoFocus={props.autoFocus}
                                    maxLength={props.maxLength}
                                    min={props.min || 0}
                                    max={props.max}
                                />
                            </>
                        )}

                        {props.inputForm === 'floating' && (
                            <>
                                <div className="mt-4">
                                    <input
                                        // ref={props.currentRef}
                                        ref={(e) => {
                                            ref(e);
                                            if (props.currentRef) props.currentRef.current = e;
                                        }}
                                        placeholder={props.placeholder}
                                        disabled={disabled}
                                        defaultValue={props.defaultValue}
                                        {...props.register(props.name)}
                                        className={`${props.type === 'ai' ? 'inputAi_floating' : 'input_floating'} ${props.type ? `input_${props.type}` : ''} ${
                                            props.name === 'address' ? 'pointer-events-none' : ''
                                        } ${props.errors[props.name] ? 'input_error' : ''} ${props.option !== undefined ? 'input_peerleft' : ''} ${
                                            props.className ? props.className : ''
                                        } peer`}
                                        type={props.type ? props.type : 'text'}
                                        id={`input_${props.name}`}
                                        onFocus={handleFocus}
                                        autoFocus={props.autoFocus}
                                        maxLength={props.maxLength}
                                        min={props.min || 0}
                                        max={props.max}
                                    />

                                    <label
                                        htmlFor={`floating_${props.name}`}
                                        className={`${props.typeLabel === 'ai' ? 'inputAi_labelFloating' : 'input_labelFloating'}`}
                                    >
                                        {props.label}
                                    </label>
                                </div>
                            </>
                        )}
                        {props.inputForm === 'flat' && (
                            <>
                                <div className="">
                                    <input
                                        // ref={props.currentRef}
                                        ref={(e) => {
                                            ref(e);
                                            if (props.currentRef) props.currentRef.current = e;
                                        }}
                                        placeholder={props.placeholder}
                                        disabled={disabled}
                                        defaultValue={props.defaultValue}
                                        {...props.register(props.name)}
                                        className={`${props.type === 'ai' ? 'inputAi_flat' : 'input_flat'} input_${props.type} ${
                                            props.name === 'address' ? 'pointer-events-none' : ''
                                        } ${props.errors[props.name] ? 'input_error' : ''} ${props.option !== undefined ? 'input_peerleft' : ''} ${
                                            props.className ? props.className : ''
                                        } peer`}
                                        type={props.type ? props.type : 'text'}
                                        id={`input_${props.name}`}
                                        onFocus={handleFocus}
                                        autoFocus={props.autoFocus}
                                        maxLength={props.maxLength}
                                        min={props.min || 0}
                                        max={props.max}
                                    />

                                    {/* <label htmlFor={`floating_${props.name}`} className={`${props.typeLabel === "ai" ? "inputAi_labelFloating" : "input_labelFloating"}`}>
                                        {props.label}
                                    </label> */}
                                </div>
                            </>
                        )}
                        {props.type === 'checkbox' && (
                            <label htmlFor={`input_${props.name}`} className={`${props.className} input_label input_label_checkbox`}>
                                {props.label}
                            </label>
                        )}
                    </div>
                </div>
                {props.errors && props.errors[props.name] && (
                    <span className={`mt-0 mb-1 relative text-sm ${props.textErrorColor ? props.textErrorColor : 'text-danger-400'}`}>
                        {props.errors[props.name].message}
                    </span>
                )}
            </div>

            {props.option !== undefined && open && (
                <>
                    <OptionWrapper
                        open={[open, setOpen]}
                        actions={props.option.actions}
                        dispatchOptionsActions={(action, name, value, closeOnTrue) => dispatchOption(action, null, null, closeOnTrue)}
                    >
                        {childrenWithProps}
                    </OptionWrapper>
                </>
            )}
        </div>
    );
}

export default FieldInput;
