//STYLES
import "./rating.css";
//LIBS
import Rate from "rc-rate";
import "rc-rate/assets/index.css";

function Rating(props) {
    return <Rate {...props} />;
}

export default Rating;
