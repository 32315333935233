import { useState, useContext } from 'react';
//API
import { deleteAnswer as deleteAnswerRoom, putAnswer as putAnswerRoom, deleteRoom } from 'api/forum';
import { deleteAnswer as deleteAnswerShoot, putAnswer as putAnswerShoot, deleteShoot } from 'api/shoots';
//API
import { postCounter } from 'api/counters';
//LIBS
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
//FORMS
import schema from 'components/forms/yup/answer';
//CONTEXTS
import { AuthContext } from 'context/authContext';
import { CounterContext } from 'context/counterContext';
//COMPONENTS
import Modal from 'components/common/Modal';
import Report from 'components/reports/Report';
import { FlagIcon, TrashIcon, XIcon } from '@heroicons/react/solid';
import { useNavigate } from 'react-router-dom';
import useHasCounter from 'hooks/useHasCounter';
function OptionsMenu(props) {
    // CONTEXTS
    const [authState] = useContext(AuthContext);
    const { counterDispatch } = useContext(CounterContext);

    const [updateOpen, setUpdateOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [openConfirmRoomDelete, setOpenConfirmRoomDelete] = useState(false);
    const [openConfirmShootDelete, setOpenConfirmShootDelete] = useState(false);
    const [openReportSubmit, setOpenReportSubmit] = useState(false);
    const [openUserBlockConfirm, setOpenUserBlockConfirm] = useState(false);
    const [openUserBlockSuccess, setOpenUserBlockSuccess] = useState(false);

    //HOOKS
    const isBlockUserForMe = useHasCounter(props.user?.data?.counters, 'block_user_forMe', props.user?.data?.id);

    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        /* getValues, */
        reset,
    } = useForm({
        resolver: yupResolver(schema),
    });

    function onSubmit(data) {
        if (errors.length > 0) return false;

        const update =
            props?.itemType === 'room' ? putAnswerRoom(props.answer._id, props.type, data.answer) : putAnswerShoot(props.answer._id, props.type, data.answer);
        update.then(() => {
            setUpdateOpen(false);
            toast('Votre réponse a été modifiée', { type: 'success' });

            props.refresh();
            reset();
        });
    }

    function onDeleteAnswer() {
        // console.log(props.answer._id);
        // console.log(props.type);
        const delAnswer = props?.itemType === 'room' ? deleteAnswerRoom(props.answer._id, props.type) : deleteAnswerShoot(props.answer._id, props.type);
        delAnswer.then(() => {
            setDeleteOpen(false);
            toast('Votre réponse a été supprimée', { type: 'success' });
            props.refresh();
            reset();
        });
    }

    function handleUpdateAnswer() {
        closeOptionsMenu();
        setValue('answer', props.answer.answer);
        setUpdateOpen(true);
    }
    function handleDeleteAnswer() {
        closeOptionsMenu();
        setValue('answer', props.answer.answer);
        setDeleteOpen(true);
    }
    function handleDeleteRoom() {
        closeOptionsMenu();
        setOpenConfirmRoomDelete(true);
    }
    function handleDeleteShoot() {
        closeOptionsMenu();
        setOpenConfirmShootDelete(true);
    }
    function handleSubmitUpdate() {
        handleSubmit(onSubmit)();
    }

    function handleReport() {
        closeOptionsMenu();
        if (authState.isLogged) {
            setOpenReportSubmit(true);
        } else document.getElementById('button-login')?.click();
    }

    function handleUserBlock() {
        closeOptionsMenu();
        if (authState.isLogged) {
            setOpenUserBlockConfirm(true);
        } else document.getElementById('button-login')?.click();
    }

    function hiddenRoomForMe() {
        closeOptionsMenu();
        if (authState.isLogged) {
            postCounter({ type: 'hidden_room_forMe', to: props.room?.id || props.room?._id }).then((data) => {
                let counters = data.counters;

                counterDispatch({
                    type: 'UPDATE_OR_ADD_COUNTERS',
                    payload: { targetId: props.room?.id || props.room?._id, counters, updatedAt: new Date().toISOString() },
                });

                props?.refresh();
            });
        } else document.getElementById('button-login')?.click();
    }

    function BlockUserForMe() {
        if (authState.isLogged) {
            postCounter({ type: 'block_user_forMe', to: props.user?.data?.id }).then(() => {
                setOpenUserBlockSuccess(true);
                props.refresh();
            });
        } else document.getElementById('button-login')?.click();
    }

    function handleCloseRoom() {
        setOpenConfirmRoomDelete(false);

        const closeRoom = deleteRoom(props.room?.id);
        closeRoom.then((res) => {
            if (res.status === 200) {
                toast('Vous avez supprimé votre Room !', { type: 'success' });
                reset();
                navigate('/');
            } else {
                toast('Une erreur est survenue', { type: 'danger' });
            }
        });
    }

    function handleCloseShoot() {
        setOpenConfirmShootDelete(false);

        const closeShoot = deleteShoot(props.shoot?._id);
        closeShoot.then((res) => {
            if (res.status === 200) {
                toast('Vous avez supprimé votre shoot !', { type: 'success' });
                props.refresh();
                reset();
            } else {
                toast('Une erreur est survenue', { type: 'danger' });
            }
        });
    }

    function closeOptionsMenu() {
        const els = document.getElementsByClassName('optionsMenu');
        [].forEach.call(els, function (el) {
            el.classList.add('hidden');
        });
    }

    return (
        <>
            {(props.type === 'answer' || props.type === 'answerTo') && (
                <>
                    <Modal
                        title="Modifier ma réponse"
                        open={updateOpen}
                        onClose={{
                            onClick: () => {
                                setUpdateOpen(false);
                            },
                        }}
                        onConfirm={{
                            label: 'Modifier',
                            onClick: handleSubmitUpdate,
                        }}
                    >
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <textarea
                                name="answer"
                                defaultValue={props.answer?.answer}
                                className="input resize-none py-4"
                                errors={errors}
                                {...register('answer')}
                                rows="3"
                                style={{ height: 'auto' }}
                                placeholder={`Votre réponse à ${props.answer?.user?.profile?.pseudo}`}
                            >
                                {/* {getValues("answer")} */}
                            </textarea>

                            {errors && errors['answer'] && <span className={`mt-0 text-sm text-danger-400`}>{errors['answer'].message}</span>}
                        </form>
                    </Modal>
                    <Modal
                        title="Supprimer votre réponse"
                        open={deleteOpen}
                        onClose={{
                            onClick: () => {
                                setDeleteOpen(false);
                            },
                        }}
                        onConfirm={{
                            label: 'Confirmer',
                            onClick: onDeleteAnswer,
                        }}
                    >
                        <div className="pb-5">
                            <h1 className="font-bold">Voulez-vous vraiment supprimer votre réponse ?</h1>
                        </div>
                        <div className={'overflow-hidden ' + (props.answer?.answer?.length > 500 ? 'overflow-y-auto h-32' : '')}>{props.answer?.answer}</div>
                    </Modal>
                </>
            )}

            {(props.type === 'room' || props.type === '_room') && (
                <>
                    <Modal
                        title="Supprimer la Room"
                        open={openConfirmRoomDelete}
                        onClose={{
                            onClick: () => {
                                setOpenConfirmRoomDelete(false);
                            },
                        }}
                        onConfirm={{
                            label: 'Supprimer',
                            onClick: handleCloseRoom,
                        }}
                    >
                        <p>Voulez-vous vraiment supprimer cette Room ?</p>
                    </Modal>
                </>
            )}

            {props.type === 'shoot' && (
                <>
                    <Modal
                        title="Supprimer"
                        open={openConfirmShootDelete}
                        onClose={{
                            onClick: () => {
                                setOpenConfirmShootDelete(false);
                            },
                        }}
                        onConfirm={{
                            label: 'Supprimer',
                            onClick: handleCloseShoot,
                        }}
                    >
                        <p>Voulez-vous vraiment supprimer ce Shoot ?</p>
                    </Modal>
                </>
            )}

            {props.type === 'user' && (
                <>
                    <Modal
                        title={`${isBlockUserForMe ? "Débloquer l'utilisateur" : "Bloquer l'utilisateur"}`}
                        open={openUserBlockConfirm}
                        onClose={{
                            onClick: () => {
                                setOpenUserBlockConfirm(false);
                            },
                        }}
                        onConfirm={{
                            label: `${isBlockUserForMe ? 'Débloquer cet utilisateur' : 'Bloquer cet utilisateur'}`,
                            onClick: () => {
                                setOpenUserBlockConfirm(false);
                                BlockUserForMe();
                            },
                        }}
                    >
                        {isBlockUserForMe ? (
                            <>
                                <p>Voulez-vous vraiment Débloquer cet Utilisateur ?</p>
                            </>
                        ) : (
                            <>
                                <p>Voulez-vous vraiment bloquer cet Utilisateur ?</p>
                            </>
                        )}
                    </Modal>
                </>
            )}
            <Modal
                open={openUserBlockSuccess}
                onConfirm={{
                    label: 'Fermer',
                    onClick: () => {
                        setOpenUserBlockSuccess(false);
                    },
                }}
            >
                {isBlockUserForMe ? (
                    <>
                        <p>Cette utilisateur ne pourra plus vous contacter</p>
                    </>
                ) : (
                    <>
                        <p>Vous avez débloqué cet utilisateur</p>
                    </>
                )}
            </Modal>
            <Modal
                open={openReportSubmit}
                onClose={{
                    onClick: () => {
                        setOpenReportSubmit(false);
                    },
                }}
            >
                <h1 className="h1 py-2">Signaler</h1>
                <Report type={props.type} user={props.user?.data} shoot={props.shoot} room={props.room} answer={props.answer} service={props.service} />
                <div className="sticky py-4 -bottom-4 sm:bottom-0 z-[999999]">
                    <div className="flex items-center gap-4 justify-center">
                        <button
                            className="flex flex-row items-center gap-1 rounded-full px-4 py-1 bg-white button-border-gray border-2"
                            onClick={() => {
                                setOpenReportSubmit(false);
                            }}
                        >
                            <XIcon className="h-5 w-5 p-0" />
                            <span>Fermer</span>
                        </button>
                    </div>
                </div>
            </Modal>

            {props.type === 'user' || props.type === 'answer' || props.type === 'answerTo' || props.type === '_room' || props.type === 'service' ? (
                <>
                    <div
                        id={`optionsMenu_${
                            props.type === 'user'
                                ? props.user?.data?.id
                                : props.type === '_room'
                                ? props.room?._id || props.room?.id
                                : props.type === 'service'
                                ? props.service?.id
                                : props.answer?._id
                        }${props.screen === 'mobile' ? '_mobile' : ''}`}
                        className={`optionsMenu hidden absolute ${
                            props.type === 'service'
                                ? 'bottom-5 right-4'
                                : `${
                                      props.room?.type === 'job'
                                          ? 'right-10 bottom-[0.4rem]'
                                          : props.type === 'answer' || props.type === 'answerTo'
                                          ? 'bottom-5 right-4'
                                          : 'bottom-10 right-4'
                                  }`
                        } text-left font-semibold right-0 bg-white drop-shadow-md w-auto min-w-max h-auto text-xs p-2 rounded`}
                    >
                        <ul className={`text-center flex flex-col gap-2`}>
                            {props.type === 'user' && (
                                <>
                                    {authState?.me?.id !== props.user?.data?.id && (
                                        <>
                                            <li className="text-warning-400 hover:bg-lightGraySkills">
                                                <button className="flex flex-row items-center gap-2" onClick={handleReport}>
                                                    <FlagIcon className="w-4 h-4 text-warning-400" />
                                                    <li className="font-semibold">Signaler</li>
                                                </button>
                                            </li>

                                            <button className="flex flex-row items-center gap-2 hover:bg-lightGraySkills" onClick={handleUserBlock}>
                                                <li className="font-bold text-danger-500 mx-auto">{isBlockUserForMe ? 'Débloquer' : 'Bloquer'}</li>
                                            </button>
                                        </>
                                    )}
                                </>
                            )}
                            {(props.type === 'answer' || props.type === 'answerTo') && (
                                <>
                                    {authState?.me?.id === props.answer?.user?.id ? (
                                        <>
                                            <li className="text-warning-400 hover:bg-lightGraySkills">
                                                <button onClick={handleUpdateAnswer}>Modifier</button>
                                            </li>
                                            <li className="text-danger-400 hover:bg-lightGraySkills">
                                                <button onClick={handleDeleteAnswer}>Supprimer</button>
                                            </li>
                                            {/* <li className="border-b border-blue-100 py-1"><button onClick={handleUpdateAnswer}>Supprimer</button></li> */}
                                        </>
                                    ) : (
                                        <li className="text-warning-400 hover:bg-lightGraySkills">
                                            <button onClick={handleReport}>Signaler</button>
                                        </li>
                                    )}
                                </>
                            )}
                            {props.type === 'service' && (
                                <>
                                    <li className="text-danger-400 hover:bg-lightGraySkills">
                                        <button onClick={handleReport} className="flex flex-row mx-auto items-center gap-2">
                                            <FlagIcon className="w-4 h-4 text-warning-400" />
                                            <li className="font-semibold">Signaler</li>
                                        </button>
                                    </li>
                                </>
                            )}
                            {props.type === '_room' && (
                                <>
                                    {authState?.isLogged ? (
                                        <>
                                            <li className="w-auto text-warning-400 hover:bg-lightGraySkills">
                                                <button onClick={hiddenRoomForMe}>Ne plus voir ce post</button>
                                            </li>

                                            {authState?.me?.id !== props.room?.user?.id && (
                                                <>
                                                    <li className="text-warning-400 hover:bg-lightGraySkills">
                                                        <button onClick={handleReport} className="w-full flex">
                                                            <div className="flex flex-row items-center mx-auto gap-2">
                                                                <FlagIcon className="w-4 h-4 text-warning-400" />
                                                                <li className="font-semibold">Signaler</li>
                                                            </div>
                                                        </button>
                                                    </li>
                                                </>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            <li className="text-danger-400 hover:bg-lightGraySkills">
                                                <button onClick={handleReport} className="flex flex-row mx-auto items-center gap-2">
                                                    <FlagIcon className="w-4 h-4 text-warning-400" />
                                                    <li className="font-semibold">Signaler</li>
                                                </button>
                                            </li>
                                        </>
                                    )}
                                </>
                            )}
                        </ul>
                    </div>
                </>
            ) : (
                <>
                    <div
                        id={`optionsMenu_${props.type === 'room' ? props.room?.id : props.type === 'shoot' ? props.shoot?._id : ''}${
                            props.screen === 'mobile' ? '_mobile' : ''
                        }`}
                        className={`optionsMenu hidden absolute z-20 ${
                            props.type === 'shoot' ? (props.path === 'profil' ? 'top-8 right-0' : 'top-12 sm:top-8 right-0') : 'top-9 -right-4'
                        } bg-white drop-shadow-md w-auto min-w-max h-auto p-2 rounded-lg`}
                    >
                        {/* fixed bg-white flex flex-col w-auto z-20 top-15 right-0 p-2 h-auto rounded-lg shadow-md */}
                        <ul className="text-center flex flex-col gap-2">
                            {props.type === 'room' && (
                                <>
                                    {authState.isLogged ? (
                                        <>
                                            {authState.me?.id === props.room?.user?.id ? (
                                                <>
                                                    <li className="text-danger-500 hover:bg-lightGraySkills">
                                                        <button className={`flex flex-row items-center gap-2`} onClick={handleDeleteRoom}>
                                                            <TrashIcon className="w-5 h-5 text-danger-500" />
                                                            <li className="font-semibold">Supprimer ma room</li>
                                                        </button>
                                                    </li>
                                                </>
                                            ) : (
                                                <li className="text-warning-400 hover:bg-lightGraySkills">
                                                    <button onClick={handleReport} className="flex flex-row items-center gap-2">
                                                        <FlagIcon className="w-5 h-5 text-warning-400" />
                                                        <li className="font-semibold">Signaler</li>
                                                    </button>
                                                </li>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            <li className="text-warning-400 hover:bg-lightGraySkills">
                                                <button onClick={handleReport} className="flex flex-row items-center gap-2">
                                                    <FlagIcon className="w-5 h-5 text-warning-400" />
                                                    <li className="font-semibold">Signaler</li>
                                                </button>
                                            </li>
                                        </>
                                    )}
                                </>
                            )}
                            {props.type === 'shoot' && (
                                <>
                                    {authState.isLogged ? (
                                        <>
                                            {authState.me?.id === props.shoot?.user?.id ? (
                                                <>
                                                    <li className="text-danger-500 hover:bg-lightGraySkills">
                                                        <button className={`flex flex-row items-center gap-2`} onClick={handleDeleteShoot}>
                                                            <TrashIcon className="w-5 h-5 text-danger-500" />
                                                            <li className="font-semibold">Supprimer</li>
                                                        </button>
                                                    </li>
                                                </>
                                            ) : (
                                                <li className="text-warning-400 hover:bg-lightGraySkills">
                                                    <button onClick={handleReport} className="flex flex-row items-center gap-2">
                                                        <FlagIcon className="w-5 h-5 text-warning-400" />
                                                        <li className="font-semibold">Signaler</li>
                                                    </button>
                                                </li>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            <li className="text-warning-400 hover:bg-lightGraySkills">
                                                <button onClick={handleReport} className="flex flex-row items-center gap-2">
                                                    <FlagIcon className="w-4 h-4 text-warning-400" />
                                                    <li className="font-semibold">Signaler</li>
                                                </button>
                                            </li>
                                        </>
                                    )}
                                </>
                            )}
                        </ul>
                    </div>
                </>
            )}
        </>
    );
}

export default OptionsMenu;
