import { useEffect, createContext, useReducer } from 'react';

const CookieContext = createContext();

const initialState = () => {
    return {
        status: 'initial',
        functionnals: true,
        statistics: localStorage.getItem('cookie_statistics') === 'true',
        marketing: localStorage.getItem('cookie_marketing') === 'true',
    };
};

const CookieProvider = (props) => {
    const reducer = (state, action) => {
        switch (action.type) {
            case 'action':
                return { ...state, status: action.status };
            case 'setStatistics':
                return { ...state, statistics: action.checked };
            case 'setMarketing':
                return { ...state, marketing: action.checked };
            case 'acceptAll':
                return { ...state, status: 'accepted', statistics: true, marketing: true };
            case 'disableAll':
                return { ...state, status: 'disabled', statistics: false, marketing: false };
            case 'save':
                return { ...state, status: 'saved', statistics: state.statistics, marketing: state.marketing };

            default:
        }
    };

    const [state, Dispatch] = useReducer(reducer, null, initialState);

    useEffect(() => {
        switch (state.status) {
            case 'saved':
            case 'accepted':
            case 'disabled':
                localStorage.setItem('cookie_statistics', state.statistics);
                localStorage.setItem('cookie_marketing', state.marketing);
                break;
            case 'initial':
                const isChoosen = !localStorage.getItem('cookie_statistics') || !localStorage.getItem('cookie_marketing');
                if (isChoosen) Dispatch({ type: 'action', status: 'opened' });

                break;
            default:
        }
    }, [state]);

    return <CookieContext.Provider value={[state, Dispatch]}>{props.children}</CookieContext.Provider>;
};
export { CookieContext, CookieProvider };
