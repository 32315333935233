import { useContext, useState } from 'react';

//UTILS
import { isFoundByKey } from 'utils/functions';
import { isMobile } from 'utils/functions';
//LIBS
import { useNavigate, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

//API
import { deleteRoom } from 'api/forum';
import { postCounter } from 'api/counters';

//CONTEXTS
import { AuthContext } from 'context/authContext';

//ICONS
import { CheckIcon, BellIcon, ArrowNarrowLeftIcon, HeartIcon } from '@heroicons/react/solid';
//import { BellIcon as BellIconOutline } from '@heroicons/react/outline';

//HOOKS
import useHasCounter from 'hooks/useHasCounter';

//CONTAINERS
import ImageSlider from 'containers/rooms/ImageSlider';
import CountersRoom from 'containers/rooms/CountersRoom';
import CardProfilRoom from 'containers/profils/CardProfilRoom';
import ForumSearchBarV2 from 'containers/searchBar/ForumSearchBarV2';

//COMPONENTS
import Share from 'components/share/Share';
import Modal from 'components/common/Modal';
import ReactionIcons from 'components/ReactionIcons';
import Nl2br from 'components/common/Nl2br';

function HeadRoom(props) {
    const [authState] = useContext(AuthContext);

    const navigate = useNavigate();
    const location = useLocation();

    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
    const [openEditRoom, setEditRoom] = useState(false);

    const hasBookmark = useHasCounter(props.room?.data?.counters, 'bookmark_job', props.room?.data?.id ? props.room?.data?.id : props.room?.data?._id);

    function handleCloseRoom() {
        const closeRoom = deleteRoom(props.room?.data?.id);
        closeRoom.then((res) => {
            if (res.status === 200) {
                toast('Vous avez supprimé votre Room !', { type: 'success' });
                // reset();
                navigate('/');
            } else {
                toast('Une erreur est survenue', { type: 'danger' });
            }
        });
    }

    function handleSubscribe() {
        if (authState.isLogged) {
            postCounter({ type: 'subscription', to: props.room?.data?.user?.id }).then(() => {
                props.room.refetch();
            });
        } else document.getElementById('button-login')?.click();
    }

    function handleBookmark(jobId) {
        if (authState.isLogged) {
            postCounter({ type: 'bookmark_job', to: jobId }).then(() => {
                props.room.refetch();
            });
        } else document.getElementById('button-login')?.click();
    }

    return (
        <>
            <Modal
                title="Supprimer une Room"
                open={openConfirmDelete}
                onClose={{
                    onClick: () => {
                        setOpenConfirmDelete(false);
                    },
                }}
                onConfirm={{
                    label: 'Supprimer',
                    onClick: handleCloseRoom,
                }}
            >
                <p>Voulez-vous vraiment supprimer cette Room ?</p>
            </Modal>
            {openEditRoom && (
                <ForumSearchBarV2
                    isButton={false}
                    isEdit={true}
                    roomData={props.room.data}
                    title={false}
                    className="linkCardMainNavMobile"
                    typeForum="news"
                    label="Un post"
                    iconClassName="w-6 h-6 mx-auto"
                    mobileLabelClassName="text-xl text-gray-500 flex justify-center items-center pl-4"
                    handleClose={() => setEditRoom(false)}
                />
            )}
            <div className="w-full">
                {(props?.goBack || props?.share !== false || props?.jobFav !== false) && (
                    <div className="flex justify-between items-center mt-4">
                        {props?.goBack && (
                            <div className="hidden sm:block w-fit text-gray-200 text-md font-bold">
                                <button className="flex items-center px-0 link_basic" onClick={props?.goBack}>
                                    <ArrowNarrowLeftIcon className="w-6 h-6 mr-2 relative" />
                                    <span className="font-bold text-md">à la une</span>
                                </button>
                            </div>
                        )}

                        <div className="hidden sm:flex items-center gap-2">
                            {props?.share !== false && !isMobile() && (
                                <div>
                                    <Share type="outline" url={location.pathname} />
                                </div>
                            )}
                            {props?.jobFav !== false && props.room?.data?.type === 'job' && (
                                <div className="flex">
                                    <button
                                        onClick={() => {
                                            handleBookmark(props.room?.data?._id);
                                        }}
                                    >
                                        <HeartIcon className={`w-6 h-6 ${hasBookmark ? 'text-red' : 'text-graySkills'} sm:hover:text-blueMain`} />
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                )}

                <div className={`${!isMobile() && 'mt-4'}`}>
                    {props.room.isSuccess && (
                        <div className="w-full flex flex-col justify-between h-full">
                            <div className={`${props.room.data.type !== 'job' && 'card-header mb-1'} relative w-full`}>
                                <div className="flex items-center justify-between">
                                    <div className="">
                                        <CardProfilRoom
                                            user={props.room.data.user}
                                            room={props.room.data}
                                            isAnonymous={props.room.data.anonymousQuestion === false && props.room.data.user !== null ? false : true}
                                            hiddenDate={false}
                                        />
                                    </div>

                                    {authState.isLogged && authState?.me?.id === props.room?.data?.user?.id && (
                                        <div className="flex gap-2">
                                            <button
                                                // className={`hidden sm:block linkCardHeader border border-red text-red hover:text-white hover:bg-red`}
                                                className={`hidden sm:block linkCard purple`}
                                                onClick={() => {
                                                    setEditRoom(true);
                                                }}
                                            >
                                                <span className="">Modifier ma room</span>
                                            </button>
                                            <button
                                                // className={`hidden sm:block linkCardHeader border border-red text-red hover:text-white hover:bg-red`}
                                                className={`hidden sm:block linkCard red`}
                                                onClick={() => {
                                                    setOpenConfirmDelete(true);
                                                }}
                                            >
                                                <span className="">Supprimer ma room</span>
                                            </button>
                                        </div>
                                    )}
                                    {props.room?.data?.anonymousQuestion === false && props.room?.data?.user !== null && (
                                        <>
                                            {authState?.me?.id !== props.room?.data?.user?.id && (
                                                <>
                                                    <div className="hidden sm:block self-start">
                                                        <button
                                                            onClick={() => handleSubscribe()}
                                                            className={`h-8 gap-2 ${
                                                                isFoundByKey(props.room?.data?.user?.counters, 'user', authState?.me?.id) &&
                                                                isFoundByKey(props.room?.data?.user?.counters, 'type', 'subscription') &&
                                                                authState.isLogged
                                                                    ? 'linkCard red active'
                                                                    : 'linkCard-purpleSkills'
                                                            }`}
                                                        >
                                                            {isFoundByKey(props.room?.data?.user?.counters, 'user', authState?.me?.id) &&
                                                            isFoundByKey(props.room?.data?.user?.counters, 'type', 'subscription') &&
                                                            authState.isLogged ? (
                                                                <>
                                                                    <div className="flex items-center gap-2">
                                                                        <CheckIcon className="h-4 w-4" />
                                                                        <span>Suivi</span>
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <div className="flex items-center gap-2">
                                                                        <BellIcon className="h-4 w-4" />
                                                                        <span>Suivre</span>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </button>
                                                    </div>

                                                    <div className="sm:hidden self-start">
                                                        <button
                                                            onClick={() => handleSubscribe()}
                                                            className={`${
                                                                isFoundByKey(props.room?.data?.user?.counters, 'user', authState?.me?.id) &&
                                                                isFoundByKey(props.room?.data?.user?.counters, 'type', 'subscription') &&
                                                                authState.isLogged
                                                                    ? 'linkCard red active'
                                                                    : 'linkCard-purpleSkills'
                                                            }`}
                                                        >
                                                            {isFoundByKey(props.room?.data?.user?.counters, 'user', authState?.me?.id) &&
                                                            isFoundByKey(props.room?.data?.user?.counters, 'type', 'subscription') &&
                                                            authState.isLogged ? (
                                                                <>
                                                                    <div className="flex items-center gap-2">
                                                                        <CheckIcon className="h-4 w-4" />
                                                                        <span>Suivi</span>
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <div className="flex items-center gap-2">
                                                                        <BellIcon className="h-4 w-4" />
                                                                        <span>Suivre</span>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </button>
                                                    </div>
                                                </>
                                            )}
                                        </>
                                    )}
                                </div>
                                {props.room.data.type !== 'job' && (
                                    <div className="flex flex-wrap flex-row -mx-2">
                                        {props.room.data?.tags?.map((skill, index) => (
                                            <Link to={`/search?q=${skill.name}`} key={index} className="link_basic">
                                                {skill.name}
                                            </Link>
                                        ))}
                                    </div>
                                )}
                                {props.room.data.type !== 'news' ? (
                                    <>
                                        {props.room.data.type === 'job' ? (
                                            <div className={'mt-6 mb-4'}>
                                                <h1 className="font-bold text-3xl text-purpleSkills">{props.room?.data?.question}</h1>
                                                {props.room.data?.tags?.map((skill, index) => (
                                                    <Link to={`/search?q=${skill.name}`} key={index} className="link_basic">
                                                        {skill.name}
                                                    </Link>
                                                ))}
                                            </div>
                                        ) : (
                                            <h1 className="card-item-question">
                                                <Nl2br str={props.room.data?.question} />
                                            </h1>
                                        )}
                                    </>
                                ) : (
                                    /*  <div className="roomNews" dangerouslySetInnerHTML={{ __html: props.room.data.description }}></div> */
                                    <div className="roomNews text-sm text-[#000a53]">
                                        <Nl2br str={props.room.data.description} />
                                    </div>
                                )}
                                <ImageSlider room={props.room.data} />
                                <ReactionIcons counters={props.room.data.counters} to={props?.room?.data?._id} targetType="room" type="reactions" />
                            </div>
                            {props.room.data.type !== 'job' && <CountersRoom room={props.room} />}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default HeadRoom;
