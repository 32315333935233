import { useEffect } from 'react';

//LIBS
import { animated, useSpring } from 'react-spring';

function PageContentFadingAnimation(props) {
    const fadeIn = useSpring({
        from: { opacity: 0 },
        to: { opacity: 1 },
        config: { duration: props.duration },
    });

    return <animated.div style={fadeIn} className={props.className}>{props.children}</animated.div>;
}

// direction : bottom = glissade d'un élément vers le bas | top = glissade d'un élément vers le haut.
function TopBottomSlideAnimation(props) {
    if (props.direction === undefined || (props.direction.toLowerCase() !== 'bottom' && props.direction.toLowerCase() !== 'top')) {
        props.direction = 'bottom';
    }

    const [animate, setAnimate] = useSpring(() => ({
        marginTop: `${props.direction === 'bottom' ? '-600%' : '600'}`,
        from: { marginTop: `${props.direction === 'bottom' ? '-600%' : '600'}` },
        to: { marginTop: '0%' },
        config: { duration: props.duration },
    }));

    useEffect(() => {
        setAnimate({ marginTop: '0%' });
    }, [setAnimate]);
  
    return (
        <animated.div
            style={{
                ...animate,
                position: 'fixed',
                width: '100%',
                height: '100%',
                overflowY: 'auto',
                overflowX: 'hidden',
            }}
            className={props.className}
        >
            {props.children}
        </animated.div>
    );
}

// direction : left = glissade d'un élément vers la gauche | right = glissade d'un élément vers la droite.
function LeftRightSlideAnimation(props) {
    if (props.direction === undefined || (props.direction.toLowerCase() !== 'right' && props.direction.toLowerCase() !== 'left')) {
        props.direction = 'right';
    }

    const [animate, setAnimate] = useSpring(() => ({
        marginLeft: `${props.direction === 'right' ? '-100%' : '100%'}`,
        from: { marginLeft: `${props.direction === 'right' ? '-100%' : '100%'}` },
        to: { marginLeft: '0%' },
        config: { duration: props.duration },
    }));
  
    useEffect(() => {
        setAnimate({ marginLeft: '0%' });
    }, [setAnimate]);
  
    return (
        <animated.div
            style={{
                ...animate,
                position: 'fixed',
                width: '100%',
                height: '100%',
                overflowY: 'auto',
                overflowX: 'hidden',
            }}
            className={props.className}
        >
            {props.children}
        </animated.div>
    );
}
  

export { LeftRightSlideAnimation, PageContentFadingAnimation, TopBottomSlideAnimation };