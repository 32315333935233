//LIBS
import { Link } from 'react-router-dom';

//UTILS
import config from 'utils/constants';

//COMPONENTS
import Image from 'components/common/Image';
import PingConnected from 'components/PingConnected';

//CONTAINERS
import PointGamification from 'containers/profils/PointGamification';

const sizes = {
    avatar: '?w=120&h=120&c=true&q=80',
};

function CardRoomSmall({ room }) {
    return (
        <>
            <div className="relative w-full my-2">
                <Link to={'/room/' + room?.slug} title={`${room?.question}`}>
                    <div className="flex flex-row items-center w-full relative">
                        <div className="flex-none relative w-14 h-auto mr-2">
                            {room?.user?.profile?.avatar?.url ? (
                                <Image
                                    src={room?.user?.profile?.avatar?.url + sizes.avatar}
                                    alt={room?.user.profile?.pseudo}
                                    dynamic
                                    loading="false"
                                    className="w-12 bg-white rounded-full"
                                />
                            ) : (
                                <img className="w-12 bg-white rounded-full" src={`${config.publicDir}profil_anonyme.png` + sizes.avatar} alt={room?.question} />
                            )}
                            {room?.user?.profile?.avatar?.url && (
                                <>
                                    <PointGamification gamification={room?.user?.gamification?.profile} />
                                    <PingConnected connexions={room?.user?.connexions} type="global" />
                                </>
                            )}
                        </div>
                        <div className="w-auto grow flex flex-wrap flex-col">
                            <span className="text-sm font-bold text-purpleSkills">{room.question?.substring(0, 50)}</span>
                            {/* <span className="text-xs text-gray-400">{user.profile.status}</span> */}
                        </div>
                    </div>
                </Link>
            </div>
        </>
    );
}

export default CardRoomSmall;
