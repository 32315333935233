import styles from 'components/svgs/spinner.css';

function Spinner() {
    return (
        <div className="relative">
            <div className={styles.spinner}>
                <svg
                    className="w-4 h-4 spinner"
                    viewBox="0 0 100 100"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <circle cx="50" cy="50" r="45" />
                </svg>
            </div>
        </div>
    );
}

export default Spinner;
