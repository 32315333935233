import { Fragment, useEffect, useRef, useState } from 'react';

//LIBS
import _ from 'lodash';
import { useLocation } from 'react-router-dom';

//STYLES
import './tabsMessagerie.css';
import useSwipe from 'hooks/useSwipe';
import useScroll from 'hooks/useScroll';
//UTILS
import { isMobile } from 'utils/functions';
//import { ChatIcon } from '@heroicons/react/solid';

//import CardProfilClone from '../../containers/discussions/CardProfilClone';
//import useScroll from 'hooks/useScroll';

function TabsMessagerie(props) {
    const refTabs = useRef(null);
    const swipeContainerRef = useRef(null);

    //const navigate = useNavigate();

    const { direction } = useSwipe({ ref: swipeContainerRef, threshold: 80 });
    const scrollDirection = useScroll();
    const location = useLocation();

    const [allTabs, setAllTabs] = useState(props.tabs);
    const [currentTab, setCurrentTab] = useState(props.initPosition ?? 0);

    function handleClick(e, tab) {
        e.stopPropagation();
        e.preventDefault();

        if (tab?.url) {
            window.history.replaceState(null, null, `${tab.url}`);
        }
        if (refTabs.current) {
            const items = refTabs.current.getElementsByClassName('Tabs_item_messagerie');
            _.forEach(items, (item) => {
                item.classList.remove('active');
            });
        }

        moveSlider(e.currentTarget.parentNode);

        if (typeof props?.refetchFav === 'function') {
            props?.refetchFav();
        }
    }

    function handleSwipe(direction) {
        if (refTabs.current) {
            const items = refTabs.current.getElementsByClassName('Tabs_item_messagerie');
            const arrItems = Array.from(items);

            arrItems.forEach((item) => {
                if (item.classList.contains('active')) {
                    setCurrentTab(arrItems.indexOf(item));
                }
            });

            if (currentTab > 0 && direction === 'right') {
                items[currentTab].classList.remove('active');
                moveSlider(items[currentTab - 1]);
                if (isMobile()) {
                    document.body.scrollTop = 0;
                    document.documentElement.scrollTop = 0;
                }
            } else if (currentTab < items.length - 1 && direction === 'left') {
                items[currentTab].classList.remove('active');
                moveSlider(items[currentTab + 1]);
                if (isMobile()) {
                    document.body.scrollTop = 0;
                    document.documentElement.scrollTop = 0;
                }
            }
        }
    }

    function moveSlider(elem) {
        elem.classList.add('active');
        const slider = getChildNodeIndex(elem);
        const width = parseInt(getComputedStyle(elem).width, 10);

        if (refTabs.current) {
            const sliderElem = refTabs.current.getElementsByClassName('Tabs_messagerie_slider');
            sliderElem[0].style.left = slider.left + 'px';
            sliderElem[0].style.width = width + 'px';
        }
    }

    function getChildNodeIndex(elem) {
        let left = 0;
        let position = 0;
        while ((elem = elem.previousSibling) != null) {
            if (elem.nodeType !== Node.TEXT_NODE) {
                const style = getComputedStyle(elem);

                left += parseFloat(style.width, 10);
                position++;
            }
        }

        setCurrentTab(position);

        return { position, left };
    }

    function init(tabPosition) {
        if (refTabs.current) {
            const items = refTabs.current.getElementsByClassName('Tabs_item_messagerie');

            _.forEach(items, (item, k) => {
                if (k === tabPosition) {
                    const timeout = setTimeout(() => {
                        moveSlider(item);
                        clearTimeout(timeout);
                    }, 300);
                }
            });
        }
    }

    useEffect(() => {
        if (props.tabs) {
            _.remove(props.tabs, (n) => {
                return n.visible === false;
            });
            setAllTabs(props.tabs);
        }
        //eslint-disable-next-line
    }, [props.tabs]);

    useEffect(() => {
        if (allTabs.length !== props.tabs.length) init(props.initPosition ?? 0);
        //eslint-disable-next-line
    }, [allTabs]);

    useEffect(() => {
        init(props.initPosition ?? 0);
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        setCurrentTab(0);
        init(props.initPosition ?? 0);
        //eslint-disable-next-line
    }, [location.key]);

    useEffect(() => {
        if (direction) {
            handleSwipe(direction);
            // Check if props.refetch is a function before calling it
        }
        //eslint-disable-next-line
    }, [direction]);

    /*  const handleGoToNotifications = useCallback(() => {
        navigate('/dashboard/notifications');
    }, [navigate]); */

    const stickyTabs = `w-auto -mx-2 sm:mx-0 sticky transition-all duration-300 ease-out ${
        scrollDirection === 'down' ? 'top-0 sm:top-[64px]' : 'top-[60px] sm:top-[64px]'
    }`;

    return (
        <>
            <div
                className={`${stickyTabs} z-10 bg-black`}
                /* style={{backgroundImage: 'linear-gradient(150deg, #E348FF 0%, #2600FC 100%)'}} */
            >
                {/* <div className={`${props.type === 'dashboard' ? '' : 'hidden'} flex flex-col w-full z-10`}>
                    <div className="flex justify-center gap-1 pt-2 px-4">
                        <span className={`w-44`}>
                            <img src={`${config.publicDir}logo_ai_transparent.png`} alt="ai_logo" />
                        </span>
                        <div className="w-7 justify-end flex text-graySkills">
                            <button id="button-notifications" onClick={handleGoToNotifications}>
                                <ChatIcon className="block w-7 h-7" />
                            </button>
                        </div>
                    </div> */}
                {/* {props.cloneId && (
                        <div className='bg-black text-white border-b border-white py-2'>
                            <div className='px-2 flex items-center justify-between'>
                                <CardProfilClone
                                    discussionId={`${authState.me?.id}.${props.cloneId}`}
                                    user={authState.me}
                                    clone={props.clone.data}
                                    onClick={(e) => props.handleCloneOpenDiscussion(e, props.cloneId, "cloneDiscussion")}
                                />
                                <button 
                                    className='w-36 p-2 bg-black font-bold rounded-full mr-2 border-y-2 border-x-8 border-[#BB7EFF] hover:bg-[#b235fe]'
                                    onClick={(e) => {
                                        e.preventDefault()
                                        e.stopPropagation()
                                        props.handleAiTrain()
                                    }}
                                >AI Train</button>
                            </div>
                        </div>
                    )} */}
                {/*  </div> */}

                <ul className="Tabs flex overflow-x-auto scrollbar-hide" ref={refTabs}>
                    {allTabs
                        ?.filter((i) => i.visible !== false)
                        ?.map((tab, k) => (
                            <Fragment key={k}>
                                {tab.visible !== false && (
                                    <li className={`Tabs_item_messagerie ${props.cssTabsItem ? props.cssTabsItem : 'text-sm'}`}>
                                        <a
                                            className="relative"
                                            href={`#${tab.key}`}
                                            onClick={(e) => {
                                                handleClick(e, tab);
                                            }}
                                        >
                                            {tab.label}
                                        </a>
                                    </li>
                                )}
                            </Fragment>
                        ))}
                    <li className="Tabs_messagerie_slider" role="presentation"></li>
                </ul>
            </div>
            <div className="overflow-y-auto grow -mx-2 sm:mx-0" ref={swipeContainerRef}>
                {allTabs
                    ?.filter((i) => i.visible !== false)
                    ?.map((tab, k) => (
                        <Fragment key={k}>
                            {k === currentTab ? (
                                <div className="h-full">
                                    <div className="Tabs_content h-full">{tab.content}</div>
                                </div>
                            ) : null}
                        </Fragment>
                    ))}
            </div>
        </>
    );
}

export default TabsMessagerie;
