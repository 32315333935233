import { useEffect } from 'react';
import { useCallback } from 'react';
import { useState } from 'react';

const useSwipe = ({ ref, threshold = 5 }) => {
    const [swipe, setSwipe] = useState({
        startX: 0,
        startY: 0,
        endY: 0,
        endX: 0,
        isSwiping: false,
        startTime: new Date(),
        direction: null,
        distance: 0,
    });

    // Debut du swipe
    const handleStart = useCallback(
        (e) => {
            // preventDefault && event.preventDefault();
            const touch = e.changedTouches[0];
            setSwipe({
                ...swipe,
                startX: touch.pageX,
                startY: touch.pageY,
                startTime: new Date().getTime(),
            });
        },
        [swipe]
    );
    // Fin du swipe
    const handleEnd = useCallback(
        (e) => {
            const touch = e.changedTouches[0];
            const direction = (touch.pageX - swipe.startX > threshold && 'right') || (swipe.startX - touch.pageX > threshold && 'left') || (touch.pageY - swipe.startY > threshold && 'down') || (swipe.startY - touch.pageY > threshold && 'up');
            const distance = (direction === 'left' && touch.pageX - swipe.startX) || (direction === 'right' && touch.pageX - swipe.startX) || (direction === 'up' && swipe.startY - touch.pageY) || (direction === 'down' && touch.pageY - swipe.startY);
            const time = (new Date().getTime() - swipe.startTime) / 1000;
            setSwipe((swipe) => ({
                ...swipe,
                endX: touch.pageX,
                endY: touch.pageY,
                isSwiping: false,
                elapsedTime: time,
                direction: direction,
                distance: distance,
            }));
            return swipe;
        },
        //eslint-disable-next-line
        [swipe]
    );

    const handleMove = useCallback(
        (e) => {
            const touch = e.changedTouches[0];
            const direction = (touch.pageX - swipe.startX > threshold && 'right') || (swipe.startX - touch.pageX > threshold && 'left') || (touch.pageY - swipe.startY > threshold && 'down') || (swipe.startY - touch.pageY > threshold && 'up');
            const distance = (direction === 'left' && touch.pageX - swipe.startX) || (direction === 'right' && touch.pageX - swipe.startX) || (direction === 'up' && swipe.startY - touch.pageY) || (direction === 'down' && touch.pageY - swipe.startY);
            const time = (new Date().getTime() - swipe.startTime) / 1000;
            setSwipe((swipe) => ({
                ...swipe,
                endX: touch.pageX,
                endY: touch.pageY,
                isSwiping: true,
                elapsedTime: time,
                direction: direction,
                speed: distance / time,
                distance: distance,
            }));
            return swipe;
        },
        //eslint-disable-next-line
        [swipe]
    );

    //Cancel swipe
    const handleCancel = useCallback((event) => {
        return null;
    }, []);

    //Events touch
    // {passive: false} pour enlever à défaut le scroll.
    // handleStart, handleEnd, handleCancel, handleMove
    useEffect(() => {
        // detectection zone de l'évenement
        const touchSurface = ref.current;
        touchSurface.addEventListener('touchstart', handleStart, {
            passive: true,
        });
        touchSurface.addEventListener('touchend', handleEnd, {
            passive: true,
        });
        touchSurface.addEventListener('touchcancel', handleCancel, {
            passive: true,
        });
        touchSurface.addEventListener('touchmove', handleMove, {
            passive: true,
        });

        return () => {
            touchSurface.removeEventListener('touchstart', handleStart, {
                passive: true,
            });
            touchSurface.removeEventListener('touchend', handleEnd, {
                passive: true,
            });
            touchSurface.removeEventListener('touchcancel', handleCancel, {
                passive: true,
            });
            touchSurface.removeEventListener('touchmove', handleMove, {
                passive: true,
            });
        };
    }, [handleStart, handleCancel, handleMove, handleEnd, ref]);
    return swipe;
};

export default useSwipe;
