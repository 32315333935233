import React, { useContext, useRef, useEffect, useCallback, useState } from 'react';
//API
import { putProfile } from 'api/users';
//COMPONENTS
import FieldInput from 'components/forms/fields/input';
import ProgressBar from 'components/common/ProgressBar';

//CONTEXTS
import { AuthContext } from 'context/authContext';
import { FormContext } from 'context/formContext';
//FORMS
import schema from 'components/forms/yup/stepUniverse';

//HOOKS
import useCurrentForm from 'hooks/useForm';

//LIBS
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
//STYLES
import 'components/forms/forms.css';
import { XCircleIcon } from '@heroicons/react/outline';

import { isMobile } from 'utils/functions';

function FormProfilStepUniverse(props) {
    const [readyToWatch, setReadyToWatch] = useState(false);
    const [progressBar, setProgressBar] = useState({ active: false, value: 0 });

    //CONTEXTS
    const [authState, , refresh] = useContext(AuthContext);
    const [formContextState, formContextDispatch] = useContext(FormContext);
    //REFS
    const currentForm = useRef(null);
    const seeLink = useRef(null);

    //HOOKS
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        setValue,
        clearErrors,
    } = useForm({
        resolver: yupResolver(schema),
    });

    //DISPATCH
    const dispatchAction = useCallback(() => {
        switch (formContextState.action) {
            case 'save':
                currentForm.current.click();
                break;
            default:
        }
        // eslint-disable-next-line
    }, [formContextState.action]);

    //EFFECTS
    useEffect(() => {
        refresh();
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (readyToWatch) {
            clearErrors();
        }
        //eslint-disable-next-line
    }, [readyToWatch]);

    useEffect(() => {
        dispatchAction();
    }, [dispatchAction]);

    useEffect(() => {
        const me = authState.me;
        if (me) {
            seeLink.current = '/profil/' + me.profile?.slug;
            formContextDispatch({ type: 'publish', status: true });

            setValue('linkWebsite', me.profile?.linkWebsite);
            setValue('linkYoutube', me.profile?.linkYoutube);
            setValue('linkInstagram', me.profile?.linkInstagram);
            setValue('linkSnapchat', me.profile?.linkSnapchat);
            setValue('linkLinkedin', me.profile?.linkLinkedin);
            setValue('linkTiktok', me.profile?.linkTiktok);
            setValue('linkTwitter', me.profile?.linkTwitter);

            setValue('price', me.visio?.price || 0);
            setValue('isFreeFirst', me.visio?.isFreeFirst || false);

            const timeoutId = setTimeout(() => {
                setReadyToWatch(true);
            }, 1000);

            return () => {
                clearTimeout(timeoutId);
            };
        }
        //eslint-disable-next-line
    }, [authState]);

    useCurrentForm(readyToWatch, watch);

    /* const handleCloneCreate = async () => {
        if(authState.isLogged) {
            
            try {
                const response = await createClone();
              
                switch (response.status) {
                    case 200:
                        toast('Votre clone a bien été créée', {
                            type: 'success',
                        });
                        break;
                    case 201:
                        toast('Votre clone existe déjà', { type: 'error' });
                        break;
                    case 202:
                        toast(response.data.message, { type: 'error' });
                        break;
                    default:
                        toast('Une erreur est survenue', { type: 'error' });
                        break;
                }
            } catch (e) {
                toast('Une erreur est survenue', { type: 'error' });
            }
        }
    }
 */
    const onSubmitClose = () => {
        handleSubmit((data) => onSubmit(data, 'close'))();
    };

    const onSubmitNext = () => {
        handleSubmit((data) => onSubmit(data, 'next'))();
    };

    const onSubmit = (data, button) => {
        if (errors.length > 0) return false;

        const fd = new FormData();
        for (var k in data) {
            if (data[k] !== undefined) {
                let item = null;
                item = data[k];
                fd.append(k, item);
            }
        }

        const put = putProfile(fd, function (e) {
            let progress = (e.loaded / e.total) * 100;
            formContextDispatch({ type: 'progress', status: progress });

            setProgressBar({ ...progressBar, active: true, value: progress });
        });
        put.then((res) => {
            if (res.status === 200) {
                if (!isMobile()) {
                    toast('Profil mis à jour', { type: 'success' });
                }
                if (button === 'close') {
                    props.handleCloneCreate();
                    props.handleGoToProfile();
                    props.setPage(0);
                } else if (button === 'next') {
                    props.setPage(props.page + 1);
                }
                refresh();
                setTimeout(() => {
                    formContextDispatch({ type: 'change', status: false });
                    setTimeout(() => {
                        formContextDispatch({ type: 'progress', status: 0 });
                    }, 400);
                }, 100);
            } else {
                toast('Une erreur est survenue', { type: 'danger' });
            }
        });
    };

    function handleSkipPage() {
        props.setPage(props.page + 1);
    }

    /* function submit() {
        handleSubmit(onSubmit)();
    } */

    return (
        <>
            <div className="w-full flex flex-col">
                {props.typeSteps === 'ai' ? (
                    <div className="text-white">
                        <h1 className="font-bold text-3xl">Votre univers</h1>
                        <p className="text-sm">Ces informations aideront votre Clone AI à être plus efficace.</p>
                    </div>
                ) : (
                    <h1 className="font-bold text-2xl text-purpleSkills">Votre univers</h1>
                )}
                {props.typeSteps !== 'ai' && (
                    <div className="absolute top-2 right-2">
                        <XCircleIcon className={`w-10 h-10 text-graySkills hover:text-blueMain hover:cursor-pointer`} onClick={onSubmitClose} />
                    </div>
                )}
                <form onSubmit={(e) => e.preventDefault()} className="form" autoComplete="off" encType="multipart/form-data">
                    <ProgressBar progress={progressBar.value} />
                    <div className="w-full flex flex-col">
                        <div className="flex flex-col  w-full">
                            <div className="flex flex-col gap-0">
                                <div className="w-full flex flex-col items-start gap-1 mb-1">
                                    <div>
                                        <FieldInput
                                            inputForm="checkbox"
                                            className={`${props.typeSteps === 'ai' ? 'text-white' : 'text-purpleSkills font-bold'}`}
                                            size="w-auto"
                                            name="isFreeFirst"
                                            type="checkbox"
                                            label="Offrir la première heure de visio"
                                            errors={errors}
                                            register={register}
                                        />
                                    </div>
                                </div>

                                <FieldInput
                                    className={`${props.typeSteps === 'ai' ? 'text-white border-white focus:border-white' : 'text-mainText'}`}
                                    inputForm={props.typeSteps === 'ai' ? props.typeSteps : 'flat'}
                                    typeLabel={props.typeSteps}
                                    name="price"
                                    label="€ Prix horaire pour une heure de visio"
                                    placeholder={props.typeSteps === 'ai' ? '' : '€ Prix horaire pour une heure de visio'}
                                    type="number"
                                    min="0"
                                    defaultValue={0}
                                    errors={errors}
                                    register={register}
                                />
                                <div className="grid grid-cols-2 md:grid-cols-2 gap-1 mt-2">
                                    <FieldInput
                                        type={props.typeSteps}
                                        inputForm={props.typeSteps === 'ai' ? props.typeSteps : 'flat'}
                                        typeLabel={props.typeSteps}
                                        name="linkWebsite"
                                        label={props.typeSteps === 'ai' ? 'URL site Internet' : false}
                                        placeholder={props.typeSteps === 'ai' ? '' : 'URL site Internet'}
                                        errors={errors}
                                        register={register}
                                    />
                                    {props.typeSteps !== 'ai' && (
                                        <>
                                            <FieldInput
                                                type={props.typeSteps}
                                                inputForm={props.typeSteps === 'ai' ? props.typeSteps : 'flat'}
                                                typeLabel={props.typeSteps}
                                                name="linkLinkedin"
                                                label={props.typeSteps === 'ai' ? 'URL LinkedIn' : false}
                                                placeholder={props.typeSteps === 'ai' ? '' : 'URL LinkedIn'}
                                                errors={errors}
                                                register={register}
                                            />
                                            <FieldInput
                                                type={props.typeSteps}
                                                inputForm={props.typeSteps === 'ai' ? props.typeSteps : 'flat'}
                                                typeLabel={props.typeSteps}
                                                name="linkTwitter"
                                                label={props.typeSteps === 'ai' ? 'URL X' : false}
                                                placeholder={props.typeSteps === 'ai' ? '' : 'URL X'}
                                                errors={errors}
                                                register={register}
                                            />
                                        </>
                                    )}
                                    <FieldInput
                                        type={props.typeSteps}
                                        inputForm={props.typeSteps === 'ai' ? props.typeSteps : 'flat'}
                                        typeLabel={props.typeSteps}
                                        name="linkTiktok"
                                        label={props.typeSteps === 'ai' ? 'URL TikTok' : false}
                                        placeholder={props.typeSteps === 'ai' ? '' : 'URL TikTok'}
                                        errors={errors}
                                        register={register}
                                    />
                                    <FieldInput
                                        type={props.typeSteps}
                                        inputForm={props.typeSteps === 'ai' ? props.typeSteps : 'flat'}
                                        typeLabel={props.typeSteps}
                                        name="linkSnapchat"
                                        label={props.typeSteps === 'ai' ? 'URL Snapchat' : false}
                                        placeholder={props.typeSteps === 'ai' ? '' : 'URL Snapchat'}
                                        errors={errors}
                                        register={register}
                                    />
                                    {props.typeSteps !== 'ai' && (
                                        <>
                                            <FieldInput
                                                type={props.typeSteps}
                                                inputForm={props.typeSteps === 'ai' ? props.typeSteps : 'flat'}
                                                typeLabel={props.typeSteps}
                                                name="linkYoutube"
                                                label={props.typeSteps === 'ai' ? 'URL YouTube' : false}
                                                placeholder={props.typeSteps === 'ai' ? '' : 'URL YouTube'}
                                                errors={errors}
                                                register={register}
                                            />
                                        </>
                                    )}
                                    <FieldInput
                                        type={props.typeSteps}
                                        inputForm={props.typeSteps === 'ai' ? props.typeSteps : 'flat'}
                                        typeLabel={props.typeSteps}
                                        name="linkInstagram"
                                        label={props.typeSteps === 'ai' ? 'URL Instagram' : false}
                                        placeholder={props.typeSteps === 'ai' ? '' : 'URL Instagram'}
                                        errors={errors}
                                        register={register}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <input ref={currentForm} type="submit" className="hidden" />
                </form>

                <div className="w-full flex gap-3 sm:mb-0 mt-1 justify-end">
                    <button
                        onClick={(e) => {
                            if (props.typeSteps === 'ai') {
                                props.handleGoToCloneAi();
                                props.handleCloneCreate();
                            } else {
                                e.preventDefault();
                                handleSkipPage();
                            }
                        }}
                        className={`w-auto px-8 py-2 font-bold ${props.typeSteps === 'ai' ? 'text-white' : 'text-graySkills'}`}
                    >
                        Plus tard
                    </button>
                    <button
                        className={`${
                            props.typeSteps === 'ai'
                                ? 'bg-[#E348FF] rounded-full hover:text-blueMain'
                                : 'bg-purpleSkills rounded-full button-border-gray hover:text-graySkills'
                        } 
                                    button font-bold text-white hover:bg-white justify-end w-auto px-8 py-2`}
                        onClick={(e) => {
                            e.preventDefault();
                            if (props.typeSteps === 'ai') {
                                props.handleGoToCloneAi();
                                props.handleCloneCreate();
                            } else {
                                onSubmitNext();
                            }
                        }}
                    >
                        {props.typeSteps === 'ai' ? 'Valider' : 'Suivant'}
                    </button>
                </div>
            </div>
        </>
    );
}

export default FormProfilStepUniverse;
