import { useEffect, useCallback, useState, useContext, useRef } from 'react';
import { useImperativeHandle, forwardRef } from 'react';
//UTILS
import { countObj, autoresize } from 'utils/functions';
import config from 'utils/constants';
//LIBS
import _ from 'lodash';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

//CONTEXTS
import { AuthContext } from 'context/authContext';

//API
//import { postAnswer as postAnswerRoom } from 'api/forum';
//import { postAnswer as postAnswerShoot } from 'api/shoots';
import { postAnswer as postAnswerRoom, postAnswerTo as postAnswerToRoom, postAnswerToAnswer } from 'api/forum';
import { postAnswerShoot, postAnswerToShoot, postAnswerToAnswerShoot } from 'api/shoots';

import { getForumResponse } from 'api/clone';

//FORMS
import schema from 'components/forms/yup/answer';

//HOOKS
import useSpeechToText from 'react-hook-speech-to-text';

//ICONS
import { PhotographIcon, PaperAirplaneIcon } from '@heroicons/react/solid';
import { LoginIcon } from '@heroicons/react/outline';

//COMPONENTS
import Modal from 'components/common/Modal';
import FieldFiles from 'components/forms/fields/Files';
import BuyCloneAi from 'components/common/BuyCloneAi';

//CONTAINERS
import Avatar from 'containers/profils/Avatar';
import UserLink from 'containers/profils/UserLink';
import useDetectKeyboardOpen from '../../hooks/useDetectKeyboardOpen';

function AnswerForm(props, ref) {
    const [countMedias, setCountMedias] = useState(0);
    const [openConfirmSubmit, setOpenConfirmSubmit] = useState(false);
    const [isAnswerEmpty, setIsAnswerEmpty] = useState(true);
    const [answerMedias, setAnswerMedias] = useState([]);
    const [openModalPictures, setOpenModalPictures] = useState(false);
    const [openModalNotAllowed, setOpenModalNotAllowed] = useState(false);
    const [waiting, setWaiting] = useState(false);
    const [aiInstruction, setAiInstruction] = useState(false);
    const [aiInstructionText, setAiInstructionText] = useState('');

    const [isDisabled, setIsDisabled] = useState(false);

    const [openUserLink, setOpenUserLink] = useState(false);

    const answerRef = useRef(null);
    const userLinkRef = useRef(null);

    const mobileButtonContainerRef = useRef(null);
    const desktopSubmitButtonRef = useRef(null);
    const mobileSubmitButtonRef = useRef(null);
    const mobileSubmitFocusButtonRef = useRef(null);

    const [authState] = useContext(AuthContext);
    const location = useLocation();

    const [isMessageCloneExceeded, setMessageCloneExceeded] = useState(false);
    const [messageCloneData, setMessageCloneData] = useState(null);

    const speech = useSpeechToText({
        continuous: true,
        useLegacyResults: false,
    });

    const [isTextareaFocused, setIsTextareaFocused] = useState(false);

    const isKeyboardOpen = useDetectKeyboardOpen();

    //FORM
    const {
        register,
        control,
        handleSubmit,
        formState: { errors },
        setValue,
        getValues,
        reset,
        watch,
    } = useForm({
        resolver: yupResolver(schema),
    });

    const { ref: registerRef, ...rest } = register('answer');
    const answer = useWatch({ control, name: 'answer' });

    useImperativeHandle(ref, () => ({
        reset() {
            reset();
            resetMedias();
        },
        focusTextarea() {
            setTimeout(() => {
                if (answerRef.current) {
                    answerRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    answerRef.current.focus();
                }
            }, 100); // Délai pour s'assurer que l'élément est visible avant de focuser
        },
    }));

    function resetMedias() {
        // Vider le champ `medias` dans le formulaire
        setValue('medias', []);
        // Réinitialiser l'état local des images
        setAnswerMedias([]);
        // Mettre à jour le compteur d'images
        setCountMedias(0);
    }

    function onKeyDown(e) {
        if (e.key === '@') {
            setOpenUserLink(true);
        }
    }

    function onCloseUserLink(user) {
        if (user !== undefined) {
            let newAnswer = answer ?? '';
            newAnswer += '@' + user?.profile?.slug + ' ';
            setValue('answer', newAnswer);
        }
        answerRef.current.focus();
        setOpenUserLink(false);
    }

    useEffect(() => {
        if (openUserLink) {
            userLinkRef.current.focus();
        }
    }, [openUserLink]);

    useEffect(() => {
        if (answer !== undefined) handleChange();
        //eslint-disable-next-line
    }, [answer]);

    const handleOpenInscription = useCallback(() => {
        document.getElementById('button-login')?.click();
    }, []);

    function handleOpenPictures() {
        setAnswerMedias(getValues('medias'));
        setOpenModalPictures(true);
    }

    function handleChange() {
        if (authState.isLogged) {
            if (authState?.me?.gamification?.profile >= 5) {
                const answerValue = getValues('answer');
                const mediasValue = getValues('medias');

                setCountMedias(countObj(mediasValue));
                setIsAnswerEmpty(!countObj(answerValue));
            } else {
                setOpenModalNotAllowed(true);
            }
        }
    }

    function submitAnswer() {
        const currentAnswer = getValues('answer');

        if (!currentAnswer || currentAnswer.trim() === '') {
            toast('Votre réponse est vide', { type: 'warning' });
            return;
        }

        if (!openUserLink) handleSubmit(onSubmit)();
    }

    function onSubmit() {
        if (errors.length > 0) return false;

        handleConfirm();
        setIsTextareaFocused(false);
        if (props?.options?.isShoot) {
            window.scrollTo(0, 0);
        }
        if (props.onCancelTarget) {
            props.onCancelTarget();
        }
        // setOpenConfirmSubmit(true);
    }

    function handleConfirm() {
        setIsDisabled(true);
        setOpenConfirmSubmit(false);
        setCountMedias(0);
        setIsAnswerEmpty(true);

        const data = getValues();
        data.itemId = props.item.id;
        data.type = props.item.type;

        // Si on répond à une réponse (principale ou sous-réponse)
        if (props.targetAnswer) {
            data.answerTo = data.answer; // Copier la réponse dans `answerTo`
            /* data.answer = ''; */ // Vider `answer` car on utilise `answerTo` pour la réponse
        }

        const fd = new FormData();
        for (var k in data) {
            if (data[k] !== undefined) {
                let item = data[k];
                if (k === 'medias') {
                    let files = data[k];
                    for (let i = 0; i < files.length; i++) {
                        fd.append(k, files[i]);
                    }
                } else {
                    fd.append(k, item);
                }
            }
        }

        let post = null;

        if (props.targetAnswer) {
            // Réponse à une réponse (principale ou sous-réponse)
            fd.append('answerId', props.targetAnswer._id);

            if (props.parentAnswer) {
                // Si c'est une sous-réponse
                fd.append('parentAnswer', props.parentAnswer._id);

                if (props.type === 'shoot') {
                    fd.append('userTo', props.targetAnswer.user.id);
                    post = postAnswerToAnswerShoot(fd);
                } else if (props.type === 'room') {
                    post = postAnswerToAnswer(fd);
                }
            } else {
                // Réponse à une réponse principale
                if (props.type === 'shoot') {
                    fd.append('userTo', props.targetAnswer.user.id);
                    post = postAnswerToShoot(fd);
                } else if (props.type === 'room') {
                    post = postAnswerToRoom(fd);
                }
            }
        } else {
            // Réponse au sujet principal
            if (props?.type === 'shoot') {
                post = postAnswerShoot(fd);
            } else if (props?.type === 'room') {
                post = postAnswerRoom(fd);
            }
        }

        if (post) {
            post.then((res) => {
                if (res.status === 200) {
                    //toast('Votre réponse a bien été postée', { type: 'success' });
                    reset();
                    props.refetch();
                } else {
                    toast('Une erreur est survenue', { type: 'danger' });
                }
                setIsDisabled(false);
            });
        } else {
            toast('Une erreur est survenue', { type: 'danger' });
            setIsDisabled(false);
        }
    }

    async function generateResponse() {
        setAiInstruction(false);
        const data = getValues();
        data.question = props.item.question;
        data.forumId = props.item.id;
        data.userId = authState?.me?.id;
        data.instructions = aiInstructionText;

        try {
            setWaiting(true);
            const getResponse = await getForumResponse(data);
            if (getResponse.status === 200) {
                let response = getResponse.data;
                setWaiting(false);
                setValue('answer', response);
                handleChange();
            } else if (getResponse.status === 201) {
                setWaiting(false);
                setMessageCloneData(getResponse.data);
                setMessageCloneExceeded(true);
            } else {
                toast('Une erreur est survenue réessayer plus tard', { type: 'danger' });
            }
        } catch (error) {
            console.error('An error occurred:', error);
            toast('Une erreur est survenue réessayer plus tard', { type: 'danger' });
        }
    }

    useEffect(() => {
        setValue('medias', []);

        //eslint-disable-next-line
    }, [, location]);

    const watchFields = watch('answer');
    useEffect(() => {
        const ta = document.getElementById('answer_textarea');
        if (ta) autoresize(ta);
    }, [watchFields]);

    useEffect(() => {
        const speechResult = _.last(speech.results);
        if (speechResult) {
            setIsAnswerEmpty(false);
            setValue('answer', speechResult.transcript);
            speech.stopSpeechToText();
        }
        //eslint-disable-next-line
    }, [speech.results]);

    useEffect(() => {
        if (!isKeyboardOpen) {
            const activeElement = document.activeElement;

            if (
                activeElement === desktopSubmitButtonRef.current ||
                activeElement === mobileSubmitButtonRef.current ||
                activeElement === mobileSubmitFocusButtonRef.current
            ) {
                // Si un bouton est focalisé, ne pas annuler la réponse
                return;
            }

            if (answerRef.current) {
                answerRef.current.blur();
            }

            // Vérifier si le champ de texte (answer) ou le champ medias est vide
            const currentAnswer = getValues('answer');
            const currentMedias = getValues('medias');

            if ((!currentAnswer && currentMedias?.length === 0) || (currentAnswer.trim() === '' && currentMedias?.length === 0)) {
                if (props.onCancelTarget) {
                    props.onCancelTarget();
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isKeyboardOpen]);

    // Fonction de gestion du blur
    function handleBlur(event) {
        const relatedTarget = event.relatedTarget;

        // Vérifier si le clic ou focus se déplace à l'intérieur du conteneur des boutons mobiles
        if (mobileButtonContainerRef.current && mobileButtonContainerRef.current.contains(relatedTarget)) {
            // Ne pas annuler la réponse si un clic ou un focus est à l'intérieur du conteneur
            return;
        }

        // Vérifier si le focus se déplace vers un des boutons de soumission spécifiques
        if (
            relatedTarget === desktopSubmitButtonRef.current ||
            relatedTarget === mobileSubmitButtonRef.current ||
            relatedTarget === mobileSubmitFocusButtonRef.current
        ) {
            return;
        }

        setIsTextareaFocused(false);

        if (props?.options?.isShoot) {
            window.scrollTo(0, 0);
        }

        // Vérifier si le champ de texte (answer) ou le champ medias est vide
        const currentAnswer = getValues('answer');
        const currentMedias = getValues('medias');

        if ((!currentAnswer && currentMedias?.length === 0) || (currentAnswer.trim() === '' && currentMedias?.length === 0)) {
            if (props.onCancelTarget) {
                props.onCancelTarget();
            }
        }
    }

    useEffect(() => {
        if (isTextareaFocused) document.querySelector('body').style.overflow = 'hidden';
        else document.querySelector('body').style.overflow = 'auto';
    }, [isTextareaFocused]);

    return (
        <>
            {isTextareaFocused && props?.options?.isShoot && (
                <div
                    className="absolute bottom-0 w-screen h-screen bg-graySkills -z-20 opacity-40 sm:hidden"
                    onClick={(e) => {
                        setIsTextareaFocused(false);

                        if (props?.options?.isShoot) {
                            window.scrollTo(0, 0);
                        }

                        // Vérifier si le champ de texte (answer) ou le champ medias est vide
                        const currentAnswer = getValues('answer');
                        const currentMedias = getValues('medias');

                        if ((!currentAnswer && currentMedias?.length === 0) || (currentAnswer.trim() === '' && currentMedias?.length === 0)) {
                            if (props.onCancelTarget) {
                                props.onCancelTarget();
                            }
                        }
                    }}
                ></div>
            )}

            <Modal
                open={openModalNotAllowed}
                onConfirm={{
                    label: "J'ai compris",
                    onClick: () => {
                        setValue('answer', '');
                        setOpenModalNotAllowed(false);
                    },
                }}
                onClose={{
                    onClick: () => {
                        setValue('answer', '');
                        setOpenModalNotAllowed(false);
                        if (props.onCancelTarget) {
                            props.onCancelTarget();
                        }
                    },
                }}
            >
                <div className="flex flex-col">
                    <h2 className="font-bold">Vous devez avoir un minimum de 5 points pour pouvoir bénéficier de cette fonctionnalité</h2>
                    <p className="text-sm text-purpleSkills mt-2">Vous pouvez y parvenir en complétant votre profil !</p>
                </div>
            </Modal>
            <Modal
                title="Confirmer votre réponse"
                open={openConfirmSubmit}
                onClose={{
                    onClick: () => {
                        setOpenConfirmSubmit(false);
                        if (props.onCancelTarget) {
                            setIsTextareaFocused(false);
                            props.onCancelTarget();
                        }
                    },
                }}
                onConfirm={{
                    label: 'Valider',
                    disabled: isDisabled,
                    onClick: (e) => {
                        e?.preventDefault();
                        handleConfirm();
                        if (props.onCancelTarget) {
                            setIsTextareaFocused(false);
                            props.onCancelTarget();
                        }
                        if (props?.options?.isShoot) {
                            window.scrollTo(0, 0);
                        }
                    },
                }}
            >
                <p>Voulez-vous vraiment poster cette réponse ?</p>
            </Modal>
            <Modal
                open={openModalPictures}
                onConfirm={{
                    label: 'Valider',
                    onClick: () => {
                        setOpenModalPictures(false);
                        handleChange();
                    },
                }}
                onClose={{
                    onClick: () => {
                        setOpenModalPictures(false);
                    },
                }}
            >
                <div className="flex flex-col">
                    {authState.isLogged && (
                        <div className="w-full">
                            <FieldFiles
                                name="medias"
                                label="Déposez ou cliquez ici pour uploader une image"
                                type="multiple"
                                multiple={true}
                                register={register}
                                initValue={answerMedias}
                                fromBlob={true}
                                readyToWatch={true}
                                onChange={(value) => {
                                    setValue('medias', value);
                                    handleChange();
                                }}
                                accepts={['image/png', 'image/jpeg', 'image/jpg']}
                            />
                        </div>
                    )}
                </div>
            </Modal>
            <Modal
                title="Votre clone AI répond pour vous :"
                open={aiInstruction}
                onClose={{
                    onClick: () => {
                        setAiInstruction(false);
                    },
                }}
                onConfirm={{
                    label: 'Générer',
                    onClick: generateResponse,
                }}
            >
                <textarea
                    rows="4"
                    className="w-full bg-lightGraySkills h-auto max-h-28 sm:text-sm card-item-question rounded-xl p-2"
                    style={{ height: 'auto' }}
                    placeholder={`Donnez lui vos instructions pour une réponse qui vous ressemble`}
                    onChange={(e) => {
                        setAiInstructionText(e.target.value);
                    }}
                ></textarea>
            </Modal>

            <div className={`answer-form-container bg-white z-50 p-2 ${props?.options?.isJob ? 'rounded-3xl' : ''}`}>
                {isMessageCloneExceeded && <BuyCloneAi state={isMessageCloneExceeded} setState={setMessageCloneExceeded} data={messageCloneData} />}

                {errors && errors['answer'] && (
                    <div className="pb-2">
                        <span className="text-danger-400 text-bold">{errors['answer'].message}</span>
                    </div>
                )}

                <form onSubmit={(e) => e.preventDefault()}>
                    <div className="flex flex-col w-full">
                        <div className={`flex flex-row w-full gap-2 ${isAnswerEmpty && !props?.options?.isShoot ? 'md:w-auto' : 'w-full'}`}>
                            <div className="flex-auto w-auto h-auto rounded-t-3xl rounded-b-3xl bg-white sm:bg-none sm:pr-2">
                                <div className="flex flex-row h-full items-center">
                                    <div className={`hidden sm:block h-auto my-auto mr-2`}>
                                        {authState.isLogged && (
                                            <Avatar onClick={() => answerRef.current.focus()} user={authState?.me} className="w-10" gamification={false} />
                                        )}
                                    </div>
                                    <div
                                        className={`flex flex-col w-auto grow sm:ml-0 p-2 rounded-3xl ${
                                            props?.options?.isJob ? 'bg-white' : 'bg-blancSkills'
                                        } sm:bg-white`}
                                    >
                                        {waiting ? (
                                            <div className="w-full">
                                                <span className="waiting-animation text-purpleSkills"></span>
                                            </div>
                                        ) : (
                                            <textarea
                                                ref={(e) => {
                                                    answerRef.current = e;

                                                    if (registerRef) {
                                                        registerRef(e);
                                                    }

                                                    if (typeof ref === 'function') {
                                                        ref(e);
                                                    } else if (ref && ref.current) {
                                                        ref.current = e;
                                                    }
                                                }}
                                                autoresize=""
                                                rows="1"
                                                disabled={authState.isLogged ? false : true}
                                                errors={errors}
                                                {...rest}
                                                name="answer"
                                                id="answer_textarea"
                                                placeholder={
                                                    authState.isLogged ? props.options.placeholder ?? 'Tapez votre réponse' : 'Connectez vous pour répondre'
                                                }
                                                className={`w-full text-sm ${
                                                    props?.options?.isJob ? 'bg-white' : 'bg-blancSkills'
                                                } sm:bg-white h-auto max-h-28 card-item-question pb-0`}
                                                onKeyDown={onKeyDown}
                                                onFocus={() => setIsTextareaFocused(true)}
                                                onBlur={(e) => {
                                                    handleBlur(e);
                                                }}
                                            ></textarea>
                                        )}
                                        <UserLink ref={userLinkRef} open={openUserLink} onClose={(user) => onCloseUserLink(user)} />
                                        {countMedias > 0 && <div className="card-item-question py-2 pb-0 text-xs">{countMedias} image(s)</div>}
                                    </div>
                                    {authState.isLogged && !props.options?.isShoot && (
                                        <>
                                            <div className="hidden md:flex flex-row items-center gap-2">
                                                <div id="medias" onClick={handleOpenPictures} className="cursor-pointer m-0">
                                                    <PhotographIcon className="relative h-9 w-9 text-graySkills hover:text-blueMain" />
                                                </div>
                                                {authState?.me?.clone && (
                                                    <>
                                                        <div className="min-h-[2rem]">
                                                            <button
                                                                type="submit"
                                                                onClick={() => setAiInstruction(true)}
                                                                className="w-auto h-auto p-2 bg-black text-white font-bold rounded-full mr-2 border-y-2 border-x-8 border-[#BB7EFF] hover:bg-[#b235fe]"
                                                            >
                                                                Réponse AI
                                                            </button>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>

                            {!authState.isLogged && (
                                <div className="w-fit relative md:hidden">
                                    <button
                                        type="submit"
                                        ref={mobileSubmitButtonRef}
                                        onClick={(e) => {
                                            handleOpenInscription();
                                        }}
                                        className="w-auto border border-blueMain bg-blueMain text-white p-2 rounded-full hover:border-gray-400 hover:bg-graySkills hover:text-white transition-colors duration-400"
                                    >
                                        <LoginIcon className="w-5 h-5" />
                                    </button>
                                </div>
                            )}
                            {authState.isLogged && !isTextareaFocused && answer && answer.trim() !== '' && (
                                <div className="w-fit relative md:hidden flex flex-col justify-end">
                                    <button
                                        type="submit"
                                        ref={mobileSubmitButtonRef}
                                        onClick={(e) => {
                                            submitAnswer();
                                        }}
                                        className="w-auto border border-blueMain bg-blueMain text-white p-2 rounded-full hover:border-gray-400 hover:bg-graySkills hover:text-white transition-colors duration-400"
                                    >
                                        <PaperAirplaneIcon className="w-5 h-5" />
                                    </button>
                                </div>
                            )}
                            {/* Faire apparaitre cette div si la textarea est focus */}
                            <div className="hidden sm:block w-fit h-auto self-center">
                                <div className="text-center w-fit h-auto">
                                    <div className={`hidden relative md:flex flex-row h-10 ${authState.isLogged && 'gap-2 w-full'}`}>
                                        <button
                                            type="submit"
                                            ref={desktopSubmitButtonRef}
                                            onClick={(e) => {
                                                if (authState.isLogged) {
                                                    submitAnswer();
                                                } else {
                                                    handleOpenInscription();
                                                }
                                            }}
                                            className="h-auto border border-blueMain text-white bg-blueMain px-4 flex justify-center items-center rounded-full hover:bg-white hover:text-blueMain transition-colors duration-400"
                                        >
                                            {authState.isLogged ? 'Envoyer' : 'Connexion'}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {(isTextareaFocused || props?.options?.isJob) && (
                            <div ref={mobileButtonContainerRef} className="sm:hidden flex justify-end items-center mt-2 gap-2">
                                {(authState.isLogged && !props.options?.isShoot) ||
                                    (authState.isLogged && props?.options?.isJob && (
                                        <>
                                            <div id="medias-answer" className="flex">
                                                <button className="w-7 h-7 flex relative text-graySkills" onClick={handleOpenPictures}>
                                                    <PhotographIcon className="absolute w-10 h-9 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
                                                </button>
                                            </div>

                                            <div id="generate-answer" className="flex">
                                                <button type="submit" onClick={() => setAiInstruction(true)} className="w-auto text-purpleSkills rounded-full">
                                                    <img className="w-7 h-7" src={`${config.publicDir}logo_ai_switch2.svg`} alt="ai_logo" />
                                                </button>
                                            </div>
                                        </>
                                    ))}

                                <div id="submit-answer" className="flex">
                                    <button
                                        type="submit"
                                        ref={mobileSubmitFocusButtonRef}
                                        onClick={(e) => {
                                            e?.preventDefault();
                                            if (authState.isLogged) {
                                                submitAnswer();
                                            } else {
                                                handleOpenInscription();
                                            }
                                        }}
                                        className="bg-blueMain w-12 h-7 flex justify-center items-center py-1 text-white rounded-full"
                                    >
                                        {authState.isLogged ? <PaperAirplaneIcon className="w-5 h-5" /> : <LoginIcon className="w-5 h-5" />}
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </form>
            </div>
        </>
    );
}

export default forwardRef(AnswerForm);
