import { useState } from 'react';

//CONTAINERS
import SearchFilterPlace from 'containers/search/SearchFilterPlace';

function SearchFilters(props) {
    const [placeOpen] = useState(true);

    // function openSearchPlace() {
    //     setPlaceOpen((o) => !o);
    // }

    return (
        <>
            {/* <div className="flex gap-2 mt-4"> */}
            {/* <button className="linkCard pt-1 px-4">Profils</button> */}
            {/* <button className="linkCard pt-1 px-4">Salons</button> */}
            {/* <button className="linkCard pt-1 px-4">Services</button> */}
            {/* <button className={`linkCard pt-1 px-4 ${placeOpen ? 'active' : ''}`} onClick={openSearchPlace}> */}
            {/*     Filtrer par lieu */}
            {/* </button> */}
            {/* </div> */}
            {placeOpen && (
                <div className={`flex gap-2 ${!props?.isMainSearch && 'mt-6'}`}>
                    <SearchFilterPlace refetch={(latlng) => props.refetch(latlng)} isMainSearch={props?.isMainSearch}/>
                </div>
            )}
        </>
    );
}

export default SearchFilters;
