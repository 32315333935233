import API from './axios.config';
import authHeader from "services/Auth.Header";

async function getConversationsClone() {
    const response = await API.post(
        'cloneDiscussions/pull',
        {},
        {
            headers: authHeader(),
        }
    );
    return response.data;
}

async function getOpportunities() {
    const response = await API.post(
        'cloneDiscussions/pullOpportunities',
        {},
        {
            headers: authHeader(),
        }
    );
    return response.data;
}

/* async function postCloneDialogue(data) {
    console.log(data)
    const response = await API.post('cloneDiscussions/conversation', data, { headers: authHeader() });
    return response.data;
} */

export {
    getConversationsClone,
    getOpportunities
};