//COMPONENTS
import AddShoot from 'components/common/shoot/AddShoot';
import Header from 'components/common/Header';
import ListShoots from 'containers/shoots/ListShoots';

//LAYOUT
import DashboardLayout from 'pages/layouts/Dashboard';

//LIBS
import { useParams } from 'react-router-dom';

//ICONS
import { VideoCameraIcon } from '@heroicons/react/solid';

//STYLES
import 'App.css';

//UTILS
import { PageContentFadingAnimation } from 'utils/animations';

function Services() {
    //HOOKS
    const params = useParams();

    /*  //API
    const currentService = useQuery(
        ['service', params.id],
        (params) => {
            if (params.queryKey[1] !== undefined) return getService(params.queryKey[1]);
        },
        { enabled: false }
    );
    
    useEffect(() => {
        if (params.id !== undefined) currentService.refetch();
        //eslint-disable-next-line
    }, [params.id]); */

    return (
        <DashboardLayout>
            <PageContentFadingAnimation duration={225}>
                <div className="App">
                    {!params.id && (
                        <>
                            <Header>
                                <h1 className="h1 text-main pb-3">Mes Shoots</h1>
                            </Header>

                            <div className="pb-3">
                                <AddShoot>
                                    <button className="text-2xl font-bold linkCardDashboard gap-2 w-full px-6 py-2" type="button">
                                        <VideoCameraIcon className="w-5 h-5" />
                                        Ajouter un shoot
                                    </button>
                                </AddShoot>
                            </div>
                        </>
                    )}

                    <main>{!params.id && <ListShoots />}</main>
                </div>
            </PageContentFadingAnimation>
        </DashboardLayout>
    );
}

export default Services;
