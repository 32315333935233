import { Fragment, useCallback, useEffect, useState } from 'react';

//UTILS
//import config from 'utils/constants';
import InAllLayouts from 'pages/layouts/InAllLayouts';
import { isMobile } from 'utils/functions';

//HOOKS
import useScroll from 'hooks/useScroll';
//import useNotifications from 'hooks/useNotifications';
//API
//import { getNotifications } from 'api/discussions';

//COMPONENTS
import MainNavSearch from 'components/common/MainNavSearch';
import MainNavMobile from 'components/common/MainNavMobile';
import MainSearchBar from 'components/common/mainSearch/MainSearchBar';

//CONTAINERS
import ForumSearchBarV2 from 'containers/searchBar/ForumSearchBarV2';
import SearchFiltersV2 from 'containers/search/SearchFiltersV2';
import SearchFilters from 'containers/search/SearchFilters';
//LIBS
import { useLocation, useNavigate } from 'react-router-dom';
import Banner from 'components/common/Banner';

//CONTEXTS
import { AuthContext } from 'context/authContext';
import { useContext } from 'react';
//import { BellIcon } from '@heroicons/react/solid';
//import { StoreContext } from 'context/storeContext';

function SearchLayout(props) {
    //HOOKS
    const location = useLocation();
    const scrollDirection = useScroll();
    const navigate = useNavigate();
    const [authState] = useContext(AuthContext);

    const [isHomeLocation, setIsHomeLocation] = useState(false);
    const [isSearchLocation, setIsSearchLocation] = useState(false);

    //contexts
    /*  const store = useContext(StoreContext);
    const discussionNotifications = useNotifications(getNotifications);

    const handleGoToNotifications = useCallback(() => {
        if (authState.isLogged) navigate('/dashboard/notifications');
        else document.getElementById('button-login')?.click();
        //eslint-disable-next-line
    }, [authState, navigate]); */

    useEffect(() => {
        setIsHomeLocation(location.pathname === '/' ? true : false);
        setIsSearchLocation(location.pathname === '/search' ? true : false);
    }, [location.pathname]);

    const handleGoToSearch = useCallback(() => {
        navigate('/search');
    }, [navigate]);

    /* const handleGoToCloneAI = useCallback(() => {
        navigate('/dashboard/clone-ai');
    }, [navigate]); */

    return (
        <Fragment>
            {props?.noContain && (
                <div
                    onClick={() => {
                        /*   setOpenedJobDetail(false); */
                        /* closeJobDetail();
                        window.history.replaceState({}, '', `/jobs`); */
                    }}
                    className={`fixed top-0 left-0 w-full h-full backdrop-filter backdrop-blur-xs bg-blueMain/10 flex justify-end`}
                />
            )}
            <InAllLayouts />
            <div className="flex flex-col min-h-screen container-fluid">
                <div className="flex-grow">
                    <div
                        className={`hidden md:block sticky z-20 ${isSearchLocation ? 'h-auto pb-2' : 'h-16 max-h-16'} ${
                            props?.noContain ? '' : 'container-fluid mx-auto'
                        } w-full px-4 bg-white top-0`}
                    >
                        <div className={`${props?.noContain ? 'w-5/12' : ''}`}>
                            <MainNavSearch isSearchLocation={isSearchLocation} onRefectch={props?.onRefectch} onFilter={props?.onFilter} />
                        </div>
                    </div>
                    <div
                        className={`md:hidden sticky w-full top-0 md:top-16 z-10 pt-4 px-4 bg-white transition-all duration-300 ease-out ${
                            scrollDirection === 'down' && !location.pathname?.includes('shoots') ? '-top-[125px]' : 'top-0'
                        }`}
                    >
                        <div className={`${isMobile() && 'flex flex-col'}`}>
                            <div className={`flex gap-2 top-0.5 pb-4`}>
                                <div className="flex gap-4 w-full">
                                    <div className="flex flex-row gap-2 w-full">
                                        <div className="w-7/12 sm:w-full" onClick={handleGoToSearch}>
                                            <MainSearchBar isInNavBar={true} />
                                        </div>

                                        <div className={`w-5/12 sm:hidden`}>
                                            <SearchFilters refetch={(latlng) => props?.onRefectch(latlng)} isMainSearch={true} />
                                        </div>
                                    </div>

                                    {/*  <div className="flex flex-col gap-2 w-full">
                                        <div className="w-full" onClick={handleGoToSearch}>
                                            <MainSearchBar isInNavBar={true} />
                                        </div>
                                    </div> */}

                                    {/* <div className="w-4">
                                        <button id="button-notifications" onClick={handleGoToNotifications}>
                                            <div className="relative text-graySkills text-sm flex flex-col items-center w-auto">
                                                <div className="absolute -top-3">
                                                        {(store.get('notifications')?.length > 0 || discussionNotifications) && (
                                                            <>
                                                                <div className="sm:hidden flex justify-center items-center text-white text-xs font-semibold absolute -top-2 -right-3 bg-red p-1 h-5 w-5 rounded-full">
                                                                    <span>{discussionNotifications+store.get('notifications').length}</span>
                                                                </div>
                                                                {store.get('notifications')?.length > 0 && (
                                                                    <div className="hidden sm:flex justify-center items-center text-white text-xs font-semibold absolute -top-2 -right-3 bg-red p-1 h-5 w-5 rounded-full">
                                                                        <span>{store.get('notifications').length}</span>
                                                                    </div>
                                                                )}                                                 
                                                            </>
                                                        )}

                                                    <BellIcon className="block w-6 h-6 " />
                                                </div>
                                            </div>
                                        </button>
                                    </div> */}
                                </div>
                            </div>
                            {isMobile() && (
                                <div className="w-full pb-2">
                                    <SearchFiltersV2 onRefectch={props?.onRefectch} onFilter={props?.onFilter} />
                                </div>
                            )}
                        </div>
                        {isHomeLocation && (
                            <div className="hidden sm:block pb-2">
                                <ForumSearchBarV2 />
                            </div>
                        )}
                    </div>
                    <div className={`relative ${props?.noContain ? 'w-[51%]' : 'container mx-auto'} px-2 sm:px-4`}>
                        <div className="w-full">{props?.children}</div>
                    </div>
                </div>
            </div>
            {!authState.isLogged && (
                <>
                    <div className={`z-20 sticky sm:bottom-0 transition-all duration-300 ease-out bottom-[68px]`}>
                        <Banner />
                    </div>
                </>
            )}
            {/*  {authState.isLogged && (
                <button onClick={handleGoToCloneAI} className={`sm:hidden z-10 sticky bottom-20 left-[18.5rem] w-12 cursor-pointer`}>
                    <img src={`${config.publicDir}logo_ai_mobile.svg`} alt="ai_logo" />
                </button>
            )} */}
            <MainNavMobile />
        </Fragment>
    );
}

export default SearchLayout;
