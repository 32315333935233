import { useCallback, useState, useRef, useContext } from 'react';

//LIBS
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

//UTILS
import { isMobile } from 'utils/functions';
import config from 'utils/constants';

//CONTEXTS
import { AuthContext } from 'context/authContext';

//HOOKS
import UseAuth from 'hooks/useAuth';

//ICONS
import { UserIcon } from '@heroicons/react/solid';
// import { UserCircleIcon, CogIcon, SearchIcon, UserIcon, BellIcon } from '@heroicons/react/solid';
// import { LoginIcon, LogoutIcon, BadgeCheckIcon, QuestionMarkCircleIcon, MenuIcon, PencilAltIcon } from '@heroicons/react/outline';

//COMPONENTS
import Button from 'components/common/Button';
// import MainSearch from 'components/common/mainSearch/MainSearch';
import Notifications from 'components/common/Notifications';
import Modal from 'components/common/Modal';
import FormConnexion from 'components/forms/Connexion';
// import MainSearchBar from 'components/common/mainSearch/MainSearchBar';

function MainNav() {
    const [isConnexionOpen, setIsConnexionOpen] = useState(false);
    // const [openMainSearch, setOpenMainSearch] = useState(false);
    const [openedMenu, setOpenedMenu] = useState(false);
    const [modalConfirmLabel, setModalConfirmLabel] = useState('Valider');
    // eslint-disable-next-line no-unused-vars
    const [isLogged, setIsLogged] = useState(false);
    //CONTEXTS
    const [authState] = useContext(AuthContext);
    //HOOKS
    const navigate = useNavigate();
    const { logout } = UseAuth();
    //REFS
    const loginRef = useRef(null);

    const handleOpenConnexion = useCallback((e) => {
        e.preventDefault();
        setOpenedMenu(false);
        setIsConnexionOpen(true);
        setIsLogged(false);
    }, []);

    const handleLogin = useCallback(() => {
        loginRef.current.login();
    }, []);

    const handleGoToProfile = useCallback(() => {
        navigate('/dashboard/profil');
    }, [navigate]);
    // const handleGoToProfilePage = useCallback(() => {
    //     navigate('/profil/' + authState.me?.profile?.slug);
    //     //eslint-disable-next-line
    // }, [authState, navigate]);

    // const handleGoToSubscription = useCallback(() => {
    //     navigate('/subscription');
    // }, [navigate]);

    // const handleGoToHowTo = useCallback(() => {
    //     navigate('/comment-ca-marche');
    // }, [navigate]);

    // const handleGoToWhyRegister = useCallback(() => {
    //     navigate('/pourquoi-s-inscrire');
    // }, [navigate]);

    // const handleOpenSearch = useCallback(() => {
    //     setOpenMainSearch(true);
    // }, []);

    const openMenu = (e) => {
        if (authState.isLogged) setOpenedMenu(openedMenu ? false : true);
        else handleOpenConnexion(e);
        //eslint-disable-next-line
    };

    const handleLogout = useCallback(() => {
        setOpenedMenu(false);
        logout();
        //eslint-disable-next-line
    }, []);

    function onLogged(status) {
        setIsLogged(status);
    }

    return (
        <>
            <button id="button-login" className="hidden" onClick={handleOpenConnexion}></button>
            <nav className="bg-blueMain z-20 container w-container mx-auto">
                {/* <div className="flex flex-row justify-between items-center md:flex-none w-auto"> */}
                <div className="hidden md:flex flex-nowrap py-3 order-1 items-center justify-between">
                    <div className="">
                        <Link to={isMobile() ? '/home' : '/'}>
                            <img src={`${config.publicDir}logo_blanc.svg`} alt="logo" className="h-10" />
                        </Link>
                    </div>
                    <div className="text-white">
                        <p className="text-base font-bold">Le moteur de réponses et d'échanges professionnels</p>
                    </div>
                    <div className="flex flex-row">
                        {authState.isLogged && (
                            <div className="pr-4">
                                <Notifications />
                            </div>
                        )}

                        <div className="w-auto mx-2 ml-0">
                            <Button
                                label={<UserIcon className="w-6 h-5 mx-auto" />}
                                justIcon={true}
                                css="w-8 h-8 bg-white rounded-full text-blueMain"
                                onClick={openMenu}
                            />
                        </div>
                    </div>
                </div>
                {openedMenu && (
                    <div className="block fixed  z-50 top-14 right-0 bg-blueMain w-auto h-screen flex-col justify-end shadow-2xl">
                        <ul className="w-auto p-8 text-right text-white text-sm">
                            {/* <li className="font-bold mb-2">Mon compte</li> */}
                            {authState.isLogged ? (
                                <>
                                    <li className="my-2 ml-2">
                                        <Link to={`/profil/${authState.me?.profile?.slug}`}>Voir mon Smart profil</Link>
                                    </li>
                                    <button onClick={handleGoToProfile}>Modifier mon profil</button>
                                </>
                            ) : (
                                <li className="my-2 ml-2 hover:text-blueMain hover:bg-blancSkills">
                                    <button onClick={handleOpenConnexion}>Connexion / Inscription</button>
                                </li>
                            )}

                            {authState.isLogged && (
                                <>
                                    <li className="my-2 ml-2">
                                        <Link to="/dashboard/services">Ma Boutique</Link>
                                    </li>
                                    <li className="my-2 ml-2">
                                        <Link to="/dashboard/visios/asked">Mes visios</Link>
                                    </li>
                                    <li className="my-2 ml-2">
                                        <Link to="/dashboard/notifications">Mes notifications</Link>
                                    </li>
                                    <li className="my-1 ml-2 fixed bottom-4 right-8">
                                        <button onClick={handleLogout}>Déconnexion</button>
                                    </li>
                                </>
                            )}
                        </ul>
                    </div>
                )}
            </nav>
            <Modal
                open={isConnexionOpen}
                onClose={{
                    onClick: () => {
                        setIsConnexionOpen(false);
                    },
                }}
                onConfirm={
                    !authState.isLogged
                        ? {
                              label: modalConfirmLabel,
                              class: 'font-bold',
                              onClick: handleLogin,
                          }
                        : false
                }
            >
                <FormConnexion
                    ref={loginRef}
                    onLogged={() => setIsConnexionOpen(false)}
                    isLogged={(status) => onLogged(status)}
                    modalConfirmLabel={(label) => setModalConfirmLabel(label)}
                />
            </Modal>
        </>
    );
}
export default MainNav;
