import API from "./axios.config";
import authHeader from "services/Auth.Header";

async function postComment(data) {
  const response = await API.post("comments/push", data, {
    headers: authHeader(),
  });
  return response;
}

async function getComments(id) {
  const response = await API.get("comments/pull/" + id);
  return response.data;
}

export { postComment, getComments };
