import { useEffect } from 'react';

//API
import { getUsersP } from 'api/headless';

//LIBS
import { useNavigate } from 'react-router-dom';
import { useHeadlessModule } from 'libs/headless';

//ICONS
import { HiOutlineUsers, HiOutlineCurrencyEuro, HiFire } from 'react-icons/hi';
import { BiMessageCheck } from 'react-icons/bi';
import { TiMessages } from 'react-icons/ti';
import { MdDashboard } from 'react-icons/md';
import { VscDebugConsole } from 'react-icons/vsc';
import { AiFillGoogleSquare } from 'react-icons/ai';

//COMPONENTS
import CardProfilHeadless from 'containers/profils/CardProfilHeadless';

//HEADLESS MODULES
import ModuleCounter from 'libs/headless/modules/Counter';
import ModuleList from 'libs/headless/modules/list';
import ModuleLogs from 'libs/headless/modules/logs';
import ModuleChart from 'libs/headless/modules/chart';
import ModuleGtm, { useGtm } from 'libs/headless/modules/gtm';

function Custom(props) {
    return <p>Je suis custom {props?.test}</p>;
}

export default function Crm() {
    const navigate = useNavigate();

    const headlessModule = useHeadlessModule();

    const gtm = useGtm();

    useEffect(() => {
        gtm.init();

        headlessModule.add('custom', <Custom />);
        headlessModule.add('counter', <ModuleCounter />);
        headlessModule.add('list', <ModuleList />);
        headlessModule.add('logs', <ModuleLogs />);
        headlessModule.add('chart', <ModuleChart />);
        headlessModule.add('gtm', <ModuleGtm />);

        //eslint-disable-next-line
    }, []);

    const headlessConfig = {
        pageDefault: 'dashboard',
        // pageDefault: 'hlConfigHelp',
        firebase: {
            apiKey: 'AIzaSyAb_-RgnnrrN-TrA7HCcQIbaeAI23tUIrg',
            authDomain: 'headless-28cc7.firebaseapp.com',
            databaseURL: 'https://headless-28cc7-default-rtdb.europe-west1.firebasedatabase.app',
            projectId: 'headless-28cc7',
            storageBucket: 'headless-28cc7.appspot.com',
            messagingSenderId: '658525197609',
            appId: '1:658525197609:web:3e64edb8d9eef487825338',
        },
        api: {
            baseUrl: process.env.NODE_ENV === 'production' ? 'https://api.skillsmarket.tv/api' : 'http://localhost:8080/api',
        },
        pages: [
            {
                page: { label: 'Tableau de bord', key: 'dashboard', icon: <MdDashboard /> },
                modules: [
                    {
                        chart: {
                            request: { route: 'headless/stats', data: { models: ['user'] } },
                        },
                    },
                    {
                        grid: 3,
                        row: [
                            {
                                counter: {
                                    label: "Nombre d'utilisateurs",
                                    icon: <HiOutlineUsers className="w-6 h-6" />,
                                    request: { route: 'headless/count', data: { model: 'user' } },
                                },
                            },
                            {
                                counter: {
                                    label: 'Nombre de services',
                                    icon: <HiOutlineCurrencyEuro className="w-6 h-6" />,
                                    request: { route: 'headless/count', data: { model: 'service' } },
                                },
                            },
                            {
                                counter: {
                                    label: 'Nombre de shoots',
                                    icon: <HiOutlineCurrencyEuro className="w-6 h-6" />,
                                    request: { route: 'headless/count', data: { model: 'shoot' } },
                                },
                            },
                            {
                                counter: {
                                    label: 'Nombre de forums',
                                    icon: <TiMessages className="w-6 h-6" />,
                                    request: { route: 'headless/count', data: { model: 'forum' } },
                                },
                            },
                            {
                                counter: {
                                    label: 'Nombre de commentaires',
                                    icon: <BiMessageCheck className="w-6 h-6" />,
                                    request: { route: 'headless/count', data: { model: 'comment' } },
                                },
                            },
                            {
                                counter: {
                                    label: "Nombre d'intéractions",
                                    icon: <HiFire className="w-6 h-6" />,
                                    request: { route: 'headless/count', data: { model: 'counter' } },
                                },
                            },
                        ],
                    },
                ],
            },
            {
                page: {
                    label: 'Utilisateurs',
                    key: 'users',
                    icon: <HiOutlineUsers />,
                    actions: [
                        {
                            label: 'Filtrer par date',
                        },
                    ],
                },
                modules: [
                    {
                        list: {
                            label: 'Derniers inscrits',
                            uniqKey: 'infiniteUsersListHeadless',
                            result: getUsersP,
                            item: (item) => <CardProfilHeadless user={item} />,
                            actions: [
                                {
                                    label: 'Modifier le profil',
                                    onClick: (item, refetch) => navigate('/profil/' + item.profile.slug),
                                    hideAfter: true,
                                },
                            ],
                        },
                    },
                ],
            },
            {
                page: {
                    label: 'GTM',
                    key: 'gtm',
                    icon: <AiFillGoogleSquare />,
                },
                modules: [{ gtm: {} }],
            },
            {
                page: {
                    label: 'Logs',
                    key: 'logs',
                    icon: <VscDebugConsole />,
                    role: ['admin'],
                },
                modules: [
                    { logs: { label: 'Infos du jour', request: { route: 'headless/logs', refetchEvery: 10000 } } },
                    { logs: { label: 'Alertes du jour', request: { route: 'headless/logs?&type=warn' } } },
                    { logs: { label: 'Erreurs du jour', request: { route: 'headless/logs?&type=error' } } },
                ],
            },
        ],
    };
    return headlessConfig;
}
