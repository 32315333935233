import { useState, useImperativeHandle, forwardRef, useContext, useEffect, useCallback, useRef } from 'react';

//LIBS
//import _ from 'lodash';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// import GoogleLogin from 'react-google-login';
import { passwordStrength } from 'check-password-strength';

//HOOKS
import UseAuth from 'hooks/useAuth';

//FORMS
import schema from 'components/forms/yup/signup';

//CONTEXTS
import { AuthContext } from 'context/authContext';

//COMPONENTS
import Alert from 'components/Alert';
import FieldSelect from 'components/forms/fields/Select';
import { Link } from 'react-router-dom';

function FormConnexion(props, ref) {
    //CONTEXTS
    const [authState] = useContext(AuthContext);

    //HOOKS
    // eslint-disable-next-line no-unused-vars
    const {
        isSubmitted,
        isForgotSubmitted,
        isSignupSubmitted,
        hasError,
        login,
        forgotPassword,
        signUp,
        // reload,
    } = UseAuth();

    //HOOKS
    // const navigate = useNavigate();
    /*  const [inputsConnexion, setInputsConnexion] = useState({
         email: '',
         password: '',
         confirm: '',
         pseudo: '',
     }); */
    const [inputsConnexion, setInputsConnexion] = useState({
        email: '',
        password: '',
        confirm: '',
        firstname: '',
        lastname: '',
        society: '',
        typeInscription: '',
    });
    const [isForgot, setIsForgot] = useState(false);
    const [isSignup, setIsSignup] = useState(false);
    const [strongPwd, setStrongPwd] = useState(null);
    const [typeInscription, setTypeInscription] = useState(null);

    const ggRef = useRef();

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        // setValue,
        getValues,
    } = useForm({
        resolver: yupResolver(schema),
    });

    const renderGoogleButton = useCallback(() => {
        if (!window.google || isForgot || isSignup) return;

        window.google.accounts.id.renderButton(ggRef.current, {
            theme: 'outline',
            size: 'medium',
            shape: 'pill',
        });
    }, [isForgot, isSignup]);

    useEffect(() => {
        if (isSignup) {
            props.modalConfirmLabel("S'inscrire");
        } else if (isForgot) {
            props.modalConfirmLabel('Réinitialiser');
        } else {
            props.modalConfirmLabel('Se connecter');
        }
        //eslint-disable-next-line
    }, [isSignup, isForgot]);

    useEffect(() => {
        if (window.google) {
            renderGoogleButton();
        } else {
            const checkGoogleLoaded = setInterval(() => {
                if (window.google) {
                    renderGoogleButton();
                    clearInterval(checkGoogleLoaded);
                }
            }, 100);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [renderGoogleButton, window.google]);

    useEffect(() => {
        if (isSubmitted && authState.isLogged) {
            props.isLogged(true);
        }
        //eslint-disable-next-line
    }, [isSubmitted]);

    // useEffect(() => {
    //     if (isSignupSubmitted) {
    //         setTimeout(() => {
    //             navigate('/dashboard/profil');
    //         }, 1500);
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [isSignupSubmitted]);

    const onSubmit = useCallback(() => {
        if (isSubmitted && authState.isLogged) {
            props.onLogged();
        }
    }, [authState.isLogged, props, isSubmitted]);

    //EFFECTS
    useEffect(() => {
        onSubmit();
    }, [onSubmit]);

    function onSubmitSignUp(data) {
        //console.log('submit');
        var existingScript = document.querySelector('script[data-layer-script]');
        if (!existingScript) {
            var script = document.createElement('script');
            script.setAttribute('data-layer-script', '');
            script.innerHTML = `
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                    'event': 'aiform',
                    'category': 'prospect',
                    'action': 'clic',
                    'label': 'ai'
                });
            `;
            document.head.appendChild(script);
        }
        data.type = 'cloneAI';
        if (strongPwd?.allowed === true) signUp(data);
    }

    function handleChangeFormConnexion(e) {
        setInputsConnexion({
            ...inputsConnexion,
            [e.target.name]: e.target.value,
        });
    }

    useImperativeHandle(ref, () => ({
        login: () => {
            setInputsConnexion({
                ...inputsConnexion,
                type: 'cloneAI',
            });
            if (!isForgot && !isSignup) login(inputsConnexion);
            else if (isForgot) forgotPassword(inputsConnexion);
            else if (isSignup && !isSignupSubmitted) {
            handleSubmit(onSubmitSignUp)();
            }
        },
        openSignup: () => {
            setIsSignup(true);
        },
    }));

    // const responseGoogle = (response, type) => {
    //     loginByOauth(response.tokenObj.id_token, type);
    // };

    useEffect(() => {
        const sPwd = getValues('password');
        const strength = passwordStrength(sPwd);
        // setStrongPwdContains(strength.contains);
        // setStrongPwdLength(strength.length);

        switch (strength.value) {
            default:
                setStrongPwd(null);
                break;
            case 'Too weak':
                setStrongPwd({
                    class: 'bg-red w-1/4',
                    contains: strength.contains,
                    length: strength.length,
                    allowed: true,
                });
                break;
            case 'Weak':
                setStrongPwd({
                    class: 'bg-warning-500 w-2/4',
                    contains: strength.contains,
                    length: strength.length,
                    allowed: true,
                });
                break;
            case 'Medium':
                setStrongPwd({
                    class: 'bg-warning-500 w-3/4',
                    contains: strength.contains,
                    length: strength.length,
                    allowed: true,
                });
                break;
            case 'Strong':
                setStrongPwd({
                    class: 'bg-success-500 w-full',
                    contains: strength.contains,
                    length: strength.length,
                    allowed: true,
                });
                break;
        }
        if (strength.length <= 0) setStrongPwd(null);
        //eslint-disable-next-line
    }, [watch('password')]);

    return (
        <>
            {/* {isSubmitted && authState.isLogged && ( */}
            {/*     <div className="w-full flex flex-col justify-center"> */}
            {/*         <p className="text-center font-semibold text-blue-800">Bienvenue sur SkillsMarket !</p> */}
            {/*     </div> */}
            {/* )} */}

            {!isForgot && !isSignup && !authState.isLogged && (
                <>
                    <div className="bg-black sm:rounded-t-xl text-center text-white -mx-2 md:-mx-8 -mt-8  font-bold px-2 md:px-8 py-4">
                        <h1 className="text-xl">Accédez à votre Clone AI</h1>
                    </div>
                    {/* <Alert type="warning"> */}
                    {/*     <strong className="uppercase">Nouvelle version:</strong> 1ère connexion depuis la nouvelle version ? Pour récupérer votre ancien compte il suffit de faire "mot de passe oublié". */}
                    {/* </Alert> */}
                    {/* {isSubmitted && authState.isLogged && <Alert type="success" message="Vous êtes connecté(e)" />} */}
                    {hasError && isSubmitted && !authState.isLogged && (
                        <Alert type="danger" message="Mot de passe ou identifiant incorrect, vous pouvez réinitialiser votre mot de passe." />
                    )}
                    {/* {hasError && !authState.isLogged && <Alert type="danger" message="Le formulaire contient des erreurs" />} */}
                    <p className="mt-8 text-white text-sm justify-center flex flex-row">
                        <button className="font-bold text-xl w-56 border border-white rounded-full py-1" onClick={() => setIsSignup(true)}>
                            Créer mon clone AI
                        </button>
                    </p>
                    <p className="text-center text-white font-bold text-lg mt-4">ou</p>
                    <div className="m-auto text-center flex justify-center mt-4">
                        <div ref={ggRef}></div>
                    </div>
                    <p className="text-center text-white font-bold text-lg mt-4">ou</p>
                    <form className="mt-2 m-0" autoComplete="off">
                        <p className="text-left text-lg text-white font-bold">Se connecter :</p>
                        <div className="relative z-0 w-full mt-6 mb-6 group">
                            <input
                                type="Ai"
                                name="email"
                                value={inputsConnexion.email}
                                onChange={handleChangeFormConnexion}
                                className="inputAi_floating peer"
                                placeholder=" "
                                required
                            />
                            <label htmlFor="floating_email" className="inputAi_labelFloating">
                                Adresse email
                            </label>
                        </div>
                        <div className="relative z-0 w-full mb-6 group">
                            <input
                                type="password"
                                name="password"
                                value={inputsConnexion.password}
                                onChange={handleChangeFormConnexion}
                                className="inputAi_floating peer"
                                placeholder=" "
                                required
                            />
                            <label htmlFor="floating_password" className="inputAi_labelFloating">
                                Mot de passe
                            </label>
                        </div>
                        {/*  <label className="input_label">Mot de passe:</label>
                        <input className="input px-4" type="password" name="password" value={inputsConnexion.password} onChange={handleChangeFormConnexion} />
                 */}
                    </form>
                    <p className="mt-4 text-white text-sm text-left md:text-center justify-center flex flex-col md:flex-row gap-4">
                        <button className="sm:text-base" onClick={() => setIsForgot(true)}>
                            Mot de passe oublié ?
                        </button>
                    </p>
                </>
            )}
            {isForgot && (
                <>
                    <h1 className="-mx-2 md:-mx-8 -mt-8 text-lg text-mainText font-bold px-2 md:px-8 py-4 border-b border-gray-200;">Mot de passe oublié</h1>
                    {hasError && isForgotSubmitted && !authState.isLogged && (
                        <Alert type="danger" message="Il n'existe pas de compte SkillsMarket avec cette adresse email" />
                    )}
                    {isForgotSubmitted && !hasError && !authState.isLogged && (
                        <Alert type="success" message="Un email de réinitialisation vous a été envoyé. N'oubliez pas de vérifier vos spams si besoin." />
                    )}
                    <form className="mt-8 m-0" autoComplete="off">
                        <div className="relative z-0 w-full mb-6 group">
                            <input
                                className="input_floating peer"
                                placeholder=" "
                                type="Ai"
                                name="email"
                                value={inputsConnexion.email}
                                onChange={handleChangeFormConnexion}
                            />
                            <label htmlFor="floating_email" className="input_labelFloating">
                                Adresse email
                            </label>
                        </div>
                    </form>
                    <p className="mt-4 text-blueMain text-sm text-center justify-center flex gap-4">
                        <button onClick={() => setIsForgot(false)}>Me connecter</button>
                        <button
                            onClick={() => {
                                setIsSignup(true);
                                setIsForgot(false);
                            }}
                        >
                            S'inscrire !
                        </button>
                    </p>
                </>
            )}
            {isSignup && (
                <>
                    <div className="bg-black sm:rounded-t-xl text-center text-white -mx-2 md:-mx-8 -mt-8  font-bold px-2 md:px-8 py-4">
                        <h1 className="text-xl">Création de votre Clone AI sur Skillsmarket</h1>
                        <p>Ce profil sera la base votre clone AI</p>
                    </div>
                    {isSignupSubmitted && authState.isLogged && <Alert type="success" message="Vous avez créé un compte SkillsMarket !" />}
                    {hasError && isSignupSubmitted && !authState.isLogged && <Alert type="danger" message="Un compte existe déjà avec cette adresse email" />}
                    <form className="m-0 min-w-lg text-white" autoComplete="off">
                        {/*  <div className="relative z-0 w-full mb-6 group">
                            <input className="input_floating peer" placeholder=" " type="text" name="pseudo" {...register('pseudo')} />
                            <label htmlFor="floating_pseudo" className="input_labelFloating">
                                Pseudo
                            </label>
                            {errors && errors['pseudo'] && <span className={`mt-0 text-sm text-danger-400`}>{errors['pseudo'].message}</span>}
                        </div> */}
                        <div className="w-full mt-2 mb-6">
                            <label className="text-sm">Inscription en tant que :</label>
                            <FieldSelect
                                inputForm="rounded"
                                name="typeInscription"
                                label={false}
                                options={[
                                    { label: 'Personne', value: 'realnames' },
                                    { label: 'Société', value: 'society' },
                                    // { label: 'News', value: 'news' },
                                    // { label: 'Demande de devis', value: 'devis' },
                                ]}
                                onChange={(e) => {
                                    setTypeInscription(e.target.value);
                                }}
                                register={register}
                                errors={errors}
                            />
                        </div>
                        <div className="flex w-full gap-3">
                            {typeInscription === 'society' ? (
                                <>
                                    <div className="w-full relative z-0 mb-2 group">
                                        <input className="inputAi_floating peer" placeholder=" " type="Ai" name="society" {...register('society')} />
                                        <label htmlFor="floating_society" className="inputAi_labelFloating">
                                            Société*
                                        </label>
                                        {errors && errors['society'] && <span className={`mt-0 text-sm text-danger-400`}>{errors['society'].message}</span>}
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="w-1/2 relative z-0 mb-2 group">
                                        <input className="inputAi_floating peer" placeholder=" " type="Ai" name="firstname" {...register('firstname')} />
                                        <label htmlFor="floating_firstname" className="inputAi_labelFloating">
                                            Prénom*
                                        </label>
                                        {errors && errors['firstname'] && <span className={`mt-0 text-sm text-danger-400`}>{errors['firstname'].message}</span>}
                                    </div>
                                    <div className="w-1/2 relative z-0 mb-2 group">
                                        <input className="inputAi_floating peer" placeholder=" " type="Ai" name="lastname" {...register('lastname')} />
                                        <label htmlFor="floating_lastname" className="inputAi_labelFloating">
                                            Nom*
                                        </label>
                                        {errors && errors['lastname'] && <span className={`mt-0 text-sm text-danger-400`}>{errors['lastname'].message}</span>}
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="relative z-0 w-full mb-2 group">
                            <input className="inputAi_floating peer" placeholder=" " type="Ai" name="email" {...register('email')} />
                            <label htmlFor="floating_email" className="inputAi_labelFloating">
                                Adresse email*
                            </label>
                            {errors && errors['email'] && <span className={`mt-0 text-sm text-danger-400`}>{errors['email'].message}</span>}
                        </div>
                        <div className="relative z-0 w-full mb-6 group">
                            <input
                                className="inputAi_floating peer"
                                placeholder=" "
                                type="password"
                                name="password"
                                {...register('password')}
                                autoComplete="off"
                            />
                            <label htmlFor="floating_password" className="inputAi_labelFloating">
                                Mot de passe
                            </label>
                            <div className={`${strongPwd?.class} h-[2px] block absolute bottom-0`}></div>
                            {errors && errors['password'] && <span className={`w-full mt-0 text-sm text-danger-400`}>{errors['password'].message}</span>}
                        </div>

                        {/* <div className="relative z-0 w-full mb-2 group">
                            <input className="input_floating peer" placeholder=" " type="password" name="confirm" {...register('confirm')} />
                            <label htmlFor="floating_confirm" className="input_labelFloating">
                                Confirmation du mot de passe
                            </label>
                            {errors && errors['confirm'] && <span className={`mt-0 text-sm text-danger-400`}>{errors['confirm'].message}</span>}
                        </div> */}
                        <div className="flex flex-col">
                            <div className="flex gap-2">
                                <input type="checkbox" id="cguConsent" className="w-10 sm:w-6 mr-4" name="cguConcent" {...register('cguConsent')} />
                                <label className="text-sm" htmlFor="cgu_consent">
                                    J'ai lu et je consens à la{' '}
                                    <Link className="text-blueMain" to="/cgu" title="Consulter notre politique de confidentialité">
                                        politique de confidentialité
                                    </Link>
                                    {' '}et aux{' '}
                                    <Link className="text-blueMain" to="/cgu" title="Consulter nos conditions d'utilisation">
                                        conditions d'utilisation
                                    </Link>
                                    .
                                </label>
                            </div>

                            {errors && errors['cguConsent'] && <span className={`w-full mt-0 text-sm text-danger-400`}>{errors['cguConsent'].message}</span>}
                        </div>
                    </form>
                    {/*  <p className="mt-4 text-blueMain text-sm text-center justify-center flex gap-4">
                        <button onClick={() => setIsSignup(false)}>Retour à la connexion</button>
                    </p> */}
                    {/* <div className="m-auto mt-8 text-center"> */}
                    {/*     <GoogleLogin clientId={process.env.REACT_APP_GG_APP_ID} buttonText="Création de compte avec Google" onSuccess={(response) => responseGoogle(response, 'create')} onFailure={(response) => responseGoogle(response, 'create')} cookiePolicy="single_host_origin" /> */}
                    {/* </div> */}
                </>
            )}
        </>
    );
}

export default forwardRef(FormConnexion);
