import { Fragment, useCallback, useEffect, useState, useContext } from 'react';

//LIBS
import { useSpring, animated } from 'react-spring';
import { Link, useLocation, useNavigate } from 'react-router-dom';

//UTILS
import config from 'utils/constants';
import { isMobile } from 'utils/functions';

//HOOKS
//import useScroll from 'hooks/useScroll';
import useNotifications from 'hooks/useNotifications';

//API
import { getNotifications } from 'api/discussions';

//CONTEXTS
import { AuthContext } from 'context/authContext';
import { StoreContext } from 'context/storeContext';

//LAYOUTS
import InAllLayouts from 'pages/layouts/InAllLayouts';

// COMPONENTS
import MainNavSearch from 'components/common/MainNavSearch';
import MainNavMobile from 'components/common/MainNavMobile';
import MainNavUser from 'components/common/MainNavUser';
import Image from 'components/common/Image';
import Banner from 'components/common/Banner';
//import Share from 'components/share/Share';
// ICONS
import { ArrowNarrowLeftIcon, ChatIcon, SearchIcon, UserIcon } from '@heroicons/react/solid';
import Share from '../../components/share/Share';

function ProfilLayout({ children, noContain }) {
    //HOOKS
    //const scrollDirection = useScroll();
    const [openedUserMenu, setOpenedUserMenu] = useState(false);

    const navigate = useNavigate();
    const [authState] = useContext(AuthContext);

    const discussionNotifications = useNotifications(getNotifications);
    const location = useLocation();

    // eslint-disable-next-line no-unused-vars
    /*  const [isHomeLocation, setIsHomeLocation] = useState(false);
    const [isSearchLocation, setIsSearchLocation] = useState(false); */

    //contexts
    const store = useContext(StoreContext);

    const handleGoToNotifications = useCallback(() => {
        if (authState?.isLogged) navigate('/dashboard/notifications');
        else document.getElementById('button-login')?.click();
        //eslint-disable-next-line
    }, [authState, navigate]);

    const handleOpenUserMenu = useCallback(() => {
        setOpenedUserMenu(openedUserMenu ? false : true);
    }, [openedUserMenu]);

    useEffect(() => {
        if (openedUserMenu) document.querySelector('body').style.overflow = 'hidden';
        else document.querySelector('body').style.overflow = 'auto';
    }, [openedUserMenu]);

    const openCloseSlideAnimationContent = useSpring({
        transform: openedUserMenu ? `translateX(16rem)` : 'translateX(0rem)',
        config: {
            duration: 225,
        },
    });

    const handleGoToSearch = useCallback(() => {
        if (!location.pathname.includes('jobs')) {
            navigate('/search');
        }
        //eslint-disable-next-lines
    }, [navigate, location]);

    /*  const handleGoToCloneAI = useCallback(() => {
        navigate('/dashboard/clone-ai');
    }, [navigate]); */

    return (
        <Fragment>
            {noContain && (
                <div
                    onClick={() => {
                        /*   setOpenedJobDetail(false); */
                        /* closeJobDetail();
                        window.history.replaceState({}, '', `/jobs`); */
                    }}
                    className={`fixed top-0 left-0 w-full h-full backdrop-filter backdrop-blur-xs bg-blueMain/10 flex justify-end`}
                />
            )}
            <InAllLayouts />

            {/**
             * MENU NAV USER
             *
             */}
            <MainNavUser handleOpenUserMenu={handleOpenUserMenu} isOpen={openedUserMenu} />

            {/**
             * MENU HEAD DESKTOP
             *
             */}
            <div className={`hidden sm:block ${noContain ? '' : 'container-fluid mx-auto'} sticky z-20 h-16 max-h-16 w-full px-4 bg-white top-0`}>
                <div className={`${noContain ? 'w-5/12' : ''}`}>
                    <MainNavSearch />
                </div>
            </div>

            {/**
             *  MENU HEAD MOBILE
             *
             */}
            <div
                /* className={`sm:hidden sticky w-full z-10 pt-2 px-4 bg-white transition-all duration-300 ease-out ${
                            scrollDirection === 'down' ? '-top-[65px]' : 'top-0'
                        }`} */
                className={`sm:hidden fixed top-0 w-full z-10 pt-2 px-4 transition-all duration-300 ease-out ee`}
            >
                <div className={`flex w-auto justify-between items-center gap-2 pb-2`}>
                    {location.pathname.includes('/profil') ? (
                        <button
                            className={`button-prev flex w-10 h-10 rounded-xl bg-opacity-20 backdrop-blur-sm bg-graySkills text-white items-center justify-center`}
                            onClick={() => {
                                navigate(-1);
                            }}
                        >
                            <ArrowNarrowLeftIcon className="w-6 h-6" />
                        </button>
                    ) : (
                        <>
                            {authState.isLogged ? (
                                <button
                                    className="flex flex-col items-center w-auto text-purpleSkills font-semibold text-sm"
                                    id="button-profil"
                                    onClick={handleOpenUserMenu}
                                >
                                    {authState.isLogged ? (
                                        authState.me?.profile?.avatar?.url ? (
                                            <Image
                                                src={authState.me?.profile?.avatar?.url}
                                                alt={authState.me?.profile?.pseudo}
                                                loading="false"
                                                className="rounded-full w-8 h-8"
                                            />
                                        ) : (
                                            <img
                                                className="rounded-full w-8 h-8"
                                                src={`${config.publicDir}skillsLogo.jpg`}
                                                alt={authState.me?.profile?.pseudo}
                                            />
                                        )
                                    ) : (
                                        <UserIcon className="block w-6 h-6" />
                                    )}
                                </button>
                            ) : (
                                <Link to={isMobile() ? '/home' : '/'}>
                                    <img src={`${config.publicDir}logo_skillsmobile.png`} alt="logo" className="h-8 w-auto" />
                                </Link>
                            )}
                        </>
                    )}

                    {/* <div className="flex gap-4 w-3/4">
                        <div className="w-full" onClick={handleGoToSearch}>
                            <MainSearchBar isInNavBar={true} />
                        </div>
                    </div> */}
                    <div className="flex justify-end gap-4 w-3/4">
                        <button
                            className={`w-10 h-10 rounded-xl bg-opacity-20 backdrop-blur-sm bg-graySkills text-white flex items-center justify-center`}
                            onClick={() => {
                                handleGoToSearch();
                            }}
                        >
                            <SearchIcon type="" className="w-6 h-6" />
                        </button>
                    </div>

                    <Share url={location.pathname} />

                    <div className="w-7 flex">
                        <button id="button-notifications" onClick={handleGoToNotifications}>
                            <div
                                className={`w-10 h-10 rounded-xl bg-opacity-20 backdrop-blur-sm bg-graySkills text-white flex items-center justify-center`} /* className="relative text-graySkills text-sm flex flex-col items-center w-auto" */
                            >
                                {authState.isLogged && (
                                    <>
                                        {(store.get('notifications')?.length > 0 || discussionNotifications) && (
                                            <>
                                                <div className="sm:hidden flex justify-center items-center text-white text-xs font-semibold absolute -top-0.5 -right-0.5 bg-red p-1 h-5 w-5 rounded-full">
                                                    <span>{discussionNotifications + store.get('notifications').length}</span>
                                                </div>
                                                {store.get('notifications')?.length > 0 && (
                                                    <div className="hidden sm:flex justify-center items-center text-white text-xs font-semibold absolute -top-0.5 -right-0.5 bg-red p-1 h-5 w-5 rounded-full">
                                                        <span>{store.get('notifications').length}</span>
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </>
                                )}
                                <ChatIcon className="w-6 h-6" />
                            </div>
                        </button>
                    </div>
                </div>
            </div>

            <animated.div style={openCloseSlideAnimationContent} className="flex flex-col min-h-screen container-fluid">
                <div className="flex-grow">
                    <div className={`relative ${noContain ? 'w-[51%]' : 'container mx-auto'} px-2 sm:px-4`}>
                        <div className="w-full">{children}</div>
                    </div>
                </div>
                {!authState.isLogged && (
                    <div className={`z-20 sticky sm:bottom-0 transition-all duration-300 ease-out bottom-[68px]`}>
                        <Banner />
                    </div>
                )}
                <MainNavMobile />
            </animated.div>
            {/* {authState.isLogged && (
                <button onClick={handleGoToCloneAI} className={`sm:hidden z-10 fixed bottom-20 right-4 w-12 cursor-pointer`}>
                    <img src={`${config.publicDir}logo_ai_mobile.svg`} alt="ai_logo" />
                </button>
            )} */}
        </Fragment>
    );
}

export default ProfilLayout;
