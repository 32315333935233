import { useCallback, useContext, useEffect, useRef, useState } from 'react';

//API
import { postCounter } from 'api/counters';

//UTILS
import { countAnswers, isMobile, isSmartphone } from 'utils/functions';
//import config from 'utils/constants';

//COMPONENTS
// import Button from 'components/common/Button';
import CountersReaction from './CountersReaction';
// import Image from 'components/common/Image';
import OptionsMenu from 'components/common/OptionsMenu';
import ReactionIcons from 'components/ReactionIcons';
import SeeMore from 'components/common/SeeMore';
//import Image from 'components/common/Image';

//CONTAINER
import CardProfilRoom from 'containers/profils/CardProfilRoom';
import ImageSlider from 'containers/rooms/ImageSlider';
import PoleEmploiCardProfilRoom from './PoleEmploiCardProfilRoom';

//CONTETXS
import { AuthContext } from 'context/authContext';
import { SocketContext } from 'context/socketContext';
import { CounterContext } from 'context/counterContext';
//HOOKS
import useHasCounter from 'hooks/useHasCounter';
import useIntersectionObserver from 'hooks/useIntersectionObserver';

//ICONS
import { BellIcon, HeartIcon, LocationMarkerIcon } from '@heroicons/react/solid';
// eslint-disable-next-line no-unused-vars
import { BellIcon as BellIconOutline, QuestionMarkCircleIcon, BriefcaseIcon, CurrencyEuroIcon, RssIcon } from '@heroicons/react/outline';
import { ChatAlt2Icon, EyeIcon, DotsVerticalIcon } from '@heroicons/react/solid';

//LIBS
//import _ from 'lodash';
import { Link, useNavigate, useLocation } from 'react-router-dom';
// import ReadMoreReact from 'read-more-react';
// import Slider from 'react-slick';

/* const sizes = {
    smallAvatar: '?w=40&h=40&c=true&q=80',
    avatar: '?w=130&h=130&c=true&q=80',
    cover: '?w=1000&h=600&c=true&q=80',
}; */

function CardFrontRoom({ room, refetch, profile, userId, listingQueryKeys }) {
    //const widthRef = useRef(null);
    const roomRef = useRef(null);
    const [authState] = useContext(AuthContext);
    const { counterDispatch, counterState } = useContext(CounterContext);
    //const [userIcon, setUserIcon] = useState(true);
    //const currentRoom = counterState.find((r) => r.id === room.id || r.id === room._id);
    const [currentRoomState, setCurrentRoomState] = useState(room);
    //HOOKS
    const isHiddenRoomForMe = useHasCounter(currentRoomState.counters, 'hidden_room_forMe', room.id || room._id);
    //const isHiddenRoomForMe = useHasCounter(currentRoom.counters, 'hidden_room_forMe', currentRoom.id || currentRoom._id);
    const socket = useContext(SocketContext);

    const isSubscribed = useHasCounter(currentRoomState.counters, 'subscribe_room', room.id || room._id);
    const hasBookmark = useHasCounter(currentRoomState.counters, 'bookmark_job', room.id || room._id);

    const [isSelected, setIsSelected] = useState(false);

    const originalUrlRef = useRef(window.location.pathname);

    const navigate = useNavigate();
    const location = useLocation();

    const jobPrice = {
        '10k-20k': 'Entre 10k et 20k',
        '20k-30k': 'Entre 20k et 30k',
        '30k-40k': 'Entre 30k et 40k',
        '40k-50k': 'Entre 40k et 50k',
        '>50k': 'Supérieur à 50k',
    };

    const jobTypeContrat = {
        full: 'Temps plein',
        partial: 'Temps partiel',
        contrat: 'Contrat',
        tmp: 'Travail temporaire',
        benevolat: 'Bénévolat',
        stage: 'Stage / Alternance',
    };

    useIntersectionObserver({
        target: roomRef,
        onIntersect: (isVisible) => {
            if (isVisible) {
                let data = {
                    id: authState?.me?.id,
                    location: `/rooms/${room.slug}`,
                };
                setTimeout(() => {
                    socket.send('userActivity', data);
                }, 1000);
            }
        },
        enabled: true,
    });

    function toggleSubscription() {
        if (authState.isLogged) {
            postCounter({ type: 'subscribe_room', to: room.id || room._id }).then((data) => {
                //refetch();
                counterDispatch({
                    type: 'UPDATE_OR_ADD_COUNTERS',
                    payload: { targetId: room.id || room._id, counters: data.counters, updatedAt: new Date().toISOString() },
                });
            });
        } else document.getElementById('button-login')?.click();
    }

    function closeOptionsMenu(room) {
        const roomId = room._id || room.id;
        const els = document.getElementsByClassName('optionsMenu');
        [].forEach.call(els, function (el) {
            if (el.id !== `optionsMenu_${roomId}`) {
                el.classList.add('hidden');
            }
        });
    }

    function handleOptionsMenu(room) {
        const roomId = room._id || room.id;
        closeOptionsMenu(room);
        document.getElementById(`optionsMenu_${roomId}`).classList.toggle('hidden');
    }

    const handleGoToRoom = useCallback(
        (e) => {
            e?.preventDefault();
            navigate('/room/' + room?.slug, { state: { background: location } });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [navigate, room?.slug]
    );

    const handleClick = useCallback(
        (e) => {
            e.stopPropagation();
            //const originalUrl = window.location.pathname;
            window.history.replaceState({ originalUrl: originalUrlRef.current }, '', `/jobs/${room.slug}`);
        },
        [room]
    );

    const handleStateChange = useCallback(() => {
        const slug = window.location.pathname.split('/')[2];
        setIsSelected(slug === room?.slug);
    }, [room?.slug]);

    function handleBookmark(jobId) {
        //console.log(room);
        if (authState.isLogged) {
            postCounter({ type: 'bookmark_job', to: jobId }).then((data) => {
                //refetch();

                counterDispatch({
                    type: 'UPDATE_OR_ADD_COUNTERS',
                    payload: { targetId: jobId, counters: data.counters, updatedAt: new Date().toISOString() },
                });
            });
        } else document.getElementById('button-login')?.click();
    }

    useEffect(() => {
        const roomData = { targetId: room.id || room._id, counters: room.counters, updatedAt: room.updatedAt };
        counterDispatch({ type: 'UPDATE_OR_ADD_COUNTERS', payload: roomData });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [room]);

    useEffect(() => {
        const updatedRoom = counterState.find((r) => r.id === room.id || r.id === room._id);
        if (updatedRoom) {
            setCurrentRoomState((prevState) => ({
                ...prevState,
                counters: updatedRoom.counters,
                updatedAt: updatedRoom.updatedAt,
            }));
        }
    }, [counterState, room._id, room.id]);

    useEffect(() => {
        if (!location.pathname.includes('jobs')) {
            return;
        }

        const originalReplaceState = window.history.replaceState;
        window.history.replaceState = function () {
            originalReplaceState.apply(this, arguments);
            window.dispatchEvent(new Event('statechange'));
        };

        window.addEventListener('statechange', handleStateChange);

        return () => {
            window.history.replaceState = originalReplaceState;
            window.removeEventListener('statechange', handleStateChange);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleStateChange]);

    if (isHiddenRoomForMe === true) return null;

    return (
        <>
            <div
                ref={roomRef}
                id={`room_${room._id || room.id}`}
                className={`w-full flex flex-col justify-between h-full border-b border-l border-r rounded-xl border-white hover:bg-white ${
                    isSelected ? 'bg-white' : ''
                }`}
            >
                <div className={`card-header mb-1 relative w-full`}>
                    {
                        userId && userId === room.user?.id && room.anonymousQuestion !== true ? (
                            <p className="text-blue-300 text-xs pb-2">Post publié par {profile?.profile?.pseudo}</p>
                        ) : room.type === 'private' && userId === room.userTo ? (
                            <p className="text-blue-300 text-xs pb-2">Question pour {profile?.profile?.pseudo}</p>
                        ) : (
                            profile &&
                            room?.type !== 'job' &&
                            room.anonymousQuestion !== true && <p className="text-blue-300 text-xs pb-2">Post en lien avec {profile?.profile?.pseudo}</p>
                        ) /*||
                        (room.type === 'question' && (
                            <p className={`text-xs py-2 flex gap-1 card_type card_${room.type}`}>
                                <QuestionMarkCircleIcon className="w-4 h-4 md:w-6 md:h-6" />
                            </p>
                        )) ||
                        (room.type === 'job' && (
                            <p className={`text-xs py-2 flex gap-1 card_type card_${room.type}`}>
                                <BriefcaseIcon className="w-4 h-4 md:w-6 md:h-6" />
                            </p>
                        )) ||
                        (room.type === 'devis' && (
                            <p className={`text-xs py-2 flex gap-1 card_type card_${room.type}`}>
                                <CurrencyEuroIcon className="w-4 h-4 md:w-6 md:h-6" />
                            </p>
                        )) ||
                        (room.type === 'news' && (
                            <p className={`text-xs py-2 flex gap-1 card_type card_${room.type}`}>
                                <RssIcon className="w-4 h-4 md:w-6 md:h-6" />
                            </p>
                        ))*/
                    }
                    <div className="flex items-center justify-between">
                        {room?.type === 'job' && room?.poleEmploi?.refId ? (
                            <PoleEmploiCardProfilRoom
                                company={room?.poleEmploi?.company}
                                partenaire={room?.poleEmploi?.partenaire}
                                room={room}
                                hiddenDate={true}
                            />
                        ) : room?.user ? (
                                <CardProfilRoom user={room?.user[0] ? room?.user[0] : room?.user} room={room} isAnonymous={room.anonymousQuestion} hiddenDate={true} />
                            ) : (
                                <CardProfilRoom user={room?.user} room={room} isAnonymous={true} hiddenDate={true} />
                            )
                        }
                        {room?.type === 'job' && (
                            <div className="relative footerCardFrontRoom">
                                <button className="flex" onClick={() => handleOptionsMenu(room)}>
                                    <DotsVerticalIcon className="h-6 w-6 sm:w-7 sm:h-7 hover:text-blueMain" />
                                </button>
                                <OptionsMenu room={room} refresh={refetch} type="_room" />
                            </div>
                        )}
                    </div>

                    <div
                        className="w-full cursor-pointer"
                        onClick={(e) => {
                            isMobile() ? handleGoToRoom() : room?.type === 'job' ? handleClick(e) : handleGoToRoom();
                        }}
                    >
                        <div className="pl-16 w-full">
                            {room?.type === 'job' ? (
                                <div className="mb-4">
                                    {room?.poleEmploi?.refId ? (
                                        <SeeMore
                                            className={
                                                'font-bold text-xl text-purpleSkills -mb-2 cursor-pointer hover:text-blueMain transition-colors duration-200'
                                            }
                                            string={room?.poleEmploi?.title}
                                            link={'/room/' + room?.slug}
                                            linkActive={false}
                                            readMoreIsLink={true}
                                        />
                                    ) : (
                                        <SeeMore
                                            className={
                                                'font-bold text-xl text-purpleSkills -mb-2 cursor-pointer hover:text-blueMain transition-colors duration-200'
                                            }
                                            string={room?.question}
                                            link={'/room/' + room?.slug}
                                            linkActive={false}
                                            readMoreIsLink={true}
                                        />
                                    )}
                                    {/* {room.tags && (
                                        <div className="flex flex-wrap flex-row -mx-2">
                                            {room.tags?.map((tag, index) => (
                                                <Link to={`/search?q=${tag.name}`} key={index} className="link_basic cursor-pointer mb-1">
                                                    {tag.name}
                                                </Link>
                                            ))}
                                        </div>
                                    )} */}
                                    {room?.poleEmploi?.refId ? (
                                        <div className={`mt-2 w-full h-fit text-purpleSkills cursor-pointer`} /*  onClick={handleGoToRoom} */>
                                            {room?.poleEmploi?.address?.libelle && (
                                                <p
                                                    className={`${
                                                        isSmartphone() && 'justify-start'
                                                    } px-4 py-1 flex flex-row text-sm gap-2 hover:text-blueMain transition-colors duration-200`}
                                                >
                                                    <LocationMarkerIcon className="w-5 h-5" />
                                                    {room?.poleEmploi?.address?.libelle}
                                                </p>
                                            )}
                                            {room?.poleEmploi?.salaire && (
                                                <div
                                                    className={`${
                                                        isSmartphone() && 'justify-start'
                                                    } px-4 py-1 flex flex-row text-sm gap-2 hover:text-blueMain transition-colors duration-200`}
                                                >
                                                    <CurrencyEuroIcon className="w-5 h-5" />
                                                    <div className="flex flex-col gap-2 w-fit">
                                                        {room?.poleEmploi?.salaire &&
                                                            Object.keys(room?.poleEmploi?.salaire).map((key, k, array) => (
                                                                <span key={k}>{room?.poleEmploi?.salaire[key]}</span>
                                                            ))}
                                                    </div>
                                                </div>
                                            )}
                                            <div className="flex flex-col">
                                                {room?.poleEmploi?.contratType && (
                                                    <p
                                                        className={`${
                                                            isSmartphone() && 'justify-start'
                                                        } px-4 py-1 flex flex-row text-sm gap-2 hover:text-blueMain transition-colors duration-200`}
                                                    >
                                                        <BriefcaseIcon className="w-5 h-5" />
                                                        {room?.poleEmploi?.contratType} de
                                                    </p>
                                                )}
                                                <div className="px-4 flex items-center gap-2">
                                                    <p
                                                        className={`${
                                                            isSmartphone() && 'justify-start'
                                                        } px-4 py-1 flex flex-row w-fit text-sm gap-2 hover:text-blueMain transition-colors duration-200 bg-white text-purpleSkills rounded-3xl font-bold`}
                                                    >
                                                        Voir l'offre
                                                    </p>
                                                    <div className="flex">
                                                        <button
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                e.stopPropagation();
                                                                handleBookmark(room._id || room.id);
                                                            }}
                                                        >
                                                            <HeartIcon
                                                                className={`w-6 h-6 ${
                                                                    hasBookmark ? 'text-red sm:hover:text-blueMain' : 'text-graySkills sm:hover:text-red'
                                                                }`}
                                                            />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className={`mt-2 w-full h-fit text-purpleSkills cursor-pointer`} /* onClick={handleGoToRoom} */>
                                            {room?.address && (
                                                <p
                                                    className={`${
                                                        isSmartphone() && 'justify-start'
                                                    } px-4 py-1 flex flex-row text-sm gap-2 hover:text-blueMain transition-colors duration-200`}
                                                >
                                                    <LocationMarkerIcon className="w-5 h-5" />
                                                    {room?.address}
                                                </p>
                                            )}
                                            {room?.jobPrice && (
                                                <div
                                                    className={`${
                                                        isSmartphone() && 'justify-start'
                                                    } px-4 py-1 flex flex-row text-sm gap-2 hover:text-blueMain transition-colors duration-200`}
                                                >
                                                    <CurrencyEuroIcon className="w-5 h-5" />
                                                    {jobPrice[room?.jobPrice]}/an
                                                </div>
                                            )}
                                            {room?.jobTypeContrat && (
                                                <div className="flex flex-col">
                                                    {room?.jobTypeContrat && (
                                                        <p
                                                            className={`${
                                                                isSmartphone() && 'justify-start'
                                                            } px-4 py-1 flex flex-row w-full text-sm gap-2 hover:text-blueMain transition-colors duration-200`}
                                                        >
                                                            <BriefcaseIcon className="w-5 h-5" />
                                                            {jobTypeContrat[room?.jobTypeContrat]}
                                                        </p>
                                                    )}
                                                    <div className="px-4 flex items-center gap-2">
                                                        <p
                                                            className={`${
                                                                isSmartphone() && 'justify-start'
                                                            } px-4 py-1 flex flex-row w-fit text-sm gap-2 hover:text-blueMain transition-colors duration-200 bg-white text-purpleSkills rounded-3xl font-bold`}
                                                        >
                                                            Voir l'offre
                                                        </p>
                                                        <div className="flex">
                                                            <button
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    e.stopPropagation();
                                                                    handleBookmark(room._id || room.id);
                                                                }}
                                                            >
                                                                <HeartIcon
                                                                    className={`w-6 h-6 ${
                                                                        hasBookmark ? 'text-red sm:hover:text-blueMain' : 'text-graySkills sm:hover:text-red'
                                                                    } `}
                                                                />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <>
                                    {/* {room.tags && (
                                        <div className="flex flex-wrap flex-row -mx-2">
                                            {room.tags?.map((tag, index) => (
                                                <Link to={`/search?q=${tag.name}`} key={index} className="link_basic cursor-pointer mb-1">
                                                    {tag.name}
                                                </Link>
                                            ))}
                                        </div>
                                    )} */}
                                    <SeeMore
                                        className="cursor-pointer"
                                        string={room.question}
                                        link={'/room/' + room?.slug}
                                        linkActive={false}
                                        readMoreIsLink={true}
                                    />
                                </>
                            )}
                            {room?.medias?.length > 0 && (
                                <Link to={'/room/' + room?.slug} className="w-full h-full mb-2">
                                    <div className="w-full">
                                        <ImageSlider room={room} link={'/room/' + room?.slug} goToRoom={true} isCard={true} />
                                    </div>
                                </Link>
                            )}
                            <ReactionIcons counters={currentRoomState.counters} to={room?.id || room?._id} targetType="room" type="reactions" />
                            <div className="relative">
                                <div
                                    className="flex flex-row sm:gap-x-2 justify-between items-center"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }}
                                >
                                    {room?.type !== 'job' && (
                                        <>
                                            <CountersReaction
                                                btnSize="w-5 h-5 sm:w-7 sm:h-7"
                                                counters={currentRoomState.counters}
                                                room={room}
                                                //refetch={refetch}
                                                listingQueryKeys={listingQueryKeys}
                                                type="room"
                                                to={room?.id || room?._id}
                                                userTo={room?.user?.id}
                                                cssButton={'footerCardFrontRoom min-w-[2rem]'}
                                            />
                                            <Link to={'/room/' + room?.slug} className="footerCardFrontRoom">
                                                <div className="flex flex-row items-center">
                                                    <span className="">
                                                        <ChatAlt2Icon className="w-5 h-5 sm:w-7 sm:h-7" />
                                                    </span>

                                                    <span
                                                        className={`counter-icon_number text-sm sm:text-base px-1 sm:px-4 min-w-[2rem]`} /* style={{ minWidth: 'calc(1em + 24px)' }} */
                                                    >
                                                        {countAnswers(room.answers)}
                                                    </span>
                                                </div>
                                            </Link>
                                            <Link to={'/room/' + room?.slug} className="footerCardFrontRoom">
                                                <div className="flex flex-row items-center">
                                                    <span className="inline-flex">
                                                        <EyeIcon className="w-5 h-5 sm:w-7 sm:h-7" />
                                                    </span>
                                                    <span
                                                        className={`counter-icon_number text-sm sm:text-base px-1 sm:px-4 min-w-[2rem]`} /* style={{ minWidth: 'calc(1em + 24px)' }} */
                                                    >
                                                        {room.views}
                                                    </span>
                                                </div>
                                            </Link>
                                            <button
                                                onClick={toggleSubscription}
                                                className={`text-graySkills hover:text-blueMain ${
                                                    isSubscribed ? 'active text-blueMain' : ''
                                                } footerCardFrontRoom`}
                                            >
                                                {isSubscribed ? (
                                                    <>
                                                        <div className="text-blueMain" /* style={{ minWidth: 'calc(1em + 24px)' }} */>
                                                            <BellIcon className="w-5 h-5 sm:w-7 sm:h-7" />
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div className="" /* style={{ minWidth: 'calc(1em + 24px)' }} */>
                                                            <BellIconOutline className="w-5 h-5 sm:w-7 sm:h-7" />
                                                        </div>
                                                    </>
                                                )}
                                            </button>
                                            <div className="footerCardFrontRoom">
                                                <button className="flex" onClick={() => handleOptionsMenu(room)}>
                                                    <DotsVerticalIcon className="h-5 w-5 sm:w-7 sm:h-7 hover:text-blueMain" />
                                                </button>
                                                <OptionsMenu room={room} refresh={refetch} type="_room" />
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CardFrontRoom;
