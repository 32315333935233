import { useState } from 'react';
import { useGetTop3Reactions } from '../hooks/useHasCounter';

import { HeartIcon, ThumbUpIcon, ThumbDownIcon } from '@heroicons/react/solid';

import Modal from './common/Modal';
import CardProfilRoom from 'containers/profils/CardProfilRoom';
import LaughIcon from './svgs/Laugh';
import InterestingIcon from './svgs/Interesting';
import { isMobile } from '../utils/functions';

function ReactionIcons(props) {
    const [showModal, setShowModal] = useState(false);
    const reactions = useGetTop3Reactions(props.counters, props.to, props.targetType, props.type);

    const getIcon = (value, size) => {
        switch (value) {
            case 'like':
                return <ThumbUpIcon className={`${size} text-purpleSkills`} />;
            case 'likeMuch':
                return <HeartIcon className={`${size} text-[#E06C83]`} />;
            case 'interesting':
                return <span className={`${size} text-l text-center`}>{<InterestingIcon />}</span>;
            case 'mdr':
                return <span className={`${size} text-l text-center`}>{<LaughIcon />}</span>;
            case 'dislike':
                return <ThumbDownIcon className={`${size} text-[#075985]`} />;
            default:
                return <ThumbUpIcon className={`${size} text-purpleSkills`} />;
        }
    };

    const handleClick = (e) => {
        e.preventDefault();
        setShowModal(true);
    };

    return (
        <>
            <Modal
                title={`Réactions`}
                open={showModal}
                modalHeight={isMobile() ? 'h-auto' : 'h-[40vh]'}
                onClose={{
                    label: 'Fermer',
                    onClick: () => {
                        setShowModal(false);
                    },
                }}
            >
                <div className={`${isMobile() ? 'h-[65vh]' : 'h-[30vh]'} pl-6 overflow-y-auto`}>
                    {reactions.value?.length > 0 &&
                        reactions.value.map((reaction, index) => (
                            <div key={index} className="flex flex-col gap-2">
                                {reaction.map((item, index2) => (
                                    <div key={index2} className="flex items-center">
                                        <CardProfilRoom user={item.user} />
                                        {getIcon(item.value, 'h-10 w-10')}
                                    </div>
                                ))}
                            </div>
                        ))}
                </div>
            </Modal>

            {reactions && reactions.type.length > 0 && (
                <>
                    <div className="w-full flex">
                        <div className="ReactionIcons cursor-pointer flex items-center" onClick={(e) => handleClick(e)}>
                            {reactions.type.map((reaction, index) => {
                                return (
                                    <div key={index} className="flex items-center">
                                        {getIcon(reaction, 'h-5 w-5')}
                                    </div>
                                );
                            })}
                            <span className="text-gray-500 text-xs ml-1">{reactions.total}</span>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}

export default ReactionIcons;
