import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

//API
import { QueryClient, QueryClientProvider } from 'react-query';

//LIBS
import reportWebVitals from 'reportWebVitals';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { HeadlessProvider } from 'libs/headless';

//PAGES
import Home from 'pages/Home';
import Register from 'pages/Register';
import Login from 'pages/Login';
import Forum from 'pages/Forum';
import Listing from 'pages/Listing';
import ListingServices from 'pages/ListingServices';
import ListingJobs from 'pages/ListingJobs';
import ListingShoots from 'pages/ListingShoots';
import SingleProfil from 'pages/single/profil';
import SingleService from 'pages/single/service';
import SingleVideo from 'pages/single/video';
import SingleRoom from 'pages/single/room';
import ResetPassword from 'pages/ResetPassword';
import Search from 'pages/Search';
import Subscription from 'pages/Subscription';
import LandingPage from 'pages/LandingPage';
import LandingPageSkillers from 'pages/LandingPageSkillers';
import Cgu from 'pages/Cgu';
import Support from 'pages/Support';

import Dashboard from 'pages/dashboard/Dashboard';
import DashboardServices from 'pages/dashboard/Services';
import DashboardNeeds from 'pages/dashboard/Needs';
import DashboardShoots from 'pages/dashboard/Shoots';
import DashboardSubscribers from 'pages/dashboard/Subscribers';
import DashboardProducts from 'pages/dashboard/Products';
import DashboardVideos from 'pages/dashboard/Videos';
import DashboardRooms from 'pages/dashboard/Rooms';
import DashboardVisios from 'pages/dashboard/Visios';
import DashboardBuySell from 'pages/dashboard/BuySell';
import DashboardAskedVisios from 'pages/dashboard/AskedVisios';
import DashboardNotifications from 'pages/dashboard/Notifications';
import DashboardCloneAi from 'pages/dashboard/CloneAi';
import DashboardOptionsNotifications from 'pages/dashboard/OptionsNotifications';
// import DashboardTagSubscriptions from "pages/dashboard/TagSubscriptions";
import DashboardParamsAccount from 'pages/dashboard/DashboardParamsAccount';
import ListingRooms from 'pages/ListingRooms';
import ServicesCategory from './pages/ServicesCategory';
import Sitemap from './pages/Sitemap';

//COMPONENTS
import ProtectedRoute from 'components/common/ProtectedRoute';
import Discussion from 'components/common/discussion/Discussion';
import ScrollToTop from 'components/common/ScrollToTop';
import GoogleAuth from 'components/common/GoogleAuth';
//import Welcome from 'components/common/Welcome';

//CONTEXTS
import { SocketProvider } from 'context/socketContext';
import { AuthProvider } from 'context/authContext';
import { CookieProvider } from 'context/cookieContext';
import { StoreProvider } from 'context/storeContext';
import { FormProvider } from 'context/formContext';
import { ShootProvider } from 'context/shootContext';
import { CloneProvider } from './context/cloneContext';
import { CounterProvider } from './context/counterContext';

//CONTAINERS
import AddService from 'containers/services/AddService';
import AddNeed from 'containers/needs/AddNeed';
//UTILS
import { isMobile } from 'utils/functions';
import CollectionShoots from './pages/CollectionShoots';
import StepsFormProfil from './components/common/StepsFormProfil';
import HomeWelcome from './components/HomeWelcome';
import SEO from './components/Seo';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 5_000,
            refetchOnWindowFocus: false,
            refetchOnMount: 'always',
        },
    },
});

ReactDOM.render(
    // <React.StrictMode>
    <QueryClientProvider client={queryClient}>
        <SocketProvider>
            <AuthProvider>
                <CookieProvider>
                    <StoreProvider>
                        <HeadlessProvider>
                            <HelmetProvider>
                                <FormProvider>
                                    <CounterProvider>
                                        <ShootProvider>
                                            <CloneProvider>
                                                <BrowserRouter>
                                                    {/* GLOBAL COMPONENTS */}
                                                    <Discussion />
                                                    <AddService />
                                                    <AddNeed />
                                                    <GoogleAuth />
                                                    {/*
                                                <Welcome />
                                                */}

                                                    <StepsFormProfil />

                                                    {/* / GLOBAL COMPONENTS */}
                                                    <SEO
                                                        title="Bienvenue sur SkillsMarket"
                                                        description="Obtenez des réponses personnalisées rapidement par des professionnels"
                                                        type="summary_large_image"
                                                    />
                                                    <ScrollToTop />
                                                    {/* <Suspense fallback={<Loader />}> */}
                                                    <Routes>
                                                        <Route path="dashboard">
                                                            <Route
                                                                path="profil"
                                                                element={
                                                                    <ProtectedRoute>
                                                                        <Dashboard />
                                                                    </ProtectedRoute>
                                                                }
                                                            ></Route>
                                                            <Route
                                                                path="services"
                                                                element={
                                                                    <ProtectedRoute>
                                                                        <DashboardServices />
                                                                    </ProtectedRoute>
                                                                }
                                                            ></Route>
                                                            <Route
                                                                path="needs"
                                                                element={
                                                                    <ProtectedRoute>
                                                                        <DashboardNeeds />
                                                                    </ProtectedRoute>
                                                                }
                                                            ></Route>
                                                            <Route
                                                                path="shoots"
                                                                element={
                                                                    <ProtectedRoute>
                                                                        <DashboardShoots />
                                                                    </ProtectedRoute>
                                                                }
                                                            ></Route>

                                                            <Route
                                                                path="services/:id"
                                                                element={
                                                                    <ProtectedRoute>
                                                                        <DashboardServices />
                                                                    </ProtectedRoute>
                                                                }
                                                            ></Route>
                                                            <Route
                                                                path="subscribers"
                                                                element={
                                                                    <ProtectedRoute>
                                                                        <DashboardSubscribers />
                                                                    </ProtectedRoute>
                                                                }
                                                            ></Route>
                                                            <Route
                                                                path="products"
                                                                element={
                                                                    <ProtectedRoute>
                                                                        <DashboardProducts />
                                                                    </ProtectedRoute>
                                                                }
                                                            ></Route>
                                                            <Route
                                                                path="products/:slug"
                                                                element={
                                                                    <ProtectedRoute>
                                                                        <DashboardProducts />
                                                                    </ProtectedRoute>
                                                                }
                                                            ></Route>
                                                            <Route
                                                                path="videos"
                                                                element={
                                                                    <ProtectedRoute>
                                                                        <DashboardVideos />
                                                                    </ProtectedRoute>
                                                                }
                                                            ></Route>
                                                            <Route
                                                                path="videos/:id"
                                                                element={
                                                                    <ProtectedRoute>
                                                                        <DashboardVideos />
                                                                    </ProtectedRoute>
                                                                }
                                                            ></Route>
                                                            <Route
                                                                path="rooms"
                                                                element={
                                                                    <ProtectedRoute>
                                                                        <DashboardRooms />
                                                                    </ProtectedRoute>
                                                                }
                                                            ></Route>
                                                            <Route path="visios">
                                                                <Route
                                                                    path="received"
                                                                    element={
                                                                        <ProtectedRoute>
                                                                            <DashboardVisios />
                                                                        </ProtectedRoute>
                                                                    }
                                                                ></Route>
                                                                <Route
                                                                    path="asked"
                                                                    element={
                                                                        <ProtectedRoute>
                                                                            <DashboardAskedVisios />
                                                                        </ProtectedRoute>
                                                                    }
                                                                ></Route>
                                                            </Route>
                                                            <Route
                                                                path="buysell"
                                                                element={
                                                                    <ProtectedRoute>
                                                                        <DashboardBuySell />
                                                                    </ProtectedRoute>
                                                                }
                                                            ></Route>
                                                            <Route
                                                                path="notifications"
                                                                element={
                                                                    <ProtectedRoute>
                                                                        <DashboardNotifications />
                                                                    </ProtectedRoute>
                                                                }
                                                                onEnter={() => console.log('Works fine')}
                                                            ></Route>
                                                            <Route
                                                                path="clone-ai"
                                                                element={
                                                                    <ProtectedRoute>
                                                                        <DashboardCloneAi />
                                                                    </ProtectedRoute>
                                                                }
                                                                onEnter={() => console.log('Works fine')}
                                                            ></Route>
                                                            <Route
                                                                path="options"
                                                                element={
                                                                    <ProtectedRoute>
                                                                        <DashboardOptionsNotifications />
                                                                    </ProtectedRoute>
                                                                }
                                                            ></Route>
                                                            {/* <Route path="params">
                                                                <Route
                                                                    path="tagSubscriptions"
                                                                    element={
                                                                        <ProtectedRoute>
                                                                            <DashboardTagSubscriptions />
                                                                        </ProtectedRoute>
                                                                    }></Route>
                                                            </Route> */}
                                                            <Route path="params">
                                                                <Route
                                                                    path="account"
                                                                    element={
                                                                        <ProtectedRoute>
                                                                            <DashboardParamsAccount />
                                                                        </ProtectedRoute>
                                                                    }
                                                                ></Route>
                                                            </Route>
                                                        </Route>
                                                        <Route path="/" element={<HomeWelcome />} />
                                                        <Route path="/home" element={<Home />}></Route>
                                                        <Route path="/inscription-clone-ai" element={<Home />}></Route>
                                                        <Route path="/login" element={isMobile() ? <Login /> : <Home />}></Route>
                                                        <Route path="/register" element={isMobile() ? <Register /> : <Home />}></Route>
                                                        <Route path="/forum" element={<Forum />}></Route>
                                                        <Route path="/rooms" element={<ListingRooms />}></Route>
                                                        <Route path="/skillers" element={<Listing />}></Route>
                                                        <Route path="/shoots/:slug" element={<ListingShoots />}></Route>
                                                        <Route path="/shoots" element={<ListingShoots />}></Route>
                                                        <Route path="/collection-shoots" element={<CollectionShoots />}></Route>
                                                        <Route path="/jobs" element={<ListingJobs />}></Route>
                                                        <Route path="/services" element={<ListingServices />}></Route>
                                                        <Route path="/search" element={<Search />}></Route>
                                                        <Route path="/subscription" element={<Subscription />}></Route>
                                                        <Route path="/comment-ca-marche" element={<LandingPage />}></Route>
                                                        <Route path="/pourquoi-s-inscrire" element={<LandingPageSkillers />}></Route>
                                                        <Route path="/cgu" element={<Cgu />}></Route>
                                                        <Route path="/profil/:slug" element={<SingleProfil />}></Route>
                                                        <Route path="/service/:slug" element={<SingleService />}></Route>
                                                        <Route path="/video/:slug" element={<SingleVideo />}></Route>
                                                        <Route path="/room/:slug" element={<SingleRoom key={new Date().getTime()} />}></Route>
                                                        <Route path="/jobs/:slug" element={<SingleRoom key={new Date().getTime()} />}></Route>
                                                        {/* <Route path="/inventory" element={<Inventory />}></Route> */}
                                                        {/* <Route path="/connexion" element={<Connect />}></Route> */}
                                                        <Route path="/services/:category" element={<ServicesCategory />}></Route>
                                                        <Route path="/services/:category/:subcategory" element={<ServicesCategory />}></Route>
                                                        <Route path="/resetPassword/:id" element={<ResetPassword />}></Route>
                                                        <Route path="/sitemap.xml" element={<Sitemap />}></Route>
                                                        <Route path="/support" element={<Support />}></Route>
                                                    </Routes>
                                                    {/* </Suspense> */}
                                                </BrowserRouter>
                                            </CloneProvider>
                                        </ShootProvider>
                                    </CounterProvider>
                                </FormProvider>
                            </HelmetProvider>
                        </HeadlessProvider>
                    </StoreProvider>
                </CookieProvider>
                <ToastContainer position={isMobile() ? 'top-center' : 'bottom-left'} />
            </AuthProvider>
        </SocketProvider>
    </QueryClientProvider>,

    // </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
