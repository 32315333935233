import { createPortal } from 'react-dom';

import 'components/common/mainSearch/mainSearch.css';
import { XCircleIcon } from '@heroicons/react/outline';
import Button from 'components/common/Button';

function MainSearch(props) {
    if (!props.open) return null;

    return createPortal(
        <div className="mainSearch">
            <div className="mainSearch-content">
                {props.title && (
                    <div className="mainSearch-title">
                        <h2>{props.title && props.title}</h2>
                    </div>
                )}
                <section className={`mainSearch-main ${!props.title && 'rounded-2xl'} ${props.fullScreen ? 'p-0' : 'py-8 px-4'}`}>{props.children}</section>
                {props.onClose?.onClick && (
                    <button className="mainSearch-close" onClick={props.onClose.onClick}>
                        <XCircleIcon className="h-8 w-8 text-white" />
                    </button>
                )}
                {props.onClose && props.onConfirm && (
                    <div className="mainSearch-actions">
                        {props.onClose && (
                            <Button
                                label={props.onClose.label ? props.onClose.label : 'Annuler'}
                                css="text-gray-500 mx-2 bg-gray-200 p-0"
                                onClick={props.onClose.onClick}
                            />
                        )}
                        {props.onConfirm && (
                            <Button
                                label={props.onConfirm.label ? props.onConfirm.label : 'Valider'}
                                css="text-white m-0 p-0 bg-secondary"
                                disabled={props.onConfirm.disabled}
                                onClick={() => {
                                    !props.onConfirm.disabled && props.onConfirm.onClick();
                                }}
                            />
                        )}
                    </div>
                )}
            </div>
        </div>,
        document.body
    );
}
export default MainSearch;
