import { useParams } from 'react-router-dom';

//COMPONENTS
//import Header from 'components/common/Header';
import ListMessages from '../../containers/cloneai/ListMessages';
//LAYOUT

//STYLES
import 'App.css';

//UTILS
import { PageContentFadingAnimation } from 'utils/animations';
import CloneAiLayout from '../layouts/CloneAi';

function CloneAi() {
    const params = useParams();

    return (
        <CloneAiLayout>
            <PageContentFadingAnimation duration={400}>
                <div className="App">
                    {/*  <h1 className="h1 mx-4 sm:mx-0">Mes notifications</h1> */}

                    <main>{!params.slug && <ListMessages />}</main>
                </div>
            </PageContentFadingAnimation>
        </CloneAiLayout>
    );
}

export default CloneAi;
