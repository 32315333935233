// import { useContext, useEffect } from 'react';

//CONTEXTS
// import { HeadlessContext } from '../..';
//
import HeadlessCodeBlock from '../../components/content/HeadlessCodeBlock';

export default function HeadlessPageConfigHelp(props) {
    // const store = useContext(HeadlessContext);

    return (
        <div className="">
            <span className="w-full inline-block font-semibold text-lg">Headless Documentation</span>
            <p className="text-[#745C89] my-2">
                Headless permet d'installer une interface backend directement sur votre projet <strong>Firebase Express React Node (FERN)</strong> et de le
                configurer de manière modulaire.
            </p>
            <p className="text-[#745C89] my-2">Vous pouvez donc créer ce que bon vous semble, un CRM, un blog, un shop, etc...</p>
            <p className="text-[#745C89] my-2">
                Headless se comporte comme une librairie qui propose des outils pour simplifier la mise en place de votre projet. Néanmoins, il inclut la
                gestion de l'authentification et la configuration des utilisateurs comme le ferait un framework.
            </p>

            <span className="w-full inline-block font-semibold">Installation</span>

            <HeadlessCodeBlock code={'npm install headless'} highlightLine={true} />
        </div>
    );
}
