//STYLES
import 'App.css';
//LAYOUT
import DashboardLayout from 'pages/layouts/Dashboard';
//LIBS
import { useParams } from 'react-router-dom';
//COMPONENTS
import Header from 'components/common/Header';
import ListRooms from 'containers/rooms/ListRooms';

function Rooms(props) {
    //HOOKS
    const params = useParams();

    return (
        <DashboardLayout>
            <div className="App">
                <Header>
                    <h1 className="h1">Mes Rooms</h1>
                </Header>
                <main>{!params.slug && <ListRooms />}</main>
            </div>
        </DashboardLayout>
    );
}

export default Rooms;
