import { useState, forwardRef } from 'react';
import { createPortal } from 'react-dom';

//LIBS
import { Cropper } from 'react-advanced-cropper';

import Navigation from './components/Navigation';
import Slider from './components/Slider';
import AdjustableCropperBackground from './components/AdjustableCropperBackground';
import './ImageEditor.css';

//ICONS
import { XCircleIcon, CheckCircleIcon } from '@heroicons/react/outline';

function ImageEditor(props, ref) {
    const [mode, setMode] = useState('crop');
    const [adjustments, setAdjustments] = useState({
        brightness: 0,
        hue: 0,
        saturation: 0,
        contrast: 0,
    });

    const cropperEnabled = mode === 'crop';
    const changed = Object.values(adjustments).some((el) => Math.floor(el * 100));

    function onChangeValue(value) {
        if (mode in adjustments) {
            setAdjustments((previousValue) => ({
                ...previousValue,
                [mode]: value,
            }));
        }
    }

    function onReset() {
        setMode('crop');
        setAdjustments({
            brightness: 0,
            hue: 0,
            saturation: 0,
            contrast: 0,
        });
    }

    if (!props.open) return null;

    return createPortal(
        <div className={'imageEditor'}>
            <div className="imageEditor__wrap">
                <div className="imageEditor__cropper">
                    <div>
                        <Cropper
                            ref={ref}
                            src={props.src}
                            stencilProps={{
                                ...props.stencilProps,
                                movable: cropperEnabled,
                                resizable: cropperEnabled,
                                lines: cropperEnabled,
                                handlers: cropperEnabled,
                                overlayClassName: `
imageEditor__cropper-overlay ${!cropperEnabled && 'imageEditor__cropper-overlay--faded'}
`,
                            }}
                            stencilComponent={props.stencilComponent}
                            sizeRestrictions={props.sizeRestrictions}
                            backgroundWrapperProps={{
                                scaleImage: cropperEnabled,
                                moveImage: cropperEnabled,
                            }}
                            backgroundComponent={AdjustableCropperBackground}
                            backgroundProps={adjustments}
                            onUpdate={props.onChange}
                        />
                        {mode !== 'crop' && (
                            <div className="w-full relative flex justify-center">
                                <Slider value={adjustments[mode]} onChange={onChangeValue} />
                            </div>
                        )}
                    </div>
                    <div className="absolute top-4 right-4 flex gap-2">
                        <XCircleIcon
                            className="w-8 h-8 text-white cursor-pointer"
                            onClick={props.onCancel}
                        />
                        <CheckCircleIcon
                            className="w-8 h-8 text-white cursor-pointer"
                            onClick={() => props.onValidate(adjustments)}
                        />
                    </div>
                </div>
                <Navigation mode={mode} onChange={setMode} reset={onReset} changed={changed} />
            </div>
        </div>,
        document.body
    );
}

export default forwardRef(ImageEditor);
