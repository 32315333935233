import * as yup from 'yup';

const schema = yup.object().shape({
    question: yup.string().when('typeForum', {
        is: 'question',
        then: yup.string().min(30, "Vous devez saisir une question d'au moins 30 caractères").required('Vous devez saisir une question'),
        otherwise: yup.string().when('typeForum', {
            is: 'job',
            then: yup.string().min(3, "Vous devez saisir l'intitulé du poste avec au moins 3 caractères").required("Vous devez saisir l'intitulé du poste"),
        }),
    }),
    news: yup.string().when('typeForum', {
        is: 'news',
        then: yup.string().min(3, "Vous devez saisir une news d'au moins 3 caractères").required('Vous devez saisir une news'),
    }),
    tags: yup.array().when('typeForum', {
        is: (val) => ['question', 'job'].includes(val),
        then: yup.array().min(1, 'Vous devez choisir au moins un tag').required('Vous devez saisir un tag'),
    }),
    isPublic: yup.boolean(),
    // description: yup.string().required('Vous devez saisir une description'),
});

export default schema;
