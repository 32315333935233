//LIBS
// import { toast } from 'react-toastify';

//API

//ICONS
import {
    CheckIcon,
    // XCircleIcon,
    // AnnotationIcon,
    // ClockIcon,
} from '@heroicons/react/solid';

//COMPONENTS
// import Modal from "components/common/Modal";
import Moment from 'components/common/Moment';

function CardBuy({ transaction }) {
    const service = transaction.services[0];
    const userTo = transaction.userTo;

    return (
        <>
            <div className="card card-service w-12/12 p-4">
                <div className="flex justify-between">
                    <span>
                        {transaction.type} <span className="text-blue-500">{service.title}</span>
                        <span className="text-sm text-blueMain ml-2">Par {userTo.profile.pseudo}</span>
                    </span>
                    <span className="text-gray-700">{transaction?.amount}€</span>
                </div>
                <div className="text-xs text-gray-400">
                    <Moment date={transaction.createdAt} format={'DD MMM YYYY - HH:mm'} />
                    <span className="text-sm font-light text-success-400 ml-2">{transaction.status}</span>
                </div>

                <div className="flex flex-nowrap justify-end">
                    <div className="card-actions w-full">
                        {/* eslint-disable-next-line */}
                        <a href="#" className="flex items-center text-success-400 cursor-pointer">
                            <CheckIcon className={`h-4 w-4 text-white bg-success-400 rounded-full mr-1`} />
                            Valider
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CardBuy;
