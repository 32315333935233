//API
import { useQuery } from 'react-query';
import { getVideo } from 'api/videos';
//STYLES
import 'App.css';
//LAYOUT
import DashboardLayout from 'pages/layouts/Dashboard';
//LIBS
import { useParams } from 'react-router-dom';
//COMPONENTS
import Header from 'components/common/Header';
import ListVideos from 'containers/videos/ListVideos';
import AddVideo from 'containers/videos/AddVideo';
import FormVideo from 'components/forms/video';

function Videos(props) {
    //HOOKS
    const params = useParams();

    //API
    const currentVideo = useQuery(
        ['video', params.id],
        (params) => {
            return getVideo(params.queryKey[1]);
        },
        { enabled: false }
    );

    return (
        <DashboardLayout>
            <div className="App">
                {params.id ? (
                    <>
                        {currentVideo.isSuccess && (
                            <Header>
                                <h1 className="h1">{currentVideo.data?.title}</h1>
                            </Header>
                        )}
                    </>
                ) : (
                    <Header>
                        <h1 className="h1">Mes espaces vidéos</h1>
                    </Header>
                )}

                <main>
                    {!params.id && <ListVideos />}
                    {!!params.id && (
                        <FormVideo
                            // slug={params.id}
                            video={currentVideo}
                            refetch={currentVideo.refetch}
                        />
                    )}
                    <AddVideo />
                </main>
            </div>
        </DashboardLayout>
    );
}

export default Videos;
