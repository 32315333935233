import { useEffect, useContext, useImperativeHandle, forwardRef, useRef, useState, useReducer } from 'react';

//LIBS
import { toast } from 'react-toastify';

//REDUCERS
import buyReducer from 'reducers/buy';

//CONTEXTS
import { AuthContext } from 'context/authContext';

//COMPONENTS
import Modal from 'components/common/Modal';
import SimpleCheckout from 'components/stripe/simpleCheckout';

//Stipe
import { getProductById } from 'api/stripe';
import constants from '../utils/constants';

function Buy(props, ref) {
    const [authState, , refresh] = useContext(AuthContext);

    const [open, setOpen] = useState({ confirm: false });
    const [success, setSuccess] = useState(false);
    const [itemData, setItemData] = useState([]);

    const [buyState, buyDispatch] = useReducer(buyReducer);

    const refCheckout = useRef(null);

    useEffect(() => {
        async function getProductStripe() {
            await getProductById(constants.stripeAi)
                .then((data) => {
                    if (data) {
                        setItemData({
                            title: `S'abonnner à ${data.product.name}`,
                            details: {
                                title: data.product.name,
                                itemId: data.product.id,
                                priceId: data.price.id,
                                description: data.product.description,
                                image: data.product.images[0],
                                amount: data.price.unit_amount / 100,
                                type: 'chatAi',
                                userTo: null,
                                userFromEmail: authState?.me?.auth?.email,
                            },
                            price: data.price,
                        });
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
        if (props.type === 'chatAi') {
            getProductStripe();
        } else {
            setItemData({
                title: `Acheter le ${props?.type} de ${props?.item?.user?.profile?.pseudo}`,
                details: {
                    itemId: props?.item?.id,
                    type: `${props?.type}`,
                    amount: `${props?.item?.price}`,
                    title: `${props?.type} de ${props?.item?.user?.profile?.pseudo} pour ${buyState?.item?.price} €`,
                    userTo: props?.item?.user?.id,
                    userFromEmail: authState?.me?.auth?.email,
                },
            });
        }
        //eslint-disable-next-line
    }, [props]);

    useImperativeHandle(ref, () => ({
        buy: () => {
            buyDispatch({ type: 'init', item: itemData, itemType: props.type });
            setOpen({ ...open, confirm: true });
        },
    }));

    function handleConfirm() {
        buyDispatch({ type: 'confirm' });
        refCheckout.current.submit();
    }

    function handleSucceedPayment() {
        setOpen({ ...open, confirm: false });
        toast.success('Achat effectué', { type: 'success' });
        setSuccess(true);
    }
    // useEffect(() => {
    //     console.log(buyState);
    // }, [buyState]);

    return (
        <>
            <Modal
                open={success}
                title={'Votre achat a bien été pris en compte.'}
                type={props.type}
                onClose={{
                    label: 'Fermer',
                    onClick: () => {
                        setSuccess(false);
                        props?.setState(false);
                        refresh();
                    },
                }}
            >
                <div>
                    <p className="text-lg text-gray-500 text-center">Votre achat a bien été pris en compte.</p>
                    <p className="text-lg text-gray-500 text-center">Merci pour votre confiance.</p>
                </div>
            </Modal>
            <Modal
                open={open.confirm}
                title={itemData.title}
                type={props.type}
                onClose={{
                    onClick: () => setOpen({ confirm: false }),
                }}
                onConfirm={{
                    label: 'Confirmer',
                    onClick: () => handleConfirm(),
                }}
            >
                <>
                    {props?.type === 'service' && (
                        <>
                            <h2 className="uppercase mb-2">{itemData.title}</h2>
                            <p className="text-sm text-gray-500 mb-4">
                                {
                                    "Le transfert d'argent vers le Skiller sera executé une fois la réalisation du service validée par les deux parties (vous et le Skiller)."
                                }
                            </p>
                        </>
                    )}
                    <SimpleCheckout
                        refCheckout={refCheckout}
                        amount={itemData?.details?.amount}
                        details={itemData?.details}
                        priceData={itemData?.price}
                        handleSucceedPayment={handleSucceedPayment}
                        type={props.type}
                    />
                </>
            </Modal>
        </>
    );
}

export default forwardRef(Buy);
