//API
import { useQuery } from "react-query";
import { getVideosByMe } from "api/videos";
//COMPONENTS
import CardVideo from "../videos/CardVideo";

function ListVideos() {
    //API
    const videos = useQuery("dashboardVideos", getVideosByMe);

    return (
        <>
            {videos.isSuccess && (
                <div className="cards">
                    {videos.data.map((video, index) => (
                        <CardVideo video={video} refetch={videos.refetch} key={index} />
                    ))}
                </div>
            )}
        </>
    );
}

export default ListVideos;
