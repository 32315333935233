// shareUtils.js

// Helper function to determine if an object is a Promise
export const isPromise = (obj) => {
    return !!obj && (typeof obj === 'object' || typeof obj === 'function') && typeof obj.then === 'function';
};

// Calculate the position to center the share window
export const getBoxPositionOnWindowCenter = (width, height) => ({
    left: window.outerWidth / 2 + (window.screenX || window.screenLeft || 0) - width / 2,
    top: window.outerHeight / 2 + (window.screenY || window.screenTop || 0) - height / 2,
});

// Function to open a new window for sharing
export const openShareWindow = (url, { width, height }, onClose) => {
    const position = getBoxPositionOnWindowCenter(width, height);
    const features = `status=no,height=${height},width=${width},left=${position.left},top=${position.top},scrollbars=yes`;
    const newWindow = window.open(url, '_blank', features);

    if (newWindow) {
        const interval = setInterval(() => {
            if (newWindow.closed) {
                clearInterval(interval);
                if (onClose) {
                    onClose();
                }
            }
        }, 1000);
    }
};
