import { useContext, useEffect, useState } from 'react';

//CONTEXTS
import { SocketContext } from 'context/socketContext';
import { AuthContext } from 'context/authContext';

function useNotifications(query) {
    const socket = useContext(SocketContext);
    const [authState] = useContext(AuthContext);

    const [counter, setCounter] = useState(null);

    useEffect(() => {
        socket.send('notificationsRefresh');
        socket.on('notificationsRefresh', () => {
            if (authState.isLogged) {
                query().then((data) => {
                    setCounter(data.counter > 99 ? '99+' : data.counter > 0 ? data.counter : null);
                });
            }
        });
        return () => {
            socket.off('notificationsRefresh');
        };

        // eslint-disable-next-line
    }, []);
    return counter;
}

export default useNotifications;
