import API from './axios.config';
import authHeader from 'services/Auth.Header';

async function getUsersByListIds(data) {
    const response = await API.post('counters/getUsersByListIds', data, {
        headers: authHeader(),
    });
    return response.data;
}

async function postCounter(data) {
    const response = await API.post('counters/push', data, {
        headers: authHeader(),
    });
    return response.data;
}

export { getUsersByListIds, postCounter };
