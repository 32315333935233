import { useState } from "react";
//LIBS
import { useForm } from "react-hook-form";
import ReactPlayer from "react-player";
//COMPONENTS
import FieldInput from "components/forms/fields/input";
import { useEffect } from "react";

function VideoOption(props) {
    //HOOKS
    const { register, watch, setValue } = useForm();

    const [url, setUrl] = useState("");

    watch((value, { name, type }) => {
        if (value !== "")
            props.dispatchOption(props.dispatch, name, value[name]);
    });

    useEffect(() => {
        if (props.readyToWatch) {
            const videoUrl = props.getValues()?.video;
            setValue("videoUrl", videoUrl);
            setUrl(videoUrl);
        }
        //eslint-disable-next-line
    }, [props.readyToWatch]);

    useEffect(() => {
        if (props.readyToWatch) {
            const subscription = watch((value, { name, type }) => {
                setUrl(value.videoUrl);
            });
            return () => subscription.unsubscribe();
        }
    }, [props.readyToWatch, watch]);

    return (
        <div className="w-full p-4">
            <p className="text-sm mt-2 text-warning-500">
                Si la vidéo ne s'affiche pas ici, elle ne s'affichera pas non
                plus sur votre profil public !
            </p>
            <div className="videoOption">
                <FieldInput
                    name="videoUrl"
                    errors={props.errors}
                    register={register}
                />
            </div>
            <div className="w-full block mt-4 rounded-lg">
                <ReactPlayer
                    url={url}
                    width="100%"
                    height="280px"
                    origin={window.location.origin}
                />
            </div>
        </div>
    );
}

export default VideoOption;
