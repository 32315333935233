import { useEffect, useContext, useRef, useState } from 'react';

//LIBS
// import _ from 'lodash';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Files from 'react-files';

//FORMS
import schema from 'components/forms/yup/shoot';

//CONTEXTS
import { AuthContext } from 'context/authContext';
import { ShootContext } from 'context/shootContext';

//ICONS
import Spinner from 'components/svgs/Spinner';

//COMPONENTS
import Modal from 'components/common/Modal';
import FieldInput from 'components/forms/fields/input';
import Tags from 'components/forms/fields/Tags';

// const videoType = new RegExp(/(mp4)$/i);

function AddShoot(props) {
    const [authState] = useContext(AuthContext);
    const shoot = useContext(ShootContext);

    const filesRef = useRef(null);
    const currentForm = useRef();

    const [open, setOpen] = useState(false);
    const [openError, setOpenError] = useState(false);
    const [codeError, setCodeError] = useState();
    const [messageError, setMessageError] = useState();

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        // getValues,
    } = useForm({
        resolver: yupResolver(schema),
    });

    const autorizedExtensions = [
        'video/mp4',
        'video/quicktime',
    ];

   /*  function onFilesChange(uploadedFiles) {
        if (uploadedFiles.length >= 1) {
            shoot.dispatch({ type: 'addFile', file: uploadedFiles[0] });
        }
    } */

    function checkVideoQuality(video) {
        const height = video.videoHeight;
        const width = video.videoWidth;
        const decodedFrames = video.mozDecodedFrames;
      
        if (height > 2160 && width > 3840) {
          // Video is 4K or higher
          return "4K ou plus";
        } else if ((decodedFrames / video.duration) > 60) {
          // Video has frame rate higher than 60 fps
          return "supérieur à 60 fps";
        } else {
          // Video is within allowed limits
          return "within allowed limits";
        }
      }

    function onFilesChange(uploadedFiles) {
        if (uploadedFiles.length >= 1) {
            const file = uploadedFiles[0];
            const video = document.createElement("video");
            video.preload = "metadata";
            video.addEventListener("loadedmetadata", function() {
                const quality = checkVideoQuality(video);
                //if vide is not in the autorized extensions
                if (!autorizedExtensions.includes(file.type)) {
                    setCodeError(`file-invalid-quality`);
                    setMessageError('Format de vidéo incorrect: le format doit être mp4 ou mov');
                    setOpenError(true);
                } else if (quality !== "within allowed limits") {
                    setCodeError(`file-invalid-quality`);
                    setMessageError(quality)
                    setOpenError(true);
                } else {
                    // Video is within allowed limits
                    shoot.dispatch({ type: "addFile", file });
                }
                video.parentNode.removeChild(video);
                URL.revokeObjectURL(video.src); // Revoke the object URL after processing the video
                video.remove(); // Remove the video element from the DOM
            });
            video.src = URL.createObjectURL(file);
            document.body.appendChild(video); // Add the video element to the DOM to load its metadata
        }
    }

    function onFilesError(error, file) {
        setOpenError(true);
        setCodeError(error.code);
        //setMessageError(error.message)
        // console.log('error code ' + error.code + ': ' + error.message);
    }

    function handleSubmitShoot() {
        handleSubmit(onSubmit)();
    }

    function onSubmit(data) {
        setOpen(false);
        shoot.dispatch({ type: 'send', data });
    }

    useEffect(() => {
        if (shoot.state.type === 'addFile') {
            setOpen(true);
        }
        if (shoot.state.type === 'progress') {
            setOpen(false);
            // console.log(shoot.state.progress);
        }
        //eslint-disable-next-line
    }, [shoot.state]);

    return (
        <div>
            <Modal
                title="Ajouter un Shoot"
                open={open}
                onConfirm={{
                    label: 'Publier',
                    onClick: () => {
                        handleSubmitShoot();
                    },
                }}
                onClose={{
                    onClick: () => {
                        setOpen(false);
                        shoot.dispatch({ type: 'reset' });
                    },
                }}
            >
                <div>
                    <p className="text-sm text-graySkills">Votre vidéo:</p>
                    <p className="text-sm text-purpleSkills mb-2 inline-block">{shoot.state?.file?.name}</p>
                    <p className="text-sm text-purpleSkills mb-2 inline-block">{shoot.state?.file?.duration ? shoot.state.file.duration.toFixed(2) + 's' : ''}</p>
                    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                        <FieldInput inputForm="floating" name="title" label={'Titre*'} size="w-full" errors={errors} register={register} />
                        <Tags
                            name="tags"
                            label=" "
                            register={register}
                            initValue={[]}
                            onChange={(value) => {
                                setValue('tags', value);
                            }}
                            countUsedTag={true}
                            readyToWatch={true}
                        />
                        <input ref={currentForm} type="submit" className="hidden" />
                    </form>
                </div>
            </Modal>
            <Modal
                title="Erreur sur votre fichier"
                open={openError}
                onConfirm={{
                    label: 'Ok',
                    onClick: () => {
                        setOpenError(false);
                        shoot.dispatch({ type: 'reset' });
                    },
                }}
                onClose={{
                    onClick: () => {
                        setOpenError(false);
                        shoot.dispatch({ type: 'reset' });
                    },
                }}
            >
                <div>
                    <p className="text-red">Votre fichier contient des erreurs</p>
                    
                    {/* {codeError === 2 && <p className="text-sm text-main">Votre fichier dépasse la taille autorisée (100Mo)</p>} */}
                    <div className="text-center">
                        {codeError === 2 && (
                            <p className="text-sm text-redSkills font-semibold mb-2">
                                La taille de la vidéo dépasse la limite autorisée (500Mo).
                            </p>
                        )}
                        {codeError === 'file-invalid-quality' && (
                            <p className="text-sm text-redSkills font-semibold mb-2">
                                {`Format de vidéo incorrect : ${messageError}.`}
                            </p>
                        )}
                    </div>
                </div>
            </Modal>

            <Files
                ref={filesRef}
                clickable
                className="files-dropzone"
                onChange={onFilesChange}
                onError={onFilesError}
                accepts={
                    props.accepts ?? [
                        'video/mp4',
                        'video/ogg',
                        'video/webp',
                        'video/webm',
                        'video/avi',
                        'video/quicktime',
                        'video/3gpp',
                        'application/x-mpegURL',
                        'video/MP2T',
                    ]
                }
                multiple={false}
                maxFileSize={500000000} //500Mo
                // maxFiles={2}
                minFileSize={1}
            >
                {props.children}
            </Files>

            {shoot.state.type === 'progress' && shoot.state.pending && authState.isLogged && (
                <div className="fixed bottom-10 right-10 w-auto max-w-full h-auto z-50 rounded-lg p-8 bg-white shadow-md">
                    <div className="w-full z-10 absolute bottom-0 left-0">
                        <div className="h-2 absolute left-0 bottom-0 bg-success-400 rounded-lg" style={{ width: `${shoot.state.progress ?? 0}%` }}></div>
                    </div>
                    <div className="z-20">
                        <div className="flex gap-2 justify-between items-center">
                            <p className="text-purpleSkills mb-1">Vidéo en cours de tranfert</p>
                        </div>
                        <div className="flex gap-2 justify-between items-center">
                            <p className="text-mainText text-sm">{shoot?.state?.file?.name ?? 'vidéo de test.mp4'}</p>
                            <Spinner />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default AddShoot;
