import { useState, useEffect, useContext } from 'react';

//LIBS
import _ from 'lodash';

//CONTEXTS
import { HeadlessContext } from '../../index.js';
import { HeadlessNotificationsContext } from '../HeadlessNotifications.jsx';

//HOOKS
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import useRequest from '../../services/useRequest';

//SCHEMAS
import schema, { schemaUpdate } from '../../yup/users';

//ICONS
import { AiOutlineUserAdd, AiOutlineDelete, AiOutlineControl } from 'react-icons/ai';
import { ImSpinner8 } from 'react-icons/im';

//COMPONENTS
import HeadlessInput from '../content/HeadlessInput';
import HeadlessTable from '../content/HeadlessTable';
import HeadlessModal from '../HeadlessModal';

export default function HeadlessFormUsers() {
    const store = useContext(HeadlessContext);
    const notif = useContext(HeadlessNotificationsContext);

    const request = useRequest();

    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [modalUpdate, setModalUpdate] = useState(false);
    const [currentUser, setCurrentUser] = useState({});

    const formUser = useForm({
        resolver: yupResolver(schema),
    });

    const formUserUpdate = useForm({
        resolver: yupResolver(schemaUpdate),
    });

    const fetchUsers = async () => {
        const data = await request.get({ route: 'headless/auth/users' });

        if (data) {
            const authUsers = [];
            _.forEach(data?.users, (user) => {
                authUsers.push({ uid: user.uid, email: user.email, role: user.customClaims.role });
            });
            setUsers(authUsers);
        }
    };

    async function onSubmit(data) {
        if (formUser.formState.errors.length > 0) return false;

        setLoading(true);
        request
            .post({ route: 'headless/auth/createUser', data: { email: data.email, password: data.password, role: 'user' } })
            .then((data) => {
                notif.set({ action: 'add', type: 'success', message: 'Utilisateur ajouté' });
                setUsers([...users, { uid: data.user.uid, email: data.user.email, role: data.role }]);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            });
    }

    async function handleTableUpdate(item) {
        formUserUpdate.reset();
        setCurrentUser(item);
        setModalUpdate(true);
    }

    // async function handleTableToggleStatus(item) {
    //     const status = item.status === 'publish' ? 'unpublish' : 'publish';
    //     // await db.update('/users', item.id, { status }).then(() => {
    //     //     fetchUsers().catch(console.error);
    //     // });
    // }

    async function handleTableDelete(item) {
        const userDeleted = await request.post({ route: 'headless/auth/deleteUser', data: { uid: item.uid } });

        if (userDeleted) {
            setUsers(_.reject(users, (n) => n.uid === item.uid));
            notif.set({ action: 'add', type: 'success', message: 'Utilisateur supprimé' });
        }
    }

    function handleConfirm() {
        formUserUpdate.handleSubmit(submitUpdate)();
    }

    async function submitUpdate(data) {
        if (formUser.formState.errors.length > 0) return false;

        setLoading(true);
        request
            .post({ route: 'headless/auth/updateUser', data: { ...data, uid: currentUser.uid } })
            .then(() => {
                var index = _.findIndex(users, { uid: currentUser.uid });
                const clonedUsers = users;
                clonedUsers.splice(index, 1, { ...clonedUsers[index], email: data.email, role: data.role });
                setUsers(clonedUsers);

                notif.set({ action: 'add', type: 'success', message: 'Utilisateur modifié' });
                setModalUpdate(false);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            });
    }

    const actions = [
        {
            jsx: <AiOutlineControl />,
            if: (item) => item.id !== store.get('me')?.uid && store.get('me')?.role === 'admin',
            onClick: handleTableUpdate,
        },
        // {
        //     jsx: <AiOutlineStop className="text-red" />,
        //     if: (item) => item.id !== store.get('me')?.id && store.get('me')?.role === 'admin',
        //     onClick: handleTableToggleStatus,
        //     classNameIf: (item) => item?.status === 'unpublish',
        // },
        {
            jsx: <AiOutlineDelete />,
            if: (item) => item?.uid !== store.get('me')?.uid && store.get('me')?.role === 'admin',
            onClick: handleTableDelete,
        },
    ];

    const colors = [
        // {
        //     if: (item) => !item.password,
        //     color: 'bg-[#EF8E1E77]',
        //     legend: 'Pas de mot de passe',
        // },
        // {
        //     if: (item) => item?.data?.status === 'unpublish',
        //     color: 'bg-[#FF000077]',
        //     legend: 'Compte désactivé',
        // },
        // {
        //     if: (item) => item?.data?.status === 'publish',
        //     color: 'bg-[#25B30A66]',
        //     legend: 'Compte actif',
        // },
    ];
    const keys = ['email', 'role'];

    useEffect(() => {
        fetchUsers().catch(console.error);
        //eslint-disable-next-line
    }, []);

    // useEffect(() => {
    //     console.log(currentUser);
    //     //eslint-disable-next-line
    // }, [currentUser]);

    return (
        <div className="mt-4">
            <HeadlessModal
                title="Modifier un role"
                open={modalUpdate}
                loading={loading}
                onClose={{ action: () => setModalUpdate(false) }}
                onConfirm={{ label: 'Enregistrer', action: handleConfirm }}
            >
                <div className="grid grid-cols-1 gap-2 justify-start w-full">
                    <HeadlessInput
                        label="Adresse email"
                        type="email"
                        value={currentUser.email}
                        register={formUserUpdate.register('email')}
                        errors={formUserUpdate.formState.errors}
                    />
                    <HeadlessInput
                        label="Mot de passe"
                        placeholder="Ne sera pas changé si vide"
                        type="password"
                        register={formUserUpdate.register('password')}
                        errors={formUserUpdate.formState.errors}
                    />
                    {store.get('me').role === 'admin' && currentUser?.uid !== store.get('me')?.uid && (
                        <HeadlessInput
                            label="Rôle"
                            type={{
                                select: [
                                    { label: 'Administrateur', value: 'admin' },
                                    { label: 'Utilisateur', value: 'user' },
                                ],
                            }}
                            value={currentUser.role}
                            register={formUserUpdate.register('role')}
                            errors={formUserUpdate.formState.errors}
                        />
                    )}
                </div>
            </HeadlessModal>

            {store.get('me').role === 'admin' && (
                <form onSubmit={formUser.handleSubmit(onSubmit)} autoComplete="off" className="mb-4">
                    <div className="grid grid-cols-2 gap-2 justify-start w-full">
                        <HeadlessInput
                            label="Adresse email"
                            type="email"
                            register={formUser.register('email')}
                            errors={formUser.formState.errors}
                            autoComplete="disabled"
                        />
                        <HeadlessInput
                            label="Mot de passe"
                            type="password"
                            register={formUser.register('password')}
                            errors={formUser.formState.errors}
                            actions={[
                                {
                                    jsx: loading ? <ImSpinner8 className="animate-spin" /> : <AiOutlineUserAdd />,
                                    onClick: () => formUser.handleSubmit(onSubmit)(),
                                },
                            ]}
                            autoComplete="disabled"
                        />
                    </div>
                </form>
            )}
            <div className="w-full">
                <HeadlessTable items={users} keys={keys} actions={actions} colors={colors} />
            </div>
        </div>
    );
}
