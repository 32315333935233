import API from './axios.config';
import authHeader from 'services/Auth.Header';

async function postNotification(data) {
    const response = await API.post('notifications/push', data, {
        headers: authHeader(),
    });
    return response.data;
}

async function enableSocialNotification(data) {
    const response = await API.put('notifications/option/enable', data, {
        headers: authHeader(),
    });
    return response.data;
}

async function getNotificationsPagination(params) {
    const response = await API.get(`notifications` + params, {
        headers: authHeader(),
    });
    return response.data;
}

async function getNotifications(value) {
    const response = await API.get('notifications/pull', {
        headers: authHeader(),
    });
    return response.data;
}

async function getNewNotifications(value) {
    const response = await API.get('notifications/newnotifications', {
        headers: authHeader(),
    });
    return response.data;
}

async function getSocialNotifications(value) {
    const response = await API.get('notifications/options', {
        headers: authHeader(),
    });
    return response.data;
}

//getSocialNotificationsByUser() {
//const response = await API.get("notifications/options", {
//      headers: authHeader(),
//  });
//  return response.data
//}

async function deleteNotification(id) {
    const response = await API.delete('notifications/delete/' + id, {
        headers: authHeader(),
    });
    return response.data;
}

async function archiveNotification(id, data) {
    const response = await API.put('notifications/archive/' + id, data, {
        headers: authHeader(),
    });
    return response.data;
}

async function archiveAllNotifications(data) {
    const response = await API.put('notifications/archiveall', data, {
        headers: authHeader(),
    });
    return response.data;
}

export { postNotification, getNotifications, getNotificationsPagination, deleteNotification, getSocialNotifications, enableSocialNotification, archiveNotification, archiveAllNotifications, getNewNotifications };
