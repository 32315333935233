import { useContext, useEffect, useState } from 'react';

//LIBS
import { Link } from 'react-router-dom';

//API
import { postCounter } from 'api/counters';

//CONTEXTS
import { AuthContext } from 'context/authContext';
import { StoreContext } from 'context/storeContext';

//HOOKS
import useHasCounter, { useGetListCounter } from 'hooks/useHasCounter';

//ICONS
import { BriefcaseIcon, LocationMarkerIcon, CurrencyEuroIcon, CalendarIcon, EyeIcon, ChatIcon, ClockIcon } from '@heroicons/react/solid';

//COMPONENTS
import Modal from 'components/common/Modal';
import Moment from 'components/common/Moment';

//CONTAINERS
import CardProfilSmall from 'containers/profils/CardProfilSmall';
import CountersRoom from 'containers/rooms/CountersRoom';

//UTILS
import { isMobile } from 'utils/functions';

/* const jobPrice = {
    '10k-20k': 'Entre 10k et 20k',
    '20k-30k': 'Entre 20k et 30k',
    '30k-40k': 'Entre 30k et 40k',
    '40k-50k': 'Entre 40k et 50k',
    '>50k': 'Supérieur à 50k',
}; */

/* const jobTypeContrat = {
    full: 'Temps plein',
    partial: 'Temps partiel',
    contrat: 'Contrat',
    tmp: 'Travail temporaire',
    benevolat: 'Bénévolat',
    stage: 'Stage / Alternance',
};
const jobTypePlace = {
    presential: 'Présentiel',
    remote: 'Télétravail',
    presential_remote: 'Présentiel + Télétravail',
}; */

function PoleEmploiJobRoom(props) {
    const [authState] = useContext(AuthContext);
    const store = useContext(StoreContext);

    const [isAdmin, setIsAdmin] = useState(false);
    const [modalList, setModalList] = useState(false);

    const isJobApply = useHasCounter(props.room.data.counters, 'job_apply', props.room.data._id);
    const listUsersJobApply = useGetListCounter(props.room.data.counters, 'job_apply', props.room.data._id);

    function jobApply() {
        postCounter({ type: 'job_apply', to: props.room.data._id }).then((res) => {
            props.room.refetch();
        });
    }

    function contactUser(userId) {
        store.set({ type: 'add', key: 'openChat', value: { open: true, userId: userId } });
    }

    useEffect(() => {
        setIsAdmin(authState.isLogged && authState?.me?.id === props.room.data?.user?._id ? true : false);
        //eslint-disable-next-line
    }, [authState.me]);

    return (
        <>
            {authState.isLogged && (
                <div className="flex flex-row gap-2 justify-center items-center">
                    {!isAdmin && props.showJobApply !== false && (
                        <button
                            className={`hidden sm:block fixed bottom-20 z-20 px-4 py-2 font-bold rounded-full bg-blueMain text-white hover:bg-white hover:text-blueMain transition-colors duration-400 ${
                                isJobApply ? 'active' : ''
                            }`}
                            onClick={
                                props.room.data?.user
                                    ? //go to poleEmploi.url
                                      () => jobApply()
                                    : () =>
                                          window.open(
                                              props.room.data?.poleEmploi?.partenaire?.url
                                                  ? props.room.data?.poleEmploi?.partenaire?.url
                                                  : props.room.data?.poleEmploi?.url,
                                              '_blank'
                                          )
                            }
                        >
                            {!isJobApply ? props.room.data?.user ? <>Postuler avec votre profil</> : <>Postuler</> : <>J'ai postulé</>}
                        </button>
                    )}
                    {isAdmin && listUsersJobApply?.length > 0 && (
                        <div>
                            <button className={`linkCard purple mb-4`} onClick={() => setModalList(true)}>
                                Consulter la liste des réponses à votre offre ( {listUsersJobApply?.length} )
                            </button>
                        </div>
                    )}
                </div>
            )}
            <Modal
                title={`Liste des Skillers ayant postulé`}
                open={modalList}
                onClose={{
                    label: 'Fermer',
                    onClick: () => {
                        setModalList(false);
                    },
                }}
            >
                <>
                    {listUsersJobApply.map((user, k) => (
                        <div key={k} className="flex flex-col justify-between border-b border-greySkills py-2">
                            <span className="text-xs text-mainText">
                                <Moment fromNow date={user.counters_user?.[0].updatedAt} />
                            </span>
                            <div className="flex flex-row gap-2 justify-between items-center">
                                <CardProfilSmall user={user} />
                                <div className="flex gap-2 h-8">
                                    <Link to={`/profil/${user?.profile?.slug}`} className="linkCard justify-center w-8 h-8 p-0">
                                        <EyeIcon className="w-4 h-4" />
                                    </Link>
                                    <button className="linkCard justify-center w-8 h-8 p-0" onClick={() => contactUser(user.id)}>
                                        <ChatIcon className="w-4 h-4" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                </>
            </Modal>
            <div className="w-full flex flex-col gap-1">
                <div className={`w-full mt-4 sm:mt-4 sm:w-2/3 h-fit shadow-sm rounded-xl text-purpleSkills bg-white p-2`}>
                    {props.room?.data?.poleEmploi?.salaire && (
                        <div className={`${isMobile() && 'justify-start'} px-4 py-2 flex flex-row text-m gap-2 font-semibold`}>
                            <CurrencyEuroIcon className="w-5 h-5" />
                            <div className="flex flex-col gap-2 w-fit">
                                {props.room?.data?.poleEmploi?.salaire &&
                                    Object.keys(props.room?.data?.poleEmploi?.salaire).map((key, k, array) => (
                                        <span key={k}>{props.room?.data?.poleEmploi?.salaire[key]}</span>
                                    ))}
                            </div>
                        </div>
                    )}
                    {props.room?.data?.poleEmploi?.address && (
                        <p className={`${isMobile() && 'justify-start'} px-4 py-2 flex flex-row text-m gap-2 font-semibold`}>
                            <LocationMarkerIcon className="w-5 h-5" />
                            {props.room?.data?.poleEmploi?.address?.codePostal}, {props.room?.data?.poleEmploi?.address?.commune}
                        </p>
                    )}
                    {props.room?.data?.poleEmploi?.contratType && (
                        <p className={`${isMobile() && 'justify-start'} px-4 py-2 flex flex-row text-m gap-2 font-semibold`}>
                            <BriefcaseIcon className="w-5 h-5" />
                            {props.room?.data?.poleEmploi?.contratType}
                        </p>
                    )}
                    {props.room?.data?.poleEmploi?.dureeTravailLibelle && (
                        <p className={`${isMobile() && 'justify-start'} px-4 py-2 flex flex-row text-m gap-2 font-semibold`}>
                            <ClockIcon className="w-5 h-5" />
                            {props.room?.data?.poleEmploi?.dureeTravailLibelle}
                        </p>
                    )}
                    {props.room?.data?.poleEmploi?.experienceLibelle && (
                        <p className={`${isMobile() && 'justify-start'} px-4 py-2 flex flex-row text-m gap-2 font-semibold`}>
                            <CalendarIcon className="w-5 h-5" />
                            {props.room?.data?.poleEmploi?.experienceLibelle}
                        </p>
                    )}
                </div>
                <div className={`w-full content break-words my-2 text-m text-[#000a53] py-2 gap-2`}>
                    {props.room?.data?.poleEmploi?.competences && (
                        <>
                            <h2 className="font-bold text-xl text-purpleSkills ">Compétences requises :</h2>
                            {props.room?.data?.poleEmploi?.competences.map((competence, k) => (
                                <p className="px-8 py-1">
                                    <span key={k}>- {competence.libelle}</span>
                                </p>
                            ))}
                        </>
                    )}
                    {props.room?.data?.poleEmploi?.formations && (
                        <>
                            <h2 className="font-bold text-xl text-purpleSkills">Formations requises :</h2>
                            {props.room?.data?.poleEmploi?.formations.map((formation, k) => (
                                <p className="px-8 py-1">
                                    <span key={k}>- {formation.niveauLibelle}</span>
                                </p>
                            ))}
                        </>
                    )}
                    <div>
                        <h2 className="font-bold text-xl text-purpleSkills mb-4">Description :</h2>
                        <div dangerouslySetInnerHTML={{ __html: props.room?.data?.description }} style={{ whiteSpace: 'pre-wrap' }}></div>
                    </div>
                </div>
            </div>
            <div className="py-4">
                <CountersRoom room={props.room} />
            </div>
        </>
    );
}

export default PoleEmploiJobRoom;
