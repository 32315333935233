import { useContext, Fragment, cloneElement } from 'react';

//UTILS
import Filters from '../utils/filters';

//CONTEXTS
import { HeadlessContext } from '../index.js';

//COMPONENTS
import HeadlessModule from './HeadlessModule';
import HeadlessPageConfig from '../pages/HeadlessPageConfig';

export default function HeadlessPages(props) {
    const filters = new Filters();

    const store = useContext(HeadlessContext);

    const config = store.get('config');

    return (
        <div className="w-full h-full relative flex flex-col">
            {config?.pages
                ?.filter((item) => filters.pagesByRoles(item, store.get('me')?.role))
                ?.map((item, k) => (
                    <Fragment key={k}>
                        {item.page.key === store.get('page') && (
                            <div className="flex flex-col w-full h-full pl-8">
                                <div className="flex gap-1 items-center text-[#745C89]">
                                    {cloneElement(item.page.icon, { className: 'w-6 h-6' })}
                                    <span className="inline-block font-semibold text-lg">{item.page.label}</span>
                                </div>
                                <div className="w-full mb-4">
                                    {item.page?.actions?.map((action, k) => (
                                        <button className="text-xs text-gray-400" key={k} onClick={() => action?.onClick?.(props.data)}>
                                            {action.label}
                                        </button>
                                    ))}
                                </div>
                                <div className="relative block w-full overflow-auto p-0 pb-1">
                                    {item.modules?.map((grid, k2) => (
                                        <div key={k2} className={`grid grid-cols-1 md:grid-cols-${grid.grid ? grid.grid : '1'} gap-2 mt-2`}>
                                            {grid.row?.map((module, k3) => (
                                                <HeadlessModule key={k3} module={module} />
                                            ))}
                                            {!grid.row && <HeadlessModule module={grid} />}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </Fragment>
                ))}
            {store.get('page').includes('hlConfig') && (
                <div className="relative flex flex-col items-stretch w-full h-full p-0">
                    <HeadlessPageConfig />
                </div>
            )}
        </div>
    );
}
