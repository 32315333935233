import { useState } from 'react';

//CONFIG
import config from 'utils/constants';
import ShareIcon from '../svgs/Share';

//ICONS
//import { ShareIcon } from '@heroicons/react/outline';
import ShareOptions from './ShareOptions';

function Share(props) {
    const [isOpen, setIsOpen] = useState(false);
    const [currentUrl] = useState(props?.hrefUrl ? props.hrefUrl : config.clientUrl + props.url);

    const togglePopup = () => setIsOpen(!isOpen);

    return (
        <>
            <button
                onClick={togglePopup}
                className={`${props?.className ? props?.className : 'rounded-xl'}  ${props?.size ? props?.size : 'w-10 h-10 sm:w-8 sm:h-8'} ${
                    props?.type === 'outline' ? (props?.color ? props?.color : 'text-purpleSkills') : ' bg-opacity-20 backdrop-blur-sm bg-graySkills text-white'
                }  flex items-center justify-center sm:hover:text-blueMain`}
            >
                <ShareIcon className="h-6 w-6" />
            </button>

            <ShareOptions url={currentUrl} onClose={togglePopup} isOpen={isOpen}/>
        </>
    );
}

export default Share;
