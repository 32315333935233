import React, { useState } from 'react';

//COMPONENTS
import Modal from 'components/common/Modal';

//STYLES
import 'components/forms/forms.css';

//UTILS
import { PageContentFadingAnimation } from 'utils/animations';
import { XIcon } from '@heroicons/react/outline';
import AiTrainFirstStep from './aiTrainFirstStep';
import AiTrainSndStep from './aiTrainSndStep';

function FormAiTrainSteps(props) {
    const [page, setPage] = useState(0);

    const handleCloseSteps = () => {
        props.setOpenAiTrain(false);
    };

    const conditionalComponent = () => {
        switch (page) {
            case 0:
                return <AiTrainFirstStep closeSteps={handleCloseSteps} page={page} setPage={setPage} />;
            case 1:
                return <AiTrainSndStep closeSteps={handleCloseSteps} page={page} setPage={setPage} />;
            default:
                return <AiTrainFirstStep closeSteps={handleCloseSteps} page={page} setPage={setPage} />;
        }
    };

    return (
        <>
            <PageContentFadingAnimation duration={250}>
                <Modal
                    className={''}
                    typeModal={'ai'}
                    aiBg={true}
                    modalWidth={'container relative mx-auto w-full sm:w-2/3 lg:w-2/3 xl:w-2/5'}
                    open={props.openAiTrain}
                >
                    <div className="-mx-2 sm:-mx-8 -mt-8">
                        <div className="relative flex items-center justify-center p-4 bg-black mb-4 sm:rounded-t-xl">
                            <div className="flex flex-col items-center">
                                <span className="text-white text-3xl font-extrabold">AI Train</span>
                                <span className="text-white text-lg font-bold">Pour un clone plus proche de vous</span>
                            </div>
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    setPage(0);
                                    props.setOpenAiTrain(false);
                                }}
                                className="absolute right-2 text-white justify-end"
                            >
                                <XIcon className="w-8" />
                            </button>
                        </div>
                        <div className={`w-full`}>
                            <div className="px-4 sm:px-8">{conditionalComponent()}</div>
                        </div>
                    </div>
                </Modal>
            </PageContentFadingAnimation>
        </>
    );
}

export default FormAiTrainSteps;
