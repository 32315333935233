import { useMutation, useQueryClient } from 'react-query';
import { postCounter } from 'api/counters';

function usePostCounter() {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: postCounter,
        onMutate: async (variables) => {
            const { isListing, listingQueryKeys } = variables;

            const targetQueries = isListing ? listingQueryKeys : undefined;
            let previousDatas;

            if (targetQueries) {
                await Promise.all(targetQueries.map((queryKey) => queryClient.cancelQueries(queryKey, { exact: false })));
                previousDatas = targetQueries.flatMap((queryKey) => queryClient.getQueriesData(queryKey));

                // Log les données avant la mutation
                // console.log('Data before mutation:', previousDatas);
            }

            return { previousDatas, targetQueries };
        },
        onSuccess: (data, variables, context) => {
            const { isListing, listingQueryKeys } = variables;
            const targetQueries = isListing ? listingQueryKeys : undefined;

            if (targetQueries) {
                // Attente de l'invalidation des requêtes
                Promise.all(targetQueries.map((queryKey) => queryClient.invalidateQueries(queryKey, { exact: false })));

                // const data = targetQueries.flatMap((queryKey) => queryClient.getQueriesData(queryKey));
                // console.log('Queries invalidated for', data);
            }
        },
        onError: (error, variables, context) => {
            const { type, to } = variables;

            console.error(`Mutation failed for action: ${type}, to: ${to}, error: ${error.message}`);
            // Restauration des données
            if (context?.previousDatas) {
                context.previousDatas.forEach(([queryKey, data]) => {
                    queryClient.setQueryData(queryKey, data);
                });
            }
        },
    });

    const postCounterMutate = async ({ type, to, value, userTo, isListing = false, listingQueryKeys = [] }) => {
        try {
            const data = await mutation.mutateAsync({ type, to, value, userTo, isListing, listingQueryKeys });

            return data;
        } catch (error) {
            console.error(`Mutation failed for action: ${type}, to: ${to}, error: ${error.message}`);
        }
    };

    return { postCounterMutate, isLoading: mutation.isLoading, isError: mutation.isError };
}

export default usePostCounter;
