import { useEffect, useRef, useState, forwardRef } from 'react';
import config from 'utils/constants';

import { isMobile } from 'utils/functions';
//STYLES
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { PlayIcon, VolumeOffIcon } from '@heroicons/react/solid';

function Video(props, ref) {
    const [playing, setPlaying] = useState(false);
    const [fitPoster, setFitPoster] = useState('cover');

    const videoPlayer = useRef(null);

    const [state, setState] = useState({
        url: null,
        pip: false,
        playing: props.playing ?? false,
        controls: false,
        light: props.light ?? false,
        playsinline: props.playsinline ?? false,
        volume: 1,
        muted: props.muted ?? true,
        played: 0,
        loaded: 0,
        duration: 0,
        playbackRate: 1.0,
        loop: true,
        infos: props.showInfos ?? false,
        width: props.width ? props.width : 'auto',
        height: props.height ?? '100%',
    });

    function onStart() {}

    function onReady() {
        if (!state.playing && props.seekTo) videoPlayer.current.currentTime = 5;
    }

    const handleContextMenu = (event) => {
        event.preventDefault(); // Empêche le menu contextuel par défaut
    };

    const handleVideoPress = () => {
        if (videoPlayer.current) {
            if (!playing) {
                videoPlayer.current.play();
                setPlaying(true);
            } else {
                videoPlayer.current.pause();
                setPlaying(false);
            }
        }
    };

    useEffect(() => {
        setState({ ...state, playing: props.shoot._id === props.playing ? true : false });
        //eslint-disable-next-line
    }, [props.playing]);

    useEffect(() => {
        const img = new Image();
        img.src = props.poster;

        const handleImageLoad = () => {
            if (props.shoot?.video?.position === 'portrait') {
                if (img.naturalWidth > img.naturalHeight) {
                    setFitPoster('cover');
                } else {
                    setFitPoster('contain');
                }
            } else {
                setFitPoster('contain');
            }
        };
        img.addEventListener('load', handleImageLoad);

        return () => {
            img.removeEventListener('load', handleImageLoad);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.poster]);

    useEffect(() => {
        if (props.playing) {
            //console.log(props.playing)
            if (videoPlayer.current) {
                //console.log(videoPlayer.current)
                let startPlayPromise = videoPlayer.current.play();

                if (startPlayPromise !== undefined) {
                    startPlayPromise
                        .then(() => {
                            // Start whatever you need to do only after playback
                            // has begun.
                            //videoPlayer.current.muted = false;
                        })
                        .catch((error) => {
                            if (error.name === 'NotAllowedError') {
                                props?.handleMuted();
                                /* videoPlayer.current.load(); */
                            } else {
                                console.log(error);
                                console.log(error.name);
                                //console.log(error)
                                // Handle a load or playback error
                            }
                        });
                }
            }
        } else {
            if (videoPlayer.current) {
                if (videoPlayer.current.play) {
                    videoPlayer.current.pause();
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.playing]);

    return (
        <>
            {isMobile() ? (
                <div
                    className={`playerContainer relative h-full w-[100vw] flex overflow-hidden ${props.videoFitCover !== false && 'video-fit-cover'}`}
                    onClick={(e) => {
                        e.preventDefault();
                        handleVideoPress();
                    }}
                >
                    <>
                        {props.playing && (
                            <div className="h-full w-full">
                                <video
                                    ref={videoPlayer}
                                    crossOrigin="use-credentials"
                                    preload="auto"
                                    src={
                                        process.env.NODE_ENV === 'production'
                                            ? `https://d26deilyej5ndi.cloudfront.net/shoots/${props.shoot?.user?.id}/${props.shoot?.video?._id}.mp4`
                                            : `${config.serverUrl + `/stream/${props.shoot?.user?.id}/${props.shoot?.video?._id}.mp4`}`
                                    }
                                    loop
                                    controls={props.controls ? props.controls : state.controls}
                                    muted={props.muted}
                                    autoPlay={true}
                                    playsInline
                                    onCanPlay={onReady}
                                    onPlay={props.onStart ?? onStart}
                                    onContextMenu={handleContextMenu}
                                    /*poster={props.poster} */
                                    style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: fitPoster, zIndex: 3 }}
                                    onLoadStart={(data) => {
                                        //console.log(`load start : ${props.shoot?.title}`);
                                        //console.log(data);
                                    }}
                                    onLoadedData={(data) => {
                                        //console.log(`loaded data : ${props.shoot?.title}`);
                                    }}
                                    type="video/mp4"
                                />
                            </div>
                        )}

                        <img
                            src={props.poster}
                            alt={props.shoot?.title}
                            style={{ position: 'absolute', width: '100%', height: '100%', objectFit: fitPoster, zIndex: 2 }}
                        />

                        {/*  {props.playing && videoPlayer.current?.paused && (
                        <div className="absolute inset-0 flex items-center justify-center" style={{ zIndex: 40, transform: 'translate3d(0, 0, 0)'  }}>
                            <PlayIcon className="w-14 h-14 text-white backdrop-blur-lg rounded-full" />
                        </div>
                    )}  */}

                        {props.muted && (
                            <>
                                <div
                                    className={`absolute top-16 right-5 p-1 rounded-lg bg-white font-semibold`}
                                    style={{ zIndex: 40, transform: 'translate3d(0, 0, 0)' }}
                                >
                                    <button
                                        className="flex gap-1 items-center"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            props?.handleMuted();
                                        }}
                                    >
                                        <VolumeOffIcon className="w-5 h-5" />
                                        <span>Activer le son</span>
                                    </button>
                                </div>
                            </>
                        )}
                    </>
                </div>
            ) : (
                <>
                    <canvas className="w-full h-full" />
                    <div className={`playerContainer absolute left-0 top-0 w-full h-full`} onClick={handleVideoPress}>
                        <div className="relative w-full h-full overflow-hidden">
                            <img
                                src={props.poster}
                                alt={props.shoot?.title}
                                style={{ position: 'absolute', width: '100%', height: '100%', objectFit: fitPoster }}
                            />

                            {props.playing && (
                                <div className="absolute left-0 top-0 w-full h-full">
                                    <div className="h-full w-full">
                                        <video
                                            ref={videoPlayer}
                                            crossOrigin="use-credentials"
                                            preload="auto"
                                            src={
                                                process.env.NODE_ENV === 'production'
                                                    ? `https://d26deilyej5ndi.cloudfront.net/shoots/${props.shoot?.user?.id}/${props.shoot?.video?._id}.mp4`
                                                    : `${config.serverUrl + `/stream/${props.shoot?.user?.id}/${props.shoot?.video?._id}.mp4`}`
                                            }
                                            loop
                                            controls={props.controls ? props.controls : state.controls}
                                            muted={props.muted}
                                            autoPlay={true}
                                            playsInline
                                            onCanPlay={onReady}
                                            onPlay={props.onStart ?? onStart}
                                            onContextMenu={handleContextMenu}
                                            //poster={props.poster}
                                            style={{ width: '100%', height: '100%', objectFit: fitPoster }}
                                            onLoadStart={(data) => {
                                                //console.log(`load start : ${props.shoot?.title}`);
                                                //console.log(data);
                                            }}
                                            onLoadedData={(data) => {
                                                //console.log(`loaded data : ${props.shoot?.title}`);
                                            }}
                                            type="video/mp4"
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                        {videoPlayer.current?.paused && (
                            <div className="absolute inset-0 flex items-center justify-center" style={{ zIndex: 40, transform: 'translate3d(0, 0, 0)' }}>
                                <PlayIcon className="w-14 h-14 text-white backdrop-blur-lg rounded-full" />
                            </div>
                        )}
                        {props.muted && (
                            <div
                                className={`absolute top-16 right-5 p-1 rounded-lg bg-white font-semibold`}
                                style={{ zIndex: 10, transform: 'translate3d(0, 0, 0)' }}
                            >
                                <button
                                    className="flex gap-1 items-center"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        props?.handleMuted();
                                    }}
                                >
                                    <VolumeOffIcon className="w-5 h-5" />
                                    <span>Activer le son</span>
                                </button>
                            </div>
                        )}
                    </div>
                </>
            )}
        </>
    );
}

export default forwardRef(Video);
