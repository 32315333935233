import { useCallback, useContext, useEffect, useState } from 'react';

//LIBS
// import _ from 'lodash';
import { Link } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSpring, animated } from 'react-spring';

//UTILS
import config from 'utils/constants';
import { isMobile } from 'utils/functions';

//COMPONENTS
import AddShoot from 'components/common/shoot/AddShoot';
import Button from 'components/common/Button';
import ForumSearchBarV2 from 'containers/searchBar/ForumSearchBarV2';
import MainSearch from 'components/common/mainSearch/MainSearch';
import MainSearchBar from 'components/common/mainSearch/MainSearchBar';
import BuyCloneAi from 'components/common/BuyCloneAi';

import NotificationsNeeds from './NotificationsNeeds';
//CONTEXTS
import { AuthContext } from 'context/authContext';
import { FormContext } from 'context/formContext';
import { StoreContext } from 'context/storeContext';

//HOOKS
import useScroll from 'hooks/useScroll';

//ICONS
import { PlusCircleIcon, ShoppingBagIcon, UsersIcon, VideoCameraIcon, StarIcon, BriefcaseIcon, SearchIcon } from '@heroicons/react/solid';

function MainNavMobile() {
    const [openShoots, setOpenShoots] = useState(false);
    const [openedUserMenu, setOpenedUserMenu] = useState(false);
    const [openedAddMenu, setOpenedAddMenu] = useState(false);
    const [openedShootsMenu, setOpenedShootsMenu] = useState(false);
    const [isPlanOpen, setIsPlanOpen] = useState(false);
    const [isAlreadyPremium, setIsAlreadyPremium] = useState(false);

    // eslint-disable-next-line no-unused-vars
    const [isMenuVisible, setIsMenuVisible] = useState(false);

    //CONTEXTS
    const [authState] = useContext(AuthContext);
    const store = useContext(StoreContext);
    const [, formContextDispatch] = useContext(FormContext);

    //HOOKS
    const navigate = useNavigate();
    const location = useLocation();
    const scrollDirection = useScroll();

    const openCloseSlideAnimationUserMenu = useSpring({
        transform: openedUserMenu ? 'translateY(0%)' : 'translateY(100%)',
        onRest: () => {
            if (!openedUserMenu) {
                setIsMenuVisible(false);
            }
        },
        config: {
            duration: 225,
        },
    });

    const openCloseSlideAnimationAddMenu = useSpring({
        transform: openedAddMenu ? 'translateY(0%)' : 'translateY(100%)',
        onRest: () => {
            if (!openedAddMenu) {
                setIsMenuVisible(false);
            }
        },
        config: {
            duration: 225,
        },
    });

    const closeMenus = () => {
        setOpenedUserMenu(false);
        setOpenedAddMenu(false);
        setOpenedUserMenu(false);
        setOpenedShootsMenu(false);
    };

    // eslint-disable-next-line no-unused-vars
    const handleGoToHome = useCallback(() => {
        closeMenus();
        navigate(isMobile() ? '/home' : '/');
        //eslint-disable-next-line
    }, [authState, navigate]);

    const handleGoToProfile = useCallback(() => {
        navigate('/dashboard/profil');
    }, [navigate]);

    const handleGoToShoots = useCallback(() => {
        navigate('/shoots');
        // setOpenedShootsMenu(openedShootsMenu ? false : true);

        setOpenedUserMenu(false);
        setOpenedAddMenu(false);
        setOpenedUserMenu(false);

        //eslint-disable-next-line
    }, [openedShootsMenu]);

    const handleGoToSkillers = useCallback(() => {
        navigate('/skillers');
        // setOpenedShootsMenu(openedShootsMenu ? false : true);

        setOpenedUserMenu(false);
        setOpenedAddMenu(false);
        setOpenedUserMenu(false);

        //eslint-disable-next-line
    }, [openedShootsMenu]);

    const handleGoToJobs = useCallback(() => {
        navigate('/jobs');
        // setOpenedShootsMenu(openedShootsMenu ? false : true);

        setOpenedUserMenu(false);
        setOpenedAddMenu(false);
        setOpenedUserMenu(false);

        //eslint-disable-next-line
    }, [openedShootsMenu]);

    const handleGoToCloneAI = useCallback(() => {
        navigate('/dashboard/clone-ai');
    }, [navigate]);

    // eslint-disable-next-line no-unused-vars
    const handleOpenChat = useCallback(() => {
        store.set({ type: 'add', key: 'openChat', value: { open: true } });
    }, [store]);

    const handleOpenAddMenu = useCallback(() => {
        setOpenedAddMenu(openedAddMenu ? false : true);
        setOpenedUserMenu(false);
        setOpenedShootsMenu(false);
    }, [openedAddMenu]);

    const handleAddService = useCallback(() => {
        setOpenedAddMenu(false);
        setOpenedShootsMenu(false);

        formContextDispatch({ type: 'add' });
    }, [formContextDispatch]);

    const openMenu = () => {
        if (openedAddMenu || openedUserMenu) {
            setIsMenuVisible(false);
        } else {
            setIsMenuVisible(true);
        }

        setOpenedUserMenu(openedUserMenu ? false : true);
        setOpenedAddMenu(false);
        setOpenedShootsMenu(false);
        //eslint-disable-next-line
    };

    const handleGoToMarket = useCallback(() => {
        navigate('/services');
    }, [navigate]);

    const handleAddNeed = useCallback(() => {
        setOpenedAddMenu(false);
        setOpenedShootsMenu(false);

        formContextDispatch({ type: 'addNeed' });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formContextDispatch]);

    const handleOpenInscription = useCallback(() => {
        if (isMobile()) {
            navigate('/login');

            if (window.ReactNativeWebView) {
                window.ReactNativeWebView.postMessage('/login');
            }

            /* window.history.replaceState(null, null, `/login`); */
        } else {
            document.getElementById('button-login')?.click();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (authState?.me?.plans && authState?.me?.plans.length > 0) {
            if (authState?.me?.plans.some((plan) => plan.type === 'chatAi' && plan.status === 'succeeded')) {
                setIsAlreadyPremium(true);
            }
        }
        //eslint-disable-next-line
    }, [authState]);

    return (
        <>
            {isPlanOpen && <BuyCloneAi state={isPlanOpen} setState={setIsPlanOpen} />}
            <MainSearch
                open={openShoots}
                onClose={{
                    onClick: () => {
                        setOpenShoots(false);
                    },
                }}
            >
                <MainSearchBar onClose={() => setOpenShoots(false)} />
            </MainSearch>
            <div
                className={`main-nav-mobile ${
                    !isMobile() ? 'sm:hidden' : ''
                } sticky bottom-0 left-0 right-0 z-50 transition-all duration-300 ease-out container-fluid w-full shadow-xl mx-auto px-0 bg-white ${
                    scrollDirection === 'down' && !location.pathname?.includes('shoots') ? '-bottom-[70px]' : 'bottom-0'
                }`}
            >
                <nav className="relative mx-auto h-auto z-10">
                    {openedUserMenu && (
                        <>
                            <div className="absolute bottom-20 w-screen h-screen bg-graySkills -z-10 opacity-60" onClick={openMenu}></div>
                            <animated.div
                                style={openCloseSlideAnimationUserMenu}
                                className="relative right-0 top-0 bg-white md:hidden w-full flex-col rounded-lg menu"
                            >
                                <ul className="w-auto p-5 text-right text-gray-500 font-bold text-lg">
                                    <li className="ml-2 mb-8">
                                        <div>
                                            <button
                                                className="text-gold-500 inline-flex"
                                                onClick={() => {
                                                    if (isAlreadyPremium) {
                                                        navigate('/dashboard/params/account');
                                                    } else {
                                                        setIsPlanOpen(true);
                                                    }
                                                }}
                                            >
                                                <StarIcon className="w-5 h-5 mx-auto self-center" />
                                                <span>Offre premium</span>
                                            </button>
                                        </div>
                                    </li>
                                    <li className="my-4 ml-2">
                                        <Link to={`/profil/${authState.me?.profile?.slug}`}>Voir mon profil</Link>
                                    </li>
                                    <li className="my-4 ml-2">
                                        <button onClick={handleGoToProfile}>Modifier mon profil</button>
                                    </li>
                                    <li className="my-4 ml-2">
                                        <Link to="/dashboard/needs">Mes besoins</Link>
                                    </li>
                                    <li className="my-4 ml-2">
                                        <Link to="/dashboard/services">Ma Boutique</Link>
                                    </li>
                                    <li className="my-4 ml-2">
                                        <Link to="/dashboard/shoots">Mes shoots</Link>
                                    </li>
                                    <li className="my-4 ml-2">
                                        <Link to="/dashboard/visios/asked">Mes visios</Link>
                                    </li>
                                    <li className="my-4 ml-2">
                                        <Link to="/dashboard/params/account">Paramètres</Link>
                                    </li>
                                </ul>
                            </animated.div>
                        </>
                    )}
                    {openedShootsMenu && (
                        <div className="relative right-0 bg-blueMain md:hidden w-full flex-col shadow-2xl">
                            <ul className="w-auto p-5 text-right text-white font-bold text-lg">
                                <li className="my-4 ml-2">
                                    <button>Bientôt disponible</button>
                                </li>
                            </ul>
                        </div>
                    )}
                    {openedAddMenu && (
                        <>
                            <animated.div
                                style={openCloseSlideAnimationAddMenu}
                                className="relative right-0 top-0 bg-white md:hidden w-full flex-col rounded-lg menu"
                            >
                                <ul className="w-auto p-5 text-right text-white font-bold text-lg">
                                    <ForumSearchBarV2
                                        isButton={true}
                                        title={false}
                                        className="linkCardMainNavMobile"
                                        typeForum="news"
                                        label="Un post"
                                        iconClassName="w-6 h-6 mx-auto"
                                        mobileLabelClassName="text-xl text-gray-500 flex justify-center items-center pl-4"
                                    />
                                    <ForumSearchBarV2
                                        isButton={true}
                                        title={false}
                                        className="linkCardMainNavMobile"
                                        typeForum="question"
                                        label="Une question"
                                        iconClassName="w-7 h-7 mx-auto"
                                        mobileLabelClassName="text-xl text-gray-500 flex justify-center items-center pl-4"
                                    />
                                    <ForumSearchBarV2
                                        isButton={true}
                                        title={false}
                                        className="linkCardMainNavMobile"
                                        typeForum="job"
                                        iconClassName="w-6 h-6 mx-auto"
                                        label="Une offre d'emploi"
                                        mobileLabelClassName="text-xl text-gray-500 flex justify-center items-center pl-4"
                                    />
                                    <AddShoot>
                                        <li className="my-4 w-full flex">
                                            <Button label={<VideoCameraIcon className="w-6 h-6 mx-auto" />} justIcon={true} css="linkCardMainNavMobile" />
                                            <span className="text-xl text-gray-500 flex justify-center items-center pl-4">Un shoot</span>
                                        </li>
                                    </AddShoot>
                                    <li
                                        className="my-4 w-full flex"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            handleAddService(e);
                                        }}
                                    >
                                        <Button label={<ShoppingBagIcon className="w-6 h-6 mx-auto" />} justIcon={true} css="linkCardMainNavMobile" />
                                        <span className="text-xl text-gray-500 flex justify-center items-center pl-4">Un service / produit</span>
                                    </li>
                                    <li
                                        onClick={(e) => {
                                            e.preventDefault();
                                            handleAddNeed(e);
                                        }}
                                        className="my-4 w-full flex"
                                    >
                                        <Button label={<SearchIcon className="w-6 h-6 mx-auto" />} justIcon={true} css="linkCardMainNavMobile" />
                                        <span className="text-xl text-gray-500 flex justify-center items-center pl-4">Un besoin urgent</span>
                                    </li>
                                    {/* <ForumSearchBarV2
                                        isButton={true}
                                        title={false}
                                        className="linkCardMainNavMobile"
                                        typeForum="story"
                                        label="Ajouter à ma story"
                                        iconClassName="w-7 h-7 mx-auto"
                                        mobileLabelClassName="text-xl text-gray-500 flex justify-center items-center pl-4"
                                        isClose={() => {
                                            setOpenedAddMenu(false);
                                        }}
                                    /> */}
                                </ul>
                            </animated.div>
                            <div className="absolute bottom-20 w-screen h-screen bg-graySkills -z-10 opacity-80" onClick={handleOpenAddMenu}></div>
                        </>
                    )}
                    <div className="relative h-auto">
                        <div className="relative flex flex-row items-center justify-around py-2">
                            {/* <div className="flex flex-row justify-between items-center md:flex-none w-auto"> */}

                            <>
                                {/* <div className="">
                                    <button id="button-home" onClick={handleGoToHome} className={``}>
                                        <div
                                            className={`font-semibold ${
                                                (authState.isLogged && location.pathname === '/') || location.pathname === '/home'
                                                    ? 'text-blueMain'
                                                    : 'text-purpleSkills'
                                            }  text-[10px] flex flex-col items-center w-auto`}
                                        >
                                            <div>
                                                <RssIcon className="block w-7 h-7" />
                                            </div>
                                            <div>
                                                <span>News</span>
                                            </div>
                                        </div>
                                    </button>
                                </div> */}

                                {/* {!authState.isLogged && ( */}
                                <div className="">
                                    <button id="button-shoots" onClick={handleGoToShoots} className="">
                                        <div
                                            className={`font-semibold ${
                                                (isMobile() && location.pathname === '/') ||
                                                location.pathname === '/collection-shoots' ||
                                                location.pathname.includes('/shoots')
                                                    ? 'text-blueMain'
                                                    : 'text-purpleSkills font-semibold'
                                            } text-[10px] flex flex-col items-center w-auto`}
                                        >
                                            <div>
                                                <VideoCameraIcon className="block w-7 h-7" />
                                            </div>
                                            <div>
                                                <span>Shoots</span>
                                            </div>
                                        </div>
                                    </button>
                                </div>

                                <div className="relative">
                                    <button id="button-relations" onClick={handleGoToSkillers} className="">
                                        <div
                                            className={`${
                                                location.pathname === '/skillers' || (!authState?.isLogged && location.pathname === '/')
                                                    ? 'text-blueMain'
                                                    : 'text-purpleSkills font-semibold'
                                            }  text-[10px] flex flex-col items-center w-auto`}
                                        >
                                            <div>
                                                <UsersIcon className="block w-7 h-7" />
                                            </div>
                                            <div>
                                                <span>Matching</span>
                                            </div>
                                        </div>
                                    </button>
                                    <div className="absolute -top-2 -right-6">
                                        <NotificationsNeeds />
                                    </div>
                                </div>
                                <div className="relative">
                                    <button id="button-addMenu" onClick={authState.isLogged ? handleOpenAddMenu : handleOpenInscription} className="">
                                        <div className="text-purpleSkills font-semibold text-[10px] flex flex-col items-center w-auto">
                                            <div className="absolute -bottom-[15px] items-center z-20">
                                                <PlusCircleIcon className="block text-blueMain w-[4.5rem] h-[4.5rem]" />
                                            </div>
                                            {/*   <div className="relative top-[12.2px]">
                                                <span>Publier</span>
                                            </div> */}
                                        </div>
                                    </button>
                                </div>
                                {authState.isLogged && (
                                    <div className={`sm:hidden absolute flex flex-col items-center gap-2 bottom-16 right-3 z-10`}>
                                        {authState.isLogged && location.pathname !== '/dashboard/clone-ai' && (
                                            <button id="button-cloneAi" onClick={handleGoToCloneAI} className={`w-12 cursor-pointer p-1`}>
                                                <img src={`${config.publicDir}logo_ai_mobile.svg`} alt="ai_logo" />
                                            </button>
                                        )}
                                        {/*  {location.pathname !== '/dashboard/clone-ai' && location.pathname !== '/skillers' && (
                                            <button id="button-addMenu" onClick={authState.isLogged ? handleOpenAddMenu : handleOpenInscription} className="">
                                                <div className="text-purpleSkills font-semibold text-[10px] flex flex-col items-center w-auto">
                                                    <div className="relative flex flex-col items-center justify-center z-10">
                                                        <PlusCircleIcon className="block text-blueMain w-16 h-16 z-10" />
                                                        <div className="absolute w-12 h-12 rounded-full bg-white shadow-blueMain/60 shadow-lg"></div>
                                                    </div>
                                                </div>
                                            </button>
                                        )} */}
                                    </div>
                                )}

                                <div className="">
                                    <button id="button-jobs" onClick={handleGoToJobs} className="">
                                        <div
                                            className={`${
                                                location.pathname === '/jobs' ? 'text-blueMain' : 'text-purpleSkills font-semibold'
                                            }  text-[10px] flex flex-col items-center w-auto`}
                                        >
                                            <div>
                                                <BriefcaseIcon className="block w-7 h-7" />
                                            </div>
                                            <div>
                                                <span>Jobs</span>
                                            </div>
                                        </div>
                                    </button>
                                </div>
                                <div className="">
                                    <button id="button-services" onClick={handleGoToMarket} className="">
                                        <div
                                            className={`${
                                                location.pathname === '/services' ? 'text-blueMain' : 'text-purpleSkills font-semibold'
                                            }  text-[10px] flex flex-col items-center w-auto`}
                                        >
                                            <div>
                                                <ShoppingBagIcon className="block w-7 h-7" />
                                            </div>
                                            <div>
                                                <span>Market</span>
                                            </div>
                                        </div>
                                    </button>
                                </div>

                                {/* <div className="">
                                    <button id="button-profil" onClick={authState.isLogged ? openMenu : handleOpenInscription} className="">
                                        <div className="text-purpleSkills font-semibold text-sm flex flex-col items-center w-auto">
                                            <div>
                                                {authState.isLogged ? (
                                                    authState.me?.profile?.avatar?.url ? (
                                                        <Image
                                                            src={authState.me?.profile?.avatar?.url}
                                                            alt={authState.me?.profile?.pseudo}
                                                            loading="false"
                                                            className="rounded-full w-6 h-6"
                                                        />
                                                    ) : (
                                                        <img
                                                            className="rounded-full w-6 h-6"
                                                            src={`${config.publicDir}skillsLogo.jpg`}
                                                            alt={authState.me?.profile?.pseudo}
                                                        />
                                                    )
                                                ) : (
                                                    <UserIcon className="block w-6 h-6" />
                                                )}
                                            </div>
                                            <div>
                                                <span>Profil</span>
                                            </div>
                                        </div>
                                    </button>
                                </div> */}
                            </>
                        </div>
                    </div>
                </nav>
            </div>
        </>
    );
}

export default MainNavMobile;
