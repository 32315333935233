import { useContext, useEffect, useState } from 'react';

//LIBS
import { getDatabase, ref, set, onValue } from 'firebase/database';

//CONTEXTS
import { HeadlessContext } from '../../index.js';
import { HeadlessNotificationsContext } from '../HeadlessNotifications.jsx';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

//SCHEMAS
import schema from '../../yup/profil';

import { ImSpinner8 } from 'react-icons/im';

import HeadlessInput from '../../components/content/HeadlessInput';
import HeadlessButton from '../content/HeadlessButton.jsx';

export default function HeadlessFormProfil() {
    const store = useContext(HeadlessContext);
    const notif = useContext(HeadlessNotificationsContext);

    // const dbConfig = useJsonDb('headless_config');

    const [loading, setLoading] = useState(false);
    const [profil, setProfil] = useState({});

    const formProfil = useForm({
        resolver: yupResolver(schema),
    });

    const fetchProfil = async () => {
        const db = getDatabase();
        const user = ref(db, 'users/' + store.get('me').uid);
        onValue(user, (snapshot) => {
            const data = snapshot.val();
            setProfil({ data: { email: store.get('me').email, firstname: data?.firstname, lastname: data?.lastname } });
        });
    };

    async function writeUserData(userId, data) {
        const db = getDatabase();
        const write = await set(ref(db, 'users/' + userId), {
            firstname: data.firstname ?? '',
            lastname: data.lastname ?? '',
        });

        return write;
    }

    function onSubmit(data) {
        setLoading(true);
        writeUserData(store.get('me').uid, data).then(() => {
            notif.set({ action: 'add', type: 'success', message: 'Profil enregistré' });
            setLoading(false);
        });
    }

    useEffect(() => {
        fetchProfil().catch(console.error);
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        formProfil.setValue('email', profil?.data?.email);
        formProfil.setValue('firstname', profil?.data?.firstname);
        formProfil.setValue('lastname', profil?.data?.lastname);

        //eslint-disable-next-line
    }, [profil]);

    return (
        <>
            <form onSubmit={formProfil.handleSubmit(onSubmit)}>
                <HeadlessInput
                    label="Adresse email"
                    disabled
                    value={profil?.email}
                    register={formProfil.register('email')}
                    errors={formProfil.formState.errors}
                />
                <div className="grid grid-cols-2 gap-4">
                    <HeadlessInput label="Prénom" value={profil?.firstname} register={formProfil.register('firstname')} errors={formProfil.formState.errors} />
                    <HeadlessInput label="Nom" value={profil?.lastname} register={formProfil.register('lastname')} errors={formProfil.formState.errors} />
                </div>
                <div className="mt-4">
                    <HeadlessButton
                        activeIcon={loading}
                        icon={<ImSpinner8 className="animate-spin" />}
                        label="Enregistrer"
                        theme="important"
                        className="mt-4"
                    />
                </div>
            </form>
        </>
    );
}
