import { useState, useRef, forwardRef, useEffect } from 'react';

//API
import { getUserSuggests } from 'api/search';

//COMPONENTS
import Modal from 'components/common/Modal';

//CONTAINERS
import CardProfilSmall from 'containers/profils/CardProfilSmall';

//TODO: Envoyer une notification quand un user est linké
function UserLink(props, ref) {
    const [users, setUsers] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [input, setInput] = useState('');

    const currentValue = useRef(null);

    function suggest(e) {
        setInput(e.target.value);
        currentValue.current = e.target.value;

        if (currentValue.current.length >= 3) {
            getUserSuggests(currentValue.current).then((users) => {
                setUsers(users);
            });
        }
    }

    function reset() {
        setUsers([]);
        setInput('');
        currentValue.current = null;
    }

    function choose(e, user) {
        e?.preventDefault();
        props.onClose(user);
        reset();
    }

    function selectUser(direction) {
        if (direction === 'down') {
            if (selectedIndex < users.length - 1) if (users.length > 0) setSelectedIndex(selectedIndex + 1);
        }
        if (direction === 'up') {
            if (selectedIndex > 0) if (users.length > 0) setSelectedIndex(selectedIndex - 1);
        }
    }

    function onKeyUp(e) {
        if (e.key === 'Escape') {
            props.onClose();
            reset();
        }
        if (e.key === 'ArrowDown') {
            selectUser('down');
        }
        if (e.key === 'ArrowUp') {
            selectUser('up');
        }
        if (e.key === 'Enter') {
            choose(e, users[selectedIndex]);
        }
    }

    useEffect(() => {
        if (props.open) {
            reset();
            setTimeout(() => {
                setInput('');
            }, 100);
        }
        //eslint-disable-next-line
    }, [props.open]);

    useEffect(() => {
        if (users.length > 0) setSelectedIndex(0);
    }, [users]);

    return (
        <>
            <Modal
                open={props.open}
                onClose={{
                    onClick: () => {
                        props.onClose();
                    },
                }}
                onConfirm={{
                    label: 'Valider',
                    onClick: () => choose(null, users[selectedIndex]),
                }}
            >
                <div className="relative bg-white">
                    <input type="text" ref={ref} onChange={suggest} onKeyUp={onKeyUp} value={input} placeholder="Chercher une personne" />
                    {users.length > 0 && (
                        <div className="mt-2">
                            {users.map((user, k) => (
                                <div key={k} className={`${selectedIndex === k ? 'bg-lightBlueSkills' : ''} rounded-lg p-1`}>
                                    <CardProfilSmall user={user} onClick={(e) => choose(e, user)} />
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </Modal>
        </>
    );
}

export default forwardRef(UserLink);
