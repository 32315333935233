//LIBS
import { Link } from 'react-router-dom';

//CONTAINERS
import Avatar from 'containers/profils/Avatar';

export default function CardProfilHeadless({ user, onClick }) {
    if (!user?.profile) return null;

    return (
        <>
            <div className="w-full py-1">
                <div className="flex gap-2 flex-row items-center w-full relative">
                    <Avatar user={user} className="flex-none w-14" onClick={false} />
                    <Link
                        className="relative w-full"
                        onClick={onClick ?? null}
                        to={!onClick ? '/profil/' + user?.profile?.slug : false}
                        title={`Profil de ${user?.profile?.pseudo}`}
                    >
                        <div className="w-auto grow flex flex-wrap flex-col">
                            <span className="text-sm text-purpleSkills font-bold">{user?.profile?.pseudo}</span>
                            <span className="text-xs text-gray-400">{user?.profile?.status}</span>
                        </div>
                    </Link>
                </div>
            </div>
        </>
    );
}
