import { React, useCallback, useState } from 'react';

//COMPONENTS
import Nl2br from 'components/common/Nl2br';

//LIBS
import { useNavigate, useLocation } from 'react-router-dom';

const MAX_STRING_LENGTH = 260;

function SeeMore(props) {
    const [readMore, setReadMore] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();

    function handleReadMore() {
        setReadMore(readMore ? false : true);
    }

    const handleGoToRoom = useCallback(
        (event) => {
            event.preventDefault();
            navigate(props?.link, { state: { background: location } });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [navigate, props?.link]
    );

    return (
        <div className={`w-full`}>
            {props.link ? (
                <>
                    <div
                        onClick={() => {
                            if (props.linkActive !== false) {
                                handleGoToRoom();
                            }
                        }}
                    >
                        <span
                            className={
                                props.string !== undefined && props.string.length > MAX_STRING_LENGTH
                                    ? readMore
                                        ? 'card-item-question-expanded'
                                        : `card-item-question-non-expanded ${props.height ? props.height : 'h-[5rem]'} ${props.className}`
                                    : `card-item-question ${props.className}`
                            }
                        >
                            <Nl2br str={props.string !== undefined && props.string} />
                        </span>

                        {props.readMoreIsLink === true && props.string.length > MAX_STRING_LENGTH && (
                            <p className="hover:text-blueMain font-semibold cursor-pointer text-right text-sm">
                                {readMore === true ? 'Voir moins' : 'Voir plus...'}
                            </p>
                        )}
                    </div>
                </>
            ) : (
                <span
                    className={
                        props.string !== undefined && props.string.length > MAX_STRING_LENGTH
                            ? readMore
                                ? `card-item-question-expanded ${props.className}`
                                : `card-item-question-non-expanded ${props.height ? props.height : 'h-[5rem]'} ${props.className}`
                            : `card-item-question ${props.className}`
                    }
                    dangerouslySetInnerHTML={{
                        __html: props.string !== undefined ? props.string : '',
                    }}
                />
            )}

            {props.string !== undefined && props.string.length > MAX_STRING_LENGTH && props.readMoreIsLink !== true && (
                <div
                    className={`flex gap-2 mr-2 justify-end text-purpleSkills font-bold cursor-pointer ${props.className ? props.className : 'text-sm'}`}
                    onClick={() => handleReadMore()}
                >
                    {readMore === true ? (
                        <p>Voir Moins</p>
                    ) : (
                        <>
                            <p className="text-mainText">{'...'}</p>
                            <p>Plus</p>
                        </>
                    )}
                </div>
            )}
        </div>
    );
}

export default SeeMore;
