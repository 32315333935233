import { useEffect, useState } from 'react';
//CONTAINERS
import SectionTitle from 'components/common/SectionTitle';
//import CardFrontRoomSearch from 'containers/rooms/CardFrontRoomSearch';
import CardFrontRoom from 'containers/rooms/CardFrontRoom';

import _ from 'lodash';

function SearchRooms(props) {
    const [nb, setNb] = useState(3);
    const [items, setItems] = useState(props?.filter === false ? _.take(props.rooms, nb) : props.rooms);

    function showMore() {
        // setNb(nb + 3);
        setNb(_.size(props.rooms));
    }
    function showLess() {
        setNb(3);
    }

    useEffect(() => {
        if (nb >= 3 && props?.filter === false)
            setItems(_.take(props.rooms, nb));
        else
            setItems(props.rooms);
        //eslint-disable-next-line
    }, [nb, props.rooms, props?.filter]);

    return (
        <>
            <SectionTitle>
                <p className="text-xs text-graySkills mx-0 my-4">
                    <span className="h2 mx-0 my-2 mt-4 text-bold text-purpleSkills">Rooms :</span>
                </p>
            </SectionTitle>
            <div className="container">
                {items.map((room, index) => (
                    <div key={index}>
                        <CardFrontRoom room={room} refetch={props.refetch} />
                    </div>
                ))}
            </div>
            {props?.filter === false && nb < props.rooms?.length ? (
                <div className="container">
                    <div className="flex justify-center pt-4 mt-4 text-primary font-semibold text-sm">
                        <button onClick={showMore}>...Voir plus de posts</button>
                    </div>
                </div>
            ) : (
                props?.filter === false &&
                <>
                    {props.rooms?.length >= 3 && (
                        <div className="container">
                            <div className="flex justify-center pt-4 mt-4 text-primary font-semibold text-sm">
                                <button onClick={showLess}>...Replier</button>
                            </div>
                        </div>
                    )}
                </>
            )}
        </>
    );
}

export default SearchRooms;
