import { useCallback, useContext, useEffect } from 'react';

//API
import { useQuery } from 'react-query';
import { getService } from 'api/services';

//COMPONENTS
import Header from 'components/common/Header';
import ListServices from 'containers/services/ListServices';
//import AddService from 'containers/services/AddService';
import FormService from 'components/forms/service';

//CONTEXTS
import { FormContext } from 'context/formContext';

//ICONS
import { ShoppingBagIcon } from '@heroicons/react/solid';

//LAYOUT
import DashboardLayout from 'pages/layouts/Dashboard';

//LIBS
import { useParams } from 'react-router-dom';

//STYLES
import 'App.css';

//UTILS
import { PageContentFadingAnimation } from 'utils/animations';
import { isMobile } from '../../utils/functions';

function Services() {
    //CONTEXTS
    const [, formContextDispatch] = useContext(FormContext);

    //HOOKS
    const params = useParams();

    //API
    const currentService = useQuery(
        ['service', params.id],
        (params) => {
            if (params.queryKey[1] !== undefined) return getService(params.queryKey[1]);
        },
        { enabled: false }
    );

    const handleAddService = useCallback(() => {
        formContextDispatch({ type: 'add' });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formContextDispatch]);

    useEffect(() => {
        if (params.id !== undefined) currentService.refetch();
        //eslint-disable-next-line
    }, [params.id]);

    return (
        <DashboardLayout>
            <PageContentFadingAnimation duration={250} className="App">
                {!params.id && (
                    <>
                        <Header>
                            <h1 className="h1 mx-4">Ma Boutique</h1>
                        </Header>

                        <button
                            className="flex text-2xl font-bold linkCardDashboard gap-2 w-full px-6 py-2 mt-4"
                            type="button"
                            onClick={(e) => {
                                e.preventDefault();
                                handleAddService(e);
                            }}
                        >
                            <ShoppingBagIcon className="w-5 h-5" />
                            <span className="md:text-2xl text-xl">Ajouter un produit / service</span>
                        </button>

                        <div className="py-3"></div>
                    </>
                )}
                <main>
                    {!params.id && <ListServices />}
                    {!!params.id && <FormService service={currentService.data} refetch={currentService.refetch} />}
                    {/* <AddService /> */}
                </main>
            </PageContentFadingAnimation>
        </DashboardLayout>
    );
}

export default Services;
