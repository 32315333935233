import { useState, useContext, useEffect, useMemo } from 'react';

import { HeadlessContext } from '../index.js';

import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';

import { EditorState, ContentState, convertToRaw, convertFromHTML } from 'draft-js';

import { AiOutlineSave, AiOutlineUndo } from 'react-icons/ai';

const toolbarOptions = {
    options: ['inline', 'fontSize'],
    inline: {
        options: ['bold', 'italic', 'underline', 'strikethrough'],
    },
};

export default function HeadlessEdit(props) {
    const store = useContext(HeadlessContext);

    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [hasChanged, setHasChanged] = useState(false);
    const [initialValue, setInitialValue] = useState(null);

    var htmlData = useMemo(() => draftToHtml(convertToRaw(editorState.getCurrentContent())), [editorState]);

    const init = () => {
        setHasChanged(false);
        const htmlToDraft = convertFromHTML(props.content ?? '');
        setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft)));
    };

    const handleChange = (data) => {
        setEditorState(data);
    };

    const handleContentChange = () => {
        setHasChanged(htmlData !== initialValue ? true : false);
    };

    const onSave = () => {
        props.onSave(htmlData);
        setHasChanged(false);
        setInitialValue(htmlData);
    };

    const onUndo = () => {
        init();
    };

    useEffect(() => {
        if (props.content) {
            init();
        }
        //eslint-disable-next-line
    }, [props.content]);

    useEffect(() => {
        if (props.content && initialValue === null) setInitialValue(htmlData);
        //eslint-disable-next-line
    }, [htmlData]);

    useEffect(() => {
        setInitialValue(null);
    }, []);

    return (
        <>
            {store.get('logged') ? (
                <div className={`border-t ${hasChanged ? 'border-warning-300' : 'border-gray-400'} w-full relative`}>
                    <div className="absolute flex justify-end gap-2 top-0 right-0">
                        {hasChanged && (
                            <div onClick={onUndo} className="z-50 cursor-pointer w-5 h-5">
                                <AiOutlineUndo className={`w-5 h-5 mt-1 ${hasChanged ? 'text-warning-500' : 'text-gray-600'}`} />
                            </div>
                        )}

                        <div onClick={onSave} className="z-50 cursor-pointer w-5 h-5">
                            <AiOutlineSave className={`w-5 h-5 mt-1 ${hasChanged ? 'text-warning-500' : 'text-gray-600'}`} />
                        </div>
                    </div>
                    <Editor
                        toolbarHidden={props.toolbarHidden ?? true}
                        editorState={editorState}
                        onEditorStateChange={handleChange}
                        onContentStateChange={handleContentChange}
                        wrapperClassName="editor-wrapper"
                        editorClassName="message-editor"
                        toolbarClassName="message-toolbar"
                        toolbar={toolbarOptions}
                    />
                </div>
            ) : (
                <>{props.children}</>
            )}
        </>
    );
}
