//LIBS
import { Link } from "react-router-dom";
//COMPONENTS
import Button from "components/common/Button";
//ICONS
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";

function SectionTitle(props) {
    return (
        <div className="flex justify-between items-center flex-col md:flex-row mt-0">
            {props.children}
            {((props.prev && props.next) || props.buttonLinkTo) && (
                <div className="flex flex-nowrap">
                    {props.prev && props.next && (
                        <>
                            <Button
                                label={<ChevronLeftIcon className="w-6 h-6" />}
                                justIcon={true}
                                css="text-blue-500 p-0"
                                onClick={props.prev}
                            />
                            <Button
                                label={<ChevronRightIcon className="w-6 h-6" />}
                                justIcon={true}
                                css="text-blue-500 p-0"
                                onClick={props.next}
                            />
                        </>
                    )}
                    {props.buttonLinkTo && (
                        <Link
                            to={props.buttonLinkTo}
                            className="link mt-1 ml-2"
                            style={{ top: -1 }}
                        >
                            <span>{props.buttonLabel}</span>
                        </Link>
                    )}
                </div>
            )}
        </div>
    );
}

export default SectionTitle;
