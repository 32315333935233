import { useContext } from 'react';

//HOOKS
import useInfinite from './useInfinite';

//CONTEXTS
import { HeadlessContext } from '../../index.js';

function ItemList(props) {
    const store = useContext(HeadlessContext);

    function handleClick(action) {
        action.onClick(props.data);

        if (action.hideAfter) {
            store.set({ type: 'close' });
        }
    }

    return (
        <div className={`border border-gray-300 rounded-sm p-2 my-2 flex flex-col justify-between`}>
            <div>{props.item(props.data)}</div>
            {props.actions && (
                <div className="flex flex-row gap-1 justify-end">
                    {props.actions.map((action, k) => (
                        <button className="text-xs text-gray-400" key={k} onClick={() => handleClick(action)}>
                            {action.label}
                        </button>
                    ))}
                </div>
            )}
        </div>
    );
}

export default function List(props) {
    const list = useInfinite(props.result, props.uniqKey, { onItem: (data, refetch) => <ItemList {...props} data={data} refetch={refetch} /> });
    return (
        <div className={`flex flex-col w-full h-full ${props.className ?? ''}`}>
            <div className="relative block w-full overflow-auto pr-8">{list}</div>
        </div>
    );
}
