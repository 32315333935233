//CONFIG
import config from 'utils/constants';

//LIBS
import { Link } from 'react-router-dom';

//COMPONENTS
import Image from 'components/common/Image';
import PingConnected from 'components/PingConnected';
import PointGamification from 'containers/profils/PointGamification';

const sizes = {
    avatar: '?w=500&h=500&c=true&q=80',
};

function Avatar(props) {
    return (
        <>
            {props.user?.profile?.avatar?.url ? (
                <div className={`relative h-auto p-0 m-0 ${props.className}`}>
                    <Link
                        className="relative flex"
                        onClick={props.onClick ?? null}
                        to={!props.onClick ? '/profil/' + props.user?.profile?.slug : false}
                        title={`${props.user?.profile?.pseudo}`}
                    >
                        <Image
                            src={props.user?.profile?.avatar?.url + sizes.avatar}
                            alt={props.user?.profile?.pseudo}
                            loading="false"
                            dynamic
                            className={`h-auto ${props.roundImg !== false && 'rounded-full'} ${props.className}`}
                        />
                        {props.gamification !== false &&
                            (!props.totalGamification || props.totalGamification === undefined ? (
                                <PointGamification gamification={props.user?.gamification?.profile} />
                            ) : (
                                <PointGamification gamification={props.totalGamification} />
                            ))}
                        {props.ping !== false && <PingConnected connexions={props.user?.connexions} type="global" />}
                    </Link>
                </div>
            ) : (
                <div className={`relative h-auto p-0 m-0 ${props.className}`}>
                    {props.user?.profile?.slug ? (
                        <Link className="relative flex" onClick={props.onClick ?? null} to={!props.onClick ? '/profil/' + props.user?.profile?.slug : false}>
                            <img
                                className={`h-auto ${props.roundImg !== false && 'rounded-full'} ${props.className}`}
                                src={`${config.publicDir}skillsLogo.jpg` + sizes.avatar}
                                alt={props.user?.profile?.pseudo}
                            />
                            {props.gamification !== false &&
                                (!props.totalGamification || props.totalGamification === undefined ? (
                                    <PointGamification gamification={props.user?.gamification?.profile} />
                                ) : (
                                    <PointGamification gamification={props.totalGamification} />
                                ))}
                            {props.ping !== false && <PingConnected connexions={props.user?.connexions} type="global" />}
                        </Link>
                    ) : (
                        <img
                            className={`h-auto ${props.roundImg !== false && 'rounded-full'} ${props.className}`}
                            onClick={props.onClick ?? null}
                            src={`${config.publicDir}skillsLogo.jpg` + sizes.avatar}
                            alt={props.user?.profile?.pseudo}
                        />
                    )}
                </div>
            )}
        </>
    );
}

export default Avatar;
