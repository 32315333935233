import { useState, useRef } from 'react';

//LIBS

//COMPONENTS
import Modal from 'components/common/Modal';

import FormConnexion from 'components/forms/ConnexionCloneAi';
import { useCallback } from 'react';

export default function WelcomeCloneAi() {
    const [isConnexionOpen, setIsConnexionOpen] = useState(true);
    const [isLogged, setIsLogged] = useState(false);
    const [modalConfirmLabel, setModalConfirmLabel] = useState('Valider');
    //REFS
    const loginRef = useRef(null);

    const handleLogin = useCallback(() => {
        loginRef.current.login();
    }, []);

    function onLogged(status) {
        setIsLogged(status);
    }

    return (
        <>
            <Modal
                open={isConnexionOpen}
                typeModal="ai"
                aiBg={true}
                noBorder={true}
                onConfirm={
                    !isLogged
                        ? {
                              label: modalConfirmLabel,
                              class: 'font-bold',
                              onClick: handleLogin,
                          }
                        : false
                }
            >
                <FormConnexion ref={loginRef} onLogged={() => setIsConnexionOpen(false)} isLogged={(status) => onLogged(status)} modalConfirmLabel={(label) => setModalConfirmLabel(label)}/>
            </Modal>
        </>
    );
}
