import * as yup from 'yup';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const schema = yup.object().shape({
    firstname: yup.string().required('Le prénom est requis'),
    lastname: yup.string().required('Le nom est requis'),
    email: yup.string().email("L'adresse email n'est pas au bon format").required("L'adresse email est requise"),
    avatar: yup.array().when('avatarUrl', {
        is: false,
        then: yup.array().required('La photo de profil est requise'),
    }),
    //tags: yup.array().defined().min(3, 'Vous devez choisir au moins trois tags'),
    address: yup.string().required("L'adresse postale est requise"),
    status: yup.string().required('Le job est requis'),
    objectifs: yup.string().min(10, "Vous devez saisir un objectif d'au moins 10 caractères").required("L'objectif est requis"),
    presentation: yup.string().required('La présentation est requise'),
    phone: yup
        .string()
        .matches(phoneRegExp, {
            message: "Le numéro de téléphone n'est pas valide",
            excludeEmptyString: true,
        })
        .nullable(),
    linkWebsite: yup.string().url("L'adresse URL n'est pas valide"),
    linkYoutube: yup.string().url("L'adresse URL n'est pas valide"),
    linkTiktok: yup.string().url("L'adresse URL n'est pas valide"),
    linkSnapchat: yup.string().url("L'adresse URL n'est pas valide"),
    linkInstagram: yup.string().url("L'adresse URL n'est pas valide"),
    linkLinkedin: yup.string().url("L'adresse URL n'est pas valide"),
    linkTwitter: yup.string().url("L'adresse URL n'est pas valide"),
});

export default schema;
