import * as yup from 'yup';

const schema = yup.object().shape({
    email: yup.string().email("L'adresse email n'est pas au bon format").required("L'adresse email est requise"),
    password: yup.string().min(6, 'Le mot de passe doit avoir plus de 6 caractères'),
});
export default schema;

const schemaUpdate = yup.object().shape({
    email: yup.string().email("L'adresse email n'est pas au bon format").required("L'adresse email est requise"),
    password: yup.string().when({
        is: (password) => password.length > 0,
        then: yup.string().min(6, 'Le mot de passe doit avoir plus de 6 caractères'),
    }),
});

export { schemaUpdate };
