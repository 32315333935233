import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import { AiOutlineCloseSquare } from 'react-icons/ai';
import { ImSpinner8 } from 'react-icons/im';

//COMPONENTS
import HeadlessButton from './content/HeadlessButton';

export default function HeadlessModal(props) {
    const [open, setOpen] = useState(false);

    function handleClose() {
        props?.onClose?.action();
        setOpen(false);
    }

    function handleConfirm() {
        props?.onConfirm?.action();
    }

    useEffect(() => {
        setOpen(props.open);
        return () => setOpen(false);
    }, [props.open]);

    if (open === false) return null;

    return createPortal(
        <div className="absolute flex top-0 left-0 bg-[#00000044] rounded-sm justify-center items-center w-full h-full backdrop-filter backdrop-blur-sm">
            <div className="relative rounded-sm bg-[#FFFFFF] shadow w-1/2 h-auto p-8">
                <button onClick={handleClose} className="absolute -top-8 right-0 p-0 text-[#F7F7F7]">
                    <AiOutlineCloseSquare className="w-6 h-6" />
                </button>
                <div className="flex flex-col justify-between gap-6 w-full h-full">
                    <div className="flex flex-col justify-start">
                        {props?.title && <div className="font-semibold text-lg text-[#745C89] mb-4 uppercase">{props.title}</div>}
                        <div>{props.children}</div>
                    </div>
                    <div className="flex flex-row justify-end gap-2">
                        <HeadlessButton label={`${props.onClose?.label ?? 'Annuler'}`} onClick={handleClose} />
                        <HeadlessButton
                            icon={<ImSpinner8 className="animate-spin w-4 h-4" />}
                            activeIcon={props.loading}
                            disabled={props.loading}
                            label={`${props.onConfirm?.label ?? 'Valider'}`}
                            theme="important"
                            onClick={handleConfirm}
                        />
                    </div>
                </div>
            </div>
        </div>,
        document.getElementById('headless')
    );
}
