/* eslint-disable no-unused-vars */
import React, { useContext, useRef, useEffect, useCallback, useState } from 'react';

//LIBS
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import serviceOptions from 'components/forms/options/service';

//API
import { putService, putServicePublish } from 'api/services';

//CONTEXTS
// import { AuthContext } from "context/authContext";
import { FormContext } from 'context/formContext';

//CONFIG
import config from 'utils/constants';

//LIBS
import { toast } from 'react-toastify';

//HOOKS
import useCurrentForm from 'hooks/useForm';

//FORMS
import schema from 'components/forms/yup/service';

//STYLES
import 'components/forms/forms.css';

//COMPONENTS
import FieldInput from 'components/forms/fields/input';
import Textarea from 'components/forms/fields/Textarea';
import Tags from 'components/forms/fields/Tags';
import FieldFiles from 'components/forms/fields/Files';
import { CalendarIcon, PhotographIcon, PlayIcon, PlusIcon, XCircleIcon } from '@heroicons/react/solid';
import Modal from 'components/common/Modal';
import useSafeState from 'hooks/useSafeState';
import { Calendar } from '@natscale/react-calendar';
import TimeKeeper from 'react-timekeeper';
import Header from 'components/common/Header';

function FormService(props) {
    const [itemId, setItemId] = useState(null);
    const [readyToWatch, setReadyToWatch] = useState(false);
    const [descriptionInitialValue, setDescriptionInitialValue] = useState('');
    /*   const [objectifsInitialValue, setObjectifsInitialValue] = useState('');
    const [programmeInitialValue, setProgrammeInitialValue] = useState(''); */
    const [tagsInitialValue, setTagsInitialValue] = useState([]);
    const [coverUrl, setCoverUrl] = useState(null);
    const [date, setDate] = useState(new Date());
    const [time, setTime] = useState('9:00');
    const [modalCalendar, setModalCalendar] = useSafeState(false);
    //CONTEXTS
    // const [authState, , refresh] = useContext(AuthContext);
    const [formContextState, formContextDispatch] = useContext(FormContext);

    //REFS
    const seeLink = useRef(null);
    const currentForm = useRef(null);

    //HOOKS
    const {
        control,
        register,
        handleSubmit,
        watch,
        formState: { errors },
        setValue,
        getValues,
        clearErrors,
    } = useForm({
        resolver: yupResolver(schema),
    });

    const {
        fields: fieldsData,
        append,
        remove,
    } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: 'sessions', // unique name for your Field Array
    });

    //DISPATCH
    const dispatchAction = useCallback(() => {
        switch (formContextState.action) {
            case 'save':
                currentForm.current.click();
                break;
            case 'tooglePublish':
                togglePublish(itemId);
                break;
            default:
        }
        // eslint-disable-next-line
    }, [formContextState.action]);

    //EFFECTS
    useEffect(() => {
        if (readyToWatch) {
            clearErrors();
            // formContextDispatch({ type: "change", status: false });
        }
        //eslint-disable-next-line
    }, [readyToWatch]);

    useEffect(() => {
        dispatchAction();
    }, [dispatchAction]);

    useEffect(() => {
        if (props.service) {
            const service = props.service;
            // clearErrors();
            setItemId(service.id);

            seeLink.current = '/service/' + service?.slug;
            formContextDispatch({
                type: 'publish',
                status: service?.publish,
                seeLink: seeLink.current,
            });

            if (service?.cover?.url) {
                setCoverUrl(service?.cover?.url ? config.serverUrl + service?.cover?.url : '');
            }

            setValue('title', service?.title);
            setValue('type', service?.type);
            setValue('description', service?.description);
            setDescriptionInitialValue(service?.description);

            if (service?.video) {
                setValue('video', service?.video);
            }

            setValue('objectifs', service?.objectifs);
            setValue('programme', service?.programme);

            setTagsInitialValue(service?.tags);
            /*
            service.sessions.forEach((session) => {
                append(session);
            }); */
            /* update(...[service?.sessions]); */
            setValue('sessions', ...[service?.publish]);
            setValue('sessions', ...[service?.sessions]);
            setValue('time', service?.time || 0);
            setValue('timeType', service?.timeType);
            setValue('price', service?.price || 0);
            setValue('place', service?.place);
            setValue('visio', service?.visio);
            setValue('prerequisite', service?.prerequisite);
            setValue('public', service?.public);
            setValue('finality', service?.finality);
            setValue('funding', service?.funding);

            setTimeout(() => {
                setReadyToWatch(true);
            }, 0);
        } else props.refetch();
        //eslint-disable-next-line
    }, [props.service]);

    useCurrentForm(readyToWatch, watch);

    //FUNCTIONS
    const onSubmit = (data) => {
        if (errors.length > 0) return false;

        const fd = new FormData();
        for (var k in data) {
            if (data[k] !== undefined) {
                let item = null;
                item = data[k];
                /*
                if (k === 'tags') {
                    item = JSON.stringify(data[k]);
                }
                */
                if (k === 'sessions') {
                    item = JSON.stringify(data[k]);
                }
                if (k === 'cover') {
                    let files = data[k];
                    for (let i = 0; i < files.length; i++) {
                        fd.append(k, files[i]);
                    }
                }
                if (k === 'medias') {
                    let files = data[k];
                    let existingMediasArray = []; // Pour stocker les médias existants

                    for (let i = 0; i < files.length; i++) {
                        if (files[i] instanceof File) {
                            fd.append(k, files[i]);
                        } else {
                            existingMediasArray.push(files[i]); // Ajoutez à l'array au lieu de JSON.stringify ici
                        }
                    }
                    // Convertir le tableau de médias existants en une chaîne JSON et l'ajouter à FormData
                    if (existingMediasArray.length > 0) {
                        fd.append('existingMedias', JSON.stringify(existingMediasArray));
                    }
                } else fd.append(k, item);
            }
        }
        const put = putService(itemId, fd, function (e) {
            let progress = (e.loaded / e.total) * 100;
            formContextDispatch({ type: 'progress', status: progress });
        });
        put.then((res) => {
            if (res.status === 200) {
                toast('Service mis à jour', { type: 'success' });
                // refresh();
                if (!props.service.updated) {
                    togglePublish(itemId);
                }
                setTimeout(() => {
                    formContextDispatch({ type: 'change', status: false });
                    setTimeout(() => {
                        formContextDispatch({ type: 'progress', status: 0 });
                    }, 400);
                }, 100);
                props.refetch();
            } else {
                toast('Une erreur est survenue', { type: 'danger' });
            }
        });
    };

    const togglePublish = useCallback(
        (itemId) => {
            if (errors.length > 0) return false;

            const put = putServicePublish(itemId);
            put.then((res) => {
                const msg = res.publish ? 'Service publié' : 'Service dépublié';

                toast(msg, { type: 'success' });

                formContextDispatch({
                    type: 'publish',
                    status: res.publish,
                    seeLink: seeLink.current,
                });
                props.refetch();
            });
        },
        //eslint-disable-next-line
        [formContextDispatch]
    );

    //OPTIONS
    function dispatchOptionsActions(action, name, value) {
        switch (action) {
            case 'onValidateOption':
                return onValidateOption(name, value);
            case 'onChangeOption':
                return onChangeOption(name, value);
            default:
        }
    }
    //FUNCTIONS OPTIONS
    function onChangeOption(name, value) {
        if (name === 'videoUrl') setValue('video', value);
    }
    function onValidateOption(name, value) {
        return true;
    }

    const onChange = useCallback(
        (val) => {
            setDate(val);
        },
        [setDate]
    );

    useEffect(() => {
        // eslint-disable-next-line no-use-before-define
        const date = new Date(date);
        date.setHours(time.split(':')[0]);
        date.setMinutes(time.split(':')[1]);
        props?.onChange?.(date);
        //eslint-disable-next-line
    }, [date, time]);

    function handleCalendar() {
        setModalCalendar(true);
    }

    const buttonUpload = (
        <div className="flex gap-2 text-graySkills my-2">
            <PhotographIcon className="w-6 h-6" />
            <PlayIcon className="w-6 h-6" />
        </div>
    );

    return (
        <>
            <Modal
                open={modalCalendar}
                title="Ajouter une date"
                onClose={{
                    onClick: () => setModalCalendar(false),
                }}
                onConfirm={{
                    label: 'Valider',

                    onClick: () => {
                        setModalCalendar(false);
                        append({ date: date.toLocaleDateString('fr-FR'), time: time });
                    },
                }}
            >
                <div className="flex flex-col items-center sm:flex-row">
                    <div className="sm:w-6/12">
                        <Calendar startOfWeek={1} disablePast size="250" value={date} onChange={onChange} />
                    </div>
                    <div>
                        <TimeKeeper
                            time={time}
                            hour24Mode
                            switchToMinuteOnHourSelect
                            switchToHourOnMinuteSelect
                            coarseMinutes={15}
                            forceCoarseMinutes
                            onChange={(newTime) => setTime(newTime.formatted24)}
                        />
                    </div>
                </div>
                <div className="mt-3 text-blue-800 text-center">
                    Créneau séléctionné :{' '}
                    <span className="text-blueMain">
                        {date.toLocaleDateString('fr-FR')} à {time}
                    </span>
                </div>
                {/* <Reservation ref={refReservation} user={user} onClose={() => setModalCalendar(false)} /> */}
            </Modal>

            <h1 className="font-bold text-3xl text-purpleSkills py-4">{props.service?.title}</h1>

            <form onSubmit={handleSubmit(onSubmit)} className="form mb-28 sm:mb-20" autoComplete="off" encType="multipart/form-data">
                {props.service?.cover?.url && (
                    <div className="w-full mb-4">
                        <FieldFiles
                            name="cover"
                            type="single"
                            label="Votre image de couverture"
                            multiple={false}
                            register={register}
                            initValue={coverUrl}
                            readyToWatch={readyToWatch}
                            onChange={(value) => {
                                setValue('cover', value);
                            }}
                        />
                    </div>
                )}
                <div className="flex w-full gap-3">
                    <FieldInput inputForm="flat" name="title" placeholder="Titre du service*" label={false} errors={errors} register={register} />
                    <FieldInput inputForm="flat" name="type" placeholder="Nature / Type de service" label={false} errors={errors} register={register} />
                </div>

                {!props.service?.cover?.url && (
                    <div className="w-full mb-2">
                        <label className="text-sm text-graySkills">Importez des vidéos et des photos pour illustrer votre service</label>
                        <FieldFiles
                            name="medias"
                            label="Déposez ou cliquez ici pour uploader une image ou vidéo"
                            type="multiple"
                            multiple={true}
                            register={register}
                            oldFiles={props.service?.medias}
                            initValue={[]}
                            readyToWatch={true}
                            onChange={(newFiles) => {
                                setValue('medias', newFiles);
                            }}
                            button={buttonUpload}
                            accepts={['image/png', 'image/jpeg', 'image/jpg', 'image/gif', 'video/mp4', 'video/quicktime']}
                        />
                    </div>
                )}
                <Textarea
                    name="description"
                    errors={errors}
                    register={register}
                    initValue={descriptionInitialValue}
                    onChange={(value) => setValue('description', value)}
                />
                {props.service?.video && (
                    <FieldInput
                        inputForm="flat"
                        name="video"
                        placeholder="Vidéo de présentation"
                        label={false}
                        errors={errors}
                        register={register}
                        option={serviceOptions.video}
                        dispatchOptionsActions={dispatchOptionsActions}
                        getValues={getValues}
                        readyToWatch={readyToWatch}
                    />
                )}
                {/*  <Textarea name="objectifs" errors={errors} register={register} initValue={objectifsInitialValue} onChange={(value) => setValue("objectifs", value)} />
                    <Textarea name="programme" errors={errors} register={register} initValue={programmeInitialValue} onChange={(value) => setValue("programme", value)} />
                <div className="w-full pb-8">
                    <h3 className="font-medium text-sm mt-4 text-graySkills">Tags du service*</h3>
                    <Tags
                        className="input_flat py-2 input_tags"
                        name="tags"
                        errors={errors}
                        register={register}
                        initValue={tagsInitialValue}
                        placeholder="Tapez sur Entrée pour ajouter un tag"
                        onChange={(value) => {
                            setValue('tags', value);
                        }}
                        readyToWatch={readyToWatch}
                    />
                </div>
                */}

                <div className="flex w-full gap-2">
                    <FieldInput
                        inputForm="flat"
                        name="time"
                        placeholder="Durée du service"
                        label={false}
                        type="number"
                        min="0"
                        defaultValue={0}
                        size="w-1/2"
                        errors={errors}
                        register={register}
                    />
                    <FieldInput
                        inputForm="flat"
                        name="timeType"
                        placeholder="Type de durée (jours, heures, ...)"
                        label={false}
                        size="w-1/2"
                        errors={errors}
                        register={register}
                    />
                </div>
                <div className="flex flex-col w-full gap-2 mt-4 ">
                    <label className="text-sm text-graySkills">Prix du service (€)</label>
                    <FieldInput
                        inputForm="flat"
                        name="price"
                        placeholder="Prix du service (€)"
                        label={false}
                        type="number"
                        size="w-1/2"
                        errors={errors}
                        register={register}
                    />
                </div>

                <div className="pt-4 flex flex-col w-full">
                    <div className="pb-4">
                        <button className="flex linkCard gap-2" type="button" onClick={handleCalendar}>
                            <PlusIcon className="w-4 h-4" />
                            <span> Ajouter une date</span>
                        </button>
                    </div>
                    <div>
                        <ul>
                            {fieldsData.map((session, index) => (
                                <li className="py-2 flex items-center gap-2" key={session.id}>
                                    <div className="relative z-0 w-full sm:w-1/2 mb-6 group">
                                        <div className="flex flex-row items-center border-b border-graySkills text-purpleSkills">
                                            <input
                                                className="input_floating border-none"
                                                disabled
                                                placeholder=""
                                                type="text"
                                                name="date"
                                                {...register(`sessions.${index}.date`)}
                                            />
                                            <span>à</span>
                                            <input
                                                className="input_floating border-none"
                                                disabled
                                                placeholder=""
                                                type="text"
                                                name="time"
                                                {...register(`sessions.${index}.time`)}
                                            />

                                            <CalendarIcon className="w-10 h-10" />
                                        </div>
                                    </div>
                                    {/* <input {...register(`sessions.${index}.date à`)} /> */}
                                    {/*  <Controller
              render={({ field }) => <input {...field} />}
              name={`test.${index}.lastName`}
              control={control}
            /> */}
                                    <div>
                                        <button type="button" onClick={() => remove(index)}>
                                            <XCircleIcon className="w-6 h-6 text-graySkills" />
                                        </button>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>

                <div className="flex w-full gap-2">
                    <FieldInput
                        inputForm="flat"
                        name="visio"
                        placeholder="Lien de la visio-conférence (URL)"
                        label={false}
                        size="w-1/2"
                        errors={errors}
                        register={register}
                    />
                    <FieldInput
                        inputForm="flat"
                        name="place"
                        placeholder="Lieu de la prestation"
                        label={false}
                        size="w-1/2"
                        errors={errors}
                        register={register}
                    />
                </div>

                {/*  <div className="flex w-full gap-2">
            <FieldInput name="prerequisite" label="Prérequis" size="w-1/2" errors={errors} register={register} />
            <FieldInput name="public" label="Public concerné" size="w-1/2" errors={errors} register={register} />
        </div>
        <FieldInput name="finality" label="Finalité du service (diplôme, certification, qualif, attestation, autre)" errors={errors} register={register} />
        <FieldInput name="funding" label="Financements et éligibilités" errors={errors} register={register} />
 */}
                <input ref={currentForm} type="submit" className="hidden" />
            </form>
        </>
    );
}

export default FormService;
