// import Image from "components/common/Image";
import { Link } from "react-router-dom";

// const sizes = {
//     avatar: "?w=130&h=130&c=true&q=80",
//     cover: "?w=80&h=80&c=true&q=80",
// };

function SimilarSection(props) {
    return (
        <>
            {props.services.isSuccess && props.services.data.length > 0 && (
                <section className="section_categories w-auto">
                    <h3 className="text-secondary uppercase my-1">Services similaires</h3>
                    <ul>
                        {props.services.data.map((service, index) => (
                            <li key={index} className="my-2">
                                <figure className="flex">
                                    <div className="flex flex-wrap w-full">
                                        <Link to={`/service/${service.slug}`} className="text-xs text-blue-600" title={service.title}>
                                            <h2 className="block w-full my-0 mx-1 text-sm font-semibold text-blue-800">{service.title}</h2>
                                        </Link>
                                        <figcaption className="block w-full mx-1 text-sm text-gray-500">{service.type}</figcaption>
                                        <span className="block w-full text-left mx-1 mt-1 mb-2 text-primary-500">
                                            <Link to={`/service/${service.slug}`} className="text-xs text-blue-600" title={service.title}>
                                                Voir la fiche
                                            </Link>
                                        </span>
                                    </div>
                                </figure>
                            </li>
                        ))}
                    </ul>
                </section>
            )}
        </>
    );
}

export default SimilarSection;
