const isLoggable = process.env.NODE_ENV === 'development' ? true : false;

var Logger = function () {
    let debug = {};

    if (!window.console) return function () {};

    if (isLoggable) {
        for (var m in console) if (typeof console[m] !== 'object') debug[m] = console[m].bind(window.console);
    } else {
        for (var m2 in console) if (typeof console[m2] === 'function') debug[m2] = function () {};
    }
    return debug;
};

export default Logger;
