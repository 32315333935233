import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
//CONTEXTS
import { AuthContext } from 'context/authContext';
//FORMS
import schema from 'components/forms/yup/report';
//HOOKS
import { useContext, useEffect, useState } from 'react';
import useCurrentForm from 'hooks/useForm';
import TreeView from 'components/treeView/TreeView';
import { postReport } from 'api/reports';
import { toast } from 'react-toastify';
import { answerReportData, roomReportData, userReportData, shootReportData, serviceReportData } from './reportData';

function UserReport(props) {
    const [readyToWatch, setReadyToWatch] = useState(false);
    const [sendReport, setSendReport] = useState(false);
    //CONTEXTS
    const [authState, , refresh] = useContext(AuthContext);

    const {
        /*     register, */
        handleSubmit,
        watch,
        formState: { errors },
        setValue,
        /*      getValues, */
        clearErrors,
    } = useForm({
        resolver: yupResolver(schema),
    });

    //EFFECTS
    useEffect(() => {
        if (readyToWatch) {
            clearErrors();
            // formContextDispatch({ type: "change", status: false });
        }
        //eslint-disable-next-line
    }, [readyToWatch]);

    useEffect(() => {
        const me = authState.me;

        if (me) {
            setValue('typeReport', props.type === '_room' ? 'room' : props.type);
            if (props.type === 'user') setValue('userReport', props.user?.id);
            if (props.type === 'room' || props.type === '_room') setValue('roomReport', props.room?.id);
            if (props.type === 'answer' || props.type === 'answerTo') setValue('answerReport', props.answer?._id);
            if (props.type === 'shoot') setValue('shootReport', props.shoot?._id);
            if (props.type === 'service') setValue('serviceReport', props.service?.id);
            // setValue('pseudo', me.profile?.pseudo);

            const timeoutId = setTimeout(() => {
                setReadyToWatch(true);
            }, 1000);

            return () => {
                clearTimeout(timeoutId);
            };
        }
        //eslint-disable-next-line
    }, [authState]);

    useCurrentForm(readyToWatch, watch);

    const onSubmit = (data) => {
        if (errors.length > 0) return false;
        const post = postReport(data);

        post.then((res) => {
            if (res.status === 200) {
                toast('Signalement envoyé', { type: 'success' });
                setSendReport(true);
                refresh();
            } else {
                toast('Une erreur est survenue', { type: 'danger' });
            }
        });
    };

    function onClickMotif(mainMotif, subMotif, sendReport) {
        mainMotif !== null && setValue('mainMotif', mainMotif);
        subMotif !== null && setValue('subMotif', subMotif);

        if (sendReport === true) {
            handleSubmit(onSubmit)();
        }
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
                <div className="-mx-8 ">
                    {props.type === 'user' && (
                        <TreeView
                            type={'report'}
                            typeReport={'user'}
                            key={userReportData.id}
                            treeData={userReportData}
                            errors={errors}
                            sendReport={sendReport}
                            onClickMotif={onClickMotif}
                        />
                    )}
                    {(props.type === 'room' || props.type === '_room') && (
                        <TreeView
                            type={'report'}
                            typeReport={'room'}
                            key={roomReportData.id}
                            treeData={roomReportData}
                            errors={errors}
                            sendReport={sendReport}
                            onClickMotif={onClickMotif}
                        />
                    )}
                    {(props.type === 'answer' || props.type === 'answerTo') && (
                        <TreeView
                            type={'report'}
                            typeReport={'answer'}
                            key={answerReportData.id}
                            treeData={answerReportData}
                            errors={errors}
                            sendReport={sendReport}
                            onClickMotif={onClickMotif}
                        />
                    )}
                    {props.type === 'shoot' && (
                        <TreeView
                            type={'report'}
                            typeReport={'shoot'}
                            key={shootReportData.id}
                            treeData={shootReportData}
                            errors={errors}
                            sendReport={sendReport}
                            onClickMotif={onClickMotif}
                        />
                    )}
                    {props.type === 'service' && (
                        <TreeView
                            type={'report'}
                            typeReport={'service'}
                            key={serviceReportData.id}
                            treeData={serviceReportData}
                            errors={errors}
                            sendReport={sendReport}
                            onClickMotif={onClickMotif}
                        />
                    )}
                </div>
            </form>
        </>
    );
}

export default UserReport;
