import { useImperativeHandle, forwardRef } from "react";
//LIBS
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
//API
import { postProduct } from "api/products";
//COMPONENTS
import FieldInput from "components/forms/fields/input";
//FORMS
import schema from "components/forms/yup/product";

function FormAddProduct(props, ref) {
    //HOOKS
    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    useImperativeHandle(ref, () => ({
        handleSubmit: (data) => {
            handleSubmit(onSubmit)();
        },
    }));

    //FUNCTIONS
    const onSubmit = (data) => {
        if (errors.length > 0) return false;

        const post = postProduct(data);
        post.then((res) => {
            if (res.status === 200) {
                toast("Produit créé", { type: "success" });
                // console.log(res.data);
                navigate("/dashboard/products/" + res.data.slug);
            } else {
                toast("Une erreur est survenue", { type: "warning" });
            }
        });
    };

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className="form"
            autoComplete="off"
            encType="multipart/form-data"
        >
            <div className="flex w-full gap-0">
                <FieldInput
                    name="title"
                    label="Nom du produit"
                    errors={errors}
                    register={register}
                />
            </div>
        </form>
    );
}

export default forwardRef(FormAddProduct);
