import { useEffect, useState } from 'react';
//UTILS
import { isMobile } from 'utils/functions';
import config from 'utils/constants';
//CONTAINERS
import SectionTitle from 'components/common/SectionTitle';
import CardShoot from 'containers/shoots/CardShoot';
import PlayerVideo from 'components/common/PlayerVideo';
import OptionsMenu from 'components/common/OptionsMenu';
import Button from 'components/common/Button';
import { DotsVerticalIcon } from '@heroicons/react/solid';
//import CardShootMobile from 'containers/shoots/CardShootMobile';
import _ from 'lodash';

function SearchShoots(props) {
    const [nb, setNb] = useState(3);
    const [items, setItems] = useState(props?.filter === false ? _.take(props.shoots, nb) : props.shoots);

    function handleOptionsShootMenu(shoot) {
        closeOptionsMenu(shoot);
        document.getElementById(`optionsMenu_${shoot._id}`).classList.toggle('hidden');
    }

    function closeOptionsMenu(shoot) {
        const els = document.getElementsByClassName('optionsMenu');
        [].forEach.call(els, function (el) {
            if (el.id !== `optionsMenu_${shoot._id}`) {
                el.classList.add('hidden');
            }
        });
    }

 /*    function handleClick(e, shoot) {
        e.stopPropagation();
        setOpen(true);
        currentIndex.current = favShoots.findIndex((favShoot) => favShoot._id === shoot._id);
        window.history.replaceState(null, null, `/shoots/${shoot.slug}`);
        setCurrentShoot(shoot);
        handleTooglePlay(shoot._id);
    } */

    function showMore() {
        // setNb(nb + 3);
        setNb(_.size(props.shoots));
    }
    function showLess() {
        setNb(3);
    }

    useEffect(() => {
        if (nb >= 3 && props?.filter === false)
            setItems(_.take(props.shoots, nb));
        else
            setItems(props.shoots);
        //eslint-disable-next-line
    }, [nb, props.shoots, props?.filter]);

    return (
        <>
            <SectionTitle>
                <p className="text-xs text-graySkills mx-0 my-4">
                    {/* {props.users.length > 20 && <span>Environs </span>} */}
                    <span className="h2 mx-0 my-2 mt-4 text-bold text-purpleSkills">Shoot :</span>
                </p>
            </SectionTitle>
            <div className="container">
                {/* {props.shoots.map((shoot, index) => (
                    <div key={index}>
                        {!isMobile() ? <CardShoot shoot={shoot} refetch={props.refetch} isSearch={true}/> : <CardShootMobile shoot={shoot} refetch={props.refetch} />}
                    </div>
                ))} */}
                {!isMobile() ?(
                    <>
                        {items.map((shoot, index) => (
                            <CardShoot key={index} shoot={shoot} refetch={props.refetch} isSearch={true}/>
                        ))}
                    </>
                ) : (
                    <div className='grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5'>
                        {items.map((shoot, index) => (
                            <div key={index} className='relative h-64 py-4 p-2 mb-4'>
                                <div className="h-64 w-auto rounded-lg shadow overflow-hidden">
                                    <PlayerVideo
                                        activeClick={true}
                                        id={shoot._id}
                                        width={'100%'}
                                        shoot={shoot}
                                        muted={false}
                                        controls={false}
                                        light={`${config.serverUrl}/downloads/videos/${shoot.user.id}/screenshot_${shoot.video._id}_${shoot.selectedScreen}.jpg`}
                                    />
                                    <div className="absolute top-5 right-2">
                                        <div className="flex flex-col gap-1 items-center">
                                            <Button
                                                onClick={() => {
                                                    handleOptionsShootMenu(shoot);
                                                }}
                                                label={<DotsVerticalIcon className="w-6 h-6" />}
                                                justIcon={true}
                                                css="text-white"
                                            />
                                            <OptionsMenu shoot={shoot} user={shoot.user} path="profil" type="shoot" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
            {props?.filter === false && nb < props.shoots?.length ? (
                <div className="container">
                    <div className="flex justify-center border-b border-gray-200 pb-4 mb-8 mt-4 text-primary font-semibold text-sm">
                        <button onClick={showMore}>...Voir plus de shoots</button>
                    </div>
                </div>
            ) : (
                props?.filter === false &&
                <>
                    {props.shoots?.length >= 3 && (
                        <div className="container">
                            <div className="flex justify-center border-b border-gray-200 pb-4 mb-8 mt-4 text-primary font-semibold text-sm">
                                <button onClick={showLess}>...Replier</button>
                            </div>
                        </div>
                    )}
                </>
            )}
        </>
    );
}

export default SearchShoots;