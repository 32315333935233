import * as yup from 'yup';

const schema = yup.object().shape({
    title: yup.string().required('Le titre est requis'),
    society: yup.string().required("Le nom de l'entreprise est requis"),
    yearFrom: yup.number("L'année est requise").min(1900, 'Le minimum est 1900').required("L'année est requise"),
    yearTo: yup.number("L'année est requise").max(new Date().getFullYear(), 'Maximum atteint').required("L'année est requise"),
});

export default schema;
