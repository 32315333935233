import { useCallback, useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
//COMPONENTS
import AddShoot from 'components/common/shoot/AddShoot';
import Modal from 'components/common/Modal';

//CONTAINERS
// import AddService from 'containers/services/addService';
import ForumSearchBarV2 from 'containers/searchBar/ForumSearchBarV2';

//CONTEXTS
import { AuthContext } from 'context/authContext';
import { FormContext } from 'context/formContext';

//ICONS
//import { RssIcon } from '@heroicons/react/outline';
import { PlusCircleIcon, SearchIcon, ShoppingBagIcon, VideoCameraIcon } from '@heroicons/react/solid';

function PublishButton(props) {
    const [openAddMenu, setOpenAddMenu] = useState(false);

    const location = useLocation();

    /*    (location.pathname.includes('search')) */

    //CONTEXTS
    const [, formContextDispatch] = useContext(FormContext);
    // eslint-disable-next-line no-unused-vars
    const [authState, , refresh] = useContext(AuthContext);

    const handleAddService = useCallback(() => {
        formContextDispatch({ type: 'add' });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formContextDispatch]);

    const handleAddNeed = useCallback(() => {
        formContextDispatch({ type: 'addNeed' });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formContextDispatch]);

    function handleOpenAddMenu(e) {
        e.preventDefault();
        setOpenAddMenu(true);
    }

    const handleOpenInscription = useCallback(() => {
        document.getElementById('button-login')?.click();
    }, []);

    return (
        <>
            <Modal open={openAddMenu} noBorder onClose={{ noButton: true, onClick: () => setOpenAddMenu(false) }}>
                <div className="relative pr-8 pl-8 text-center">
                    <h1 className="text-3xl font-bold mb-8">Publier</h1>
                    <div className="flex flex-col">
                        <ul className="text-right items-center text-white font-bold text-lg">
                            <li className="my-4 w-full">
                                <ForumSearchBarV2
                                    isButton={true}
                                    title={false}
                                    className="flex text-2xl font-bold linkCard gap-2 w-full px-6 py-2"
                                    typeForum="news"
                                    /*  icon="briefcase" */
                                    iconClassName="w-5 h-5"
                                    label="Un post"
                                />
                            </li>
                            <li className="my-4 w-full">
                                <ForumSearchBarV2
                                    isButton={true}
                                    title={false}
                                    className="flex text-2xl font-bold linkCard gap-2 w-full px-6 py-2"
                                    typeForum="question"
                                    /*   icon="question" */
                                    iconClassName="w-7 h-7"
                                    label="Une question"
                                />
                            </li>
                            <li className="my-4 w-full">
                                <ForumSearchBarV2
                                    isButton={true}
                                    title={false}
                                    className="flex text-2xl font-bold linkCard gap-2 w-full px-6 py-2"
                                    typeForum="job"
                                    /*   icon="briefcase" */
                                    iconClassName="w-5 h-5"
                                    label="Une offre d'emploi"
                                />
                            </li>
                            <li className="my-4 w-full">
                                <AddShoot>
                                    <button className="text-2xl font-bold linkCard gap-2 w-full px-6 py-2" type="button">
                                        <VideoCameraIcon className="w-5 h-5" />
                                        Un shoot
                                    </button>
                                </AddShoot>
                            </li>
                            <li className="my-4 w-full">
                                <button
                                    className="flex text-2xl font-bold linkCard gap-2 w-full px-6 py-2"
                                    type="button"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleAddService(e);
                                    }}
                                >
                                    <ShoppingBagIcon className="w-5 h-5" />
                                    <span>Un service / produit</span>
                                </button>
                            </li>
                            <li className="my-4 w-full">
                                <button
                                    className="flex text-2xl font-bold linkCard gap-2 w-full px-6 py-2"
                                    type="button"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleAddNeed(e);
                                    }}
                                >
                                    {/*  <ShoppingBagIcon className="w-5 h-5" /> */}
                                    <SearchIcon className="w-5 h-5" />
                                    <span>Un besoin urgent</span>
                                </button>
                            </li>
                            {/*  <li className="my-4 w-full">
                                <ForumSearchBarV2
                                    isButton={true}
                                    title={false}
                                    className="flex text-2xl font-bold linkCard gap-2 w-full px-6 py-2"
                                    typeForum="story"
                                    iconClassName="w-7 h-7"
                                    label="Ajouter à ma story"
                                    isClose={() => {
                                        setOpenAddMenu(false);
                                    }}
                                />
                            </li> */}
                        </ul>
                    </div>
                </div>
            </Modal>

            <div className={`${location.pathname.includes('/jobs') || location.pathname.includes('/service') ? 'w-full' : 'lg:w-4/5'}`}>
                <button
                    onClick={authState.isLogged ? handleOpenAddMenu : handleOpenInscription}
                    className={`w-full py-1 rounded-full ${
                        location.pathname.includes('/skillers') || location.pathname.includes('/jobs') || location.pathname.includes('/skillers') || location.pathname.includes('/service') || !authState.isLogged
                            ? 'bg-white text-blueMain hover:bg-blueMain hover:text-white'
                            : 'bg-blueMain text-white hover:bg-white hover:text-blueMain'
                    } transition-colors duration-400`}
                >
                    <div className="flex justify-center py-1">
                        <li className="flex items-center gap-2">
                            <PlusCircleIcon className="w-7 h-7" />
                            <span className="font-bold">Publier</span>
                        </li>
                    </div>
                </button>
            </div>
        </>
    );
}

export default PublishButton;
