import * as yup from 'yup';

export const simple = yup.object().shape({
    title: yup.string().required('Le titre est requis'),
});

const global = yup.object().shape({
    title: yup.string().required('Le titre est requis'),
    type: yup.string().required('Le type est requis'),
    // description: yup.string().required('Une description est requise'),
    price: yup.number().required('Un prix est requis').min(1, 'Votre service doit contenir un prix'),
    //tags: yup.array().min(1, "Vous devez renseigner au moins un tag")
});

export default global;
