// import PseudoOption from "components/forms/options/pseudo/PseudoOption";
// import AddressOption from "components/forms/options/address/AddressOption";
import VideoOption from "components/forms/options/video/VideoOption";

const service = {
    video: {
        element: {
            jsx: <VideoOption />,
            props: {
                dispatchEvent: "onChangeOption",
            },
        },
        actions: [
            {
                label: "Valider",
                onClick: "onValidateOption",
                closeOnTrue: true,
            },
        ],
    },
};

export default service;
