import { useEffect, useState, Fragment, cloneElement, useRef } from 'react';

import _ from 'lodash';

export default function HeadlessTable(props) {
    const [keys, setKeys] = useState([]);
    const grid = useRef('grid-cols-1');

    useEffect(() => {
        const current = props.keys ?? _.keys(props.items?.[0]);

        if (props.actions) {
            current.push('actions');
        }
        setKeys(current);
        //eslint-disable-next-line
    }, [props.items]);

    useEffect(() => {
        switch (keys?.length) {
            case 2:
                grid.current = 'grid-cols-2';
                break;
            case 3:
                grid.current = 'grid-cols-3';
                break;
            case 4:
                grid.current = 'grid-cols-4';
                break;
            case 5:
                grid.current = 'grid-cols-5';
                break;
            default:
        }
    }, [keys]);

    const tr = keys?.map((key, k) => {
        return (
            <div className="bg-[#F9F9F9] p-2" key={k}>
                <span className="uppercase">{key}</span>
            </div>
        );
    });

    const td = props?.items?.map((item, k) => {
        return (
            <Fragment key={k}>
                {keys?.map((key, k) => (
                    <Fragment key={k}>
                        <div className={`p-2 flex gap-1 justify-start ${props?.colors?.map((color) => (color.if(item) ? color.color : '')).join(' ')}`}>
                            {key !== 'actions' ? (
                                <span>{item[key]}</span>
                            ) : (
                                <>
                                    {props?.actions?.map((action, k) => (
                                        <Fragment key={k}>
                                            {(!action?.if || action?.if?.(item)) && (
                                                <span>
                                                    {cloneElement(action.jsx, {
                                                        onClick: () => action.onClick(item),
                                                        className: action?.classNameIf?.(item)
                                                            ? action.jsx.props?.className + ' cursor-pointer h-full'
                                                            : 'cursor-pointer h-full',
                                                    })}
                                                </span>
                                            )}
                                        </Fragment>
                                    ))}
                                </>
                            )}
                        </div>
                    </Fragment>
                ))}
            </Fragment>
        );
    });

    const legends = props.colors?.map((color, k) => {
        if (!color.legend) return null;
        return (
            <Fragment key={k}>
                <div className="flex flex-row gap-2">
                    <div className={`border-none ${color.color} w-6`}></div>
                    <div className="text-xs">{color.legend}</div>
                </div>
            </Fragment>
        );
    });

    return (
        <>
            {props?.items && (
                <>
                    <div className={`rounded-sm ${props.items?.length > 0 ? 'border' : ''} grid ${grid.current} gap-1 mb-4`}>
                        {props.items?.length > 0 && (
                            <>
                                {tr}
                                {td}
                            </>
                        )}
                    </div>

                    <div className="flex flex-row gap-2">{legends}</div>
                </>
            )}
        </>
    );
}
