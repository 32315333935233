function Sidebar(props) {
    const pos = props.position === "bottom" ? "70%" : "10%";
    const order = props.order ? props.order : "1";

    return (
        <aside className={`order-${order} md:sticky w-full md:w-3/12 h-full block`} style={{ top: pos }}>
            {props.children}
        </aside>
    );
}

export default Sidebar;
