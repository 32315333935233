import React, { useEffect, useState } from 'react';
//API
import { getProductById, terminatePlan } from 'api/stripe';
//LIBS
import Modal from 'components/common/Modal';
import { toast } from 'react-toastify';

function PlanCard(props) {
    const [item, setItem] = useState({});
    const [open, setOpen] = useState(false);

    useEffect(() => {
        getProductById(props.plan.metadata.itemId)
            .then((data) => {
                setItem(data);
            })
            .catch((err) => {
                console.log(err);
            });
        //eslint-disable-next-line
    }, []);

    function handleTerminatePlan() {
        terminatePlan(props.plan.id)
            .then((data) => {
                setOpen(false);
                toast('Votre abonnement a bien été résilié', { type: 'success' });
                props.refresh();
            })
            .catch((err) => {
                console.log(err);
                toast('Une erreur est survenue', { type: 'error' });
            });
    }

    return (
        <>
            <Modal
                open={open}
                setOpen={setOpen}
                title={'Résiliation du clone AI Infinite'}
                size="lg"
                className="bg-white"
                onClose={{
                    label: 'Non',
                    onClick: () => {
                        setOpen(false);
                    },
                }}
                onConfirm={{
                    label: 'Oui',
                    onClick: handleTerminatePlan,
                }}
            >
                <div className="flex flex-col gap-4">
                    <p className="text-center text-lg font-bold text-purpleSkills">Souhaitez-vous résilier votre abonnement ?</p>
                </div>
            </Modal>
            <div className="relative w-full my-2 pb-2 border-b rounded-xl border-gray-200">
                <div className="flex flex-wrap flex-row">
                    <div className="dlex flex-row w-3/4 gap-2">
                        <div className="flex gap-2 flex-row items-center relative">
                            <div className="flex-none relative w-24 h-24 mr-2">
                                <img className="rounded-full w-24 h-24" src={item.product?.images[0]} alt={''} />
                            </div>
                            <div className="w-full break-words grow flex flex-wrap flex-col">
                                <span className="text-lg  text-purpleSkills font-bold" style={{ maxWidth: '70%' }}>
                                    {item.product?.name}
                                </span>
                                <span className="text-sm text-gray-400">{item.product?.description}</span>
                            </div>
                        </div>
                        <p className="text-sm text-gray-400 mt-2">
                            Depuis le : &ensp;
                            <span className="text-purpleSkills font-bold">{new Date(props.plan.created * 1000).toLocaleDateString()}</span>
                        </p>
                        <p className="text-sm text-gray-400 mt-2">
                            {`${!props.plan.cancel_at_period_end ? 'Prochain paiement le :' : 'Votre abonnement se termine le :'}`}
                            &ensp;
                            <span className="text-purpleSkills font-bold">{new Date(props.plan.current_period_end * 1000).toLocaleDateString()}</span>
                        </p>
                    </div>
                    <div className="w-1/4 flex flex-wrap flex-col gap-4 pt-2">
                        <p className={`text-center text-lg font-bold ${!props.plan.cancel_at_period_end ? 'text-greenSkills' : 'text-red-500'}`}>
                            {!props.plan.cancel_at_period_end ? 'Abonnement actif' : 'Abonnement résilié'}
                        </p>
                        <div className="text-center">
                            <span className="text-lg text-purpleSkills font-bold">{item.price?.unit_amount / 100}€ par mois</span>
                        </div>
                        {!props.plan.cancel_at_period_end && (
                            <div className="flex flex-col gap-2">
                                <button
                                    className="w-auto justify-center flex p-2 px-4 bg-black text-white font-bold rounded-full mr-2 border-y-2 border-x-8 border-[#BB7EFF] hover:bg-[#b235fe]"
                                    onClick={() => {
                                        setOpen(true);
                                    }}
                                >
                                    <span>Résilier</span>
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default PlanCard;
