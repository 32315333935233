import { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
//API
import { createPayment, createSubscription, updateSubscription } from 'api/stripe';
//LIBS
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

//Stripe dev and prod keys
//const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_TEST_PK);
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_LIVE_PK);

const StripePayButton = forwardRef(({ amount, details, priceData, handleSucceedPayment, type}, ref) => {
    const [succeeded, setSucceeded] = useState(false);
    const [error, setError] = useState(null);
    const [clientSecret, setClientSecret] = useState('');

    const stripe = useStripe();
    const elements = useElements();
    
    useImperativeHandle(ref, () => ({
        submit: () => {
            handleSubmit();
        },
    }));

    useEffect(() => {
        const paymentItems = {
            items: [
                {
                    price: amount,
                },
            ],
            details,
        };
        if (type !== "chatAi") {
            const create = createPayment(paymentItems);
            create.then((data) => {
                setClientSecret(data.clientSecret);
                // handleSucceedPayment(); //to delete
            });
        }
        //eslint-disable-next-line
    }, []);

    const handleChange = async (event) => {
        setError(event.error ? event.error.message : '');
    };

    const handleSubmit = async (ev) => {
        if (priceData) {
            const paymentMethod = await stripe?.createPaymentMethod({
                type: "card",
                card: elements.getElement(CardElement),
            });

            const paymentItems = {
                items: [
                    {
                        price: amount,
                    },
                ],
                details,
                priceData,
                clientSecret,
                paymentMethod: paymentMethod?.paymentMethod?.id,
            };
            const create = createSubscription(paymentItems);
            create.then(async (data) => {
                //if subscription status is incomplete, we need to confirm the payment
                if (data?.status === "incomplete") {
                    try {
                        const payload = await stripe?.confirmCardPayment(data?.clientSecret, {
                            payment_method: {
                                card: elements.getElement(CardElement),
                            },
                        });
                        if (payload.error) {
                            switch (payload.error.decline_code) {
                                case "fraudulent":
                                    setError("Le paiement a été refusé car il a été considéré comme frauduleux par Stripe");
                                    break;
                                case "card_declined":
                                    setError("Le paiement a été refusé par votre banque");
                                    break;
                                default:
                                    setError(`${payload.error.message}`);
                                    break;
                            }
                        } else {
                            let dataToUp = {
                                payload: payload,
                                details: details,
                                subscriptionId: data?.subscriptionId,
                                status: payload?.paymentIntent?.status,
                                stripeCustomerId: data?.stripeCustomerId,
                            };
                            const update = await updateSubscription(data?.subscriptionId, dataToUp);
                            if (update.error) {
                                setError(`${update.error.message}`);
                            } else {
                                setError(null);
                                setSucceeded(true);
                                handleSucceedPayment(data);
                            }
                        }
                    } catch (err) {
                        if (err.response) {
                            setError(err.response.data.message);
                        } else {
                            setError("Une erreur est survenue");
                        }
                    }
                }
            }).catch((err) => {
                if (err.response) {
                    setError(err.response.data.message);
                } else {
                    setError("Une erreur est survenue");
                }
            });
        } else {
            const payload = await stripe.confirmCardPayment(clientSecret, {
                payment_method: {
                    card: elements.getElement(CardElement),
                },
            });
            if (payload.error) {
                setError(`${payload.error.message}`);
            } else {
                setError(null);
                setSucceeded(true);
                handleSucceedPayment(payload);
            }
        }
    };

    return (
        <div className="full-width">
            <div className="cart__sidebar__popin">
                {succeeded && (
                    <div className="text-center">
                        <p className="text-success-500 font-semibold">Merci ! votre paiement a bien été accepté</p>
                        <p className="text-sm text-blue-800">Vous allez recevoir un email de confirmation concernant votre achat.</p>
                    </div>
                )}

                {!succeeded && (
                    <>
                        {details?.description && (
                            <div className={`mb-4 w-full ${details?.image && "flex flex-row"}`}>
                                <div className="self-center w-3/4">
                                    <h2 className="uppercase mb-2 font-bold text-2xl">{details.title}</h2>
                                    <p className="text-gray-600 cart__sidebar__popin__resume w-3/4">
                                        {details?.description}
                                    </p>
                                </div>
                                {details?.image && (
                                    <div className="w-1/4">
                                        <img
                                            src={details?.image}
                                            alt={details?.description}
                                            className="w-full h-full rounded-full ml-2 self-center"
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                        <p className="text-gray-600 cart__sidebar__popin__resume">
                            Vous allez payer un montant de <span className="text-secondary font-bold">{amount}</span> €
                        </p>
                        <p className="text-sm text-success-500">Paiment sécurisé avec notre partenaire Stripe</p>
                        <div className="mt-4">
                            {error && (
                                <div className="text-sm text-danger-500" role="alert">
                                    <p className="danger">{error}</p>
                                </div>
                            )}
                        </div>
                        <form id="payment-form" onSubmit={handleSubmit} className="">
                            <CardElement onChange={handleChange} className="mb-4 border border-gray-200 p-3 rounded-lg shadow-sm" />
                            {/* <div className="flex justify-center">
                                    <Button
                                        label={`Valider le paiement`}
                                        css="button-secondary button-small"
                                        disabled={
                                            processing || disabled || succeeded
                                        }
                                        onClick={(e) => {
                                            handleSubmit(e);
                                        }}
                                    />
                                </div> */}
                        </form>
                    </>
                )}
            </div>
        </div>
    );
});

function SimpleCheckout(props) {
    return (
        <Elements stripe={stripePromise}>
            <StripePayButton ref={props.refCheckout} {...props} />
        </Elements>
    );
}
export default SimpleCheckout;
