import { useContext, Fragment, cloneElement } from 'react';

//UTILS
import Filters from '../utils/filters';

//CONTEXTS
import { HeadlessContext } from '../index.js';

//ICONS
import { AiOutlineLogout } from 'react-icons/ai';
import { HiOutlineCog } from 'react-icons/hi';

const logo = require('../headless.png');

const HeadlessMenuLink = (props) => {
    const store = useContext(HeadlessContext);

    function handleMenu(e, value) {
        e.preventDefault();

        store.set({ type: 'page', value });
    }

    return (
        <li className={`border-b ${store.get('page')?.includes(props.item?.key) ? 'border-b-[#745C89]' : 'border-b-gray-200'}`}>
            <a
                href="#headlessDashboard"
                className={`cursor-pointer w-full h-full pt-6 flex gap-1 items-center ${
                    store.get('page')?.includes(props.item?.key) ? 'text-[#745C89]' : 'text-gray-300'
                }`}
                onClick={(e) => handleMenu(e, props.item?.key)}
            >
                {cloneElement(props.item?.icon, { className: store.get('barOpened') ? 'w-5 h-5' : 'w-8 h-8' })}
                {store.get('barOpened') && <span>{props.item?.label}</span>}
            </a>
        </li>
    );
};

export default function HeadlessMenu() {
    const filters = new Filters();

    const store = useContext(HeadlessContext);

    function handleToggleBar() {
        store.set({ type: 'toggleBar', value: !store.get('barOpened') });
    }

    function handleConfig() {
        store.set({ type: 'page', value: 'hlConfig' });
    }

    function handleLogout() {
        store.set({ type: 'logout' });
    }

    return (
        <>
            <div className={`${store.get('barOpened') ? 'w-3/12' : 'w-[60px]'} border-r border-r-[#745C89] h-full flex flex-col justify-between pr-8`}>
                <div>
                    <div className="w-full text-center">
                        <img
                            src={logo}
                            onClick={handleToggleBar}
                            className={`inline-block cursor-pointer ${store.get('barOpened') ? 'w-20' : 'w-full'}`}
                            alt="headless"
                        />
                    </div>
                    <ul className="mt-8 w-full mr-2">
                        {store
                            .get('config')
                            ?.pages?.filter((item) => filters.pagesByRoles(item, store.get('me')?.role))
                            ?.map((item, k) => (
                                <Fragment key={k}>
                                    {item.page && <HeadlessMenuLink item={{ label: item.page.label, key: item.page.key, icon: item.page.icon }} />}
                                </Fragment>
                            ))}
                    </ul>
                </div>
                <div className={`flex ${store.get('barOpened') ? 'flex-row' : 'flex-col'} items-center gap-2`}>
                    <div onClick={handleConfig} className="text-[#745C89] flex gap-1 items-center cursor-pointer">
                        <HiOutlineCog className="w-5 h-5" />
                    </div>
                    <div onClick={handleLogout} className="text-[#745C89] flex gap-1 items-center cursor-pointer">
                        <AiOutlineLogout className="w-5 h-5" />
                    </div>
                </div>
            </div>
        </>
    );
}
