import { cloneElement } from 'react';

export default function HeadlessInput(props) {
    return (
        <div className="my-1 w-full">
            <label className="text-[#745C89] text-sm">{props.type !== 'checkbox' && props.label}&nbsp;</label>
            <div className="flex gap-1">
                {props?.type?.select && (
                    <select
                        {...props.register}
                        className="w-full border border-[#745C8988] rounded-sm p-2 focus:!border-[#745C89] text-sm"
                        defaultValue={props.value ?? ''}
                    >
                        {props.type.select.map((option, k) => (
                            <option key={k} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                )}
                {props?.type === 'checkbox' && (
                    <div className="flex items-center relative top-2">
                        <input
                            type="checkbox"
                            {...props.register}
                            className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">{props.label}</label>
                    </div>
                )}
                {(props?.type === undefined || props?.type === 'password' || props?.type === 'email') && (
                    <input
                        {...props.register}
                        disabled={props.disabled ?? false}
                        autoComplete={props.autoComplete ?? 'on'}
                        placeholder={props.placeholder}
                        defaultValue={props.value ?? ''}
                        type={props.type ?? 'text'}
                        className="w-full border border-[#745C8988] rounded-sm p-2 focus:!border-[#745C89] text-sm"
                    />
                )}
                {props.actions?.map((action, k) => (
                    <div key={k} className="w-10 cursor-pointer" onClick={action?.onClick}>
                        {cloneElement(action.jsx, { className: `w-full h-full p-1 text-[#745C89] ${action.jsx.props.className}` })}
                    </div>
                ))}
            </div>

            {props.errors && props.errors[props.name] && <span className="text-[#FF0000] text-xs">{props.errors[props.name].message}</span>}
        </div>
    );
}
