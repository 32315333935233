import { useCallback, useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
//API
import { getDiscussionsPagination } from 'api/discussions';
import { getNotifications } from 'api/discussions';
import { getNotificationsPagination } from 'api/notifications';
import { getCloneByMe } from 'api/clone';
//COMPONENTS
import Tabs from 'components/tabs/Tabs';

//CONTEXTS
import { SocketContext } from 'context/socketContext';
import { StoreContext } from 'context/storeContext';
import { AuthContext } from 'context/authContext';
//CONTAINERS
import CardDiscussion from 'containers/discussions/CardDiscussion';
import CardNotification from 'containers/notifications/CardNotification';

//HOOKS
import useInfinite from 'hooks/useInfinite';
import useNotifications from 'hooks/useNotifications';
import CardProfilClone from '../discussions/CardProfilClone';

function ListNotifications(props) {
    const socket = useContext(SocketContext);
    //contexts
    const store = useContext(StoreContext);

    const discussionNotifications = useNotifications(getNotifications);
    const [cloneId, setCloneId] = useState(null);

    //HOOKS
    const navigate = useNavigate();

    const [authState] = useContext(AuthContext);

    useEffect(() => {
        store.set({ type: 'add', key: 'discussionType', value: 'standard' });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const clone = useQuery(['clone'], getCloneByMe);

    useEffect(() => {
        if (clone.data) {
            // Si le clone est prêt et a un ID
            setCloneId(clone.data.id);
        } else {
            setCloneId(false);
        }
    }, [clone.data, authState.me]);

    function handleCloneOpenDiscussion(e, cloneId, type) {
        if (e) e.preventDefault();
        e?.stopPropagation();
        store.set({ type: 'add', key: 'discussionType', value: 'ai' });
        /*  store.set({ type: 'add', key: 'openChat', value: { open: true } }); */
        store.set({ type: 'add', key: 'openChat', value: { open: true, cloneId: cloneId, chatType: 'ai', type: type } });
    }

    //HOOKS
    const infiniteNotifications = useInfinite(getNotificationsPagination, 'listInfiniteNotifications', {
        onItem: (notification, refetch) => {
            return <CardNotification notification={notification} refetch={refetch} socket={socket} />;
        },
        onNoResult: () => {
            return (
                <div className="text-center mt-8">
                    <p className="text-graySkills">Pas de notification pour le moment</p>
                </div>
            );
        },
        msgToSee: 'Voir plus de notifications',
    });

    const infiniteMessages = useInfinite(getDiscussionsPagination, 'listInfiniteDiscussions', {
        onItem: (discussion, refetch) => {
            return <CardDiscussion discussion={discussion} refetch={refetch} socket={socket} />;
        },
        onNoResult: () => {
            return (
                <div className="text-center mt-8">
                    <p className="text-graySkills">Pas de message pour le moment</p>
                </div>
            );
        },
        msgToSee: 'Voir plus de notifications',
    });

    const handleGoToCloneAI = useCallback(() => {
        navigate('/dashboard/clone-ai');
    }, [navigate]);

    const tabNotifications = <div className="mt-4 w-full">{infiniteNotifications}</div>;
    const tabMessages = (
        <>
            <div className="mt-4 w-full">
                {infiniteMessages}
                <div className="sm:hidden w-[80%] inline-block sticky bottom-[4.5rem] z-10 cursor-pointer mt-4">
                    {/* {cloneId && ( */}
                    <div className="px-2">
                        <div className={`bg-black text-white px-1 py-1 flex items-center justify-between rounded-full ${cloneId ? 'ai-shadow' : ''}`}>
                            <CardProfilClone
                                widthAvatar={'w-8'}
                                sizeText={'text-[1.1rem]'}
                                discussionId={`${authState.me?.id}.${cloneId ? cloneId : 'noClone'}`}
                                user={authState.me}
                                clone={cloneId ? clone.data : false}
                                onClick={(e) => {
                                    e?.preventDefault();
                                    e?.stopPropagation();

                                    if (cloneId) {
                                        handleCloneOpenDiscussion(e, cloneId, 'cloneDiscussion');
                                    } else {
                                        handleGoToCloneAI();
                                    }
                                }}
                            />
                        </div>
                    </div>
                    {/* )} */}
                </div>
            </div>
        </>
    );

    const labelTabs = (label) => {
        return (
            <>
                <span>{label}</span>
                {label === 'Notifications' && store.get('notifications')?.length > 0 && (
                    <span className="flex justify-center items-center absolute top-1 right-4 sm:right-1/4 bg-red h-5 w-5 rounded-full text-white text-xs">
                        {store.get('notifications').length}
                    </span>
                )}
                {label === 'Messages' && discussionNotifications && (
                    <span className="flex justify-center items-center absolute top-1 right-4 sm:right-1/4 bg-red h-5 w-5 rounded-full text-white text-xs">
                        {discussionNotifications}
                    </span>
                )}
            </>
        );
    };

    const tabs = [
        { label: labelTabs('Messages'), key: 'tab_messages', content: tabMessages },
        { label: labelTabs('Notifications'), key: 'tab_notifications', content: tabNotifications },
    ];

    return (
        <>
            <Tabs tabs={tabs} />
        </>
    );
}

export default ListNotifications;
