import React, { useContext } from 'react';

//LIBS
import _ from 'lodash';

//CONTEXTS
import { HeadlessContext } from '../index.js';

export default function HeadlessModule(props) {
    const store = useContext(HeadlessContext);

    const modules = store.get('modules');
    const moduleKey = _.findKey(props.module);

    const currentJsx = modules?.[moduleKey]?.jsx ?? null;
    const currentProps = props.module?.[moduleKey];

    const currentRef = currentJsx?.ref;

    if (currentJsx) return <>{React.cloneElement(currentJsx, currentProps, currentRef)}</>;
    return null;
}
