//UTILS
import config from 'utils/constants';

import React, { useRef, forwardRef, useImperativeHandle, useEffect } from 'react';
import ReactPlayer from 'react-player';

function PlayerMedia(props, ref) {
    const videoRef = useRef(null);

    useImperativeHandle(ref, () => ({
        play: () => console.log('play'),
    }));

    const handleContextMenu = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    useEffect(() => {
        if (props?.currentIndex !== props?.index) {
            if (videoRef?.current) {
                videoRef?.current?.getInternalPlayer()?.pause();
            }
        }
    }, [props?.currentIndex, props?.index]);

    return (
        <>
            <ReactPlayer
                ref={videoRef}
                url={`${
                    props?.media.encoded
                        ? `${config.serverUrl + `/stream/${props?.user?._id}/${props?.media?._id}.mp4`}`
                        : `${config.serverUrl}${props?.media.url}`
                }`}
                className="react-player sm:rounded-3xl overflow-hidden w-full bg-black h-full"
                width={'100%'}
                height={'100%'}
                /* style={{ borderRadius: "0.75rem", overflow: "hidden" }} */
                playsinline={true}
                playing={props?.isPlaying}
                controls
                stopOnUnmount={true}
                onContextMenu={handleContextMenu}
            />
        </>
    );
}

export default forwardRef(PlayerMedia);
