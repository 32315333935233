import { useEffect, useState, Fragment } from 'react';
//LIBS
import { toast } from 'react-toastify';
//API
import { putCancel, putAccept } from 'api/transactions';
import { postNotification } from 'api/notifications';
import { getDiscussion } from 'api/discussions';

import { useForm } from 'react-hook-form';

import FieldInput from 'components/forms/fields/input';
//ICONS
import { CheckIcon, XCircleIcon, AnnotationIcon, ClockIcon } from '@heroicons/react/solid';
import Modal from 'components/common/Modal';

function CardVisio({ transaction, refetch }) {
    const [item, setItem] = useState([]);
    const [openConfirmCancel, setOpenConfirmCancel] = useState(false);
    const [openConfirmAccept, setOpenConfirmAccept] = useState(false);
    const [openMessageReservation, setOpenMessageReservation] = useState(false);
    const [humanStatus, setHumanStatus] = useState('');
    const [currentDate, setCurrentDate] = useState(null);
    const [currrentMessage, setCurrentMessage] = useState(null);
    const [currentIsFirstTransaction, setCurrentIsFirstTransaction] = useState(null);

    const {
        register,
        //handleSubmit,
        //watch,
        formState: { errors },
        //setValue,
        getValues,
    } = useForm();

    async function handleCancel() {
        putCancel(item.id).then(async (data) => {
            if (getValues('messageReservation')) {
                await getDiscussion(transaction.userFrom._id, getValues('messageReservation')).then((data) => {
                    console.log('data', data);
                });
            }
            refetch();
            toast('Visio annulée', { type: 'success' });
            setOpenConfirmCancel(false);
            postNotification({
                type: 'visioCanceled',
                user: item.userFrom._id,
            });
        });
    }

    function handleAccept() {
        putAccept(item.id).then((data) => {
            refetch();
            toast('Visio acceptée !', { type: 'success' });
            setOpenConfirmAccept(false);
            postNotification({
                type: 'visioAccepted',
                user: item.userFrom._id,
            });
        });
    }

    useEffect(() => {
        switch (transaction.status) {
            case 'init':
                setHumanStatus('En attente');
                break;
            case 'canceled':
                setHumanStatus('Annulée');
                break;
            case 'accepted':
                setHumanStatus('Acceptée');
                break;
            case 'validated':
                setHumanStatus('Validée');
                break;
            default:
        }
        setItem(transaction);

        const infos = JSON.parse(transaction.infos);
        let date = Date.parse(infos.date);
        date = new Date(date).toLocaleString([], {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
        });
        setCurrentDate(date);
        setCurrentMessage(infos?.messageReservation);
        setCurrentIsFirstTransaction(infos?.isFirstTransaction);
        //eslint-disable-next-line
    }, [, transaction]);

    return (
        <>
            <Modal
                title="Annuler la demande"
                open={openConfirmCancel}
                onClose={{
                    label: 'Fermer',
                    onClick: () => {
                        setOpenConfirmCancel(false);
                    },
                }}
                onConfirm={{
                    label: 'Confirmer',
                    onClick: handleCancel,
                }}
            >
                <div className='flex flex-col gap-4'>
                    <p>Voulez-vous annuler cette demande de Visio-consultation ?</p>
                    <FieldInput
                        label="Votre message (optionnel)"
                        name="messageReservation"
                        type="textarea"
                        inputForm="floating"
                        size="w-auto"
                        errors={errors}
                        register={register}
                    />
                </div>
            </Modal>
            <Modal
                title="Confirmer la demande"
                open={openConfirmAccept}
                onClose={{
                    onClick: () => {
                        setOpenConfirmAccept(false);
                    },
                }}
                onConfirm={{
                    label: 'Confirmer',
                    onClick: handleAccept,
                }}
            >
                <>
                    <p>Voulez-vous confirmer cette demande de Visio-consultation ?</p>
                </>

            </Modal>
            <Modal
                title={`Message de ${item?.userFrom?.profile?.pseudo}`}
                open={openMessageReservation}
                onClose={{
                    label: 'Fermer',
                    onClick: () => {
                        setOpenMessageReservation(false);
                    },
                }}
                onConfirm={{
                    label: 'Ok',
                    onClick: () => {
                        setOpenMessageReservation(false);
                    },
                }}
            >
                <p>
                    {currrentMessage?.split('\n').map((item, key) => {
                        return (
                            <Fragment key={key}>
                                {item}
                                <br />
                            </Fragment>
                        );
                    })}
                </p>
            </Modal>
            <div className="card p-2 w-12/12">
                <div className="card-header flex justify-between">
                    <span className="inline-block text-blue-800">
                        par {item?.userFrom?.profile?.pseudo}
                        <span className="text-sm ml-2">
                            1h
                            {!currentIsFirstTransaction && <span>- {item.amount} €</span>}
                            {currentIsFirstTransaction && <span>- Offerte</span>}
                        </span>
                    </span>
                    <span className="inline-block text-sm text-success-500">{humanStatus}</span>
                </div>
                <div className="flex justify-between">
                    <span className="inline-block mx-2">{currentDate}</span>
                </div>
                { currrentMessage && (
                    <div className="flex justify-between mt-2">
                        <span className="inline-block mx-2">{currrentMessage}</span>
                    </div>
                )}
                <div className="flex flex-nowrap justify-end">
                    <div className="card-actions w-full">
                        {item?.status === 'init' && (
                            <div className="flex flex-nowrap">
                                {/*}
                                {currrentMessage && (
                                    <button onClick={() => setOpenMessageReservation(true)} className="text-success-500 flex ml-2">
                                        <AnnotationIcon className="w-5 h-5" /> Message
                                    </button>
                                )}
                                */}
                                <button onClick={() => setOpenConfirmAccept(true)} className="text-blue-500 flex ml-2 border-2 rounded-xl border-blue-500 py-1 px-2">
                                    <CheckIcon className="w-5 h-5" /> Accepter
                                </button>
                                <button onClick={() => setOpenConfirmCancel(true)} className="text-danger-500 flex ml-2 border-2 rounded-xl border-danger-500 py-1 px-2">
                                    <XCircleIcon className="w-5 h-5 " /> Refuser
                                </button>
                            </div>
                        )}
                        {item?.status === 'accepted' && (
                            <button className="text-blue-500 flex ml-2">
                                <ClockIcon className="w-5 h-5" /> Le prestataire validera la prestation une fois effectuée
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default CardVisio;
