import { useState } from "react";
import { useEffect, useRef, useCallback } from "react";

function useSafeState(initValue = null) {
    const isMounted = useRef(true);
    const [state, setState] = useState(initValue);
    useEffect(() => () => (isMounted.current = false), []);

    const setStateSafe = useCallback((value) => {
        if (isMounted.current) {
            setState(value);
        }
    }, []);
    return [state, setStateSafe];
}

export default useSafeState;
