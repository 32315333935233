import { useEffect, useRef, Fragment, useContext, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
//CONTEXTS
import { AuthContext } from 'context/authContext';

//API
import { useInfiniteQuery } from 'react-query';
import { getJobsP } from 'api/forum';

//HOOKS
import UseIntersectionObserver from 'hooks/useIntersectionObserver';

//CONTAINERS
import CardFrontRoom from 'containers/rooms/CardFrontRoom';
import SectionTitle from 'components/common/SectionTitle';
//UTILS
// import { isFoundByKey } from 'utils/functions';
import ClipLoader from 'react-spinners/ClipLoader';

function useInfiniteJobs(q, place, contract, type, isFav) {
    const [authState] = useContext(AuthContext);
    const location = useLocation();
    //REFS
    const page = useRef(0);
    const hasNextPage = useRef(false);
    const loadMoreButtonRef = useRef();

    const [tab, setTab] = useState(null);

    const globalJobs = useInfiniteQuery(
        ['infiniteJobs', isFav],
        ({ pageParam = 0 }) => getJobsP(`?p=${pageParam}&l=10&q=${q}&place=${place}&contract=${contract}&type=${type}`, isFav),
        {
            getNextPageParam: (lastPage) => {
                if (lastPage.hasNextPage) {
                    hasNextPage.current = lastPage.hasNextPage;

                    return (page.current = lastPage.page);
                } else {
                    hasNextPage.current = false;
                    page.current = 0;
                }
                return;
            },
            enabled: true,
        }
    );

    //HOOKS
    UseIntersectionObserver({
        target: loadMoreButtonRef,
        onIntersect: (isVisible) => {
            if (isVisible) globalJobs.fetchNextPage();
        },
        enabled: !!hasNextPage.current,
    });

    useEffect(() => {
        if (authState.isLogged) globalJobs.refetch();
        //eslint-disable-next-line
    }, [authState.isLogged]);

    useEffect(() => {
        globalJobs.refetch();
        //eslint-disable-next-line
    }, [location.state]);

    useEffect(() => {
        globalJobs.refetch();
        //eslint-disable-next-line
    }, [window.history.state]);

    const tabGlobal = useCallback(() => {
        return (
            <>
                <SectionTitle>
                    <h1 className="h1 mx-0 my-2 mt-4 mb-2">
                        <span className="text-purpleSkills">Plus de {globalJobs?.data?.pages[0].totalDocsCount.toLocaleString()} d'offres</span>
                    </h1>
                </SectionTitle>
                {globalJobs.data?.pages && (
                    <div className="mt-4">
                        {globalJobs.data?.pages?.map((pages, index) => (
                            <Fragment key={index}>
                                {pages.docs.map((job, jobIndex) => {
                                    return (
                                        <CardFrontRoom
                                            key={jobIndex}
                                            room={job}
                                            refetch={globalJobs.refetch}
                                            profile={job?.user}
                                            listingQueryKeys={['infiniteJobs']}
                                        />
                                    );
                                })}
                            </Fragment>
                        ))}
                    </div>
                )}
                {hasNextPage.current && (
                    <div className="text-center mt-4 mb-14">
                        <button
                            ref={loadMoreButtonRef}
                            className="button button-secondary"
                            onClick={globalJobs.fetchNextPage}
                            disabled={!hasNextPage.current || globalJobs.isFetchingNextPage}
                        >
                            {globalJobs.isFetchingNextPage ? 'Chargement...' : 'Voir plus'}
                        </button>
                    </div>
                )}
            </>
        );
        //eslint-disable-next-line
    }, [globalJobs.data, globalJobs.isSuccess]);

    useEffect(() => {
        if (globalJobs?.data?.pages[0].totalDocs === 0) {
            return setTab(
                <div className="flex justify-center mt-4">
                    {isFav ? (
                        <p className="text-center text-purpleSkills">Aucun favoris</p>
                    ) : (
                        <p className="text-center text-purpleSkills">Aucune offre ne correspond à votre recherche</p>
                    )}
                </div>
            );
        }
        // if loading
        if (globalJobs.isLoading) {
            return setTab(
                <>
                    <div className="flex justify-center mt-4">
                        <ClipLoader color={'#3f3d56'} loading={true} size={25} />
                    </div>
                </>
            );
        }
        setTab(tabGlobal);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tabGlobal, globalJobs.data, authState.isLogged]);

    return tab;
}

export default useInfiniteJobs;
