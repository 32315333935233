import { useState, useRef } from 'react';

//STYLES
import 'containers/cards.css';

//COMPONENTS
import Image from 'components/common/Image';
//import Modal from 'components/common/Modal';
import ModalSwipe from '../../components/common/modal/ModalSwipe';

const sizes = {
    low: '?w=120&h=120&c=true&q=1&f=webp&p=true',
    full: '?w=900&q=95',
    portfolio: '?w=140&h=140&c=true&q=95',
};

function CardPortfolio({ media, user }) {
    //CONTEXTS
    const [open, setOpen] = useState(false);
    //const [currentMedia, setCurrentMedia] = useState(media);
    const currentIndex = useRef(null);

    function handleOpen() {
        currentIndex.current = user.profile.medias.indexOf(media);
        // setCurrentMedia(media);
        setOpen(true);
    }

    /*   function handlePrev() {
        setCurrentMedia((prevMedia) => {
            const currentIndex = user.profile.medias.indexOf(prevMedia);
            const prevIndex = currentIndex > 0 ? currentIndex - 1 : user.profile.medias.length - 1;
            return user.profile.medias[prevIndex];
        });
    }

    function handleNext() {
        setCurrentMedia((prevMedia) => {
            const currentIndex = user.profile.medias.indexOf(prevMedia);
            const nextIndex = currentIndex < user.profile.medias.length - 1 ? currentIndex + 1 : 0;
            return user.profile.medias[nextIndex];
        });
    } */

    return (
        <>
            <ModalSwipe
                modalHeight={'h-full'}
                open={open}
                navigation={true}
                /*    prev={handlePrev}
                next={handleNext} */
                onClose={{
                    onClick: () => setOpen(false),
                }}
                onCloseOverlay={true}
                onSlideChange={(swiper) => {}}
                onSwiper={(swiper) => {}}
                swipeDirection="horizontal"
                images={user.profile.medias}
                initialSlide={currentIndex.current}
            />

            <div className="bg-none w-full h-full border-blueMain">
                <Image
                    onClick={handleOpen}
                    className="object-cover h-28 sm:h-48 w-full rounded-lg cursor-pointer"
                    src={media?.url}
                    loader={media?.url + sizes.low}
                    alt={user.profile?.pseudo}
                />
            </div>
        </>
    );
}

export default CardPortfolio;
