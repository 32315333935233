import { useEffect, useState } from 'react';

//LIBS
//import _ from 'lodash';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

//FORMS
import schema from 'components/forms/yup/experience';

//ICONS
import { PlusIcon, XCircleIcon, PlusCircleIcon } from '@heroicons/react/solid';

//COMPONENTS
import profileOptions from 'components/forms/options/profile';
import FieldInput from 'components/forms/fields/input';
//import useSafeState from 'hooks/useSafeState';
import Modal from 'components/common/Modal';
import Textarea from 'components/forms/fields/Textarea';
import { isMobile } from '../../../utils/functions';

function FieldExperiences(props) {
    const [items, setItems] = useState(props.items);
    const [modalIsModify, setModalIsModify] = useState(false);
    const [modalIndex, setModalIndex] = useState(0);
    const [modalTitle, setModalTitle] = useState('Ajouter une expérience');
    const [modalExperience, setModalExperience] = useState(false);
    const [presentationInitialValue, setPresentationInitialValue] = useState('');
    const [readyToWatch, setReadyToWatch] = useState(false);

    //HOOKS
    const {
        register,
        handleSubmit,
        setValue,
        clearErrors,
        getValues,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const sortExperiences = (experiences) => {
        return experiences.sort((a, b) => {
            if (a.currentJob && !b.currentJob) {
                return -1;
            } else if (!a.currentJob && b.currentJob) {
                return 1;
            } else if (a.yearTo !== b.yearTo) {
                return b.yearTo - a.yearTo;
            } else if (a.yearFrom !== b.yearFrom) {
                return -a.yearFrom - b.yearFrom;
            } else {
                return a.title.localeCompare(b.title);
            }
        });
    };

    function handleDelete(index, _id) {
        let newExperiences;
        if (_id) {
            newExperiences = items.filter((experience) => experience._id !== _id);
        } else {
            newExperiences = [...items];
            newExperiences.splice(index, 1);
        }
        const sortedExperiences = sortExperiences(newExperiences);
        setItems(sortedExperiences);
        props.onChange(sortedExperiences);
    }

    function submit() {
        /*  e.preventDefault(); */
        handleSubmit(onSubmit)();
    }

    useEffect(() => {
        if (readyToWatch) {
            clearErrors();
        }
        //eslint-disable-next-line
    }, [readyToWatch]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setReadyToWatch(true);
        }, 1000);

        return () => clearTimeout(timer);

        //eslint-disable-next-line
    }, []);

    const onSubmit = (data) => {
        if (errors.length > 0) return false;
        setModalExperience(false);

        const location = [];
        if (data.address && data.point) {
            const points = data.point.split(',');
            if (points.length === 2) {
                location.push(parseFloat(points[0]));
                location.push(parseFloat(points[1]));
            }
        }

        let newExperiences = [];

        if (modalIsModify) {
            newExperiences = items.map((experience, index) => {
                if (index === modalIndex) {
                    return {
                        title: data.title,
                        yearFrom: data.yearFrom,
                        yearTo: data.yearTo,
                        society: data.society,
                        description: data.description,
                        currentJob: data.currentJob,
                        location: {
                            address: data.address,
                            type: 'Point',
                            coordinates: location,
                        },
                    };
                }
                return experience;
            });
        } else {
            newExperiences = [
            ...items,
            {
                title: data.title,
                yearFrom: data.yearFrom,
                yearTo: data.yearTo,
                society: data.society,
                description: data.description,
                currentJob: data.currentJob,
                location: {
                    address: data.address,
                    type: 'Point',
                    coordinates: location,
                },
            },
            ];
        }
        /*  newExperiences = _.reverse(_.sortBy(newExperiences, ['yearTo']));
        setItems(newExperiences);
        props.onChange(newExperiences); */
        const sortedExperiences = sortExperiences(newExperiences);
        setItems(sortedExperiences);
        props.onChange(sortedExperiences);

        setValue('title', '');
        setValue('society', '');
        setValue('yearFrom', 1900);
        setValue('yearTo', new Date().getFullYear());
        setValue('description', '');
        setPresentationInitialValue('');
        setValue('currentJob', false);
        setValue('address', '');
        setValue('point', '');

        const timer = setTimeout(() => {
            setReadyToWatch(true);
        }, 1000);

        return () => clearTimeout(timer);
    };

    useEffect(() => {
        const sortedExperiences = sortExperiences(props.items);
        setItems(sortedExperiences);
        setItems(props.items);
    }, [props.items]);

    //OPTIONS
    function dispatchOptionsActions(action, name, value) {
        switch (action) {
            case 'onValidateOption':
                return onValidateOption(name, value);
            case 'onChangeOptionAddress':
                return onChangeOptionAddress(name, value);
            default:
        }
    }

    //FUNCTIONS OPTIONS
    function onChangeOptionAddress(name, value) {
        setValue(name, value);
    }
    function onValidateOption(name, value) {
        return true;
    }

    function handleExperience(e) {
        e.preventDefault();
        setModalTitle('Ajouter une expérience');
        setModalIsModify(false);
        setValue('title', '');
        setValue('society', '');
        setValue('yearFrom', new Date().getFullYear());
        setValue('yearTo', new Date().getFullYear());
        setValue('description', '');
        setValue('currentJob', false);
        setValue('address', '');
        setValue('point', '');
        setPresentationInitialValue('');
        setModalExperience(true);
    }

    function handleModify(index) {
        const experience = items[index];
        setValue('title', experience.title);
        setValue('society', experience.society);
        setValue('yearFrom', experience.yearFrom);
        setValue('yearTo', experience.yearTo);
        setValue('description', experience.description);
        setValue('currentJob', experience.currentJob);
        setValue('address', experience.location?.address);
        setValue('point', experience.location?.coordinates?.join(','));
        setPresentationInitialValue(experience.description);
        setModalTitle('Modifier une expérience');
        setModalIsModify(true);
        setModalIndex(index);
        setModalExperience(true);
    }

    return (
        <>
            <Modal
                open={modalExperience}
                title={modalTitle}
                onClose={{
                    onClick: () => setModalExperience(false),
                }}
                onConfirm={{
                    label: 'Valider',

                    onClick: () => {
                        submit();
                    },
                }}
            >
                <div>
                    <div className="flex gap-3">
                        <FieldInput
                            label={`${props.label ? props.label : 'Titre*'}`}
                            inputForm="floating"
                            name="title"
                            size="w-full"
                            errors={errors}
                            register={register}
                        />
                        <FieldInput
                            label="Début*"
                            name="yearFrom"
                            type="number"
                            min="1900"
                            defaultValue={1900}
                            inputForm="floating"
                            size="w-5/12 sm:w-3/12"
                            errors={errors}
                            register={register}
                        />
                        <FieldInput
                            label="Fin*"
                            name="yearTo"
                            type="number"
                            max={new Date().getFullYear()}
                            defaultValue={new Date().getFullYear()}
                            inputForm="floating"
                            size="w-5/12 sm:w-3/12"
                            errors={errors}
                            register={register}
                        />
                    </div>
                    {/* autocomplète */}
                    <div className="flex gap-1 pb-2">
                        <FieldInput
                            label={`${isMobile() ? "Nom de l'entreprise*" : "Nom de l'entreprise ou de l'association*"}`}
                            name="society"
                            inputForm="floating"
                            size="w-1/2"
                            errors={errors}
                            register={register}
                        />
                        <FieldInput
                            inputForm="floating"
                            disabled={true}
                            name="address"
                            label="Lieu"
                            size="w-1/2"
                            depends="point"
                            errors={errors}
                            register={register}
                            option={profileOptions.address}
                            dispatchOptionsActions={dispatchOptionsActions}
                            getValues={getValues}
                            readyToWatch={readyToWatch}
                        />
                        {/* <FieldInput label="Lieu" name="adress" inputForm="floating" size="w-1/2" errors={errors} register={register} /> */}
                    </div>
                    <div className="flex gap-2 items-center py-2">
                        <input type="checkbox" id="currentJob" className="w-8 sm:w-4" name="currentJob" {...register('currentJob')} />
                        <label className="text-sm text-purpleSkills" htmlFor="currentJob">
                            C'est mon emploi actuel
                        </label>
                    </div>
                    <div className="flex w-full">
                        <Textarea
                            label="Description du poste"
                            name="description"
                            errors={errors}
                            register={register}
                            initValue={presentationInitialValue}
                            onChange={(value) => setValue('description', value)}
                        />
                    </div>
                </div>
            </Modal>
            <div className="w-full">
                <button
                    className={`flex ${props.className ? props.className : ''} linkCard text-purpleSkills gap-2 text-left`}
                    type="button"
                    onClick={(e) => {
                        handleExperience(e);
                    }}
                >
                    <PlusIcon className={`${props.iconClassName ? props.iconClassName : 'w-4 h-4'}`} />
                    <span>Ajouter une expérience</span>
                </button>

                <div className="flex flex-col mt-2 gap-2">
                    {items?.map((experience, k) => (
                        <div key={k} className="p-2 flex flex-col bg-blancSkills rounded">
                            <div className="flex justify-between w-full">
                                <div className="w-full flex flex-col">
                                    <p className="text-purpleSkills font-bold">{experience.title}</p>
                                    {/*  <p className="text-purpleSkills"> - </p> */}
                                    <p className="text-purpleSkills font-semibold">{experience.society}</p>

                                    <p className="text-purpleSkills text-sm">
                                        De {experience.yearFrom} à {experience.yearTo}
                                        {experience.currentJob ? " - aujourd'hui" : ''}
                                        {' - '}
                                        {experience.yearTo - experience.yearFrom} an(s)
                                    </p>
                                    {experience.location?.address && <p className="text-purpleSkills text-sm">{experience.location?.address}</p>}
                                </div>
                                <div className="cursor-pointer">
                                    <PlusCircleIcon onClick={() => handleModify(k)} className="m-2 w-5 h-5 text-graySkills" />
                                </div>
                                <div className="cursor-pointer">
                                    <XCircleIcon onClick={() => handleDelete(k, experience._id)} className="m-2 w-5 h-5 text-graySkills" />
                                </div>
                            </div>

                            {experience.description && (
                                <div className="pt-2 text-purpleSkills">
                                    <div dangerouslySetInnerHTML={{ __html: experience.description }} />
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}

export default FieldExperiences;
