import { useContext, useState /* , useEffect */ } from 'react';
import { useSpring, animated } from 'react-spring';
//COMPONENTS
import SideNav from 'components/common/nav/SideNav';
import Modal from 'components/common/Modal';
import ForumSearchBarV2 from 'containers/searchBar/ForumSearchBarV2';
//import SliderShoots from 'containers/shoots/SliderShoots';
import Tabs from 'components/tabs/Tabs';

//CONTAINERS
// import TopSkillersListing from 'containers/profils/TopSkillersListing';
// import ListingJobs from 'pages/ListingJobs';

//CONTEXTS
import { AuthContext } from 'context/authContext';
import { StoreContext } from 'context/storeContext';
//HOOKS
import useInfiniteRooms from 'hooks/useInfiniteRooms';
import useInfinitePosts from 'hooks/useInfinitePosts';
//import useInfiniteMatchMaking from 'hooks/useInfiniteMatchMaking';
//LAYOUT
import ContainerLayout from 'pages/layouts/Container';

//UTILS
import { isMobile } from 'utils/functions';
import { PageContentFadingAnimation } from 'utils/animations';
import WelcomeCloneAi from '../components/common/WelcomeCloneAi';
import { useLocation } from 'react-router-dom';
import JobDetail from '../containers/rooms/JobDetail';

function Home() {
    const [authState] = useContext(AuthContext);
    const location = useLocation();
    //const navigate = useNavigate();
    // const [tabs, setTabs] = useState(null);
    const [open, setOpen] = useState(false);
    const [fetchedTabs, setFetchedTabs] = useState({});
    const isTabFetched = (tabKey) => !!fetchedTabs[tabKey];

    const store = useContext(StoreContext);

    //const infiniteRoomsForMe = useInfiniteRooms(true, false, false, isTabFetched('tab_forMe'));
    //const infiniteRoomsForMeNews = useInfiniteRooms(true, true, false, isTabFetched('tab_forMe'));

    const infiniteRooms = useInfiniteRooms(false, false, false);
    const infiniteRoomsNew = useInfiniteRooms(false, true, false);

    //const infinitePostsTendance = useInfinitePosts(false, isTabFetched('tab_forum'));
    const infinitePostsNew = useInfinitePosts(false, isTabFetched('tab_forum'));

    /*  const infiniteMatchMaking = useInfiniteMatchMaking(); */
    const handleTabChange = (index) => {
        if (index && !fetchedTabs[index]) {
            setFetchedTabs((prev) => ({ ...prev, [index]: true }));
        }
    };

    /* useEffect(() => {
        // Si l'utilisateur est connecté et essaie d'accéder à '/inscription', redirigez vers la racine
        if(authState.isLogged && authState.me?.neverUpdate === false && location.pathname === '/inscription-clone-ai') {
            navigate('/');
        } 
    }, [authState, location, navigate]); */

    /*
    const tabForMe = (
        <>
            {infiniteRoomsForMeNews ? infiniteRoomsForMeNews : infiniteRoomsNew}
            {infiniteRoomsForMe ? infiniteRoomsForMe : infiniteRooms}
        </>
    );*/
    const tabHome = (
        <>
            {infiniteRoomsNew}
            {infiniteRooms}
        </>
    );

    const tabForum = (
        <>
            <Modal
                open={open}
                title={'Créer un post'}
                onClose={{
                    label: 'Fermer',
                    onClick: () => setOpen(false),
                }}
                onConfirm={{
                    label: 'Créer',
                    onClick: () => setOpen(false),
                }}
            >
                <ForumSearchBarV2
                    isButton={true}
                    title={false}
                    className="flex text-2xl font-bold linkCard gap-2 w-full px-6 py-2"
                    typeForum="question"
                    /*   icon="question" */
                    iconClassName="w-7 h-7"
                    isCreatePost={true}
                    handleClose={() => setOpen(false)}
                />
            </Modal>
            <div className="flex justify-center sticky w-full z-10 cursor-pointer flex-col items-center mt-2 p-2 sm:p-6 rounded-3xl">
                <div className="relative mb-4 flex flex-col justify-center bg-slate-900 overflow-hidden">
                    <div className="w-full mx-auto ">
                        <div className="text-center">
                            <div className="font-extrabold text-lg md:text-xl [text-wrap:balance] bg-clip-text text-purpleSkills bg-gradient-to-r from-slate-200/60 to-50% to-slate-200">
                                <span className="text-indigo-500 inline-flex flex-col h-auto">
                                    <ul className="block leading-tight [&_li]:block text-center h-auto">
                                        <li>Des réponses rapides, sûres par l'IA et des experts</li>
                                    </ul>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-[60%] sm:w-[40%]">
                    <button
                        className="shadow-blueMain/60 hover:bg-white hover:text-blueMain shadow-lg flex items-center text-md sm:text-lg font-bold rounded-3xl justify-center bg-blueMain text-white gap-2 w-full px-4 py-2"
                        type="button"
                        onClick={(e) => {
                            e.preventDefault();
                            authState.isLogged ? setOpen(true) : document.getElementById('button-login')?.click();
                        }}
                    >
                        <span>Posez votre Question</span>
                    </button>
                </div>
            </div>
            {infinitePostsNew}
        </>
    );

    /*  const tabMatchMaking = (
        <>
            {infiniteMatchMaking}
        </>
    ); */

    const tabs = [];
    tabs['desktop'] = [
        /*   { label: 'Matchmaking', key: 'tab_matchMaking', content: tabMatchMaking}, */
        { label: 'News', key: 'tab_home', content: tabHome },
        //{ label: 'Pour moi', key: 'tab_forMe', content: tabForMe, visible: authState.isLogged },
        { label: 'Forum AI', key: 'tab_forum', content: tabForum },
    ];

    tabs['mobile'] = [
        { label: 'News', key: 'tab_home', content: tabHome },
        { label: 'Forum AI', key: 'tab_forum', content: tabForum },
    ];

    const closeSlideAnimationSideNav = useSpring({
        transform: store.get('openJobDetail') ? 'translateX(-23%)' : 'translateX(0%)',
        config: {
            duration: 140,
        },
    });

    return (
        <>
            <JobDetail />
            <ContainerLayout noContain={store.get('openJobDetail') ? true : false}>
                <animated.div style={closeSlideAnimationSideNav} className="container">
                    <div className="w-full flex flex-col sm:flex-row sm:gap-8 pb-4">
                        {!isMobile() && (
                            <div className="hidden sm:block w-[18%]">
                                <SideNav />
                            </div>
                        )}

                        <div className={`w-full ${store.get('openJobDetail') ? 'w-full sm:w-[80%] xl:pl-28 pr-2' : 'sm:w-[65%]'} h-full`}>
                            <main className="">
                                <PageContentFadingAnimation duration={250}>
                                    {/*
                                    <div className="py-4">
                                        <SliderShoots />
                                    </div>
                                    */}
                                    {!isMobile() ? (
                                        <>
                                            <Tabs tabs={tabs?.desktop} onTabChange={handleTabChange} />
                                        </>
                                    ) : (
                                        <Tabs tabs={tabs?.mobile} onTabChange={handleTabChange} />
                                    )}
                                </PageContentFadingAnimation>
                            </main>
                        </div>
                    </div>
                </animated.div>
            </ContainerLayout>

            {!authState.isLogged && location.pathname === '/inscription-clone-ai' && <WelcomeCloneAi />}
        </>
    );
}

export default Home;
