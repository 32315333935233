import { useEffect, useState } from "react";
//LIBS
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
//API
import { putVideoPublish, deleteVideo } from "api/videos";
//ICONS
import {
    EyeIcon,
    EyeOffIcon,
    PencilIcon,
    TrashIcon,
} from "@heroicons/react/solid";
import Modal from "components/common/Modal";

function CardVideo({ video, refetch }) {
    const [item, setItem] = useState(video);
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

    function handleTogglePublish() {
        putVideoPublish(item.id).then((data) => {
            setItem({ ...item, publish: data.publish });
            const msg = data.publish
                ? "Espace vidéo publié"
                : "Espace vidéo dépublié";
            toast(msg, { type: "success" });
        });
    }
    function handleDelete() {
        deleteVideo(item.id).then((data) => {
            toast("Espace vidéo supprimé", { type: "success" });
            refetch();
            setOpenConfirmDelete(false);
        });
    }

    useEffect(() => {
        setItem(video);
        //eslint-disable-next-line
    }, [, video]);

    return (
        <>
            <Modal
                title="Supprimer l'espace vidéo"
                open={openConfirmDelete}
                onClose={{
                    onClick: () => {
                        setOpenConfirmDelete(false);
                    },
                }}
                onConfirm={{
                    label: "Supprimer",
                    onClick: handleDelete,
                }}
            >
                <p>Voulez-vous vraiment supprimer cet espace vidéo ?</p>
            </Modal>
            <div className="card card-service w-12/12">
                <div className="card-header">
                    <span>{item.title}</span>
                    <span>{item.price}</span>
                </div>

                <div className="flex flex-nowrap justify-end">
                    <div className="card-actions flex flex-nowrap">
                        <button
                            onClick={handleTogglePublish}
                            className={`flex ml-2 ${
                                item.publish
                                    ? "text-success-400"
                                    : "text-warning-300"
                            }`}
                        >
                            <>
                                {item.publish ? (
                                    <>
                                        <EyeIcon className="w-5 h-5 mr-1" />{" "}
                                        Dépublier
                                    </>
                                ) : (
                                    <>
                                        <EyeOffIcon className="w-5 h-5 mr-1" />{" "}
                                        Publier
                                    </>
                                )}
                            </>
                        </button>
                        <Link to={`${item.id}`} className="flex ml-2">
                            <>
                                <PencilIcon className="w-5 h-5 mr-1" /> Modifier
                            </>
                        </Link>
                        <button
                            onClick={() => setOpenConfirmDelete(true)}
                            className="ml-2 flex text-danger-500"
                        >
                            <>
                                <TrashIcon className="w-5 h-5 mr-1" /> Supprimer
                            </>
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CardVideo;
