import * as yup from 'yup';

const schema = yup.object().shape({
    linkWebsite: yup.string().url("L'adresse URL n'est pas valide"),
    linkYoutube: yup.string().url("L'adresse URL n'est pas valide"),
    linkSnapchat: yup.string().url("L'adresse URL n'est pas valide"),
    linkInstagram: yup.string().url("L'adresse URL n'est pas valide"),
    linkTiktok: yup.string().url("L'adresse URL n'est pas valide"),
    linkLinkedin: yup.string().url("L'adresse URL n'est pas valide"),
    linkTwitter: yup.string().url("L'adresse URL n'est pas valide"),
});

export default schema;
