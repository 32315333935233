import { useCallback, useContext, useEffect, useRef, useState } from 'react';

//LIBS
import { HeadlessEdit } from 'libs/headless';

import { Link, useLocation } from 'react-router-dom';
import ReactPlayer from 'react-player';
import { useParams } from 'react-router-dom';
import Slider from 'react-slick';

//STYLES
import 'App.css';

//UTILS
import { isFoundByKey, countByKey, isMobile, isSmartphone } from 'utils/functions';
import { PageContentFadingAnimation } from 'utils/animations';
import Logger from 'utils/logger';

//API
import { getComments } from 'api/comments';
//import { getRoomsByUser } from 'api/forum';
import { getServicesByUserForProfile } from 'api/services';
import { getSimilar } from 'api/search';
import { getShootsPaginateByUser, getShoot, getShootsByUser } from 'api/shoots';
import { getRoomsByUserForProfilPaginate, getSampleRoomsByUser } from 'api/forum';
import { getUser } from 'api/users';
import { postCounter } from 'api/counters';
import { useQuery } from 'react-query';

//COMPONENTS
import Avatar from 'containers/profils/Avatar';
import Button from 'components/common/Button';
import CommentForm from 'components/forms/comment';
import FormProfil from 'components/forms/profil';
//import ForumSearchBarV2 from 'containers/searchBar/ForumSearchBarV2';
import Image from 'components/common/Image';
// import MessageForm from 'components/forms/message';
import Modal from 'components/common/Modal';
// import OptionsMenu from 'components/common/OptionsMenu';
import PlayerVideo from 'components/common/PlayerVideo';
import Rating from 'components/Rating';
import ReactiveSidebarForm from 'components/forms/ReactiveSidebarForm';
import Reservation from 'components/visio/Reservation';
import Share from 'components/share/Share';
import SideNav from 'components/common/nav/SideNav';
//import Tabs from 'components/tabs/Tabs';
import SeeMore from 'components/common/SeeMore';
import TabsProfil from 'components/tabs/TabsProfil';
//import Moment from 'components/common/Moment';

//CONFIG
import config from 'utils/constants';

//CONTAINERS
// import AdSenseIns from 'containers/adsense/AdSenseIns';
import AnswerForm from 'containers/answers/AnswerForm';
import Answers from 'containers/answers/Answers';
import CardFrontRoom from 'containers/rooms/CardFrontRoom';
import CardFrontService from 'containers/services/CardFrontService';
import CardProfilShoot from 'containers/profils/CardProfilShoot';
import CardFrontProfil from 'containers/profils/CardFrontProfil';
import Cv from 'containers/profils/Cv';
import CardPortfolio from 'containers/profils/CardPortfolio';
import OptionsMenu from 'components/common/OptionsMenu';
//import StepsFormProfil from 'components/common/StepsFormProfil';
import CardUserSmall from 'containers/services/CardUserSmall';
import ShareOptions from 'components/share/ShareOptions';

//CONTEXTS
import { AuthContext } from 'context/authContext';
import { FormContext } from 'context/formContext';
import { StoreContext } from 'context/storeContext';

//HOOKS
import userInfiniteSkillers from 'hooks/useInfiniteSkillers';
import useInfiniteMatchMaking from 'hooks/useInfiniteMatchMaking';
import useInfinite from 'hooks/useInfinite';
import useHasCounter from 'hooks/useHasCounter';

//ICONS
import { SvgLinkedin, SvgTwitter, SvgInstagram, SvgYoutube, SvgTiktok, SvgSnapchat } from 'components/common/Svg';
import {
    AnnotationIcon,
    ArrowDownIcon,
    ArrowUpIcon,
    BellIcon,
    BookmarkIcon,
    VideoCameraIcon,
    CheckIcon,
    /*  DotsHorizontalIcon, */
    DotsVerticalIcon,
    EyeIcon,
    GlobeIcon,
    HeartIcon,
    LocationMarkerIcon,
    PencilIcon,
    PhoneIcon,
    PlusIcon,
    StarIcon,
    UsersIcon,
    XCircleIcon,
    XIcon,
    LinkIcon,
} from '@heroicons/react/solid';
//import { BellIcon as BellIconOutline } from '@heroicons/react/outline';

//LAYOUT
import ProfilLayout from '../layouts/ProfilLayout';
import { SvgX } from '../../components/common/Svg';
import SEO from '../../components/Seo';

//eslint-disable-next-line
const logger = Logger();

const sizes = {
    avatar: '?w=250&h=250&c=true&q=80',
    cover: '?w=900&q=80',
    coverMobile: '?w=800&h=260&c=true&q=80',
};

function SingleProfil() {
    const params = useParams();
    const location = useLocation();

    //CONTEXTS
    const [authState] = useContext(AuthContext);
    const store = useContext(StoreContext);
    const [, formContextDispatch] = useContext(FormContext);

    //STATES
    const [modalCalendar, setModalCalendar] = useState(false);
    const [modalProfil, setModalProfil] = useState(false);
    const [hasShoot, setHasShoot] = useState(false);
    const [hasRoom, setHasRoom] = useState(false);
    const [openAvatar, setOpenAvatar] = useState(false);
    const [shoots, setShoots] = useState([]);
    const [currentShoot, setCurrentShoot] = useState({});
    const [playedShoots, setPlayedShoots] = useState({});
    const [openShoot, setOpenShoot] = useState(false);
    const [showAllPortfolio, setShowAllPortfolio] = useState(false);
    const [rowCount, setRowCount] = useState(0);
    const [showAllRows, setShowAllRows] = useState(false);
    const [switchTab, setSwitchTab] = useState('tab_presentation');
    const [showAll, setShowAll] = useState(false);
    const [displayedServices, setDisplayedServices] = useState(isMobile() ? 2 : 3);
    const [shareModal, setShareModal] = useState(false);
    //REFS
    const currentIndex = useRef(null);
    const shootRef = useRef(null);
    const skillsRef = useRef(null);
    const refReservation = useRef(null);
    const hasNextPage = useRef(false);
    // const refUser = useRef(null);
    //const topSkillers = userInfiniteSkillers('interests', false, true);

    const toggleShowAll = () => {
        setShowAll(!showAll);
        setDisplayedServices(showAll ? (isMobile() ? 2 : 3) : services.data.length);
    };

    //Options
    /* const [openOptionsUserMenu, setOpenOptionsUserMenu] = useState({ id: null, open: false }); */

    //API
    const user = useQuery(
        ['user', params.slug],
        (params) => {
            return getUser(params.queryKey[1]);
        },
        { enabled: false }
    );

    const users = useQuery(['similarUsers', params.slug], () => getSimilar(user.data.id, 20), {
        enabled: false,
    });
    /*  const rooms = useQuery(['userRooms', params.slug], () => getRoomsByUser(user.data.id, 20), {
        enabled: false,
    }); */
    const comments = useQuery(['userComments', params.slug], () => getComments(user.data.id), {
        enabled: false,
    });

    const services = useQuery(['services', user?.data?.id], () => getServicesByUserForProfile(user?.data?.id), {
        enabled: false,
    });
    const userShoots = useQuery(['userShoots', user?.data?.id], () => getShootsByUser(user?.data?.id, isMobile() ? 3 : 4), {
        enabled: false,
    });

    const userRooms = useQuery(['userRooms', user?.data?.id], () => getSampleRoomsByUser(user?.data?.id, 2), {
        enabled: false,
    });

    const infiniteUsersMatchMaking = useInfiniteMatchMaking(false, true, false);

    const interestsSkillers = userInfiniteSkillers('interests', false, true);

    const infiniteRooms = useInfinite(getRoomsByUserForProfilPaginate, ['ListingRoomsForProfil', params], {
        onItem: (room, refetch) => (
            <div className="w-full">
                <CardFrontRoom room={room} refetch={refetch} profile={user.data} userId={user.data.id} listingQueryKeys={['userRooms']} />
            </div>
        ),
        enabled: !!user.data?.id,
        listen: user.data?.id,
        user: user.data?.id,
        limit: 10,
        msgToSee: 'Voir plus de posts',
        onNoResult: () => <></>,
        onData: (data) => {
            if (data?.pages[0]?.docs?.length > 0) {
                setHasRoom(true);
            }
        },
    });

    const infiniteShoots = useInfinite(getShootsPaginateByUser, ['Listingshoots', params.slug], {
        onItem: (shoot, refetch) => (
            <div className="relative h-52" onClick={(e) => handleClick(e, shoot)}>
                <div className="h-52 w-auto rounded-lg shadow overflow-hidden">
                    <PlayerVideo
                        id={shoot._id}
                        width={'100%'}
                        shoot={shoot}
                        muted={false}
                        controls={false}
                        light={`${config.serverUrl}/downloads/videos/${shoot.user.id}/screenshot_${shoot.video._id}_${shoot.selectedScreen}.jpg`}
                    />
                    <div className="absolute top-5 right-2">
                        <div className="flex flex-col gap-1 items-center">
                            <Button
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    handleOptionsShootMenu(shoot);
                                }}
                                label={<DotsVerticalIcon className="w-6 h-6" />}
                                justIcon={true}
                                css="text-white"
                            />
                            <OptionsMenu shoot={shoot} user={shoot.user} refresh={refetch} path="profil" type="shoot" />
                        </div>
                    </div>
                </div>
            </div>
        ),
        enabled: !!user.data?.id,
        listen: user.data?.id,
        user: user.data?.id,
        limit: 5,
        msgToSee: 'Voir plus de shoots',
        onNoResult: () => <></>,
        onData: (data) => {
            if (data) {
                data?.pages?.forEach((page) => {
                    if (page?.docs?.length > 0) {
                        setShoots((prevShoots) => [...prevShoots, ...page.docs]);
                    }
                });
                if (data.pages[0]?.docs?.length > 0) {
                    setHasShoot(true);
                }
            }
        },
        onLastIndex: () => shoots.indexOf(currentShoot) === shoots.length - 1,
        onRefetch: (refetch) => refetch(),
    });

    const handleOpenAvatar = useCallback((e) => {
        e.preventDefault();
        setOpenAvatar(true);
    }, []);

    const handleSwitchTab = useCallback((tab) => {
        setSwitchTab(tab);
    }, []);

    const handleSubscribe = useCallback(() => {
        postCounter({ type: 'subscription', to: user.data?.id }).then(() => {
            user.refetch();
        });
    }, [user]);

    const handleCalendar = useCallback(() => {
        if (authState.isLogged) setModalCalendar(true);
        else document.getElementById('button-login')?.click();
    }, [authState]);

    const handleProfil = useCallback(
        (e) => {
            e.stopPropagation();
            e.preventDefault();
            if (typeof window != 'undefined' && window.document) {
                document.body.style.overflow = 'hidden';
            }
            if (authState.isLogged) setModalProfil(true);
            else document.getElementById('button-login')?.click();
        },
        [authState]
    );

    const handleAddService = useCallback(
        (e) => {
            e.stopPropagation();
            e.preventDefault();
            if (authState.isLogged) {
                formContextDispatch({ type: 'add' });
            } else document.getElementById('button-login')?.click();
        },
        [authState, formContextDispatch]
    );

    const handleReservation = useCallback(() => {
        refReservation.current.submit();
    }, []);

    const openDiscussion = useCallback(() => {
        store.set({ type: 'add', key: 'openChat', value: { open: true, userId: user.data.id, chatType: 'standard' } });
        store.set({ type: 'add', key: 'discussionType', value: 'standard' });
    }, [store, user]);

    function handleOptionsMenu(user) {
        document.getElementById(`optionsMenu_${user?.data?.id}`).classList.toggle('hidden');
    }

    /* function handleOptionsMenuMobile(user) {
        document.getElementById(`optionsMenu_${user?.data?.id}_mobile`).classList.toggle('hidden');
    } */

    const handleTooglePlay = (shootId) => {
        setPlayedShoots((prevPlayedShoots) => {
            const newPlayedShoots = { ...prevPlayedShoots };
            newPlayedShoots[shootId] = true;
            for (const id in newPlayedShoots) {
                if (id !== shootId) {
                    newPlayedShoots[id] = false;
                }
            }
            return newPlayedShoots;
        });
    };

    function handlePrev() {
        currentIndex.current = shoots.indexOf(currentShoot);
        let prevIndex = shoots[currentIndex.current - 1] ? currentIndex.current - 1 : shoots.length - 1;

        setCurrentShoot(shoots[prevIndex]);
        handleTooglePlay(shoots[prevIndex]._id);
    }

    function handleNext() {
        currentIndex.current = shoots.indexOf(currentShoot);
        let nextIndex = shoots[currentIndex.current + 1] ? currentIndex.current + 1 : 0;

        setCurrentShoot(shoots[nextIndex]);
    }

    function handleOptionsShootMenu(shoot) {
        closeOptionsMenu(shoot);
        document.getElementById(`optionsMenu_${shoot._id}`).classList.toggle('hidden');
    }

    function closeOptionsMenu(shoot) {
        const els = document.getElementsByClassName('optionsMenu');
        [].forEach.call(els, function (el) {
            if (el.id !== `optionsMenu_${shoot._id}`) {
                el.classList.add('hidden');
            }
        });
    }

    const shoot = useQuery(
        ['shoot', currentShoot?.slug],
        (params) => {
            return getShoot(currentShoot?.slug);
        },
        { enabled: currentShoot?.slug ? true : false, staleTime: 0 }
    );
    const hasCounter = useHasCounter(shoot.data?.current?.counters, 'like_shoot', shoot.data?.current._id);
    const hasBookmark = useHasCounter(shoot.data?.current?.counters, 'bookmark_shoot', shoot.data?.current._id);

    const likeCounterCount = shoot.data?.current?.counters?.filter((counter) => counter.type === 'like_shoot').length;
    const bookmarkCounterCount = shoot.data?.current?.counters?.filter((counter) => counter.type === 'bookmark_shoot').length;

    function refetchShoot() {
        shoot.refetch();
    }

    function handleLikeShoot() {
        if (authState.isLogged) {
            postCounter({ userTo: shoot.data?.current?.user.id, type: 'like_shoot', to: shoot.data?.current?._id }).then(() => {
                shoot.refetch();
            });
        } else document.getElementById('button-login')?.click();
    }

    function handleBookmarkShoot() {
        if (authState.isLogged) {
            postCounter({ userTo: shoot.data?.current?.user.id, type: 'bookmark_shoot', to: shoot.data?.current._id }).then(() => {
                shoot.refetch();
            });
        } else document.getElementById('button-login')?.click();
    }

    function handleClick(e, shoot) {
        e.stopPropagation();
        setOpenShoot(true);
        currentIndex.current = shoots.indexOf(shoot);
        setCurrentShoot(shoot);
        handleTooglePlay(shoot._id);
    }

    function handleClose(e) {
        e.stopPropagation();
        setOpenShoot(false);
    }

    useEffect(() => {
        let isMounted = true;

        if (params.slug !== '') {
            hasNextPage.current = false;
            user?.refetch().then(() => {
                if (!isMounted) return;
            });
        }

        return () => {
            isMounted = false;
        };
        //eslint-disable-next-line
    }, [params.slug, location.key]);

    useEffect(() => {
        let isMounted = true;

        if (user.isSuccess && user?.data?.id) {
            store.set({ type: 'del', key: 'currentUser' });
            store.set({ type: 'del', key: 'currentUserComments' });
            users.refetch();
            comments.refetch();
            //rooms.refetch();
            services.refetch();
            userShoots.refetch();
            userRooms.refetch();

            if (user.data?.profile?.medias) {
                user.data.profile.medias.forEach((element, index) => {
                    if (!isMounted) return;
                    user.data.profile.medias[index] = { ...element, url: element.url };
                    store.set({
                        type: 'add',
                        key: 'currentUser',
                        value: { user: user.data, medias: user.data.profile.medias },
                    });
                });
            }
        }

        return () => {
            isMounted = false;
        };
        //eslint-disable-next-line
    }, [user.data]);

    useEffect(() => {
        if (!skillsRef.current) {
            return;
        }

        const container = skillsRef.current;
        const children = Array.from(container.children);

        let currentRow = -1;
        let maxBottom = -1;
        let rowCounts = 0;

        children.forEach((child) => {
            const childRect = child.getBoundingClientRect();
            if (childRect.top > maxBottom) {
                maxBottom = childRect.bottom;
                currentRow++;
                if (currentRow < 2 || showAllRows) {
                    rowCounts++;
                    child.style.display = 'block';
                } else {
                    child.style.display = 'none';
                }
            } else {
                if (currentRow < 2 || showAllRows) {
                    child.style.display = 'block';
                } else {
                    child.style.display = 'none';
                }
            }
        });

        setRowCount(rowCounts);
        //eslint-disable-next-line
    }, [skillsRef.current?.children.length, showAllRows]);

    useEffect(() => {
        if (comments.isSuccess && comments.data) {
            store.set({
                type: 'add',
                key: 'currentUserComments',
                value: { comments: comments.data },
            });
        }
        //eslint-disable-next-line
    }, [comments.data]);

    useEffect(() => {
        return () => {
            store.set({ type: 'del', key: 'currentUser' });
            store.set({ type: 'del', key: 'currentUserComments' });
        };
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        location.state?.shareModal &&
            setTimeout(() => {
                setShareModal(true);
            }, 1000);
        //eslint-disable-next-line
    }, []);

    const actionModifProfil = (
        <>
            {authState?.me?.id === user?.data?.id && (
                <>
                    <Button
                        label={<PencilIcon className="w-4 h-4 mx-auto" />}
                        justIcon={true}
                        css="w-6 h-6 text-white rounded-full bg-blueMain"
                        onClick={handleProfil}
                    />
                </>
            )}
        </>
    );

    const actionAddService = (
        <>
            {authState?.me?.id === user?.data?.id && (
                <>
                    <Button
                        label={<PlusIcon className="w-4 h-4 mx-auto" />}
                        justIcon={true}
                        css="w-6 h-6 text-white rounded-full bg-blueMain"
                        onClick={handleAddService}
                    />
                </>
            )}
        </>
    );

    const profilContent = (
        <>
            <Modal
                open={openAvatar}
                fullScreen={true}
                modalWidth={'w-auto'}
                transparentBg={true}
                noActions
                onClose={{ noButton: true, onClick: () => setOpenAvatar(false) }}
                onCloseOverlay={true}
            >
                <Avatar user={user.data} gamification={false} ping={false} roundImg className={`w-72 sm:w-96`} />
            </Modal>

            <Modal
                open={modalProfil}
                overflow={true}
                modalWidth={'container relative mx-auto px-2 sm:px-4'}
                onClose={{
                    onClick: () => {
                        document.body.style.overflow = 'unset';
                        setModalProfil(false);
                        user.refetch();
                    },
                }}
            >
                <FormProfil />

                <div className="sticky bottom-4 sm:bottom-0 z-[999999]">
                    <div className="flex items-center gap-4 justify-center">
                        <button
                            className="flex flex-row items-center gap-1 rounded-full px-4 py-1 bg-white button-border-gray border-2"
                            onClick={() => {
                                document.body.style.overflow = 'unset';
                                setModalProfil(false);
                                user.refetch();
                            }}
                        >
                            <XIcon className="h-5 w-5 p-0" />
                            <span>Fermer</span>
                        </button>
                        <ReactiveSidebarForm />
                    </div>
                </div>
            </Modal>

            <div className="sm:mt-4 -mx-2 sm:mx-0">
                {/**
                 * Bannière de profil (mobile & desktop)
                 *
                 */}
                {/* <div className="relative cover card_profile_images_cover shadow-lg shadow-lightBlueSkills rounded-b-[3rem] sm:rounded-none"> */}
                <div className="relative cover card_profile_images_cover shadow-lg shadow-lightBlueSkills rounded-b-[3rem]">
                    {user.data?.profile?.cover?.url ? (
                        <Image
                            /* className="w-full sm:rounded-xl object-cover h-36 sm:h-48 rounded-b-[3rem]" */
                            className="w-full object-cover h-36 sm:h-48 sm:rounded-t-xl rounded-b-[3rem]"
                            src={user.data?.profile?.cover?.url + sizes.cover}
                            dynamic
                            alt={user.data?.profile?.pseudo}
                        />
                    ) : (
                        <img
                            /*  className="w-full object-cover h-36 sm:h-48 rounded-b-[3rem] sm:rounded-xl" */
                            className="w-full object-cover h-36 sm:h-48 sm:rounded-t-xl rounded-b-[3rem]"
                            src={`${config.publicDir}banniere_skillsdefault2.png` + sizes.cover}
                            alt={user.data?.profile?.pseudo}
                        />
                    )}

                    <div className="hidden sm:flex absolute top-1 right-1">
                        <Share url={location.pathname} className="hover:text-blueMain rounded-xl" />
                    </div>
                </div>

                {/**
                 * Avatar (mobile & desktop) + buttons(desktop only)
                 *
                 */}
                {/* <div className="relative flex flex-row items-center justify-center sm:justify-between w-full pr-2 sm:pr-0"> */}
                <div className="relative flex flex-row items-center justify-center w-full pr-2 sm:pr-0">
                    <div className="relative -mt-20 ml-2 sm:ml-4 mb-4 w-32 h-32 sm:-mt-28 sm:w-40 sm:h-40 md:-mt-36 md:w-44 md:h-44 lg:ml-5 lg:-mt-44 lg:h-56 lg:w-56">
                        <Avatar
                            user={user.data}
                            gamification={true}
                            totalGamification={user.data?.totalGamification}
                            onClick={(e) => handleOpenAvatar(e)}
                            className="shadow-lg shadow-lightBlueSkills rounded-full w-32 sm:w-40 md:w-44 lg:w-56"
                        />

                        {authState?.me?.id === user?.data?.id && (
                            <>
                                <div className="absolute top-0 right-0 w-6 h-6 bg-blueMain rounded-full flex justify-center items-center">
                                    <Button
                                        label={<PencilIcon className="w-4 h-4 mx-auto" />}
                                        justIcon={true}
                                        css="relative inline-flex justify-center items-center w-6 h-6 text-white rounded-full bg-blueMain"
                                        onClick={handleProfil}
                                    />
                                </div>
                            </>
                        )}
                    </div>

                    {/*   <div className="hidden sm:flex flex-row flex-wrap-reverse justify-end gap-2  lg:mt-0"> */}
                    {/* <MessageForm user={user.data} />  */}
                    {/*   {user.data?.visio?.price > 0 && (
                            <button
                                className="hidden sm:flex linkCard-purpleSkills h-8 gap-2 "
                                onClick={() => authState?.me?.id !== user?.data?.id && handleCalendar()}
                            >
                                <VideoCameraIcon className="h-4 w-4" />
                                <span className="hidden md:inline">
                                    Prendre RDV {user.data?.visio?.price > 0 && <span className="font-bold">{user.data?.visio?.price}€</span>}
                                </span>
                                <span className="inline md:hidden">
                                    Réserver {user.data?.visio?.price > 0 && <span className="font-bold">{user.data?.visio?.price}€</span>}
                                </span>
                            </button>
                        )} */}
                    {/*  {authState.isLogged && (
                            <button className="linkCard-purpleSkills h-8 gap-2" onClick={() => openDiscussion()}>
                                <AnnotationIcon className="h-4 w-4" />
                                <span className="hidden sm:inline">Message</span>
                            </button>
                        )} */}
                    {/* {authState.isLogged && user.data?.profile?.phone && (
                            <a href={'tel:' + user.data.profile?.phone} target="_blank" rel="noreferrer" className="linkCard-purpleSkills h-8 gap-2">
                                <PhoneIcon className="h-4 w-4" />
                                <span className="hidden sm:inline">Appeler</span>
                            </a>
                        )} */}
                    {/*  {authState.isLogged && authState?.me?.id !== user.data?.id && (
                            <>
                                <button
                                    onClick={() => handleSubscribe()}
                                    className={`h-8 flex gap-2 ${
                                        isFoundByKey(user.data?.counters, 'user', authState?.me?.id) &&
                                        isFoundByKey(user.data?.counters, 'type', 'subscription') &&
                                        authState.isLogged
                                            ? 'linkCard red active'
                                            : 'linkCard-purpleSkills'
                                    }`}
                                >
                                    {isFoundByKey(user.data?.counters, 'user', authState?.me?.id) &&
                                    isFoundByKey(user.data?.counters, 'type', 'subscription') &&
                                    authState.isLogged ? (
                                        <>
                                            <CheckIcon className="h-4 w-4" />
                                            <span>Suivi</span>
                                        </>
                                    ) : (
                                        <>
                                            <BellIcon className="h-4 w-4" />
                                            <span>Suivre</span>
                                        </>
                                    )}
                                </button>
                            </>
                        )} */}

                    {/*  <div className="hidden sm:flex">
                            <Share url={location.pathname} className="hover:text-blueMain" />
                        </div> */}

                    {/* {authState?.me?.id !== user?.data?.id && (
                            <>
                                <div className="relative flex">
                                    <button onClick={() => handleOptionsMenu(user)} className={`text-purpleSkills hover:text-blueMain`}>
                                        <DotsHorizontalIcon className="w-6 h-6" />
                                    </button>
                                    <OptionsMenu user={user} refresh={user.refetch} type="user" />
                                </div>
                            </>
                        )} */}
                    {/*   </div> */}
                </div>

                <div className="w-full px-2 sm:px-0 break-words sm:mb-4">
                    {/* <div className="w-full px-2 sm:px-0 sm:mt-4 break-words"> */}
                    {/* <div className="flex flex-row items-center justify-center sm:justify-between gap-1"> */}

                    <div className="flex flex-row items-center justify-center gap-1">
                        <h1 className="text-center text-base leading-none font-bold sm:py-0 sm:h1 text-purpleSkills">{user.data?.profile?.pseudo}</h1>

                        {authState?.me?.id === user?.data?.id && (
                            <>
                                <div className="flex">
                                    <Button
                                        label={<PencilIcon className="w-4 h-4 sm:w-5 sm:h-5 mx-auto" />}
                                        justIcon={true}
                                        css="w-5 h-5 sm:w-7 sm:h-7 text-white rounded-full bg-blueMain"
                                        onClick={handleProfil}
                                    />
                                </div>
                            </>
                        )}
                    </div>

                    {user?.data?.profile?.status && (
                        <>
                            {/* <p className="text-graySkills text-xs sm:text-base text-center sm:text-left font-semibold">{user?.data?.profile?.status}</p> */}
                            <p className="text-graySkills text-xs sm:text-base text-center font-semibold">{user?.data?.profile?.status}</p>
                        </>
                    )}

                    {/* <div className="sm:hidden flex gap-1 items-center justify-center my-2"> */}
                    <div className="flex gap-1 items-center justify-center my-2">
                        {authState.isLogged && authState?.me?.id !== user.data?.id && (
                            <>
                                <button
                                    onClick={() => handleSubscribe()}
                                    className={`sm:hover:bg-[#f7f9ff] sm:hover:!text-purpleSkills shadow-sm shadow-lightBlueSkills sm:hover:shadow-xl sm:hover:shadow-lightBlueSkills transition-shadow duration-500 px-3 w-auto h-10 flex gap-1 rounded-xl backdrop-blur-sm bg-white text-xs font-bold text-purpleSkills items-center justify-center ${
                                        isFoundByKey(user.data?.counters, 'user', authState?.me?.id) &&
                                        isFoundByKey(user.data?.counters, 'type', 'subscription') &&
                                        authState.isLogged
                                            ? ''
                                            : ''
                                    }`}
                                >
                                    {isFoundByKey(user.data?.counters, 'user', authState?.me?.id) &&
                                    isFoundByKey(user.data?.counters, 'type', 'subscription') &&
                                    authState.isLogged ? (
                                        <>
                                            <CheckIcon className="h-4 w-4" />
                                            <span>Suivi</span>
                                        </>
                                    ) : (
                                        <>
                                            <BellIcon className="h-4 w-4" />
                                            <span>Suivre</span>
                                        </>
                                    )}
                                </button>
                            </>
                        )}

                        <button
                            className={`px-3 w-auto gap-1 h-10 rounded-xl bg-opacity-20 backdrop-blur-sm bg-graySkills text-purpleSkills flex items-center justify-center sm:hover:bg-[#f7f9ff] sm:hover:!text-purpleSkills shadow-sm shadow-lightBlueSkills sm:hover:shadow-xl sm:hover:shadow-lightBlueSkills transition-shadow duration-500`}
                            onClick={() => {
                                authState?.me?.id !== user?.data?.id && handleCalendar();
                            }}
                        >
                            <VideoCameraIcon className="h-4 w-4" />
                            <span className="inline text-sm">
                                Prendre RDV {user.data?.visio?.price > 0 && <span className="font-bold">{user.data?.visio?.price}€</span>}
                            </span>
                        </button>
                        {authState.isLogged && (
                            <button
                                className="sm:hover:bg-[#f7f9ff] sm:hover:!text-purpleSkills shadow-sm shadow-lightBlueSkills sm:hover:shadow-xl sm:hover:shadow-lightBlueSkills transition-shadow duration-500 w-10 h-10 rounded-xl bg-opacity-20 backdrop-blur-sm bg-graySkills text-purpleSkills flex items-center justify-center"
                                onClick={() => openDiscussion()}
                            >
                                <AnnotationIcon className="h-4 w-4" />
                            </button>
                        )}
                        {authState.isLogged && user.data?.profile?.phone && (
                            <a
                                href={'tel:' + user.data.profile?.phone}
                                target="_blank"
                                rel="noreferrer"
                                className="sm:hover:bg-[#f7f9ff] sm:hover:!text-purpleSkills shadow-sm shadow-lightBlueSkills sm:hover:shadow-xl sm:hover:shadow-lightBlueSkills transition-shadow duration-500 w-10 h-10 rounded-xl bg-opacity-20 backdrop-blur-sm bg-graySkills text-purpleSkills flex items-center justify-center"
                            >
                                <PhoneIcon className="h-4 w-4" />
                            </a>
                        )}

                        {authState?.me?.id !== user?.data?.id && (
                            <>
                                <div className="relative flex">
                                    <button onClick={() => handleOptionsMenu(user)} className={`text-purpleSkills hover:text-blueMain`}>
                                        <DotsVerticalIcon className="w-5 h-5" />
                                    </button>
                                    <OptionsMenu user={user} refresh={user.refetch} type="user" />
                                </div>
                            </>
                        )}

                        {/*   <div className="w-10 h-10 rounded-xl bg-opacity-20 backdrop-blur-sm bg-graySkills text-purpleSkills flex items-center justify-center">
                                <Share url={location.pathname} className="hover:text-blueMain" />
                            </div> */}

                        {/*   <div className="w-10 h-10 rounded-xl bg-opacity-20 backdrop-blur-sm bg-graySkills text-white flex items-center justify-center">
                                {user.data?.visio?.price > 0 && (
                                    
                                    <button className="sm:hidden linkCard-purpleSkills h-auto flex gap-2" onClick={() => handleCalendar()}>
                                        <CalendarIcon className="h-4 w-4" />
                                        <span className="hidden md:inline">
                                            Réserver visio <span className="font-bold">{user.data?.visio?.price ?? 0}€</span>
                                        </span>
                                        <span className="inline md:hidden">Réserver</span>
                                    </button>
                                )}
                            </div> */}
                    </div>

                    <div className="flex justify-center flex-row gap-2 sm:gap-4">
                        <div className="p-0 m-0 items-center gap-1 -mt-0.5">
                            <Rating disabled={true} value={user.data?.social?.notation} character={<StarIcon className="h-4 w-4 mt-0" />} />
                            <span className="text-xs -mt-1">{user.data?.counters_comments} avis</span>
                        </div>
                        {authState.isLogged && authState?.me?.id !== user?.data?.id && (
                            <div className="-mt-0.5">
                                <CommentForm user={user.data} />
                            </div>
                        )}
                    </div>

                    {authState.isLogged && user.data?.profile?.address && (
                        <div className="text-graySkills text-xs flex justify-center">
                            {/*    <div className="text-graySkills text-xs md:mt-2 flex justify-center sm:justify-start"> */}
                            <p className="flex justify-start">
                                <span>
                                    <LocationMarkerIcon className="w-4 h-4 mr-1 mt-0" />
                                </span>
                                {user?.data?.profile?.address && <span>{user.data?.profile?.address}</span>}
                            </p>
                        </div>
                    )}

                    <div className="text-graySkills">
                        {/* <div className="flex flex-col sm:flex-row gap-1 sm:gap-10"> */}
                        {/*  <div className="flex flex-col gap-1 sm:gap-10"> */}
                        <div className="flex flex-col gap-1">
                            <div className="flex justify-center items-center">
                                <span>
                                    <UsersIcon className="w-4 h-4 mr-1 mt-0" />
                                </span>
                                <span className="text-xs">{countByKey(user.data?.counters, 'type', 'subscription')} abonné(s)</span>
                            </div>

                            {user.data?.profile?.linkWebsite && (
                                <div className="justify-center mb-1">
                                    {/*  <div className="sm:hidden justify-center mb-1"> */}
                                    <a
                                        className="flex justify-center items-center gap-1"
                                        href={user.data?.profile?.linkWebsite}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <LinkIcon className="w-4 h-4 text-purpleSkills rounded-full hover:bg-blueMain transition" />
                                        <span className="text-purpleSkills text-sm truncate overflow-hidden whitespace-nowrap max-w-[18rem]">
                                            {user.data?.profile?.linkWebsite}
                                        </span>
                                    </a>
                                </div>
                            )}

                            {(user.data?.profile?.linkLinkedin ||
                                user.data?.profile?.linkTwitter ||
                                user.data?.profile?.linkInstagram ||
                                user.data?.profile?.linkTiktok ||
                                user.data?.profile?.linkSnapchat ||
                                user.data?.profile?.linkYoutube) && (
                                /*  <div className="flex sm:hidden justify-center flex-row gap-2"> */
                                <div className="flex justify-center flex-row gap-2">
                                    {user.data?.profile?.linkLinkedin && (
                                        <a href={user.data?.profile?.linkLinkedin} target="_blank" rel="noreferrer">
                                            <SvgLinkedin className="text-gray-300 rounded-full hover:text-blueMain transition" />
                                        </a>
                                    )}
                                    {user.data?.profile?.linkTwitter && (
                                        <a href={user.data?.profile?.linkTwitter} target="_blank" rel="noreferrer">
                                            {/*   <SvgTwitter className="text-gray-300 rounded-full hover:text-blueMain transition" /> */}

                                            <SvgX className="text-white bg-gray-300 rounded-full hover:text-blueMain transition p-1" />
                                        </a>
                                    )}
                                    {user.data?.profile?.linkInstagram && (
                                        <a href={user.data?.profile?.linkInstagram} target="_blank" rel="noreferrer">
                                            <SvgInstagram className="text-gray-300 rounded-full hover:text-blueMain transition" />
                                        </a>
                                    )}
                                    {user.data?.profile?.linkYoutube && (
                                        <a href={user.data?.profile?.linkYoutube} target="_blank" rel="noreferrer">
                                            <SvgYoutube className="text-gray-300 rounded-full hover:text-blueMain transition" />
                                        </a>
                                    )}
                                    {user.data?.profile?.linkTiktok && (
                                        <a href={user.data?.profile?.linkTiktok} target="_blank" rel="noreferrer">
                                            <SvgTiktok className="text-gray-300 rounded-full hover:text-blueMain transition" />
                                        </a>
                                    )}
                                    {user.data?.profile?.linkSnapchat && (
                                        <a href={user.data?.profile?.linkSnapchat} target="_blank" rel="noreferrer">
                                            <SvgSnapchat className="text-gray-300 rounded-full hover:text-blueMain transition" />
                                        </a>
                                    )}
                                </div>
                            )}

                            {/* <div className="flex flex-row items-center justify-between">
                                <div className="sm:hidden">
                                    <Share url={location.pathname} />
                                </div>

                                <div className="flex flex-col sm:flex-row sm:gap-4">
                                    <div className="p-0 m-0 flex items-center gap-1 -mt-0.5">
                                        <Rating disabled={true} value={user.data?.social?.notation} character={<StarIcon className="h-4 w-4 mt-0" />} />
                                        <span className="text-xs -mt-1">{user.data?.counters_comments} avis</span>
                                    </div>
                                    {authState.isLogged && authState?.me?.id !== user?.data?.id && (
                                        <div className="-mt-0.5">
                                            <CommentForm user={user.data} />
                                        </div>
                                    )}
                                </div>
                            </div> */}
                        </div>
                    </div>
                    {/* <div className="text-graySkills text-sm hidden md:inline-block">
                        <p className="flex gap-1">
                            <UserAddIcon className="h-4 w-4" />
                            Inscrit(e) <Moment date={user?.data?.createdAt} fromNow={true} />
                        </p>
                    </div> */}
                </div>
            </div>
            {/* <div className={`block sm:sticky w-full top-0 sm:top-16 z-10 pt-5 bg-transparent`}>
                <div>
                    <ForumSearchBarV2 label={`Posez lui vos questions publiquement`} userTo={user.data?.id} />
                </div>
            </div> */}
        </>
    );

    const tabPresentation = (
        <div className="mb-8">
            <>
                {/*  {isMobile() && profilContent} */}

                {services?.data && services?.data.length > 0 && (
                    <div className="w-full mt-4">
                        {services.data.length === 1 ? (
                            <div className="flex justify-center sm:justify-start">
                                {services.data.map((service, index) => {
                                    return (
                                        <div key={index} className="w-52 sm:w-64">
                                            <CardFrontService key={index} service={service} user={user.data} refetch={services.refetch} userId={user.data.id} />
                                        </div>
                                    );
                                })}
                            </div>
                        ) : (
                            <div className="flex flex-col">
                                <div className="grid grid-cols-2 md:grid-cols-3 gap-1 w-full">
                                    {services.data.slice(0, displayedServices).map((service, index) => (
                                        <CardFrontService key={index} service={service} user={user.data} refetch={services.refetch} userId={user.data.id} />
                                    ))}
                                </div>
                                <div className="mt-4 w-full">
                                    <div className="flex justify-end text-purpleSkills font-bold text-sm">
                                        <button
                                            onClick={() => {
                                                toggleShowAll();
                                            }}
                                        >
                                            {showAll ? 'Voir moins' : 'Voir plus ...'}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                )}
                {userShoots?.data && userShoots?.data?.length > 0 && (
                    <>
                        {userShoots?.data?.length === 1 ? (
                            <>
                                <div className={`w-full mt-4 flex justify-center sm:justify-start`}>
                                    {userShoots?.data.map((shoot, index) => (
                                        <div key={index} className="relative h-52" onClick={(e) => handleClick(e, shoot)}>
                                            <div className="h-52 w-36 rounded-lg shadow overflow-hidden">
                                                <PlayerVideo
                                                    id={shoot._id}
                                                    width={'100%'}
                                                    shoot={shoot}
                                                    muted={false}
                                                    controls={false}
                                                    light={`${config.serverUrl}/downloads/videos/${shoot.user.id}/screenshot_${shoot.video._id}_${shoot.selectedScreen}.jpg`}
                                                />
                                                <div className="absolute top-5 right-2">
                                                    <div className="flex flex-col gap-1 items-center">
                                                        <Button
                                                            onClick={(e) => {
                                                                e?.preventDefault();
                                                                e?.stopPropagation();
                                                                handleOptionsShootMenu(shoot);
                                                            }}
                                                            label={<DotsVerticalIcon className="w-6 h-6" />}
                                                            justIcon={true}
                                                            css="text-white"
                                                        />
                                                        <OptionsMenu shoot={shoot} user={shoot.user} refresh={userShoots.refetch} path="profil" type="shoot" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </>
                        ) : (
                            <>
                                <div className={`w-full grid grid-cols-3 sm:grid-cols-4 gap-1`}>
                                    {userShoots?.data.map((shoot, index) => (
                                        <div key={index} className="relative h-52 py-4 mb-4" onClick={(e) => handleClick(e, shoot)}>
                                            <div className="h-52 w-auto rounded-lg shadow overflow-hidden">
                                                <PlayerVideo
                                                    id={shoot._id}
                                                    width={'100%'}
                                                    shoot={shoot}
                                                    muted={false}
                                                    controls={false}
                                                    light={`${config.serverUrl}/downloads/videos/${shoot.user.id}/screenshot_${shoot.video._id}_${shoot.selectedScreen}.jpg`}
                                                />
                                                <div className="absolute top-5 right-2">
                                                    <div className="flex flex-col gap-1 items-center">
                                                        <Button
                                                            onClick={(e) => {
                                                                e?.preventDefault();
                                                                e?.stopPropagation();
                                                                handleOptionsShootMenu(shoot);
                                                            }}
                                                            label={<DotsVerticalIcon className="w-6 h-6" />}
                                                            justIcon={true}
                                                            css="text-white"
                                                        />
                                                        <OptionsMenu shoot={shoot} user={shoot.user} refresh={userShoots.refetch} path="profil" type="shoot" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                <div className="mt-4 mb-2 flex justify-end text-purpleSkills font-bold text-sm">
                                    <button
                                        onClick={() => {
                                            setSwitchTab('tab_shoots');
                                        }}
                                    >
                                        Voir plus ...
                                    </button>
                                </div>
                            </>
                        )}
                    </>
                )}

                <div className="mt-4 flex flex-row items-center justify-center w-full gap-2 text-graySkills relative">
                    <div className="flex-auto h-[1px] bg-gray-200"></div>
                    <h2 className="text-gray-400 text-xl font-bold">Bio</h2>
                    <div className="flex-auto h-[1px] bg-gray-200"></div>
                </div>

                <div className="mx-2 sm:px-0">
                    <CardUserSmall banner={false} gamification={false} user={user?.data} />
                </div>

                <div className="mt-4 flex flex-col md:flex-row gap-4">
                    {(user.data?.profile?.video || user.data?.profile?.presentationVideo?.url) && (
                        <div className="py-2 aspect-video relative md:mt-1 w-full md:w-5/12">
                            <ReactPlayer
                                url={`${
                                    user.data?.profile?.presentationVideo?.url
                                        ? config.serverUrl + user.data?.profile?.presentationVideo?.url
                                        : user.data?.profile?.video
                                }`}
                                width="100%"
                                height="100%"
                                origin={window.location.origin}
                                className="absolute top-0 left-0 w-full h-full rounded-lg overflow-hidden aspect-video"
                                pip
                                controls
                            />
                        </div>
                    )}

                    <div className={`w-full ${user.data?.profile?.video ? 'md:w-7/12' : ''}`}>
                        {(user.data?.profile?.linkWebsite ||
                            user.data?.profile?.linkLinkedin ||
                            user.data?.profile?.linkTwitter ||
                            user.data?.profile?.linkInstagram ||
                            user.data?.profile?.linkYoutube) && (
                            <div className="hidden sm:block">
                                <h2 className="text-gray-500 text-sm font-bold mb-4">Réseaux sociaux</h2>
                                <div className="flex flex-row gap-2">
                                    {user.data?.profile?.linkWebsite && (
                                        <a href={user.data?.profile?.linkWebsite} target="_blank" rel="noreferrer">
                                            <GlobeIcon className="w-6 h-6 text-white bg-gray-400 rounded-full hover:bg-blueMain transition" />
                                        </a>
                                    )}
                                    {user.data?.profile?.linkLinkedin && (
                                        <a href={user.data?.profile?.linkLinkedin} target="_blank" rel="noreferrer">
                                            <SvgLinkedin className="text-graySkills rounded-full hover:text-blueMain transition" />
                                        </a>
                                    )}
                                    {user.data?.profile?.linkTwitter && (
                                        <a href={user.data?.profile?.linkTwitter} target="_blank" rel="noreferrer">
                                            <SvgTwitter className="text-graySkills rounded-full hover:text-blueMain transition" />
                                        </a>
                                    )}
                                    {user.data?.profile?.linkInstagram && (
                                        <a href={user.data?.profile?.linkInstagram} target="_blank" rel="noreferrer">
                                            <SvgInstagram className="text-graySkills rounded-full hover:text-blueMain transition" />
                                        </a>
                                    )}
                                    {user.data?.profile?.linkYoutube && (
                                        <a href={user.data?.profile?.linkYoutube} target="_blank" rel="noreferrer">
                                            <SvgYoutube className="text-graySkills rounded-full hover:text-blueMain transition" />
                                        </a>
                                    )}
                                    {user.data?.profile?.linkTiktok && (
                                        <a href={user.data?.profile?.linkTiktok} target="_blank" rel="noreferrer">
                                            <SvgTiktok className="text-graySkills rounded-full hover:text-blueMain transition" />
                                        </a>
                                    )}
                                    {user.data?.profile?.linkSnapchat && (
                                        <a href={user.data?.profile?.linkSnapchat} target="_blank" rel="noreferrer">
                                            <SvgSnapchat className="text-graySkills rounded-full hover:text-blueMain transition" />
                                        </a>
                                    )}
                                </div>
                            </div>
                        )}

                        {/**
                         * Tags skills profile
                         *
                         */}
                        <div className="mt-4">
                            {/* <h2 className="text-gray-500 text-sm font-bold mt-4 mb-2">Skills</h2> */}
                            <div className={`flex flex-row flex-wrap gap-1 -mx-2`} ref={skillsRef}>
                                {user.data?.profile?.tags.map((skill, index) => (
                                    <Link to={`/search?q=${skill.name}`} key={index} className={`link_basic ${skill.selected && 'active'}`}>
                                        {skill.name}
                                    </Link>
                                ))}
                            </div>
                        </div>

                        {rowCount > 1 && (
                            <div className="flex justify-end text-purpleSkills font-bold text-sm">
                                <button
                                    onClick={() => {
                                        setShowAllRows(!showAllRows);
                                    }}
                                >
                                    {showAllRows ? 'Voir moins' : 'Voir plus ...'}
                                </button>
                            </div>
                        )}
                    </div>
                </div>

                {/**
                 * Présentation profile
                 *
                 */}
                <div className="mt-2 content whitespace-pre-wrap break-words text-sm text-gray-800">
                    <HeadlessEdit content={user.data?.profile.presentation}>
                        <SeeMore height={'h-[13.9rem]'} className={'text-sm leading-relaxed'} string={user.data?.profile?.presentation} />
                        {/*  <div className="" dangerouslySetInnerHTML={{ __html: user.data?.profile.presentation }} /> */}
                    </HeadlessEdit>
                </div>

                {/**
                 * Portfolio Médias
                 *
                 */}
                {user?.data?.profile?.medias?.length > 0 && (
                    <div className="w-full m-auto xl:container mt-4">
                        {/*   <h2 className="mb-4 text-purpleSkills text-xl font-bold">Portfolio</h2> */}
                        <div className="flex w-full center flex-col">
                            <div className="w-full grid grid-cols-3 sm:grid-cols-4 gap-1">
                                {user?.data?.profile?.medias
                                    ?.slice(0, showAllPortfolio ? user?.data?.profile?.medias?.length : isSmartphone() ? 6 : 8)
                                    .map((media, index) => (
                                        <div key={index}>
                                            <CardPortfolio key={index} media={media} user={user?.data} />
                                        </div>
                                    ))}
                            </div>
                            {user?.data?.profile?.medias?.length > (isSmartphone() ? 6 : 8) && (
                                <div className="mt-2 container">
                                    <div className="flex justify-end text-purpleSkills font-bold text-sm">
                                        <button
                                            onClick={() => {
                                                setShowAllPortfolio(!showAllPortfolio);
                                            }}
                                        >
                                            {showAllPortfolio ? 'Voir moins' : `Voir plus (${user?.data?.profile?.medias?.length - isSmartphone() ? 6 : 8})`}
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )}

                {comments?.data?.length > 0 && (
                    <div className="w-full m-auto xl:container mt-4 mb-4">
                        <div className="mb-2 flex flex-row items-center justify-center w-full gap-2 text-graySkills relative">
                            <div className="flex-auto h-[1px] bg-gray-200"></div>
                            <h2 className="text-gray-400 text-xl font-bold">Avis</h2>
                            <div className="flex-auto h-[1px] bg-gray-200"></div>
                        </div>

                        <div className="hover:bg-white cursor-pointer rounded-3xl w-full">
                            <Slider
                                className="standard-slider"
                                infinite
                                autoplay
                                arrows={false}
                                autoplaySpeed={5000}
                                adaptiveHeight
                                speed={800}
                                slidesToShow={1}
                                slidesToScroll={1}
                            >
                                {comments?.data?.map((comment, index) => (
                                    <Link
                                        key={index}
                                        to={'/profil/' + comment.userFrom?.profile?.slug}
                                        title={`Profil de ${comment.userFrom?.profile?.pseudo}`}
                                    >
                                        <div key={index} className="p-0">
                                            <div className="flex flex-col justify-start gap-2">
                                                <div className="mr-0">
                                                    <div className="flex flex-row items-center">
                                                        {comment.userFrom?.profile?.avatar?.url ? (
                                                            <Image
                                                                src={comment.userFrom?.profile?.avatar?.url + sizes.avatar}
                                                                alt={comment.userFrom?.profile?.pseudo}
                                                                loading="false"
                                                                className="w-10 h-10 rounded-full shadow-xl mr-2"
                                                            />
                                                        ) : (
                                                            <img
                                                                className="w-32 rounded-full  border-4 border-white"
                                                                src={`${config.publicDir}skillsLogo.jpg` + sizes.avatar}
                                                                alt={comment.userFrom?.profile?.pseudo}
                                                            />
                                                        )}
                                                        <div className="flex flex-col gap-1">
                                                            <span className="text-sm text-purpleSkills font-bold pt-2">
                                                                {comment.userFrom?.profile?.pseudo}
                                                            </span>
                                                            <Rating
                                                                className="relative -top-2"
                                                                disabled={true}
                                                                value={comment.note}
                                                                character={<StarIcon className="h-3.5 w-3.5" />}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="text-sm text-[#000a53]">
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: comment.message,
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                ))}
                            </Slider>
                        </div>
                    </div>
                )}

                <div className="mt-4">
                    <Cv userData={user.data} />
                </div>

                {userRooms?.data && userRooms?.data?.length > 0 && (
                    <div className={`w-full mt-4`}>
                        <div className="mb-2 flex flex-row items-center justify-center w-full gap-2 text-graySkills relative">
                            <div className="flex-auto h-[1px] bg-gray-200"></div>
                            <h2 className="text-gray-400 text-xl font-bold">Derniers Posts</h2>
                            <div className="flex-auto h-[1px] bg-gray-200"></div>
                        </div>
                        {userRooms?.data.map((room, index) => (
                            <div key={index} className="relative">
                                <div className="w-full">
                                    <CardFrontRoom
                                        room={room}
                                        refetch={userRooms.refetch}
                                        profile={user.data}
                                        userId={user.data.id}
                                        listingQueryKeys={['userRooms']}
                                    />
                                </div>
                            </div>
                        ))}
                        <div className="mt-4 mb-2 flex justify-end text-purpleSkills font-bold text-sm">
                            <button
                                onClick={() => {
                                    setSwitchTab('tab_rooms');
                                }}
                            >
                                Voir plus ...
                            </button>
                        </div>
                    </div>
                )}

                <div className="w-full m-auto xl:container mb-4 mt-4">
                    <h2 className="mb-4 text-purpleSkills text-xl text-center sm:text-left font-bold">Profils similaires</h2>
                    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 auto-cols-max gap-x-2 sm:gap-4" style={{ maxWidth: '100%' }}>
                        {users?.data?.map((user, key) => (
                            <div className="mt-0 sm:pb-0 card card_profile w-full h-full" key={key}>
                                <CardFrontProfil user={user} refetch={users.refetch} />
                            </div>
                        ))}
                    </div>
                </div>

                {infiniteUsersMatchMaking}
                {interestsSkillers}
            </>
        </div>
    );

    const tabShoots = (
        <>
            <div className="mt-10 grid grid-cols-3 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-2">{infiniteShoots ?? null}</div>
        </>
    );

    const tabRooms = (
        <>
            {/* <div className={`${isMobile() ? 'block' : 'hidden'} w-full top-0 sm:top-16 z-10 pt-5 bg-transparent`}>
                <div>
                    <ForumSearchBarV2 label={`Posez lui vos questions publiquement`} userTo={user.data?.id} />
                </div>
            </div> */}

            <div className="w-full mt-10">
                {infiniteRooms ?? null}
                {/*   {rooms.data.docs.map(function (room, index) {
                            return (
                                <div className="w-full" key={index}>
                                    <CardFrontRoom
                                        room={room}
                                        refetch={rooms.refetch}
                                        profile={user.data}
                                        userId={user.data.id}
                                        listingQueryKeys={['userRooms']}
                                    />
                                </div>
                            );
                        })}
                        {rooms.data?.docs?.length <= 0 && <p className="text-center text-gray-500">Pas de posts pour le moment</p>} */}
            </div>
        </>
    );

    const labelTabs = (label) => {
        return (
            <>
                {label}

                {!isMobile() && (
                    <>
                        {label === 'Présentation' && <span className="absolute right-0 top-3">{actionModifProfil}</span>}
                        {label === 'Services' && <span className="absolute right-0 top-3">{actionAddService}</span>}
                    </>
                )}
            </>
        );
    };

    const tabs = [
        { label: labelTabs('Présentation'), key: 'tab_presentation', content: tabPresentation },
        { label: 'Shoots', key: 'tab_shoots', content: tabShoots, visible: hasShoot ? true : false },
        { label: 'Posts', key: 'tab_rooms', content: tabRooms, visible: hasRoom ? true : false },
    ];

    /*
        useEffect(() => {
            if (users?.data?.length > 0) {
                //store.set({type: 'ready'});
                store.set({ type: 'add', key: 'navbar_subjects', value: users.data });

                const sectionSimilarSkillers = (
                    <>
                        <div className="w-full m-auto xl:container mb-4">
                            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 auto-cols-max gap-x-2 sm:gap-4" style={{ maxWidth: '100%' }}>
                                {users?.data?.map((user, key) => (
                                    <div className="mt-0 sm:pb-0 card card_profile w-full h-full" key={key}>
                                        <CardFrontProfil
                                            user={user}
                                            refetch={users.refetch}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </>
                );
                setSections([
                    {
                        key: 'similarUsers',
                        title: 'Skillers similaires',
                        content: sectionSimilarSkillers,
                    },
                ]);
            } else {
                setSections([]);
            }
            //eslint-disable-next-line
        }, [users.data]);
    */

    return (
        <ProfilLayout>
            {user?.isSuccess && user?.data && (
                <SEO
                    title={'Profil de ' + user.data?.profile?.pseudo}
                    description={user.data?.profile?.presentation}
                    name={user.data?.profile?.pseudo}
                    type="summary_large_image"
                    image={user.data?.profile?.avatar?.url}
                />
            )}

            <Modal
                overflow={true}
                open={modalCalendar}
                title={
                    <div className="inline-flex gap-2">
                        <span>Vos préférences pour le RDV</span>
                        <VideoCameraIcon className="h-6 w-6 mt-0.5" />
                    </div>
                }
                titleCenter={true}
                onClose={{
                    onClick: () => setModalCalendar(false),
                }}
                onConfirm={{
                    label: 'Suivant',
                    onClick: () => handleReservation(),
                }}
            >
                <Reservation ref={refReservation} user={user.data} onClose={() => setModalCalendar(false)} />
            </Modal>
            <Modal
                modalWidth={'w-screen h-screen overflow-y-hidden sm:overflow-y-auto lg:overflow-y-hidden relative'}
                open={openShoot}
                title={false}
                fullScreen={true}
                noMaxWidth={true}
            >
                <div className="w-full ">
                    <div className="w-full h-full sm:flex sm:flex-col lg:flex-row">
                        <div className={`relative lg:w-screen lg:max-w-full bg-black`}>
                            <div ref={shootRef} className={`relative ${currentShoot.video?.position === 'landscape' ? 'h-screen w-auto' : 'h-screen w-auto'}`}>
                                <PlayerVideo
                                    id={currentShoot._id}
                                    width={'100%'}
                                    videoFitCover={false}
                                    played={playedShoots[currentShoot._id]}
                                    shoot={currentShoot}
                                    muted={false}
                                    controls={true}
                                />
                                <div className="absolute top-2 left-5 flex flex-col gap-10">
                                    <div className="flex flex-col gap-1 items-center">
                                        <Button label={<XCircleIcon className="w-10 h-10 text-white" />} justIcon={true} onClick={(e) => handleClose(e)} />
                                    </div>
                                </div>
                                <div className="absolute bottom-52 right-5 flex flex-col gap-10">
                                    {currentShoot && (
                                        <>
                                            <div className="flex flex-col gap-1 items-center">
                                                <Button
                                                    label={<EyeIcon className="w-6 h-6 mx-auto" />}
                                                    justIcon={true}
                                                    css="w-12 h-12 bg-white border border-graySkills rounded-full text-graySkills"
                                                />
                                                <span className="text-xs text-graySkills">{currentShoot?.views}</span>
                                            </div>
                                            <div className="flex flex-col gap-1 items-center">
                                                <Button
                                                    label={<HeartIcon className="w-6 h-6 mx-auto" />}
                                                    justIcon={true}
                                                    css={`w-12 h-12 border rounded-full ${
                                                        hasCounter ? 'text-white border-red bg-red' : 'text-graySkills border-graySkills bg-white'
                                                    }`}
                                                    // css="w-12 h-12 bg-white border border-graySkills rounded-full text-graySkills"
                                                    onClick={() => handleLikeShoot()}
                                                />
                                                <span className={`text-graySkills`}>{likeCounterCount}</span>
                                            </div>
                                            <div className="flex flex-col gap-1 items-center">
                                                <Button
                                                    label={<BookmarkIcon className="w-6 h-6 mx-auto" />}
                                                    justIcon={true}
                                                    css={`w-12 h-12 border rounded-full ${
                                                        hasBookmark
                                                            ? 'text-white border-purpleSkills bg-purpleSkills'
                                                            : 'text-graySkills border-graySkills bg-white'
                                                    }`}
                                                    onClick={() => handleBookmarkShoot()}
                                                />
                                                <span className={`text-graySkills`}>{bookmarkCounterCount}</span>
                                            </div>
                                        </>
                                    )}
                                    <div className="flex flex-col gap-1 items-center">
                                        <Button
                                            label={<ArrowUpIcon className="w-6 h-6 mx-auto" />}
                                            justIcon={true}
                                            css="w-12 h-12 bg-white border border-graySkills rounded-full text-graySkills"
                                            onClick={() => handlePrev()}
                                        />
                                    </div>
                                    <div className="flex flex-col gap-1 items-center">
                                        <Button
                                            label={<ArrowDownIcon className="w-6 h-6 mx-auto" />}
                                            justIcon={true}
                                            css="w-12 h-12 bg-white border border-graySkills rounded-full text-graySkills"
                                            onClick={() => {
                                                handleNext();
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="hidden relative sm:p-2 w-full lg:w-[600px] max-h-screen sm:flex flex-col">
                            {shoot.status === 'success' && shoot.data.current && (
                                <div className="flex flex-col w-full h-full">
                                    <div className="flex flex-col w-full">
                                        <CardProfilShoot user={shoot.data.current.user} shoot={shoot.data.current} />
                                        <div className="px-2 py-2 w-full bg-white md:bg-transparent bottom-2 z-10">
                                            <AnswerForm
                                                item={shoot.data.current}
                                                type={'shoot'}
                                                refetch={refetchShoot}
                                                options={{ placeholder: null, isShoot: true }}
                                            />
                                        </div>
                                    </div>
                                    {currentShoot?.answers && (
                                        <Answers item={shoot.data.current} counters={shoot.data.current?.counters} type={'shoot'} refetch={refetchShoot} />
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </Modal>
            {location.state?.shareModal === true && shareModal && (
                <ShareOptions url={location.pathname} title={'Wow 😍 ! Partage ta publication !'} isOpen={shareModal} onClose={(e) => setShareModal(false)} />
            )}
            <div className="container">
                <div className="grid grid-cols-12 gap-7 sm:pb-4 flex-col-reverse md:flex-col">
                    <div className="hidden sm:block col-span-12 md:col-span-3 order-last md:order-first">
                        <SideNav sections={[]} />
                    </div>
                    <PageContentFadingAnimation durations={250} className="col-span-12 md:col-span-9">
                        <main>
                            {profilContent}
                            <TabsProfil
                                tabs={tabs}
                                visibleTabs={hasShoot === false && hasRoom === false ? false : true}
                                idFirst="tab_presentation"
                                switchTab={switchTab}
                                handleSwitchTab={(tab) => handleSwitchTab(tab)}
                            />

                            {/* <div className="mt-4"> */}
                            {/*     <AdSenseIns block="insideContent" /> */}
                            {/* </div> */}
                        </main>
                    </PageContentFadingAnimation>
                </div>
            </div>
        </ProfilLayout>
    );
}

export default SingleProfil;
