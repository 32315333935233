import { Fragment, useCallback, useEffect, useState } from 'react';
import { useSpring, animated } from 'react-spring';
//UTILS
import InAllLayouts from 'pages/layouts/InAllLayouts';
import config from 'utils/constants';
//HOOKS
import useScroll from 'hooks/useScroll';

//COMPONENTS
import MainNavSearch from 'components/common/MainNavSearch';
import MainNavMobile from 'components/common/MainNavMobile';

//CONTAINERS
//import ForumSearchBarV2 from 'containers/searchBar/ForumSearchBarV2';

//LIBS
import { Link, useLocation } from 'react-router-dom';
import Banner from 'components/common/Banner';

//CONTEXTS
import { AuthContext } from 'context/authContext';
import { useContext } from 'react';
import { isMobile, isSmartphone } from '../../utils/functions';
import { UserIcon } from '@heroicons/react/solid';
import Image from '../../components/common/Image';
import MainNavUser from '../../components/common/MainNavUser';

function ShootLayout({ children }) {
    //HOOKS
    const [openedUserMenu, setOpenedUserMenu] = useState(false);
    const location = useLocation();
    const scrollDirection = useScroll();

    //const navigate = useNavigate();

    const handleOpenUserMenu = useCallback(() => {
        setOpenedUserMenu(openedUserMenu ? false : true);
    }, [openedUserMenu]);

    useEffect(() => {
        if (openedUserMenu) document.querySelector('body').style.overflow = 'hidden';
        else document.querySelector('body').style.overflow = 'auto';
    }, [openedUserMenu]);

    const openCloseSlideAnimationContent = useSpring({
        transform: openedUserMenu ? `translateX(16rem)` : 'translateX(0rem)',
        config: {
            duration: 225,
        },
    });

    const [authState] = useContext(AuthContext);

    /* const handleGoToCloneAI = useCallback(() => {
        navigate('/dashboard/clone-ai');
    }, [navigate]); */

    return (
        <Fragment>
            <InAllLayouts />
            <MainNavUser handleOpenUserMenu={handleOpenUserMenu} isOpen={openedUserMenu} />
            {/*   MENU DESKTOP */}
            <div className={`hidden sm:block sticky z-20 h-16 max-h-16 container-fluid w-full mx-auto px-4 bg-white top-0`}>
                <MainNavSearch />
            </div>
            <animated.div style={openCloseSlideAnimationContent} className="flex flex-col min-h-screen container-fluid">
                <div className="flex-grow">
                    {/* MOBILE */}
                    {isSmartphone() && (
                        <div className="asbolute top-0 left-0 right-0 flex flex-row text-center h-auto p-2">
                            <div className={`block z-20`}>
                                {authState.isLogged ? (
                                    <button id="button-profil" onClick={handleOpenUserMenu} className="">
                                        <div className="text-purpleSkills font-semibold text-sm flex flex-col items-center w-auto">
                                            <div>
                                                {authState.isLogged ? (
                                                    authState.me?.profile?.avatar?.url ? (
                                                        <Image
                                                            src={authState.me?.profile?.avatar?.url}
                                                            alt={authState.me?.profile?.pseudo}
                                                            loading="false"
                                                            className="rounded-full w-8 h-8"
                                                        />
                                                    ) : (
                                                        <img
                                                            className="rounded-full w-8 h-8"
                                                            src={`${config.publicDir}skillsLogo.jpg`}
                                                            alt={authState.me?.profile?.pseudo}
                                                        />
                                                    )
                                                ) : (
                                                    <UserIcon className="block w-6 h-6" />
                                                )}
                                            </div>
                                        </div>
                                    </button>
                                ) : (
                                    <Link to={isMobile() ? '/home' : '/'}>
                                        <img src={`${config.publicDir}logo_blanc.png`} alt="logo" className="h-8 w-auto" />
                                    </Link>
                                )}
                            </div>
                        </div>
                    )}

                    <div className="container relative mx-auto sm:px-4">
                        <div className="w-full h-full">{children}</div>
                    </div>
                </div>
                {!authState.isLogged && (
                    <>
                        <div
                            className={`z-20 sticky sm:bottom-0 transition-all duration-300 ease-out ${
                                scrollDirection === 'down' && !location.pathname?.includes('shoots') ? '-bottom-[0px]' : 'bottom-[68px]'
                            }`}
                        >
                            <Banner />
                        </div>
                    </>
                )}
                <MainNavMobile />
            </animated.div>
            {/*  {authState.isLogged && (
                <button onClick={handleGoToCloneAI} className={`sm:hidden z-10 fixed bottom-20 right-4 w-12 cursor-pointer`}>
                    <img src={`${config.publicDir}logo_ai_mobile.svg`} alt="ai_logo" />
                </button>
            )} */}
        </Fragment>
    );
}

export default ShootLayout;
