// import PseudoOption from "components/forms/options/pseudo/PseudoOption";
import AddressOption from "components/forms/options/address/AddressOption";
import VideoOption from "components/forms/options/video/VideoOption";

const profile = {
    // pseudo: {
    //     element: {
    //         jsx: <PseudoOption />,
    //         requiredFields: ["pseudoOption"],
    //         props: {
    //             dispatchEvent: "onChangeOptionPseudo",
    //         },
    //     },
    //     actions: [
    //         {
    //             label: "Valider",
    //             onClick: "onValidateOption",
    //             closeOnTrue: true,
    //         },
    //     ],
    // },
    address: {
        openOnFocus: true,
        element: {
            jsx: <AddressOption />,
            requiredFields: ["point"],
            props: {
                dispatchEvent: "onChangeOptionAddress",
                zoom: 13,
            },
        },
        actions: [
            {
                label: "Valider",
                onClick: "onValidateOption",
                closeOnTrue: true,
            },
        ],
    },
    video: {
        element: {
            jsx: <VideoOption />,
            props: {
                dispatchEvent: "onChangeOption",
            },
        },
        actions: [
            {
                label: "Valider",
                onClick: "onValidateOption",
                closeOnTrue: true,
            },
        ],
    },
};

export default profile;
