import { useEffect, useState, useRef } from 'react';

//LIBS
import _ from 'lodash';
import ReactTags from 'react-tag-autocomplete';

//ICONS
import { XIcon } from '@heroicons/react/solid';

//API
import { getCountUsedTag } from 'api/search';

/* function capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
} */

function Tags(props) {
    const [tags, setTags] = useState(props.initValue);
    const [mainTag, setMainTag] = useState('');
    // const [suggestions] = useState([
    //     { id: 1, name: "Php" },
    //     { id: 2, name: "Css" },
    // ]);
    useEffect(() => {
        setTags(props.initValue);
        onChange(props.initValue);
        if (props.countUsedTag) {
            _.each(props.initValue, (tag, k) => {
                countUsedTag(props.initValue, tag);
            });
        }
        //eslint-disable-next-line
    }, [props.readyToWatch]);

    useEffect(() => {
        if (tags.length > 0) {
            selectFirstTag();
        }
        //eslint-disable-next-line
    }, [tags]);

    //REFS
    const reactTags = useRef(null);

    //FUNCTIONS
    function onChange(newTags) {
        props.onChange(newTags);

        if (props.selected) {
            newTags.map((tag) => {
                if (tag.selected) {
                    setMainTag(tag);
                }
                return null;
            });
        }
    }

    function onDelete(i) {
        const newTags = tags.slice(0);
        newTags.splice(i, 1);
        setTags(newTags);
        onChange(newTags);
        selectFirstTag();
    }

    function selectFirstTag() {
        if (props.selected) {
            let selected = false;
            tags.map((item, i) => {
                if (item.selected) {
                    selected = true;
                }
                return null;
            });
            if (!selected) {
                if (tags.length > 0) tags[0].selected = true;
            }
            reactTags?.current?.forceUpdate();
        }
    }

    function handleSelected(tag) {
        if (props.selected) {
            tags.map((item, i) => {
                if (item.name === tag.name) return (tags[i].selected = !tags[i].selected);
                return (tags[i].selected = false);
            });
            setTags(tags);
            onChange(tags);
            selectFirstTag();
        }
    }

    function onAddition(tag) {
        const newTags = [...tags, { name: tag.name, selected: false, total: 0 }];

        setTags(newTags);
        onChange(newTags);
        selectFirstTag();
        countUsedTag(newTags, tag);
    }

    function onValidate(tag) {
        return (props.limit && props.limit > 0 && tags.length < props.limit) || !props.limit ? true : false;
    }

    function countUsedTag(newTags, tag) {
        if (props.countUsedTag) {
            getCountUsedTag(tag.name).then((res) => {
                newTags.map((item, i) => {
                    if (item.name === tag.name) return (newTags[i].total = res.total);
                    return newTags[i];
                });
                setTags(newTags);
                reactTags?.current?.forceUpdate();
            });
        }
    }

    function TagComponent({ tag, removeButtonText, onDelete }) {
        return (
            <button
                type="button"
                title={`${removeButtonText}: ${tag.name}`}
                className={`${
                    tag.selected && props.selected ? 'text-purpleSkills font-bold' : props.type === 'ai' ? 'text-white' : 'text-mainText'
                } rounded-lg my-1 text-sm`}
            >
                <span className="flex flex-row items-center">
                    <span onClick={() => handleSelected(tag)}>
                        <>
                            {tag.name}
                            {tag.total > 0 && <span className="ml-1 text-xs">({tag.total})</span>}
                        </>
                    </span>
                    <span className="flex justify-center items-center rounded-full w-4 h-4" onClick={onDelete}>
                        <XIcon className={`w-3 h-3 ${props.type === 'ai' ? 'text-white hover:text-black' : 'text-purpleSkills hover:text-primary'}`} />
                    </span>
                </span>
            </button>
        );
    }

    return (
        <>
            <div className="w-full">
                <div className={`w-full relative`}>
                    <div>
                        {/*    {props.errors && props.errors[props.name] && (
                            <>
                                <label className={`input_label ${props.errors && props.errors[props.name] && 'input_error'}`} htmlFor={`input_${props.name}`}>
                                    {props.label ? capitalize(props.label) : capitalize(props.name)}
                                </label>
                            </>
                        )} */}

                        <div className={`${props.type === 'ai' ? 'input_ai input_ai_tags' : `${props.className ? props.className : 'input input_tags'}`}`}>
                            <ReactTags
                                ref={reactTags}
                                tags={tags}
                                suggestions={props.suggestions}
                                allowNew={true}
                                placeholderText={props.placeholder || "Insérez votre tag puis sur Entrée ou virgule pour l'ajouter"}
                                // newTagText="Ajouter le tag:"
                                onDelete={onDelete}
                                onAddition={onAddition}
                                onValidate={onValidate}
                                tagComponent={TagComponent}
                                delimiters={['Enter', 'Tab', ',']}
                                maxSuggestionsLength={2}
                            />
                        </div>
                    </div>

                    {props.errors && props.errors[props.name] && (
                        <span className={`mt-0 text-sm ${props.textErrorColor ? props.textErrorColor : 'text-danger-400'}`}>
                            {props.errors[props.name].message}
                        </span>
                    )}
                </div>
                {props.selected && (
                    <div className="w-full">
                        <div className="flex w-full gap-2">
                            <span className="text-graySkills text-sm">
                                Tag principal:
                                <span className="rounded-lg my-1 p-1 px-2 text-purpleSkills text-sm font-bold">{`${
                                    mainTag.selected ? mainTag.name : ''
                                }`}</span>
                            </span>
                            {/*  <span className="text-graySkills text-sm">Tag principal:</span> */}
                        </div>
                        {/* <span className="text-graySkills text-sm mb-4"> */}
                        {/*     Vous serez alerté en temps réel de toutes questions, demandes ou appels d'offres référencés sur ce tag.{' '} */}
                        {/*     <Link to="/" className="text-blueMain font-bold"> */}
                        {/*         Plus de tags ? */}
                        {/*     </Link> */}
                        {/* </span> */}
                    </div>
                )}
            </div>
        </>
    );
}

export default Tags;
