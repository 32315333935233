//API
import { useQuery } from "react-query";
import { getProductsByMe } from "api/products";
//COMPONENTS
import CardProduct from "../products/CardProduct";

function ListProducts() {
    //API
    const products = useQuery("products", getProductsByMe);

    return (
        <>
            {products.isSuccess && (
                <div className="cards">
                    {products.data.map((product, index) => (
                        <CardProduct
                            product={product}
                            refetch={products.refetch}
                            key={index}
                        />
                    ))}
                </div>
            )}
        </>
    );
}

export default ListProducts;
