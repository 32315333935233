import { Fragment } from "react";
import MainNav from "components/common/MainNav";

function DefaultLayout({ children }) {
    return (
        <Fragment>
            <div className="flex flex-col min-h-screen container-fluid  ">
                <div className="flex-grow ">
                    <div className="sticky top-0 z-10 container-fluid w-full mx-auto px-0 bg-blueMain">
                        <MainNav />
                    </div>
                    <div className="container-fluid relative mx-4">
                        <div className="w-full">{children}</div>
                    </div>
                </div>

            </div>
        </Fragment>
    );
}

export default DefaultLayout;
