import { useEffect, useRef, Fragment, useContext, useState, useCallback } from 'react';

//LIBS
import { useInfiniteQuery } from 'react-query';
import ClipLoader from 'react-spinners/ClipLoader';

import SectionTitle from 'components/common/SectionTitle';

//CONTAINERS
import CardFrontNeed from '../containers/needs/CardFrontNeed';
//import CardProfilSearch from 'containers/profils/CardProfilSearch';
import CardFrontProfil from '../containers/profils/CardFrontProfil';
//CONTEXTS
import { AuthContext } from 'context/authContext';
import { SocketContext } from 'context/socketContext';
//API
import { getAllMatchingNeedsForMe, getAllMatchingUsersForMe, getAllMatchingNeedsReceived } from '../api/matchingNeeds';

//HOOKS
// import UseIntersectionObserver from 'hooks/useIntersectionObserver';

function useInfiniteMatchMaking(isNeeds, isUsers, isNeedsReceived) {
    //CONTEXTS
    const [authState] = useContext(AuthContext);
    const socket = useContext(SocketContext);
    //REFS
    const pageNeeds = useRef(0);
    const pageUsers = useRef(0);
    const pageNeedsReceived = useRef(0);

    const hasNextPageNeeds = useRef(false);
    const hasNextPageUsers = useRef(false);
    const hasNextPageNeedsReceived = useRef(false);

    const loadMoreNeedsButtonRef = useRef();
    const loadMoreUsersButtonRef = useRef();
    const loadMoreNeedsReceivedReButtonRef = useRef();

    //USESTATES
    const [tab, setTab] = useState(null);

    const NeedsReceived = useInfiniteQuery(
        ['infiniteNeedsReceived', authState?.isLogged],
        ({ pageParam = 0 }) => getAllMatchingNeedsReceived(`?p=${pageParam}&l=4`, authState?.isLogged),
        {
            getNextPageParam: (lastPage) => {
                if (lastPage.hasNextPage) {
                    hasNextPageNeedsReceived.current = lastPage.hasNextPage;
                    return (pageNeedsReceived.current = lastPage.page);
                } else {
                    hasNextPageNeedsReceived.current = false;
                    pageNeedsReceived.current = 0;
                }
                return;
            },
            enabled: isNeedsReceived && authState?.isLogged,
        }
    );

    const NeedsMatch = useInfiniteQuery(
        ['infiniteNeedsMatch', authState?.isLogged],
        ({ pageParam = 0 }) => getAllMatchingNeedsForMe(`?p=${pageParam}&l=15`, authState?.isLogged),
        {
            getNextPageParam: (lastPage) => {
                if (lastPage.hasNextPage) {
                    hasNextPageNeeds.current = lastPage.hasNextPage;
                    return (pageNeeds.current = lastPage.nextPage);
                } else {
                    hasNextPageNeeds.current = false;
                    pageNeeds.current = 0;
                }
                return;
            },
            enabled: isNeeds && authState?.isLogged,
        }
    );

    const UsersMatch = useInfiniteQuery(
        ['infiniteUsersMatch', authState?.isLogged],
        ({ pageParam = 0 }) => getAllMatchingUsersForMe(`?p=${pageParam}&l=15`, authState?.isLogged),
        {
            getNextPageParam: (lastPage) => {
                if (lastPage.hasNextPage) {
                    hasNextPageUsers.current = lastPage.hasNextPage;
                    return (pageUsers.current = lastPage.nextPage);
                } else {
                    hasNextPageUsers.current = false;
                    pageUsers.current = 0;
                }
                return;
            },
            enabled: isUsers && authState?.isLogged,
        }
    );

    useEffect(() => {
        if (authState.isLogged) {
            if (isNeeds) {
                NeedsMatch.refetch();
            } else if (isUsers) {
                UsersMatch.refetch();
            } else if (isNeedsReceived) {
                NeedsReceived.refetch();
            }
        }
        //eslint-disable-next-line
    }, [authState.isLogged]);

    //count the number of needs that have "hidden_need_forMe" === true in there counters

    const hiddenCount = NeedsMatch.data?.pages[0]?.docs.filter((need) => need.counters.every((counter) => counter.type === 'hidden_need_forMe')).length;
    const isAllHidden = hiddenCount === NeedsMatch.data?.pages[0]?.totalDocs;

    const tabGlobal = useCallback(() => {
        return (
            <>
                {isNeedsReceived && (
                    <>
                        {NeedsMatch.data?.pages && NeedsMatch.isSuccess && (
                            <div className="mt-4">
                                {NeedsReceived.data?.pages?.map((pages, index) => (
                                    <Fragment key={index}>
                                        {pages.docs.map((match, index) => {
                                            return (
                                                <CardFrontNeed
                                                    key={index}
                                                    need={match}
                                                    userId={match?.user?._id}
                                                    needRefetch={NeedsReceived.refetch}
                                                    socket={socket}
                                                    listingQueryKeys={[
                                                        'infiniteNeedsMatch',
                                                        'infiniteUsersMatch',
                                                        'infiniteTopSkillers',
                                                        'infiniteInterestsSkillers',
                                                    ]}
                                                    isNeedsReceived={true}
                                                    sharedFrom={match?.shared?.length && match?.shared[0]?.from}
                                                />
                                            );
                                        })}
                                    </Fragment>
                                ))}
                            </div>
                        )}
                        {hasNextPageNeedsReceived.current && (
                            <div className="text-center mt-4 mb-14">
                                <button
                                    ref={loadMoreNeedsReceivedReButtonRef}
                                    className="button button-secondary"
                                    onClick={NeedsReceived.fetchNextPage}
                                    disabled={!hasNextPageNeedsReceived.current || NeedsReceived.isFetchingNextPage}
                                >
                                    {NeedsReceived.isFetchingNextPage ? 'Chargement...' : 'Voir plus'}
                                </button>
                            </div>
                        )}
                        {NeedsReceived?.isLoading && (
                            <div className="flex justify-center items-center mt-12">
                                <ClipLoader color={'#0f172a'} loading={NeedsReceived.isLoading} size={35} />
                            </div>
                        )}
                        {NeedsReceived?.isError && <p className="text-center mt-8 text-sm text-graySkills">Une erreur est survenue</p>}
                    </>
                )}
                {isNeeds && (
                    <>
                        {NeedsMatch.data?.pages && (
                            <div className="mt-4">
                                <>
                                    {NeedsMatch.data?.pages && NeedsMatch?.data?.pages[0]?.totalDocs > 0 && !isAllHidden && (
                                        <SectionTitle>
                                            <p className="text-center mt-2 mb-2 font-extrabold text-lg sm:text-xl text-purpleSkills">
                                                Ils ont besoin de vous !
                                            </p>
                                        </SectionTitle>
                                    )}
                                    {NeedsMatch.data?.pages?.map((pages, index) => (
                                        <Fragment key={index}>
                                            {pages.docs.map((match, index) => {
                                                return (
                                                    <CardFrontNeed
                                                        key={index}
                                                        need={match}
                                                        userId={match.user._id}
                                                        needRefetch={NeedsMatch.refetch}
                                                        socket={socket}
                                                        listingQueryKeys={[
                                                            'infiniteNeedsMatch',
                                                            'infiniteUsersMatch',
                                                            'infiniteTopSkillers',
                                                            'infiniteInterestsSkillers',
                                                        ]}
                                                    />
                                                );
                                            })}
                                        </Fragment>
                                    ))}
                                </>
                            </div>
                        )}
                        {hasNextPageNeeds.current && (
                            <div className="text-center mt-4 mb-14">
                                <button
                                    ref={loadMoreNeedsButtonRef}
                                    className="button button-secondary"
                                    onClick={NeedsMatch.fetchNextPage}
                                    disabled={!hasNextPageNeeds.current || NeedsMatch.isFetchingNextPage}
                                >
                                    {NeedsMatch.isFetchingNextPage ? 'Chargement...' : 'Voir plus'}
                                </button>
                            </div>
                        )}
                        {NeedsMatch?.isLoading && (
                            <div className="flex justify-center items-center mt-8">
                                <ClipLoader color={'#0f172a'} loading={NeedsMatch.isLoading} size={35} />
                            </div>
                        )}
                        {/*{NeedsMatch?.data?.pages[0].totalDocs === 0 && (
                            <p className="text-center mt-16 font-extrabold text-lg sm:text-xl text-graySkills">Vous n'avez pas encore de demandes</p>
                        )}
                        {NeedsMatch?.data?.pages[0].totalDocs === 0 && <p className="text-center mt-16 font-extrabold text-lg sm:text-xl text-graySkills"></p>}*/}
                        {NeedsMatch?.isError && <p className="text-center mt-8 text-sm text-graySkills">Une erreur est survenue</p>}
                    </>
                )}
                {isUsers && (
                    <>
                        {UsersMatch.data?.pages && (
                            <div className="mt-4">
                                <>
                                    {UsersMatch?.data?.pages && UsersMatch?.data?.pages[0]?.totalDocs > 0 && (
                                        <SectionTitle>
                                            <p className="text-center mt-2 mb-2 font-extrabold text-lg sm:text-xl text-purpleSkills">
                                                Sélectionnés pour vous !
                                            </p>
                                        </SectionTitle>
                                    )}
                                    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 auto-cols-max gap-x-2 sm:gap-4" style={{ maxWidth: '100%' }}>
                                        {UsersMatch.data?.pages?.map((pages, index) => (
                                            <Fragment key={index}>
                                                {pages.docs.map((data, index) => (
                                                    <div className="mt-0 sm:pb-0 card card_profile w-full h-full" key={index}>
                                                        <CardFrontProfil
                                                            user={data.user}
                                                            refetch={UsersMatch.refetch}
                                                            listingQueryKeys={[
                                                                'infiniteUsersMatch',
                                                                'infiniteNeedsMatch',
                                                                'infiniteTopSkillers',
                                                                'infiniteInterestsSkillers',
                                                            ]}
                                                        />
                                                    </div>
                                                ))}
                                            </Fragment>
                                        ))}
                                    </div>
                                </>
                            </div>
                        )}
                        {hasNextPageUsers.current && (
                            <div className="text-center mt-4 mb-14">
                                <button
                                    ref={loadMoreUsersButtonRef}
                                    className="button button-secondary"
                                    onClick={UsersMatch.fetchNextPage}
                                    disabled={!hasNextPageUsers.current || UsersMatch.isFetchingNextPage}
                                >
                                    {UsersMatch.isFetchingNextPage ? 'Chargement...' : 'Voir plus'}
                                </button>
                            </div>
                        )}
                        {UsersMatch?.isLoading && (
                            <div className="flex justify-center items-center mt-8">
                                <ClipLoader color={'#0f172a'} loading={UsersMatch.isLoading} size={35} />
                            </div>
                        )}
                        {/*{UsersMatch?.data?.pages[0].totalDocs === 0 && <p className="text-center mt-16 font-extrabold text-lg sm:text-xl text-graySkills"></p>}*/}
                        {UsersMatch?.isError && <p className="text-center mt-8 text-sm text-graySkills">Une erreur est survenue</p>}
                    </>
                )}
            </>
        );
        //eslint-disable-next-line
    }, [NeedsMatch.data, NeedsMatch.isSuccess, UsersMatch.data, UsersMatch.isSuccess]);

    useEffect(() => {
        setTab(tabGlobal);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tabGlobal, NeedsMatch.data, UsersMatch.data, authState.isLogged]);

    return tab;
}

export default useInfiniteMatchMaking;
