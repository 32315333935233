import { useImperativeHandle, forwardRef, useState, useEffect, useContext } from 'react';

//LIBS
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

//HOOKS
import useCurrentForm from 'hooks/useForm';

//CONTEXTS
import { SocketContext } from 'context/socketContext';
import { AuthContext } from 'context/authContext';
import { FormContext } from 'context/formContext';

//API
import { createMatchingNeed } from '../../api/matchingNeeds';

//COMPONENTS
import FieldInput from 'components/forms/fields/input';
import profileOptions from 'components/forms/options/profile';
//FORMS
import schema from 'components/forms/yup/need';
import FieldFiles from 'components/forms/fields/Files';

//ICONS
import { PhotographIcon, PlayIcon } from '@heroicons/react/solid';

function FormAddNeed(props, ref) {
    //useStates
    const [readyToWatch, setReadyToWatch] = useState(false);

    //CONTEXTS
    // eslint-disable-next-line no-unused-vars
    const [authState, , refresh] = useContext(AuthContext);
    const socket = useContext(SocketContext);
    // eslint-disable-next-line no-unused-vars
    const [formContextState, formContextDispatch] = useContext(FormContext);

    const {
        register,
        getValues,
        handleSubmit,
        watch,
        formState: { errors },
        setValue,
    } = useForm({
        resolver: yupResolver(schema),
    });

    useImperativeHandle(ref, () => ({
        handleSubmit: (data) => {
            handleSubmit(onSubmit)();
        },
    }));

    useCurrentForm(readyToWatch, watch);

    useEffect(() => {
        const me = authState.me;

        if (me) {
            formContextDispatch({ type: 'publish', status: true });

            setValue('address', '');
            setValue('point', '');
            setValue('type', 'customNeed');

            const timeoutId = setTimeout(() => {
                setReadyToWatch(true);
            }, 1000);

            return () => {
                clearTimeout(timeoutId);
            };
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authState]);

    //FUNCTIONS
    const onSubmit = (data) => {
        if (errors.length > 0) {
            return false;
        }

        props.closeForm();

        const fd = new FormData();
        for (var k in data) {
            if (data[k] !== undefined) {
                let item = null;
                item = data[k];

                if (k === 'medias') {
                    let files = data[k];

                    if (props?.isEdit) {
                        let existingMediasArray = []; // Pour stocker les médias existants

                        for (let i = 0; i < files.length; i++) {
                            if (files[i] instanceof File) {
                                fd.append(k, files[i]);
                            } else {
                                existingMediasArray.push(files[i]); // Ajoutez à l'array au lieu de JSON.stringify ici
                            }
                        }
                        // Convertir le tableau de médias existants en une chaîne JSON et l'ajouter à FormData
                        if (existingMediasArray.length > 0) {
                            fd.append('existingMedias', JSON.stringify(existingMediasArray));
                        }
                    } else {
                        for (let i = 0; i < files.length; i++) {
                            fd.append(k, files[i]);
                        }
                    }
                } else fd.append(k, item);
            }
        }

        createMatchingNeed(fd)
            .then((res) => {
                if (res.status === 201) {
                    toast('Besoin créé', { type: 'success' });
                    socket.send('startNeedMatching', { needId: res.data.data.id, userId: res.data.data.user });
                    // Navigate ou autres actions
                } else {
                    toast('Une erreur est survenue', { type: 'warning' });
                }
            })
            .catch((error) => {
                console.error('Erreur lors de la création du besoin:', error);
                toast('Erreur de réseau ou du serveur', { type: 'error' });
            });
    };

    function dispatchOptionsActions(action, name, value) {
        switch (action) {
            case 'onValidateOption':
                return onValidateOption(name, value);
            case 'onChangeOptionAddress':
                return onChangeOptionAddress(name, value);
            default:
        }
    }

    // eslint-disable-next-line no-unused-vars
    const getTodayDateString = () => {
        const today = new Date();
        const day = String(today.getDate()).padStart(2, '0');
        const month = String(today.getMonth() + 1).padStart(2, '0'); // Les mois commencent à 0
        const year = today.getFullYear();

        return `${year}-${month}-${day}`;
    };

    //FUNCTIONS OPTIONS
    function onChangeOptionAddress(name, value) {
        setValue(name, value);
    }
    function onValidateOption(name, value) {
        return true;
    }

    const buttonUpload = (
        <div className="flex gap-2 text-graySkills my-2">
            <PhotographIcon className="w-6 h-6" />
            <PlayIcon className="w-6 h-6" />
        </div>
    );

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="form" autoComplete="off" encType="multipart/form-data">
            <div className="flex items-center w-full gap-0">
                <FieldInput
                    inputForm="flat"
                    name="title"
                    label="Que recherchez-vous ?*"
                    /* placeholder="Préciez votre besoin" */
                    size="w-full"
                    errors={errors}
                    register={register}
                />
            </div>
            <div className="flex w-full gap-2">
                <FieldInput
                    inputForm="flat"
                    disabled={true}
                    name="address"
                    label="Lieu*"
                    size="w-full"
                    depends="point"
                    errors={errors}
                    register={register}
                    option={profileOptions.address}
                    dispatchOptionsActions={dispatchOptionsActions}
                    getValues={getValues}
                    readyToWatch={readyToWatch}
                />

                {/* <div className="w-1/2">
                    <label className={`text-graySkills`}>À partir du</label>
                    <FieldInput
                        inputForm="flat"
                        name="startDate"
                        placeholder="À partir du"
                        label={false}
                        type="date"
                        min={getTodayDateString()}
                        errors={errors}
                        register={register}
                    />
                </div> */}

                {/*  <FieldInput
                    inputForm="flat"
                    name="quantity"
                    label="Quantité"
                    size="w-1/2"
                    type="number"
                    min="0"
                    defaultValue={0}
                    errors={errors}
                    register={register}
                /> */}
            </div>

            <div className="w-full mt-4">
                <label className="text-sm">Illustrez votre besoin avec une vidéo ou un image</label>
                <FieldFiles
                    name="medias"
                    label="Déposez ou cliquez ici pour uploader une image"
                    type="multiple"
                    multiple={true}
                    register={register}
                    isEdit={props?.isEdit}
                    oldFiles={props?.needData?.medias}
                    initValue={[]}
                    readyToWatch={true}
                    onChange={(value) => {
                        setValue('medias', value);
                    }}
                    button={buttonUpload}
                    accepts={['image/png', 'image/jpeg', 'image/jpg', 'image/gif', 'video/mp4', 'video/quicktime']}
                />
            </div>

            {/*  <div className="flex items-center w-full gap-2">
                <FieldInput
                    inputForm="flat"
                    name="time"
                    placeholder="Durée du besoin"
                    label={false}
                    type="number"
                    min="0"
                    defaultValue={0}
                    size="w-1/2"
                    errors={errors}
                    register={register}
                />
                <FieldInput
                    inputForm="flat"
                    name="date"
                    placeholder="Durée du besoin"
                    size="w-1/2"
                    label={false}
                    type="date"
                    errors={errors}
                    register={register}
                />
            </div> */}
        </form>
    );
}

export default forwardRef(FormAddNeed);
