function reducer(state, action) {

    switch (action.type) {
        case "init":
            return { ...state, step: "init", type: action.itemType, item: action.item };
        case "confirm":
            return { ...state, step: "confirm" };
        default:
            return state;
    }
}

export default reducer;
