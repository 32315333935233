import moment from 'moment';

moment.updateLocale('en', {
    relativeTime: {
        future: 'dans %s',
        past: 'il y a %s',
        s: 'quelques secondes',
        ss: '%d secondes',
        m: 'une minute',
        mm: '%d minutes',
        h: 'une heure',
        hh: '%d heures',
        d: 'un jour',
        dd: '%d jours',
        w: 'une semaine',
        ww: '%d semaines',
        M: 'un mois',
        MM: '%d mois',
        y: 'un an',
        yy: '%d années',
    },
});

function Moment(props) {
    return (
        <>
            {props.fromNow && <>{moment(props.date).fromNow()}</>}

            {props.format && <>{moment(props.date).format(props.format)}</>}
        </>
    );
}

export default Moment;
