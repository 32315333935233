import { useContext } from 'react';

//CONFIG
// import { DotsHorizontalIcon } from '@heroicons/react/solid';

//LIBS
import _ from 'lodash';

//COMPONENTS
import Moment from 'components/common/Moment';
// import Button from 'components/common/Button';

//CONTAINERS
import Avatar from 'containers/profils/Avatar';

//CONTEXTS
import { AuthContext } from 'context/authContext';
import { StoreContext } from 'context/storeContext';

function CardDiscussion({ discussion, refetch, socket }) {
    const [authState] = useContext(AuthContext);
    const store = useContext(StoreContext);

    const user = discussion.user1?.id === authState?.me?.id ? discussion.user2 : discussion.user1;
    const unreaded = discussion.user1?.id === authState.me?.id ? discussion.unreaded1 : discussion.unreaded2;
    const lastMsg = _.last(discussion?.messages);

    function openDiscussion(e) {
        e?.preventDefault();
        e?.stopPropagation();
        store.set({ type: 'add', key: 'openChat', value: { open: true, userId: user.id } });
        refetch();
    }

    return (
        <>
            <div
                onClick={(e) => {
                    openDiscussion(e);
                }}
                className={`flex flex-row px-2 w-full ${unreaded ? 'bg-lightBlueSkills bg-opacity-50 rounded-xl' : ''} border-b mb-1 border-gray-100 py-4`}
            >
                <div className={`flex flex-row w-full cursor-pointer ${unreaded ? 'cursor-pointer' : ''}`}>
                    <div className="flex justify-between w-full my-auto">
                        <div className="relative w-full">
                            <div className="flex flex-row w-full gap-2 sm:gap-4 relative">
                                <Avatar user={user} onClick={openDiscussion} className="w-20" />

                                <div className="w-full flex flex-col">
                                    <div className="flex flex-col">
                                        <span className="text-sm font-bold text-purpleSkills">{user?.profile?.pseudo}</span>

                                        {user?.profile?.status && <span className="text-xs text-graySkills">{user?.profile?.status}</span>}
                                        {lastMsg?.message && (
                                            <>
                                                <span className="hidden sm:block mt-2 text-xs text-purpleSkills">
                                                    {lastMsg?.message?.substring(0, 100)}
                                                    {lastMsg?.message?.length > 100 && ' ...'}
                                                </span>
                                                <span className="sm:hidden mt-2 text-xs text-purpleSkills">
                                                    {lastMsg?.message?.substring(0, 50)}
                                                    {lastMsg?.message?.length > 50 && ' ...'}
                                                </span>
                                            </>
                                        )}

                                        {unreaded > 0 && (
                                            <div className="absolute top-1 right-1 text-white bg-red p-1 rounded-full w-5 h-5 flex justify-center items-center text-xs">
                                                {unreaded}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col w-32 px-2">
                            <div className="text-xs text-center text-graySkills">
                                <Moment date={discussion.createdAt} fromNow />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CardDiscussion;
