import { useEffect, useContext, useState } from 'react';
//STYLES
import 'containers/cards.css';
//UTILS
import config from 'utils/constants';
//API
import { postCounter } from 'api/counters';
//CONTEXTS
import { AuthContext } from 'context/authContext';

//HOOKS
import useSafeState from 'hooks/useSafeState';
import usePostCounter from 'hooks/usePostCounter';

//LIBS
import { Link } from 'react-router-dom';
import { isFoundByKey } from 'utils/functions';

//CONTAINERS
//import Avatar from 'containers/profils/Avatar';
import PointGamification from 'containers/profils/PointGamification';

//COMPONENTS
import Image from 'components/common/Image';
import PingConnected from 'components/PingConnected';

//ICONS
import { LocationMarkerIcon, CheckIcon, BellIcon /*  EyeIcon */ } from '@heroicons/react/solid';

const sizes = {
    smallAvatar: '?w=40&h=40&c=true&q=80',
    avatar: '?w=500&h=500&c=true&q=80',
    cover: '?w=1000&h=600&c=true&q=80',
};

function CardFrontProfil({ user, refetch, listingQueryKeys }) {
    //CONTEXTS
    const [authState] = useContext(AuthContext);
    //REFS
    const [userName, setUserName] = useSafeState('');
    /* const [isSubscribed, setIsSubscribed] = useState(
        // Initialisation basée sur les données actuelles, si disponible
        isFoundByKey(user?.counters, 'user', authState?.me?.id) && isFoundByKey(user?.counters, 'type', 'subscription')
    ); */
    const { postCounterMutate } = usePostCounter();
    const [isSubscribed, setIsSubscribed] = useState(false);

    useEffect(() => {
        const subscribed = isFoundByKey(user?.counters, 'user', authState?.me?.id) && isFoundByKey(user?.counters, 'type', 'subscription');
        setIsSubscribed(subscribed);
    }, [authState?.me?.id, user?.counters]);

    const handleSubscribeToggle = () => {
        if (authState.isLogged) {
            if (listingQueryKeys !== null || listingQueryKeys !== undefined) {
                //console.log(listingQueryKeys);
                postCounterMutate({
                    to: user?.id || user?._id,
                    type: 'subscription',
                    isListing: true,
                    listingQueryKeys: listingQueryKeys,
                });

                setIsSubscribed((prevState) => !prevState);
            } else {
                postCounter({ type: 'subscription', to: user?.id || user?._id })
                    .then((res) => {
                        setIsSubscribed((prevState) => !prevState);
                        refetch();
                    })
                    .catch((error) => {
                        console.error('Erreur lors de la souscription :', error);
                    });
            }

            //setIsSubscribed((prevState) => !prevState);
        } else {
            document.getElementById('button-login')?.click();
        }
    };

    useEffect(() => {
        if (user === null) return;
        if (user?.profile?.slug) setUserName(user?.profile?.slug);
        if (user?.profile?.display === 'realnames' && user?.profile?.pseudo) setUserName(user?.profile?.pseudo);
        else if (user?.profile?.display === 'society' && user?.profile?.society) setUserName(user?.profile?.society);
        else setUserName(user?.profile?.firstname);
        //eslint-disable-next-line
    }, [user]);

    return (
        <>
            <div
                className={`bg-white rounded-3xl shadow-sm shadow-lightBlueSkills hover:shadow-xl hover:shadow-lightBlueSkills transition-shadow duration-400 w-full h-full`}
            >
                <Link to={'/profil/' + user?.profile?.slug}>
                    <div className="card_profile_images_cover relative h-[8rem]">
                        <div className="w-full relative h-28">
                            {user.profile?.cover?.url ? (
                                <Image
                                    width="100%"
                                    /*  height={`${isSmartphone() ? '6rem' : '8rem'}`} */
                                    src={user.profile?.cover?.url + sizes.cover}
                                    className="w-full object-cover  h-28 relative rounded-t-3xl max-h-28 bg-black"
                                    dynamic
                                    alt={user.profile?.pseudo}
                                    style={{ overflow: 'hidden', pointerEvents: 'none' }}
                                />
                            ) : (
                                <>
                                    <img
                                        className="w-full h-28 relative rounded-t-3xl"
                                        src={`${config.publicDir}banniere_skillsdefault2.png` + sizes.cover}
                                        alt={user.data?.profile?.pseudo}
                                    />
                                </>
                            )}
                        </div>
                        <div className="absolute bottom-0 left-0 right-0">
                            <div className="flex justify-center">
                                {/*  <Avatar
                                    user={user}
                                    gamification={true}
                                    totalGamification={user.data?.totalGamification}
                                    className="w-[7.5rem] rounded-full shadow-md shadow-lightBlueSkills"
                                /> */}
                                <div className={`relative h-auto p-0 m-0 w-[7.5rem]`}>
                                    {user?.profile?.avatar?.url ? (
                                        <Image
                                            src={user?.profile?.avatar?.url + sizes.avatar}
                                            alt={userName}
                                            loading="false"
                                            dynamic
                                            className={`h-auto rounded-full w-[7.5rem] shadow-md shadow-lightBlueSkills`}
                                        />
                                    ) : (
                                        <img
                                            className="rounded-full shadow-md shadow-lightBlueSkills  w-[7.5rem]"
                                            src={`${config.publicDir}skillsLogo.jpg` + sizes.avatar}
                                            alt={userName}
                                        />
                                    )}
                                    <PointGamification gamification={user?.gamification?.profile} />
                                    <PingConnected connexions={user?.connexions} type="global" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col mx-2 my-2">
                        <div className="pseudo w-full sm:overflow-hidden mt-2">
                            <p
                                className="text-center text-purpleSkills text-sm font-bold hover:text-blueMain max-h-[26px] w-full leading-none"
                                style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
                            >
                                <span>{userName}</span>
                            </p>
                        </div>
                        <div className="status w-full sm:overflow-hidden mt-1">
                            <p
                                className="text-center text-purpleSkills text-[12px] font-semibold hover:text-blueMain max-h-[26px] w-full leading-none"
                                style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
                            >
                                {user?.profile?.status ? user?.profile?.status : 'Société'}
                            </p>
                        </div>

                        <div className="flex flex-row items-center justify-center w-full gap-2 text-xs text-graySkills mt-1 relative">
                            <div className="flex-auto h-[1px] bg-gray-200"></div>
                            {user?.profile?.address && (
                                <div className="flex gap-0.5 items-center">
                                    <LocationMarkerIcon className="w-3 h-3" />
                                    <span>
                                        {user?.profile?.address
                                            ? (() => {
                                                  const parts = user.profile.address.split(',');
                                                  // au moins ville et pays
                                                  if (parts.length >= 2) {
                                                      //  ville et le pays
                                                      const city = parts[parts.length - 2].trim();
                                                      const country = parts[parts.length - 1].trim();
                                                      return `${city}, ${country}`;
                                                  } else if (parts.length === 1) {
                                                      // pays
                                                      return parts[0].trim();
                                                  } else {
                                                      // Si l'adresse est mal formée ou vide
                                                      return user.profile.address;
                                                  }
                                              })()
                                            : ''}
                                    </span>
                                </div>
                            )}
                            <div className="flex-auto h-[1px] bg-gray-200"></div>
                        </div>

                        <div className="w-full sm:overflow-hidden mt-1">
                            <p className="line-clamp-2 text-center text-purpleSkills text-[11px] hover:text-blueMain h-[22px] sm:h-[22px] leading-none">
                                {user?.profile.objectifs ? `"${user?.profile.objectifs}"` : ''}
                            </p>
                        </div>
                    </div>
                    <div className="followers flex flex-row justify-center mt-2 mb-2 mx-2">
                        <div className="flex flex-row  items-center justify-center w-full relative">
                            <div className="flex items-center gap-2 bg-blancSkills text-purpleSkills text-xs p-1 rounded-full pr-2 py-[2px]">
                                <div className="flex flex-col sm:flex-row items-center justify-end w-auto gap-2">
                                    {user?.profile?.slug !== authState?.me?.profile?.slug && (
                                        <>
                                            {/* <button
                                                onClick={(e) => {
                                                    e?.preventDefault();
                                                    e?.stopPropagation();
                                                    handleSubscribeToggle();
                                                }}
                                                className={`h-8 flex gap-2 ${
                                                    isFoundByKey(user?.counters, 'user', authState?.me?.id) &&
                                                    isFoundByKey(user?.counters, 'type', 'subscription') &&
                                                    authState.isLogged
                                                        ? 'linkCard red active'
                                                        : 'linkCard-purpleSkills'
                                                }`}
                                            >
                                                {isFoundByKey(user?.counters, 'user', authState?.me?.id) &&
                                                isFoundByKey(user?.counters, 'type', 'subscription') &&
                                                authState.isLogged ? (
                                                    <>
                                                        <CheckIcon className="h-4 w-4" />
                                                        <span>Suivi</span>
                                                    </>
                                                ) : (
                                                    <>
                                                        <BellIcon className="h-4 w-4" />
                                                        <span>Suivre</span>
                                                    </>
                                                )}
                                            </button> */}
                                            <button
                                                onClick={(e) => {
                                                    e?.preventDefault();
                                                    e?.stopPropagation();
                                                    handleSubscribeToggle();
                                                }}
                                                className={`h-8 flex gap-2 ${isSubscribed ? 'linkCard red active' : 'linkCard-purpleSkills'}`}
                                            >
                                                {isSubscribed ? (
                                                    <>
                                                        <CheckIcon className="h-4 w-4" />
                                                        <span>Suivi</span>
                                                    </>
                                                ) : (
                                                    <>
                                                        <BellIcon className="h-4 w-4" />
                                                        <span>Suivre</span>
                                                    </>
                                                )}
                                            </button>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
        </>
    );
}

export default CardFrontProfil;
