//UTILS
import { isMobile } from 'utils/functions';

//API
import { useQuery } from 'react-query';
import { getShootsPaginate, getShootsNewsPaginate, getShoot } from 'api/shoots';

//LAYOUT
import ContainerLayout from 'pages/layouts/Container';
// eslint-disable-next-line no-unused-vars
import { postCounter } from 'api/counters';
//COMPONENTS
import SideNav from 'components/common/nav/SideNav';
import Button from 'components/common/Button';
//CONFIG
import config from 'utils/constants';
//CONTEXTS
// eslint-disable-next-line no-unused-vars
import { AuthContext } from 'context/authContext';
//CONTAINERS
import Modal from 'components/common/Modal';
import OptionsMenu from 'components/common/OptionsMenu';
import CardProfilShoot from 'containers/profils/CardProfilShoot';
//HOOKS
import useInfinite from 'hooks/useInfinite';

import PlayerVideo from 'components/common/PlayerVideo';
// eslint-disable-next-line no-unused-vars
import { ArrowDownIcon, ArrowUpIcon, DotsVerticalIcon, EyeIcon, HeartIcon, XCircleIcon } from '@heroicons/react/solid';
// eslint-disable-next-line no-unused-vars
import { useContext, useEffect, useRef, useState } from 'react';
//HOOKS
// eslint-disable-next-line no-unused-vars
import useHasCounter from 'hooks/useHasCounter';
import { useNavigate } from 'react-router-dom';

import Answers from 'containers/answers/Answers';
import AnswerForm from 'containers/answers/AnswerForm';

function CollectionShoots() {
    //STATES
    const [open, setOpen] = useState(false);
    const [currentShoot, setCurrentShoot] = useState({});
    const currentIndex = useRef(null);
    const [shoots, setShoots] = useState([]);

    //CONTEXTS
    const [authState] = useContext(AuthContext);

    //HOOKS
    const navigate = useNavigate();
    //const hasCounter = useHasCounter(currentShoot?.counters, 'like_shoot', currentShoot?._id);

    const [playedShoots, setPlayedShoots] = useState({});

    const handleTooglePlay = (shootId) => {
        setPlayedShoots((prevPlayedShoots) => {
            const newPlayedShoots = { ...prevPlayedShoots };
            newPlayedShoots[shootId] = true;
            for (const id in newPlayedShoots) {
                if (id !== shootId) {
                    newPlayedShoots[id] = false;
                }
            }
            return newPlayedShoots;
        });
    };

    function handleOpen(shoot) {
        setOpen(true);
        currentIndex.current = shoots.indexOf(shoot);
        setCurrentShoot(shoot);
        handleTooglePlay(shoot._id);
    }

    function handlePrev() {
        currentIndex.current = shoots.indexOf(currentShoot);
        let prevIndex = shoots[currentIndex.current - 1] ? currentIndex.current - 1 : shoots.length - 1;

        setCurrentShoot(shoots[prevIndex]);
        handleTooglePlay(shoots[prevIndex]._id);
    }

    function handleNext() {
        currentIndex.current = shoots.indexOf(currentShoot);
        let nextIndex = shoots[currentIndex.current + 1] ? currentIndex.current + 1 : 0;

        setCurrentShoot(shoots[nextIndex]);
        handleTooglePlay(shoots[nextIndex]._id);
    }

    /* function handleSubscribe(shootId) {
        if (authState.isLogged) {
            postCounter({ type: 'like_shoot', to: shootId }).then(() => {
            });
        } else document.getElementById('button-login')?.click();
    } */

    function handleClick(slug) {
        navigate(`/shoots/${slug}`);
    }

    function closeOptionsMenu(shoot) {
        const els = document.getElementsByClassName('optionsMenu');
        [].forEach.call(els, function (el) {
            if (el.id !== `optionsMenu_${shoot._id}`) {
                el.classList.add('hidden');
            }
        });
    }

    function handleOptionsShootMenu(shoot) {
        closeOptionsMenu(shoot);
        document.getElementById(`optionsMenu_${shoot._id}`).classList.toggle('hidden');
    }
    let infShoots = [];

    function handleSubscribe(shoot) {
        if (authState.isLogged) {
            postCounter({ userTo: shoot.user.id, type: 'like_shoot', to: shoot._id }).then(() => {
                refetchShoot();
                /*   props.refetch(); */
            });
        } else document.getElementById('button-login')?.click();
    }

    const infiniteShoots = useInfinite(getShootsPaginate, 'ListingCollectionshoots', {
        onItem: (shoot, refetch) => {
            return (
                <>
                    <div className="relative py-2 p-1">
                        <div className="h-44 sm:h-64 md:h-72 w-auto rounded-lg shadow hover:shadow-2xl overflow-hidden">
                            <div
                                className="h-full"
                                onClick={() => {
                                    if (isMobile()) handleClick(shoot.slug);
                                    else handleOpen(shoot);
                                }}
                            >
                                <PlayerVideo
                                    shoot={shoot}
                                    id={shoot._id}
                                    showInfos={true}
                                    muted={false}
                                    width={'100%'}
                                    light={`${config.serverUrl}/downloads/videos/${shoot.user.id}/screenshot_${shoot.video._id}_${shoot.selectedScreen}.jpg`}
                                />
                            </div>

                            <div className="absolute top-5 right-2">
                                <div className="flex flex-col gap-1 items-center">
                                    <Button
                                        onClick={() => {
                                            handleOptionsShootMenu(shoot);
                                        }}
                                        label={<DotsVerticalIcon className="w-6 h-6" />}
                                        justIcon={true}
                                        css="text-white"
                                    />
                                    <OptionsMenu shoot={shoot} user={shoot.user} refresh={refetch} path="profil" type="shoot" />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            );
        },
        onNoResult: () => {
            return <></>;
        },
        onData: (data) => {
            if (data) {
                data?.pages?.forEach((page) => {
                    page.docs.forEach((shoot) => {
                        if (!shoots.includes(shoot)) {
                            setShoots((prevShoots) => [...prevShoots, shoot]);
                        }
                    });
                });
            }
        },
        onLastIndex: () => {
            if (shoots.indexOf(currentShoot) === shoots.length - 1) {
                return true;
            } else {
                return false;
            }
        },
        onRefetch: (refetch) => {
            refetch();
        },
        limit: 6,
        gridShoots: true,
        msgToSee: 'Voir plus de shoots',
    });
    const infiniteShootsNew = useInfinite(getShootsNewsPaginate, 'ListingCollectionshootsByNewsOne', {
        onItem: (shoot, refetch) => {
            return (
                <>
                    <div className="relative py-2 p-1">
                        <div className="h-44 sm:h-64 md:h-72 w-auto rounded-lg shadow hover:shadow-2xl overflow-hidden">
                            <div
                                className="h-full"
                                onClick={() => {
                                    if (isMobile()) handleClick(shoot.slug);
                                    else handleOpen(shoot);
                                }}
                            >
                                <PlayerVideo
                                    shoot={shoot}
                                    id={shoot._id}
                                    showInfos={true}
                                    muted={false}
                                    width={'100%'}
                                    light={`${config.serverUrl}/downloads/videos/${shoot.user.id}/screenshot_${shoot.video._id}_${shoot.selectedScreen}.jpg`}
                                />
                            </div>

                            <div className="absolute top-5 right-2">
                                <div className="flex flex-col gap-1 items-center">
                                    <Button
                                        onClick={() => {
                                            handleOptionsShootMenu(shoot);
                                        }}
                                        label={<DotsVerticalIcon className="w-6 h-6" />}
                                        justIcon={true}
                                        css="text-white"
                                    />
                                    <OptionsMenu shoot={shoot} user={shoot.user} refresh={refetch} path="profil" type="shoot" />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            );
        },
        onNoResult: () => {
            return <></>;
        },
        onData: (data) => {
            if (data) {
                data?.pages?.forEach((page) => {
                    page.docs.forEach((shoot) => {
                        if (!shoots.includes(shoot)) {
                            setShoots((prevShoots) => [...prevShoots, shoot]);
                        }
                    });
                });
            }
        },
        onLastIndex: () => {
            if (shoots.indexOf(currentShoot) === shoots.length - 1) {
                return true;
            } else {
                return false;
            }
        },
        onRefetch: (refetch) => {
            refetch();
        },
        limit: 6,
        gridShoots: true,
        msgToSee: 'Voir plus de shoots',
    });

    infShoots.push(infiniteShootsNew);
    infShoots.push(infiniteShoots);

    const shoot = useQuery(
        ['shoot', currentShoot?.slug],
        (params) => {
            return getShoot(currentShoot?.slug);
        },
        { enabled: currentShoot?.slug ? true : false, staleTime: 0 }
    );

    //HOOKS
    const hasCounter = useHasCounter(shoot.data?.current?.counters, 'like_shoot', shoot.data?.current?._id);

    function refetchShoot() {
        shoot.refetch();
    }

    useEffect(() => {
        if (currentShoot?.slug) {
            shoot.refetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentShoot]);

    return (
        <ContainerLayout>
            <Modal
                modalWidth={'w-screen h-screen overflow-y-hidden sm:overflow-y-auto lg:overflow-y-hidden relative'}
                open={open}
                title={false}
                fullScreen={true}
                noMaxWidth={true}
            >
                <div className="w-full ">
                    <div className="w-full h-full sm:flex sm:flex-col lg:flex-row">
                        <div className={`relative lg:w-screen lg:max-w-full bg-black`}>
                            <div className={`relative ${currentShoot.video?.position === 'landscape' ? 'h-screen w-auto' : 'h-screen w-auto'}`}>
                                <PlayerVideo
                                    id={currentShoot._id}
                                    width={'100%'}
                                    videoFitCover={false}
                                    played={playedShoots[currentShoot._id]}
                                    shoot={currentShoot}
                                    muted={false}
                                    controls={true}
                                />
                                <div className="absolute top-2 left-5 flex flex-col gap-10">
                                    <div className="flex flex-col gap-1 items-center">
                                        <Button label={<XCircleIcon className="w-10 h-10 text-white" />} justIcon={true} onClick={() => setOpen(false)} />
                                    </div>
                                </div>
                                <div className="absolute bottom-52 right-5 flex flex-col gap-10">
                                    {shoot.status === 'success' && shoot.data.current && (
                                        <>
                                            <div className="flex flex-col gap-1 items-center">
                                                <Button
                                                    label={<EyeIcon className="w-6 h-6 mx-auto" />}
                                                    justIcon={true}
                                                    css="w-12 h-12 bg-white border border-graySkills rounded-full text-graySkills"
                                                />
                                                <span className="text-xs text-graySkills">{shoot.data.current?.views}</span>
                                            </div>
                                            <div className="flex flex-col gap-1 items-center">
                                                <Button
                                                    label={<HeartIcon className="w-6 h-6 mx-auto" />}
                                                    justIcon={true}
                                                    css={`w-12 h-12 border rounded-full  ${
                                                        hasCounter ? 'text-red border-red bg-red' : 'text-graySkills border-graySkills bg-white'
                                                    }`}
                                                    // css="w-12 h-12 bg-white border border-graySkills rounded-full text-graySkills"
                                                    onClick={() => handleSubscribe(shoot.data.current)}
                                                />
                                                <span className={`text-graySkills`}>{shoot.data.current?.counters?.length}</span>
                                            </div>
                                        </>
                                    )}
                                    <div className="flex flex-col gap-1 items-center">
                                        <Button
                                            label={<ArrowUpIcon className="w-6 h-6 mx-auto" />}
                                            justIcon={true}
                                            css="w-12 h-12 bg-white border border-graySkills rounded-full text-graySkills"
                                            onClick={() => handlePrev()}
                                        />
                                    </div>
                                    <div className="flex flex-col gap-1 items-center">
                                        <Button
                                            label={<ArrowDownIcon className="w-6 h-6 mx-auto" />}
                                            justIcon={true}
                                            css="w-12 h-12 bg-white border border-graySkills rounded-full text-graySkills"
                                            onClick={() => {
                                                handleNext();
                                                //console.log(shoots);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`hidden relative sm:block sm:p-2 w-full lg:w-[600px] h-screen`}>
                            {shoot.status === 'success' && shoot.data.current && (
                                <div className="grid grid-auto-rows">
                                    <div className="h-full">
                                        <CardProfilShoot user={shoot.data.current.user} shoot={shoot.data.current} />
                                        <div className="px-2 py-2 w-full bg-white md:bg-transparent bottom-2 z-10">
                                            <AnswerForm
                                                item={shoot.data.current}
                                                type={'shoot'}
                                                refetch={refetchShoot}
                                                options={{ placeholder: null, isShoot: true }}
                                            />
                                        </div>
                                    </div>

                                    {currentShoot?.answers && (
                                        <Answers item={shoot.data.current} counters={shoot.data.current?.counters} type={'shoot'} refetch={refetchShoot} />
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </Modal>
            <div className="container">
                <div className="sm:grid sm:grid-cols-12 sm:gap-8 pb-4">
                    {!isMobile() && (
                        <div className="hidden sm:block sm:col-span-3">
                            <SideNav />
                        </div>
                    )}
                    <div className="sm:col-span-9">
                        <main>
                            <div className={`relative w-full m-auto xl:container`}>
                                <h3 className="mt-4 font-bold text-purpleSkills">Shoots tendances</h3>
                                <div className="mt-2 mb-4">
                                    {infShoots.map((infShoot, index) => {
                                        return <div key={index}>{infShoot}</div>;
                                    })}
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </div>
        </ContainerLayout>
    );
}

export default CollectionShoots;
