import { useState, useEffect, useContext, cloneElement } from 'react';

import { HeadlessContext } from '../index.js';

//ICONS
import { HiOutlineCog, HiOutlineUser, HiOutlineUsers } from 'react-icons/hi';
import { SiFirebase } from 'react-icons/si';
import { MdHelpCenter } from 'react-icons/md';

//COMPONENTS
import HeadlessAnnotation from '../components/content/HeadlessAnnotation';
import HeadlessFormUsers from '../components/form/HeadlessFormUsers';

//PAGES
import HeadlessPageConfigProfil from './config/HeadlessPageConfigProfil';
import HeadlessPageConfigFirebase from './config/HeadlessPageConfigFirebase';
import HeadlessPageConfigHelp from './config/HeadlessPageConfigHelp';

function Icon(props) {
    const [active, setActive] = useState(props.active);

    useEffect(() => {
        setActive(props.active);
        //eslint-disable-next-line
    }, [props.active]);

    return (
        <div onClick={props.onClick}>
            {cloneElement(props.icon, { className: `w-7 h-7 cursor-pointer p-1 ${active ? 'bg-[#745C8988] text-white rounded-full shadow' : ''}` })}
        </div>
    );
}

export default function HeadlessPageConfig() {
    const store = useContext(HeadlessContext);

    function handlePageChange(page) {
        store.set({ type: 'page', value: page });
    }

    return (
        <div className="flex flex-col items-stretch h-full justify-start">
            <div className="flex flex-row gap-2 h-full">
                <div className="flex flex-col items-center border-r border-r-[#745C89] text-[#745C89] gap-3 hl_navbar w-[60px]">
                    <span>
                        <Icon onClick={() => handlePageChange('hlConfig')} icon={<HiOutlineUser />} active={store.get('page') === 'hlConfig' ? true : false} />
                    </span>
                    {store.get('me').role === 'admin' && (
                        <span>
                            <Icon
                                onClick={() => handlePageChange('hlConfigUsers')}
                                icon={<HiOutlineUsers />}
                                active={store.get('page') === 'hlConfigUsers' ? true : false}
                            />
                        </span>
                    )}
                    {store.get('me').role === 'admin' && (
                        <span>
                            <Icon
                                onClick={() => handlePageChange('hlConfigFirebase')}
                                icon={<SiFirebase />}
                                active={store.get('page') === 'hlConfigFirebase' ? true : false}
                            />
                        </span>
                    )}
                    <span>
                        <Icon
                            onClick={() => handlePageChange('hlConfigHelp')}
                            icon={<MdHelpCenter />}
                            active={store.get('page') === 'hlConfigHelp' ? true : false}
                        />
                    </span>
                </div>
                <div className="w-full flex pl-6">
                    <div className="w-full flex flex-col justify-between">
                        <div className="flex gap-1 items-center text-[#745C89] mb-6">
                            <HiOutlineCog className="w-6 h-6" />
                            <span className="inline-block text-[#745C89] font-semibold text-lg">Configuration</span>
                        </div>
                        <div className="overflow-auto w-full h-full">
                            {'hlConfig' === store.get('page') && <HeadlessPageConfigProfil />}
                            {'hlConfigUsers' === store.get('page') && (
                                <>
                                    <div className="text-sm">
                                        {store.get('me')?.role === 'admin' ? (
                                            <>
                                                <p>Configurez vos utilisateurs qui auront accès à cet espace</p>
                                                <HeadlessAnnotation>Attention à bien définir un rôle adapté pour plus de sécurité</HeadlessAnnotation>
                                            </>
                                        ) : (
                                            <p>Liste des utilisateurs</p>
                                        )}
                                        <HeadlessFormUsers />
                                    </div>
                                </>
                            )}
                            {'hlConfigFirebase' === store.get('page') && <HeadlessPageConfigFirebase />}
                            {'hlConfigHelp' === store.get('page') && <HeadlessPageConfigHelp />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
