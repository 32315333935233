import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
//API
import { getTagsSuggests } from 'api/search';

//COMPONENTS
import Button from 'components/common/Button';
import FieldInput from 'components/forms/fields/input';

import SearchFilterPlace from 'containers/search/SearchFilterPlace';

//FORMS
import schema from 'components/forms/yup/search';

//LIBS
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';

//STYLES
import './MainSearchBar.css';

//UTILS
import { isMobile } from 'utils/functions';

function MainSearchBar(props) {
    const [state, setState] = useState({
        activeSuggestion: 0,
        filteredSuggestions: [],
        showSuggestions: false,
        userInput: '',
        suggestions: [],
        totalSuggestions: 0,
    });

    // eslint-disable-next-line no-unused-vars
    const [isSearchActive, setIsSearchActive] = useState(true);

    const [isSearchLocation, setIsSearchLocation] = useState(false);
    const [isJobsLocation, setIsJobsLocation] = useState(false);
    const [address, setAddress] = useState({
        label: props?.roomData?.address || '',
        value: props?.roomData?.address || '',
    });

    const currentForm = useRef(null);
    const searchRef = useRef(null);
    const jobRef = useRef(null);

    let [searchParams] = useSearchParams();
    const q = searchParams.get('q');

    //HOOKS
    const navigate = useNavigate();
    const location = useLocation();

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        //reset,
        setValue,
        //getValues,
    } = useForm({
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        const subscription = watch((value, { name }) => {
            handleChange({ name, value: value[name] });
        });
        return () => subscription.unsubscribe();
        //eslint-disable-next-line
    }, [watch]);

    useEffect(() => {
        setIsSearchLocation(location.pathname === '/search' ? true : false);
    }, [location.pathname]);

    useEffect(() => {
        if (location.pathname.includes('search')) {
            searchRef.current !== null && searchRef.current.focus();
        }
        if (location.pathname.includes('jobs')) {
            jobRef.current !== null && jobRef.current.focus();
            setIsJobsLocation(true);
        }
        //eslint-disable-next-line
    }, []);

    function handleChange(input) {
        if (input.value?.length >= 3) {
            const call = getTagsSuggests(input.value);
            call.then((res) => {
                if (res.status === 200) {
                    const suggestions = res.data;
                    const counts = {};

                    if (suggestions.length > 0) {
                        suggestions.forEach(function (x) {
                            x.text.forEach(function (y) {
                                counts[y.name] = (counts[y.name] || 0) + 1;
                            });
                        });

                        //convert counts to array
                        const countsArray = Object.keys(counts).map(function (key) {
                            return [key];
                        });

                        if (countsArray.length > 0) {
                            // const filteredSuggestions = suggestions.filter(
                            //     (suggestion) =>
                            //         suggestion.profile.pseudo
                            //             .toLowerCase()
                            //             .indexOf(input.value.toLowerCase()) > -1
                            // );

                            let elementNb = countsArray.length;

                            if (countsArray.length > 5) {
                                countsArray.length = 5;
                            }

                            setState({
                                ...state,
                                filteredSuggestions: suggestions,
                                showSuggestions: true,
                                userInput: input.value,
                                suggestions: countsArray,
                                totalSuggestions: elementNb,
                            });
                            /* currentForm.current.click(); */
                        } else {
                            setState({
                                ...state,
                                filteredSuggestions: [],
                                showSuggestions: false,
                                totalSuggestions: 0,
                            });
                        }
                    } else {
                        setState({
                            ...state,
                            filteredSuggestions: [],
                            showSuggestions: false,
                            totalSuggestions: 0,
                        });
                    }
                }
            });
            call.catch((err) => {
                console.log(err);
            });
        } else {
            setState({
                ...state,
                filteredSuggestions: [],
                showSuggestions: false,
                totalSuggestions: 0,
            });
        }
    }

    // function handleClickItem(e) {
    //     const userInput = e.currentTarget.dataset.suggested;
    //     const slug = e.currentTarget.dataset.slug;
    //     setValue('search', userInput);
    //     setValue('slug', slug);
    //     setTimeout(() => {
    //         setState({
    //             ...state,
    //             activeSuggestion: 0,
    //             filteredSuggestions: [],
    //             showSuggestions: false,
    //             userInput: userInput,
    //         });
    //     }, 200);

    //     onSubmit(getValues(), e);
    // }

    // function handleHoverItem(index) {
    //     setState({ ...state, activeSuggestion: index });
    // }

    function handleKeyDown(e) {
        const { activeSuggestion, filteredSuggestions } = state;

        if (e.keyCode === 13) {
            // setValue('search', filteredSuggestions[activeSuggestion]?.result);
            // setValue('slug', filteredSuggestions[activeSuggestion]?.slug);
            setTimeout(() => {
                setState({
                    ...state,
                    activeSuggestion: 0,
                    filteredSuggestions: [],
                    showSuggestions: false,
                    userInput: filteredSuggestions[activeSuggestion],
                    suggestions: [],
                    totalSuggestions: 0,
                });
                document.activeElement.blur();
                //currentForm.current.click();
            }, 3000);
            if (isJobsLocation) {
                //console.log('isJobsLocation');
                const jobRefValue = jobRef.current.value;
                const random = Math.random().toString(36).substring(7);
                navigate(`/jobs${jobRefValue ? '?q=' + jobRefValue : ''}`, {
                    state: {
                        search: jobRefValue,
                        place: location?.state?.place,
                        contract: location?.state?.contract,
                        type: location?.state?.type,
                        randomKey: random,
                    },
                    replace: true,
                });
            } else {
                const searchRefValue = searchRef.current.value;
                navigate('/search?q=' + searchRefValue);
            }
        } else if (e.keyCode === 38) {
            if (activeSuggestion === 0) {
                return;
            }
            setState({ ...state, activeSuggestion: activeSuggestion - 1 });
        } else if (e.keyCode === 40) {
            if (activeSuggestion + 1 === filteredSuggestions.length) {
                return;
            }
            setState({ ...state, activeSuggestion: activeSuggestion + 1 });
        }
    }

    function handleSubmitOnClick() {
        currentForm.current.click();
    }

    function onSubmit(data, e) {
        // e.preventDefault();
        // console.log(state.userInput);
        const { activeSuggestion, filteredSuggestions } = state;
        setTimeout(() => {
            //reset();
            setState({
                ...state,
                activeSuggestion: 0,
                filteredSuggestions: [],
                showSuggestions: false,
                userInput: filteredSuggestions[activeSuggestion],
                suggestions: [],
                totalSuggestions: 0,
            });
            if (isJobsLocation) {
                const random = Math.random().toString(36).substring(7);
                navigate(`/jobs${q ? '?q=' + q : ''}`, {
                    state: { search: q, place: location?.state?.place, contract: location?.state?.contract, type: location?.state?.type, randomKey: random },
                    replace: true,
                });
            } else {
                navigate('/search?q=' + data?.search);
            }
        }, 1000);
    }

    function handleSelectSuggestionTag(e, suggestion) {
        e.preventDefault();

        setTimeout(() => {
            setState({
                ...state,
                activeSuggestion: 0,
                filteredSuggestions: [],
                showSuggestions: false,
                userInput: '',
                suggestions: [],
                totalSuggestions: 0,
            });
            setValue('search', suggestion);
            setValue('jobs', suggestion);
        }, 1000);
    }

    function handleChangePlace(latlng, value) {
        setValue('address', value);
        setValue('point', latlng);
        setAddress(value);
        if (isJobsLocation) {
            const random = Math.random().toString(36).substring(7);
            navigate(`/jobs${q ? '?q=' + q : ''}`, {
                state: { search: q, place: latlng, contract: location?.state?.contract, type: location?.state?.type, randomKey: random },
                replace: true,
            });
        }
    }

    return (
        <form
            className={`mainSearchBar ${props?.isInNavBar ? '' : 'order-3 md:order-2'} ${isSearchActive ? 'w-full transition-all' : ''}`}
            onSubmit={handleSubmit(onSubmit)}
            autoComplete="off"
        >
            {/* <div>
                <div onClick={handleCreateRoom} className={`my-4 relative -left-1`}>
                    <Button label="Créer un salon" css="button-secondary relative" />
                </div>
            </div> */}
            {/* if url contains /jobs or /freelances, we display the search bar */}
            {isJobsLocation ? (
                <div className={`w-full relative mainSearchBar__suggest flex my-4 items-center`} onKeyDown={handleKeyDown}>
                    <div className="w-1/2 flex">
                        <FieldInput
                            currentRef={jobRef}
                            name="jobs"
                            label={false}
                            inputForm="rounded"
                            {...(props.isSearchPage && {
                                searchMain: true,
                                className: 'w-[calc(100% - 2.5rem)]',
                            })}
                            placeholder={isMobile() ? 'Chercher...' : 'Trouver un job, un freelance,...'}
                            register={register}
                            errors={errors}
                            textErrorColor="relative text-red text-xs"
                            option={{
                                isSearchPage: true,
                            }}
                            onClickOption={() => {
                                handleSubmitOnClick();
                            }}
                        />
                    </div>
                    <div className="w-1/2">
                        <SearchFilterPlace defaultValue={address} refetch={(latlng, value) => handleChangePlace(latlng, value)} isJobsPage={true} />
                    </div>
                </div>
            ) : (
                <div
                    className={`relative mainSearchBar__suggest flex gap-2 my-4 items-center ${isSearchActive ? 'left-0 right-0' : ''}`}
                    onKeyDown={handleKeyDown}
                >
                    <FieldInput
                        currentRef={searchRef}
                        name="search"
                        label={false}
                        inputForm="rounded"
                        {...(props.isSearchPage && {
                            searchMain: true,
                            className: 'w-[calc(100% - 2.5rem)]',
                        })}
                        placeholder={isMobile() ? 'Chercher...' : 'Trouver un job, un freelance, un profil, un shoot, une compétence...'}
                        register={register}
                        errors={errors}
                        textErrorColor="relative text-red text-xs"
                        option={{
                            isSearchPage: true,
                        }}
                        onClickOption={() => {
                            handleSubmitOnClick();
                        }}
                    />
                    {isSearchLocation && !props?.isInNavBar && (
                        <div className="w-auto">
                            <Button
                                onClick={() => {
                                    handleSubmit(onSubmit)();
                                }}
                                label={'Rechercher'}
                                css={`linkCard border w-auto p-4 font-bold`}
                            />
                        </div>
                    )}
                </div>
            )}

            {state.suggestions.length > 0 && (
                <div className="ml-2">
                    {state.suggestions.map((suggestion, index) => (
                        <div key={index} className="flex flex-col">
                            <span className="cursor-pointer" onClick={(e) => handleSelectSuggestionTag(e, suggestion)}>
                                {suggestion}
                            </span>
                        </div>
                    ))}
                </div>
            )}

            <div>
                {state.totalSuggestions > 0 && (
                    <div className={`relative ${props?.isInNavBar && 'pb-4'}`}>
                        <span className="absolute top-0 sm:top-1 pb-1 left-0 p-0 m-0 text-xs text-gray-500">
                            {state.totalSuggestions >= 20 && '+ de '}
                            {state.totalSuggestions} résultat
                            {state.totalSuggestions > 1 ? '(s)' : ''}
                        </span>
                    </div>
                )}
                {/* {state.showSuggestions && state.userInput && state.filteredSuggestions.length > 0 && ( */}
                {/*     <ul className="mainSearchBar__suggest__results"> */}
                {/*         {state.filteredSuggestions.map((suggestion, index) => ( */}
                {/*             <li key={index} onClick={handleClickItem} onMouseOver={() => handleHoverItem(index)} className={`mainSearchBar__suggest__results__item ${state.activeSuggestion === index && 'active'}`} data-suggested={suggestion.result} data-slug={suggestion.slug}> */}
                {/*                 <span className="inline w-full text-white">{suggestion.result}</span> */}
                {/*                 {' - '} */}
                {/*                 <span className="text-sm text-gray-400">{humanizeEntity[suggestion.entity]}</span> */}
                {/*             </li> */}
                {/*         ))} */}
                {/*     </ul> */}
                {/* )} */}
            </div>

            <input ref={currentForm} type="submit" className="hidden" />
        </form>
    );
}

export default MainSearchBar;
