import API from './axios.config';
import authHeader from 'services/Auth.Header';

//GET
async function getUsers() {
    const response = await API.get('users');
    return response.data;
}
async function getUsersP(params) {
    const response = await API.get('users/p/' + params);
    return response.data;
}
async function getUser(slug) {
    const response = await API.get('users/' + slug);
    return response.data;
}
async function getMe() {
    const response = await API.get('users/me', { headers: authHeader() });
    return response.data;
}
async function getSubscribers() {
    const response = await API.get('users/subscribers', { headers: authHeader() });
    return response.data;
}
async function getUsersByInterestsP(params) {
    const response = await API.get('users/interests/p/' + params, { headers: authHeader() });
    return response.data;
}
async function getUserPlans() {
    const response = await API.get('users/plans/all', { headers: authHeader() });
    return response.data;
}
async function searchUsers(params) {
    const response = await API.get('users/search' + params, { headers: authHeader() });
    return response.data;
}

//PUT
async function putProfile(data, onProgress) {
    const response = await API.put('users/profile', data, {
        headers: authHeader(),
        onUploadProgress: onProgress,
    });
    return response;
}
async function putAiTrain(data) {
    const response = await API.put('users/aiTrain', data, { headers: authHeader() });
    return response;
}
async function putPassword(data) {
    const response = await API.put('users/reset', data, {
        headers: authHeader(),
    });
    return response;
}
async function putProfilePublish() {
    const response = await API.put(
        'users/profile/togglePublish',
        { publish: true },
        {
            headers: authHeader(),
        }
    );
    return response;
}
async function putProfileNeverUpdate() {
    const response = await API.put(
        'users/profile/toggleNeverUpdate',
        { publish: false },
        {
            headers: authHeader(),
        }
    );
    return response;
}
async function putUserPlan(data) {
    const response = await API.put('users/plans/update', data, {
        headers: authHeader(),
    });
    return response;
}

//POST
async function postMessage(data) {
    const response = await API.post('users/sendProfilMessage', data, {
        headers: authHeader(),
    });
    return response;
}
async function postDisableAccount(data) {
    const response = await API.post('users/disableAccount', data, {
        headers: authHeader(),
    });
    return response.data;
}

async function HeadlessConnect(data) {
    const response = await API.post('headless/login', data);
    return response.data;
}

//DELETE
async function deleteMedia(id) {
    const response = await API.delete('users/medias/' + id, {
        headers: authHeader(),
    });
    return response.data;
}

async function sendTokenToServer(data) {
    const response = await API.post('users/save-token', data, {
        headers: authHeader(),
    });
    return response.data;
}

export {
    getUsers,
    getUsersP,
    getUsersByInterestsP,
    getUser,
    getMe,
    getSubscribers,
    getUserPlans,
    putProfile,
    putPassword,
    putProfilePublish,
    putProfileNeverUpdate,
    putAiTrain,
    putUserPlan,
    postMessage,
    postDisableAccount,
    deleteMedia,
    HeadlessConnect,
    sendTokenToServer,
    searchUsers,
};
