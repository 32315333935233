import { useEffect, useRef, Fragment, useContext, useState, useCallback } from 'react';

//CONTEXTS
import { AuthContext } from 'context/authContext';

//API
import { useInfiniteQuery } from 'react-query';
import { getAllPosts } from 'api/forum';

//HOOKS
import UseIntersectionObserver from 'hooks/useIntersectionObserver';

//CONTAINERS
import CardFrontRoom from 'containers/rooms/CardFrontRoom';
//UTILS
// import { isFoundByKey } from 'utils/functions';

function useInfinitePosts(byInterest, isEnabled = true) {
    const [authState] = useContext(AuthContext);

    //REFS
    const page = useRef(0);
    const hasNextPage = useRef(false);
    const loadMoreButtonRef = useRef();

    const [tab, setTab] = useState(null);

    const globalRooms = useInfiniteQuery(
        ['infinitePosts', byInterest],
        ({ pageParam = 0 }) => getAllPosts(`?p=${pageParam}&l=20&i=${byInterest}${authState?.me?.id ? '&u=' + authState?.me?.id : ''}`),
        {
            getNextPageParam: (lastPage) => {
                if (lastPage.hasNextPage) {
                    hasNextPage.current = lastPage.hasNextPage;

                    return (page.current = lastPage.page);
                } else {
                    hasNextPage.current = false;
                    page.current = 0;
                }
                return;
            },
            /* enabled: isEnabled, */
            enabled: true,
        }
    );

    //HOOKS
    UseIntersectionObserver({
        target: loadMoreButtonRef,
        onIntersect: (isVisible) => {
            if (isVisible) globalRooms.fetchNextPage();
        },
        enabled: !!hasNextPage.current,
    });

    useEffect(() => {
        /* if(!isEnabled) return; */
        globalRooms.refetch();
        //eslint-disable-next-line
    }, [authState.isLogged, isEnabled]);

    const tabGlobal = useCallback(() => {
        return (
            <>
                {globalRooms.data?.pages && (
                    <div className="mt-4">
                        <>
                            {globalRooms.data?.pages?.map((pages, index) => (
                                <Fragment key={index}>
                                    {pages.docs.map((room, index) => {
                                        return <CardFrontRoom key={index} room={room} refetch={globalRooms.refetch} listingQueryKeys={['infinitePosts']} />;
                                    })}
                                </Fragment>
                            ))}
                            {globalRooms.data?.pages.length === 0 && <p className="text-center mt-8 text-sm text-graySkills">Aucun post pour le moment</p>}
                        </>
                    </div>
                )}
                {hasNextPage.current && (
                    <div className="text-center mt-4 mb-14">
                        <button
                            ref={loadMoreButtonRef}
                            className="button button-secondary"
                            onClick={globalRooms.fetchNextPage}
                            disabled={!hasNextPage.current || globalRooms.isFetchingNextPage}
                        >
                            {globalRooms.isFetchingNextPage ? 'Chargement...' : 'Voir plus'}
                        </button>
                    </div>
                )}
            </>
        );
        //eslint-disable-next-line
    }, [globalRooms.data, globalRooms.isSuccess]);

    useEffect(() => {
        if (globalRooms?.data?.pages[0].totalDocs === 0) {
            return setTab(null);
        }
        setTab(tabGlobal);
    }, [tabGlobal, globalRooms.data, authState.isLogged]);

    return tab;
}

export default useInfinitePosts;
