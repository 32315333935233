import _ from 'lodash';

export default class Filters {
    pagesByRoles(item, role) {
        if (item.page?.role) {
            return _.filter(item.page?.role, (o) => o === role)?.length > 0 ? true : false;
            // return role?.includes(item.page.role) ? true : false;
        } else {
            return true;
        }
    }
}
