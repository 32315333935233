//API
import { useQuery } from "react-query";
import { getRoomsByMe } from "api/forum";
//COMPONENTS
import CardRoom from "../rooms/CardRoom";

function ListRooms() {
    //API
    const rooms = useQuery("rooms", getRoomsByMe);

    return (
        <>
            {rooms.isSuccess && (
                <div className="cards">
                    {rooms.data.map((room, index) => (
                        <CardRoom
                            room={room}
                            refetch={rooms.refetch}
                            key={index}
                        />
                    ))}
                </div>
            )}
        </>
    );
}

export default ListRooms;
