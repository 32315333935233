import { useEffect, useState } from "react";
//LIBS
// import { Link } from "react-router-dom";
import { toast } from "react-toastify";
//API
import { putRoomPublish, deleteRoom } from "api/forum";
//ICONS
import {
    EyeIcon,
    EyeOffIcon,
    // PencilIcon,
    // TrashIcon,
} from "@heroicons/react/solid";
import Modal from "components/common/Modal";

function CardRoom({ room, refetch }) {
    const [item, setItem] = useState([]);
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

    function handleTogglePublish() {
        putRoomPublish(item.id).then((data) => {
            setItem({ ...item, publish: data.publish });
            const msg = data.publish ? "Room publiée" : "Room dépubliée";
            toast(msg, { type: "success" });
        });
    }
    function handleDelete() {
        deleteRoom(item.id).then((data) => {
            toast("Room supprimée", { type: "success" });
            refetch();
            setOpenConfirmDelete(false);
        });
    }

    useEffect(() => {
        setItem(room);
        //eslint-disable-next-line
    }, [, room]);

    return (
        <>
            <Modal
                title="Supprimer une Room"
                open={openConfirmDelete}
                onClose={{
                    onClick: () => {
                        setOpenConfirmDelete(false);
                    },
                }}
                onConfirm={{
                    label: "Supprimer",
                    onClick: handleDelete,
                }}
            >
                <p>Voulez-vous vraiment supprimer cette Room ?</p>
            </Modal>
            <div className="card card-service w-12/12">
                <div className="card-header">
                    <span>{item.question}</span>
                </div>

                <div className="flex flex-nowrap justify-end">
                    <div className="card-actions flex flex-nowrap">
                        <button
                            onClick={handleTogglePublish}
                            className={`flex ml-2 ${
                                item.publish
                                    ? "text-success-400"
                                    : "text-warning-300"
                            }`}
                        >
                            <>
                                {item.publish ? (
                                    <>
                                        <EyeIcon className="w-5 h-5" /> Publier
                                    </>
                                ) : (
                                    <>
                                        <EyeOffIcon className="w-5 h-5" />{" "}
                                        Dépublier
                                    </>
                                )}
                            </>
                        </button>
                        {/* <Link to={`/rooms/${item.slug}`} className="flex">
                            <PencilIcon className="w-5 h-5" />
                        </Link> */}
                        {/* <button
                            onClick={() => setOpenConfirmDelete(true)}
                            className="text-danger-500"
                        >
                            <TrashIcon className="w-5 h-5 " />
                        </button> */}
                    </div>
                </div>
            </div>
        </>
    );
}

export default CardRoom;
