import { useState, useEffect } from 'react';
//LIBS
import { Switch } from '@headlessui/react';
//API
import { enableSocialNotification } from 'api/notifications';

function CardOptionsNotifications({ typeNotification, refetch }) {
    const [item, setItem] = useState(typeNotification);
    //console.log(refetch)

    useEffect(() => {
        setItem(typeNotification);
        //eslint-disable-next-line
    }, [, typeNotification]);

    const messages = {
        /* like_answer: {
            label: 'Réponses aimées',
        },
        like_answerTo: {
            label: "Réponses aimées d'une réponse",
        }, */
        subscription: {
            label: 'Abonnement à mon profil',
        },
        subscribe_room: {
            label: 'Abonnement à mes rooms',
        },
        room_created_subscribed: {
            label: "Rooms qui correspondent à l'un de mes tags",
        },
        room_created_hasTags: {
            label: 'Rooms qui correspondent à mon/mes tag(s) principal/aux',
        },
        room_created_admin: {
            label: "Confirmation de publication de ma room",
        },
        send_profil_message: {
            label: 'Messages reçus',
        },
        select_answer: {
            label: 'Réponses sélectionnées',
        },
    };

    function enableNotification() {
        enableSocialNotification({
            typeNotification: item.type,
        }).then((data) => {
            refetch();
        });
    }

    if (!messages?.[item.type]?.label) return null;

    return (
        <>
            <Switch.Group as="div" className="w-full flex gap-2 mb-3">
                <Switch.Label as="h1" className="w-full text-mainText">
                    {messages?.[item.type]?.label}
                </Switch.Label>
                <div className="justify-end self-center">
                    <Switch
                        checked={item.isEnable}
                        onChange={enableNotification}
                        className={`${item.isEnable ? 'bg-blueMain' : 'bg-graySkills'} relative inline-flex items-center h-6 rounded-full w-11 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                    >
                        <span className={`${item.isEnable ? 'translate-x-6' : 'translate-x-1'} inline-block w-4 h-4 transform bg-white rounded-full transition-transform`} />
                    </Switch>
                </div>  
            </Switch.Group>
        </>
    );
}

export default CardOptionsNotifications;
