import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';

//UTILS
import config from 'utils/constants';

//COMPONENTS
import Moment from 'components/common/Moment';

const sizes = {
    avatar: '?w=120&h=120&c=true&q=80',
};

function PoleEmploiCardProfilRoom({ company, partenaire, room, hiddenDate }) {

    const [dateIsHidden, setDateIsHidden] = useState(false);

    useEffect(() => {
        if (hiddenDate === true) {
            setDateIsHidden(true);
        } else {
            setDateIsHidden(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="relative w-full my-2">
                {company?.nom  ? (
                    <div className="flex flex-row items-center w-full relative">
                        {company.logo ? (
                            <div className="flex-none relative w-14 h-auto mr-2">
                                <img className="rounded-full" src={company?.logo} alt={''} />
                            </div>
                        ) : (
                            <div className="flex-none relative w-14 h-auto mr-2">
                                <img className="rounded-full" src={`${config.publicDir}skillsLogo.jpg` + sizes.avatar} alt={''} />
                            </div>
                        )}
                        <div className="w-auto grow flex flex-wrap flex-col">
                            { room?.user?.profile?.slug ? (
                                <Link to={'/profil/' + room?.user?.profile?.slug} title={`Profil de ${room?.user?.profile?.pseudo}`}>
                                    <div className="w-auto grow flex flex-wrap flex-col">
                                        <span className="text-sm md:text-l font-bold text-purpleSkills hover:text-blueMain">{room?.user?.profile?.pseudo}</span>
                                        <span className="text-sm font-normal text-graySkills">{room?.user?.profile?.status}</span>
                                        {room && dateIsHidden === false && (
                                            <span className="text-graySkills font-normal text-sm">
                                                <Moment date={room?.createdAt} fromNow />
                                            </span>
                                        )}
                                    </div>
                                </Link>
                            ) : (
                                <div className="w-auto grow flex flex-wrap flex-col">
                                    <span className="text-sm md:text-l font-bold text-purpleSkills hover:text-blueMain">{company?.nom}</span>
                                    {room && dateIsHidden === false && (
                                        <span className="text-graySkills font-normal text-sm">
                                            <Moment date={room?.createdAt} fromNow />
                                        </span>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                ) : partenaire?.nom ? (
                    <div className="flex flex-row items-center w-full relative">
                    {partenaire.logo ? (
                        <div className="flex-none relative w-14 h-auto mr-2">
                            <img className="rounded-full" src={partenaire?.logo} alt={''} />
                        </div>
                    ) : (
                        <div className="flex-none relative w-14 h-auto mr-2">
                            <img className="rounded-full" src={`${config.publicDir}skillsLogo.jpg` + sizes.avatar} alt={''} />
                        </div>
                    )}
                        <div className="w-auto grow flex flex-wrap flex-col">
                            <div className="w-auto grow flex flex-wrap flex-col">
                                <span className="text-sm md:text-l font-bold text-purpleSkills hover:text-blueMain">{partenaire?.nom}</span>
                                {room && dateIsHidden === false && (
                                    <span className="text-graySkills font-normal text-sm">
                                        <Moment date={room?.createdAt} fromNow />
                                    </span>
                                )}
                            </div>
                        </div>
                </div>
                ) : (
                    <div className="flex flex-row w-full relative">
                        <div className="flex-none relative w-14 h-auto mr-2">
                            <img className="rounded-full" src={`${config.publicDir}Logo-France-TRAVAIL.png` + sizes.avatar} alt={''} />
                        </div>
                        <div className="w-auto grow flex flex-wrap flex-col justify-center">
                            <span className="text-sm md:text-l font-bold text-purpleSkills hover:text-blueMain">Offre France Travail</span>
                            {room && dateIsHidden === false && (
                                <span className="text-graySkills font-normal text-sm">
                                    <Moment date={room?.createdAt} fromNow />
                                </span>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}

export default PoleEmploiCardProfilRoom;