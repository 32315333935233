import { useEffect, useState, useContext } from 'react';
//LIBS
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { AuthContext } from 'context/authContext';

import { postCounter } from 'api/counters';
import { shareNeedToUser } from 'api/matchingNeeds';

//UTILS
import config from 'utils/constants';
import { isFoundByKey } from 'utils/functions';

//CONTAINERS
import Avatar from 'containers/profils/Avatar';
// import PopperProfil from 'containers/profils/PopperProfil';

const sizes = {
    avatar: '?w=120&h=120&c=true&q=80',
};

function CardProfilSmall(props) {
    const [authState] = useContext(AuthContext);

    const [needShare, setNeedShare] = useState(false);

    useEffect(() => {
        const sended = isFoundByKey(props?.user?.counters, 'user', authState?.me?.id) && isFoundByKey(props?.user?.counters, 'type', 'shareNeed');
        setNeedShare(sended);
    }, [authState?.me?.id, props?.user?.counters]);

    function handleSendNeedToggle() {
        if (authState.isLogged) {
            postCounter({ type: 'shareNeed', to: props?.user?.id || props?.user?._id, sharedFromUser: props?.need?.user?._id})
                .then((res) => {
                    setNeedShare((prevState) => !prevState);
                    if (props?.need?._id && props?.user?._id && authState?.me?.id) {
                        shareNeedToUser({ needId: props?.need?._id, to: props?.user?._id, from: authState?.me?.id}).then((data) => {
                            toast('Envoyé', { type: 'success' });
                            props?.refetch();
                        });
                    }
                })
                .catch((error) => {
                    console.error('Erreur lors de la souscription :', error);
                });
        } else {
            document.getElementById('button-login')?.click();
        }
    }

    if (!props?.user?.profile) return null;

    return (
        <>
            <div className={`w-full ${!props?.isSharing && "py-1"} hover:bg-white hover:cursor-pointer rounded-full`}>
                {/* <Popper content={<PopperProfil />}> */}
                {props?.isAnonymous !== true ? (
                    <div className="flex gap-2 flex-row items-center w-full relative">
                        <Avatar user={props?.user} className="flex-none w-14" onClick={false} />
                        <Link
                            className={`w-full relative`}
                            onClick={props?.onClick ?? null}
                            to={!props?.onClick ? '/profil/' + props?.user?.profile?.slug : false}
                            title={`Profil de ${props?.user?.profile?.pseudo ? props?.user?.profile?.pseudo : props?.user?.profile?.toSlug}`}
                        >
                            <div className={`break-words grow flex flex-wrap flex-col`}>
                                <span className="w-full text-sm text-purpleSkills font-bold" style={{ maxWidth: '70%' }}>
                                    {props?.user?.profile?.pseudo ? props?.user?.profile?.pseudo : props?.user?.profile?.toSlug}
                                    {props?.isSharing === true && (
                                        <span className="w-full text-center text-sm text-purpleSkills font-bold" style={{ maxWidth: '70%' }}>
                                            {" vous recommande :"}
                                        </span>
                                    )}
                                </span>
                                {!props?.isSharing && (
                                    <span className="text-xs text-gray-400">{props?.user?.profile?.status}</span>
                                )}
                            </div>
                        </Link>
                        {props?.isSearch === true && (
                            <button
                                onClick={(e) => {
                                    e?.preventDefault();
                                    e?.stopPropagation();
                                    handleSendNeedToggle();
                                }}
                                className={`h-8 flex gap-2 ${needShare ? 'linkCard red active' : 'linkCard-purpleSkills'}`}
                                disabled={needShare}
                            >
                                {needShare ? 'Envoyé' : 'Envoyer'}
                            </button>
                        )}
                        
                    </div>
                ) : (
                    <div className="flex flex-row w-full relative">
                        <div className="flex-none relative w-12 h-auto mr-2">
                            <img className="rounded-full" src={`${config.publicDir}profil_anonyme.png` + sizes.avatar} alt={''} />
                        </div>
                        <div className="w-auto grow flex flex-wrap flex-col">
                            <span className="text-sm md:text-xl text-purpleSkills font-bold">Utilisateur anonyme</span>
                        </div>
                    </div>
                )}
                {/* </Popper> */}
            </div>
        </>
    );
}

export default CardProfilSmall;
