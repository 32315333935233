import React, { useContext, useRef, useEffect, useCallback, useState } from 'react';

//API
import { putProfile } from 'api/users';
//COMPONENTS
import profileOptions from 'components/forms/options/profile';
import FieldInput from 'components/forms/fields/input';
import TextareaCustom from 'components/forms/fields/TextareaCustom';
//import Tags from 'components/forms/fields/Tags';
import FieldFiles from 'components/forms/fields/Files';
import ProgressBar from 'components/common/ProgressBar';

//CONFIG
import config from 'utils/constants';

//CONTEXTS
import { AuthContext } from 'context/authContext';
import { FormContext } from 'context/formContext';
//FORMS
import schema from 'components/forms/yup/stepProfile';

//HOOKS
import useCurrentForm from 'hooks/useForm';

//LIBS
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';

//STYLES
import 'components/forms/forms.css';
import { XCircleIcon } from '@heroicons/react/outline';
import { isMobile } from 'utils/functions';

function FormProfilStep(props) {
    const [readyToWatch, setReadyToWatch] = useState(false);
    const [presentationInitialValue, setPresentationInitialValue] = useState('');
    const [objectifsInitialValue, setObjectifsInitialValue] = useState('');
    //const [tagsInitialValue, setTagsInitialValue] = useState([]);
    const [avatarUrl, setAvatarUrl] = useState(null);

    // eslint-disable-next-line no-unused-vars
    // const [medias, setMedias] = useState([]);
    // eslint-disable-next-line no-unused-vars
    // const [experiences, setExperiences] = useState([]);
    // eslint-disable-next-line no-unused-vars
    // const [qualifications, setQualifications] = useState([]);
    const [progressBar, setProgressBar] = useState({ active: false, value: 0 });

    //CONTEXTS
    const [authState, , refresh] = useContext(AuthContext);
    const [formContextState, formContextDispatch] = useContext(FormContext);
    //REFS
    const currentForm = useRef(null);
    const seeLink = useRef(null);

    //HOOKS
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        setValue,
        getValues,
        clearErrors,
    } = useForm({
        resolver: yupResolver(schema),
    });

    //DISPATCH
    const dispatchAction = useCallback(() => {
        switch (formContextState.action) {
            case 'save':
                currentForm.current.click();
                break;
            default:
        }
        // eslint-disable-next-line
    }, [formContextState.action]);

    //EFFECTS
    useEffect(() => {
        refresh();
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (readyToWatch) {
            clearErrors();
        }
        //eslint-disable-next-line
    }, [readyToWatch]);

    useEffect(() => {
        dispatchAction();
    }, [dispatchAction]);

    useEffect(() => {
        const me = authState.me;
        if (me) {
            seeLink.current = '/profil/' + me.profile?.slug;
            formContextDispatch({ type: 'publish', status: true });

            setAvatarUrl(me.profile?.avatar?.url ? config.serverUrl + me.profile?.avatar?.url : '');
            setValue('avatarUrl', me.profile?.avatar?.url ? true : false);
            setValue('displayVerif', me.profile?.display);
            setValue('status', me.profile?.status);
            setValue('address', me.profile?.address);
            setValue('point', me.location?.coordinates?.join(','));
            setValue('presentation', me.profile?.presentation);
            setValue('objectifs', me.profile?.objectifs);
            setValue('typeSteps', props.typeSteps);

            setPresentationInitialValue(me.profile?.presentation);
            setObjectifsInitialValue(me.profile?.objectifs);
            //setTagsInitialValue(me.profile?.tags);

            setTimeout(() => {
                setReadyToWatch(true);
            }, 1000);
        }
        //eslint-disable-next-line
    }, [authState]);

    useCurrentForm(readyToWatch, watch);

    const onSubmitClose = () => {
        handleSubmit((data) => onSubmit(data, 'close'))();
    };

    const onSubmitNext = () => {
        handleSubmit((data) => onSubmit(data, 'next'))();
    };

    const onSubmit = (data, button) => {
        if (errors.length > 0) return false;

        data.display = data.society === '' ? 'realnames' : data.display;

        const fd = new FormData();
        for (var k in data) {
            if (data[k] !== undefined) {
                let item = null;
                item = data[k];
                if (k === 'tags') {
                    item = JSON.stringify(data[k]);
                }
                if (k === 'avatar') {
                    let files = data[k];
                    for (let i = 0; i < files.length; i++) {
                        fd.append(k, files[i]);
                    }
                } else fd.append(k, item);
            }
        }

        const put = putProfile(fd, function (e) {
            let progress = (e.loaded / e.total) * 100;
            formContextDispatch({ type: 'progress', status: progress });

            setProgressBar({ ...progressBar, active: true, value: progress });
        });
        put.then((res) => {
            if (res.status === 200) {
                if (!isMobile()) {
                    toast('Profil mis à jour', { type: 'success' });
                }

                if (button === 'close') {
                    console.log('clone creation');
                    props.handleCloneCreate();
                    props.handleGoToProfile();
                    props.setPage(0);
                } else if (button === 'next') {
                    props.setPage(props.page + 1);
                }
                refresh();

                setTimeout(() => {
                    formContextDispatch({ type: 'change', status: false });
                    setTimeout(() => {
                        formContextDispatch({ type: 'progress', status: 0 });
                    }, 400);
                }, 100);
            } else {
                toast('Une erreur est survenue', { type: 'danger' });
            }
        });
    };

    //OPTIONS
    function dispatchOptionsActions(action, name, value) {
        switch (action) {
            case 'onValidateOption':
                return onValidateOption(name, value);
            case 'onChangeOption':
                return onChangeOption(name, value);
            case 'onChangeOptionPseudo':
                return onChangeOptionPseudo(name, value);
            case 'onChangeOptionAddress':
                return onChangeOptionAddress(name, value);
            default:
        }
    }
    //FUNCTIONS OPTIONS
    function onChangeOption(name, value) {
        if (name === 'videoUrl') setValue('video', value);
    }
    function onChangeOptionPseudo(name, value) {
        setValue('pseudoOption', value);
    }
    function onChangeOptionAddress(name, value) {
        setValue(name, value);
    }
    function onValidateOption(name, value) {
        return true;
    }

    return (
        <>
            <div className="flex flex-col">
                {/* <h1 className="font-bold text-2xl text-purpleSkills pb-4">Vos informations afin de profiter au maximum de SkillsMarket</h1> */}
                <form onSubmit={(e) => e.preventDefault()} autoComplete="off" encType="multipart/form-data">
                    {props.typeSteps !== 'ai' && (
                        <div className="absolute top-2 right-2">
                            <XCircleIcon className={`w-10 h-10 text-graySkills hover:text-blueMain hover:cursor-pointer`} onClick={onSubmitClose} />
                        </div>
                    )}

                    <ProgressBar progress={progressBar.value} />
                    {props.typeSteps === 'ai' && (
                        <div className="text-white text-center">
                            <h1 className="font-bold text-3xl">Personnalisez votre Clone AI !</h1>
                            <p>Renseignez votre profil pour améliorer l'efficacité et la précision de votre clone IA.</p>
                        </div>
                    )}
                    <div className="flex flex-wrap md:flex-nowrap w-full py-2 sm:gap-4">
                        <div className="w-full justify-center">
                            {props.typeSteps !== 'ai' && (
                                <h1 className="sm:text-lg text-center text-purpleSkills font-bold mb-2">Entrez dans un univers d'opportunités !</h1>
                            )}
                            <div className="flex justify-center">
                                <FieldFiles
                                    name="avatar"
                                    className={`${props.typeSteps === 'ai' ? 'text-white' : 'text-purpleSkills'} font-bold`}
                                    type="single_rounded"
                                    label="Votre photo de profil*"
                                    multiple={false}
                                    register={register}
                                    initValue={avatarUrl}
                                    errors={errors}
                                    onChange={(value) => {
                                        setValue('avatar', value);
                                    }}
                                />
                            </div>
                            {errors && errors['avatar'] && <span className="mt-0 text-danger-400 text-sm">{errors['avatar'].message}</span>}
                        </div>
                        {/* <div className="w-full justify-center md:w-1/2"> */}
                        {/*     <h1 className="text-sm text-graySkills">Image de couverture*</h1> */}
                        {/*     <FieldFiles */}
                        {/*         name="cover" */}
                        {/*         type="single" */}
                        {/*         label="Votre image de couverture" */}
                        {/*         multiple={false} */}
                        {/*         register={register} */}
                        {/*         initValue={coverUrl} */}
                        {/*         onChange={(value) => { */}
                        {/*             setValue('cover', value); */}
                        {/*         }} */}
                        {/*     /> */}
                        {/* </div> */}
                    </div>

                    <div className="w-full flex flex-col md:flex-row md:gap-4 mt-2">
                        <div className="w-full">
                            <>
                                {authState.me?.profile?.display !== 'society' && (
                                    <div>
                                        <FieldInput
                                            inputForm={props.typeSteps === 'ai' ? props.typeSteps : 'flat'}
                                            name="status"
                                            label={props.typeSteps === 'ai' ? 'Que faites-vous dans la vie ?*' : false}
                                            placeholder={props.typeSteps === 'ai' ? '' : 'Que faites-vous dans la vie ?*'}
                                            /*  placeholder="Demandeur d'emploi, étudiant, en poste" */
                                            className={`${props.typeSteps === 'ai' ? 'ai' : 'text-mainText'} text-sm mb-2`}
                                            errors={errors}
                                            register={register}
                                            maxLength={60}
                                        />
                                    </div>
                                )}
                                <div className="mt-2">
                                    <TextareaCustom
                                        type={props.typeSteps ? props.typeSteps : ''}
                                        name="objectifs"
                                        label={props.typeSteps === 'ai' ? "Quel est votre but professionel aujourd'hui ?*" : false}
                                        placeholder={`${
                                            props.typeSteps === 'ai' ? 'Nous pourrons vous aider à les atteindre' : "Quel est votre but professionel aujourd'hui ?*"
                                        }`}
                                        /* placeholder="Nous pourrons vous aider à les atteindre" */
                                        rows={1}
                                        className={`${
                                            props.typeSteps === 'ai' ? 'inputAi_floating text-white placeholder:text-graySkills' : 'input_flat text-mainText'
                                        }`}
                                        autoresize={true}
                                        errors={errors}
                                        register={register}
                                        initValue={objectifsInitialValue}
                                        onChange={(e) => {
                                            setValue('objectifs', e.target.value);
                                        }}
                                    />
                                </div>
                                {/* <label className={`font-medium text-sm ${props.typeSteps === "ai" ? 'text-white' :'text-graySkills'}`}>TAGS: vos compétences ("entrée" ou "virgule" pour ajouter un tag)*</label>
                                <Tags
                                    type={props.typeSteps}
                                    name="tags"
                                    // label="Vos compétences (en tags*)"
                                    register={register}
                                    errors={errors}
                                    initValue={tagsInitialValue}
                                    onChange={(value) => {
                                        setValue('tags', value);
                                    }}
                                    countUsedTag={true}
                                    readyToWatch={readyToWatch}
                                    // selected={true}
                                /> */}

                                <div className="flex w-full mt-4">
                                    <FieldInput
                                        type={props.typeSteps === 'ai' ? props.typeSteps : 'flat'}
                                        inputForm={props.typeSteps === 'ai' ? props.typeSteps : 'flat'}
                                        typeLabel={props.typeSteps}
                                        disabled={true}
                                        name="address"
                                        label={props.typeSteps === 'ai' ? 'Ville, adresse ou région*' : false}
                                        placeholder={props.typeSteps === 'ai' ? '' : 'Ville, adresse ou région*'}
                                        depends="point"
                                        errors={errors}
                                        size="w-full  text-sm"
                                        className={`${props.typeSteps === 'ai' ? 'ai' : 'text-mainText'} text-sm`}
                                        register={register}
                                        option={profileOptions.address}
                                        dispatchOptionsActions={dispatchOptionsActions}
                                        getValues={getValues}
                                        readyToWatch={readyToWatch}
                                    />
                                </div>

                                <div className="">
                                    {/* <div className={`${authState.me?.profile?.display === 'society' ? 'sm:pb-2' : ''}`}>
                                        <label className={`font-medium text-sm ${props.typeSteps === "ai" ? "text-white" :"text-graySkills"}`}>Votre présentation{props.typeSteps === "ai" ? "*" :"*"}</label>
                                    </div> */}
                                    <TextareaCustom
                                        type={props.typeSteps ? props.typeSteps : ''}
                                        label={props.typeSteps === 'ai' ? 'Présentez-vous :*' : false}
                                        name="presentation"
                                        rows={4}
                                        className={`${
                                            props.typeSteps === 'ai' ? 'inputAi_floating text-white placeholder:text-graySkills' : 'input_flat text-mainText'
                                        }`}
                                        placeholder={props.typeSteps === 'ai' ? '' : 'Présentez-vous :*'}
                                        autoresize={true}
                                        errors={errors}
                                        register={register}
                                        initValue={presentationInitialValue}
                                        onChange={(e) => {
                                            setValue('presentation', e.target.value);
                                        }}
                                    />
                                </div>

                                {/* <div className="pt-4 md:pt-0"> */}
                                {/*     <FieldInput */}
                                {/*         inputForm="floating" */}
                                {/*         name="video" */}
                                {/*         label="Vidéo de présentation (Lien de la vidéo)" */}
                                {/*         errors={errors} */}
                                {/*         register={register} */}
                                {/*         option={profileOptions.video} */}
                                {/*         dispatchOptionsActions={dispatchOptionsActions} */}
                                {/*         getValues={getValues} */}
                                {/*         readyToWatch={readyToWatch} */}
                                {/*     /> */}
                                {/* </div> */}
                                <div className="w-full flex justify-end pt-4 pb-7">
                                    <button
                                        className={`${
                                            props.typeSteps === 'ai' ? 'bg-[#E348FF]' : 'button-border-gray bg-blueMain'
                                        } button hover:text-blueMain hover:bg-white font-bold text-white justify-end w-auto px-8 py-2 rounded-full`}
                                        onClick={onSubmitNext}
                                    >
                                        Suivant
                                    </button>
                                </div>
                                {/*  {errors && errors['address'] && <span className="mt-0 text-danger-400 text-sm">{errors['address'].message}</span>} */}
                            </>
                            {/* <FieldInput inputForm="floating" name="phone" size="w-full" label="Téléphone" maxLength={15} errors={errors} register={register} /> */}
                            {/* {authState.me?.profile?.display === 'society' && ( */}
                            {/* <FieldInput */}
                            {/*     inputForm="floating" */}
                            {/*     name="video" */}
                            {/*     label="Vidéo de présentation (Lien de la vidéo)" */}
                            {/*     errors={errors} */}
                            {/*     register={register} */}
                            {/*     option={profileOptions.video} */}
                            {/*     dispatchOptionsActions={dispatchOptionsActions} */}
                            {/*     getValues={getValues} */}
                            {/*     readyToWatch={readyToWatch} */}
                            {/* /> */}
                            {/* )} */}
                            {/*  <FieldInput
                                inputForm="floating"
                                name="video"
                                label="Vidéo de présentation (Lien de la vidéo)"
                                errors={errors}
                                register={register}
                                option={profileOptions.video}
                                dispatchOptionsActions={dispatchOptionsActions}
                                getValues={getValues}
                                readyToWatch={readyToWatch}
                            /> */}
                        </div>
                    </div>

                    <input ref={currentForm} type="submit" className="hidden" />
                </form>
            </div>
        </>
    );
}

export default FormProfilStep;
