//LIBS
// eslint-disable-next-line no-unused-vars
import { useNavigate, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
//UTILS
import { isMobile } from 'utils/functions';
//API
import { postCounter } from 'api/counters';

//ICONS
import { ArrowNarrowLeftIcon, HeartIcon } from '@heroicons/react/solid';

//CONTAINERS
import ImageSlider from 'containers/rooms/ImageSlider';
//HOOKS
import useHasCounter from 'hooks/useHasCounter';

//import CountersRoom from 'containers/rooms/CountersRoom';
import PoleEmploiCardProfilRoom from './PoleEmploiCardProfilRoom';

//CONTEXTS
import { AuthContext } from 'context/authContext';

//COMPONENTS
import Share from 'components/share/Share';
import ReactionIcons from 'components/ReactionIcons';
import Nl2br from 'components/common/Nl2br';
import { useContext } from 'react';

function PoleEmploiHeadRoom(props) {
    const [authState] = useContext(AuthContext);

    const hasBookmark = useHasCounter(props.room?.data?.counters, 'bookmark_job', props.room?.data?.id ? props.room?.data?.id : props.room?.data?._id);

    const location = useLocation();

    function handleBookmark(jobId) {
        if (authState.isLogged) {
            postCounter({ type: 'bookmark_job', to: jobId }).then(() => {
                props.room.refetch();
            });
        } else document.getElementById('button-login')?.click();
    }

    return (
        <>
            <div className="w-full">
                <div className="flex justify-between items-center mt-4">
                    {props?.goBack && (
                        <div className="hidden sm:block w-fit text-gray-200 text-md font-bold">
                            <button className="flex items-center px-0 link_basic" onClick={props?.goBack}>
                                <ArrowNarrowLeftIcon className="w-6 h-6 mr-2 relative" />
                                <span className="font-bold text-md">à la une</span>
                            </button>
                        </div>
                    )}

                    {isMobile() && (
                        <div>
                            <ImageSlider room={props.room.data} />
                        </div>
                    )}
                    <div className="hidden sm:flex items-center gap-2">
                        {props?.share !== false && !isMobile() && (
                            <div>
                                <Share type="outline" url={location.pathname} />
                            </div>
                        )}
                        {props?.jobFav !== false && props.room?.data?.type === 'job' && (
                            <div className="flex">
                                <button
                                    onClick={() => {
                                        handleBookmark(props.room?.data?._id);
                                    }}
                                >
                                    <HeartIcon className={`w-6 h-6 ${hasBookmark ? 'text-red' : 'text-graySkills'} sm:hover:text-blueMain`} />
                                </button>
                            </div>
                        )}
                    </div>
                </div>

                <div className={`${!isMobile() && 'mt-4'}`}>
                    {props.room.isSuccess && (
                        <div className="w-full flex flex-col justify-between h-full">
                            <div className="card-header mb-1 relative w-full">
                                <div className="flex items-center justify-between ">
                                    <div className="">
                                        <PoleEmploiCardProfilRoom
                                            company={props.room.data.poleEmploi?.company}
                                            partenaire={props?.room?.data?.poleEmploi?.partenaire}
                                            room={props.room.data}
                                        />
                                    </div>
                                </div>
                                <div className={'mt-6 mb-4'}>
                                    <h1 className="font-bold text-3xl text-purpleSkills">{props.room?.data?.poleEmploi?.title}</h1>
                                    {props.room.data?.tags && (
                                        <div className="flex flex-row flex-wrap gap-1">
                                            {props.room.data?.tags?.map((skill, index) => (
                                                <Link to={`/search?q=${skill.name}`} key={index} className="link_basic cursor-pointer mb-1">
                                                    {skill.name}
                                                </Link>
                                            ))}
                                        </div>
                                    )}
                                </div>
                                <div className="roomNews text-sm text-[#000a53]">
                                    <Nl2br str={props.room.data.poleEmploi?.company?.description} />
                                </div>
                                {!isMobile() && <ImageSlider room={props.room.data} />}
                                <ReactionIcons counters={props.room.data.counters} to={props?.room?.data?._id} targetType="room" type="reactions" />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default PoleEmploiHeadRoom;
