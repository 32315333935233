export default function HeadlessButton(props) {
    const theme = {
        default: 'border-[#9F9F9F] text-[#9F9F9F]',
        important: 'border-[#C131BB] bg-[#C131BB] text-[#FFFFFF]',
    };
    return (
        <div className="flex flex-row gap-1" onClick={props.onClick}>
            <button disabled={props.disabled} className={`flex flex-row items-center gap-2 text-sm border p-2 rounded-sm ${theme[props.theme ?? 'default']}`}>
                {props?.activeIcon && props?.icon}
                {props.label}
            </button>
        </div>
    );
}
