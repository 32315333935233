//API
import { getShootsByMe } from 'api/shoots';

//HOOKS
import useInfinite from 'hooks/useInfinite';

//COMPONENTS
import CardShootDashboard from 'containers/shoots/CardShootDashboard';

function ListShoots() {
    //API
    // const shoots = useQuery('dashboardShoots', getShootsByMe);
    const infiniteShoots = useInfinite(getShootsByMe, 'ListingDashboardShoots', {
        onItem: (shoot, refetch) => {
            return <CardShootDashboard shoot={shoot} refetch={refetch} />;
        },
        onNoResult: () => {
            return (
                <div className="text-center mt-8">
                    <p className="text-graySkills">Pas de shoot pour le moment</p>
                </div>
            );
        },
        msgToSee: 'Voir plus de shoots',
    });
    return <div className="cards mb-20">{infiniteShoots}</div>;
}

export default ListShoots;
