import { formatNumber } from "utils/functions";

function PointGamification(props) {
    return (
        <>
            {/* {props.gamification && ( */}
            <div className="absolute -bottom-1 -right-1 w-6 h-6 bg-opacity-20 backdrop-blur-sm bg-graySkills rounded-full flex justify-center items-center">
                <span className="relative inline-flex justify-center items-center rounded-full h-auto w-auto text-xs text-blueMain p-2">
                    {formatNumber(props.gamification ? props.gamification : 0)}
                </span>
            </div>
            {/* )} */}
        </>
    );
}

export default PointGamification;
