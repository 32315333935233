import React, { useContext } from 'react';
import { AuthContext } from '../context/authContext';
import Listing from '../pages/Listing';
import Home from '../pages/Home';
import { isMobile } from '../utils/functions';
import ListingShoots from '../pages/ListingShoots';

function HomeWelcome() {
    const [authState] = useContext(AuthContext);

    if (isMobile()) {
        return <ListingShoots />;
    } else {
        if (authState?.isLogged) {
            return <Home />;
        } else {
            return <Listing />;
        }
    }
}
export default HomeWelcome;
