import { useEffect, useState } from 'react';

const ScrollDirection = {
    up: 'up',
    down: 'down',
};

const useScroll = (reset) => {
    const threshold = 20;
    const [scrollDir, setScrollDir] = useState(ScrollDirection.up);

    useEffect(() => {
        let previousScrollYPosition = window.scrollY;

        const scrolledMoreThanThreshold = (currentScrollYPosition) => Math.abs(currentScrollYPosition - previousScrollYPosition) > threshold;

        const isScrollingUp = (currentScrollYPosition) =>
            currentScrollYPosition > previousScrollYPosition &&
            !(previousScrollYPosition > 0 && currentScrollYPosition === 0) &&
            !(currentScrollYPosition > 0 && previousScrollYPosition === 0);

        const updateScrollDirection = () => {
            const currentScrollYPosition = window.scrollY;

            if (scrolledMoreThanThreshold(currentScrollYPosition)) {
                const newScrollDirection = isScrollingUp(currentScrollYPosition) ? ScrollDirection.down : ScrollDirection.up;
                setScrollDir(newScrollDirection);
                previousScrollYPosition = currentScrollYPosition > 0 ? currentScrollYPosition : 0;
                if (reset) setScrollDir(null);
            }
        };

        const onScroll = () => window.requestAnimationFrame(updateScrollDirection);

        window.addEventListener('scroll', onScroll);

        return () => window.removeEventListener('scroll', onScroll);
        //eslint-disable-next-line
    }, []);

    return scrollDir;
};

export default useScroll;
