//API
import { useQuery } from "react-query";
import { getTransactionsReceived } from "api/transactions";
//COMPONENTS
import CardVisio from "../visios/CardVisio";

function ListVisios() {
    //API
    const visios = useQuery("visiosReceived", () =>
        getTransactionsReceived("visio")
    );

    return (
        <>
            {visios.isSuccess && (
                <div className="cards">
                    {visios.data.map((visio, index) => (
                        <CardVisio
                            transaction={visio}
                            refetch={visios.refetch}
                            key={index}
                        />
                    ))}
                </div>
            )}
        </>
    );
}

export default ListVisios;
