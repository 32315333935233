import { useEffect, useState, Fragment } from 'react';
//LIBS
import { toast } from 'react-toastify';
//API
import { putCancel, putValidate } from 'api/transactions';
import { postNotification } from 'api/notifications';
//ICONS
import { CheckIcon, XCircleIcon, AnnotationIcon } from '@heroicons/react/solid';
import Modal from 'components/common/Modal';

function CardAskVisio({ transaction, refetch }) {
    const [item, setItem] = useState([]);
    const [openConfirmCancel, setOpenConfirmCancel] = useState(false);
    const [openConfirmValidation, setOpenConfirmValidation] = useState(false);
    const [openMessageReservation, setOpenMessageReservation] = useState(false);
    const [humanStatus, setHumanStatus] = useState('');
    const [currentDate, setCurrentDate] = useState(null);
    const [currrentMessage, setCurrentMessage] = useState(null);
    const [currentIsFirstTransaction, setCurrentIsFirstTransaction] = useState(null);

    function handleCancel() {
        putCancel(item.id).then((data) => {
            refetch();
            toast('Visio annulée', { type: 'success' });
            setOpenConfirmCancel(false);
            postNotification({
                type: 'visioCanceled',
                user: item.userTo._id,
            });
        });
    }

    function handleValidation() {
        putValidate(item.id).then((data) => {
            refetch();
            toast('Visio validée !', { type: 'success' });
            setOpenConfirmValidation(false);
            postNotification({
                type: 'visioValidated',
                user: item.userTo._id,
            });
        });
    }

    useEffect(() => {
        switch (transaction.status) {
            case 'init':
                setHumanStatus("En attente d'acceptation");
                break;
            case 'canceled':
                setHumanStatus('Annulée');
                break;
            case 'accepted':
                setHumanStatus('Acceptée');
                break;
            case 'validated':
                setHumanStatus('Validée');
                break;
            default:
        }
        setItem(transaction);

        const infos = JSON.parse(transaction.infos);
        let date = Date.parse(infos.date);
        date = new Date(date).toLocaleString([], {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
        });
        setCurrentDate(date);
        setCurrentMessage(infos?.messageReservation);
        setCurrentIsFirstTransaction(infos?.isFirstTransaction);
        //eslint-disable-next-line
    }, [, transaction]);

    return (
        <>
            <Modal
                title="Annuler la demande"
                open={openConfirmCancel}
                onClose={{
                    label: 'Fermer',
                    onClick: () => {
                        setOpenConfirmCancel(false);
                    },
                }}
                onConfirm={{
                    label: 'Confirmer',
                    onClick: handleCancel,
                }}
            >
                <p>Voulez-vous annuler cette demande de Visio-consultation ?</p>
            </Modal>

            <Modal
                title={`Message de ${item?.userFrom?.profile?.pseudo}`}
                open={openMessageReservation}
                onClose={{
                    onClick: () => {
                        setOpenMessageReservation(false);
                    },
                }}
            >
                <p>
                    {currrentMessage?.split('\n').map((item, key) => {
                        return (
                            <Fragment key={key}>
                                {item}
                                <br />
                            </Fragment>
                        );
                    })}
                </p>
            </Modal>
            <Modal
                title="Valider la visio-consultation"
                open={openConfirmValidation}
                onClose={{
                    onClick: () => {
                        setOpenConfirmValidation(false);
                    },
                }}
                onConfirm={{
                    label: 'Confirmer',
                    onClick: handleValidation,
                }}
            >
                <p>Voulez-vous valider que la visio-consultation a bien été effectuée ?</p>
            </Modal>
            <div className="card p-2 w-12/12">
                <div className="card-header flex justify-between">
                    <span className="inline-block text-blue-800">
                        pour {item?.userTo?.profile?.pseudo}
                        <span className="text-sm ml-2">
                            1h
                            {!currentIsFirstTransaction && <span>- {item.amount} €</span>}
                            {currentIsFirstTransaction && <span>- Offerte</span>}
                        </span>
                    </span>
                    <span className="inline-block text-sm text-success-500">{humanStatus}</span>
                </div>
                <div className="flex justify-between">
                    <span className="inline-block mx-2">{currentDate}</span>
                </div>

                <div className="flex flex-nowrap justify-end">
                    <div className="card-actions w-full">
                        {item?.status === 'init' && (
                            <div className="flex flex-nowrap">
                                {currrentMessage && (
                                    <button onClick={() => setOpenMessageReservation(true)} className="text-success-500 flex ml-2">
                                        <AnnotationIcon className="w-5 h-5" /> Message envoyé
                                    </button>
                                )}
                                <button onClick={() => setOpenConfirmCancel(true)} className="text-danger-500 flex ml-2">
                                    <XCircleIcon className="w-5 h-5 " /> Annuler
                                </button>
                            </div>
                        )}
                        {item?.status === 'accepted' && (
                            <button onClick={() => setOpenConfirmValidation(true)} className="text-blue-500 flex ml-2">
                                <CheckIcon className="w-5 h-5" /> Valider la visio-consultation
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default CardAskVisio;
