//LIBS
import { LocationMarkerIcon /* UsersIcon */ } from '@heroicons/react/solid';
import Image from 'components/common/Image';
/* import Rating from 'components/Rating';
import { useEffect } from 'react'; */
import { Link } from 'react-router-dom';

//CONTAINERS
import Avatar from 'containers/profils/Avatar';

//UTILS
import config from 'utils/constants';
//import { countByKey } from 'utils/functions';

const sizes = {
    avatar: '?w=120&h=120&c=true&q=80',
    cover: '?h=180&c=true&q=80',
};
function CardUserSmall({ banner, user, gamification = true }) {
    if (!user?.profile) return null;

    return (
        <>
            <div className="relative w-full my-2 sm:mt-6 sm:mb-6">
                <div className="flex flex-wrap flex-col">
                    <div className="flex gap-3 flex-row items-center w-full relative">
                        <Avatar user={user} gamification={gamification} className="flex-none w-14" onClick={false} />
                        <Link
                            className="w-full"
                            to={'/profil/' + user?.profile?.slug}
                            title={`Profil de ${user?.profile?.toSlug ? user?.profile?.toSlug : user?.profile?.pseudo}`}
                        >
                            <div className="flex items-center gap-1">
                                <div className="w-full break-words grow flex flex-wrap flex-col">
                                    <span className="text-sm sm:text-lg  text-purpleSkills font-bold" style={{ maxWidth: '70%' }}>
                                        {user?.profile?.toSlug ? user?.profile?.toSlug : user?.profile?.pseudo}
                                    </span>
                                    <div className="flex gap-2 text-xs sm:text-sm text-graySkills">
                                        <span className="">{user?.profile?.status}</span>
                                        <div className="">
                                            <div className="flex flex-row gap-1">
                                                {user?.profile?.address && (
                                                    <p className="flex justify-start">
                                                        <span>
                                                            <LocationMarkerIcon className="w-4 h-4 mr-[0.10rem]" />
                                                        </span>
                                                        <span className="">
                                                            {user?.profile?.address.split(',')[1]
                                                                ? user?.profile?.address.split(',')[1]
                                                                : user?.profile?.address}
                                                        </span>
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <Link to={'/profil/' + user?.profile?.slug} title={`Profil de ${user?.profile?.toSlug ? user?.profile?.toSlug : user?.profile?.pseudo}`}>
                        {/*  {user?.profile?.cover?.url} */}
                        {banner !== false && (
                            <div className="pt-4 card_profile_images_cover mb-2">
                                {user?.profile?.cover?.url ? (
                                    <Image
                                        className="w-full rounded-lg"
                                        src={user?.profile?.cover?.url + sizes.cover}
                                        dynamic
                                        alt={user?.profile?.toSlug ? user?.profile?.toSlug : user?.profile?.pseudo}
                                    />
                                ) : (
                                    <img
                                        className="w-full rounded-lg"
                                        src={`${config.publicDir}banniere_skillsdefault2.png` + sizes.cover}
                                        alt={user?.profile?.toSlug ? user?.profile?.toSlug : user?.profile?.pseudo}
                                    />
                                )}
                            </div>
                        )}
                        {/* <div className="hidden sm:block text-graySkills">
                            <div className="flex flex-row justify-between">
                                {user?.profile?.address && (
                                    <p className="flex justify-start">
                                        <span>
                                            <LocationMarkerIcon className="w-4 h-4 mr-1 mt-0.5" />
                                        </span>
                                        <span>{user?.profile?.address.split(',')[1] ? user?.profile?.address.split(',')[1] : user?.profile?.address}</span>
                                    </p>
                                )}
                                <p className="flex justify-start">
                                    <span>
                                        <UsersIcon className="w-4 h-4 mr-1 mt-1" />
                                    </span>
                                    <span>{countByKey(user?.counters, 'type', 'subscription')}</span>
                                </p>
                            </div>
                        </div>  */}
                    </Link>
                </div>
            </div>
        </>
    );
}

export default CardUserSmall;
