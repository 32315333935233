import { useContext } from 'react';

//CONTEXTS
import { HeadlessContext } from '../index.js';

//ICONS
import { AiOutlineCloseSquare } from 'react-icons/ai';

//COMPONENTS
import HeadlessMenu from './HeadlessMenu';
import HeadlessPages from './HeadlessPages';

export default function HeadlessHome() {
    const store = useContext(HeadlessContext);

    function handleClose() {
        store.set({ type: 'close' });
    }

    return (
        <>
            <div className="fixed flex justify-center items-center z-50 top-0 left-0 w-full h-full backdrop-filter backdrop-blur-sm bg-gray-200/20">
                <div id="headless" className={`relative w-[90vw] h-[90vh] max-w-full rounded-sm shadow bg-white p-8 flex flex-col items-center`}>
                    <button onClick={handleClose} className="absolute -top-8 right-0 p-0 text-[#745C89]">
                        <AiOutlineCloseSquare className="w-6 h-6" />
                    </button>
                    <div className="flex flex-row w-full h-full">
                        <HeadlessMenu />
                        <HeadlessPages />
                    </div>
                </div>
            </div>
        </>
    );
}
