// import { useState, useRef, useEffect, useContext, useCallback } from "react";
//API
import { useQuery } from "react-query";
import { getServicesByMe } from "api/services";
//COMPONENTS
import CardService from "./CardService";

function ListServices() {
    //API
    const services = useQuery("dashboardServices", getServicesByMe);
    // services.refetch();
    return (
        <>
            {services.isSuccess && (
                <div className="cards">
                    {services.data.map((service, index) => (
                        <CardService service={service} refetch={services.refetch} key={index} />
                    ))}
                </div>
            )}
        </>
    );
}

export default ListServices;
