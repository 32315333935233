import { useEffect, useState, useRef } from 'react';
import useRequest from '../../services/useRequest';

export default function Logs(props) {
    const [content, setContent] = useState(null);
    const interval = useRef(null);

    const request = useRequest();

    function runQuery() {
        request.get(props.request).then((data) => {
            let current = '';

            const regex = /,/gi;
            const regex2 = /{|}/gi;
            const regex3 = /meta:/gi;

            current = data.replace(regex, '<br />');
            current = current.replace(regex2, '');
            current = current.replace(regex3, '<br />');

            setContent(current);
        });
    }
    useEffect(() => {
        runQuery();

        if (props.request?.refetchEvery) {
            interval.current = setInterval(runQuery, props.request.refetchEvery);
        }

        return () => clearInterval(interval.current);
        //eslint-disable-next-line
    }, []);

    return (
        <>
            <div className="text-sm">{props.label}</div>
            <div className="w-full max-h-60 overflow-auto bg-[black] text-[white] text-xs rounded-sm p-2">
                <div dangerouslySetInnerHTML={{ __html: content }} />
            </div>
        </>
    );
}
