import { useEffect, useState, useRef, useContext, forwardRef, useImperativeHandle } from 'react';

//API
import { postCounter } from 'api/counters';

//CONTEXTS
import { AuthContext } from 'context/authContext';
import { CounterContext } from 'context/counterContext';

//HOOKS
import usePostCounter from '../hooks/usePostCounter';

//LIBS
import { toast } from 'react-toastify';

//COMPONENTS
import Button from 'components/common/Button';

//ICONS
import { EmojiHappyIcon, EmojiSadIcon, CheckCircleIcon, HeartIcon as HeartIconOutline } from '@heroicons/react/outline';
import { HeartIcon, BellIcon, ThumbUpIcon, ThumbDownIcon } from '@heroicons/react/solid';
import LaughIcon from './svgs/Laugh';
import InterestingIcon from './svgs/Interesting';

const components = {
    EmojiHappyIcon,
    EmojiSadIcon,
    ThumbUpIcon,
    ThumbDownIcon,
    HeartIcon,
    HeartIconOutline,
    BellIcon,
    CheckCircleIcon,
};

const emojis = {
    mdr: <LaughIcon />,
    interesting: <InterestingIcon />,
};

function Counter(props, ref) {
    const [active, setActive] = useState(false);
    const total = useRef(0);

    const { postCounterMutate } = usePostCounter();

    const { counterDispatch } = useContext(CounterContext);

    useImperativeHandle(ref, () => ({
        getTotal: () => total.current,
    }));

    //CONTEXTS
    const [authState] = useContext(AuthContext);

    const increment = (counter) => {
        if (counter) {
            if (props.type === 'reactions') {
                if (counter.to === props.to && counter.type === props.type && counter.value === props.value) {
                    total.current++;
                    if (counter.user.id === authState.me?.id && authState.isLogged) {
                        setActive(true);
                    }
                }
            } else {
                if (counter.to === props.to && counter.type === props.type) {
                    total.current++;
                    if (counter.user.id === authState.me?.id && authState.isLogged) {
                        setActive(true);
                    }
                }
            }
        }
    };
    const getTotal = () => {
        total.current = 0;
        setActive(false);
        props.counters?.map?.((counter) => {
            /* console.log(counter) */
            if (Array.isArray(counter)) {
                counter.map((counter2) => {
                    if (Array.isArray(counter2)) {
                        counter2.map((counter3) => {
                            increment(counter3);
                            return void 0;
                        });
                    } else {
                        increment(counter2);
                    }
                    return void 0;
                });
            } else {
                increment(counter);
            }

            return void 0;
        });
    };

    var MyIcon = components[props.icon];

    useEffect(() => {
        getTotal();
        //eslint-disable-next-line
    }, [props.counters, authState.me, authState.isLogged]);

    useEffect(() => {
        props.onInit?.(props.type, props.value, active);
        //eslint-disable-next-line
    }, [active]);

    async function handleCounter(e, type, to, value) {
        e.preventDefault();
        //console.log('handleCounter', type, to, props.userTo, authState.me.id, value);
        let userTo = props?.userTo;

        if (!props.disabled && authState.me && authState.isLogged) {
            if ((props.userTo && authState.me.id !== props.userTo) || !props.userTo) {
                if (props.refetch) {
                    if (props?.listingQueryKeys) {
                        const result = await postCounterMutate({
                            type: type,
                            to: to,
                            value: value,
                            userTo: userTo,
                            isListing: true,
                            listingQueryKeys: props?.listingQueryKeys,
                        });

                        let counters = result.counters;

                        counterDispatch({
                            type: 'UPDATE_OR_ADD_COUNTERS',
                            payload: { targetId: to, counters, updatedAt: new Date().toISOString() },
                        });
                    } else {
                        try {
                            const { counters } = await postCounter({ type, to, value, userTo });

                            props.refetch?.();

                            counterDispatch({
                                type: 'UPDATE_OR_ADD_COUNTERS',
                                payload: { targetId: to, counters, updatedAt: new Date().toISOString() },
                            });
                        } catch (error) {
                            console.error('Erreur lors de la mise à jour des compteurs');
                        }
                    }
                } else {
                    try {
                        const { counters } = await postCounter({ type, to, value, userTo });

                        counterDispatch({
                            type: 'UPDATE_OR_ADD_COUNTERS',
                            payload: { targetId: to, counters, updatedAt: new Date().toISOString() },
                        });
                    } catch (error) {
                        console.error('Erreur lors de la mise à jour des compteurs');
                    }
                }
                /* if (props?.listingQueryKeys) {
                    postCounterMutate({
                        type: type,
                        to: to,
                        value: value,
                        userTo: userTo,
                        isListing: true,
                        listingQueryKeys: props?.listingQueryKeys,
                    });
                } else {
                    postCounter({ type, to, value, userTo }).then((res) => {
                        props.refetch();
                    });
                } */
            } else {
                toast('Vous ne pouvez pas voter pour vous même', { type: 'warning' });
            }
        }
        props.onClick?.();
        //closeReactionsMenu();
    }

    return (
        <div className={`counter-icon gap-1 flex flex-col ${active ? (props.activeClass ? 'bg-blueSelect' : 'text-primary') : 'text-primary'}`}>
            <div className={`flex items-center gap-1 cursor-pointer`} onClick={(e) => handleCounter(e, props.type, props?.to, props?.value)}>
                {props.icon && (
                    <Button
                        label={
                            <MyIcon
                                className={`${props.size ? props.size : 'h-8 w-8 sm:h-10 sm:w-10'} ${
                                    props.iconColor ? props.iconColor : 'text-graySkills hover:text-purpleSkills'
                                }`}
                            />
                        }
                        justIcon={true}
                        css={`p-0`}
                    />
                )}
                {props.emoji && <span className={`h-8 w-8 sm:h-10 sm:w-10 text-3xl text-center p-1`}>{emojis[props.emoji]}</span>}
                {props.label && (
                    <span className={`${props.labelColor ? `${props.labelColor}` : ''} text-sm ${props?.targetType === 'room' ? 'text-center' : ''}`}>
                        {props.label}
                    </span>
                )}
            </div>
        </div>
    );
}

export default forwardRef(Counter);
