import { useCallback } from 'react';
//UTILS
import { isMobile } from 'utils/functions';
//LIBS
import { useGtm } from 'libs/headless/modules/gtm';
import { useNavigate } from 'react-router-dom';

function Banner() {
    const gtm = useGtm();
    const navigate = useNavigate();

    const handleOpenInscription = useCallback(() => {
        //console.log("handleOpenInscription")
        if (isMobile()) {
            navigate('/login');

            if (window.ReactNativeWebView) {
                window.ReactNativeWebView.postMessage('/login');
            }

            /* window.history.replaceState(null, null, `/login`); */
        } else {
            document.getElementById('button-login')?.click();
        }

        gtm.execute('inscription');
        //eslint-disable-next-line
    }, []);

    return (
        <>
            <div className={`container-fluid w-full shadow-xl mx-auto px-0 bg-blue-400`}>
                <nav className="container-fluid mx-auto h-auto py-2">
                    <div className="relative flex w-full flex-col md:flex-row justify-center">
                        {/*  <div className="hidden sm:block mx-auto md:mx-4 mt-2">
                            <Link to="/">
                                <img src={`${config.publicDir}logo_skillsmobile.png`} alt="logo" className="h-10" />
                            </Link>
                        </div> */}
                        {/* <div className="hidden sm:block md:pl-5 text-white font-bold text-sm sm:text-xl text-center my-4 w-auto"> */}
                        {/*     Découvrez les opportunités d'un réseau d'entraide professionnel */}
                        {/* </div> */}

                        <div className="flex flex-col md:flex-row justify-center items-center">
                            <div className=" md:pl-5 text-white font-bold text-sm sm:text-xl text-left pl-4 my-auto w-auto">
                                Propulsez votre réseau professionnel et votre carrière
                            </div>

                            <div className="mx-auto my-auto px-4 mt-2 md:mt-0">
                                <button
                                    onClick={handleOpenInscription}
                                    className="h-10 bg-blueMain font-bold text-sm text-white rounded-full px-5 hover:bg-white hover:text-blueMain transition"
                                >
                                    {'Inscription / Connexion'}
                                </button>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </>
    );
}

export default Banner;
