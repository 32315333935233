import React, { createContext, useReducer } from 'react';

const CounterContext = createContext({
    counterState: [],
    counterDispatch: () => {},
});

const initialState = [];

const counterReducer = (state, action) => {
    switch (action.type) {
        case 'UPDATE_OR_ADD_COUNTERS': {
            const { targetId, counters, updatedAt } = action.payload;
            const existingTargetIndex = state.findIndex((r) => r.id === targetId);

            if (existingTargetIndex === -1) {
                //console.log("ajouter l'état");
                return [...state, { id: targetId, counters, updatedAt: updatedAt || null }];
            }

            const existingTarget = state[existingTargetIndex];
            const shouldUpdate = updatedAt ? !existingTarget.updatedAt || new Date(updatedAt) > new Date(existingTarget.updatedAt) : true;

            if (shouldUpdate) {
                //console.log('modifier');
                const updatedState = [...state];
                updatedState[existingTargetIndex] = { ...existingTarget, counters, updatedAt: updatedAt || existingTarget.updatedAt };
                return updatedState;
            }

            return state;
        }
        default:
            return state;
    }
};

const CounterProvider = ({ children }) => {
    const [counterState, counterDispatch] = useReducer(counterReducer, initialState);

    const contextValue = { counterState, counterDispatch };

    return <CounterContext.Provider value={contextValue}>{children}</CounterContext.Provider>;
};

export { CounterContext, CounterProvider };
