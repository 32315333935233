import { useSpring, animated } from 'react-spring';
//STYLES
import 'containers/cards.css';
import '../../components/common/nav/sideNav.css';
//UTILS
import { isMobile } from 'utils/functions';
//LIBS
import { Link, useNavigate } from 'react-router-dom';

//COMPONENTS
import SideNav from 'components/common/nav/SideNav';
import MainNavSearch from 'components/common/MainNavSearch';
import config from 'utils/constants';
//HOOKS
import useNotifications from 'hooks/useNotifications';
import useScroll from 'hooks/useScroll';
//API
import { getNotifications } from 'api/discussions';
//CONTEXTS
import MainNavMobile from 'components/common/MainNavMobile';
// eslint-disable-next-line no-unused-vars
import { BellIcon, ChatIcon, UserIcon } from '@heroicons/react/solid';
import { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { AuthContext } from 'context/authContext';
import { StoreContext } from 'context/storeContext';
import MainNavUser from '../../components/common/MainNavUser';
import { isSmartphone } from '../../utils/functions';
import Image from '../../components/common/Image';

function CloneAiLayout({ children }) {
    const [authState] = useContext(AuthContext);
    const navigate = useNavigate();

    const [openedUserMenu, setOpenedUserMenu] = useState(false);

    const scrollDirection = useScroll();
    //contexts
    const store = useContext(StoreContext);
    const discussionNotifications = useNotifications(getNotifications);

    /*  const handleGoToNotifications = useCallback(() => {
        if (authState?.isLogged) navigate('/dashboard/notifications');
        else document.getElementById('button-login')?.click();
        //eslint-disable-next-line
    }, [authState, navigate]);

    const handleGoToSearch = useCallback(() => {
        navigate('/search');
    }, [navigate]); */

    useEffect(() => {
        if (openedUserMenu) document.querySelector('body').style.overflow = 'hidden';
        else document.querySelector('body').style.overflow = 'auto';
    }, [openedUserMenu]);

    const handleOpenUserMenu = useCallback(() => {
        setOpenedUserMenu(openedUserMenu ? false : true);
    }, [openedUserMenu]);

    const openCloseSlideAnimationContent = useSpring({
        transform: openedUserMenu ? `translateX(16rem)` : 'translateX(0rem)',
        config: {
            duration: 225,
        },
    });

    const handleGoToNotifications = useCallback(() => {
        navigate('/dashboard/notifications');
    }, [navigate]);

    return (
        <Fragment>
            <MainNavUser handleOpenUserMenu={handleOpenUserMenu} isOpen={openedUserMenu} />
            <div className={`hidden sm:block sticky z-20 h-16 max-h-16 container-fluid w-full mx-auto px-4 bg-white top-0`}>
                <MainNavSearch />
            </div>
            {/* style={{backgroundImage: 'linear-gradient(150deg, #E348FF 0%, #2600FC 100%)'}} */}
            <animated.div style={openCloseSlideAnimationContent} className="flex flex-col min-h-screen">
                <div
                    className={`sm:hidden sticky w-full z-10 pt-2 px-4 bg-black transition-all duration-300 ease-out ${
                        scrollDirection === 'down' ? '-top-[65px]' : 'top-0'
                    }`}
                >
                    <div className={`flex justify-between items-center gap-2 pb-2`}>
                        <div className="w-auto flex">
                            {isSmartphone() ? (
                                <>
                                    {authState.isLogged ? (
                                        <button id="button-profil" onClick={handleOpenUserMenu} className="">
                                            <div className="text-purpleSkills font-semibold text-sm flex flex-col items-center w-auto">
                                                <div>
                                                    {authState.isLogged ? (
                                                        authState.me?.profile?.avatar?.url ? (
                                                            <Image
                                                                src={authState.me?.profile?.avatar?.url}
                                                                alt={authState.me?.profile?.pseudo}
                                                                loading="false"
                                                                className="rounded-full w-8 h-8"
                                                            />
                                                        ) : (
                                                            <img
                                                                className="rounded-full w-8 h-8"
                                                                src={`${config.publicDir}skillsLogo.jpg`}
                                                                alt={authState.me?.profile?.pseudo}
                                                            />
                                                        )
                                                    ) : (
                                                        <UserIcon className="block w-6 h-6" />
                                                    )}
                                                </div>
                                            </div>
                                        </button>
                                    ) : (
                                        <Link to={isMobile() ? '/home' : '/'}>
                                            <img src={`${config.publicDir}logo_skillsmobile.png`} alt="logo" className="h-8 w-auto" />
                                        </Link>
                                    )}
                                </>
                            ) : (
                                <Link to={isMobile() ? '/home' : '/'}>
                                    <img src={`${config.publicDir}logo_skillsmobile.png`} alt="logo" className="h-8 w-auto" />
                                </Link>
                            )}
                        </div>
                        <span className={`w-44`}>
                            <img src={`${config.publicDir}logo_ai_transparent.png`} alt="ai_logo" />
                        </span>
                        <div className="w-7 flex">
                            <button id="button-notifications" onClick={handleGoToNotifications}>
                                <div className="relative text-graySkills text-sm flex flex-col items-center w-auto">
                                    <div className="">
                                        {authState.isLogged && (
                                            <>
                                                {(store.get('notifications')?.length > 0 || discussionNotifications) && (
                                                    <>
                                                        <div className="sm:hidden flex justify-center items-center text-white text-xs font-semibold absolute -top-2 -right-3 bg-red p-1 h-5 w-5 rounded-full">
                                                            <span>{discussionNotifications + store.get('notifications').length}</span>
                                                        </div>
                                                        {store.get('notifications')?.length > 0 && (
                                                            <div className="hidden sm:flex justify-center items-center text-white text-xs font-semibold absolute -top-2 -right-3 bg-red p-1 h-5 w-5 rounded-full">
                                                                <span>{store.get('notifications').length}</span>
                                                            </div>
                                                        )}
                                                    </>
                                                )}
                                            </>
                                        )}
                                        <ChatIcon className="block w-7 h-7" />
                                        {/* <BellIcon className="block w-6 h-6" /> */}
                                    </div>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
                <div className={`flex-grow `} style={{ backgroundImage: 'linear-gradient(150deg, #b235fe 0%, #7d21fd 100%)' }}>
                    <div className={`container relative mx-auto sm:px-4`}>
                        <div className={`sm:grid sm:grid-cols-12 sm:gap-8 pb-4`}>
                            <div className={`hidden sm:block sm:col-span-3`}>
                                <SideNav />
                            </div>

                            <div className={`sm:col-span-9`}>
                                <main>
                                    <div className={`w-full relative`}>
                                        <div className="w-full px-2 mb-4">{children}</div>
                                    </div>
                                </main>
                            </div>
                        </div>
                    </div>
                </div>
                <MainNavMobile />
            </animated.div>
        </Fragment>
    );
}

export default CloneAiLayout;
