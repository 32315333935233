// exports.baseURL = "http://localhost:3000";
module.exports = {
    clientUrl: process.env.NODE_ENV === 'production' ? 'https://skillsmarket.tv' : 'http://localhost:3001',
    serverUrl: process.env.NODE_ENV === 'production' ? 'https://api.skillsmarket.tv' : 'http://localhost:8080',
    serverApiUrl: process.env.NODE_ENV === 'production' ? 'https://api.skillsmarket.tv/api' : 'http://localhost:8080/api',
    // publicDir: process.env.NODE_ENV === 'production' ? '/public/' : '/',
    publicDir: '/',
    //stripeAi: "prod_OdThT8EpB7W39B",
    stripeAi: 'prod_O9uRhzisE71je6',
};
