import { useEffect, useState, useRef, Fragment } from 'react';
//API
import { useInfiniteQuery } from 'react-query';
import { getRoomsP } from 'api/forum';

//STYLES
import 'App.css';
//LAYOUT
import FullLayout from 'pages/layouts/Full';
//COMPONENTS
import SectionTitle from 'components/common/SectionTitle';
//CONTAINERS
import CardFrontRoom from 'containers/rooms/CardFrontRoom';
//HOOKS
import UseIntersectionObserver from 'hooks/useIntersectionObserver';

function ListingRooms() {
    const [start, setStart] = useState(false);
    const page = useRef(0);
    const hasNextPage = useRef(false);

    const { data, fetchNextPage, isFetchingNextPage, refetch } = useInfiniteQuery('infiniteRooms', ({ pageParam = 0 }) => getRoomsP(`?p=${pageParam}`), {
        getNextPageParam: (lastPage, pages) => {
            if (lastPage.hasNextPage) {
                hasNextPage.current = lastPage.hasNextPage;

                return (page.current = lastPage.page);
            } else {
                hasNextPage.current = false;
                return (page.current = 0);
            }
        },
        enabled: !!start,
    });

    //REFS
    const loadMoreButtonRef = useRef();
    //HOOKS
    UseIntersectionObserver({
        target: loadMoreButtonRef,
        onIntersect: fetchNextPage,
        enabled: !!hasNextPage.current,
    });

    useEffect(() => {
        page.current = 0;
        setStart(true);
        //eslint-disable-next-line
    }, []);

    return (
        <FullLayout>
            <main>
                <div className="m-auto xl:container">
                    <SectionTitle>
                        <h1 className="h2 mx-0 my-2 mt-4 mb-2">
                            <span className="text-secondary">Recherches</span> disponibles
                        </h1>
                    </SectionTitle>
                </div>
                <div className="w-full m-auto xl:container mb-14">
                    {/* <div className="flex flex-row flex-wrap justify-start w-12/12 gap-4"> */}
                    <div
                        className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 auto-cols-max gap-x-2.5 gap-2"
                        style={{ maxWidth: '100%' }}
                    >
                        <>
                            {data?.pages && (
                                <>
                                    {data?.pages?.map((pages, index) => (
                                        <Fragment key={index}>
                                            {pages.docs.map((room, j) => (
                                                <div className="card card_profile w-full hover:shadow-lg" key={j}>
                                                    <CardFrontRoom room={room} refetch={refetch} listingQueryKeys={['infiniteRooms']} />
                                                </div>
                                            ))}
                                        </Fragment>
                                    ))}
                                </>
                            )}
                        </>
                    </div>
                </div>
                {hasNextPage.current && (
                    <div className="text-center mt-4">
                        <button
                            ref={loadMoreButtonRef}
                            className="button button-secondary"
                            onClick={fetchNextPage}
                            disabled={!hasNextPage.current || isFetchingNextPage}
                        >
                            {isFetchingNextPage ? 'Chargement...' : 'Voir plus'}
                        </button>
                    </div>
                )}
            </main>
        </FullLayout>
    );
}

export default ListingRooms;
