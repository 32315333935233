import { useContext, useState } from 'react';
import { createPortal } from 'react-dom';

//STYLES
import { Link } from 'react-router-dom';
import './CookieConsent.css';

//CONTEXTS
import { CookieContext } from 'context/cookieContext';

//COMPONENTS
import Button from 'components/common/Button';

function CookieConsent() {
    const [cookieState, cookieDispatch] = useContext(CookieContext);

    const [isSettingShown, setIsSettingShown] = useState(false);

    function handleChange(e, checkbox) {
        if (checkbox === 'statistic_cookies') {
            cookieDispatch({ type: 'setStatistics', checked: e.target.checked });
        } else if (checkbox === 'marketing_cookies') {
            cookieDispatch({ type: 'setMarketing', checked: e.target.checked });
        }
    }

    function handleAcceptAll() {
        cookieDispatch({ type: 'acceptAll' });
    }

    function handleDisableAll() {
        cookieDispatch({ type: 'disableAll' });
    }

    function handlePreferences() {
        setIsSettingShown(true);
    }

    function handleSave() {
        cookieDispatch({ type: 'save' });
    }

    return createPortal(
        <div className="CookieConsent">
            <div className="CookieConsent_content">
                <h3 className="h3 text-center">{ 'Cookies' }</h3>
                <p>
                    Nous utilisons des cookies pour améliorer l'expérience,{' '}
                    <Link to="/cgu" title="Consulter notre politique de gestion des cookies">
                        consultez notre politique de gestion des cookies
                    </Link>
                </p>
            </div>
            {isSettingShown && (
                <div id="CookieConsent_settings" className="CookieConsent_settings">
                    {/*
                    <div className="CookieConsent_checkbox">
                        <input type="checkbox" id="functionnal_cookies" name="functionnal_cookies" checked="checked" disabled />
                        <label htmlFor="functionnal_cookies">Cookies fonctionnels</label>
                    </div>
                    */}
                    <div className="CookieConsent_checkbox">
                        <input type="checkbox" id="statistic_cookies" name="statistic_cookies" checked={cookieState.statistics} onChange={(e) => handleChange(e, 'statistic_cookies')} />
                        <label htmlFor="statistic_cookies">Cookies statistiques</label>
                    </div>
                    <div className="CookieConsent_checkbox">
                        <input type="checkbox" id="marketing_cookies" name="marketing_cookies" checked={cookieState.marketing} onChange={(e) => handleChange(e, 'marketing_cookies')} />
                        <label htmlFor="marketing_cookies">Cookies marketing</label>
                    </div>
                </div>
            )}
            <div className="CookieConsent_actions">
                <Button label="Accepter les cookies" css="button-default button-border-gray bg-graySkills text-white rounded-full" onClick={handleAcceptAll} />
                {!isSettingShown && <Button label="Préférences" css="button-default rounded-full text-mainText hover:text-white" onClick={handlePreferences} />}
                {isSettingShown && <Button label="Sauvegarder" css="button-default" onClick={handleSave} />}
            </div>
            {!isSettingShown && (
                <button className="CookieConsent_deny" onClick={handleDisableAll}>
                    Continuer sans accepter
                </button>
            )}
        </div>,
        document.body
    );
}

export default CookieConsent;
