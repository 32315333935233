import { useEffect, useRef, useState, useImperativeHandle, forwardRef } from 'react';

//CONFIG
import config from 'utils/constants';
//STYLES
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

//LIBS
import ReactPlayer from 'react-player';

function PlayerVideo(props, ref) {
    const videoPlayer = useRef();

    const [state, setState] = useState({
        url: null,
        pip: false,
        playing: props.playing ?? false,
        controls: false,
        light: props.light ?? false,
        playsinline: props.playsinline ?? false,
        volume: 1,
        muted: props.muted ?? true,
        played: 0,
        loaded: 0,
        duration: 0,
        playbackRate: 1.0,
        loop: true,
        infos: props.showInfos ?? false,
        width: props.width ? props.width : 'auto',
        height: props.height ?? '100%',
    });

    useImperativeHandle(ref, () => ({
        play: () => console.log('play'),
    }));

    function onReady() {
        if (!state.playing && props.seekTo) videoPlayer.current.seekTo(5);
    }

    function onStart() {}

    function handleMouse() {
        if (props.activeMouse) setState({ ...state, playing: !state.playing });
    }

    function handleTogglePlay() {
        if (props.activeClick) setState({ ...state, playing: !state.playing });
    }

    useEffect(() => {
        const infos = state.playing ? false : props.showInfos ?? false;
        setState({ ...state, infos: infos });
        //eslint-disable-next-line
    }, [state.playing]);

    useEffect(() => {
        // console.log(props.played, props.id);
        setState({ ...state, playing: props.shoot._id === props.played ? true : false });
        //eslint-disable-next-line
    }, [props.played]);

    return (
        <div
            className={`relative h-full w-full top-0 overflow-hidden rounded-lg ${props.videoFitCover !== false && 'video-fit-cover'} `}
            onMouseEnter={handleMouse}
            onMouseLeave={handleMouse}
            onClick={handleTogglePlay}
        >
            {/*   `${config.serverUrl + '/stream/' + props.shoot?._id} */}
            <ReactPlayer
                ref={videoPlayer}
                url={
                    process.env.NODE_ENV === 'production'
                        ? `https://d26deilyej5ndi.cloudfront.net/shoots/${props.shoot?.user?.id}/${props.shoot?.video?._id}.mp4`
                        : `${config.serverUrl + `/stream/${props.shoot?.user?.id}/${props.shoot?.video?._id}.mp4`}`
                }
                controls={props.controls ? props.controls : state.controls}
                playing={props.played ? props.played : state.playing}
                muted={state.muted}
                loop={state.loop}
                pip={state.pip}
                light={state.light}
                playsinline={state.playsinline}
                stopOnUnmount={false}
                onReady={onReady}
                width={state.width}
                height={state.height}
                onStart={props.onStart ?? onStart}
                /* poster={props.poster} */
            />
            <div className={`${state.infos ? 'bottom-0' : '-bottom-28'} transition-all absolute left-0 w-full`}>
                <div
                    style={{ backgroundColor: 'rgba(255, 255, 255, 0.3)' }}
                    className="p-2 w-full text-xs text-white flex gap-2 flex-col justify-between items-start"
                >
                    <p>{props.shoot?.title}</p>
                    {props.shoot?.views > 0 && <p className="whitespace-nowrap">{props.shoot?.views} vues</p>}
                </div>
            </div>
        </div>
    );
}

export default forwardRef(PlayerVideo);
