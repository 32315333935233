//COMPONENTS
import Header from 'components/common/Header';
import ListVisios from 'containers/visios/ListVisios';

//LIBS
import { useParams } from 'react-router-dom';

//STYLES
import 'App.css';

import { PageContentFadingAnimation } from 'utils/animations';

function Visios() {
    //HOOKS
    const params = useParams();
    
    return (
        <PageContentFadingAnimation duration={250}>
            <div className="App">
                <Header>
                    <h1 className="h1 mx-4 sm:mx-0">Mes visio-consultations reçues</h1>
                </Header>
                <main>{!params.slug && <ListVisios />}</main>
            </div>
        </PageContentFadingAnimation>
    );
}

export default Visios;
