import './dashboardNav.css';

import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';

function DashboardNav() {
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === '/dashboard/profil' || location.pathname.includes('/dashboard/services/')) {
            document.body.style.backgroundColor = '#FFFFFF';
        } else {
            document.body.style.backgroundColor = '#F7F9FF';
        }
    }, [location]);

    return (
        <div className="dashboardNav">
            <ul>
                <li className="dashboardNav_head">Mon compte</li>
                <li className={`dashboardNav_link ${location.pathname === '/dashboard/profil' && 'active'}`}>
                    <Link to="/dashboard/profil">Profil</Link>
                </li>
                <li className={`dashboardNav_link ${location.pathname.includes('/dashboard/needs') && 'active'}`}>
                    <Link to="/dashboard/needs">Besoins</Link>
                </li>
                <li className={`dashboardNav_link ${location.pathname.includes('/dashboard/services') && 'active'}`}>
                    <Link to="/dashboard/services">Boutique</Link>
                </li>
                <li className={`dashboardNav_link ${location.pathname.includes('/dashboard/shoots') && 'active'}`}>
                    <Link to="/dashboard/shoots">Shoots</Link>
                </li>
                <li className={`dashboardNav_link ${location.pathname.includes('/dashboard/subscribers') && 'active'}`}>
                    <Link to="/dashboard/subscribers">Abonnés</Link>
                </li>
                <li className={`dashboardNav_link ${location.pathname.includes('/dashboard/params/account') && 'active'}`}>
                    <Link to="/dashboard/params/account">Paramètres</Link>
                </li>
                {/* <li className="dashboardNav_link">
                    <Link to="/dashboard/settings">Calendrier</Link>
                </li> */}

                {/* <li
                    className={`dashboardNav_link ${
                        location.pathname.includes("/dashboard/products") &&
                        "active"
                    }`}
                >
                    <Link to="/dashboard/products">Boutique en ligne</Link>
                </li> */}
                {/* <li className={`dashboardNav_link ${location.pathname.includes("/dashboard/videos") && "active"}`}>
                    <Link to="/dashboard/videos">Espaces vidéos</Link>
                </li> */}
                {/* <li className={`dashboardNav_link ${location.pathname.includes("/dashboard/rooms") && "active"}`}>
                    <Link to="/dashboard/rooms">Rooms</Link>
                </li> */}

                <li className="dashboardNav_head">Visios</li>
                <li className={`dashboardNav_link ${location.pathname.includes('/dashboard/visios/asked') && 'active'}`}>
                    <Link to="/dashboard/visios/asked">Reçues</Link>
                </li>
                <li className={`dashboardNav_link ${location.pathname.includes('/dashboard/visios/asked') && 'active'}`}>
                    <Link to="/dashboard/visios/asked">Demandées</Link>
                </li>
                {/* <li className="dashboardNav_head">Mes transactions</li> */}
                {/* <li className={`dashboardNav_link ${location.pathname.includes("/dashboard/buysell") && "active"}`}> */}
                {/*     <Link to="/dashboard/buysell">Achats / Ventes</Link> */}
                {/* </li> */}
                <li className="dashboardNav_head">Mes notifications</li>
                <li className={`dashboardNav_link ${location.pathname.includes('/dashboard/notifications') && 'active'}`}>
                    <Link to="/dashboard/notifications">Notifications</Link>
                </li>
                <li className={`dashboardNav_link ${location.pathname.includes('/dashboard/options') && 'active'}`}>
                    <Link to="/dashboard/options">Options</Link>
                </li>

                {/* <li className="dashboardNav_link">
                    <Link to="/dashboard/settings">Compte</Link>
                </li>
                <li className="dashboardNav_link">
                    <Link to="/dashboard/billing">Factures</Link>
                </li> */}
            </ul>
        </div>
    );
}

export default DashboardNav;
