import API from './axios.config';
import authHeader from 'services/Auth.Header';

async function getDiscussion(userTo, message) {
    const response = await API.post(
        'discussions/pullOne',
        {userTo, message},
        {
            headers: authHeader(),
        }
    );
    return response.data;
}

async function getDiscussions() {
    const response = await API.post(
        'discussions/pull',
        {},
        {
            headers: authHeader(),
        }
    );
    return response.data;
}

async function getDiscussionsPagination(params) {
    const response = await API.get(`discussions` + params, {
        headers: authHeader(),
    });
    return response.data;
}

async function getNotifications() {
    const response = await API.get('discussions/notifications', {
        headers: authHeader(),
    });
    return response.data;
}

async function resetCounter(discussionId) {
    const response = await API.post(
        'discussions/reset',
        { discussionId },
        {
            headers: authHeader(),
        }
    );
    return response.data;
}

export { getDiscussion, getDiscussions, getDiscussionsPagination, getNotifications, resetCounter };
