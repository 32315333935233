import { useReducer, createContext } from 'react';

//LIBS
import _ from 'lodash';

const StoreContext = createContext();

function reducer(state, action) {
    switch (action.type) {
        case 'ready':
            return { ...state, ready: true };
        case 'add':
            return { ...state, [action.key]: action.value };
        case 'del':
            const newStore = _.omit(state, [action.key]);
            return newStore;
        default:
            throw new Error();
    }
}

const StoreProvider = (props) => {
    const [store, set] = useReducer(reducer, {});

    function get(key) {
        return key in store ? store[key] : null;
    }

    const methods = { store, set, get };

    return <StoreContext.Provider value={methods}>{props.children}</StoreContext.Provider>;
};

export { StoreContext, StoreProvider };
