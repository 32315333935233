import { Fragment } from 'react';
import { convertUrl } from 'utils/functions';
import parse from 'html-react-parser';

function Nl2br(props) {
    return (
        <>
            {props.str?.split('\n').map((item, key) => {
                return (
                    <Fragment key={key}>
                        {parse(convertUrl(item, { title: 'Cliquez pour ouvrir dans un nouvel onglet' }))}
                        <br />
                    </Fragment>
                );
            })}
        </>
    );
}

export default Nl2br;
