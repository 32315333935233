import * as yup from 'yup';

const schema = yup.object().shape({
    title: yup.string().min(20, "Vous devez saisir une question d'au moins 20 caractères").required('Le titre du besoin est requis'),
    address: yup.string().required("L'adresse est requise"),
    startDate: yup
        .date()
        .nullable()
        .transform((value, originalValue) => (originalValue === '' ? null : value))
        .notRequired()
        .test('is-after-today', "La date ne peut pas être antérieure à aujourd'hui", (value) => {
            return !value || new Date(value) >= new Date(new Date().setHours(0, 0, 0, 0));
        }),
});

export default schema;
