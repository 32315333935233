import SearchFilters from 'containers/search/SearchFilters';
import { isMobile } from 'utils/functions';

function SearchFiltersV2(props) {
    return (
        <>
            <div className="flex flex-row gap-2 w-full">
                <div className={`${isMobile() ? 'hidden' : 'w-1/3'}`}>
                    <SearchFilters refetch={(latlng) => props?.onRefectch(latlng)} isMainSearch={true} />
                </div>
                {/*  ${isMobile() ? 'w-1/2' : 'w-2/3 */}
                <div className={`${isMobile() ? 'w-full' : 'w-2/3'} overflow-x-auto no-scrollbar self-center no-scrollbar`}>
                    <div className="w-max flex flex-row gap-2 filterContainer">
                        <button
                            className="items-center text-l text-center text-graySkills bg-blancSkills rounded-full font-medium px-2 sm:px-3 sm:py-1 hover:!bg-graySkills hover:!text-white transition-colors duration-400"
                            onClick={(e) => props.onFilter(e, 'all')}
                        >
                            Tout
                        </button>
                        <button
                            className="items-center text-l text-center text-graySkills bg-blancSkills rounded-full font-medium px-2 sm:px-3 sm:py-1 hover:!bg-graySkills hover:!text-white transition-colors duration-400"
                            onClick={(e) => props.onFilter(e, 'users')}
                        >
                            Profil
                        </button>
                        <button
                            className="items-center text-l text-center text-graySkills bg-blancSkills rounded-full font-medium px-2 sm:px-3 sm:py-1 hover:!bg-graySkills hover:!text-white transition-colors duration-400"
                            onClick={(e) => props.onFilter(e, 'services')}
                        >
                            Service
                        </button>
                        <button
                            className="text-l text-center text-graySkills bg-blancSkills rounded-full font-medium px-2 sm:px-3 sm:py-1 hover:!bg-graySkills hover:!text-white transition-colors duration-400"
                            onClick={(e) => props.onFilter(e, 'jobs')}
                        >
                            Job
                        </button>
                        <button
                            className="items-center text-l text-center text-graySkills bg-blancSkills rounded-full font-medium px-2 sm:px-3 sm:py-1 hover:!bg-graySkills hover:!text-white transition-colors duration-400"
                            onClick={(e) => props.onFilter(e, 'shoots')}
                        >
                            Shoot
                        </button>
                        <button
                            className="items-center text-l text-center text-graySkills bg-blancSkills rounded-full font-medium px-2 sm:px-3 sm:py-1 hover:!bg-graySkills hover:!text-white transition-colors duration-400"
                            onClick={(e) => props.onFilter(e, 'rooms')}
                        >
                            Post
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SearchFiltersV2;
