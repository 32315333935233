import { useContext, useEffect, useState } from 'react';

//LIBS
import { Link } from 'react-router-dom';

//UTILS
import config from 'utils/constants';
//CONTEXTS
import { AuthContext } from 'context/authContext';
//COMPONENTS
import Image from 'components/common/Image';

const sizes = {
    avatar: '?w=120&h=120&c=true&q=80',
};

function CardProfilClone({ clone, clone2, user, isFocus, onClick, discussionId, notifications, widthAvatar, sizeText }) {
    const [counter, setCounter] = useState(0);
    const [authState] = useContext(AuthContext);

    useEffect(() => {
        if (notifications?.[discussionId] >= 0 && !isFocus) {
            setCounter(notifications[discussionId]);
        }
        //eslint-disable-next-line
    }, [notifications]);

    useEffect(() => {
        if (isFocus) {
            setCounter(() => 0);
        }
        //eslint-disable-next-line
    }, [isFocus]);

    return (
        <>
            <div className={`relative w-full p-1`}>
                <Link
                    onClick={onClick ?? null}
                    to={!onClick ? ('/profil/' + clone2 ? clone2.user?.profile?.slug : user?.profile?.slug) : false}
                    title={`Discuter avec ${clone2 ? clone2.user?.profile?.pseudo : user?.profile?.pseudo}`}
                >
                    <div className="flex gap-2 flex-row items-center w-full relative">
                        <div className={`flex-none relative ${widthAvatar ? widthAvatar : 'w-16'} h-auto`}>
                            {clone2 ? (
                                <>
                                    {clone2.user?.profile?.avatar?.url ? (
                                        <Image
                                            src={clone2?.user?.profile?.avatar?.url + sizes.avatar}
                                            alt={clone2?.user?.profile?.pseudo}
                                            dynamic
                                            loading="false"
                                            className="w-20 bg-white rounded-full"
                                        />
                                    ) : (
                                        <img
                                            className="w-20 bg-white rounded-full"
                                            src={`${config.publicDir}skillsLogo.jpg` + sizes.avatar}
                                            alt={clone2?.user?.profile?.pseudo}
                                        />
                                    )}
                                </>
                            ) : (
                                <>
                                    {user?.profile?.avatar?.url ? (
                                        <Image
                                            src={user?.profile?.avatar?.url + sizes.avatar}
                                            alt={user?.profile?.pseudo}
                                            dynamic
                                            loading="false"
                                            className="w-20 bg-white rounded-full"
                                        />
                                    ) : (
                                        <img
                                            className="w-20 bg-white rounded-full"
                                            src={`${config.publicDir}skillsLogo.jpg` + sizes.avatar}
                                            alt={user?.profile?.pseudo}
                                        />
                                    )}
                                </>
                            )}

                            <div
                                className={`absolute top-0 left-0 h-full ${widthAvatar ? widthAvatar : 'w-16'} rounded-full`}
                                style={{
                                    backgroundColor: 'rgba(227, 72, 255, 0.25)',
                                }}
                            ></div>
                        </div>
                        <div className="w-auto grow flex flex-wrap flex-col ml-2">
                            {/* <span className="text-sm font-bold text-purpleSkills">{user?.profile?.pseudo}</span>
                            <span className="text-xs text-gray-400">{user?.profile?.status}</span> */}
                            <>
                                {clone2 ? (
                                    <>
                                        <span className="text-sm font-extrabold">{clone2.user?.profile?.pseudo}</span>
                                        <span className="text-sm font-bold">{clone2.user?.profile?.status}</span>
                                    </>
                                ) : (
                                    <>
                                        {user ? (
                                            <>
                                                {authState.me.id === user?.id ? (
                                                    <span className={`${sizeText ? sizeText : 'text-[1.25rem]'} font-extrabold`}>Mon Clone AI</span>
                                                ) : (
                                                    <>
                                                        <span className="text-[1.25rem] font-extrabold">{user?.profile?.pseudo}</span>
                                                        <span className="text-sm font-bold">{user?.profile?.status}</span>
                                                    </>
                                                )}
                                            </>
                                        ) : (
                                            <span className={`${sizeText ? sizeText : 'text-[1.25rem]'} font-extrabold`}>Mon Clone AI</span>
                                        )}
                                    </>
                                )}
                            </>
                        </div>
                        {counter > 0 && (
                            <div className="absolute top-1 right-1 text-white bg-red p-1 rounded-full w-5 h-5 flex justify-center items-center text-xs">
                                {counter}
                            </div>
                        )}
                    </div>
                </Link>
            </div>
        </>
    );
}

export default CardProfilClone;
