import { Fragment, useContext, useEffect, useRef, useState } from 'react';

//LIBS
import _ from 'lodash';
import { useLocation } from 'react-router-dom';

//STYLES
import './tabs.css';
import useSwipe from 'hooks/useSwipe';

//UTILS
import { isMobile } from 'utils/functions';
import useScroll from 'hooks/useScroll';

//CONTEXT
import { StoreContext } from 'context/storeContext';

function Tabs(props) {
    const refTabs = useRef(null);
    const swipeContainerRef = useRef(null);

    const { direction } = useSwipe({ ref: swipeContainerRef, threshold: 80 });
    const scrollDirection = useScroll();
    const location = useLocation();

    const store = useContext(StoreContext);

    // eslint-disable-next-line no-unused-vars
    const [isHomeLocation, setIsHomeLocation] = useState(false);
    const [allTabs, setAllTabs] = useState(props.tabs);
    const [currentTab, setCurrentTab] = useState(props.initPosition ?? 0);
    const [isSlider, setIsSlider] = useState(false);

    useEffect(() => {
        setIsHomeLocation(location.pathname === '/' || location.pathname === '/home' ? true : false);
    }, [location.pathname]);

    function closeJobDetail() {
        const currentState = window.history.state;

        if (currentState && currentState.originalUrl) {
            window.history.replaceState({}, '', currentState.originalUrl);
        }
        store.set({ type: 'del', key: 'openJobDetail' });
    }

    const handleTouchStart = (event) => {
        const element = event.target;
        if (element.parentElement.classList.contains('isSlider')) {
            setIsSlider(true);
        } else {
            setIsSlider(false);
        }
    };

    function handleClick(e, tab) {
        e.stopPropagation();
        e.preventDefault();

        if (tab?.key !== 'tab_jobs') {
            closeJobDetail();
        }

        /*  if (tab?.key) {
            window.history.replaceState(null, null, `#${tab.key}`);
        } */
        if (refTabs.current) {
            const items = refTabs.current.getElementsByClassName('Tabs_item');
            _.forEach(items, (item) => {
                item.classList.remove('active');
            });
        }

        moveSlider(e.currentTarget.parentNode);

        if (typeof props?.refetchFav === 'function') {
            props?.refetchFav();
        }
        window.history.replaceState({ tab: tab.key }, '', `#${tab.key}`);

        if (typeof props?.handleSwitchTab === 'function') {
            props.handleSwitchTab(tab.key);
        }
    }

    function handleSwipe(direction) {
        if (refTabs.current && !isSlider) {
            const items = refTabs.current.getElementsByClassName('Tabs_item');
            const arrItems = Array.from(items);

            arrItems.forEach((item) => {
                if (item.classList.contains('active')) {
                    setCurrentTab(arrItems.indexOf(item));
                }
            });

            if (currentTab > 0 && direction === 'right') {
                items[currentTab].classList.remove('active');
                moveSlider(items[currentTab - 1]);

                if (typeof props?.handleSwitchTab === 'function') {
                    props.handleSwitchTab(items[currentTab - 1].firstChild.hash);
                }

                if (isMobile()) {
                    document.body.scrollTop = 0;
                    document.documentElement.scrollTop = 0;
                }
            } else if (currentTab < items.length - 1 && direction === 'left') {
                items[currentTab].classList.remove('active');
                moveSlider(items[currentTab + 1]);

                if (typeof props?.handleSwitchTab === 'function') {
                    props.handleSwitchTab(items[currentTab + 1].firstChild.hash);
                }

                if (isMobile()) {
                    document.body.scrollTop = 0;
                    document.documentElement.scrollTop = 0;
                }
            }
        }
    }

    function moveSlider(elem) {
        elem.classList.add('active');

        requestAnimationFrame(() => {
            const sliderDetails = getChildNodeIndex(elem);
            const width = elem.offsetWidth;

            if (refTabs.current) {
                const sliderElem = refTabs.current.getElementsByClassName('Tabs_slider')[0];
                if (sliderElem) {
                    //sliderElem.style.left = `${sliderDetails.left}px`;
                    //sliderElem.style.width = `${width}px`;

                    const sliderWidth = sliderElem.offsetWidth;
                    const sliderCenter = sliderWidth / 2;
                    const elemCenter = width / 2;
                    const offset = sliderCenter - elemCenter;
                    const left = sliderDetails.left - offset;

                    sliderElem.style.left = `${left}px`;
                }
            }
        });
    }

    function getChildNodeIndex(elem) {
        let left = elem.offsetLeft;
        let position = 0;
        while (elem) {
            elem = elem.previousElementSibling;
            position++;
        }

        setCurrentTab(position - 1);

        return { position: position - 1, left };
    }
    function init(tabPosition) {
        if (refTabs.current) {
            const items = refTabs.current.getElementsByClassName('Tabs_item');

            _.forEach(items, (item, k) => {
                if (k === tabPosition) {
                    const timeout = setTimeout(() => {
                        moveSlider(item);
                        clearTimeout(timeout);
                    }, 300);
                }
            });
        }
    }

    useEffect(() => {
        if (props.tabs) {
            _.remove(props.tabs, (n) => {
                return n.visible === false;
            });
            setAllTabs(props.tabs);
        }
        //eslint-disable-next-line
    }, [props.tabs]);

    useEffect(() => {
        if (allTabs.length !== props?.tabs?.length) init(props.initPosition ?? 0);
        //eslint-disable-next-line
    }, [allTabs]);

    useEffect(() => {
        init(props.initPosition ?? 0);
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        setCurrentTab(0);
        init(props.initPosition ?? 0);
        //eslint-disable-next-line
    }, [location.key]);

    useEffect(() => {
        if (direction) {
            handleSwipe(direction);
            // Check if props.refetch is a function before calling it
            if (typeof props?.refetchFav === 'function') {
                props?.refetchFav();
            }
        }
        //eslint-disable-next-line
    }, [direction]);

    useEffect(() => {
        if (props.onTabChange) {
            props.onTabChange(allTabs[currentTab]?.key);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentTab, allTabs]);

    useEffect(() => {
        if (props.switchTab && props.switchTab !== allTabs[currentTab]?.key) {
            const tab = allTabs.find((i) => i.key === props.switchTab);
            if (tab) {
                const tabPosition = allTabs.indexOf(tab);
                init(tabPosition);
                document.documentElement.scrollTop = 0;
                window.history.replaceState({ tab: tab.key }, '', `#${tab.key}`);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.switchTab]);

    // Pour adapter le slider sous l'onglet correctement
    useEffect(() => {
        const handleResize = () => {
            const activeTab = refTabs.current?.querySelector('.Tabs_item.active');
            if (activeTab) {
                moveSlider(activeTab);
            }
        };

        window.addEventListener('resize', handleResize);

        const tabsElement = refTabs.current;
        if (tabsElement) {
            const resizeObserver = new ResizeObserver(handleResize);
            resizeObserver.observe(tabsElement);

            return () => {
                window.removeEventListener('resize', handleResize);
                resizeObserver.disconnect();
            };
        }

        return () => {
            window.removeEventListener('resize', handleResize);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /*  const stickyTabs = `w-auto sticky transition-all duration-300 ease-out ${
        scrollDirection === 'down'
            ? `${isHomeLocation ? 'top-[0px] sm:top-[68px] md:top-[0px]' : 'top-0 sm:top-[58px]'}`
            : `${isHomeLocation ? 'top-[58px] md:top-[0px]' : 'top-[58px]'}`
    }`; */

    const stickyTabs = `w-auto -mx-2 sm:mx-0 sticky transition-all duration-300 ease-out ${
        scrollDirection === 'down' ? props.stickyDown || 'top-0 sm:top-[64px]' : props.stickyUp || 'top-[50px] sm:top-[64px]'
    }`;

    return (
        <>
            {/* ${isMobile() ? stickyTabs : 'block pt-2'} */}
            {/*  mt-2 -mx-2 */}
            <div className={`${stickyTabs} bg-opacity-50 backdrop-blur-lg bg-blancSkills z-10`}>
                <ul className="Tabs flex overflow-x-auto scrollbar-hide" ref={refTabs}>
                    {allTabs
                        ?.filter((i) => i.visible !== false)
                        ?.map((tab, k) => (
                            <Fragment key={k}>
                                {tab.visible !== false && (
                                    <li className="Tabs_item">
                                        <a
                                            className="relative"
                                            href={`#${tab.key}`}
                                            onClick={(e) => {
                                                handleClick(e, tab);
                                            }}
                                        >
                                            {tab.label}
                                        </a>
                                    </li>
                                )}
                            </Fragment>
                        ))}
                    <li className="Tabs_slider" role="presentation"></li>
                </ul>
            </div>
            <div className="" ref={swipeContainerRef} onTouchStart={handleTouchStart}>
                {allTabs
                    ?.filter((i) => i.visible !== false)
                    ?.map((tab, k) => (
                        <Fragment key={k}>
                            {tab.visible !== false && <div>{k === currentTab && <div className="Tabs_content h-full mb-4 sm:mb-0">{tab.content}</div>}</div>}
                        </Fragment>
                    ))}
            </div>
        </>
    );
}

export default Tabs;
