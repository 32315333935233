import { Fragment, useEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
//API
//import { getServicesP } from 'api/services';
import { useInfiniteQuery } from 'react-query';

//COMPONENTS
import SectionTitle from 'components/common/SectionTitle';
//import ServicesTags from 'components/ServicesTags';
//import Subjects from 'components/common/nav/Subjects';
import SEO from 'components/Seo';

//CONTAINERS
import CardFrontService from 'containers/services/CardFrontService';

//HOOKS
import SideNav from '../components/common/nav/SideNav';
import UseIntersectionObserver from 'hooks/useIntersectionObserver';

//LAYOUT
import FullLayout from 'pages/layouts/Full';

//STYLES
import 'App.css';

//UTILS
import { getServicesBySubCategory, getServicesByMainCategory } from '../api/services';
import { isMobile } from 'utils/functions';
import { PageContentFadingAnimation } from 'utils/animations';

function ServicesCategory(props) {
    //get state form url
    const params = useParams();
    const navigate = useNavigate();

    const [start, setStart] = useState(false);
    const page = useRef(0);
    const hasNextPage = useRef(false);

    const { data, fetchNextPage, isFetchingNextPage, refetch } = useInfiniteQuery(
        'infiniteCatServices',
        ({ pageParam = 0 }) =>
            params?.subcategory
                ? getServicesBySubCategory(`?p=${pageParam}`, params?.subcategory)
                : getServicesByMainCategory(`?p=${pageParam}`, params?.category),
        {
            getNextPageParam: (lastPage, pages) => {
                if (lastPage.hasNextPage) {
                    hasNextPage.current = lastPage.hasNextPage;

                    return (page.current = lastPage.page);
                } else {
                    hasNextPage.current = false;
                    page.current = 0;
                }
                return;
            },
            enabled: !!start && !!params,
        }
    );

    //REFS
    const loadMoreButtonRef = useRef();

    //HOOKS
    UseIntersectionObserver({
        target: loadMoreButtonRef,
        onIntersect: (isVisible) => {
            if (isVisible) fetchNextPage();
        },
        enabled: !!hasNextPage.current,
    });

    useEffect(() => {
        page.current = 0;
        setStart(true);
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        setStart(true);
        refetch();
        //eslint-disable-next-line
    }, [params]);

    function handleOnClick(category) {
        if (category === params.category) {
            navigate(`/services/${category}`, { replace: true }, { state: { maincategory: params.category } });
        } else {
            navigate(
                `/services/${params.category}/${category}`,
                { replace: true },
                { state: { maincategory: params.category, subcategory: params.subcategory } }
            );
        }
    }

    return (
        <FullLayout>
            <SEO
                title={`Marketplace ${params.category} ${params?.subcategory ? ` / ${params?.subcategory}` : ''}`}
                description={`Marketplace ${params.category + (params?.subcategory ? ` / ${params?.subcategory}` : '')}`}
                type="summary_large_image"
            />
            <div className="container">
                <div className="sm:grid sm:grid-cols-12 sm:gap-8 pb-4">
                    {!isMobile() && (
                        <div className="hidden sm:block sm:col-span-3">
                            <SideNav />
                        </div>
                    )}
                    <div className="sm:col-span-9">
                        <main>
                            <PageContentFadingAnimation duration={250}>
                                <SectionTitle>
                                    <h1 className="h1 mx-0 my-2 mt-4 mb-2">
                                        <span className="text-purpleSkills cursor-pointer" onClick={() => handleOnClick(params.category)}>
                                            {params.category}
                                        </span>
                                        {params?.subcategory && (
                                            <>
                                                <span> / </span>
                                                <span className="text-purpleSkills cursor-pointer" onClick={() => handleOnClick(params.subcategory)}>
                                                    {params.subcategory}
                                                </span>
                                            </>
                                        )}
                                    </h1>
                                </SectionTitle>

                                <div className="w-full m-auto xl:container mb-4">
                                    {/* <div className="flex flex-row flex-wrap justify-start w-12/12 gap-4"> */}
                                    <div className="grid grid-cols-2 lg:grid-cols-3 auto-cols-max gap-x-2 sm:gap-4" style={{ maxWidth: '100%' }}>
                                        <>
                                            {data?.pages && (
                                                <>
                                                    {data?.pages?.map((pages, index) => (
                                                        <Fragment key={index}>
                                                            {pages.docs.map((service, j) => (
                                                                <div className="mt-0 sm:pb-0 card card_profile w-full" key={j}>
                                                                    <CardFrontService service={service} refetch={refetch} />
                                                                </div>
                                                            ))}
                                                        </Fragment>
                                                    ))}
                                                </>
                                            )}
                                        </>
                                    </div>
                                </div>
                                {hasNextPage.current && (
                                    <div className="text-center mt-4">
                                        <button
                                            ref={loadMoreButtonRef}
                                            className="button button-secondary"
                                            onClick={fetchNextPage}
                                            disabled={!hasNextPage.current || isFetchingNextPage}
                                        >
                                            {isFetchingNextPage ? 'Chargement...' : 'Voir plus de services'}
                                        </button>
                                    </div>
                                )}
                            </PageContentFadingAnimation>
                        </main>
                    </div>
                </div>
            </div>
        </FullLayout>
    );
}

export default ServicesCategory;
