import { useEffect, useState } from 'react';
import useRequest from '../services/useRequest';

export default function Counter(props) {
    const [total, setTotal] = useState(0);

    const request = useRequest();

    useEffect(() => {
        request.post(props.request).then((data) => {
            setTotal(data.total);
        });
        //eslint-disable-next-line
    }, []);

    return (
        <div className={`border border-gray-300 rounded-sm p-2 ${props.className ?? ''}`}>
            <div className="flex flex-row gap-4 items-center">
                <div className="text-[#745C89]">{props.icon}</div>
                <div className={`flex flex-col gap-1`}>
                    <span className="text-[#745C89]">{props.label}</span>
                </div>
                <span className="font-semibold text-gray-700 text-lg">{total}</span>
            </div>
        </div>
    );
}
