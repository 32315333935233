import { useContext, useRef, useEffect, forwardRef, useImperativeHandle, useState, useCallback } from 'react';

//UTILS
import config from 'utils/constants';
//import { isMobile } from 'utils/functions';

//LIBS
import _ from 'lodash';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';

//STYLES
import { ArrowNarrowLeftIcon, ArrowsExpandIcon, PaperAirplaneIcon } from '@heroicons/react/solid';
import './chatRoom.css';

//CONTEXTS
import { AuthContext } from 'context/authContext';
import { SocketContext } from 'context/socketContext';
//ICONS
import { XCircleIcon, ChevronDownIcon, ChevronUpIcon, XIcon, ClipboardIcon } from '@heroicons/react/outline';

//COMPONENTS
import Image from 'components/common/Image';
import Avatar from 'containers/profils/Avatar';
import { isMobile } from '../../utils/functions';
import AvatarClone from '../profils/AvatarClone';
import { useNavigate } from 'react-router-dom';
import BuyCloneAi from '../../components/common/BuyCloneAi';

const sizes = {
    avatar: '?w=100&h=100&c=true&q=80',
};

function ChatRoom(props, ref) {
    const [authState] = useContext(AuthContext);
    const navigate = useNavigate();

    const [messages, setMessages] = useState([]);
    const [user, setUser] = useState(null);
    const [isToggle, setIsToggle] = useState(false);
    const [isBlinked, setIsBlinked] = useState(false);
    const [shift, setShift] = useState(false);
    const [largeWindow, setLargeWindow] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    //const [isAiRoundLoading, setIsAiRoundLoading] = useState(false);

    const [isMessageCloneExceeded, setMessageCloneExceeded] = useState(false);
    const [messageCloneData, setMessageCloneData] = useState(null);

    const [conversations, setConversations] = useState([]);
    const [opportunities, setOpportunities] = useState([]);

    const socket = useContext(SocketContext);

    //REF
    const currentMe = useRef(null);
    const refInputMessage = useRef(null);
    const scrollRef = useRef(null);

    useImperativeHandle(ref, () => ({
        addMessage: (message) => {
            const added = [...messages[props.discussionId], message];
            setMessages({ [props.discussionId]: added });
            if (message.mine) refInputMessage.current.value = '';
            refInputMessage.current.focus();
        },
        setMessages: (data) => {
            const allMessages = { [props.discussionId]: [] };
            _.forEach(data.messages, (message) => {
                /*  console.log(message) */
                if (message.clone) {
                    allMessages[props.discussionId].push({
                        mine: false,
                        cloneId: message.clone.id,
                        time: message.time,
                        createdAt: moment(message.createdAt).tz('Europe/Paris').format('DD/MM/YYYY HH:mm'),
                        message: message.message,
                        pseudo: message.clone.pseudo,
                        avatar: data.user?.profile?.avatar,
                    });
                } else if (message.user) {
                    allMessages[props.discussionId].push({
                        mine: message.user.id === authState.me.id ? true : false,
                        userId: message.user.id,
                        time: message.time,
                        createdAt: moment(message.createdAt).tz('Europe/Paris').format('DD/MM/YYYY HH:mm'),
                        message: message.message,
                        pseudo: message.user.profile.pseudo,
                        avatar: message.user.profile.avatar,
                    });
                } else {
                    return;
                }
            });
            setMessages(allMessages);
            let userFrom;
            if (props.chatType === 'ai') {
                userFrom = data.user?.id === authState.me.id ? data.user : null;
            } else {
                userFrom = data.user1?.id === authState.me.id ? data.user2 : data.user1;
            }
            setUser(userFrom);
            refInputMessage.current?.focus();
        },
        setConversations: (data) => {
            const allConversations = { [props.discussionId]: [] };

            _.forEach(data.conversations, (conversation) => {
                allConversations[props.discussionId].push({
                    clone: conversation.clone,
                    clone2: conversation.clone2,
                    cloneReportDefensif: conversation.cloneReportDefensif,
                    cloneReportOffensif: conversation.cloneReportOffensif,
                    createdAt: moment(conversation.createdAt).tz('Europe/Paris').format('DD/MM/YYYY HH:mm'),
                    messages: conversation.messages,
                    isOpen: false,
                });
            });

            setConversations(allConversations);
            let userFrom;
            if (props.chatType === 'ai') {
                userFrom = data.clone2?.user;
            } else {
                userFrom = data.user1?.id === authState.me.id ? data.user2 : data.user1;
            }

            setUser(userFrom);
            refInputMessage.current?.focus();
        },
        setOpportunities: (data) => {
            const allConversations = { [props.discussionId]: [] };

            _.forEach(data.conversations, (conversation) => {
                allConversations[props.discussionId].push({
                    clone: conversation.clone,
                    clone2: conversation.clone2,
                    cloneReportDefensif: conversation.cloneReportDefensif,
                    cloneReportOffensif: conversation.cloneReportOffensif,
                    createdAt: moment(conversation.createdAt).tz('Europe/Paris').format('DD/MM/YYYY HH:mm'),
                    isOpen: false,
                });
            });

            setOpportunities(allConversations);
            let userFrom;
            if (props.chatType === 'ai') {
                userFrom = data.clone?.user;
            } else {
                userFrom = data.user1?.id === authState.me.id ? data.user2 : data.user1;
            }

            setUser(userFrom);
            refInputMessage.current?.focus();
        },
    }));

    function scrollDown() {
        setIsBlinked(false);
        scrollRef.current.scrollIntoView({ behavior: 'instant', block: 'end', inline: 'end' });
    }

    function onFocus() {
        props.onFocus(props.discussionId, props.chatType);
    }

    function onKeyDown(e) {
        if (e.key === 'Shift') setShift(true);
        if (!shift && e.key === 'Enter' && !isMobile()) handleSubmit(e);
        if (isMobile()) {
            adjustHeight(e);
        }
    }

    function onKeyUp(e) {
        if (e.key === 'Shift') setShift(false);
    }

    function onInput(e) {
        adjustHeight(e);
    }

    useEffect(() => {
        const handleResponseReceived = () => {
            setIsLoading(false);
        };

        socket.on('responseReceived', handleResponseReceived);

        return () => {
            socket.off('responseReceived', handleResponseReceived);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const handleMessageCloneExceeded = (data) => {
            if (data?.type === 401 || data?.type === 503) {
                toast.error(data.message, { type: 'error' });
            } else if (data?.type === 201) {
                setIsLoading(false);
                setMessageCloneData(data);
                setMessageCloneExceeded(true);
            }
        };

        socket.on('messageCloneExceeded', handleMessageCloneExceeded);

        return () => {
            socket.off('messageCloneExceeded', handleMessageCloneExceeded);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function handleSubmit(e) {
        e.preventDefault();

        if (isLoading) {
            return;
        }

        const value = refInputMessage.current.value.trim();
        if (value.length > 0 && value !== ' ') {
            const currentMessage = {
                mine: true,
                pseudo: authState?.me?.profile?.pseudo,
                avatar: authState?.me?.profile?.avatar ?? `${config.publicDir}skillsLogo.jpg`,
                userId: authState?.me?.id,
                message: refInputMessage.current.value.trimEnd(),
                time: moment().format('DD/MM/YYYY HH:mm'),
            };
            props.onSubmit(currentMessage, props.discussionId, props.chatType);
            if (props.chatType === 'ai') {
                setIsLoading(true);
            }

            adjustHeight(e);
        } else {
            refInputMessage.current.value = '';
            adjustHeight(e);
        }
    }

    function formatMessageDate(messageDateString) {
        const messageDate = moment(messageDateString, 'DD/MM/YYYY HH:mm');
        const today = moment();
        const yesterday = moment().subtract(1, 'days');

        if (!messageDate.isValid()) {
            // Si messageDateString est seulement une heure (HH:mm), retournez simplement messageDateString
            return messageDateString;
        } else if (messageDate.isSame(today, 'day')) {
            return "Aujourd'hui à " + messageDate.format('HH:mm');
        } else if (messageDate.isSame(yesterday, 'day')) {
            return 'Hier à ' + messageDate.format('HH:mm');
        } else {
            return messageDate.format('DD/MM/YYYY [à] HH:mm');
        }
    }

    function handleLogin(e) {
        e.preventDefault();
        document.getElementById('button-login')?.click();
    }

    function handleLargeWindow() {
        setLargeWindow(!largeWindow);
        isToggle && setIsToggle(false);
    }

    function toggle(e) {
        e?.preventDefault();
        setIsToggle(!isToggle);
        largeWindow && setLargeWindow(false);
    }

    const adjustHeight = () => {
        if (refInputMessage.current) {
            refInputMessage.current.style.height = 'auto';
            refInputMessage.current.style.height = refInputMessage.current.scrollHeight + 'px';
        }
    };

    const isIOS = () => {
        return (
            ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
            // iPad sur iOS 13+ de détection
            (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
        );
    };

    useEffect(() => {
        if (authState.me) currentMe.current = authState.me;
        //eslint-disable-next-line
    }, [authState]);

    useEffect(() => {
        if (props.open) {
            setIsToggle(false);
        }
    }, [props.open]);

    useEffect(() => {
        if (!isToggle && props.open) {
            scrollDown();
        }
        //eslint-disable-next-line
    }, [isToggle]);

    useEffect(() => {
        if (props.open) {
            if (!isToggle) {
                scrollDown();
            } else {
                setIsBlinked(true);
            }
        }
        //eslint-disable-next-line
    }, [messages, conversations, opportunities]);

    const handleGoToProfile = useCallback(
        (user) => {
            navigate(`/profil/${user?.profile?.slug}`);
            if (isMobile()) {
                props.onCloseDiscussions();
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [navigate]
    );

    if (!props.open) return null;

    return (
        <>
            {isMessageCloneExceeded && <BuyCloneAi state={isMessageCloneExceeded} setState={setMessageCloneExceeded} data={messageCloneData} />}
            <div
                className={`${props.chatType === 'ai' ? 'chatRoom_ai' : 'chatRoom'} items-stretch ${
                    largeWindow ? 'sm:min-h-[85vh] sm:min-w-[50vh] lg:min-h-[90vh] lg:min-w-[80vh] xl:min-w-[85vh]' : ''
                } ${`${!isToggle ? `h-full sm:h-[70vh]` : 'h-[50px]'}`} sm:rounded-[30px] z-full pointer-events-auto`}
            >
                <div className={`flex ${isToggle ? 'h-full' : 'h-auto border-b border-gray-100'} justify-between gap-2 items-center text-graySkills `}>
                    <span className="sm:hidden block mt-0 py-2 px-2 relative">
                        <ArrowNarrowLeftIcon onClick={() => props.onCloseDiscussions()} className="w-6 h-6 cursor-pointer" />
                    </span>

                    <span className="hidden sm:block mt-0 py-2 px-2 relative ">
                        {props.chatType === 'ai' ? (
                            <AvatarClone gamification={false} user={user} className={`${isToggle ? 'w-9 h-9' : 'w-16 h-16'} rounded-full mt-0`} />
                        ) : (
                            <Avatar gamification={false} user={user} className={`${isToggle ? 'w-9 h-9' : 'w-16 h-16'} rounded-full mt-0`} />
                        )}
                    </span>

                    <div
                        onClick={() => {
                            handleGoToProfile(user);
                        }}
                        className={`${
                            props.chatType === 'ai' ? 'text-white' : 'text-purpleSkills'
                        } font-bold flex sm:flex-col gap-1 items-center justify-center py-2 grow w-full h-full sm:cursor-pointer`}
                    >
                        <Avatar gamification={false} user={user} className="sm:hidden w-9 h-9 rounded-full mt-0" />
                        {props.chatType === 'ai' ? (
                            <span className={`w-44`}>
                                {user?.id === authState.me?.id ? (
                                    <img src={`${config.publicDir}logo_ai_transparent.png`} alt="ai_logo" />
                                ) : (
                                    <span>{user?.profile?.pseudo ? user.profile.pseudo.substring(0, 25) : 'Discussion'}</span>
                                )}
                            </span>
                        ) : (
                            <span>{user?.profile?.pseudo ? user.profile.pseudo.substring(0, 25) : 'Discussion'}</span>
                        )}
                    </div>

                    <span className="sm:hidden block mt-0 py-2 px-2 relative">
                        <XIcon onClick={() => props.onCloseDiscussions()} className="w-6 h-6 cursor-pointer" />
                    </span>

                    <div className="flex gap-1 items-center">
                        {isToggle && isBlinked && (
                            <span className="absolute h-32 w-96">
                                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-success-400 opacity-75"></span>
                            </span>
                        )}

                        <div className="hidden z-10 sm:flex gap-1 items-center">
                            {!isToggle ? (
                                <ChevronDownIcon className="rounded-full cursor-pointer w-6 h-6 hover:text-blueMain hover:bg-blancSkills" onClick={toggle} />
                            ) : (
                                <ChevronUpIcon className="rounded-full cursor-pointer w-6 h-6 hover:text-blueMain hover:bg-blancSkills" onClick={toggle} />
                            )}
                            <ArrowsExpandIcon className="w-6 h-6 hover:cursor-pointer hover:text-blueMain hover:bg-blancSkills" onClick={handleLargeWindow} />
                            <XCircleIcon
                                className="cursor-pointer w-6 h-6 mr-2 hover:text-blueMain hover:bg-blancSkills rounded-full"
                                onClick={() => {
                                    props.onClose(props.discussionId);
                                }}
                            />
                        </div>
                    </div>
                </div>
                {!isToggle && (
                    <>
                        <div className={`${props.chatType === 'ai' ? 'chatRoom_ai_content' : 'chatRoom_content'} pt-0 md:pt-2`}>
                            <div ref={scrollRef} className={`${props.chatType === 'ai' ? 'chatRoom_ai_content_messages' : 'chatRoom_content_messages'}`}>
                                {messages?.[props.discussionId]?.length === 0 && (
                                    <div className="text-sm rounded-lg p-3 text-blue-600 bg-blue-100 mb-2">
                                        <span>{`Lancez la discussion`}</span>
                                    </div>
                                )}
                                {messages?.[props.discussionId]?.map((message, index) => (
                                    <div
                                        key={index}
                                        className={`w-full flex flex-col py-1 ${
                                            index === messages[props.discussionId].length - 1 && !isLoading ? 'last-message' : ''
                                        }`}
                                    >
                                        <div
                                            className={`${props.chatType === 'ai' ? 'chatRoom_ai_content_message' : 'chatRoom_content_message'} ${
                                                message.mine ? 'mine' : 'default'
                                            }`}
                                        >
                                            {message.avatar?.url ? (
                                                <Image
                                                    src={message.avatar?.url + sizes.avatar}
                                                    loading="false"
                                                    className="w-9 h-9 rounded-full shadow-lg mt-0"
                                                />
                                            ) : (
                                                <img
                                                    className="w-9 h-9 rounded-full shadow-lg mt-0"
                                                    src={`${config.publicDir}skillsLogo.jpg` + sizes.avatar}
                                                    alt={message.pseudo}
                                                />
                                            )}

                                            <span>
                                                <div
                                                    className={`${props.chatType === 'ai' ? '' : 'text-mainText'} text-left`}
                                                    dangerouslySetInnerHTML={{ __html: message.message?.replaceAll('\n', '<br />') }}
                                                ></div>
                                                <div className="flex">
                                                    <div className={`text-[9px] ${message.mine ? 'text-right' : 'text-left self-center'}`}>
                                                        {formatMessageDate(message.createdAt ? message.createdAt : message.time)}
                                                    </div>
                                                    {!message.mine && props.chatType === 'ai' && (
                                                        //copy to clipboard
                                                        <ClipboardIcon
                                                            onClick={() =>
                                                                navigator.clipboard.writeText(message.message) &&
                                                                toast.success('Texte copié dans le presse-papier', { type: 'success' })
                                                            }
                                                            className="w-6 h-6 cursor-pointer ml-auto hover:text-purpleSkills hover:bg-blancSkills rounded-full"
                                                        />
                                                    )}
                                                </div>
                                            </span>
                                        </div>
                                    </div>
                                ))}
                                {isLoading && props.chatType === 'ai' && (
                                    <div className={`w-full flex flex-col py-1 last-message`}>
                                        <div className={`${props.chatType === 'ai' ? 'chatRoom_ai_content_message' : 'chatRoom_content_message'} default`}>
                                            <Image
                                                src={user?.profile?.avatar?.url + sizes.avatar}
                                                loading="false"
                                                className="w-9 h-9 rounded-full shadow-lg mt-0"
                                            />
                                            <span className="text-left">
                                                <div className="waiting-animation"></div>
                                            </span>
                                        </div>
                                    </div>
                                )}
                                {conversations?.[props.discussionId]?.map((conversation, index) => (
                                    <div
                                        key={index}
                                        className={`w-full flex flex-col py-1 ${
                                            index === conversations[props.discussionId].length - 1 && !isLoading ? 'last-message' : ''
                                        }`}
                                    >
                                        <div className="reportOffensif chatRoom_ai_content_message mine text-white">
                                            {conversation.cloneReportOffensif?.userOffensif?.profile?.avatar?.url ? (
                                                <Image
                                                    src={conversation.cloneReportOffensif?.userOffensif?.profile?.avatar?.url + sizes.avatar}
                                                    loading="false"
                                                    className="w-9 h-9 rounded-full shadow-lg mt-0"
                                                />
                                            ) : (
                                                <img
                                                    className="w-9 h-9 rounded-full shadow-lg mt-0"
                                                    src={`${config.publicDir}skillsLogo.jpg` + sizes.avatar}
                                                    alt={conversation.cloneReportOffensif?.userOffensif?.profile?.pseudo}
                                                />
                                            )}
                                            <span>
                                                <div
                                                    className={`ai text-left`}
                                                    dangerouslySetInnerHTML={{ __html: conversation.cloneReportOffensif?.message?.replaceAll('\n', '<br />') }}
                                                ></div>
                                                <div className={`text-[9px] text-right`}>
                                                    {formatMessageDate(
                                                        moment(conversation.cloneReportOffensif?.createdAt).tz('Europe/Paris').format('DD/MM/YYYY HH:mm')
                                                    )}
                                                </div>
                                            </span>
                                        </div>

                                        {conversation.isOpen &&
                                            conversation.messages?.map((message, j) => (
                                                <div key={j}>
                                                    <div
                                                        className={`${props.chatType === 'ai' ? 'chatRoom_ai_content_message' : 'chatRoom_content_message'} ${
                                                            message.user.id === authState.me.id ? 'mine' : 'default'
                                                        }`}
                                                    >
                                                        {message.user?.profile?.avatar?.url ? (
                                                            <Image
                                                                src={message.user?.profile?.avatar?.url + sizes.avatar}
                                                                loading="false"
                                                                className="w-9 h-9 rounded-full shadow-lg mt-0"
                                                            />
                                                        ) : (
                                                            <img
                                                                className="w-9 h-9 rounded-full shadow-lg mt-0"
                                                                src={`${config.publicDir}skillsLogo.jpg` + sizes.avatar}
                                                                alt={message.user?.pseudo}
                                                            />
                                                        )}

                                                        <span>
                                                            <div
                                                                className={`${props.chatType === 'ai' ? '' : 'text-mainText'} text-left`}
                                                                dangerouslySetInnerHTML={{ __html: message.message?.replaceAll('\n', '<br />') }}
                                                            ></div>
                                                            <div className={`text-[9px] ${message.user.id === authState.me.id ? 'text-right' : 'text-left'}`}>
                                                                {formatMessageDate(
                                                                    message.createdAt
                                                                        ? moment(message.createdAt).tz('Europe/Paris').format('DD/MM/YYYY HH:mm')
                                                                        : message.time
                                                                )}
                                                            </div>
                                                        </span>
                                                    </div>
                                                </div>
                                            ))}

                                        <button
                                            onClick={(e) => {
                                                const newConversations = { ...conversations };
                                                newConversations[props.discussionId][index].isOpen = !newConversations[props.discussionId][index].isOpen;
                                                setConversations(newConversations);
                                            }}
                                            className="text-white py-2 px-2 w-52 bg-[#b235fe] rounded-full"
                                        >
                                            {conversation.isOpen ? (
                                                <>
                                                    <div className="flex gap-2">
                                                        <ChevronDownIcon className="text-white w-5" />
                                                        <span>Fermer la conversation</span>
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <div className="flex gap-2">
                                                        <ChevronUpIcon className="text-white w-5" />
                                                        <span>Montrer la conversation</span>
                                                    </div>
                                                </>
                                            )}
                                        </button>
                                    </div>
                                ))}
                                {props.cloneDialogueState && props.chatType === 'ai' && (
                                    <div className={`w-full flex flex-col py-1 last-message`}>
                                        <div className={`${props.chatType === 'ai' ? 'chatRoom_ai_content_message' : 'chatRoom_content_message'} default`}>
                                            <Image
                                                src={user?.profile?.avatar?.url + sizes.avatar}
                                                loading="false"
                                                className="w-9 h-9 rounded-full shadow-lg mt-0"
                                            />
                                            <span className="text-left">
                                                <div className="waiting-animation"></div>
                                            </span>
                                        </div>
                                    </div>
                                )}
                                {opportunities?.[props.discussionId]?.map((opportunity, index) => (
                                    <div
                                        key={index}
                                        className={`w-full flex flex-col py-1 ${
                                            index === opportunities[props.discussionId].length - 1 && !isLoading ? 'last-message' : ''
                                        }`}
                                    >
                                        <div className="reportOffensif chatRoom_ai_content_message mine text-white">
                                            {opportunity.cloneReportDefensif?.userDefensif?.profile?.avatar?.url ? (
                                                <Image
                                                    src={opportunity.cloneReportDefensif?.userDefensif?.profile?.avatar?.url + sizes.avatar}
                                                    loading="false"
                                                    className="w-9 h-9 rounded-full shadow-lg mt-0"
                                                />
                                            ) : (
                                                <img
                                                    className="w-9 h-9 rounded-full shadow-lg mt-0"
                                                    src={`${config.publicDir}skillsLogo.jpg` + sizes.avatar}
                                                    alt={opportunity.cloneReportDefensif?.userDefensif?.profile?.pseudo}
                                                />
                                            )}
                                            <span>
                                                <div
                                                    className={`ai text-left`}
                                                    dangerouslySetInnerHTML={{ __html: opportunity.cloneReportDefensif?.message?.replaceAll('\n', '<br />') }}
                                                ></div>
                                                <div className={`text-[9px] text-right`}>
                                                    {formatMessageDate(
                                                        moment(opportunity.cloneReportDefensif?.createdAt).tz('Europe/Paris').format('DD/MM/YYYY HH:mm')
                                                    )}
                                                </div>
                                            </span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <form onSubmit={handleSubmit} autoComplete="off" className={`${props.chatType === 'ai' ? 'chatRoom_ai_form' : 'chatRoom_form'}`}>
                            {authState.isLogged ? (
                                <div className={`${props.chatType === 'ai' ? 'chatRoom_ai_content_input' : 'chatRoom_content_input'}`}>
                                    <textarea
                                        ref={refInputMessage}
                                        rows={largeWindow ? 3 : isMobile() ? 1 : 2}
                                        id={props.discussionId}
                                        onFocus={() => {
                                            if (isIOS()) {
                                                document.querySelector('.chatRoom_form').style.marginBottom = '40px';
                                            }

                                            const lastMessage = document.querySelector('.last-message');
                                            if (lastMessage) {
                                                lastMessage.scrollIntoView({ behavior: 'smooth' });
                                            }
                                            onFocus();
                                        }}
                                        onBlur={() => {
                                            if (isIOS()) {
                                                document.querySelector('.chatRoom_form').style.marginBottom = '0';
                                            }
                                        }}
                                        defaultValue=""
                                        onKeyDown={onKeyDown}
                                        onKeyUp={onKeyUp}
                                        onInput={onInput}
                                        disabled={props.type === 'cloneConversation' || props.type === 'cloneOpportunities' ? true : false}
                                        name="message"
                                        placeholder="Votre message"
                                    ></textarea>
                                    {/* <input ref={refInputMessage} type="text" name="message" placeholder="Votre message" autoComplete="off" /> */}
                                    <button type="submit" className={`${!isMobile() ? 'hover:text-blueMain' : ''}`}>
                                        <PaperAirplaneIcon className="w-6 h-6" />
                                    </button>
                                </div>
                            ) : (
                                <div className="chatRoom_content_noConnected">
                                    <button className="link" onClick={handleLogin}>
                                        Se connecter pour Chatter
                                    </button>
                                </div>
                            )}
                        </form>
                    </>
                )}
            </div>
        </>
    );
}

export default forwardRef(ChatRoom);
