import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { isSmartphone } from 'utils/functions';

import { createPortal } from 'react-dom';

import Modal from 'components/common/Modal';

import {
    FacebookShareButton,
    WhatsappShareButton,
    WhatsappIcon,
    LinkedinShareButton,
    EmailShareButton,
    EmailIcon,
    FacebookMessengerShareButton,
    FacebookMessengerIcon,
} from 'react-share';

import { LinkIcon, XIcon } from '@heroicons/react/solid';
import { SvgFacebook, SvgLinkedin, SvgX } from '../common/Svg';
import TwitterShareButton from './ShareButtons/TwitterShareButton';

function ShareOptions({ url, onClose, isOpen, title }) {
    const location = useLocation();
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        if (isOpen && !isSmartphone()) {
            setShowModal(true);
        } else {
            setShowModal(false);
        }
    }, [isOpen]);

    const copyUrlToClipboard = async () => {
        if (!navigator.clipboard) {
            alert("La copie dans le presse-papiers n'est pas prise en charge.");
            return handleModalClose();
        }
        try {
            await navigator.clipboard.writeText(url);
        } catch (err) {
            alert('Erreur lors de la copie du lien: ' + err);
        }
        handleModalClose();
    };

    const handleModalClose = () => {
        if (title) {
            navigate(location.pathname, { state: { showModal: false } });
            setShowModal(false);
        } else
            onClose();
    };

    return createPortal(
        <>
            <Modal
                open={showModal}
                noBorder={true}
                noActions={true}
                onCloseOverlay={true}
                onClose={{
                    noButton: true,
                    onClick: () => {
                        handleModalClose();
                    },
                }}
            >
                <div className="flex flex-col gap-4">
                    <div className="w-full flex flex-col gap-1">
                        <span className="text-mainText font-extrabold text-lg text-center">{`${title ? title : "Partager"}`}</span>
                    </div>
                    <div className="flex w-full justify-center">
                        <button
                            onClick={copyUrlToClipboard}
                            className="w-36 flex flex-col items-center justify-center bg-[#e6e8f2] text-purpleSkills gap-1 p-2 rounded-2xl"
                        >
                            <LinkIcon className="w-4 h-4" />
                            <span className="font-bold  text-sm">Copier le lien</span>
                        </button>
                    </div>
                    <div className="flex flex-col gap-1 mt-2">
                        <div className="flex justify-center gap-2 overflow-x-auto">
                            <TwitterShareButton url={url} className="w-auto">
                                <SvgX className="w-12 h-12 text-white bg-black rounded-full p-1" />
                            </TwitterShareButton>
                            <LinkedinShareButton url={url} className="w-auto">
                                <SvgLinkedin className="w-12 h-12 text-[#0a66c2] rounded-full" />
                            </LinkedinShareButton>
                            <WhatsappShareButton url={url} className="w-auto">
                                <WhatsappIcon size={50} round />
                            </WhatsappShareButton>
                            <FacebookShareButton url={url} className="w-auto">
                                <SvgFacebook className="w-12 h-12 text-[#0866ff] rounded-full" />
                            </FacebookShareButton>
                            <FacebookMessengerShareButton url={url} className="w-auto">
                                <FacebookMessengerIcon size={50} round />
                            </FacebookMessengerShareButton>
                            <EmailShareButton url={url} className="w-auto">
                                <EmailIcon size={50} round />
                            </EmailShareButton>
                        </div>
                    </div>
                </div>
            </Modal>

            {isOpen && isSmartphone() && (
                <div className={`sm:hidden fixed inset-0 bg-graySkills bg-opacity-60 z-20`} onClick={onClose}>
                    <div
                        onClick={(e) => e.stopPropagation()}
                        className="sm:hidden h-96 absolute inset-x-0 bottom-0 p-3 bg-white rounded-t-3xl shadow-lg z-50 md:hidden flex flex-col gap-4"
                    >
                        <div className="w-full flex flex-col gap-1">
                            <span className="text-mainText font-extrabold text-lg text-center">Partager</span>

                            <button onClick={onClose} className="absolute right-2">
                                <XIcon className="w-6 h-6 text-black" />
                            </button>
                        </div>
                        <div className="flex w-full justify-center">
                            <button
                                onClick={copyUrlToClipboard}
                                className="w-36 flex flex-col items-center justify-center bg-[#e6e8f2] text-purpleSkills gap-1 p-2 rounded-2xl"
                            >
                                <LinkIcon className="w-4 h-4" />
                                <span className="font-bold  text-sm">Copier le lien</span>
                            </button>
                        </div>
                        <div className="flex flex-col gap-1 mt-2">
                            <div className="flex justify-center gap-2 overflow-x-auto">
                                <TwitterShareButton url={url} className="w-auto">
                                    <SvgX className="w-12 h-12 text-white bg-black rounded-full p-1" />
                                </TwitterShareButton>
                                <LinkedinShareButton url={url} className="w-auto">
                                    <SvgLinkedin className="w-12 h-12 text-[#0a66c2] rounded-full" />
                                </LinkedinShareButton>
                                <WhatsappShareButton url={url} className="w-auto">
                                    <WhatsappIcon size={50} round />
                                </WhatsappShareButton>
                                <FacebookShareButton url={url} className="w-auto">
                                    <SvgFacebook className="w-12 h-12 text-[#0866ff] rounded-full" />
                                </FacebookShareButton>
                                <FacebookMessengerShareButton url={url} className="w-auto">
                                    <FacebookMessengerIcon size={50} round />
                                </FacebookMessengerShareButton>
                                <EmailShareButton url={url} className="w-auto">
                                    <EmailIcon size={50} round />
                                </EmailShareButton>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>,
        document.body
    );
}

export default ShareOptions;
