// import { useEffect, useState } from "react";
//API
// import { useQuery } from "react-query";
// import { getAll } from "api/search";
//STYLES
import "App.css";
//LAYOUT
import FullLayout from "pages/layouts/Full";
//LIBS
// import { useSearchParams } from "react-router-dom";
//COMPONENTS
//CONTAINERS

function Profil() {
    return (
        <FullLayout>
            <main>
                <div className="container-md">
                    <h1 className="text-center text-secondary my-8 text-3xl font-bold">
                        Passez <span className="bg-secondary text-white p-1 rounded">PREMIUM</span> dès maintenant !
                    </h1>
                    <p className="text-center text-blue-800 font-bold">À partir de 39€ par mois</p>

                    <div className="flex flex-wrap md:flex-nowrap md:flex-row justify-center items-center gap-4 mt-8">
                        <div className="w-full md:w-3/12 max-w-xs">
                            <div className="flex flex-col justify-center p-6 bg-white rounded-3xl h-auto text-secondary shadow-xl">
                                <h2 className="font-bold uppercase">Gratuit</h2>

                                <ul className="my-6 ml-5 list-disc text-sm">
                                    <li>Smartprofil</li>
                                    <li>Tag principal référencé*</li>
                                </ul>
                                {/* <button className="button button-default mt-4">Bientôt</button> */}
                            </div>
                        </div>
                        <div className="w-full md:w-3/12 max-w-xs">
                            <div className="flex flex-col justify-center p-6 bg-secondary rounded-3xl h-auto text-white shadow-xl">
                                <h2 className="font-bold uppercase">Premium 6 mois</h2>
                                <div className="flex justify-around items-center mt-2">
                                    <span className="font-bold text-xl w-12 h-12 flex justify-center items-center text-secondary bg-white rounded-full shadow-xl">39€</span>
                                    <span className="text-white">
                                        Engagement <strong>6</strong> mois
                                    </span>
                                </div>
                                <ul className="my-6 ml-5 list-disc text-sm">
                                    <li>10 tags principaux référencés*</li>
                                    <li>Smartprofil sans profil similaire en suggestion</li>
                                    <li>Label premium sur votre smartprofil</li>
                                </ul>
                                <button className="button button-default mt-4">Bientôt</button>
                            </div>
                        </div>
                        <div className="w-full md:w-3/12 max-w-xs">
                            <div className="flex flex-col justify-center p-6 bg-white rounded-3xl h-auto text-secondary shadow-xl">
                                <h2 className="font-bold uppercase">Premium sans engagement</h2>
                                <div className="flex justify-around items-center mt-2">
                                    <span className="font-bold text-xl w-12 h-12 flex justify-center items-center text-secondary bg-white rounded-full shadow-xl">49€</span>
                                    <span className="text-secondary">Sans engagement</span>
                                </div>
                                <ul className="my-6 ml-5 list-disc text-sm">
                                    <li>10 tags principaux référencés*</li>
                                    <li>Smartprofil sans profil similaire en suggestion</li>
                                    <li>Label premium sur votre smartprofil</li>
                                </ul>
                                <button className="button button-default mt-4">Bientôt</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-md flex justify-center">
                    <p className="text-blue-800 mt-8 text-sm max-w-lg">*Les tags référencés sont ceux qui vous apporteront une notification immédiate dès qu’une question sera posée dessus. Vous recevrez une notification après 12h sur les autres tags.</p>
                </div>
            </main>
        </FullLayout>
    );
}

export default Profil;
