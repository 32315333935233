import { useState, useRef, useEffect, useContext, useCallback } from 'react';

//CONTEXTS
import { FormContext } from 'context/formContext';

//COMPONENTS
import FormAddNeed from '../../components/forms/addNeed';
import Modal from 'components/common/Modal';

function AddNeed() {
    const [need, setNeed] = useState(false);
    //CONTEXTS
    const [formContextState, formContextDispatch] = useContext(FormContext);
    //REFS
    const formNeedRef = useRef(null);

    //DISPATCH
    const dispatchAction = useCallback(() => {
        switch (formContextState.action) {
            case 'addNeed':
                setNeed(true);
                break;
            default:
        }
        /* console.log(formContextState.action); */
        // eslint-disable-next-line
    }, [formContextState]);

    useEffect(() => {
        dispatchAction();
        //eslint-disable-next-line
    }, [dispatchAction]);

    function handleSubmitNeed() {
        formNeedRef.current.handleSubmit();
    }

    function closeForm() {
        setNeed(false);
        formContextDispatch({ type: 'reset' });
    }

    return (
        <Modal
            title="Précisez bien ce qu'il vous faut"
            open={need}
            onClose={{
                onClick: () => {
                    setNeed(false);
                    formContextDispatch({ type: 'reset' });
                },
            }}
            onConfirm={{
                label: 'Ajouter',
                onClick: () => {
                    handleSubmitNeed();
                },
            }}
        >
            <FormAddNeed closeForm={closeForm} ref={formNeedRef} />
        </Modal>
    );
}

export default AddNeed;
