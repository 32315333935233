import { useEffect, useContext, useState } from 'react';

//LIBS
import { setPersistence, signInWithEmailAndPassword, browserSessionPersistence } from 'firebase/auth';

//CONTEXTS
import { HeadlessContext } from '../index.js';
import { HeadlessNotificationsContext } from './HeadlessNotifications';

//HOOKS
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

//SCHEMAS
import schema from '../yup/auth';

//ICONS
import { AiOutlineCloseSquare } from 'react-icons/ai';
import { ImSpinner8 } from 'react-icons/im';

const logo = require('../headless.png');

export default function HeadlessLogin() {
    const store = useContext(HeadlessContext);
    const notif = useContext(HeadlessNotificationsContext);

    const [state, setState] = useState({ submitted: false, logged: false });

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    function handleClose() {
        store.set({ type: 'close' });
    }

    async function onSubmit(data) {
        if (errors.length > 0) return false;

        const auth = store.get('firebase').auth;

        setPersistence(auth, browserSessionPersistence)
            .then(async () => {
                return signInWithEmailAndPassword(auth, data.headless_login, data.headless_password)
                    .then(() => {
                        setState({ ...state, submitted: true, logged: true });
                        notif.set({ action: 'add', type: 'success', message: 'Vous êtes connecté(e)' });
                    })
                    .catch(() => {
                        setState({ ...state, submitted: true, logged: false });
                    });
            })
            .catch(console.error);
    }

    useEffect(() => {
        if (store.get('opened')) setState({ submitted: false, logged: false });
        //eslint-disable-next-line
    }, [store.get('opened')]);

    if (!store.get('opened')) return null;

    return (
        <div className="fixed flex justify-center items-center z-50 top-0 left-0 w-full h-full backdrop-filter backdrop-blur-sm bg-gray-200/20">
            <div className="relative w-64 max-w-full h-auto rounded-sm shadow bg-white p-8 flex flex-col items-center">
                <button onClick={handleClose} className="absolute -top-8 right-0 p-0 text-[#745C89]">
                    <AiOutlineCloseSquare className="w-6 h-6" />
                </button>
                <img src={logo} className="w-20" alt="headless" />

                {state.submitted && !state.logged && (
                    <span className="border border-[#ff0000] p-2 w-full text-[#ff0000] text-xs mt-2 text-center rounded-sm">Authentification non permise</span>
                )}
                {state.submitted && state.logged && (
                    <span className="border border-[#488D30] p-2 w-full text-[#488D30] text-xs mt-2 text-center rounded-sm flex gap-1">
                        <ImSpinner8 className="animate-spin" />
                        Bienvenue
                    </span>
                )}
                {!state.logged && (
                    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                        <div className="mt-4 w-full flex flex-col items-start">
                            <label className="text-gray-700 text-sm">Identifiant</label>
                            <input
                                type="text"
                                {...register('headless_login')}
                                className="!border !border-gray-200 !w-full !rounded !py-1 !px-2 !text-sm focus:!border-[#745C89] focus:!outline-none"
                                placeholder="Login"
                            />
                            {errors && errors['headless_login'] && (
                                <span className={`mt-0 mb-1 relative !text-xs !text-[#ff0000]`}>{errors['headless_login'].message}</span>
                            )}
                            <label className="!text-gray-700 !text-sm !mt-2">Mot de passe</label>
                            <input
                                type="password"
                                {...register('headless_password')}
                                className="!border !border-gray-200 !w-full !rounded !py-1 !px-2 !text-sm focus:!border-[#745C89] focus:!outline-none"
                            />
                            {errors && errors['headless_password'] && (
                                <span className={`mt-0 mb-1 relative !text-xs !text-[#ff0000]`}>{errors['headless_password'].message}</span>
                            )}
                            <div className="text-center w-full">
                                <button className="!py-1 !px-2 !mt-4 !text-white !rounded-sm !shadow !text-sm !bg-[#7A6291]">Connexion</button>
                            </div>
                        </div>
                    </form>
                )}
            </div>
        </div>
    );
}
