
import { useEffect, useState } from 'react';
//CONTAINERS
import SectionTitle from 'components/common/SectionTitle';
import CardFrontService from 'containers/services/CardFrontService';
//LIBS
import _ from 'lodash';

function SearchServices(props) {
    const [nb, setNb] = useState(4);
    const [items, setItems] = useState(props?.filter === false ? _.take(props.services, nb) : props.services);

    function showMore() {
        // setNb(nb + 3);
        setNb(_.size(props.services));
    }
    function showLess() {
        setNb(4);
    }

    useEffect(() => {
        if (nb >= 4 && props?.filter === false)
            setItems(_.take(props.services, nb));
        else
            setItems(props.services);
        //eslint-disable-next-line
    }, [nb, props.services, props?.filter]);

    return (
        <>
            <SectionTitle>
                <p className="text-xs text-graySkills mx-0 my-4">
                    {/* {props.users.length > 20 && <span>Environs </span>} */}
                    <span className="h2 mx-0 my-2 mt-4 text-bold text-purpleSkills">Services :</span>
                </p>
            </SectionTitle>
            <div className={`grid grid-cols-2 lg:grid-cols-3 auto-cols-max gap-x-2 sm:gap-4`}>
                {items.map((service, index) => (
                    <div className="card card_profile w-full" key={index}>
                        <CardFrontService service={service} refetch={props.refetch} />
                    </div>
                ))}
            </div>
            {props?.filter === false && nb < props.services?.length ? (
                <div className="container">
                    <div className="flex justify-center border-b border-gray-200 pb-4 mb-8 mt-4 text-primary font-semibold text-sm">
                        <button onClick={showMore}>...Voir plus de services</button>
                    </div>
                </div>
            ) : (
                props?.filter === false &&
                <>
                    {props.services?.length >= 4 && (
                        <div className="container">
                            <div className="flex justify-center border-b border-gray-200 pb-4 mb-8 mt-4 text-primary font-semibold text-sm">
                                <button onClick={showLess}>...Replier</button>
                            </div>
                        </div>
                    )}
                </>
            )}
        </>
    );
}

export default SearchServices;
