import { useQuery } from "react-query";
import { getSitemap } from "../api/sitemaps";

function Sitemap() {

    const { data } = useQuery("sitemap", getSitemap);

    return (
        <>
            {data && <p>{data}</p>}
        </>
    );
}
export default Sitemap;