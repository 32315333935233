import { useEffect, useState, useRef } from 'react';

// Fonction pour détecter si la plateforme est iOS
const isIOS = () => {
    return /iPhone|iPad|iPod/.test(navigator.userAgent) || /iPad|iPhone|iPod/.test(navigator.platform);
};

// Throttle helper function to limit the frequency of function calls
const throttle = (func, limit) => {
    let inThrottle;
    return (...args) => {
        if (!inThrottle) {
            func.apply(this, args);
            inThrottle = true;
            setTimeout(() => (inThrottle = false), limit);
        }
    };
};

const useDetectKeyboardOpen = (minKeyboardHeight = 300, defaultValue = false) => {
    const [isKeyboardOpen, setIsKeyboardOpen] = useState(defaultValue);
    const [initialHeight, setInitialHeight] = useState(
        typeof window !== 'undefined' && window.visualViewport ? window.visualViewport.height : window.innerHeight
    );

    const keyboardTimeoutRef = useRef(null);

    useEffect(() => {
        if (typeof window === 'undefined') return; // Ne rien faire si window n'est pas défini (ex: côté serveur)

        const handleResize = () => {
            const currentHeight = window.visualViewport ? window.visualViewport.height : window.innerHeight;

            // Sur iOS, les fluctuations minimes de la hauteur de la fenêtre sont courantes
            const heightDiff = initialHeight - currentHeight;

            // On considère un seuil plus large pour éviter de détecter des faux positifs (ex: barres de navigation)
            const newState = heightDiff > minKeyboardHeight;

            if (newState !== isKeyboardOpen) {
                // Pour éviter des changements trop rapides, on annule les états de clavier après 300ms si non confirmé
                clearTimeout(keyboardTimeoutRef.current);

                keyboardTimeoutRef.current = setTimeout(() => {
                    setIsKeyboardOpen(newState);
                }, 300); // Délai de 300ms pour éviter les faux changements d'état
            }
        };

        const debouncedResizeHandler = throttle(() => {
            if (isIOS()) {
                setTimeout(handleResize, 300); // Délai spécifique à iOS
            } else {
                handleResize();
            }
        }, 150); // Throttle à 150ms pour réduire les appels

        if (typeof visualViewport !== 'undefined') {
            window.visualViewport.addEventListener('resize', debouncedResizeHandler);
        } else {
            window.addEventListener('resize', debouncedResizeHandler); // Fallback si visualViewport n'est pas supporté
        }

        // Sauvegarder la hauteur initiale seulement si elle est différente
        const newInitialHeight = window.visualViewport ? window.visualViewport.height : window.innerHeight;
        if (initialHeight !== newInitialHeight) {
            setInitialHeight(newInitialHeight);
        }

        return () => {
            if (typeof visualViewport !== 'undefined') {
                window.visualViewport.removeEventListener('resize', debouncedResizeHandler);
            } else {
                window.removeEventListener('resize', debouncedResizeHandler);
            }

            // Nettoyer le timeout en cas de démontage du composant
            clearTimeout(keyboardTimeoutRef.current);
        };
    }, [isKeyboardOpen, minKeyboardHeight, initialHeight]);

    return isKeyboardOpen;
};

export default useDetectKeyboardOpen;
