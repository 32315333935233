import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

//CONTEXTS
import { AuthContext } from 'context/authContext';
import { FormContext } from 'context/formContext';
//FORMS
import schema from 'components/forms/yup/aiTrain';
import TextareaCustom from '../fields/TextareaCustom';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { putAiTrain } from '../../../api/users';

function AiTrainSndStep(props) {
    const [authState, , refresh] = useContext(AuthContext);
    const [formContextState, formContextDispatch] = useContext(FormContext);

    const [readyToWatch, setReadyToWatch] = useState(false);

    const [answer4InitialValue, setAnswer4InitialValue] = useState('');
    const [answer5InitialValue, setAnswer5InitialValue] = useState('');
    const [answer6InitialValue, setAnswer6InitialValue] = useState('');

    const currentForm = useRef(null);

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        clearErrors,
    } = useForm({
        resolver: yupResolver(schema),
    });

    //DISPATCH
    const dispatchAction = useCallback(() => {
        switch (formContextState.action) {
            case 'save':
                currentForm.current.click();
                break;
            default:
        }
        // eslint-disable-next-line
    }, [formContextState.action]);

    useEffect(() => {
        if (readyToWatch) {
            clearErrors();
        }
        //eslint-disable-next-line
    }, [readyToWatch]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setReadyToWatch(true);
        }, 1000);

        return () => clearTimeout(timer);
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        dispatchAction();
    }, [dispatchAction]);

    const onSubmitClose = () => {
        handleSubmit((data) => onSubmit(data, 'close'))();
    };

    useEffect(() => {
        const me = authState.me;
        if (me) {
            setValue('answer1', me.aiTrain[0]?.answer);
            setValue('answer2', me.aiTrain[1]?.answer);
            setValue('answer3', me.aiTrain[2]?.answer);
            setValue('answer4', me.aiTrain[3]?.answer);
            setValue('answer5', me.aiTrain[4]?.answer);
            setValue('answer6', me.aiTrain[5]?.answer);

            setAnswer4InitialValue(me.aiTrain[3]?.answer);
            setAnswer5InitialValue(me.aiTrain[4]?.answer);
            setAnswer6InitialValue(me.aiTrain[5]?.answer);

            setTimeout(() => {
                setReadyToWatch(true);
            }, 1000);
        }
        //eslint-disable-next-line
    }, [authState]);

    const onSubmit = (data, button) => {
        if (errors.length > 0) return false;

        const put = putAiTrain(data);
        put.then((res) => {
            if (res.status === 200) {
                toast('Ai Train mis à jour', { type: 'success' });
                if (button === 'close') {
                    props.closeSteps();
                    props.setPage(0);
                } else if (button === 'next') {
                    props.setPage(props.page + 1);
                }
                refresh();

                setTimeout(() => {
                    formContextDispatch({ type: 'change', status: false });
                }, 100);
            } else {
                toast('Une erreur est survenue', { type: 'danger' });
            }
        });
    };

    return (
        <div className="flex flex-col">
            <h1 className="flex justify-end text-white mb-2">2/2</h1>
            <form onSubmit={(e) => e.preventDefault()} autoComplete="off" encType="multipart/form-data">
                <div className="overflow-y-auto max-h-[30rem]">
                    <div className="mt-2">
                        <TextareaCustom
                            type={'ai'}
                            name="answer4"
                            label={'Quels sont vos objectifs à court et à long terme ?'}
                            placeholder="Réponse"
                            rows={1}
                            className={`inputAi_floating mt-2 text-gray-300 placeholder:text-gray-300`}
                            autoresize={true}
                            errors={errors}
                            register={register}
                            initValue={answer4InitialValue}
                            onChange={(e) => {
                                setValue('answer4', e.target.value);
                            }}
                            tooltip="Cela permet à l'IA de négocier efficacement en fonction des intérêts de l'utilisateur"
                        />
                    </div>

                    <div className="mt-2">
                        <TextareaCustom
                            type={'ai'}
                            name="answer5"
                            label={'Les questions de vos clients et vos réponses pour automatiser vos réponses ?'}
                            placeholder="Réponse"
                            rows={1}
                            className={`inputAi_floating mt-2 text-gray-300 placeholder:text-gray-300`}
                            autoresize={true}
                            errors={errors}
                            register={register}
                            initValue={answer5InitialValue}
                            onChange={(e) => {
                                setValue('answer5', e.target.value);
                            }}
                            tooltip="Cette question peut aider le clone AI à comprendre et à imiter le style de persuasion de l'utilisateur"
                        />
                    </div>

                    <div className="mt-2">
                        <TextareaCustom
                            type={'ai'}
                            name="answer6"
                            label={'Quels sont les points non négociables pour vous dans une discussion ou une négociation ?'}
                            placeholder="Réponse"
                            rows={1}
                            className={`inputAi_floating mt-2 text-gray-300 placeholder:text-gray-300`}
                            autoresize={true}
                            errors={errors}
                            register={register}
                            initValue={answer6InitialValue}
                            onChange={(e) => {
                                setValue('answer6', e.target.value);
                            }}
                            tooltip="Cela donne à l'IA une ligne directrice claire sur ce que l'utilisateur ne souhaite pas compromettre"
                        />
                    </div>
                </div>

                <div className="w-full flex justify-end pt-4">
                    <button
                        className={`bg-[#E348FF] button hover:text-blueMain hover:bg-white font-bold text-white justify-end w-auto px-8 py-2 rounded-full`}
                        onClick={onSubmitClose}
                    >
                        Terminer
                    </button>
                </div>
                <input ref={currentForm} type="submit" className="hidden" />
            </form>
        </div>
    );
}

export default AiTrainSndStep;
