import API from './axios.config';
import authHeader from 'services/Auth.Header';

//GET
async function getRoomsByMe() {
    const response = await API.get('forum/me', { headers: authHeader() });
    return response.data;
}

async function getRooms() {
    const response = await API.get('forum');
    return response.data;
}

async function getAllPosts(params) {
    const response = await API.get('forum/posts' + params);
    return response.data;
}

async function getRoomsP(params, isForMe, isNew, isJob, isLogged) {
    let slug = '';
    if (isForMe && isLogged) {
        if (isNew) {
            slug = 'forum/interest/custom/news';
        } else if (isJob) {
            slug = 'forum/jobs/custom';
        } else {
            slug = 'forum/interest/custom';
        }
    } else {
        if (isNew) {
            slug = 'forum/interest/all/news';
        } else if (isJob) {
            slug = 'forum/jobs';
        } else {
            slug = 'forum/interest/all';
        }
    }
    //if isLogged, add authHeader
    const response = await API.get(slug + params, {
        headers: isLogged ? authHeader() : {},
    });
    return response.data;
}

async function getRoom(id) {
    const response = await API.get('forum/' + id, {
        headers: authHeader(),
    });
    return response.data;
}

async function getJobsP(params, isFav) {
    const userStr = localStorage.getItem('user');
    let user = null;
    if (userStr) user = JSON.parse(userStr);
    if (isFav && user?.userId) {
        params = params + '&isFav=' + user.userId;
    }
    const response = await API.get('forum/jobsPg' + params);
    return response.data;
}

async function getRoomsByUser(userId, limit) {
    const response = await API.get('forum/user/' + userId + '?limit=' + limit);
    return response.data;
}

async function getRoomsByUserForProfilPaginate(params) {
    const response = await API.get('forum/user/profil/p?' + params);
    return response.data;
}

async function getSampleRoomsByUser(id, limit) {
    const response = await API.get(`forum/sample/user/` + id + `?limit=${limit}`);
    return response.data;
}

/* async function getShootsPaginateByUser(params) {
    const response = await API.get(`shoots/user/p?` + params, { headers: authHeader() });
    return response.data;
}
 */

async function getJobsFav(params) {
    const response = await API.get('forum/jobs/fav' + params, { headers: authHeader() });
    return response.data;
}

async function getSingleRoom(slug) {
    const response = await API.get('forum/slug/' + slug);
    return response.data;
}

async function getUsersByTagsRoom(slug) {
    const response = await API.get('forum/users/' + slug);
    return response.data;
}

async function countJobs() {
    const response = await API.get('forum/jobs/nb');
    return response.data;
}

//PUT
async function putForum(data, auth, id) {
    const response = await API.put('forum/edit', data, {
        headers: authHeader(),
    });
    return response;
}

async function putRoomPublish(id) {
    const response = await API.put(
        'forum/togglePublish/' + id,
        { publish: true },
        {
            headers: authHeader(),
        }
    );
    return response.data;
}
async function putSelectAnswer(id, type) {
    const response = await API.put('forum/selectAnswer/' + id, { type }, { headers: authHeader() });
    return response.data;
}
async function putAnswer(id, type, data) {
    const response = await API.put('forum/updateAnswer/', { id, type, data }, { headers: authHeader() });
    return response.data;
}
//POST
async function postForum(data, isLogged) {
    if (isLogged === true) {
        const response = await API.post('forum/create', data, {
            headers: authHeader(),
        });
        return response;
    } else {
        const response = await API.post('forum/createAnonymous', data);
        return response;
    }
}
async function postAnswer(data) {
    const response = await API.post('forum/answer', data, {
        headers: authHeader(),
    });
    return response;
}
async function postAnswerTo(data) {
    const response = await API.post('forum/answerTo', data, {
        headers: authHeader(),
    });
    return response;
}

async function postAnswerToAnswer(data) {
    const response = await API.post('forum/addAnswerToAnswer', data, {
        headers: authHeader(),
    });
    return response;
}
//DELETE
async function deleteRoom(id) {
    const response = await API.delete('forum/' + id, {
        headers: authHeader(),
    });
    return response;
}

async function deleteAnswer(id, type) {
    const response = await API.delete(`forum/deleteAnswer/${id}`, {
        data: {
            type: type,
        },
        headers: authHeader(),
    });
    return response.data;
}

export {
    postForum,
    getRoomsByMe,
    getRooms,
    getRoomsP,
    getRoom,
    getJobsP,
    getAllPosts,
    getRoomsByUser,
    getRoomsByUserForProfilPaginate,
    getSampleRoomsByUser,
    getJobsFav,
    getSingleRoom,
    getUsersByTagsRoom,
    countJobs,
    putForum,
    putRoomPublish,
    putSelectAnswer,
    putAnswer,
    postAnswer,
    postAnswerTo,
    postAnswerToAnswer,
    deleteRoom,
    deleteAnswer,
};
