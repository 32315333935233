import { useState, useRef } from 'react';

import "pdfjs-dist/build/pdf.worker.mjs";
import * as pdfjs from 'pdfjs-dist'

import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';

import { DocumentAddIcon } from '@heroicons/react/solid';
import { uploadCV } from 'api/clone';

function ImportCV(props) {
    const fileInputRef = useRef(null);
    const [isWaiting, setIsWaiting] = useState(false);

    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        if (file && file.type === 'application/pdf') {
            setIsWaiting(true);
            const fileReader = new FileReader();
            fileReader.onload = async (e) => {
                const typedArray = new Uint8Array(e.target.result);
                const pdf = await pdfjs.getDocument(typedArray).promise;
                let extractedText = '';

                for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
                    const page = await pdf.getPage(pageNumber);
                    const textContent = await page.getTextContent();
                    const pageText = textContent.items.map(item => item.str).join(' ');
                    extractedText += pageText + '\n';
                }

                //if extractedText is empty, it means the PDF is empty

                if (extractedText === '') {
                    toast('Nous n\'avons pas pu extraire le texte de ce fichier PDF', {
                        type: 'error',
                    });
                    setIsWaiting(false);
                    return;
                }

                await uploadCV({ text: extractedText })
                    .then((result) => {
                        if (result.status === 200) {
                            toast('Votre CV a été importé avec succès', {
                                type: 'success',
                            });
                            setIsWaiting(false);
                            props.refresh();
                        } else {
                            toast('Une erreur est survenue lors de l\'importation de votre CV', {
                                type: 'error',
                            });
                            setIsWaiting(false);
                        }
                    })
                    .catch((err) => {
                        toast('Une erreur est survenue lors de l\'importation de votre CV', {
                            type: 'error',
                        });
                        setIsWaiting(false);
                    });
                
            };
            fileReader.readAsArrayBuffer(file);
        } else {
            toast('Veuillez sélectionner un fichier PDF', {
                type: 'error',
            });
        }
    };

    return (
        <>
            <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileUpload}
                accept="application/pdf"
                style={{ display: 'none' }}
            />
            <div className="w-full flex py-2 justify-center content-center">
                <button
                    className={`w-80 border-y-2 border-x-8 border-lightBlueSkills bg-purpleSkills hover:bg-blueMain button font-bold text-white text-xl py-3 rounded-full`}
                    onClick={() => {
                        fileInputRef.current.click();
                    }}
                    disabled={isWaiting}
                >
                    <input type="file" style={{display:'none'}} onChange={handleFileUpload} accept="application/pdf" />
                    <div className='flex gap-2 justify-center content-center'>
                        <DocumentAddIcon className='w-6 h-6 self-center' />
                        <span>Importer mon CV</span>
                    </div>
                </button>
            </div>
            { isWaiting &&
                <div className="flex justify-center mt-4">
                    <ClipLoader color={'#3f3d56'} loading={true} size={25} />
                </div>
            }
        </>
    );
}

export default ImportCV;