//CONFIG
import config from 'utils/constants';

//LIBS
import { Link } from 'react-router-dom';

//COMPONENTS
import Image from 'components/common/Image';
import PingConnected from 'components/PingConnected';
import PointGamification from 'containers/profils/PointGamification';

const sizes = {
    avatar: '?w=500&h=500&c=true&q=80',
};

function AvatarClone(props) {

    return (
        <>
            <div className='relative'>
                {props.user?.profile?.avatar ? (
                    <div className={`relative h-auto p-0 m-0 ${props.className}`}>
                        <Link
                            className="relative flex"
                            onClick={props.onClick ?? null}
                            to={!props.onClick ? '/profil/' + props.user?.profile?.slug : false}
                            title={`${props.user?.profile?.pseudo}`}
                        >
                            <Image
                                src={props.user?.profile?.avatar?.url + sizes.avatar}
                                alt={props.user?.profile?.pseudo}
                                loading="false"
                                dynamic
                                className={`h-auto md:h-auto ${props.roundImg !== false && 'rounded-full'} ${props.className}`}
                            />                     
                        </Link>
                    </div>
                ) : (
                    <div className={`relative h-auto p-0 m-0 ${props.className}`}>
                        {props.user?.profile?.slug ? (
                            <Link className="relative inline-block" onClick={props.onClick ?? null} to={!props.onClick ? '/profil/' + props.user?.profile?.slug : false}>
                                <img
                                    className={`rounded-full ${props.className}`}
                                    src={`${config.publicDir}skillsLogo.jpg` + sizes.avatar}
                                    alt={props.user?.profile?.pseudo}
                                />
                                {props.gamification !== false && (!props.totalGamification || props.totalGamification === undefined ? (
                                    <PointGamification gamification={props.user?.gamification?.profile} />
                                ) : (
                                    <PointGamification gamification={props.totalGamification} />
                                ))}
                                {props.ping !== false && <PingConnected connexions={props.user?.connexions} type="global" />}
                            </Link>
                        ) : (
                            <img
                                className={`rounded-full ${props.className}`}
                                onClick={props.onClick ?? null}
                                src={`${config.publicDir}skillsLogo.jpg` + sizes.avatar}
                                alt={props.user?.profile?.pseudo}
                            />
                        )}
                    </div>
                )}
                <div className='absolute top-0 left-0 h-full w-full rounded-full' style={{
                        backgroundColor: 'rgba(227, 72, 255, 0.25)',
                    }}>
                </div>
            </div>
        </>
    );
}

export default AvatarClone;