import { useContext, useEffect } from 'react';

//LIBS
import { createPortal } from 'react-dom';
import { onAuthStateChanged, signOut } from 'firebase/auth';

//API
import useRequest from '../services/useRequest';

//CONTEXTS
import { HeadlessContext } from '../index.js';

//COMPONENTS
import HeadlessLogin from './HeadlessLogin';
import HeadlessMini from './HeadlessMini';
import HeadlessHome from './HeadlessHome';
import HeadlessNotifications, { HeadlessNotificationsContext, HeadlessNotificationsProvider } from './HeadlessNotifications';

/**
 * HeadlessApp - Main.
 * @param {string} props.config - App Config
 */
export default function HeadlessApp(props) {
    const store = useContext(HeadlessContext);
    const notif = useContext(HeadlessNotificationsContext);

    const request = useRequest();

    useEffect(() => {
        if (props.config) store.set({ type: 'config', value: props.config });
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        switch (store.get('type')) {
            case 'logged':
                break;
            case 'logout':
                signOut(store.get('firebase').auth)
                    .then(() => {
                        notif?.set({ action: 'add', type: 'success', message: 'Vous êtes déconnecté(e)' });
                    })
                    .catch(console.error);
                break;
            default:
        }
        //eslint-disable-next-line
    }, [store]);

    useEffect(() => {
        const firebase = store.get('firebase');
        if (firebase) {
            onAuthStateChanged(firebase.auth, (user) => {
                if (user) {
                    localStorage.setItem('hlToken', user.accessToken);

                    request.get({ route: 'headless/auth/me' }).then((claims) => {
                        store.set({ type: 'setMe', value: { ...user, role: claims.role } });
                    });
                } else {
                    localStorage.removeItem('hlToken');
                }
            });
        }
        //eslint-disable-next-line
    }, [store.get('firebase')]);
    return createPortal(
        <>
            <HeadlessNotificationsProvider>
                {store.get('logged') && store.get('opened') && <HeadlessHome />}
                {!store.get('opened') && store.get('logged') && <HeadlessMini onClick={() => store.set({ type: 'open' })} />}
                {store.get('opened') && !store.get('logged') && <HeadlessLogin />}
                <HeadlessNotifications />
            </HeadlessNotificationsProvider>
        </>,
        document.body
    );
}
