import { useState } from 'react';

//ICONS
import { MdContentCopy } from 'react-icons/md';

export default function HeadlessCodeBlock(props) {
    const { code } = props;

    const [copyText, setCopyText] = useState('Copier le code');

    const copyToClipBoard = async (copyMe) => {
        try {
            await navigator.clipboard.writeText(copyMe);
            setCopyText('Copié');
        } catch (err) {
            setCopyText('Erreur');
        }
    };
    return (
        <div className="relative">
            <pre className="text-sm p-2 bg-[#3A3A3A] text-[#FFF] rounded-sm w-full block my-2">
                {code.split('\n').map((line, index) => (
                    <div key={index}>{line}</div>
                ))}
            </pre>
            <button className="absolute top-2 right-2 text-[#FFF] flex gap-1" onClick={() => copyToClipBoard(code)}>
                <MdContentCopy className="text-sm" />
                <span className="text-xs">{copyText}</span>
            </button>
        </div>
    );
}
