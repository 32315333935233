import { useContext, useEffect, useState } from 'react';

//LIBS
import { Link } from 'react-router-dom';

//API
import { postCounter } from 'api/counters';

//CONTEXTS
import { AuthContext } from 'context/authContext';
import { StoreContext } from 'context/storeContext';

//HOOKS
import useHasCounter, { useGetListCounter } from 'hooks/useHasCounter';

//COMPONENTS
import Modal from 'components/common/Modal';
import Moment from 'components/common/Moment';

//CONTAINERS
import CardProfilSmall from 'containers/profils/CardProfilSmall';
import CountersRoom from 'containers/rooms/CountersRoom';

//ICONS
import { BriefcaseIcon, LocationMarkerIcon, CurrencyEuroIcon, CalendarIcon, EyeIcon, ChatIcon } from '@heroicons/react/solid';

//UTILS
//import { isMobile } from 'utils/functions';

/*
const jobPrice = {
    '10k-20k': 'Entre 10k et 20k',
    '20k-30k': 'Entre 20k et 30k',
    '30k-40k': 'Entre 30k et 40k',
    '40k-50k': 'Entre 40k et 50k',
    '>50k': 'Supérieur à 50k',
};*/

const jobTypeContrat = {
    full: 'Temps plein',
    partial: 'Temps partiel',
    contrat: 'Contrat',
    tmp: 'Travail temporaire',
    benevolat: 'Bénévolat',
    stage: 'Stage / Alternance',
};
const jobTypePlace = {
    presential: 'Présentiel',
    remote: 'Télétravail',
    presential_remote: 'Présentiel + Télétravail',
};

function JobRoom(props) {
    const [authState] = useContext(AuthContext);
    const store = useContext(StoreContext);

    const [isAdmin, setIsAdmin] = useState(false);
    const [modalList, setModalList] = useState(false);

    const isJobApply = useHasCounter(props.room.data.counters, 'job_apply', props.room.data._id);
    const listUsersJobApply = useGetListCounter(props.room.data.counters, 'job_apply', props.room.data._id);

    function jobApply() {
        postCounter({ type: 'job_apply', to: props.room.data._id }).then((res) => {
            props.room.refetch();
        });
    }

    function contactUser(userId) {
        store.set({ type: 'add', key: 'openChat', value: { open: true, userId: userId } });
    }

    useEffect(() => {
        setIsAdmin(authState.isLogged && authState?.me?.id === props.room.data?.user?._id ? true : false);
        //eslint-disable-next-line
    }, [authState.me]);

    return (
        <>
            <Modal
                title={`Liste des Skillers ayant postulé`}
                open={modalList}
                onClose={{
                    label: 'Fermer',
                    onClick: () => {
                        setModalList(false);
                    },
                }}
            >
                <>
                    {listUsersJobApply.map((user, k) => (
                        <div key={k} className="flex flex-col justify-between border-b border-greySkills py-2">
                            <span className="text-xs text-mainText">
                                <Moment fromNow date={user.counters_user?.[0].updatedAt} />
                            </span>
                            <div className="flex flex-row gap-2 justify-between items-center">
                                <CardProfilSmall user={user} />
                                <div className="flex gap-2 h-8">
                                    <Link to={`/profil/${user?.profile?.slug}`} className="linkCard justify-center w-8 h-8 p-0">
                                        <EyeIcon className="w-4 h-4" />
                                    </Link>
                                    <button className="linkCard justify-center w-8 h-8 p-0" onClick={() => contactUser(user.id)}>
                                        <ChatIcon className="w-4 h-4" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                </>
            </Modal>

            <div className="flex flex-col justify-start flex-wrap gap-2 text-graySkills text-sm lg:text-base mt-2">
                {props.room?.data?.jobTypeContrat && (
                    <p className={`flex flex-row gap-1 items-center md:px-4 md:py-2 sm:justify-start`}>
                        <BriefcaseIcon className="w-5 h-5 md:w-6 md:h-6" />
                        {jobTypeContrat[props.room?.data?.jobTypeContrat]}
                    </p>
                )}
                {props.room?.data?.address && (
                    <p className={`flex flex-row gap-1 items-center md:px-4 md:py-2 sm:justify-start`}>
                        <LocationMarkerIcon className="w-5 h-5 md:w-6 md:h-6" />
                        {props.room?.data?.address}
                    </p>
                )}
                {props.room?.data?.jobTypePlace && (
                    <p className={`flex flex-row gap-1 items-center md:px-4 md:py-2 sm:justify-start`}>
                        <CalendarIcon className="w-5 h-5 md:w-5 md:h-5 xl:w-6 xl:h-6" />
                        {jobTypePlace[props.room?.data?.jobTypePlace]}
                    </p>
                )}
                {props.room?.data?.jobPrice && (
                    <p className={`flex flex-row gap-1 items-center md:px-4 md:py-2 sm:justify-start`}>
                        <CurrencyEuroIcon className="w-5 h-5 md:w-6 md:h-6" />
                        {props.room?.data?.jobPrice}
                    </p>
                )}
            </div>

            {authState.isLogged && (
                <>
                    <div className="flex flex-row gap-2">
                        {!isAdmin && (
                            <button className={`linkCardApplyJob purple mt-4 ${isJobApply ? 'active' : ''}`} onClick={jobApply}>
                                {!isJobApply ? <>Postuler à cette offre</> : <>J'ai postulé</>}
                            </button>
                        )}
                        {isAdmin && listUsersJobApply?.length > 0 && (
                            <div>
                                <button className={`linkCardApplyJob purple mb-4`} onClick={() => setModalList(true)}>
                                    Consulter la liste des réponses à votre offre ( {listUsersJobApply?.length} )
                                </button>
                            </div>
                        )}
                    </div>
                </>
            )}

            <div className="content break-words my-2 text-base md:text-lg text-gray-800 py-2">
                <div dangerouslySetInnerHTML={{ __html: props.room?.data?.description }} style={{ whiteSpace: 'pre-wrap' }}></div>
                <div className="mt-4">
                    <CountersRoom room={props.room} refetch={props.refetch} reactions={props.reactions} />
                </div>
            </div>
        </>
    );
}

export default JobRoom;
