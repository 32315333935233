import { useState, useRef, useEffect, useContext, useCallback } from "react";
//CONTEXTS
import { FormContext } from "context/formContext";
import Modal from "components/common/Modal";
//COMPONENTS
import FormAddProduct from "components/forms/addProduct";

function AddProduct() {
    const [product, setProduct] = useState(false);
    //CONTEXTS
    const [formContextState] = useContext(FormContext);
    //REFS
    const formProductRef = useRef(null);

    //DISPATCH
    const dispatchAction = useCallback(() => {
        switch (formContextState.action) {
            case "add":
                setProduct(true);
                break;
            default:
        }
        // eslint-disable-next-line
    }, [formContextState]);

    useEffect(() => {
        dispatchAction();
        //eslint-disable-next-line
    }, [dispatchAction]);

    function handleSubmitProduct() {
        formProductRef.current.handleSubmit();
    }

    return (
        <Modal
            title="Ajouter un nouveau produit"
            open={product}
            onClose={{
                onClick: () => {
                    setProduct(false);
                },
            }}
            onConfirm={{
                label: "Ajouter",
                onClick: () => {
                    handleSubmitProduct();
                },
            }}
        >
            <FormAddProduct ref={formProductRef} />
        </Modal>
    );
}

export default AddProduct;
