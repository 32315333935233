import { useEffect, useState } from 'react';

import _ from 'lodash';
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
    Filler,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';

import useRequest from '../../services/useRequest';

ChartJS.register(LinearScale, CategoryScale, BarElement, PointElement, LineElement, Legend, Tooltip, LineController, BarController, Filler);

const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: '',
        },
    },
};

export default function ChartMain(props) {
    const request = useRequest();

    const [datas, setDatas] = useState([]);

    useEffect(() => {
        request.post(props.request).then((stats) => {
            _.forEach(stats, (data) => {
                setDatas((p) => [
                    ...p,
                    {
                        label: data.title,
                        type: data.type,
                        data: data.stats,
                        borderColor: data.color,
                        backgroundColor: data.color,
                        borderWidth: 2,
                        stepped: data.stepped,
                        tension: data.tension,
                        fill: data.fill,
                        borderRadius: data.borderRadius,
                    },
                ]);
            });
        });
        //eslint-disable-next-line
    }, []);

    return <Chart type="line" className="mb-4" options={options} data={{ datasets: datas }} />;
}
