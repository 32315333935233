import { useState, useRef, useEffect, useContext, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
//COMPONENTS
import Modal from 'components/common/Modal';
import FormProfilStep from '../forms/formProfilSteps/formProfilStep';
import FormProfilStepNext from '../forms/formProfilSteps/formProfilStepNext';
import FormProfilStepUniverse from '../forms/formProfilSteps/formProfilStepUniverse';
import FormProfileStepXP from '../forms/formProfilSteps/formProfilStepXP';
import FormProfilStepFinal from '../forms/formProfilSteps/formProfilStepFinal';

//CONTEXTS
import { AuthContext } from 'context/authContext';
import { SocketContext } from 'context/socketContext';
//ICONS
//import { XCircleIcon } from '@heroicons/react/outline';

//API
import { putProfileNeverUpdate } from 'api/users';
import { useNavigate } from 'react-router-dom';
import { isMobile } from '../../utils/functions';

export default function StepsFormProfil() {
    //CONTEXTS
    const [authState] = useContext(AuthContext);
    const socket = useContext(SocketContext);
    //REFS
    const timeoutRef = useRef(null);
    //HOOKS
    const navigate = useNavigate();
    const location = useLocation();

    const [page, setPage] = useState(0);
    const [openSteps, setOpenSteps] = useState(false);
    const [typeSteps, setTypeSteps] = useState('standard');

    /*  function handleProfilSteps() {
        setOpenSteps(true);
    }
 */
    const handleGoToProfile = useCallback(async () => {
        setOpenSteps(false);
        await putProfileNeverUpdate().catch((error) => {
            console.error('Failed to update profile:', error);
        });
        const path = authState?.me ? `/profil/${authState.me.profile.slug}` : '/';
        navigate(path, { state: { shareModal: true } });
    }, [authState?.me, navigate]);

    const handleGoToCloneAi = useCallback(async () => {
        setOpenSteps(false);
        await putProfileNeverUpdate().catch((error) => {
            console.error('Failed to update profile:', error);
        });
        const path = authState.me && isMobile() ? `/dashboard/clone-ai` : '/';
        navigate(path);
    }, [authState.me, navigate]);

    const handleCloneCreate = useCallback(() => {
        if (authState.isLogged) {
            console.log('handleCloneCreate');
            socket.send('startCloneCreation', { userId: authState.me?.id });
        }
    }, [authState.isLogged, authState.me?.id, socket]);

    const conditionalComponent = () => {
        if (typeSteps === 'ai') {
            switch (page) {
                case 0:
                    return (
                        <FormProfilStep
                            typeSteps={typeSteps}
                            page={page}
                            setPage={setPage}
                            handleGoToProfile={handleGoToProfile}
                            handleCloneCreate={handleCloneCreate}
                        />
                    );
                case 1:
                    return (
                        <>
                            {authState.me?.profile?.display === 'society' ? (
                                <FormProfilStepUniverse
                                    typeSteps={typeSteps}
                                    page={page}
                                    setPage={setPage}
                                    handleGoToCloneAi={handleGoToCloneAi}
                                    handleGoToProfile={handleGoToProfile}
                                    handleCloneCreate={handleCloneCreate}
                                />
                            ) : (
                                <FormProfileStepXP
                                    typeSteps={typeSteps}
                                    page={page}
                                    setPage={setPage}
                                    handleGoToProfile={handleGoToProfile}
                                    handleCloneCreate={handleCloneCreate}
                                />
                            )}
                        </>
                    );
                case 2:
                    if (authState.me?.profile?.display === 'realnames') {
                        return (
                            <FormProfilStepUniverse
                                typeSteps={typeSteps}
                                page={page}
                                setPage={setPage}
                                handleGoToCloneAi={handleGoToCloneAi}
                                handleGoToProfile={handleGoToProfile}
                                handleCloneCreate={handleCloneCreate}
                            />
                        );
                    }
                    break;
                default:
                    return (
                        <FormProfilStep
                            typeSteps={typeSteps}
                            page={page}
                            setPage={setPage}
                            handleGoToProfile={handleGoToProfile}
                            handleCloneCreate={handleCloneCreate}
                        />
                    );
            }
        } else {
            switch (page) {
                case 0:
                    return (
                        <FormProfilStep
                            typeSteps={typeSteps}
                            page={page}
                            setPage={setPage}
                            handleGoToProfile={handleGoToProfile}
                            handleCloneCreate={handleCloneCreate}
                        />
                    );
                case 1:
                    return <FormProfilStepNext page={page} setPage={setPage} handleGoToProfile={handleGoToProfile} />;
                case 2:
                    return (
                        <>
                            {authState.me?.profile?.display === 'society' ? (
                                <FormProfilStepUniverse
                                    typeSteps={typeSteps}
                                    page={page}
                                    setPage={setPage}
                                    handleGoToProfile={handleGoToProfile}
                                    handleCloneCreate={handleCloneCreate}
                                />
                            ) : (
                                <FormProfileStepXP
                                    typeSteps={typeSteps}
                                    page={page}
                                    setPage={setPage}
                                    handleGoToProfile={handleGoToProfile}
                                    handleCloneCreate={handleCloneCreate}
                                />
                            )}
                        </>
                    );
                case 3:
                    if (authState.me?.profile?.display === 'society') {
                        return (
                            <FormProfilStepFinal
                                typeSteps={typeSteps}
                                page={page}
                                setPage={setPage}
                                handleGoToProfile={handleGoToProfile}
                                handleCloneCreate={handleCloneCreate}
                            />
                        );
                    } else {
                        return (
                            <FormProfilStepUniverse
                                typeSteps={typeSteps}
                                page={page}
                                setPage={setPage}
                                handleGoToProfile={handleGoToProfile}
                                handleCloneCreate={handleCloneCreate}
                            />
                        );
                    }
                case 4:
                    if (authState.me?.profile?.display === 'realnames') {
                        return (
                            <FormProfilStepFinal
                                typeSteps={typeSteps}
                                page={page}
                                setPage={setPage}
                                handleGoToProfile={handleGoToProfile}
                                handleCloneCreate={handleCloneCreate}
                            />
                        );
                    }
                    break;
                default:
                    return (
                        <FormProfilStep
                            typeSteps={typeSteps}
                            page={page}
                            setPage={setPage}
                            handleGoToProfile={handleGoToProfile}
                            handleCloneCreate={handleCloneCreate}
                        />
                    );
            }
        }
    };

    useEffect(() => {
        if (authState.isLogged && authState.me) {
            if (authState.me?.neverUpdate) {
                timeoutRef.current = setTimeout(() => setOpenSteps(true), 500);
            }
        }
        return () => clearTimeout(timeoutRef.current);
    }, [authState.isLogged, authState.me]);

    /*  useEffect(() => {
        console.log('ok');
        setTypeSteps(location.pathname === '/inscription-clone-ai' ? 'ai' : 'standard');
        if (location.pathname === '/inscription-clone-ai' && authState.isLogged && !authState.me?.neverUpdate) {
            setOpenSteps(true);
        }
    }, [location.pathname, authState.isLogged, authState.me]); */

    useEffect(() => {
        //console.log('Effect running:', { path: location.pathname, isLogged: authState.isLogged, neverUpdate: authState.me?.neverUpdate });

        const isAiInscriptionPath = location.pathname === '/inscription-clone-ai';
        setTypeSteps(isAiInscriptionPath ? 'ai' : 'standard');

        if (isAiInscriptionPath && authState.isLogged && authState.me) {
            if (authState.me?.clone !== true && !authState.me?.neverUpdate) {
                //console.log(authState.me?.clone);
                //console.log('User has updated their profile, and aiInscriptionPath, waiting before potentially setting open steps...');
                timeoutRef.current = setTimeout(() => setOpenSteps(true), 500);
            }
        }

        return () => clearTimeout(timeoutRef.current);
    }, [location.pathname, authState.isLogged, authState.me]);

    return (
        <>
            <Modal
                overflow={true}
                typeModal={typeSteps === 'standard' ? '' : 'ai'}
                aiBg={typeSteps === 'standard' ? false : true}
                modalWidth={'container relative mx-auto w-full sm:w-2/3 lg:w-2/3 xl:w-2/5'}
                open={openSteps}
                onClose={{
                    onClick: handleGoToProfile,
                }}
                isRegisterForm={true}
                onCloseOverlay={false}
            >
                <div className="px-4 sm:px-8 sm:py-4">{conditionalComponent()}</div>
            </Modal>
        </>
    );
}
