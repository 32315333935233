//STYLES
import 'App.css';
import '../containers/searchBar/ForumSearchBar.css';

//LAYOUT
import FullLayout from 'pages/layouts/Landing';
//CONFIG
import config from 'utils/constants';
//ICONS
import { SearchCircleIcon } from '@heroicons/react/solid';
//COMPONENTS
import ForumSearchBar from 'containers/searchBar/ForumSearchBar';
//LIBS
import ReactPlayer from 'react-player';

function Profil(props) {
    const urlSkillsmarketVideo = 'https://www.youtube.com/watch?v=qWCmRAaBYu0';

    return (
        <>
            <FullLayout>
                <main>
                    <div className="pb-24">
                        <div className="container-fluid pt-32 bg-blueMain shadow-xl rounded-b-2xl">
                            <div className="container mx-auto flex flex-col md:px-24 px-4">
                                <div className="mb-5 md:mb-16 flex flex-col lg:flex-row items-center">
                                    <div className="pb-5 lg:pb-0">
                                        <p className="invisible h-0 lg:visible lg:h-auto text-white text-xl sm:text-3xl md:text-4xl xl:text-5xl 2xl:text-7xl" style={{ fontFamily: 'Nunito' }}>
                                            Le moteur<br></br>de réponses<br></br>professionnelles
                                        </p>
                                        <p className="visible lg:invisible lg:h-0 px-14 text-center text-white text-xl sm:text-3xl md:text-4xl xl:text-5xl 2xl:text-7xl" style={{ fontFamily: 'Nunito' }}>
                                            Le moteur de réponses professionnelles
                                        </p>
                                    </div>
                                    <div className="w-auto">
                                        <div className="h-52 md:h-72 lg:h-72 w-auto aspect-video relative">
                                            <ReactPlayer url={urlSkillsmarketVideo} width="100%" height="100%" origin={window.location.origin} className="rounded-lg overflow-hidden aspect-video" pip controls />
                                        </div>
                                    </div>
                                </div>
                                <div className="-mb-7">
                                    <form className="forumSearchBar" autoComplete="off">
                                        <div className="w-full lg:w-9/12 mx-auto h-full relative">
                                            <div className="flex justify-items-center">
                                                <div className="absolute top-1/2 transform -translate-y-1/2 right-1">
                                                    <SearchCircleIcon className={`h-12 w-12 transform -rotate-90 text-blueMain`} />
                                                </div>
                                                <div className="w-full">
                                                    <input placeholder="Comment gérer son stress ?" name="forum" type="text" disabled={true} id="input_landingpage" min="0" className="input md:p-7 w-auto px-6 shadow-xl"></input>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="-mx-4">
                        <div className=" w-full h-full pt-20 md:pt-24 lg:pt-20">
                            <div className="text-center pb-4">
                                <h1 className="py-2 text-xl sm:text-3xl md:text-4xl xl:text-5xl 2xl:text-6xl" style={{ fontFamily: 'Nunito', color: '#46519f' }}>
                                    Obtenez des réponses personnalisées<br></br>rapidement par des<br></br> professionnels
                                </h1>
                                <p className="text-gray-400 text-sm md:text-lg">Le moteur de réponses professionnelles</p>
                            </div>
                            <div className="relative w-full md:h-xl 2xl:h-2xl bg-no-repeat bg-right bg-contain md:bg-50% 2xl:bg-auto" style={{ backgroundImage: `url(${config.publicDir}landingpage/bg_3.png)` }}>
                                <div className="flex flex-col-reverse lg:flex-row px-5 sm:px-10 md:pl-20 lg:pl-24 xl:pl-40 2xl:pl-56 2xl:pr-20">
                                    <div className="lg:w-1/2 lg:pr-64">
                                        <h1 className="pb-6 lg:pt-24 text-xl sm:text-3xl md:text-4xl xl:text-5xl 2xl:text-6xl" style={{ fontFamily: 'Nunito', color: '#46519f' }}>
                                            Faites vos recherches
                                        </h1>
                                        <p className="text-gray-400 text-sm md:text-lg">Comme sur n’importe quel autre moteur de recherche, tapez votre question, quel que soit le sujet ...</p>
                                    </div>
                                    <div className="flex lg:w-1/2">
                                        <div className="">
                                            <img src={`${config.publicDir}landingpage/mask_group.png`} alt="" className="md:pt-16" />
                                        </div>
                                        <div className="pr-46">
                                            <img src={`${config.publicDir}landingpage/mask_group_1.png`} alt="" />
                                        </div>
                                    </div>
                                </div>
                                {/*  <div className="absolute 2xl:-top-24">
                                    <img src={`${config.publicDir}landingpage/group_189.svg`} alt="" className="invisible lg:visible" />
                                </div> */}
                            </div>
                        </div>
                    </section>

                    <section className="">
                        <div className="w-full pt-36 sm:pr-10 md:pr-20 lg:pr-24 xl:pr-40 2xl:pr-56">
                            <div className="text-right pr-5 pb-10">
                                <h1 className="text-xl sm:text-3xl md:text-4xl xl:text-5xl 2xl:text-6xl" style={{ fontFamily: 'Nunito', color: '#46519f' }}>
                                    Des Skillers experts<br></br>dans de nombreux domaines<br></br>prêts à vous aider
                                </h1>
                            </div>
                            <div className="pr-14 md:pr-32">
                                <img src={`${config.publicDir}landingpage/group_174.png`} alt="" />
                            </div>
                        </div>
                    </section>

                    <section className="relative">
                        <div className="container-md h-35rem sm:h-60rem md:h-90rem lg:h-50rem xl:h-60rem 2xl:h-70rem">
                            <div className="w-full flex flex-col-reverse lg:flex-row">
                                <div className="w-full relative">
                                    <img className="invisible lg:visible absolute" src={`${config.publicDir}landingpage/group_190.svg`} alt="" />
                                    <img className="visible lg:invisible absolute w-full" src={`${config.publicDir}landingpage/group_mobile_190.svg`} alt="" />
                                </div>
                                <div className="h-full w-full lg:pt-20">
                                    <div
                                        className="bg-no-repeat bg-right bg-contain lg:h-50rem xl:h-60rem 2xl:h-70rem"
                                        style={{
                                            backgroundImage: `url(${config.publicDir}landingpage/bg_5.svg)`,
                                        }}
                                    >
                                        <div className="relative h-full text-center lg:text-left lg:w-10/12 lg:pt-8 lg:pl-56 xl:w-10/12 xl:pt-14 xl:pl-80 2xl:w-9/12 2xl:pt-40 2xl:pl-80">
                                            <div className="absolute lg:static px-16 pt-20 sm:px-40 sm:pt-44 md:px-48 md:pt-52 lg:px-0">
                                                <h1 className="sm:pb-2 text-xl sm:text-3xl md:text-4xl xl:text-5xl 2xl:text-6xl" style={{ fontFamily: 'Nunito', color: '#46519f' }}>
                                                    Une room se crée rien que pour vous
                                                </h1>
                                                <p className="text-gray-400 text-sm md:text-lg 2xl:pr-8">Échangez autour du sujet qui vous tient à coeur !</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="relative">
                        <div className="pt-48 pl-5 sm:pl-10 md:pl-20 lg:pl-24 xl:pl-40 2xl:pl-56">
                            <div className="w-full flex flex-col lg:flex-row sm:justify-end">
                                <div className="break-words flex flex-col text-left lg:w-4/12">
                                    <h1 className="pb-5 md:pt-5 lg:pt-7 text-xl sm:text-3xl md:text-4xl xl:text-5xl 2xl:text-6xl" style={{ fontFamily: 'Nunito', color: '#46519f' }}>
                                        Des réponses<br></br>professionnelles<br></br>...
                                    </h1>
                                    <p className="text-gray-400 text-sm md:text-lg">De vraies personnes (skillers) compétentes et réactives s’occupent de répondre à votre question de façon personnalisée.</p>
                                </div>
                                <div className="flex flex-col lg:w-9/12">
                                    <div>
                                        <img className="h-full w-full" src={`${config.publicDir}landingpage/group_177.png`} alt="" />
                                    </div>
                                    <div>
                                        <h1 className="text-center pb-7 text-xl sm:text-3xl md:text-4xl xl:text-5xl 2xl:text-6xl" style={{ fontFamily: 'Nunito', color: '#46519f' }}>
                                            ...par des professionnels
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="relative">
                        <div
                            className="bg-no-repeat bg-left bg-contain"
                            style={{
                                backgroundImage: `url(${config.publicDir}landingpage/bg_4.png)`,
                            }}
                        >
                            <div className="w-full flex flex-col-reverse lg:flex-row pt-36 pl-5 sm:px-10 md:px-20 lg:px-24 xl:px-40 2xl:px-56">
                                <div className="md:w-12/12 lg:w-11/12 xl:w-10/12 2xl:w-7/12">
                                    <img className="h-full w-full" src={`${config.publicDir}landingpage/group_166.png`} alt="" />
                                </div>
                                <div className="w-full flex flex-col text-center lg:text-left sm:pt-10 md:pt-28 lg:pl-20 xl:pl-10 2xl:pl-24 lg:w-3/6 xl:w-5/12 2xl:w-2/6">
                                    <h1 className="pb-4 text-xl sm:text-3xl md:text-4xl xl:text-5xl 2xl:text-6xl" style={{ fontFamily: 'Nunito', color: '#46519f' }}>
                                        Des réponses rapides et<br></br>évaluées
                                    </h1>
                                    <p className="text-gray-400 text-sm md:text-lg">
                                        Des résultats à forte valeur ajoutée<br></br>constamment développés par les skillers !
                                    </p>
                                </div>
                            </div>
                            <div className="mx-4 pt-36">
                                <div>
                                    <h1 className="text-center pb-4 md:pb-20 text-xl sm:text-3xl md:text-4xl xl:text-5xl 2xl:text-6xl" style={{ fontFamily: 'Nunito', color: '#46519f' }}>
                                        Envie d'essayer ?
                                    </h1>
                                </div>
                                <ForumSearchBar />
                            </div>
                        </div>
                    </section>
                </main>
            </FullLayout>
        </>
    );
}

export default Profil;
