import { ArrowNarrowLeftIcon } from '@heroicons/react/solid';
import { useState } from 'react';

//CSS
import './treeView.css';

function TreeView(props) {
    const [indexParent, setIndexParent] = useState(null);

    const [showChildren, setShowChildren] = useState(false);
    const [showParent, setShowParent] = useState(true);
    const [lastClick, setLastClick] = useState(false);

    const handleMainClick = (e, key, id) => {
        const indexParent = props.treeData.map((object) => object.id).indexOf(id);

        if (indexParent !== -1) {
            setIndexParent(indexParent);

            if (props.treeData[key].hasOwnProperty('children')) {
                if (props.type === 'report') {
                    props.onClickMotif(props.treeData[key].label, null);
                }
                setShowChildren(true);
            } else {
                if (props.type === 'report') {
                    props.onClickMotif(props.treeData[key].label, null, true);
                    setShowParent(false);
                }
                setLastClick(true);
            }
        }
    };

    return (
        <>
            {props.type === 'report' && (
                <>
                    {showParent && !lastClick && (
                        <>
                            {props.typeReport === 'user' && (
                                <div className="mx-8">
                                    <p className="py-2 mb-6 text-semibold text-xl">
                                        Pourquoi signalez vous ce profil ?
                                    </p>
                                </div>
                            )}
                            {props.typeReport === 'room' && (
                                <div className="mx-8">
                                    <p className="py-2 mb-6 text-semibold text-xl">
                                        Pourquoi signalez vous cette room ?
                                    </p>
                                </div>
                            )}
                            {props.typeReport === 'answer' && (
                                <div className="mx-8">
                                    <p className="py-2 mb-6 text-semibold text-xl">
                                        Pourquoi signalez vous cette réponse ?
                                    </p>
                                </div>
                            )}
                        </>
                    )}

                    {lastClick && (
                        <>
                            {props.sendReport === true ? (
                                <>
                                    <div className="mx-8">
                                        <p className="py-2 mb-6 text-semibold text-xl">
                                            Signalement envoyé !
                                        </p>
                                        <p className="py-2 mb-6 text-semibold text-xl">
                                            Merci de nous en avoir informés. Nous traiterons votre
                                            signalement dans les 24 heures.
                                        </p>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="mx-8">
                                        <p className="py-2 mb-6 text-semibold text-xl">
                                            Une erreur est survenue.
                                        </p>
                                        <p className="py-2 mb-6 text-semibold text-xl">
                                            Veuillez reformuler votre signalement ou nous contacter
                                            si l'erreur persiste toujours, à l'adresse suivante :
                                            contact@skillsmarket.fr
                                        </p>
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </>
            )}
            {showChildren && !lastClick && (
                <>
                    <div className="mx-8 w-fit text-md font-bold my-4">
                        <button
                            onClick={() => {
                                setShowParent(true);
                                setShowChildren(false);
                            }}
                            className="flex items-center px-0"
                        >
                            <ArrowNarrowLeftIcon className="w-6 h-6 mr-2 relative" />
                            <span className="font-bold">revenir en arrière</span>
                        </button>
                    </div>
                </>
            )}
            <div className="tree-group">
                {showParent && !showChildren && (
                    <>
                        <ul>
                            {props.treeData.map((node, index) => (
                                <div
                                    id={node.id}
                                    key={index}
                                    onClick={(e) => {
                                        handleMainClick(e, node.key, node.id);
                                    }}
                                >
                                    <span key={index}>{node.label}</span>
                                </div>
                            ))}
                        </ul>
                    </>
                )}

                {showChildren && !lastClick && (
                    <>
                        <ul>
                            {props.treeData[indexParent].children.map((child, index) => (
                                <div
                                    id={child.id}
                                    key={index}
                                    onClick={(e) => {
                                        props.onClickMotif(
                                            null,
                                            child.label,
                                            props.type === 'report' && true
                                        );
                                        setLastClick(true);
                                        setShowParent(false);
                                        setShowChildren(false);
                                    }}
                                >
                                    <span key={index}>{child.label}</span>
                                </div>
                            ))}
                        </ul>
                    </>
                )}
            </div>
        </>
    );
}

export default TreeView;
