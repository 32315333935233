//API
import { getAllMatchingNeedsByMe } from 'api/matchingNeeds';

//HOOKS
import useInfinite from 'hooks/useInfinite';

//COMPONENTS
import CardNeedDashboard from 'containers/needs/CardNeedDashboard';

function ListNeeds() {
    //API
    const infiniteNeeds = useInfinite(getAllMatchingNeedsByMe, 'ListingDashboardNeeds', {
        onItem: (need, refetch) => {
            return <CardNeedDashboard need={need} refetch={refetch} />;
        },
        onNoResult: () => {
            return (
                <div className="text-center mt-8">
                    <p className="text-center font-extrabold text-xl sm:text-3xl text-graySkills">Vous n'avez pas encore de besoins</p>
                    <p className="mt-12 text-center font-extrabold text-sm sm:text-sm text-graySkills">Essayez !</p>
                    <p className="text-center font-extrabold text-sm sm:text-sm text-graySkills">
                        Postez vos besoins à notre IA et ils seront affichés directement auprès de ceux qui pourront vous aider.
                    </p>
                    <p className="text-center font-extrabold text-sm sm:text-sm text-graySkills">Exemple : je cherche un stage en développement web...</p>
                </div>
            );
        },
        msgToSee: 'Voir plus de besoins',
    });
    return <div className="cards mb-20">{infiniteNeeds}</div>;
}

export default ListNeeds;
