import { useCallback, useContext, useEffect, useState } from 'react';
import { useSpring, animated } from 'react-spring';
//COMPONENTS
import BuyCloneAi from 'components/common/BuyCloneAi';

//CONTEXTS
import { AuthContext } from 'context/authContext';
import { countByKey } from 'utils/functions';

//ICONS
import { StarIcon } from '@heroicons/react/solid';

//LIBS
// import _ from 'lodash';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Avatar from '../../containers/profils/Avatar';

function MainNavUser(props) {
    const [isPlanOpen, setIsPlanOpen] = useState(false);
    const [isAlreadyPremium, setIsAlreadyPremium] = useState(false);

    //CONTEXTS
    const [authState] = useContext(AuthContext);

    //HOOKS
    const navigate = useNavigate();

    const handleGoToProfile = useCallback(() => {
        navigate('/dashboard/profil');
    }, [navigate]);

    const openCloseSlideAnimationUserMenu = useSpring({
        transform: props.isOpen ? 'translateX(0%)' : 'translateX(-100%)',
        config: {
            duration: 225,
        },
    });

    useEffect(() => {
        if (authState?.me?.plans && authState?.me?.plans.length > 0) {
            if (authState?.me?.plans.some((plan) => plan.type === 'chatAi' && plan.status === 'succeeded')) {
                setIsAlreadyPremium(true);
            }
        }
        //eslint-disable-next-line
    }, [authState]);

    return (
        <>
            {isPlanOpen && <BuyCloneAi state={isPlanOpen} setState={setIsPlanOpen} />}
            {props.isOpen && (
                <nav className="relative mx-auto h-auto z-[9999]">
                    <div className="fixed w-screen h-screen bg-graySkills -z-10 opacity-60" onClick={props?.handleOpenUserMenu} />
                    <animated.div
                        style={openCloseSlideAnimationUserMenu}
                        className="fixed top-0 left-0 bg-white w-64 h-screen flex-col justify-end rounded-lg menu"
                    >
                        <ul className="w-auto p-5 text-left text-gray-500 font-bold text-lg">
                            <li className="ml-2 border-b mb-8 pb-4">
                                <div className="w-auto flex flex-col gap-1">
                                    <div
                                        onClick={props?.handleOpenUserMenu}
                                        className="flex flex-col gap-2" /* to={`/profil/${authState.me?.profile?.slug}`} */
                                    >
                                        <Avatar className={'w-20'} user={authState?.me} gamification={true} />
                                        <span className="text-lg">
                                            {authState?.me?.profile?.toSlug ? authState?.me?.profile?.toSlug : authState?.me?.profile?.pseudo}
                                        </span>
                                    </div>
                                    <Link onClick={props?.handleOpenUserMenu} to={`/skillers`}>
                                        <span className="text-sm">{countByKey(authState?.me?.counters, 'type', 'subscription')} Abonné(s)</span>
                                    </Link>
                                </div>
                            </li>
                            <li className="ml-2 mb-6">
                                <div>
                                    <button
                                        className="text-gold-500 inline-flex"
                                        onClick={() => {
                                            if (isAlreadyPremium) {
                                                navigate('/dashboard/params/account');
                                            } else {
                                                setIsPlanOpen(true);
                                                props?.handleOpenUserMenu();
                                            }
                                        }}
                                    >
                                        <StarIcon className="w-5 h-5 mx-auto self-center" />
                                        <span>Offre premium</span>
                                    </button>
                                </div>
                            </li>
                            <li
                                className="my-2 ml-2 text-gray-800"
                                onClick={() => {
                                    props?.handleOpenUserMenu();
                                }}
                            >
                                <Link to={`/profil/${authState.me?.profile?.slug}`}>Voir mon profil</Link>
                            </li>
                            <li
                                className="my-2 ml-2 text-gray-800"
                                onClick={() => {
                                    props?.handleOpenUserMenu();
                                }}
                            >
                                <button onClick={handleGoToProfile}>Modifier mon profil</button>
                            </li>
                            <li
                                className="my-2 ml-2 text-gray-800"
                                onClick={() => {
                                    props?.handleOpenUserMenu();
                                }}
                            >
                                <Link to="/dashboard/needs">Mes besoins</Link>
                            </li>

                            <li
                                className="my-2 ml-2 text-gray-800"
                                onClick={() => {
                                    props?.handleOpenUserMenu();
                                }}
                            >
                                <Link to="/dashboard/services">Ma Boutique</Link>
                            </li>
                            <li
                                className="my-2 ml-2 text-gray-800"
                                onClick={() => {
                                    props?.handleOpenUserMenu();
                                }}
                            >
                                <Link to="/dashboard/shoots">Mes shoots</Link>
                            </li>
                            <li
                                className="my-2 ml-2 text-gray-800"
                                onClick={() => {
                                    props?.handleOpenUserMenu();
                                }}
                            >
                                <Link to="/dashboard/visios/asked">Mes visios</Link>
                            </li>
                            <li
                                className="my-4 ml-2 border-t pt-8"
                                onClick={() => {
                                    props?.handleOpenUserMenu();
                                }}
                            >
                                <Link className="" to="/dashboard/params/account">
                                    Paramètres
                                </Link>
                            </li>
                        </ul>
                    </animated.div>
                </nav>
            )}
        </>
    );
}

export default MainNavUser;
