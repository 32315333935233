import { isFoundByKey } from 'utils/functions';

function PingConnected(props) {
    return (
        <>
            {isFoundByKey(props.connexions, 'type', props.type) && (
                <div className="absolute -bottom-1 -left-1 w-4 h-4 bg-white rounded-full flex justify-center items-center">
                    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-success-400 opacity-75"></span>
                    <span className="relative inline-flex rounded-full h-2 w-2 bg-success-500"></span>
                </div>
            )}
        </>
    );
}

export default PingConnected;
