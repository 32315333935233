import { useQuery } from "react-query";
import CardOptionsNotifications from "./CardOptionsNotifications";
import { getSocialNotifications } from "api/notifications";
function ListOptionsNotifications() {

    //fonction getUserSocialNotifications
    const notifications = useQuery('options', getSocialNotifications);
    //const valuesArray = JSON.parse(JSON.stringify(notifications.data))

    const { data, error, status } = notifications;

    return status === 'loading' ? (
        <p>Chargement...</p>
    ) : status === 'error' ? (
        <p>Error: {error.message}</p>
    ) : (
        <>
            {data.map((typeNotification, index) => (
                <CardOptionsNotifications
                    typeNotification={typeNotification}
                    refetch={notifications.refetch}
                    key={index}
                />
            ))}
        </>
    )

}

export default ListOptionsNotifications;