import API from './axios.config';

async function getSuggests(value) {
    const response = await API.get('search/suggest/' + value.replace(/[""]/g, '\\"'));
    return response;
}

async function getTagsSuggests(value) {
    const response = await API.get('search/tagsSuggest/' + value.replace(/[""]/g, '\\"'));
    return response;
}

async function getUserSuggests(value) {
    const response = await API.get('search/userSuggest/' + value.replace(/[""]/g, '\\"'));
    return response.data;
}

async function getSimilar(id, limit = 12) {
    const response = await API.get('search/similar/' + id + '?l=' + limit);
    return response.data;
}

async function getSimilarServices(id) {
    const response = await API.get('search/similar/services/' + id);
    return response.data;
}

async function getSimilarVideos(id) {
    const response = await API.get('search/similar/videos/' + id);
    return response.data;
}

async function getSimilarRooms(id) {
    const response = await API.get('search/similar/rooms/' + id);
    return response.data;
}

async function getAll(q, place, _userId, range) {
    const response = await API.get('search?q=' + q + '&p=' + place + '&u=' + _userId + '&r=' + range);
    return response.data;
}

async function getTags(q) {
    const response = await API.get('search/tags?t=1&q=' + q);
    return response.data;
}

async function getCountUsedTag(tag) {
    const response = await API.get('search?c=1&q=' + tag);
    return response.data;
}

async function searchInAll(_search, _tagSelected, _place, _userId) {
    const response = await API.get('search/search_all', { params: { search: _search, tags: _tagSelected, place: _place } });
    return response.data;
}

export { getSuggests, getTagsSuggests, getUserSuggests, getSimilar, getSimilarServices, getSimilarVideos, getSimilarRooms, getAll, getTags, getCountUsedTag, searchInAll };
