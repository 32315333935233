//CONFIG
import { useCallback, useContext } from 'react';
//CONTEXTS
import { AuthContext } from 'context/authContext';
import { FormContext } from 'context/formContext';
//COMPONENTS
import AddShoot from 'components/common/shoot/AddShoot';

import config from 'utils/constants';
import { PlusIcon } from '@heroicons/react/solid';

function FormProfilStepFinal(props) {
    //CONTEXTS
    const [, formContextDispatch] = useContext(FormContext);
    // eslint-disable-next-line no-unused-vars
    const [authState, , refresh] = useContext(AuthContext);

    const handleAddService = useCallback(() => {
        props.handleGoToProfile();
        formContextDispatch({ type: 'add' });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formContextDispatch]);

    return (
        <>
            <div className="flex flex-col gap-6 sm:gap-28">
                <div>
                    <img src={`${config.publicDir}logo_skillsmobile.png`} alt="logo" className="h-20" />
                </div>
                <div className="relative md:h-56">
                    <h1 className="text-4xl sm:text-xl font-bold text-purpleSkills">Vous êtes prêt !</h1>
                    <div className="text-xl text-graySkills font-semibold">
                        <p>Partagez votre univers professionnel</p>
                    </div>
                    <div className="flex flex-col items-center">
                        <ul className="w-auto p-5 text-center text-white font-bold text-lg">
                            <li className="my-4 ml-2 w-full">
                                <AddShoot>
                                    <button
                                        className="text-base font-bold linkCard gap-2 w-full px-6 py-2"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            props.setPage(0);
                                            props.handleCloneCreate();
                                            props.handleGoToProfile();
                                        }}
                                        type="button"
                                    >
                                        <PlusIcon className="w-5 h-5" />
                                        Ajouter un shoot
                                    </button>
                                </AddShoot>
                            </li>
                            <li className="my-4 ml-2 w-full">
                                <button
                                    className="flex text-base font-bold linkCard gap-2 w-full px-6 py-2"
                                    type="button"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        props.setPage(0);
                                        props.handleCloneCreate();
                                        handleAddService(e);
                                    }}
                                >
                                    <PlusIcon className="w-5 h-5" />
                                    <span>Ajouter un produit / service</span>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="w-full flex justify-end md:pb-7">
                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            props.handleCloneCreate();
                            props.handleGoToProfile();
                            props.setPage(0);
                        }}
                        type="button"
                        className="bg-purpleSkills rounded-full font-bold button-border-gray text-white hover:text-graySkills hover:bg-white justify-end w-auto px-8 py-2"
                    >
                        Voir mon profil
                    </button>
                </div>
            </div>
        </>
    );
}

export default FormProfilStepFinal;
