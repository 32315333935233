import { createPortal } from "react-dom";
//ICONS
import { XCircleIcon } from "@heroicons/react/outline";
//STYLES
import "./optionWrapper.css";
import Button from "components/common/Button";

function OptionWrapper(props) {
    const [open, setOpen] = props.open;

    return createPortal(
        <div className={`optionWrapper_overlay ${open && "active"}`}>
            <div className={`optionWrapper ${open && "active"}`}>
                <>
                    <div className="optionWrapper_close">
                        <XCircleIcon
                            onClick={() => setOpen(false)}
                            className="h-8 w-8 text-gray-200"
                        />
                    </div>
                    <div className="optionWrapper_content">
                        <>
                            {props.children}
                            <div className="optionWrapper_actions">
                                {props.actions?.map((action, index) => (
                                    <Button
                                        key={index}
                                        css="button-primary"
                                        label={action.label}
                                        onClick={() =>
                                            props.dispatchOptionsActions(
                                                action.onClick,
                                                null,
                                                null,
                                                action.closeOnTrue
                                            )
                                        }
                                    />
                                ))}
                            </div>
                        </>
                    </div>
                </>
            </div>
        </div>,
        document.body
    );
}

export default OptionWrapper;
