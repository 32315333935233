import { useContext, useEffect, useState } from 'react';

//CONFIG
import config from 'utils/constants';
//UTILS
import { isMobile } from 'utils/functions';
//LIBS
import _ from 'lodash';
import { Link, useLocation } from 'react-router-dom';
import { useStickyBox } from 'react-sticky-box';
import Slider from 'react-slick';
import { isSmartphone } from '../../../utils/functions';
// import { isMobile } from 'utils/functions';

//CSS
import './sideNav.css';

//ICONS
import { StarIcon, VideoCameraIcon, ShoppingBagIcon, BriefcaseIcon, RssIcon, UsersIcon } from '@heroicons/react/solid';

//CONTEXTS
import { CookieContext } from 'context/cookieContext';
import { AuthContext } from 'context/authContext';
import { StoreContext } from 'context/storeContext';
//COMPONENTS
import Rating from 'components/Rating';
import Image from 'components/common/Image';
//import Subjects from 'components/common/nav/Subjects';
import CookieConsent from 'components/cookieConsent/CookieConsent';
import NotificationsNeeds from '../NotificationsNeeds';
//CONTAINERS
//import TopSkillers from 'containers/profils/TopSkillers';
import DashboardNav from 'containers/dashboard/DashboardNav';
import CardProfilSmall from 'containers/profils/CardProfilSmall';
import CardRoomSmall from 'containers/rooms/CardRoomSmall';
import CardPortfolio from 'containers/profils/CardPortfolio';

import ReactPlayer from 'react-player';
//import CardUserSmall from 'containers/services/CardUserSmall';
import PublishButton from './PublishButton';
//import CardFrontService from 'containers/services/CardFrontService';
// import AdSenseIns from 'containers/adsense/AdSenseIns';

const sizes = {
    avatar: '?w=130&h=130&c=true&q=80',
};

function SideNav(props) {
    //CONTEXTS
    const [cookieState, cookieDispatch] = useContext(CookieContext);
    // eslint-disable-next-line no-unused-vars
    const [authState] = useContext(AuthContext);
    const store = useContext(StoreContext);

    //STATES
    const [isDashboard, setIsDashboard] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);
    //const [currentUserService, setCurrentUserService] = useState(null);
    const [portfolio, setPortfolio] = useState([]);
    const [comments, setComments] = useState([]);
    const [currentTaggedUsersRoom, setCurrentTaggedUsersRoom] = useState([]);
    const [currentConnectedUsersRoom, setCurrentConnectedUsersRoom] = useState([]);
    const [currentSimilarRoom, setCurrentSimilarRoom] = useState([]);
    //const [currentSimilarService, setCurrentSimilarService] = useState([]);
    const [isSearchLocation, setIsSearchLocation] = useState(false);
    const [isJobsLocation, setIsJobsLocation] = useState(false);
    const [isServicesLocation, setIsServicesLocation] = useState(false);

    //HOOKS
    const location = useLocation();
    const stickyRef = useStickyBox({ offsetTop: 60, offsetBottom: 100 });

    const urlSkillsmarketVideo = 'https://www.youtube.com/watch?v=A-8Lo05SR-o';

    const handleCookie = (e) => {
        e.preventDefault();
        cookieDispatch({ type: 'action', status: 'opened' });
    };

    useEffect(() => {
        setIsSearchLocation(location.pathname === '/search' ? true : false);
        setIsJobsLocation(location.pathname === '/jobs' ? true : false);
        setIsServicesLocation(location.pathname === '/services' ? true : false);
    }, [location.pathname]);

    useEffect(() => {
        setIsDashboard(location.pathname?.includes('dashboard') ? true : false);

        //SERVICE
        //setCurrentUserService(store.get('currentUserService')?.user);

        //PORTFOLIO
        setCurrentUser(store.get('currentUser')?.user);
        setPortfolio(store.get('currentUser')?.medias);
        setComments(store.get('currentUserComments')?.comments);

        //TAGGED USERS ROOM
        setCurrentTaggedUsersRoom(_.union(store.get('currentTaggedUsersRoom')?.selected, store.get('currentTaggedUsersRoom')?.tagged));

        //CONNECTED USERS ROOM
        setCurrentConnectedUsersRoom(store.get('currentConnectedUsersRoom'));

        //SIMILAR ROOM
        setCurrentSimilarRoom(store.get('currentSimilarRoom'));

        //SIMILAR SERVICES
        //setCurrentSimilarService(store.get('currentSimilarService'));

        //eslint-disable-next-line
    }, [store]);

    useEffect(() => {
        // Si l'utilisateur est sur la page du profil, changez le fond du body en blanc
        if (location.pathname === '/dashboard/profil' || location.pathname.includes('/dashboard/services/')) {
            document.body.style.backgroundColor = '#FFFFFF';
        } else {
            document.body.style.backgroundColor = '#F7F9FF';
        }
    }, [location]);

    // useEffect(() => {
    //     console.log(currentUser, currentTaggedUsersRoom);
    // }, [currentUser]);

    return (
        <div className="h-full">
            <div ref={stickyRef} className="h-full">
                <div className="sidebar__inner relative flex flex-col h-full">
                    <div
                        className={`hidden sm:block sticky ${
                            isSearchLocation || isJobsLocation || isServicesLocation ? 'top-24' : 'top-16'
                        }  mt-0 z-10 bg-blancSkills`}
                    >
                        <ul className={`mt-4 ${!isDashboard ? 'pb-8' : 'pb-4 border-b border-gray-200'}`}>
                            <li className={`sideNav_link ${location.pathname === (isMobile() ? '/home' : !authState.isLogged ? '/home' : '/') && 'active'}`}>
                                <Link to={isMobile() ? '/home' : !authState.isLogged ? '/home' : '/'} className="flex">
                                    <RssIcon className="w-5 h-5 mr-2" />
                                    News
                                </Link>
                            </li>
                            {!isDashboard && (
                                <>
                                    <li
                                        className={`sideNav_link ${
                                            (location.pathname === '/shoots' || location.pathname === '/collection-shoots') && 'active'
                                        }`}
                                    >
                                        <Link to="/shoots" className="flex">
                                            <VideoCameraIcon className="w-5 h-5 mr-2 relative top-0.5" />
                                            Shoots
                                        </Link>
                                    </li>
                                    {/*
                                    <li className={`sideNav_link ${location.pathname === '/forum' && 'active'}`}>
                                        <Link to="/forum" className="flex">
                                            <ChatAlt2Icon className="w-5 h-5 mr-2 relative top-0.5" />
                                            Forum AI
                                        </Link>
                                    </li>
                                    */}
                                    <li
                                        className={`relative sideNav_link ${
                                            location.pathname === '/skillers' || (!isMobile() && !authState.isLogged && location.pathname === '/')
                                                ? 'active'
                                                : ''
                                        }`}
                                    >
                                        <Link to="/skillers" className="flex">
                                            <UsersIcon className="w-5 h-5 mr-2 relative top-0.5" />
                                            Matching
                                        </Link>
                                        <NotificationsNeeds />
                                    </li>
                                    <li className={`sideNav_link ${location.pathname === '/jobs' && 'active'}`}>
                                        <Link to="/jobs" className="flex">
                                            <BriefcaseIcon className="w-5 h-5 mr-2 relative top-0.5" />
                                            Jobs
                                        </Link>
                                    </li>
                                    <li className={`sideNav_link ${location.pathname === '/services' && 'active'}`}>
                                        <Link to="/services" className="flex">
                                            <ShoppingBagIcon className="w-5 h-5 mr-2 relative top-0.5" />
                                            Market
                                        </Link>
                                    </li>
                                    {!isDashboard && !isSearchLocation && (
                                        <>
                                            <li className="pt-12 overflow-hidden">
                                                <PublishButton isMobile={false} />
                                            </li>
                                        </>
                                    )}
                                </>
                            )}
                        </ul>
                    </div>
                    {/* {location.pathname?.includes('shoots') && <div className="m-auto bg-danger-500 mt-14 w-full block"></div>} */}

                    {/* {currentUserService && (
                        <ul className="border-b border-gray-200">
                            <CardUserSmall user={currentUserService} />
                        </ul>
                    )}  */}

                    {currentUser && !location.pathname.includes('/service') && !location.pathname.includes('/profil') && (
                        <ul className="border-b border-gray-200 py-4">
                            <li className="text-mainText mb-4">
                                <span>Portfolio</span>
                            </li>
                            <li>
                                <div className="grid grid-cols-3 gap-1">
                                    {portfolio?.map((media, index) => (
                                        <div key={index}>
                                            <CardPortfolio key={index} media={media} user={currentUser} />
                                        </div>
                                    ))}
                                </div>
                            </li>
                        </ul>
                    )}

                    {comments?.length > 0 && !location.pathname.includes('/profil') && (
                        <ul className="border-b border-gray-200 py-4">
                            <li className="text-mainText mb-4">
                                <span>Avis</span>
                            </li>
                            <li>
                                <div className="hover:bg-white cursor-pointer rounded-3xl">
                                    <Slider
                                        className="standard-slider"
                                        infinite
                                        autoplay
                                        arrows={false}
                                        autoplaySpeed={5000}
                                        adaptiveHeight
                                        speed={800}
                                        slidesToShow={1}
                                        slidesToScroll={1}
                                    >
                                        {comments?.map((comment, index) => (
                                            <Link
                                                key={index}
                                                to={'/profil/' + comment.userFrom?.profile?.slug}
                                                title={`Profil de ${comment.userFrom?.profile?.pseudo}`}
                                            >
                                                <div key={index} className="p-0">
                                                    <div className="flex flex-col justify-start gap-2">
                                                        <div className="mr-0">
                                                            <div className="flex flex-row items-center">
                                                                {comment.userFrom?.profile?.avatar?.url ? (
                                                                    <Image
                                                                        src={comment.userFrom?.profile?.avatar?.url + sizes.avatar}
                                                                        alt={comment.userFrom?.profile?.pseudo}
                                                                        loading="false"
                                                                        className="w-10 h-10 rounded-full shadow-xl mr-2"
                                                                    />
                                                                ) : (
                                                                    <img
                                                                        className="w-32 rounded-full  border-4 border-white"
                                                                        src={`${config.publicDir}skillsLogo.jpg` + sizes.avatar}
                                                                        alt={comment.userFrom?.profile?.pseudo}
                                                                    />
                                                                )}
                                                                <div className="flex flex-col gap-1">
                                                                    <span className="text-sm text-purpleSkills font-bold pt-2">
                                                                        {comment.userFrom?.profile?.pseudo}
                                                                    </span>
                                                                    <Rating
                                                                        className="relative -top-2"
                                                                        disabled={true}
                                                                        value={comment.note}
                                                                        character={<StarIcon className="h-3.5 w-3.5" />}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="text-sm text-gray-600">
                                                            <div
                                                                dangerouslySetInnerHTML={{
                                                                    __html: comment.message,
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        ))}
                                    </Slider>
                                    <div className="flex flex-row justify-center">
                                        {comments.length <= 0 && <p className="text-sm text-blue-800">Aucun avis pour le moment</p>}
                                    </div>
                                </div>
                            </li>
                        </ul>
                    )}

                    {currentTaggedUsersRoom && !location.pathname.includes('/jobs') && (
                        <>
                            {/* <CardUserSmall /> */}

                            {currentTaggedUsersRoom?.length > 0 && (
                                <ul className="border-b border-gray-200 py-4">
                                    <li className="text-mainText mb-4">
                                        <span>Skillers en lien avec le sujet</span>
                                    </li>
                                    <li>
                                        <div>
                                            {_.take(currentTaggedUsersRoom, 8)?.map((user, index) => (
                                                <CardProfilSmall user={user} key={index} />
                                            ))}
                                        </div>
                                    </li>
                                </ul>
                            )}
                        </>
                    )}

                    {currentConnectedUsersRoom && (
                        <>
                            {currentConnectedUsersRoom?.length > 0 && _.find(currentConnectedUsersRoom, 'user.profile') !== undefined && (
                                <ul className="border-b border-gray-200 py-4">
                                    <li className="text-mainText mb-4">
                                        <span>Ici maintenant</span>
                                    </li>
                                    <li>
                                        <div className="">
                                            {_.take(currentConnectedUsersRoom, 4)?.map((room, index) => (
                                                <CardProfilSmall user={room?.user} key={index} />
                                            ))}
                                        </div>
                                    </li>
                                </ul>
                            )}
                        </>
                    )}

                    {/*  {currentSimilarService && (
                        <>
                            {currentSimilarService?.length > 0 && (
                                <ul className="border-b border-gray-200 pb-2">
                                    <li className="font-bold md:font-normal md:text-base text-xl text-purpleSkills md:text-mainText mb-4">
                                        <span>Services similaires</span>
                                    </li>
                                    <li className=''>
                                        <div className="grid grid-cols-2 sm:flex sm:flex-col gap-2 sm:gap-4">
                                            {_.take(currentSimilarService, 5)?.map((service, index) => (
                                                <CardFrontService service={service} key={index} />
                                            ))}
                                        </div>
                                    </li>
                                </ul>
                            )}
                        </>
                    )} */}

                    {currentSimilarRoom && !location.pathname.includes('/jobs') && (
                        <>
                            {currentSimilarRoom?.length > 0 && (
                                <ul className="border-b border-gray-200 py-4">
                                    <li className="text-mainText mb-4">
                                        <span>Rooms similaires</span>
                                    </li>
                                    <li>
                                        <div className="">
                                            {_.take(currentSimilarRoom, 5)?.map((room, index) => (
                                                <CardRoomSmall room={room} key={index} />
                                            ))}
                                        </div>
                                    </li>
                                </ul>
                            )}
                        </>
                    )}

                    {props?.sections?.map((section, key) => (
                        <ul key={key} className="border-b border-gray-200 py-4">
                            <li className="text-mainText mb-4">
                                <span>{section.title}</span>
                            </li>
                            <li>{section.content}</li>
                        </ul>
                    ))}

                    {isDashboard && (
                        <div className="sm:sticky sm:top-36 border-b border-gray-200 pt-2 pb-4">
                            <DashboardNav />
                        </div>
                    )}

                    {/*
                    {!props?.sections &&
                        !isDashboard &&
                        !location.pathname?.includes('service') &&
                        !location.pathname?.includes('/jobs') &&
                        (!currentTaggedUsersRoom || currentTaggedUsersRoom?.length === 0) &&
                        authState.isLogged &&
                        ((isSmartphone() && !location.pathname?.includes('search')) ||
                            (!isSmartphone() && (location.pathname?.includes('search') || !location.pathname?.includes('search')))) && (
                            <ul className="border-b border-gray-200 py-4">
                                <li className="text-purpleSkills mb-4 font-bold overflow-hidden">
                                    <Link className="flex gap-2" to="/skillers">
                                        <UsersIcon className="block w-6 h-6" />
                                        <span className="hover:text-blueMain">Communauté</span>
                                    </Link>
                                </li>
                                <li>
                                    <TopSkillers />
                                </li>
                            </ul>
                        )}
                    */}
                    {!location.pathname?.includes('/service') &&
                        !location.pathname?.includes('/jobs') &&
                        !location.pathname?.includes('shoots') &&
                        !authState.isLogged &&
                        ((isSmartphone() && !location.pathname?.includes('search')) ||
                            (!isSmartphone() && (location.pathname?.includes('search') || !location.pathname?.includes('search')))) && (
                            <ul className="border-b border-gray-200 py-4">
                                <li className="text-mainText mb-4 overflow-hidden">
                                    <span>Créez votre SkillsMarket</span>
                                </li>
                                <li>
                                    <div className="relative aspect-video">
                                        <ReactPlayer
                                            url={urlSkillsmarketVideo}
                                            width="100%"
                                            height="100%"
                                            origin={window.location.origin}
                                            className="rounded-lg overflow-hidden aspect-video"
                                            pip
                                            controls
                                        />
                                    </div>
                                </li>
                            </ul>
                        )}
                    {/*
                    {!currentUserService &&
                        !isDashboard &&
                        !location.pathname.includes('/jobs') &&
                        !location.pathname.includes('/services') &&
                        authState.isLogged &&
                        (!currentTaggedUsersRoom || currentTaggedUsersRoom?.length === 0) &&
                        ((isSmartphone() && !location.pathname?.includes('search')) ||
                            (!isSmartphone() && (location.pathname?.includes('search') || !location.pathname?.includes('search')))) && (
                            <ul className="border-b border-gray-200 py-8 bg-blancSkills">
                                <li className="text-mainText mb-4">
                                    <span>Sujets à découvrir</span>
                                </li>
                                <li className="-mx-2">
                                    <div className="min-h-max">
                                        <Subjects />
                                    </div>
                                </li>
                            </ul>
                        )}
                    */}

                    {/* {!isMobile() && <AdSenseIns block="sideNav" />} */}

                    {cookieState.status === 'opened' && <CookieConsent />}
                </div>
            </div>
            {((isSmartphone() && !location.pathname?.includes('search')) ||
                (!isSmartphone() && (location.pathname?.includes('search') || !location.pathname?.includes('search')))) && (
                <div className="Footer sticky bottom-0 bg-blancSkills">
                    <ul className="border-b border-gray-200 py-4 mb-0">
                        <li>
                            <div className="relative">
                                <div className="flex flex-wrap gap-2">
                                    <Link to="https://welcome.skillsmarket.fr/" className="link_basic_dark">
                                        SkillsMarket
                                    </Link>

                                    <Link to="/comment-ca-marche" className="link_basic_dark">
                                        Contact
                                    </Link>
                                    <Link to="/cgu" className="link_basic_dark">
                                        CGV - Mentions légales - RGPD
                                    </Link>
                                    <Link to="#" href="#" onClick={handleCookie} className="link_basic_dark">
                                        Gestion des cookies
                                    </Link>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            )}
        </div>
    );
}

export default SideNav;
