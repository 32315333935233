import React, { forwardRef, memo } from 'react';

function Button(props, ref) {
    return (
        <button
            id={props.id || undefined}
            onMouseOver={props.onMouseOver}
            onMouseLeave={props.onMouseLeave}
            disabled={props.disabled}
            onClick={props.onClick}
            className={`${props.justIcon ? 'button-justIcon' : 'button'} ${props.css} ${props.disabled && 'button-disabled'}`}
        >
            {props.label}
        </button>
    );
}

export default memo(forwardRef(Button));
