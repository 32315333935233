import { useReducer, createContext, useEffect, cloneElement, useContext, Fragment } from 'react';

//ICONS
import { AiOutlineCloseSquare } from 'react-icons/ai';

const HeadlessNotificationsContext = createContext();

function reducer(state, action) {
    switch (action.action) {
        case 'add':
            return { ...state, action: action.action, notifications: [...state.notifications, { message: action.message }] };
        case 'close':
            const newArr = state.notifications;
            newArr.splice(action.k, 1);
            state = { ...state, notifications: newArr };
            return state;
        default:
            throw new Error();
    }
}

export function HeadlessNotificationsProvider(props) {
    const [store, set] = useReducer(reducer, { opened: false, notifications: [] });
    const methods = { store, set };

    return <HeadlessNotificationsContext.Provider value={methods}>{props.children}</HeadlessNotificationsContext.Provider>;
}

export { HeadlessNotificationsContext };

export default function HeadlessNotifications() {
    const notif = useContext(HeadlessNotificationsContext);

    function handleClose(k) {
        notif.set({ action: 'close', k });
    }

    const Notification = (props) => {
        return (
            <div className="relative m-0 px-4 py-2 pr-8 bg-[#9B86AD] text-white rounded-sm shadow border-l-4 border-[#C131BB] text-sm">
                <div className="absolute top-1 right-1 cursor-pointer" onClick={() => handleClose(props.k)}>
                    <AiOutlineCloseSquare className="w-4 h-4 text-[#FFFFFF]" />
                </div>
                <span className="font-bold text-[#FFFFFF]">Génial !</span>
                <p>{props?.message}</p>
            </div>
        );
    };

    useEffect(() => {
        let timeout;
        switch (notif.store.action) {
            case 'add':
                timeout = setTimeout(() => {
                    notif.set({ action: 'close', k: 0 });
                }, 3000);
                break;
            default:
        }
        return () => clearTimeout(timeout);
        //eslint-disable-next-line
    }, [notif]);

    return (
        <div className="fixed bottom-0 right-0 h-auto z-50 flex flex-col justify-start gap-2 pr-4 pb-4">
            {notif.store.notifications.map((notification, k) => (
                <Fragment key={k}>{cloneElement(<Notification />, { ...notification, k })}</Fragment>
            ))}
        </div>
    );
}
