import { useEffect, useState, useContext } from 'react';

//CONTEXTS
import { AuthContext } from 'context/authContext';

//HOOKS
import UseAuth from 'hooks/useAuth';

export default function GoogleAuth() {
    const [gsiScriptLoaded, setGsiScriptLoaded] = useState(false);

    const [authState] = useContext(AuthContext);

    const { loginByOauth } = UseAuth();

    const handleGoogleSignIn = (res) => {
        if (!res || !res.clientId || !res.credential) return;

        loginByOauth(res.credential, 'connect');
    };

    const initializeGsi = () => {
        if (!window.google || gsiScriptLoaded) return;

        setGsiScriptLoaded(true);
        window.google.accounts.id.initialize({
            client_id: process.env.REACT_APP_GG_APP_ID,
            callback: handleGoogleSignIn,
        });
        // window.google.accounts.id.prompt();
        // window.google.accounts.id.renderButton(document.getElementsByClassName('parent_g_id_signin'), {
        //     theme: 'outline',
        //     size: 'large',
        // });
    };
    useEffect(() => {
        if (authState.isLogged || gsiScriptLoaded) return;

        const script = document.createElement('script');
        script.src = 'https://accounts.google.com/gsi/client';
        script.onload = initializeGsi;
        script.async = true;
        script.id = 'google-client-script';
        document.querySelector('body')?.appendChild(script);

        return () => {
            window.google?.accounts?.id?.cancel();
            document.getElementById('google-client-script')?.remove();
        };
        //eslint-disable-next-line
    }, [handleGoogleSignIn, initializeGsi, authState]);

    return (
        <>
            <div id="g_id_onload" data-client_id={process.env.REACT_APP_GG_APP_ID}></div>
        </>
    );
}
