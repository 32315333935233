import React, { useEffect, createContext, useReducer, useRef } from 'react';

//API
import { postShoot } from 'api/shoots';

//CONTEXTS
const ShootContext = createContext();

const initialState = () => {
    return {};
};

const ShootProvider = (props) => {
    const timeout = useRef();

    const reducer = (state, action) => {
        switch (action.type) {
            case 'reset':
                return {};
            case 'addFile':
                return { type: action.type, file: action.file, pending: false };
            case 'progress':
                return {
                    type: action.type,
                    file: state.file,
                    pending: true,
                    progress: action.progress,
                };
            case 'send':
                return { type: action.type, file: state.file, data: action.data, pending: true };
            default:
        }
    };

    function send() {
        const fd = new FormData();
        fd.append('shoot', state.file);
        fd.append('data', JSON.stringify(state.data));

        const push = postShoot(fd, (e) => {
            let progress = (e.loaded / e.total) * 100;
            dispatch({ type: 'progress', progress: Math.round(progress) });
        });
        push.then((res) => {
            if (res.status === 200) {
                timeout.current = setTimeout(() => {
                    dispatch({ type: 'reset' });
                }, 2000);
            }
        });
    }

    const [state, dispatch] = useReducer(reducer, null, initialState);

    const methods = { state, dispatch };

    useEffect(() => {
        return () => {
            clearTimeout(timeout.current);
        };
    }, []);

    useEffect(() => {
        if (state.type === 'send' && state.pending && state.file) {
            send();
        }
        //eslint-disable-next-line
    }, [state]);

    return <ShootContext.Provider value={methods}>{props.children}</ShootContext.Provider>;
};
export { ShootContext, ShootProvider };
