import { useEffect, useState } from 'react';

//LIBS
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

//API
import { putServicePublish, deleteService } from 'api/services';

//ICONS
import { EyeIcon, EyeOffIcon, PencilIcon, TrashIcon } from '@heroicons/react/solid';
import Modal from 'components/common/Modal';

function CardService({ service, refetch }) {
    const [item, setItem] = useState(service);
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

    function handleTogglePublish() {
        if (item.updated) {
            putServicePublish(item.id).then((data) => {
                setItem({ ...item, publish: data.publish });
                const msg = data.publish ? 'Service publié' : 'Service dépublié';
                toast(msg, { type: 'success' });
            });
        } else {
            toast('Vous devez renseigner le service avant de le publier', { type: 'error' });
        }
    }
    function handleDelete() {
        deleteService(item.id).then((data) => {
            toast('Service supprimé', { type: 'success' });
            refetch();
            setOpenConfirmDelete(false);
        });
    }

    useEffect(() => {
        setItem(service);
        //eslint-disable-next-line
    }, [, service]);

    return (
        <>
            <Modal
                title="Supprimer le service"
                open={openConfirmDelete}
                onClose={{
                    onClick: () => {
                        setOpenConfirmDelete(false);
                    },
                }}
                onConfirm={{
                    label: 'Supprimer',
                    onClick: handleDelete,
                }}
            >
                <p>Voulez-vous vraiment supprimer ce service ?</p>
            </Modal>
            <div className="card card-service w-full p-2 bg-white flex flex-row justify-between items-center sm:gap-4 h-auto">
                <Link to={`/service/${item.slug}`} className="flex ml-2">
                    <div className="card-header text-main text-base mb-4 font-bold text-purpleSkills">
                        <span>{item.title}</span>
                        <span>{item.price}</span>
                    </div>
                </Link>

                <div className="flex flex-nowrap justify-end">
                    <div className="card-actions flex flex-nowrap flex-col sm:flex-row items-center justify-end w-auto gap-2">
                        <button
                            onClick={handleTogglePublish}
                            className={`linkCard linkCard_Icon flex ml-2 ${item.publish ? 'text-success-400' : 'text-warning-300'}`}
                        >
                            <>
                                {item.publish ? (
                                    <>
                                        <EyeIcon className="w-5 h-5" /> {/* Dépublier */}
                                    </>
                                ) : (
                                    <>
                                        <EyeOffIcon className="w-5 h-5" /> {/* Publier */}
                                    </>
                                )}
                            </>
                        </button>
                        <Link to={`${item.id}`} className="linkCard linkCard_Icon flex ml-2">
                            <>
                                <PencilIcon className="w-5 h-5" /> {/* Modifier */}
                            </>
                        </Link>
                        <button onClick={() => setOpenConfirmDelete(true)} className="linkCard linkCard_Icon flex text-danger-500 ml-2">
                            <>
                                <TrashIcon className="w-5 h-5" /> {/* Supprimer */}
                            </>
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CardService;
