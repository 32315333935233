import { useParams } from 'react-router-dom';

//COMPONENTS
//import Header from 'components/common/Header';
import ListNotifications from 'containers/notifications/ListNotifications';

//LAYOUT
import DashboardLayout from 'pages/layouts/Dashboard';

//STYLES
import 'App.css';

//UTILS
import { PageContentFadingAnimation } from 'utils/animations';

function Notifications() {
    const params = useParams();

    return (
        <DashboardLayout>
            <PageContentFadingAnimation duration={400}>
                <div className="App">
                    {/* <Header>
                        <h1 className="h1 mx-4 sm:mx-0">Mes notifications</h1>
                    </Header> */}

                    <main>{!params.slug && <ListNotifications />}</main>
                </div>
            </PageContentFadingAnimation>
        </DashboardLayout>
    );
}

export default Notifications;
