import { useCallback, useEffect, useRef, Fragment, useContext, useState } from 'react';

//CONTEXTS
import { AuthContext } from 'context/authContext';

//API
import { useInfiniteQuery } from 'react-query';
import { getUsersP, getUsersByInterestsP } from 'api/users';

//HOOKS
import UseIntersectionObserver from 'hooks/useIntersectionObserver';

//COMPONENTS
import SectionTitle from 'components/common/SectionTitle';

//CONTAINERS
//import CardProfilSearch from 'containers/profils/CardProfilSearch';
import CardFrontProfil from '../containers/profils/CardFrontProfil';

function useInfiniteSkillers(type, isTopsSkillersEnabled = true, isInterestsSkillersEnabled = true, notLogged) {
    //CONTEXTS
    const [authState] = useContext(AuthContext);
    //USESTATES
    const [isTopSkillersEnabled, setIsTopSkillersEnabled] = useState(isTopsSkillersEnabled);
    const [isInterestSkillersEnabled, setIsInterestSkillersEnabled] = useState(isInterestsSkillersEnabled);
    //REFS
    const page = useRef(0);
    const hasNextPageTop = useRef(false);
    const hasNextPageInterests = useRef(false);
    const loadMoreButtonTopRef = useRef();
    const loadMoreButtonIntRef = useRef();

    const [tab, setTab] = useState(null);

    const handleTopSkillersEnabled = useCallback(function () {
        if (isTopSkillersEnabled !== true) {
            setIsTopSkillersEnabled(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleInterestSkillersEnabled = useCallback(function () {
        if (isInterestSkillersEnabled !== true) {
            setIsInterestSkillersEnabled(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //API
    let skillers;
    const Topskillers = useInfiniteQuery(
        ['infiniteTopSkillers', isTopSkillersEnabled],
        ({ pageParam = 0 }) => getUsersP(`?p=${pageParam}&l=${isTopSkillersEnabled ? '15' : '10'}`),
        {
            getNextPageParam: (lastPage) => {
                if (lastPage.hasNextPage) {
                    hasNextPageTop.current = lastPage.hasNextPage;

                    return (page.current = lastPage.page);
                } else {
                    hasNextPageTop.current = false;
                    page.current = 0;
                }
                return;
            },
            /* enabled: isEnabled, */
            enabled: isTopSkillersEnabled,
        }
    );

    const InterestSkillers = useInfiniteQuery(
        ['infiniteInterestsSkillers', isInterestSkillersEnabled],
        ({ pageParam = 0 }) => getUsersByInterestsP(`?p=${pageParam}&l=${isInterestSkillersEnabled ? '15' : '10'}`),
        {
            getNextPageParam: (lastPage) => {
                if (lastPage.hasNextPage) {
                    hasNextPageInterests.current = lastPage.hasNextPage;

                    return (page.current = lastPage.page);
                } else {
                    hasNextPageInterests.current = false;
                    page.current = 0;
                }
                return;
            },
            enabled: isInterestSkillersEnabled && authState?.isLogged,
        }
    );

    if (type === 'top') {
        skillers = Topskillers;
    } else if (type === 'interests' && authState.isLogged) {
        skillers = InterestSkillers;
    }

    //HOOKS
    UseIntersectionObserver({
        target: loadMoreButtonTopRef,
        onIntersect: (isVisible) => {
            if (isVisible) {
                skillers.fetchNextPage();
            }
        },
        enabled: !!hasNextPageTop.current,
    });

    UseIntersectionObserver({
        target: loadMoreButtonIntRef,
        onIntersect: (isVisible) => {
            if (isVisible) {
                skillers.fetchNextPage();
            }
        },
        enabled: !!hasNextPageInterests.current,
    });

    useEffect(() => {
        skillers?.refetch();
        //eslint-disable-next-line
    }, []);

    const tabGlobal = useCallback(() => {
        return (
            <>
                <div className="m-auto xl:container">
                    <SectionTitle>
                        <h1 className="h2 mx-0 my-2 mt-4 mb-2 w-full">
                            {notLogged ? (
                                <div className="text-center py-6">
                                    <span className="text-center mt-2 mb-2 font-extrabold text-lg sm:text-xl text-purpleSkills">
                                        Votre nouvelle carte professionnelle à partager partout
                                    </span>
                                </div>
                            ) : (
                                <span className="text-center mt-2 mb-2 font-extrabold text-lg sm:text-xl text-purpleSkills">
                                    {type === 'interests' ? 'Ils pourraient vous intéresser' : 'Top Skillers'}
                                </span>
                            )}
                        </h1>
                    </SectionTitle>
                </div>

                <div className="w-full m-auto xl:container mb-4">
                    {/* <div className="flex flex-row flex-wrap justify-start w-12/12 gap-4"> */}
                    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 auto-cols-max gap-x-2 sm:gap-4" style={{ maxWidth: '100%' }}>
                        <>
                            {skillers?.data?.pages && (
                                <>
                                    {skillers?.data?.pages?.map((pages, index) => (
                                        <Fragment key={index}>
                                            {pages.docs.map((user, j) => (
                                                <div className="mt-0 sm:pb-0 card card_profile w-full h-full" key={j}>
                                                    <CardFrontProfil
                                                        user={user}
                                                        refetch={skillers.refetch}
                                                        listingQueryKeys={[
                                                            'infiniteTopSkillers',
                                                            'infiniteInterestsSkillers',
                                                            'infiniteUsersMatch',
                                                            'infiniteNeedsMatch',
                                                        ]}
                                                    />
                                                </div>
                                            ))}
                                        </Fragment>
                                    ))}
                                </>
                            )}
                        </>
                    </div>
                </div>
                {hasNextPageTop.current && type === 'top' && (
                    <div className="text-center mt-4">
                        <button
                            ref={loadMoreButtonTopRef}
                            className="button button-secondary rounded-3xl"
                            onClick={() => {
                                skillers.fetchNextPage();
                                handleTopSkillersEnabled();
                            }}
                            disabled={!hasNextPageTop.current || skillers.isFetchingNextPage}
                        >
                            {skillers.isFetchingNextPage ? 'Chargement...' : 'Voir plus'}
                        </button>
                    </div>
                )}
                {hasNextPageInterests.current && type === 'interests' && (
                    <div className="text-center mt-4 mb-14">
                        <button
                            ref={loadMoreButtonIntRef}
                            className="button button-secondary rounded-3xl"
                            onClick={() => {
                                skillers.fetchNextPage();
                                handleInterestSkillersEnabled();
                            }}
                            disabled={!hasNextPageInterests.current || skillers.isFetchingNextPage}
                        >
                            {skillers.isFetchingNextPage ? 'Chargement...' : 'Voir plus'}
                        </button>
                    </div>
                )}
            </>
        );
        //eslint-disable-next-line
    }, [skillers?.data, skillers?.isSuccess]);

    useEffect(() => {
        if (skillers?.data?.pages[0].totalDocs === 0) {
            return setTab(null);
        }
        setTab(tabGlobal);
    }, [tabGlobal, skillers?.data, authState.isLogged]);

    return tab;
}

export default useInfiniteSkillers;
