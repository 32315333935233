import { useEffect, useState, useContext, useRef } from 'react';
import { useSpring, animated } from 'react-spring';
import SEO from 'components/Seo';

//API
//import { getServicesP } from 'api/services';
//import { useInfiniteQuery } from 'react-query';
//import { countJobs } from 'api/forum';
import { useInfiniteQuery } from 'react-query';
//UTILS
import { isMobile } from 'utils/functions';
import { PageContentFadingAnimation } from 'utils/animations';
//import { getServicesPByTags } from '../api/services';
import { useLocation, useSearchParams } from 'react-router-dom';

//CONTEXT
import { AuthContext } from 'context/authContext';
import { StoreContext } from 'context/storeContext';
//COMPONENTS
import SideNav from '../components/common/nav/SideNav';
import Tabs from 'components/tabs/Tabs';
//HOOKS
import UseIntersectionObserver from 'hooks/useIntersectionObserver';
import useInfiniteJobs from 'hooks/useInfiniteJobs';
//LAYOUT
import FullLayout from 'pages/layouts/Full';

//STYLES
import 'App.css';

import JobDetail from '../containers/rooms/JobDetail';
import { getJobsFav } from '../api/forum';

//import ClipLoader from 'react-spinners/ClipLoader';

function ListingJobs(props) {
    const [authState] = useContext(AuthContext);
    const [refetch, setRefetch] = useState(false);

    const page = useRef(0);
    const hasNextPage = useRef(false);
    const loadMoreButtonRef = useRef();

    const location = useLocation();

    let [searchParams] = useSearchParams();
    const q = searchParams.get('q');

    const store = useContext(StoreContext);

    const infiniteFavJobs = useInfiniteQuery(['infiniteJobsFav'], ({ pageParam = 0 }) => getJobsFav(`?p=${pageParam}&l=10`, authState?.isLogged), {
        getNextPageParam: (lastPage) => {
            if (lastPage.hasNextPage) {
                hasNextPage.current = lastPage.hasNextPage;

                return (page.current = lastPage.page);
            } else {
                hasNextPage.current = false;
                page.current = 0;
            }
            return;
        },
        /* staleTime: Infinity, */
        /* enabled: isEnabled, */
        enabled: false,
    });

    const infiniteJobs = useInfiniteJobs(q, location.state?.place, location.state?.contract, location.state?.type, false);
    const infiniteJobsFav = useInfiniteJobs(q, location.state?.place, location.state?.contract, location.state?.type, true);

    UseIntersectionObserver({
        target: loadMoreButtonRef,
        onIntersect: (isVisible) => {
            if (isVisible) infiniteFavJobs.fetchNextPage();
        },
        enabled: !!hasNextPage.current,
    });

    const closeSlideAnimationSideNav = useSpring({
        transform: store.get('openJobDetail') ? 'translateX(-19%)' : 'translateX(0%)',
        config: {
            duration: 140,
        },
    });

    useEffect(() => {
        if (authState.isLogged) {
            infiniteFavJobs.refetch();
        }
        //eslint-disable-next-line
    }, [authState.isLogged, refetch]);

    function refetchFav() {
        setRefetch(!refetch);
    }

    const tabJobs = <>{infiniteJobs}</>;
    const tabFav = <>{infiniteJobsFav}</>;

    const tabs = [
        { label: 'Pour moi', key: 'tab_jobs', content: tabJobs },
        { label: 'Favoris', key: 'tab_favoris', content: tabFav, visible: authState.isLogged },
    ];

    return (
        <>
            <SEO
                title={"Trouvez votre emploi idéal sur Skillsmarket Jobs"}
                description={"Explorez les offres d'emploi variées sur Skillsmarket Jobs et trouvez l'opportunité qui correspond à vos compétences. Bénéficiez de recommandations personnalisées et tirez parti de notre algorithme de matchmaking unique."}
                type="summary_large_image"
            />
            <JobDetail refetchFav={infiniteFavJobs.refetch} />
            <FullLayout noContain={store.get('openJobDetail') ? true : false}>
                <animated.div style={closeSlideAnimationSideNav} className="container">
                    <div className="w-full flex flex-col sm:flex-row sm:gap-8 pb-4">
                        {!isMobile() && (
                            <div className="hidden sm:block sm:w-[18%] md:w-[15%]">
                                <SideNav />
                            </div>
                        )}
                        <div className={`w-full sm:w-[79%] ${store.get('openJobDetail') ? 'w-full xl:pl-28 pr-2' : 'md:w-[65%]'} h-full`}>
                            <main>
                                {authState.isLogged ? (
                                    <PageContentFadingAnimation duration={250}>
                                        <Tabs stickyDown={`top-0 sm:top-[109px]`} stickyUp={`top-[52px] sm:top-[109px]`} tabs={tabs} refetchFav={refetchFav} />
                                    </PageContentFadingAnimation>
                                ) : (
                                    <>{infiniteJobs}</>
                                )}
                            </main>
                        </div>
                    </div>
                </animated.div>
            </FullLayout>
        </>
    );
}

export default ListingJobs;
