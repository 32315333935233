//import { LocationMarkerIcon } from '@heroicons/react/solid';
import { useState } from 'react';

//ICONS
// import { PlusCircleIcon, MinusCircleIcon } from '@heroicons/react/solid';

function Cv(props) {
    const [openXp, setOpenXp] = useState(true);
    const [openPermis, setOpenPermis] = useState(true);
    const [openQuali, setOpenQuali] = useState(true);
    const [openLanguages, setOpenLanguages] = useState(true);

    return (
        <>
            <div className="flex flex-col gap-2">
                {props.userData?.profile?.experiences?.length > 0 && (
                    <div>
                        {/* <h3 className="mt-4 font-bold text-mainText cursor-pointer flex flex-row gap-1" onClick={() => setOpenXp(!openXp)}>
                            Expériences
                            {openXp ? (
                                <MinusCircleIcon className="w-4 h-4 relative top-1 text-blueMain" />
                            ) : (
                                <PlusCircleIcon className="w-4 h-4 relative top-1 text-blueMain" />
                            )}
                        </h3> */}

                        <div className="flex flex-row items-center justify-center w-full gap-2 text-graySkills relative">
                            <div className="flex-auto h-[1px] bg-gray-200"></div>
                            <h3 className="text-xl flex gap-0.5 items-center font-bold text-gray-400 cursor-pointer" onClick={() => setOpenXp(!openXp)}>
                                Expériences
                                {/*  {openXp ? (
                                    <MinusCircleIcon className="w-4 h-4 relative text-blueMain" />
                                ) : (
                                    <PlusCircleIcon className="w-4 h-4 relative text-blueMain" />
                                )} */}
                            </h3>
                            <div className="flex-auto h-[1px] bg-gray-200"></div>
                        </div>

                        {openXp && (
                            <div className="flex flex-col gap-4 mt-4">
                                {props.userData?.profile?.experiences
                                    ?.sort((a, b) => {
                                        if (a.currentJob && !b.currentJob) {
                                            return -1;
                                        } else if (!a.currentJob && b.currentJob) {
                                            return 1;
                                        } else if (a.yearTo !== b.yearTo) {
                                            return b.yearTo - a.yearTo;
                                        } else if (a.yearFrom !== b.yearFrom) {
                                            return -a.yearFrom - b.yearFrom;
                                        } else {
                                            return a.title.localeCompare(b.title);
                                        }
                                    })
                                    .map((experience, k) => (
                                        <div key={k} className="flex justify-between w-full">
                                            <div className="w-full flex flex-col justify-between h-full">
                                                <p className="mb-2 font-semibold text-graySkills text-main text-sm">
                                                    De {experience.yearFrom} à {experience.yearTo}
                                                    {experience.currentJob ? " - aujourd'hui" : ''}
                                                    {' - '}
                                                    <span className="font-thin">{experience.yearTo - experience.yearFrom} an(s)</span>
                                                </p>
                                                <p className="text-lg text-purpleSkills font-bold">{experience.title}</p>
                                                <p className="text-main text-sm font-bold">{experience.society}</p>
                                                {experience.location?.address && (
                                                    <div className="text-main text-graySkills text-sm">
                                                        <p className="flex justify-start">
                                                            <span>{experience.location?.address}</span>
                                                        </p>
                                                    </div>
                                                    /*  <p className="text-main text-graySkills font-semibold text-sm">{experience.location?.address}</p> */
                                                )}
                                                {experience.description && (
                                                    <div className="pt-2 text-sm text-main">
                                                        <div dangerouslySetInnerHTML={{ __html: experience.description }} />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        )}
                    </div>
                )}
                {props.userData?.profile?.qualifications?.length > 0 && (
                    <div className="">
                        <div className="flex flex-row items-center justify-center w-full gap-2 text-graySkills relative">
                            <div className="flex-auto h-[1px] bg-gray-200"></div>
                            <h3
                                className="text-xl flex gap-0.5 items-center font-bold text-gray-400 cursor-pointer"
                                onClick={() => setOpenQuali(!openQuali)}
                            >
                                Formations
                                {/*  {openQuali ? (
                                ) : (
                                    <PlusCircleIcon className="w-4 h-4 relative text-blueMain" />
                                )} */}
                            </h3>
                            <div className="flex-auto h-[1px] bg-gray-200"></div>
                        </div>

                        {openQuali && (
                            <div className="flex flex-col gap-2 mt-4">
                                {props.userData?.profile?.qualifications
                                    ?.sort((a, b) => {
                                        if (a.currentSchool && !b.currentSchool) {
                                            return -1;
                                        } else if (!a.currentSchool && b.currentSchool) {
                                            return 1;
                                        } else if (a.yearTo !== b.yearTo) {
                                            return b.yearTo - a.yearTo;
                                        } else if (a.yearFrom !== b.yearFrom) {
                                            return -a.yearFrom - b.yearFrom;
                                        } else {
                                            return a.title.localeCompare(b.title);
                                        }
                                    })
                                    .map((qualification, k) => (
                                        <div key={k} className="flex justify-between">
                                            <div className="w-full flex flex-col justify-between h-full">
                                                <p className="text-lg text-purpleSkills font-bold">{qualification.title}</p>
                                                <p className="text-main text-sm font-bold">{qualification.school}</p>

                                                {/*  <p className="mt-2 text-main text-sm font-semibold">
                                                De {qualification.yearFrom} à {qualification.yearTo}
                                                {qualification.currentSchool ? " - aujourd'hui" : ''}
                                                {' - '}
                                                <span className="font-thin">{qualification.yearTo - qualification.yearFrom} an(s)</span>
                                            </p> */}
                                                <p className="mt-2 text-sm font-semibold text-graySkills">
                                                    {qualification.yearTo}
                                                    {qualification.currentSchool ? " - aujourd'hui" : ''}
                                                </p>
                                                {qualification.location?.address && (
                                                    <p className="text-main text-graySkills text-sm">{qualification.location?.address}</p>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        )}
                    </div>
                )}
                {props.userData?.profile?.permis?.length > 0 && (
                    <div>
                        <div className="flex flex-row items-center justify-center w-full gap-2 text-graySkills relative">
                            <div className="flex-auto h-[1px] bg-gray-200"></div>
                            <h3
                                className="text-xl flex gap-0.5 items-center font-bold text-gray-400 cursor-pointer"
                                onClick={() => setOpenPermis(!openPermis)}
                            >
                                Permis
                                {/*  {openPermis ? (
                                    <MinusCircleIcon className="w-4 h-4 relative text-blueMain" />
                                ) : (
                                    <PlusCircleIcon className="w-4 h-4 relative text-blueMain" />
                                )} */}
                            </h3>
                            <div className="flex-auto h-[1px] bg-gray-200"></div>
                        </div>
                        {openPermis && (
                            <div className="flex flex-col gap-2 mt-4">
                                {props.userData.profile?.permis?.map((permis, k) => (
                                    <div key={k} className="flex justify-between">
                                        <div className="w-full flex flex-col justify-between h-full">
                                            <p className="text-lg text-purpleSkills font-bold">{permis.permisType}</p>
                                            <p className="text-main text-sm font-bold">Véhicule : {permis.vehicle === true ? 'oui' : 'non'}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                )}
                {props.userData?.profile?.languages?.length > 0 && (
                    <div>
                        <div className="flex flex-row items-center justify-center w-full gap-2 text-graySkills relative">
                            <div className="flex-auto h-[1px] bg-gray-200"></div>
                            <h3
                                className="text-xl flex gap-0.5 items-center font-bold text-gray-400 cursor-pointer"
                                onClick={() => setOpenLanguages(!openLanguages)}
                            >
                                Langues
                                {/* {openLanguages ? (
                                <MinusCircleIcon className="w-4 h-4 relative text-blueMain" />
                            ) : (
                                <PlusCircleIcon className="w-4 h-4 relative text-blueMain" />
                            )} */}
                            </h3>
                            <div className="flex-auto h-[1px] bg-gray-200"></div>
                        </div>
                        {openLanguages && (
                            <div className="flex flex-col gap-2 mt-4">
                                {props.userData.profile?.languages?.map((language, k) => (
                                    <div key={k} className="flex justify-between">
                                        <div className="w-full flex flex-col justify-between h-full">
                                            <p className="text-lg text-purpleSkills font-bold">{language.title}</p>
                                            <p className="text-main text-sm font-bold">Niveau : {language.level}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                )}
                {props.userData?.profile?.hobbies?.length > 0 && (
                    <div>
                        <div className="flex flex-row items-center justify-center w-full gap-2 text-graySkills relative">
                            <div className="flex-auto h-[1px] bg-gray-200"></div>
                            <h3 className="text-xl flex gap-0.5 items-ecnter font-bold text-gray-400 cursor-pointer">
                                Hobbies
                                {/* {openLanguages ? (
                                <MinusCircleIcon className="w-4 h-4 relative text-blueMain" />
                            ) : (
                                <PlusCircleIcon className="w-4 h-4 relative text-blueMain" />
                            )} */}
                            </h3>
                            <div className="flex-auto h-[1px] bg-gray-200"></div>
                        </div>
                        <div className="flex flex-col gap-2 mt-4">
                            {props.userData.profile?.hobbies?.map((hobby, k) => (
                                <div key={k} className="flex justify-between">
                                    <div className="w-full flex flex-col justify-between h-full">
                                        <p className="text-lg text-purpleSkills font-bold">{hobby.title}</p>
                                        <p className="text-main text-sm font-bold">{hobby.description}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}

export default Cv;
