import { Fragment, useCallback, useEffect, useState, useContext } from 'react';
import { useSpring, animated } from 'react-spring';
//UTILS
import config from 'utils/constants';
import InAllLayouts from 'pages/layouts/InAllLayouts';
import { isMobile } from 'utils/functions';
//HOOKS
import useScroll from 'hooks/useScroll';
import useNotifications from 'hooks/useNotifications';
//API
import { getNotifications } from 'api/discussions';
//COMPONENTS
import MainNavSearch from 'components/common/MainNavSearch';
import MainNavMobile from 'components/common/MainNavMobile';
import MainSearchBar from 'components/common/mainSearch/MainSearchBar';
//CONTAINERS
//import ForumSearchBarV2 from 'containers/searchBar/ForumSearchBarV2';

//LIBS
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Banner from 'components/common/Banner';

//CONTEXTS
import { AuthContext } from 'context/authContext';
// eslint-disable-next-line no-unused-vars
import { ArrowNarrowLeftIcon, BellIcon, ChatIcon, ShoppingBagIcon, UserIcon } from '@heroicons/react/solid';
import { StoreContext } from 'context/storeContext';

import Image from '../../components/common/Image';
import MainNavUser from '../../components/common/MainNavUser';

function ContainerLayout({ children, noContain }) {
    //HOOKS
    const scrollDirection = useScroll();
    const [openedUserMenu, setOpenedUserMenu] = useState(false);

    const navigate = useNavigate();
    const [authState] = useContext(AuthContext);

    const discussionNotifications = useNotifications(getNotifications);
    const location = useLocation();
    // eslint-disable-next-line no-unused-vars
    /*  const [isHomeLocation, setIsHomeLocation] = useState(false);
    const [isSearchLocation, setIsSearchLocation] = useState(false); */

    //contexts
    const store = useContext(StoreContext);

    const handleGoToNotifications = useCallback(() => {
        if (authState?.isLogged) navigate('/dashboard/notifications');
        else document.getElementById('button-login')?.click();
        //eslint-disable-next-line
    }, [authState, navigate]);

    const handleOpenUserMenu = useCallback(() => {
        setOpenedUserMenu(openedUserMenu ? false : true);
    }, [openedUserMenu]);

    useEffect(() => {
        if (openedUserMenu) document.querySelector('body').style.overflow = 'hidden';
        else document.querySelector('body').style.overflow = 'auto';
    }, [openedUserMenu]);

    const openCloseSlideAnimationContent = useSpring({
        transform: openedUserMenu ? `translateX(16rem)` : 'translateX(0rem)',
        config: {
            duration: 225,
        },
    });

    const handleGoToSearch = useCallback(() => {
        if (!location.pathname.includes('jobs')) {
            navigate('/search');
        }
        //eslint-disable-next-lines
    }, [navigate, location]);

    /*  const handleGoToCloneAI = useCallback(() => {
        navigate('/dashboard/clone-ai');
    }, [navigate]); */

    return (
        <Fragment>
            {noContain && (
                <div
                    onClick={() => {
                        /*   setOpenedJobDetail(false); */
                        /* closeJobDetail();
                        window.history.replaceState({}, '', `/jobs`); */
                    }}
                    className={`fixed top-0 left-0 w-full h-full backdrop-filter backdrop-blur-xs bg-blueMain/10 flex justify-end`}
                />
            )}
            <InAllLayouts />
            <MainNavUser handleOpenUserMenu={handleOpenUserMenu} isOpen={openedUserMenu} />
            <div className={`hidden sm:block ${noContain ? '' : 'container-fluid mx-auto'} sticky z-20 h-16 max-h-16 w-full px-4 bg-white top-0`}>
                <div className={`${noContain ? 'w-5/12' : ''}`}>
                    <MainNavSearch />
                </div>
            </div>

            <animated.div style={openCloseSlideAnimationContent} className="flex flex-col min-h-screen container-fluid">
                <div className="flex-grow">
                    {/*   MENU DESKTOP */}

                    {/*   MENU MOBILE */}
                    <div
                        className={`sm:hidden sticky w-full z-10 pt-2 px-4 bg-white transition-all duration-300 ease-out ${
                            scrollDirection === 'down' ? '-top-[65px]' : 'top-0'
                        }`}
                    >
                        <div className={`flex justify-between items-center gap-2 pb-2`}>
                            <div className="w-auto flex">
                                <div className="sm:hidden">
                                    {location.pathname.includes('/profil') ? (
                                        <div className="flex">
                                            <button
                                                className="py-0 px-0 text-purpleSkills link_basic"
                                                onClick={() => {
                                                    navigate(-1);
                                                }}
                                            >
                                                <ArrowNarrowLeftIcon className="w-6 h-6" />
                                            </button>
                                        </div>
                                    ) : (
                                        <>
                                            {authState.isLogged ? (
                                                <button id="button-profil" onClick={handleOpenUserMenu} className="flex">
                                                    <div className="text-purpleSkills font-semibold text-sm flex flex-col items-center w-auto">
                                                        {authState.isLogged ? (
                                                            authState.me?.profile?.avatar?.url ? (
                                                                <Image
                                                                    src={authState.me?.profile?.avatar?.url}
                                                                    alt={authState.me?.profile?.pseudo}
                                                                    loading="false"
                                                                    className="rounded-full w-8 h-8"
                                                                />
                                                            ) : (
                                                                <img
                                                                    className="rounded-full w-8 h-8"
                                                                    src={`${config.publicDir}skillsLogo.jpg`}
                                                                    alt={authState.me?.profile?.pseudo}
                                                                />
                                                            )
                                                        ) : (
                                                            <UserIcon className="block w-6 h-6" />
                                                        )}
                                                    </div>
                                                </button>
                                            ) : (
                                                <Link to={isMobile() ? '/home' : '/'}>
                                                    <img src={`${config.publicDir}logo_skillsmobile.png`} alt="logo" className="h-8 w-auto" />
                                                </Link>
                                            )}
                                        </>
                                    )}
                                </div>

                                <Link className="hidden sm:block" to={isMobile() ? '/home' : '/'}>
                                    <img src={`${config.publicDir}logo_skillsmobile.png`} alt="logo" className="h-8 w-auto" />
                                </Link>
                            </div>
                            <div className="flex gap-4 w-3/4">
                                <div className="w-full" onClick={handleGoToSearch}>
                                    <MainSearchBar isInNavBar={true} />
                                </div>
                            </div>
                            <div className="w-7 flex">
                                <button id="button-notifications" onClick={handleGoToNotifications}>
                                    <div className="relative text-graySkills text-sm flex flex-col items-center w-auto">
                                        <div className="">
                                            {authState.isLogged && (
                                                <>
                                                    {(store.get('notifications')?.length > 0 || discussionNotifications) && (
                                                        <>
                                                            <div className="sm:hidden flex justify-center items-center text-white text-xs font-semibold absolute -top-2 -right-3 bg-red p-1 h-5 w-5 rounded-full">
                                                                <span>{discussionNotifications + store.get('notifications').length}</span>
                                                            </div>
                                                            {store.get('notifications')?.length > 0 && (
                                                                <div className="hidden sm:flex justify-center items-center text-white text-xs font-semibold absolute -top-2 -right-3 bg-red p-1 h-5 w-5 rounded-full">
                                                                    <span>{store.get('notifications').length}</span>
                                                                </div>
                                                            )}
                                                        </>
                                                    )}
                                                </>
                                            )}
                                            <ChatIcon className="block w-7 h-7" />
                                            {/* <BellIcon className="block w-6 h-6" /> */}
                                        </div>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={`relative ${noContain ? 'w-[51%]' : 'container mx-auto'} px-2 sm:px-4`}>
                        <div className="w-full">{children}</div>
                    </div>
                </div>
                {!authState.isLogged && (
                    <>
                        <div
                            onClick={(e) => {
                                //console.log('banner');
                            }}
                            className={`z-20 sticky sm:bottom-0 transition-all duration-300 ease-out bottom-[68px]`}
                        >
                            <Banner />
                        </div>
                    </>
                )}
                <MainNavMobile />
            </animated.div>
            {/* {authState.isLogged && (
                <button onClick={handleGoToCloneAI} className={`sm:hidden z-10 fixed bottom-20 right-4 w-12 cursor-pointer`}>
                    <img src={`${config.publicDir}logo_ai_mobile.svg`} alt="ai_logo" />
                </button>
            )} */}
        </Fragment>
    );
}

export default ContainerLayout;
