import * as yup from 'yup';

const schema = yup.object().shape({
    email: yup.string().email("L'adresse email n'est pas au bon format").required("L'adresse email est requise"),
    //password 6 caractères minimum
    password: yup.string().required('Le mot de passe est requis').min(6, 'Au moins 6 caractères'),
    typeInscription: yup.string(),
    /*  confirm: yup
    .string()
    .required('La confirmation du mot de passe est requise')
    .oneOf([yup.ref('password'), null], 'Les mots de passe ne correspondent pas'), */
    firstname: yup.string().when('typeInscription', {
        is: 'realnames',
        then: yup.string().required('Le prénom est requis'),
    }),
    lastname: yup.string().when('typeInscription', {
        is: 'realnames',
        then: yup.string().required('Le nom est requis'),
    }),
    society: yup.string().when('typeInscription', {
        is: 'society',
        then: yup.string().required('Le nom de la société est requis'),
    }),
    cguConsent: yup
        .bool() // use bool instead of boolean
        .oneOf([true], "Vous devez accepter les conditions générales d'utilisation"),
    /* pseudo: yup.string().required("Le pseudo est requis") */
});

export default schema;
