import { useEffect, useContext } from 'react';

//API
import { useQuery } from 'react-query';
// eslint-disable-next-line no-unused-vars
import { getMatchingNeedsNotifications } from 'api/matchingNeeds';

//CONTEXTS
import { SocketContext } from 'context/socketContext';
import { AuthContext } from 'context/authContext';
import { StoreContext } from 'context/storeContext';

function NotificationsNeeds() {
    const socket = useContext(SocketContext);
    const [authState] = useContext(AuthContext);
    const store = useContext(StoreContext);

    /*  const services = useQuery(['services', user?.data?.id], () => getServicesByUserForProfile(user?.data?.id), {
        enabled: false,
    }); */
    //API
    const matchingNeedsNotifications = useQuery(['matchingnotifications'], () => getMatchingNeedsNotifications(), {
        enabled: false,
    });

    useEffect(() => {
        if (authState.isLogged) {
            const refreshNotifications = () => {
                matchingNeedsNotifications.refetch();
            };

            socket.on('notificationsRefresh', refreshNotifications);

            return () => {
                socket.off('notificationsRefresh', refreshNotifications);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (matchingNeedsNotifications.isSuccess) {
            store.set({ type: 'add', key: 'notificationsNeeds', value: matchingNeedsNotifications.data });
        }
        //eslint-disable-next-line
    }, [matchingNeedsNotifications.data]);

    return (
        <>
            {authState.isLogged && matchingNeedsNotifications.isSuccess && (
                <>
                    {matchingNeedsNotifications.data.counter > 0 && (
                        <div className="flex justify-center items-center absolute top-1 right-4 sm:right-0 bg-red h-5 w-5 rounded-full text-white text-xs">
                            <span>{matchingNeedsNotifications.data.counter}</span>
                        </div>
                    )}
                </>
            )}
        </>
    );
}

export default NotificationsNeeds;
