//STYLES
import 'App.css';
//LAYOUT
import DashboardLayout from 'pages/layouts/Dashboard';
//LIBS
import { useParams } from 'react-router-dom';
//COMPONENTS
// import Header from "components/common/Header";
import ListBuy from 'containers/buysell/ListBuy';

function Visios(props) {
    //HOOKS
    const params = useParams();

    return (
        <DashboardLayout>
            <div className="App">
                <h1 className="h1 mt-4">Mes achats</h1>
                <main>{!params.slug && <ListBuy />}</main>
                <h1 className="h1 mt-4">Mes ventes</h1>
            </div>
        </DashboardLayout>
    );
}

export default Visios;
