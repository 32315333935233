import { useEffect, useState } from 'react';

//LIBS
import { toast } from 'react-toastify';

//API
import { deleteNeed } from 'api/matchingNeeds';

//ICONS
import { TrashIcon } from '@heroicons/react/solid';

//COMPONENTS
import Modal from 'components/common/Modal';

function CardNeedDashboard({ need, refetch }) {
    const [item, setItem] = useState(need);
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

    function handleDelete() {
        deleteNeed(item.id).then((data) => {
            toast('Besoin supprimé', { type: 'success' });
            refetch();
            setOpenConfirmDelete(false);
        });
    }

    useEffect(() => {
        setItem(need);
        //eslint-disable-next-line
    }, [, need]);

    return (
        <>
            <Modal
                title="Supprimer le besoin"
                open={openConfirmDelete}
                onClose={{
                    onClick: () => {
                        setOpenConfirmDelete(false);
                    },
                }}
                onConfirm={{
                    label: 'Supprimer',
                    onClick: handleDelete,
                }}
            >
                <p>Voulez-vous vraiment supprimer ce besoin ?</p>
            </Modal>
            <div className="card card-service w-full p-2 bg-white rounded-3xl flex flex-row justify-between items-center sm:gap-4 h-auto">
                <div className="card-header text-main text-base mb-4 font-bold text-purpleSkills">
                    <span>{item.title}</span>
                </div>

                <div className="flex flex-nowrap justify-end">
                    <div className="card-actions flex flex-nowrap flex-col sm:flex-row items-center justify-end w-auto gap-2">
                        <button onClick={() => setOpenConfirmDelete(true)} className="linkCard linkCard_Icon flex text-danger-500 ml-2">
                            <>
                                <TrashIcon className="w-5 h-5" /> {/* Supprimer */}
                            </>
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CardNeedDashboard;
