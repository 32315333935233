import { useContext } from 'react';

//LIBS
import axios from 'axios';

//CONTEXTS
import { HeadlessContext } from '../index.js';

function authHeader() {
    const token = localStorage.getItem('hlToken');
    if (token) {
        return {
            Authorization: 'Bearer ' + token,
        };
    } else {
        return {};
    }
}

export default function useRequest() {
    const store = useContext(HeadlessContext);
    const config = store.get('config');

    const API = axios.create({
        baseURL: config?.api.baseUrl,
    });

    const get = async (params) => {
        const response = await API.get(`${params.route}`, {
            headers: authHeader(),
        });
        return response.data;
    };

    const post = async (params) => {
        const response = await API.post(`${params.route}`, params.data, {
            headers: authHeader(),
        });
        return response.data;
    };

    return { get, post };
}
