import { useEffect } from 'react';

//LIBS
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';

//API
import { putPassword } from 'api/users';

//FORMS
import schema from 'components/forms/yup/auth';

//STYLES
import 'App.css';

//LAYOUT
import FullLayout from 'pages/layouts/Full';
import FieldInput from 'components/forms/fields/input';

function ResetPassword() {
    const params = useParams();

    //HOOKS
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        setValue('id', params.id);
        //eslint-disable-next-line
    }, []);

    function onSubmit(data) {
        if (errors.length > 0) return false;

        const put = putPassword(data);
        put.then((res) => {
            if (res.status === 200) {
                toast('Les identifiants ont bien été modifiés', {
                    type: 'success',
                });
                reset();
            } else {
                toast('Une erreur est survenue', { type: 'danger' });
            }
        }).catch((err) => {
            toast('Utilisateur non trouvé', { type: 'warning' });
        });
    }
    return (
        <FullLayout>
            <div className="App">
                <main className="m-auto mt-4 w-6/12">
                    <h1 className="h1 mb-4">Réinitialisation du mot de passe</h1>
                    <p className="p text-blue-800">Vous avez demandé à réinitialiser votre mot de passe.</p>
                    <p className="p text-blue-800">Remplissez le formulaire ci-dessous pour mettre à jour vos identifiants de connexion.</p>

                    <form className="m-auto w-full mt-4" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                        <FieldInput inputForm="floating" name="email" label="Adresse email" register={register} errors={errors} />

                        <FieldInput inputForm="floating" name="password" type="password" label="Nouveau mot de passe" register={register} errors={errors} />

                        <FieldInput
                            inputForm="floating"
                            name="confirm"
                            type="password"
                            label="Confirmation du mot de passe"
                            register={register}
                            errors={errors}
                        />

                        <div className="mt-4 mx-0">
                            <input type="submit" value="Réinitialiser le mot de passe" className="mt-8 mx-0 button button-secondary" />
                        </div>
                    </form>
                </main>
            </div>
        </FullLayout>
    );
}

export default ResetPassword;
