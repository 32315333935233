import API from './axios.config';
import authHeader from 'services/Auth.Header';

/* async function getAllMatchingNeeds(params, isLogged) {
    let slug = '';
    if (isLogged) {
        slug = 'matchingNeeds/me/all' + params;
    } else {
        slug = 'matchingNeeds/all' + params;
    }
    const response = await API.get(slug + params, {
        headers: isLogged ? authHeader() : {},
    });
    return response;
} */

/* async function getAllMatchingNeeds(params, isLogged) {
    const response = await API.get(`matchingNeeds/all${params}`, {
        headers: isLogged ? authHeader() : {},
    });
    return response.data;
} */

//getAllMatchingNeedsByMe
async function getAllMatchingNeedsByMe(params) {
    const response = await API.get('matchingNeeds/me' + params, { headers: authHeader() });
    return response.data;
}

//Pas de listings en mode non connecté
async function getAllMatchingNeedsForMe(params, isLogged) {
    let slug = isLogged ? 'matchingNeeds/me/all' : 'matchingNeeds/me/all';
    const response = await API.get(`${slug}${params}`, {
        headers: authHeader(),
    });
    return response.data;
}

async function getAllMatchingForMe(params, isNeeds, isUsers, isLogged) {
    let slug = '';

    if (isNeeds && isLogged) {
        //console.log('isNeeds');
        slug = 'matchingNeeds/me/all';
    }
    if (isUsers && isLogged) {
        slug = 'matchingNeeds/users/me/all';
    }

    //console.log(isNeeds);
    //console.log(isUsers);

    //let slug = isLogged ? 'matchingNeeds/me/all' : 'matchingNeeds/me/all';
    const response = await API.get(`${slug}${params}`, {
        headers: authHeader(),
    });
    return response.data;
}

async function getAllMatchingUsersForMe(params, isLogged) {
    let slug = isLogged ? 'matchingNeeds/users/me/all' : 'matchingNeeds/users/me/all';
    //console.log(params);
    const response = await API.get(`${slug}${params}`, {
        headers: authHeader(),
    });
    return response.data;
}

async function getAllMatchingNeedsReceived(params) {
    const response = await API.get('matchingNeeds/received' + params, {
        headers: authHeader(),
    });
    return response.data;
}

async function getMatchingNeed(slug) {
    const response = await API.get('matchingNeeds/' + slug);
    return response.data;
}

async function getMatchingNeedsNotifications() {
    const response = await API.get('matchingNeeds/notifications', {
        headers: authHeader(),
    });
    return response.data;
}
/* async function getMatchingNeed(id) {
    const response = await API.get('matchingNeeds/pull/' + id);
    return response.data;
} */

async function archiveAllNotifications(data) {
    const response = await API.put('matchingNeeds/notifications/archiveall', data, {
        headers: authHeader(),
    });
    return response.data;
}

async function shareNeedToUser(data) {
    const response = await API.put('matchingNeeds/shareToUser', data, {
        headers: authHeader(),
    });
    return response;
}

async function createMatchingNeed(data) {
    const response = await API.post('matchingNeeds/create', data, {
        headers: authHeader(),
    });
    return response;
}

/* async function updateMatchingNeed(id, data) {
    const response = await API.put('matchingNeeds/update/' + id, data, {
        headers: authHeader(),
    });
    return response;
}
 */
async function deleteNeed(id) {
    const response = await API.delete('matchingNeeds/' + id, {
        headers: authHeader(),
    });
    return response;
}

export {
    getAllMatchingNeedsByMe,
    getAllMatchingNeedsForMe,
    getAllMatchingUsersForMe,
    getAllMatchingForMe,
    getAllMatchingNeedsReceived,
    getMatchingNeed,
    getMatchingNeedsNotifications,
    archiveAllNotifications,
    shareNeedToUser,
    createMatchingNeed,
    deleteNeed,
};
