import { useContext, useState } from 'react';
//COMPONENTS
import SideNav from 'components/common/nav/SideNav';
import Tabs from 'components/tabs/Tabs';
import Modal from 'components/common/Modal';
import SEO from 'components/Seo';
//CONTEXTS
import { AuthContext } from 'context/authContext';
//HOOKS
import useInfinitePosts from 'hooks/useInfinitePosts';

//LAYOUT
import ContainerLayout from 'pages/layouts/Container';

//UTILS
import { PageContentFadingAnimation } from 'utils/animations';
import WelcomeCloneAi from '../components/common/WelcomeCloneAi';
import { useLocation } from 'react-router-dom';
import ForumSearchBarV2 from 'containers/searchBar/ForumSearchBarV2';


function Forum() {
    const [authState] = useContext(AuthContext);
    const location = useLocation();
    const [open, setOpen] = useState(false);
    const [fetchedTabs, setFetchedTabs] = useState({});
   
    const handleTabChange = (index) => {
        if (index && !fetchedTabs[index]) {
            setFetchedTabs(prev => ({ ...prev, [index]: true }));
        }
    };

    const isTabFetched = (tabKey) => !!fetchedTabs[tabKey];

    const infinitePostsTendance = useInfinitePosts(true, isTabFetched('tab_tendance'));
    const infinitePostsNew = useInfinitePosts(false, isTabFetched('tab_new'));


    const tabTendance = (
        <>
            {infinitePostsTendance}
        </>
    );

    const tabNew = (
        <>
            <Modal
                open={open}
                title={"Créer un post"}
                onClose={{
                    label: 'Fermer',
                    onClick: () => setOpen(false),
                }}
                onConfirm={{
                    label: 'Créer',
                    onClick: () => setOpen(false),
                }}
            >
                <ForumSearchBarV2
                    isButton={true}
                    title={false}
                    className="flex text-2xl font-bold linkCard gap-2 w-full px-6 py-2"
                    typeForum="question"
                    /*   icon="question" */
                    iconClassName="w-7 h-7"
                    isCreatePost={true}
                    handleClose={() => setOpen(false)}
                />
            </Modal>
            <div className="flex justify-center sticky w-full cursor-pointer flex-col items-center bg-white mt-2 p-2 sm:p-6 rounded-3xl">
                <div className="relative mb-4 flex flex-col justify-center bg-slate-900 overflow-hidden">
                    <div className="w-full mx-auto ">
                        <div className="text-center">
                            <div className="font-extrabold text-lg md:text-xl [text-wrap:balance] bg-clip-text text-purpleSkills bg-gradient-to-r from-slate-200/60 to-50% to-slate-200">
                                <span className="text-indigo-500 inline-flex flex-col h-auto">
                                    <ul className="block leading-tight [&_li]:block text-center h-auto">
                                        <li>Des réponses rapides, sûres par l'IA et des experts</li>
                                    </ul>
                                </span>
                            </div>                                
                        </div>
                    </div>
                </div>
                <div className="w-[60%] sm:w-[40%]">
                    <button
                        className="shadow-blueMain/60 hover:bg-white hover:text-blueMain shadow-lg flex items-center text-md sm:text-lg font-bold rounded-3xl justify-center bg-blueMain text-white gap-2 w-full px-4 py-2"
                        type="button"
                        onClick={(e) => {
                            e.preventDefault();
                            authState.isLogged ? setOpen(true) :
                            document.getElementById('button-login')?.click();
                        }}
                    >
                        <span>Posez votre Question</span>
                    </button>
                </div>
            </div>
            {infinitePostsNew}
        </>
    );

    const tabs = [
        { label: 'Dernières questions', key: 'tab_new', content: tabNew},
        { label: 'Populaires', key: 'tab_tendance', content: tabTendance},
    ];

    return (
        <>
            <ContainerLayout>
                <SEO
                    title={"Partagez vos connaissances et échangez avec les experts du domaine sur Skillsmarket Forum AI"}
                    description={"Découvrez une communauté dynamique d'experts en intelligence artificielle sur Skillsmarket Forum AI. Partagez vos idées, posez vos questions et explorez les dernières tendances dans le domaine de l'IA."}
                    type="summary_large_image"
                />
                <div className="container">
                    <div className="sm:grid sm:grid-cols-12 sm:gap-8 pb-4">
                        <div className="hidden sm:block sm:col-span-3">
                            <SideNav />
                        </div>
                    
                        <div className="sm:col-span-9 lg:col-span-7">
                            <main>
                                <PageContentFadingAnimation duration={250}>
                                    <Tabs tabs={tabs} onTabChange={handleTabChange} />
                                </PageContentFadingAnimation>
                            </main>
                        </div>
                    </div>
                </div>
            </ContainerLayout>
          
            {!authState.isLogged && location.pathname === '/inscription-clone-ai' &&  (
                <WelcomeCloneAi/>
            )}
        </>
    );
}

export default Forum;