//LIBS
//import _ from 'lodash';
import { Link } from 'react-router-dom';
//FUNCTIONS
import { countAnswers } from 'utils/functions';
//CONTETXS
import { useContext } from 'react';
import { AuthContext } from 'context/authContext';
//API
import { postCounter } from 'api/counters';

//ICONS
import { BellIcon, ChatAlt2Icon, EyeIcon } from '@heroicons/react/solid';
import { BellIcon as BellIconOutline } from '@heroicons/react/outline';

//COMPONENTS
import CountersReaction from './CountersReaction';
//import OptionsMenu from '../../components/common/OptionsMenu';

//HOOKS
import useHasCounter from '../../hooks/useHasCounter';

function CountersRoom(props) {
    const [authState] = useContext(AuthContext);
    const isSubscribed = useHasCounter(props.room.data.counters, 'subscribe_room', props.room.data._id);

    function toggleSubscription() {
        if (authState.isLogged) {
            postCounter({ type: 'subscribe_room', to: props.room.data.id }).then(() => {
                props.room.refetch();
            });
        } else document.getElementById('button-login')?.click();
    }

    /*  function closeOptionsMenu(room) {
        const els = document.getElementsByClassName('optionsMenu');
        [].forEach.call(els, function (el) {
            if (el.id !== `optionsMenu_${room.id}`) {
                el.classList.add('hidden');
            }
        });
    }
    function handleOptionsMenu(room) {
        closeOptionsMenu(room);
        console.log(room);
        document.getElementById(`optionsMenu_${room.id}`).classList.toggle('hidden');
    } */

    return (
        <>
            <div className="relative mx-2">
                <div className="flex flex-col justify-end w-full">
                    <div className="flex flex-row sm:gap-x-2 justify-between items-center">
                        {props.reactions !== false && (
                            <CountersReaction
                                btnSize="w-5 h-5 sm:w-7 sm:h-7"
                                counters={props.room.data.counters}
                                room={props.room.data}
                                refetch={props.room.refetch}
                                listingQueryKeys={props.listingQueryKeys}
                                type="room"
                                to={props.room.data._id}
                                userTo={props.room.data.user?.id}
                                cssButton={'footerCardFrontRoom min-w-[2rem]'}
                            />
                        )}

                        <Link to={'/room/' + props.room.data?.slug} className="footerCardFrontRoom">
                            <div className="flex flex-row items-center">
                                <span className="">
                                    <ChatAlt2Icon className="w-5 h-5 sm:w-7 sm:h-7" />
                                </span>

                                <span
                                    className={`counter-icon_number text-sm sm:text-base px-1 sm:px-4 min-w-[2rem]`} /* style={{ minWidth: 'calc(1em + 24px)' }} */
                                >
                                    {countAnswers(props.room.data.answers)}
                                </span>
                            </div>
                        </Link>
                        <Link to={'/room/' + props.room.data?.slug} className="footerCardFrontRoom">
                            <div className="flex flex-row items-center">
                                <span className="inline-flex">
                                    <EyeIcon className="w-5 h-5 sm:w-7 sm:h-7" />
                                </span>
                                <span
                                    className={`counter-icon_number text-sm sm:text-base px-1 sm:px-4 min-w-[2rem]`} /* style={{ minWidth: 'calc(1em + 24px)' }} */
                                >
                                    {props.room.data.views}
                                </span>
                            </div>
                        </Link>
                        <button
                            onClick={toggleSubscription}
                            className={`hidden sm:block text-graySkills hover:text-blueMain ${isSubscribed ? 'active text-blueMain' : ''} footerCardFrontRoom`}
                        >
                            {isSubscribed ? (
                                <>
                                    <div className="text-blueMain" /* style={{ minWidth: 'calc(1em + 24px)' }} */>
                                        <BellIcon className="w-5 h-5 sm:w-7 sm:h-7" />
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="" /* style={{ minWidth: 'calc(1em + 24px)' }} */>
                                        <BellIconOutline className="w-5 h-5 sm:w-7 sm:h-7" />
                                    </div>
                                </>
                            )}
                        </button>
                        {/* <div className="relative footerCardFrontRoom">
                            <button className="flex" onClick={() => handleOptionsMenu(props.room.data)}>
                                <DotsVerticalIcon className="h-5 w-5 sm:w-7 sm:h-7 hover:text-blueMain" />
                            </button>
                            <OptionsMenu room={props.room.data} refresh={props.room.refetch} type="_room" />
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    );
}

export default CountersRoom;
