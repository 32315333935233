import { useEffect } from 'react';

//API
import { getSubscribers } from 'api/users';
import { useQuery } from 'react-query';

//COMPONENTS
import Header from 'components/common/Header';

//CONTAINERS
import CardProfilSearch from 'containers/profils/CardProfilSearch';

//LAYOUT
import DashboardLayout from 'pages/layouts/Dashboard';

//STYLES
import 'App.css';

//UTILS
import { PageContentFadingAnimation } from 'utils/animations';

function Subscribers() {
    //API
    const subscribers = useQuery(
        ['subscribers'],
        () => {
            return getSubscribers();
        },
        { enabled: true }
    );

    useEffect(() => {
        //eslint-disable-next-line
    }, []);

    return (
        <DashboardLayout>
            <PageContentFadingAnimation duration={225} className="App">
                <Header>
                    <h1 className="h1 mx-4 sm:mx-0">Mes abonnés</h1>
                </Header>

                <main>
                    {subscribers.isSuccess && (
                        <>
                            {subscribers?.data?.length > 0 ? (
                                <div className="card_profile mx-4 sm:mx-0">
                                    {subscribers?.data?.map((counter, index) => (
                                        <div key={index} className="my-2">
                                            <CardProfilSearch user={counter.user} refetch={subscribers.refetch} />
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <div className="text-center text-sm text-gray-500 mt-4 mx-4 sm:mx-0">
                                    <p>Vous n'avez pas d'abonné pour le moment</p>
                                </div>
                            )}
                        </>
                    )}
                </main>
            </PageContentFadingAnimation>
        </DashboardLayout>
    );
}

export default Subscribers;
