import React, { useContext, useRef, useEffect, useCallback, useState } from "react";
//LIBS
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import videoOptions from "components/forms/options/video";
//API
import { putVideo, putVideoPublish } from "api/videos";
//CONTEXTS
import { FormContext } from "context/formContext";
//LIBS
import { toast } from "react-toastify";
//HOOKS
import useCurrentForm from "hooks/useForm";
//FORMS
import schema from "components/forms/yup/product";
//STYLES
import "components/forms/forms.css";
//COMPONENTS
import FieldInput from "components/forms/fields/input";
import Textarea from "components/forms/fields/Textarea";
import Tags from "components/forms/fields/Tags";
import FieldFiles from "components/forms/fields/Files";

function FormVideo(props) {
    const [itemId, setItemId] = useState(null);
    const [readyToWatch, setReadyToWatch] = useState(false);
    const [descriptionInitialValue, setDescriptionInitialValue] = useState("");
    const [tagsInitialValue, setTagsInitialValue] = useState([]);
    const [coverUrl, setCoverUrl] = useState(null);
    const [medias, setMedias] = useState([]);

    //CONTEXTS
    const [formContextState, formContextDispatch] = useContext(FormContext);
    //REFS
    const currentForm = useRef(null);
    const seeLink = useRef(null);
    //HOOKS
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        setValue,
        getValues,
    } = useForm({
        resolver: yupResolver(schema),
    });

    //DISPATCH
    const dispatchAction = useCallback(() => {
        switch (formContextState.action) {
            case "save":
                currentForm.current.click();
                break;
            case "tooglePublish":
                togglePublish(itemId);
                break;
            default:
        }
        // eslint-disable-next-line
    }, [formContextState.action]);

    //EFFECTS
    useEffect(() => {
        if (readyToWatch) props.refetch();
        //eslint-disable-next-line
    }, [readyToWatch]);

    useEffect(() => {
        dispatchAction();
        //eslint-disable-next-line
    }, [dispatchAction]);

    useEffect(() => {
        if (props.video.isSuccess) {
            const video = props.video.data;

            setReadyToWatch(true);
            setItemId(video.id);

            seeLink.current = "/video/" + video?.slug;
            formContextDispatch({
                type: "publish",
                status: video?.publish,
                seeLink: seeLink.current,
            });

            setCoverUrl(video?.cover?.url);
            setValue("title", video?.title);
            setValue("description", video?.description);
            setDescriptionInitialValue(video?.description);
            setMedias(video?.medias);
            setValue("video", video?.video);

            setTagsInitialValue(video?.tags);

            setValue("price", video?.price || 0);
            setValue("reduction", video?.reduction || 0);

            setTimeout(() => {
                formContextDispatch({ type: "change", status: false });
            }, 0);
        } else props.refetch();
        //eslint-disable-next-line
    }, [props.video]);

    useCurrentForm(readyToWatch, watch);

    //FUNCTIONS
    const onSubmit = (data) => {
        if (errors.length > 0) return false;

        const fd = new FormData();
        for (var k in data) {
            if (data[k] !== undefined) {
                let item = null;
                item = data[k];

                if (k === "tags") {
                    item = JSON.stringify(data[k]);
                }
                if (k === "cover" || k === "medias") {
                    let files = data[k];
                    for (let i = 0; i < files.length; i++) {
                        fd.append(k, files[i]);
                    }
                } else fd.append(k, item);
            }
        }
        const put = putVideo(itemId, fd, function (e) {
            let progress = (e.loaded / e.total) * 100;
            formContextDispatch({ type: "progress", status: progress });
        });
        put.then((res) => {
            if (res.status === 200) {
                toast("Espace vidéo mis à jour", { type: "success" });
                // refresh();
                setTimeout(() => {
                    formContextDispatch({ type: "change", status: false });
                    setTimeout(() => {
                        formContextDispatch({ type: "progress", status: 0 });
                    }, 400);
                }, 100);
            } else {
                toast("Une erreur est survenue", { type: "danger" });
            }
        });
    };

    const togglePublish = useCallback(
        (itemId) => {
            if (errors.length > 0) return false;

            const put = putVideoPublish(itemId);
            put.then((res) => {
                const msg = res.publish ? "Espace vidéo publié" : "Espace  vidéo dépublié";

                toast(msg, { type: "success" });

                formContextDispatch({
                    type: "publish",
                    status: res.publish,
                    seeLink: seeLink.current,
                });
                props.refetch();
            });
        },
        //eslint-disable-next-line
        [formContextDispatch]
    );

    //OPTIONS
    function dispatchOptionsActions(action, name, value) {
        switch (action) {
            case "onValidateOption":
                return onValidateOption(name, value);
            case "onChangeOption":
                return onChangeOption(name, value);
            default:
        }
    }
    //FUNCTIONS OPTIONS
    function onChangeOption(name, value) {
        if (name === "videoUrl") setValue("video", value);
    }
    function onValidateOption(name, value) {
        return true;
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="form" autoComplete="off" encType="multipart/form-data">
            <div className="w-full">
                <FieldFiles
                    name="cover"
                    type="single"
                    label="Votre image de couverture"
                    multiple={false}
                    register={register}
                    initValue={coverUrl}
                    readyToWatch={readyToWatch}
                    onChange={(value) => {
                        setValue("cover", value);
                    }}
                />
            </div>

            <FieldInput name="title" label="Titre" errors={errors} register={register} />

            <div className="flex w-full gap-2">
                <FieldInput name="price" label="Prix du produit (€)" type="number" min="0" defaultValue={0} size="w-1/2" errors={errors} register={register} />
                <FieldInput name="reduction" label="Appliquer une réduction (€)" type="number" min="0" defaultValue={0} size="w-1/2" errors={errors} register={register} />
            </div>

            <Textarea name="description" errors={errors} register={register} initValue={descriptionInitialValue} onChange={(value) => setValue("description", value)} />

            <div className="w-full">
                <FieldFiles
                    name="medias"
                    type="multiple"
                    label="Déposez ou cliquez ici pour uploader vos vidéos"
                    multiple={true}
                    register={register}
                    initValue={medias}
                    readyToWatch={readyToWatch}
                    onChange={(value) => {
                        setValue("medias", value);
                    }}
                />
            </div>

            <FieldInput name="video" label="Vidéo de présentation" errors={errors} register={register} option={videoOptions.video} dispatchOptionsActions={dispatchOptionsActions} getValues={getValues} readyToWatch={readyToWatch} />

            <Tags
                name="tags"
                register={register}
                initValue={tagsInitialValue}
                placeholder="Tapez sur Entrée pour ajouter un tag"
                onChange={(value) => {
                    setValue("tags", value);
                }}
                readyToWatch={readyToWatch}
            />

            <input ref={currentForm} type="submit" className="hidden" />
        </form>
    );
}

export default FormVideo;
