import { Fragment } from 'react';
import MainNavSearch from 'components/common/MainNavSearch';
import Banner from 'components/common/Banner';

import InAllLayouts from 'pages/layouts/InAllLayouts';

//CONTEXTS
import { useContext } from 'react';
import { AuthContext } from 'context/authContext';

function RoomLayout({ children }) {
    const [authState] = useContext(AuthContext);

    return (
        <Fragment>
            <InAllLayouts />
            <div className="flex flex-col min-h-screen container-fluid">
                <div className="hidden sm:block sticky top-0 z-20 h-16 max-h-16 container-fluid w-full mx-auto px-0 bg-white">
                    <MainNavSearch />
                </div>
                <div className="flex-grow mx-4 sm:mx-0 mb-28">
                    <div className="container relative sm:mx-auto sm:px-4">
                        <div className="w-full">{children}</div>
                    </div>
                </div>
            </div>
            {!authState.isLogged && (
                <>
                    <div className={`z-20 sticky bottom-[54px] sm:bottom-0`}>
                        <Banner />
                    </div>
                </>
            )}
        </Fragment>
    );
}

export default RoomLayout;
