//API
import { useQuery } from 'react-query';
import { getProduct } from 'api/products';
//STYLES
import 'App.css';
//LAYOUT
import DashboardLayout from 'pages/layouts/Dashboard';
//LIBS
import { useParams } from 'react-router-dom';
//COMPONENTS
import Header from 'components/common/Header';
import ListProducts from 'containers/products/ListProducts';
import AddProduct from 'containers/products/AddProduct';
import FormProduct from 'components/forms/product';

function Products(props) {
    //HOOKS
    const params = useParams();

    //API
    const currentProduct = useQuery(
        ['product', params.slug],
        (params) => {
            return getProduct(params.queryKey[1]);
        },
        { enabled: false }
    );

    return (
        <DashboardLayout>
            <div className="App">
                {params.slug ? (
                    <>
                        {currentProduct.isSuccess && (
                            <Header>
                                <h1 className="h1">{currentProduct.data?.title}</h1>
                            </Header>
                        )}
                    </>
                ) : (
                    <Header>
                        <h1 className="h1">Mes produits</h1>
                    </Header>
                )}

                <main>
                    {!params.slug && <ListProducts />}
                    {params.slug && <FormProduct slug={params.slug} product={currentProduct} refetch={currentProduct.refetch} />}
                    <AddProduct />
                </main>
            </div>
        </DashboardLayout>
    );
}

export default Products;
