import { useState, useRef } from 'react';
//CONFIG
import config from 'utils/constants';

//LIBS
import Slider from 'react-slick';
import ReactPlayer from 'react-player';
import { useNavigate } from 'react-router-dom';

//COMPONENTS
import Image from 'components/common/Image';
import Modal from 'components/common/Modal';

//HOOKS
//import useOnScreen from 'hooks/useOnScreen';
import useIntersectionObserver from 'hooks/useIntersectionObserver';

//UTILS
import { isMobile } from 'utils/functions';

//STYLES
import { ArrowCircleLeftIcon, ArrowCircleRightIcon, PlayIcon } from '@heroicons/react/solid';
//import { isSmartphone } from '../../utils/functions';

const sizes = {
    low: '?h=250&c=true&q=1&f=webp&p=true',
    full: '?w=900&q=95',
    medium: '?w=550&q=95',
    media: '?h=250&q=90',
    mobile: '?w=400&q=90',
};

function ImageSlider(props) {
    //CONTEXTS
    const [open, setOpen] = useState(false);
    const [currentMedia, setCurrentMedia] = useState(null);
    const [played, setPlayed] = useState(false);

    const currentIndex = useRef(null);
    //const widthRef = useRef(null);
    const viewRef = useRef(null);

    /*     const isVisible = useOnScreen(viewRef); */

    const navigate = useNavigate();

    function handleOpen(e, media, openMedia) {
        e.stopPropagation();
        e.preventDefault();
        if (openMedia) {
            currentIndex.current = props.room.medias.indexOf(media);
            setCurrentMedia(media);
            setOpen(true);
        } else if (props.link) {
            navigate(props.link);
        }
    }

    function handlePrev() {
        currentIndex.current = props.room?.medias.indexOf(currentMedia);
        let prevIndex = props.room?.medias[currentIndex.current - 1] ? currentIndex.current - 1 : props.room?.medias.length - 1;

        setCurrentMedia(props.room?.medias[prevIndex]);
    }

    function handleNext() {
        currentIndex.current = props.room?.medias.indexOf(currentMedia);
        let nextIndex = props.room?.medias[currentIndex.current + 1] ? currentIndex.current + 1 : 0;

        setCurrentMedia(props.room?.medias[nextIndex]);
    }

    function SlidesToShow() {
        let sliderNb = props?.room?.medias?.length > 0 ? props?.room?.medias?.length : 1;
        let maxItems = Math.round(viewRef?.current?.offsetWidth / 60);
        if (sliderNb < maxItems) maxItems = sliderNb;
        if (maxItems) return maxItems;
        return 1;
    }

    function handleContextMenu(event) {
        event.preventDefault();
    }

    const ArrowLeft = ({ currentSlide, slideCount, ...props }) => (
        <button {...props} className={'prev center w-6 h-6 m-auto'}>
            <ArrowCircleLeftIcon className="text-purpleSkills" />
        </button>
    );
    const ArrowRight = ({ currentSlide, slideCount, ...props }) => (
        <button {...props} className={'next center w-6 h-6 m-auto'}>
            <ArrowCircleRightIcon className="text-purpleSkills" />
        </button>
    );

    const settings = {
        className: `${props?.type === 'need' ? 'medias-need-slider' : 'image-slider'} flex flex-col h-full w-full gap-1`,
        arrows: isMobile() ? false : true,
        dots: true,
        autoplay: true,
        variableWidth: false,
        infinite: true,
        autoplaySpeed: 5000,
        speed: 400,
        slidesToShow: props?.isSearch ? SlidesToShow(props?.room?.medias?.length) : 1,
        SlidesToShow: 1,
        prevArrow: props?.type !== 'need' ? < ArrowLeft /> : false,
        nextArrow: props?.type !== 'need' ? <ArrowRight /> : false,
    };

    useIntersectionObserver({
        target: viewRef,
        onIntersect: (isVisible) => {
            setPlayed(isVisible);
        },
        enabled: true,
    });

    return (
        <>
            <Modal
                modalWidth={'w-auto'}
                open={open}
                title={false}
                fullScreen={true}
                navigation={true}
                prev={handlePrev}
                next={handleNext}
                onCloseOverlay={true}
                onClose={{
                    onClick: () => setOpen(false),
                }}
            >
                <div className="rounded-3xl max-h-fit" style={{ maxHeight: '90vh' }}>
                    {currentMedia?.type?.includes('image') && (
                        <Image dynamic className="w-full rounded-xl max-h-fit object-cover" src={currentMedia?.url + sizes.full} alt={props?.pseudo} />
                    )}
                    {currentMedia?.type?.includes('video') && (
                        <div
                            ref={viewRef}
                            className={`relative cursor-pointer rounded-xl overflow-hidden flex items-center object-contain justify-center w-full h-auto`}
                        >
                            <ReactPlayer
                                className="react-player"
                                url={`${currentMedia?.encoded
                                        ? `${config.serverUrl + `/stream/${props?.room?.user?._id}/${currentMedia?._id}.mp4`}`
                                        : `${config.serverUrl}${currentMedia?.url}`
                                    }`}
                                controls={false}
                                playing={true}
                                muted={false}
                                loop={true}
                                light={false}
                                playsinline={true}
                                stopOnUnmount={true}
                                width={'auto'}
                                height={`auto`}
                                onContextMenu={handleContextMenu}
                            />
                        </div>
                    )}
                </div>
            </Modal>
            <div
                className=""
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                }}
            >
                {props?.room?.medias?.length > 0 && (
                    <div className={`w-full ${props?.type === 'need' ? 'sm:max-h-[10rem]' : 'h-full'}`}>
                        <Slider {...settings}>
                            {(props?.isCard && props?.room?.medias?.length > 6 ? props.room.medias.slice(0, 6) : props.room.medias)?.map((media, index) => (
                                <div className="" key={index}>
                                    {media.type?.includes('image') && (
                                        <div className={`mr-1 flex justify-center cursor-pointer isSlider`}>
                                            <Image
                                                onClick={(e) => handleOpen(e, media, !props.gotoRoom)}
                                                className={`w-auto object-contain h-auto ${props?.type === 'need' ? 'sm:max-h-[10rem]' : 'max-h-[30rem]'
                                                    } rounded-3xl relative`}
                                                src={isMobile() ? media?.url + sizes.mobile : media?.url + sizes.mobile}
                                                alt={props.room?.question}
                                            />
                                        </div>
                                    )}
                                    {media.type?.includes('video') && (
                                        <div
                                            ref={viewRef}
                                            className={`relative ${props?.type === 'need' ? 'bg-black' : ''
                                                } rounded-3xl cursor-pointer overflow-hidden mr-2 flex items-center object-cover justify-center w-full h-auto`}
                                            onClick={(e) => handleOpen(e, media, true)}
                                        >
                                            {/* <img src={`${config.serverUrl}/downloads/videos/${props?.room?.user?._id}/screenshot_${media?._id}_1.jpg`} alt={'test'} style={{position:'absolute', width: '100%', height: '100%', objectFit: 'cover', zIndex: 2 }} />  */}

                                            <ReactPlayer
                                                className={` ${props?.type === 'need' ? 'medias-need-react-player' : 'react-player rounded-3xl'}`}
                                                url={`${media?.encoded
                                                        ? `${config.serverUrl + `/stream/${props?.room?.user?._id}/${media?._id}.mp4`}`
                                                        : `${config.serverUrl}${media?.url}`
                                                    }`}
                                                controls={false}
                                                playing={played ? true : false}
                                                muted={true}
                                                loop={true}
                                                light={false}
                                                playsinline={true}
                                                stopOnUnmount={true}
                                                width={'auto'}
                                                height={`${props?.isSearch ? '10rem' : props?.type === 'need' ? '10rem' : 'auto'}`}
                                                onContextMenu={handleContextMenu}
                                                style={{ overflow: 'hidden' }}
                                            />
                                            {played || (
                                                <div className="absolute inset-0 flex items-center justify-center ">
                                                    <PlayIcon className="w-14 h-14 text-white backdrop-blur-lg rounded-full" />
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            ))}
                        </Slider>
                    </div>
                )}
            </div>
        </>
    );
}

export default ImageSlider;
