import { useContext, useEffect, useState } from 'react';

//LIBS
import _ from 'lodash';
import * as yup from 'yup';
import { ref as fbRef, set as fbSet, onValue } from 'firebase/database';

//CONTEXTS
import { HeadlessContext } from '../../index.js';
import { HeadlessNotificationsContext } from '../../components/HeadlessNotifications.jsx';

//HOOKS
import { useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

//ICONS
import { AiOutlineMinusSquare, AiOutlinePlusSquare } from 'react-icons/ai';
import { ImSpinner8 } from 'react-icons/im';

//COMPONENTS
import HeadlessInput from '../../components/content/HeadlessInput';
import HeadlessButton from '../../components/content/HeadlessButton.jsx';

const schema = yup.object().shape({
    gtm_id: yup.string().required("L'identifiant est requis"),
});

function Gtm() {
    const notif = useContext(HeadlessNotificationsContext);
    const store = useContext(HeadlessContext);

    const [loading, setLoading] = useState(false);
    const [gtm, setGtm] = useState({});

    const formGtm = useForm({
        resolver: yupResolver(schema),
    });

    const formFieldArrayGtm = useFieldArray({ control: formGtm.control, name: 'events' });

    const fetchGtm = async () => {
        const req = fbRef(store.get('firebase').db, 'gtm/');
        onValue(req, (snapshot) => {
            const data = snapshot.val();
            setGtm(data);
        });
    };

    async function writeData(data) {
        fbSet(fbRef(store.get('firebase').db, 'gtm/'), data);
        notif.set({ action: 'add', type: 'success', message: 'Données enregistrées' });
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }

    function onSubmit(data) {
        setLoading(true);
        writeData(data);
    }

    useEffect(() => {
        fetchGtm().catch(console.error);
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        formGtm.setValue('gtm_id', gtm?.gtm_id);
        formGtm.setValue('gtag', gtm?.gtag);
        if (gtm?.events) formGtm.setValue('events', gtm?.events ?? []);
        //eslint-disable-next-line
    }, [gtm]);

    return (
        <div className="">
            <form onSubmit={formGtm.handleSubmit(onSubmit)}>
                <span className="inline-block font-semibold">Configuration</span>
                <div className="grid grid-cols-2 gap-4">
                    <HeadlessInput label="GTM ID" value={gtm?.gtm_id} register={formGtm.register('gtm_id')} errors={formGtm.formState.errors} />
                </div>
                <div className="flex flex-row gap-2 mt-8">
                    <span className="inline-block font-semibold">Déclencheurs</span>
                    <span className="cursor-pointer flex flex-row gap-1 items-center text-[#C131BB] text-xs" onClick={() => formFieldArrayGtm.append({})}>
                        <AiOutlinePlusSquare />
                        Ajouter
                    </span>
                </div>

                {formFieldArrayGtm.fields.map((item, k) => (
                    <div key={item.id} className="grid grid-cols-2 gap-4">
                        <HeadlessInput label="Nom de l'événement" register={formGtm.register(`events.${k}.evtName`)} errors={formGtm.formState.errors} />
                        <HeadlessInput
                            label="Paramètres"
                            register={formGtm.register(`events.${k}.evtParams`)}
                            errors={formGtm.formState.errors}
                            actions={[{ jsx: <AiOutlineMinusSquare />, onClick: () => formFieldArrayGtm.remove(k) }]}
                        />
                    </div>
                ))}
                <div className="mt-4">
                    <HeadlessButton
                        icon={<ImSpinner8 className="animate-spin" />}
                        activeIcon={loading}
                        label="Enregistrer"
                        theme="important"
                        className="mt-4"
                    />
                </div>
            </form>
        </div>
    );
}

export default Gtm;

const addConversion = (event) => {
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.text = `
          (function(window) {
            window.gtag_report_${event.evtName} = (function(params) {
              window.gtag('event', '${event.evtName}');
              return false;
            });
          })(window);
        `;
    document.head.appendChild(script);
    //eslint-disable-next-line
    eval(script.text);
};

const loadGtm = (gtm, callback) => {
    const existingScript = document.getElementById('gtmScript');

    if (!existingScript && gtm?.gtm_id) {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.id = 'gtmScript';
        script.text = `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${gtm.gtm_id}');
        `;
        document.head.appendChild(script);

        loadGtag(gtm);

        if (callback) {
            callback?.();
        }

        const noscript = document.createElement('noscript');
        noscript.id = 'gtmNoScript';

        const iframe = document.createElement('iframe');
        iframe.src = `https://www.googletagmanager.com/ns.html?id=${gtm.gtm_id}`;
        iframe.height = '0';
        iframe.width = '0';
        iframe.style = 'display:none;visibility:hidden';

        noscript.appendChild(iframe);
        document.body.prepend(noscript);
    }
};

const loadGtag = (gtm, callback) => {
    const existingScript = document.getElementById('gtagScript');

    if (!existingScript && gtm?.gtm_id) {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src = `https://www.googletagmanager.com/gtag/js?id=${gtm.gtm_id}`;
        script.id = 'gtagScript';
        script.text = `
          window.dataLayer = window.dataLayer || [];

          (function(window) {
            window.gtag = function(){dataLayer.push(arguments);}

            window.gtag('js', new Date());
            ${gtm?.gtm_id && `window.gtag('config','${gtm.gtm_id}');`}

          })(window);
        `;
        document.head.appendChild(script);

        script.onload = () => {
            //eslint-disable-next-line
            eval(script.text);

            _.forEach(gtm.events, (event) => {
                addConversion(event);
            });

            if (callback) {
                callback?.();
            }
        };
    }
};

export function useGtm() {
    const store = useContext(HeadlessContext);

    const [gtm, setGtm] = useState({});
    const [run, setRun] = useState(false);

    const fetchGtm = async () => {
        const req = fbRef(store.get('firebase').db, 'gtm/');
        onValue(req, (snapshot) => {
            const data = snapshot.val();
            setGtm(data);
        });
    };

    function execute(key, isAllow = true) {
        if (isAllow) window?.[`gtag_report_${key}`]?.();
    }

    function init() {
        setRun(true);
    }

    useEffect(() => {
        if (gtm.gtm_id) {
            loadGtm(gtm);
        }
        //eslint-disable-next-line
    }, [gtm]);

    useEffect(() => {
        if (store.get('firebase') && run) fetchGtm().catch(console.error);
        //eslint-disable-next-line
    }, [store.get('firebase'), run]);

    return { gtm, init, execute };
}
