import { useState, useRef } from 'react';

//API
import { postComment } from 'api/comments';

//LIBS
import { toast } from 'react-toastify';

//ICONS
import { StarIcon } from '@heroicons/react/solid';

//COMPONENTS
import Modal from 'components/common/Modal';
import Rating from 'components/Rating';

function CommentForm({ user }) {
    const [modalComment, setModalComment] = useState(false);
    const [messageComment, setMessageComment] = useState('');
    const [rateComment, setRateComment] = useState(5);

    const refMessageComment = useRef(null);

    function handleComment() {
        setMessageComment(messageComment.replace(/<script(?:(?!\/\/)(?!\/\*)[^'"]|"(?:\\.|[^"\\])*"|'(?:\\.|[^'\\])*'|\/\/.*(?:\n)|\/\*(?:(?:.|\s))*?\*\/)*?<\/script>/ims, ' '));
        if (rateComment >= 0 && rateComment <= 5 && messageComment !== '') {
            postComment({
                user: user.id,
                note: rateComment,
                message: messageComment,
            }).then((res) => {
                // console.log(rateComment, messageComment);
                setModalComment(false);
                toast('Votre commentaire a bien été envoyé', {
                    type: 'success',
                });
                // comments.refetch();
                // refetch();
            });
        } else {
            setModalComment(false);
            toast("Vous n'avez pas laissé d'avis", { type: 'warning' });
        }
    }

    return (
        <>
            <Modal
                open={modalComment}
                title={`Laissez un avis sur ${user?.profile?.pseudo}`}
                onClose={{
                    onClick: () => setModalComment(false),
                }}
                onConfirm={{
                    label: 'Valider',
                    onClick: () => handleComment(),
                }}
            >
                <Rating value={rateComment} character={<StarIcon className="h-6 w-6" />} onChange={(value) => setRateComment(value)} />
                <textarea
                    ref={refMessageComment}
                    name="messageReservation"
                    className="input py-4"
                    rows="4"
                    style={{ height: 'auto' }}
                    placeholder={`Laissez un avis sur ${user?.profile?.pseudo}`}
                    onChange={() => {
                        setMessageComment(refMessageComment.current.value);
                    }}
                    value={messageComment}
                >
                    {messageComment}
                </textarea>
            </Modal>
            <span className="text-purpleSkills font-bold text-xs sm:mt-1.5 cursor-pointer hover:text-blueMain" onClick={() => setModalComment(true)}>
                Laisser un avis
            </span>
        </>
    );
}

export default CommentForm;
