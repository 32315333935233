import { useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import { isMobile } from '../../../utils/functions';

const SubcategoryList = ({ maincategory, subcategories, style, handleCategoryLeave }) => {
    const navigate = useNavigate();

    async function handleOnClick(subcategory) {
        navigate(`/services/${maincategory}/${subcategory}`, { state: { maincategory: maincategory, subcategory: subcategory, replace: true } });
    }

    return (
        <div className={`w-auto text-mainText overflow-x-auto absolute bg-white p-4 mt-1 shadow-md rounded-md grid grid-cols-2 gap-2`} style={style}>
            {subcategories.map((subcategory, index) => (
                <div
                    className="hover:text-blueMain hover:cursor-pointer hover:bg-white hover:rounded-3xl p-1"
                    key={index}
                    onClick={() => {
                        handleOnClick(subcategory);
                        handleCategoryLeave();
                    }}
                >
                    {subcategory}
                </div>
            ))}
        </div>
    );
};

const CategoriesServices = () => {
    const navigate = useNavigate();
    const containerRef = useRef(null);
    const categories = {
        'Tech & Data': [
            'Développement Web',
            'Développement Mobile',
            'Gestion de Systèmes',
            'Sécurité Informatique',
            'Intelligence Artificielle',
            'Analyse de Données',
            'Support Technique',
            'Conception Logicielle',
            'Services Informatiques',
            'E-commerce',
        ],
        Business: [
            "Stratégie d'Entreprise",
            'Planification Financière',
            'Marketing',
            'Ressources Humaines',
            'Gestion de Projet',
            'Conseil en Start-up',
            'Analyse de Marché',
            'Développement Commercial',
            'Services Business',
            'Finance',
            'Management',
            'Soft skills',
        ],
        'Art & Créativité': [
            'Design',
            'Musique et Audio',
            'Vidéo et Montage',
            'Animation',
            'Artisanat et DIY',
            'Création de Contenu',
            'Conception Artistique',
            'Services Créatifs',
            'Photographie',
        ],
        Ingénierie: [
            'Ingénierie',
            'Sciences des Matériaux',
            'Biotechnologie',
            'Recherche Scientifique',
            'Modélisation et Simulation',
            'Gestion de Projets Techniques',
            'Ingénierie Logicielle',
            "Services d'Ingénierie et Sciences",
            'Électronique',
            'Transport et Logistique',
        ],
        'Santé & Bien-être': [
            'Coaching',
            'Nutrition',
            'Physiothérapie',
            'Psychologie',
            'Médecine Holistique',
            'Sport et Méditation',
            'Soins Alternatifs',
            'Santé Mentale',
            'Services de Santé et Bien-être',
            'Coaching de Vie',
        ],
        'Éducation & Formation': [
            'Tutorat et Soutien Scolaire',
            'Formation Professionnelle',
            'Développement Personnel',
            'Cours en Ligne',
            'Coaching Éducatif',
            'Conception de Cours',
            'Éducation Spécialisée',
            'Mentorat',
            'Services Éducatifs',
            'Langues Étrangères',
        ],
        'Com & Médias': [
            'Rédaction et Édition',
            'Relations Publiques',
            'Marketing de Contenu',
            "Animation d'Événements",
            'Médias Sociaux',
            'Podcasting',
            'Journalisme',
            'Publicité',
            'Services de Communication et Médias',
            'Relations Influences',
        ],
        Événementiel: [
            "Planification d'Événements",
            "Animation d'Événements",
            'Spectacles et Performances',
            "Gestion d'Artistes",
            'Organisation de Festivals',
            'Services de Traiteur',
            "Photographie d'Événements",
            'Animation pour Enfants',
            'Services Événementiels et de Divertissement',
            'Mariages et Événements Spéciaux',
        ],
        Environnement: [
            'Conseil en Durabilité',
            'Gestion des Déchets',
            'Énergie Renouvelable',
            'Agriculture Durable',
            'Éco-conception',
            'Sensibilisation Environnementale',
            'Conservation de la Nature',
            "Éducation à l'Écologie",
            'Services Environnementaux',
            'Architecture Durable',
        ],
        Juridiques: [
            'Conseil Juridique',
            'Rédaction de Contrats',
            'Médiation et Arbitrage',
            'Droit des Affaires',
            'Propriété Intellectuelle',
            'Conseil Fiscal',
            'Gestion de Conflits',
            "Droit de l'Immigration",
        ],
    };
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [showLeftButton, setShowLeftButton] = useState(false);
    const [showRightButton, setShowRightButton] = useState(true);
    const [subcategoriesStyle, setSubcategoriesStyle] = useState({});

    useEffect(() => {
        const container = containerRef.current;

        if (container) {
            setShowLeftButton(container.scrollLeft > 0);
            setShowRightButton(container.scrollLeft < container.scrollWidth - container.clientWidth);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCategoryClick = (e, category) => {
        e.preventDefault();
        e.stopPropagation();
        if (isMobile()) {
            const categoryElement = document.getElementById(`category-${category}`);
            const categoryRect = categoryElement.getBoundingClientRect();
            const navElement = document.getElementsByTagName('nav')[0];
            const navLeft = navElement.getBoundingClientRect().left + 15;
            const navRight = navElement.getBoundingClientRect().right - 15;
            const spaceLeft = categoryRect.left - navLeft;
            const spaceRight = window.innerWidth - (categoryRect.right + (window.innerWidth - navRight));

            if (categoryRect.left > window.innerWidth / 2) {
                setSubcategoriesStyle({
                    right: `${spaceRight}px`,
                });
            } else {
                setSubcategoriesStyle({
                    left: `${spaceLeft}px`,
                });
            }
            setSelectedCategory(category);
        } else {
            navigate(`/services/${category}`, { replace: true }, { state: { maincategory: category } });
        }
    };

    const handleCategoryHover = (category) => {
        const categoryElement = document.getElementById(`category-${category}`);
        const categoryRect = categoryElement.getBoundingClientRect();
        const navElement = document.getElementsByTagName('nav')[0];
        const navLeft = navElement.getBoundingClientRect().left + 15;
        const navRight = navElement.getBoundingClientRect().right - 15;
        const spaceLeft = categoryRect.left - navLeft;
        const spaceRight = window.innerWidth - (categoryRect.right + (window.innerWidth - navRight));

        if (categoryRect.left > window.innerWidth / 2) {
            setSubcategoriesStyle({
                right: `${spaceRight}px`,
            });
        } else {
            setSubcategoriesStyle({
                left: `${spaceLeft}px`,
            });
        }
        setSelectedCategory(category);
    };

    const handleCategoryLeave = () => {
        setSelectedCategory(null);
    };

    const handleGoRight = () => {
        if (containerRef.current) {
            const container = containerRef.current;
            const newScrollLeft = container.scrollLeft + 300; // adjust the scroll distance as needed
            scrollTo(container, newScrollLeft);
        }
    };

    const handleGoLeft = () => {
        if (containerRef.current) {
            const container = containerRef.current;
            const newScrollLeft = container.scrollLeft - 300; // adjust the scroll distance as needed
            scrollTo(container, newScrollLeft);
        }
    };

    const scrollTo = (element, to, duration = 500) => {
        const start = element.scrollLeft;
        const change = to - start;
        let currentTime = 0;
        const increment = 20;

        const animateScroll = () => {
            currentTime += increment;
            const val = easeInOutQuad(currentTime, start, change, duration);
            element.scrollLeft = val;
            if (currentTime < duration) {
                requestAnimationFrame(animateScroll);
            } else {
                setShowLeftButton(element.scrollLeft > 0);
                setShowRightButton(element.scrollLeft < element.scrollWidth - element.clientWidth);
            }
        };
        animateScroll();
    };

    const easeInOutQuad = (t, b, c, d) => {
        t /= d / 2;
        if (t < 1) return (c / 2) * t * t + b;
        t--;
        return (-c / 2) * (t * (t - 2) - 1) + b;
    };

    const handleScroll = () => {
        const container = containerRef.current;

        if (container) {
            const atLeftEnd = container.scrollLeft === 0;
            const atRightEnd = container.scrollLeft >= container.scrollWidth - container.clientWidth - 0.5;

            setShowLeftButton(!atLeftEnd);
            setShowRightButton(!atRightEnd);
        }
    };

    return (
        <div className="container mx-auto z-20 px-2 mt-2 pb-1 sm:pb-0 sm:px-4">
            {/*  <div className="max-w-[1096px] border-y border-gray-200 relative w-full flex"> */}
            <div className="max-w-[1096px] relative w-full flex">
                {showLeftButton && (
                    <button className="absolute left-0 w-auto pr-4 h-full bg-gradient-to-r from-white to-transparent" onClick={handleGoLeft}>
                        <ChevronLeftIcon className="h-6 w-6 text-gray-500" />
                    </button>
                )}
                <div ref={containerRef} onScroll={handleScroll} className="flex max-w-[1096px] h-auto overflow-x-auto scrollbar-hide cursor-pointer py-1">
                    {Object.entries(categories).map(([category, subcategories]) => (
                        <div
                            key={category}
                            id={`category-${category}`}
                            className="text-graySkills text-base inline-block w-auto h-full flex-shrink-0 p-1 mr-4 hover:underline decoration-blueMain decoration-2 underline-offset-[9px] bg-blancSkills rounded-full px-2"
                            onMouseEnter={() => handleCategoryHover(category)}
                            onMouseLeave={handleCategoryLeave}
                        >
                            <span
                                onClick={(e) => {
                                    handleCategoryClick(e, category);
                                    handleCategoryLeave();
                                }}
                            >
                                {category}
                            </span>
                            {selectedCategory === category && (
                                <SubcategoryList
                                    maincategory={category}
                                    subcategories={subcategories}
                                    style={subcategoriesStyle}
                                    handleCategoryLeave={handleCategoryLeave}
                                />
                            )}
                        </div>
                    ))}
                </div>
                {showRightButton && (
                    <button className="absolute right-0 w-auto pl-4 h-full bg-gradient-to-r from-transparent to-white" onClick={handleGoRight}>
                        <ChevronRightIcon className="h-6 w-6 text-gray-500" />
                    </button>
                )}
            </div>
        </div>
    );
};

export default CategoriesServices;
