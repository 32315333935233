/* NPM PACKAGES LIST DEPENDENCIES
 * axios
 * lodash
 * react-hook-form
 * @hookform/resolvers
 * yup
 * react-query
 * uniqid
 * react-icons
 * firebase
 * firebase-admin
 */
import { useRef, useState, useReducer, createContext, useEffect } from 'react';

//LIBS
import _ from 'lodash';
import { initializeApp } from 'firebase/app';
import { browserSessionPersistence, getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';

//COMPONENTS
import HeadlessApp from './components/HeadlessApp';
import HeadlessEdit from './components/HeadlessEdit';

import useHeadlessModule from './services/useHeadlessModule';

const HeadlessContext = createContext();

function reducer(state, action) {
    switch (action.type) {
        case 'config':
            return { ...state, type: action.type, config: action.value };
        case 'firebase':
            return { ...state, type: action.type, firebase: action.value };
        case 'open':
            return { ...state, type: action.type, opened: true };
        case 'close':
            return { ...state, type: action.type, opened: false };
        case 'toggleBar':
            return { ...state, type: action.type, barOpened: action.value };
        case 'logged':
            return { ...state, type: action.type, logged: true };
        case 'setMe':
            return { ...state, type: action.type, logged: true, me: action.value, page: state.config.pageDefault ?? 'dashboard' };
        case 'page':
            return { ...state, type: action.type, page: action.value };
        case 'logout':
            return { ...state, type: action.type, opened: false, logged: false, me: null };
        case 'addModule':
            return {
                ...state,
                type: action.type,
                modules: { ...state.modules, [action.key]: { jsx: action.jsx, options: action.options } },
                modulesLoaded: [...state.modulesLoaded, action.key],
            };
        case 'del':
            const newStore = _.omit(state, [action.key]);
            return newStore;
        default:
            throw new Error();
    }
}

function HeadlessProvider(props) {
    const interval = useRef(null);

    const [store, set] = useReducer(reducer, {
        type: null,
        firebase: null,
        opened: false,
        barOpened: true,
        logged: false,
        me: null,
        page: 'dashboard',
        modules: {},
        modulesLoaded: [],
    });

    const [state, setState] = useState({ keyup: '' });

    function get(key) {
        return store[key] ? store[key] : null;
    }

    const onKey = (e) => {
        setState((p) => {
            return { keyup: p.keyup + e.key };
        });
    };

    function initFirebase() {
        const firebaseConfig = get('config')?.firebase;
        if (firebaseConfig) {
            const firebaseApp = initializeApp(firebaseConfig);
            const firebaseDb = getDatabase();

            const auth = getAuth(firebaseApp);
            auth.setPersistence(browserSessionPersistence);

            set({ type: 'firebase', value: { app: firebaseApp, db: firebaseDb, auth } });
        }
    }

    useEffect(() => {
        const body = document.body;
        body.onkeyup = onKey;

        interval.current = setInterval(() => {
            setState(() => {
                return { keyup: '' };
            });
        }, 5000);

        return () => {
            clearInterval(interval.current);
        };
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (state.keyup.includes(process.env.REACT_APP_HEADLESS_KEYS_OPENING)) {
            set({ type: 'open' });

            setState({ ...state, keyup: '' });
        }
        //eslint-disable-next-line
    }, [state.keyup]);

    useEffect(() => {
        if (store.logged) {
            clearInterval(interval.current);
        }
        //eslint-disable-next-line
    }, [store.logged]);

    useEffect(() => {
        switch (store.type) {
            case 'config':
                break;
            default:
        }
        //eslint-disable-next-line
    }, [store.type]);

    useEffect(() => {
        if (store.config?.firebase) initFirebase();
        //eslint-disable-next-line
    }, [store.config]);

    useEffect(() => {
        if (store.opened) document.querySelector('body').style.overflow = 'hidden';
        else document.querySelector('body').style.overflow = 'auto';
    }, [store.opened]);

    const methods = { store, set, get };
    return <HeadlessContext.Provider value={methods}>{props.children}</HeadlessContext.Provider>;
}

export { HeadlessContext, HeadlessProvider, HeadlessApp as default, HeadlessEdit, useHeadlessModule };
