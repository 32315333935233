import { useEffect, useContext, useRef } from 'react';
//STYLES
import 'containers/cards.css';
import config from 'utils/constants';
//API
import { postCounter } from 'api/counters';
//CONTEXTS
import { AuthContext } from 'context/authContext';
//LIBS
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { isFoundByKey, countByKey } from 'utils/functions';
//UTILS

//COMPONENTS
// import Button from 'components/common/Button';
import Image from 'components/common/Image';
import Rating from 'components/Rating';
import Reservation from 'components/visio/Reservation';
import PingConnected from 'components/PingConnected';
//import Counter from 'components/Counter';
import Modal from 'components/common/Modal';
//CONTAINERS
import PointGamification from 'containers/profils/PointGamification';
//ICONS
import { StarIcon, LocationMarkerIcon, CheckIcon, BellIcon /*  EyeIcon */ } from '@heroicons/react/solid';
//import { ChatAltIcon } from '@heroicons/react/outline';
//HOOKS
import useSafeState from 'hooks/useSafeState';

const sizes = {
    avatar: '?w=130&h=130&c=true&q=80',
};

function CardProfilSearch({ user, refetch }) {
    //CONTEXTS
    const [authState] = useContext(AuthContext);
    //REFS
    const refReservation = useRef(null);

    const [modalVideo, setModalVideo] = useSafeState(false);
    const [modalCalendar, setModalCalendar] = useSafeState(false);
    const [userName, setUserName] = useSafeState('');

    //FUNCTIONS
    // function handleCalendar() {
    //     setModalCalendar(true);
    // }

    function handleReservation() {
        refReservation.current.submit();
    }

    function handleSubscribe() {
        if (authState.isLogged) {
            postCounter({ type: 'subscription', to: user?.id || user?._id }).then((res) => {
                refetch();
            });
        } else document.getElementById('button-login')?.click();
    }

    useEffect(() => {
        if (user === null) return;
        if (user?.profile?.pseudo) setUserName(user?.profile?.pseudo);
        else if (user?.profile?.society) setUserName(user?.profile?.society);
        else setUserName(user?.profile?.firstname);
        //eslint-disable-next-line
    }, [user]);

    return (
        <>
            <Modal
                open={modalVideo}
                title={false}
                fullScreen={true}
                onClose={{
                    onClick: () => setModalVideo(false),
                }}
            >
                <div className="overflow-hidden rounded-xl">
                    <ReactPlayer
                        url={user?.profile?.video}
                        width="100%"
                        // height="380px"
                        origin={window.location.origin}
                        controls={true}
                        className="aspect-video"
                    />
                </div>
            </Modal>
            <Modal
                open={modalCalendar}
                title="Réserver un créneau de visio-consultation"
                onClose={{
                    onClick: () => setModalCalendar(false),
                }}
                onConfirm={{
                    label: 'Réserver',
                    onClick: () => handleReservation(),
                }}
            >
                <Reservation ref={refReservation} user={user} onClose={() => setModalCalendar(false)} />
            </Modal>
            <div className="w-full flex flex-row justify-between items-center sm:gap-4 h-auto">
                <div className="flex gap-4 justify-between w-full">
                    <div className="relative card_profile_images">
                        <div className="card_profile_images_avatar">
                            <Link to={'/profil/' + user?.profile?.slug} title={`Profil de ${userName}`}>
                                <div className="relative w-20 h-20 m-auto">
                                    {user?.profile?.avatar?.url ? (
                                        <Image
                                            src={user?.profile?.avatar?.url + sizes.avatar}
                                            alt={userName}
                                            loading="false"
                                            className="bg-white w-20 h-20 mt-0 lg:w-22 lg:h-22"
                                        />
                                    ) : (
                                        <img
                                            className="bg-white w-20 h-20 mt-0 lg:w-22 lg:h-22"
                                            src={`${config.publicDir}skillsLogo.jpg` + sizes.avatar}
                                            alt={userName}
                                        />
                                    )}
                                    <PointGamification gamification={user?.gamification?.profile} />
                                    <PingConnected connexions={user?.connexions} type="global" />
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className="w-full">
                        <Link to={'/profil/' + user?.profile?.slug} title={`Profil de ${userName}`}>
                            <h3 className="h3 text-base text-purpleSkills">{userName}</h3>
                        </Link>
                        {user?.profile?.status && <p className="flex text-xs text-graySkills w-full">{user?.profile?.status}</p>}
                        <div className="flex flex-row w-full gap-6 text-xs text-graySkills mt-0.5">
                            {authState.isLogged && user?.profile?.address && (
                                <>
                                    <p className="flex">
                                        <LocationMarkerIcon className="w-3 h-3 mr-1 mt-0.5" />
                                        {user?.profile?.address?.split(',')[1] ? user?.profile?.address?.split(',')[1] : user?.profile?.address}
                                    </p>
                                </>
                            )}

                            <p className="inline w-auto">{countByKey(user?.counters, 'type', 'subscription')} abonné(s)</p>
                            <span className="hidden md:flex">
                                <Rating disabled={true} value={user?.social?.notation ?? 0} character={<StarIcon className="h-4 w-4" />} />
                            </span>
                        </div>
                        {user?.profile?.tags && (
                            <ul className="hidden md:flex flex-wrap gap-1 text-xs text-graySkills">
                                {_.take(user?.profile?.tags, 4).map((tag, index) => (
                                    <li key={index}>
                                        <Link className="link_basic mb-1 -ml-2 mr-4" to={`/search?q=${tag.name}`}>
                                            {tag.name}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                </div>
                <div className="flex flex-col sm:flex-row items-center justify-end w-auto gap-2">
                    {/*                     <button className="linkCard linkCard_icon">
                        <ChatAltIcon className="w-4 h-4" />
                    </button> */}
                    {/*  <button className="linkCard linkCard_icon">
                        <ShareIcon className="w-4 h-4" />
                    </button> */}
                    {user?.profile?.slug !== authState?.me?.profile?.slug && (
                        <button
                            onClick={() => handleSubscribe()}
                            className={`h-8 flex gap-2 ${
                                isFoundByKey(user?.counters, 'user', authState?.me?.id) &&
                                isFoundByKey(user?.counters, 'type', 'subscription') &&
                                authState.isLogged
                                    ? 'linkCard red active'
                                    : 'linkCard-purpleSkills'
                            }`}
                        >
                            {isFoundByKey(user?.counters, 'user', authState?.me?.id) &&
                            isFoundByKey(user?.counters, 'type', 'subscription') &&
                            authState.isLogged ? (
                                <>
                                    <CheckIcon className="h-4 w-4" />
                                    <span>Suivi</span>
                                </>
                            ) : (
                                <>
                                    <BellIcon className="h-4 w-4" />
                                    <span>Suivre</span>
                                </>
                            )}
                        </button>
                    )}
                </div>
            </div>
        </>
    );
}

export default CardProfilSearch;
