import { useEffect, useState, useRef } from 'react';

//API
import { deleteMedia } from 'api/users';

//LIBS
import _ from 'lodash';
import Files from 'react-files';

import { CropperPreview, CircleStencil, RectangleStencil } from 'react-advanced-cropper';
import AdjustablePreviewBackground from 'components/imageEditor/components/AdjustablePreviewBackground';
// import EditorPreview from 'components/imageEditor/components/EditorPreview';
import 'react-advanced-cropper/dist/style.css';
import 'react-advanced-cropper/dist/themes/compact.css';

import { toast } from 'react-toastify';
import config from 'utils/constants';

//COMPONENTS
import Button from 'components/common/Button';
// import Modal from 'components/common/Modal';
import ImageEditor from 'components/imageEditor/ImageEditor';

//STYLES
import 'react-image-crop/dist/ReactCrop.css';

//ICONS
import { TrashIcon, VideoCameraIcon, RefreshIcon, UploadIcon, AdjustmentsIcon } from '@heroicons/react/solid';

const validateType = new RegExp(/(gif|jpe?g|tiff?|png|webp|bmp)$/i);
const videoType = new RegExp(/(mp4)$/i);

/**
 * Input field.
 * @param {Object} props
    - label
    - name 
    - type
    - onChange 
    - multiple 
    - button
    - initValue
 */
function FieldFiles(props) {
    const [files, setFiles] = useState([]);
    const [imageSize, setImageSize] = useState('');
    const [modalCrop, setModalCrop] = useState(false);
    const [currentImg, setCurrentImg] = useState(props?.fromBlob === true ? [] : props.initValue);
    const [toggleFit] = useState('cover');
    const [image, setImage] = useState('');
    const [state, setState] = useState(null);
    const [stencil, setStencil] = useState({ aspectRatio: 28 / 9, grid: true });
    const [adjustments, setAdjustments] = useState([]);

    //REFS
    const filesRef = useRef(null);
    const cropperRef = useRef(null);
    const previewRef = useRef(null);
    const wrapRef = useRef(null);

    useEffect(() => {
        setTimeout(() => {
            if (filesRef?.current) {
                filesRef?.current?.setState({ files: [] });
            }
        }, 100);

        if (props?.oldFiles && props?.oldFiles?.length > 0) {
            let oldFiles = [];
            for (let i = 0; i < props?.oldFiles?.length; i++) {
                let file = {
                    extension: props?.oldFiles[i].name.split('.').pop(),
                    preview: {
                        url: config.serverUrl + props?.oldFiles[i].url,
                        type: props?.oldFiles[i].type.split('/')[0],
                    },
                    url: props?.oldFiles[i].url,
                    type: props?.oldFiles[i].type,
                    name: props?.oldFiles[i].name,
                    originalname: props?.oldFiles[i].originalname,
                };
                oldFiles.push(file);
            }

            setFiles(oldFiles);
        }
        //eslint-disable-next-line
    }, [props?.oldFiles]);

    function onFilesChange(uploadedFiles) {
        if (props.type === 'single_video') {
            if (uploadedFiles.length > 0) {
                setFiles([uploadedFiles[0]]);
            } else {
                setFiles([]);
            }
        } else {
            uploadedFiles.forEach((file) => {
                if (!files.includes(file)) {
                    setFiles((prevFiles) => [...prevFiles, file]);
                }
            });
        }

        if (props.multiple !== true && props.type !== 'single_video' && uploadedFiles.length >= 1) {
            setCurrentImg(uploadedFiles[0].preview.url);
            setModalCrop(true);
        }
    }

    function handleAdjust() {
        setModalCrop(true);
    }

    function onFilesError(error, file) {
        console.log('error code ' + error.code + ': ' + error.message);
    }

    const onChange = (cropper) => {
        setImage(cropper.getImage());
        setState(cropper.getState());
        // previewRef.current?.refresh();
    };

    function validateCrop(currentAdjustments) {
        setModalCrop(false);
        setAdjustments(currentAdjustments);

        const cropped = getCroppedImg();
        cropped.then((blob) => {
            const file = new File([blob], props.type + '.jpeg', {
                type: 'image/jpeg',
            });
            props.onChange([file]);
        });
    }

    function getCroppedImg() {
        const canvas = cropperRef.current.getCanvas();

        return new Promise((resolve, reject) => {
            canvas.toBlob(
                (blob) => {
                    resolve(blob);
                },
                'image/jpeg',
                1
            );
        });
    }

    function handleDelete(id, e) {
        e.preventDefault();

        deleteMedia(id).then((res) => {
            const filter = currentImg.filter((item) => item._id !== id);
            setCurrentImg(filter);

            toast('Le fichier a bien été supprimé', { type: 'success' });
        });
    }

    function handleDeleteFile(file, index, e) {
        e.preventDefault();
        filesRef.current.removeFile(file);

        const filter = files.filter((item, k) => k !== index);
        setFiles(filter);

        if (props.type === 'single_video' && filter.length === 0) {
            setFiles([]);
        }
    }

    function handleCancel() {
        setModalCrop(false);
        setFiles([]);

        if (props.fromBlob) setFiles(_.compact(props.initValue));
        else setCurrentImg(props.initValue);
    }

    useEffect(() => {
        if (props.type === 'single_rounded') {
            setStencil({ aspectRatio: 1 / 1, grid: true });
        }
        if (props.fromBlob) {
            setFiles(_.compact(props.initValue));
        } else setCurrentImg(props.initValue);

        //eslint-disable-next-line
    }, [props.initValue]);

    useEffect(() => {
        if (props.multiple || props.type === 'single_video') {
            props.onChange(_.uniq(_.compact(files)));
        }
        if (props.type === 'single_rounded') setImageSize('?w=300&h=300&c=true&q=80');
        if (props.type === 'single') setImageSize('?h=520&q=80');
        if (props.type === 'multiple') setImageSize('?h=160&q=80');
        if (props.type === 'single_video') setImageSize('?h=160&q=80');
        //eslint-disable-next-line
    }, [files]);

    return (
        <div className="FieldFiles">
            {modalCrop && (
                <ImageEditor
                    ref={cropperRef}
                    open={modalCrop}
                    src={currentImg}
                    stencilProps={stencil}
                    stencilComponent={props.type === 'single_rounded' ? CircleStencil : RectangleStencil}
                    sizeRestrictions={{ minWidth: '900', minHeight: '500' }}
                    onChange={onChange}
                    onValidate={(data) => validateCrop(data)}
                    onCancel={handleCancel}
                />
            )}

            <div
                ref={wrapRef}
                className={`relative ${props.button ? 'cursor-pointer w-auto h-auto files_upload' : 'files_upload'} ${
                    props.type === 'single_rounded' && 'files_upload-avatar'
                } ${props.type === 'single' && 'files_upload-single'}`}
            >
                <Files
                    ref={filesRef}
                    clickable
                    className="files-dropzone w-full h-full"
                    onChange={onFilesChange}
                    onError={onFilesError}
                    accepts={props.accepts ?? ['image/png', 'image/jpeg', 'image/jpg', 'image/gif', 'video/mp4', 'video/ogg', 'video/webp']}
                    multiple={props.multiple}
                    maxFiles={props.type === 'single' || props.type === 'single_rounded' ? 1 : props.maxFiles}
                    maxFileSize={props.maxFileSize}
                    // maxFiles={2}
                    // maxFileSize={10000000}
                    // minFileSize={4}
                >
                    {currentImg && !props.multiple && props.type !== 'single_video' && (
                        <div
                            className={`relative files_init overflow-hidden ${props.type === 'single_rounded' && 'rounded-full'} ${
                                props.type === 'single' && 'rounded-lg'
                            }`}
                        >
                            {props?.initValue && files.length <= 0 && (
                                <img
                                    src={props?.initValue + imageSize}
                                    alt=""
                                    className={`object-${toggleFit} object-contain h-full w-full ${props.type === 'single_rounded' && 'rounded-full'} ${
                                        props.type === 'single' && 'rounded-lg'
                                    }`}
                                />
                            )}
                            {files.length > 0 && (
                                <div className="relative overflow-hidden w-full h-full">
                                    <CropperPreview
                                        className={'files_upload-preview w-full h-full'}
                                        ref={previewRef}
                                        image={image}
                                        state={state}
                                        backgroundComponent={AdjustablePreviewBackground}
                                        backgroundProps={adjustments}
                                    />
                                </div>
                            )}
                        </div>
                    )}

                    {((files.length <= 0 && !props.initValue) || props.multiple || props.type === 'single_video') && (
                        <div
                            className={`${props.className ? props.className : 'text-mainText'} relative w-full ${
                                props.multiple || props.type === 'single_video' ? 'h-24' : 'h-full'
                            } flex justify-center items-center`}
                        >
                            {props.button ? props.button : props.label}
                        </div>
                    )}
                </Files>
                {files.length > 0 && !props.multiple && props.type !== 'single_video' && (
                    <div className="absolute top-0 left-0 w-full h-full flex gap-2 justify-center items-center z-10">
                        <div onClick={handleCancel} className="cursor-pointer relative bg-white p-1 w-6 h-6 rounded-full flex justify-center items-center">
                            <RefreshIcon className="w-4 h-4 text-purpleSkills" />
                        </div>
                        <div onClick={handleAdjust} className="cursor-pointer relative bg-white p-1 w-6 h-6 rounded-full flex justify-center items-center">
                            <AdjustmentsIcon className="w-4 h-4 text-purpleSkills" />
                        </div>
                        <div
                            onClick={() => filesRef.current.openFileChooser()}
                            className="cursor-pointer relative bg-white p-1 w-6 h-6 rounded-full flex justify-center items-center"
                        >
                            <UploadIcon className="w-4 h-4 text-purpleSkills" />
                        </div>
                    </div>
                )}
            </div>

            {(props.multiple || props.type === 'single_video') && (
                <>
                    <div className="flex flex-wrap gap-2">
                        {files?.length > 0 && (
                            <>
                                {files.map((file, index) => (
                                    <div key={index} className="relative">
                                        {validateType.test(file.type) ? (
                                            <img
                                                src={`${file.preview?.url ? file.preview?.url : config.serverUrl + file.url}`}
                                                alt=""
                                                className={`h-32 w-auto rounded`}
                                            />
                                        ) : (
                                            <div className={'h-32 w-32 rounded text-graySkills bg-gray-200 flex flex-col justify-center items-center'}>
                                                {videoType.test(file.extension ? file.extension : file.name.split('.').pop()) && (
                                                    <>
                                                        <VideoCameraIcon className="w-10 h-10" />
                                                        <span className="text-xs">{file?.extension ? file?.extension : file.name.split('.').pop()}</span>
                                                        <span className="text-xs truncate w-28">{file.name}</span>
                                                    </>
                                                )}
                                            </div>
                                        )}

                                        <Button
                                            label={<TrashIcon className="w-5 h-5" />}
                                            css="px-1 py-1 min-h-0 text-sm button-primary h-6 w-6 absolute top-0 right-0 flex justify-center items-center"
                                            onClick={(e) => handleDeleteFile(file, index, e)}
                                        />
                                    </div>
                                ))}
                            </>
                        )}

                        {props.initValue?.length > 0 && !props.fromBlob && (
                            <>
                                {currentImg.map((file, index) => (
                                    <div key={index} className="relative">
                                        {validateType.test(file.type) ? (
                                            <img src={file.url} alt="" className={`h-32 w-auto rounded`} />
                                        ) : (
                                            <div className="h-32 w-32 rounded text-graySkills bg-gray-200 flex flex-col justify-center items-center">
                                                {videoType.test(file.extension) && (
                                                    <>
                                                        <VideoCameraIcon className="w-10 h-10" />
                                                        <span className="text-xs">{file?.extension}</span>
                                                    </>
                                                )}
                                            </div>
                                        )}
                                        <Button
                                            label={<TrashIcon className="w-5 h-5" />}
                                            css="px-1 py-1 min-h-0 text-sm button-primary h-6 w-6 absolute top-0 right-0 flex justify-center items-center"
                                            onClick={(e) => handleDelete(file._id, e)}
                                        />
                                    </div>
                                ))}
                            </>
                        )}
                    </div>
                </>
            )}
        </div>
    );
}

export default FieldFiles;
