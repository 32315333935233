import * as yup from 'yup';

const schema = yup.object().shape({
    title: yup.string().max(100, "Le titre ne peut pas dépasser les 100 caractères.").required('Le titre est requis').trim(),
    // question: yup.string().min(30, "Vous devez saisir une question d'au moins 30 caractères").required("Vous devez saisir une question"),
    // tags: yup
    //     .array()
    //     .defined()
    //     .min(1, "Vous devez choisir au moins un tag")
    //     .required("Vous devez saisir une question"),
    // isPublic: yup.boolean()
});

export default schema;
