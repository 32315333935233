import { useEffect, useRef } from 'react';

//LIBS
import { createPortal } from 'react-dom';

//STYLE
import 'components/common/modal/modalSwipe.css';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';

import Image from 'components/common/Image';
import { ChevronLeftIcon, ChevronRightIcon, XIcon } from '@heroicons/react/solid';

const sizes = {
    low: '?w=120&h=120&c=true&q=1&f=webp&p=true',
    full: '?w=900&q=95',
    portfolio: '?w=140&h=140&c=true&q=95',
};

function ModalSwipe(props) {
    const swiperRef = useRef(null);

    useEffect(() => {
        if (props.open) {
            document.querySelector('body').style.overflow = 'hidden';
            // Assurez-vous que le swiper initialise à l'index correct lorsque le modal s'ouvre
            if (swiperRef.current) {
                swiperRef.current.swiper.slideTo(props.initialSlide);
            }
        } else {
            document.querySelector('body').style.overflow = 'auto';
        }
    }, [props.open, props.initialSlide]);

    if (!props.open) return null;

    return createPortal(
        <div
            onClick={(e) => {
                if (props.onCloseOverlay && e.target === e.currentTarget) {
                    props.onClose.onClick();
                }
            }}
            className={`modal items-center`}
        >
            <div onClick={(e) => e.stopPropagation()} className={`modal-content-swiper md:w-[50rem] md:h-[55rem] lg:w-[60rem] bg-black`}>
                <section className={`${!props.modalHeight ? 'h-auto' : props.modalHeight} px-2`}>
                    <Swiper
                        ref={swiperRef}
                        slidesPerView={1}
                        initialSlide={props.initialSlide}
                        mousewheel={true}
                        pagination={{ clickable: true }}
                        scrollbar={{ draggable: true }}
                        className="w-full h-full"
                    >
                        {props.images.map((img, index) => (
                            <SwiperSlide key={index} style={{ width: '100%' }}>
                                <div className="w-full h-full flex flex-col justify-center ">
                                    <Image className="object-contain max-h-full" src={img.url + sizes.full} dynamic alt={`Slide ${index}`} />
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>

                    <button
                        className="w-5 h-10 md:w-8 md:h-14 absolute left-2 top-1/2 transform -translate-y-1/2 z-50 bg-opacity-20 backdrop-blur-sm bg-[#FFFFFF99] rounded-[50%]"
                        onClick={() => swiperRef.current.swiper.slidePrev()}
                    >
                        <ChevronLeftIcon className=" text-graySkills " />
                    </button>
                    <button
                        className="w-5 h-10 md:w-8 md:h-14 absolute right-2 top-1/2 transform -translate-y-1/2 z-50 bg-opacity-20 backdrop-blur-sm bg-[#FFFFFF99] rounded-[50%]"
                        onClick={() => swiperRef.current.swiper.slideNext()}
                    >
                        <ChevronRightIcon className="text-graySkills" />
                    </button>
                </section>

                {props.onClose?.onClick && (
                    <button
                        className={`button-close absolute z-10 top-2 left-2 flex w-10 h-10 rounded-xl bg-opacity-20 backdrop-blur-sm bg-graySkills text-white items-center justify-center`}
                        onClick={props.onClose.onClick}
                    >
                        <XIcon className="h-6 w-6" />
                    </button>
                )}
            </div>
        </div>,
        document.body
    );
}
export default ModalSwipe;
