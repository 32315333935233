import React, { useContext, useState, useLayoutEffect, useEffect, useCallback } from 'react';
//LIBS
import { useLocation } from 'react-router';
//CONTEXTS
import { FormContext } from 'context/formContext';
//COMPONENTS
import Button from 'components/common/Button';
//ICONS
// eslint-disable-next-line no-unused-vars
import { CheckIcon, EyeIcon, EyeOffIcon, PlusCircleIcon, BanIcon, ExternalLinkIcon } from '@heroicons/react/solid';
import { Link } from 'react-router-dom';

function ReactiveSidebarForm(props) {
    const location = useLocation();
    const [status, setStatus] = useState({
        isChanged: false,
        isPublished: false,
        seeLink: null,
        progress: 0,
    });

    const [buttons, setButtons] = useState({});

    //CONTEXTS
    const [formContextState, formContextDispatch] = useContext(FormContext);

    // eslint-disable-next-line no-unused-vars
    const handleAdd = () => {
        formContextDispatch({ type: 'add' });
    };
    const handleSave = () => {
        formContextDispatch({ type: 'save' });
        handlePopHover('save', false);
    };
    const handleTogglePublish = () => {
        formContextDispatch({ type: 'tooglePublish' });
    };

    useLayoutEffect(() => {
        switch (formContextState.action) {
            case 'change':
                setStatus((c) => ({
                    ...c,
                    isChanged: formContextState.status,
                }));
                break;
            case 'publish':
                setStatus((c) => ({
                    ...c,
                    isPublished: formContextState.status,
                    seeLink: formContextState.seeLink,
                }));
                break;
            case 'progress':
                setStatus((c) => ({
                    ...c,
                    progress: formContextState.status,
                }));
                break;
            default:
        }
    }, [formContextState]);

    useEffect(() => {
        if (location.pathname === '/dashboard/services' || location.pathname === '/dashboard/products' || location.pathname === '/dashboard/videos') {
            setButtons({
                ...buttons.current,
                add: true,
                save: false,
                publish: false,
                seeLink: true,
                progress: true,
            });
        } else if (location.pathname.includes('/dashboard/services') || location.pathname.includes('/dashboard/products') || location.pathname.includes('/dashboard/videos')) {
            setButtons({
                ...buttons.current,
                add: true,
                save: true,
                publish: true,
                seeLink: true,
                progress: true,
            });
        } else if (location.pathname.includes('/dashboard/profil') || location.pathname.includes('/profil')) {
            setButtons({
                ...buttons.current,
                add: false,
                save: true,
                publish: false,
                seeLink: true,
                progress: true,
            });
        } else if (location.pathname.includes('/dashboard/params/tagSubscriptions')) {
            setButtons({
                ...buttons.current,
                add: false,
                save: true,
                publish: false,
                seeLink: false,
                progress: false,
            });
        }
        //eslint-disable-next-line
    }, [location.pathname]);

    const handlePopHover = useCallback((popId, showed) => {
        if (showed) {
            document.getElementById(popId)?.classList.add('active');
        } else {
            document.getElementById(popId)?.classList.remove('active');
        }
    }, []);

    return (
        <>
            <div className="flex flex-row gap-2 items-center justify-center">
               {/*  {buttons.add && (
                    <div className="popHover">
                        <Button label={<PlusCircleIcon className="w-4 h-4" />} onMouseOver={() => handlePopHover('add', true)} onMouseLeave={() => handlePopHover('add', false)} css="button-small button-secondary text-white w-8 h-8" onClick={handleAdd} />
                        <div id="add" className="popHover-content"> 
                           Nouveau 
                         </div>
                    </div>
                )} */}
                {buttons.save && (
                    <div className="popHover">
                        {buttons.progress && status.progress > 0 ? (
                            <Button label={<BanIcon className="w-4 h-4" />} css="button-small button-disabled h-8 w-8" />
                        ) : (
                            <button
                                disabled={status?.isChanged ? false : true}
                                onMouseOver={() => {
                                    if (status.isChanged) {
                                        handlePopHover('save', true);
                                    }
                                }}
                                onMouseLeave={() => {
                                    if (status.isChanged) {
                                        handlePopHover('save', false);
                                    }
                                }}
                                onClick={handleSave}
                                className={`flex flex-row items-center gap-1 rounded-full px-4 py-1 bg-white ${status?.isChanged ? 'button-border-green border-2' : 'button-border-gray border-2'}`}
                            >
                                <>
                                    <CheckIcon className="h-5 w-5 p-0" />
                                    <span>Enregistrer</span>
                                </>
                            </button>

                            /*  <Button
                  disabled={status?.isChanged ? false : true}
                  label={<CheckIcon className="w-4 h-4" /> }
                  onMouseOver={() => {
                      if (status.isChanged) {
                          handlePopHover('save', true);
                      }
                  }}
                  onMouseLeave={() => {
                      if (status.isChanged) {
                          handlePopHover('save', false);
                      }
                  }}
                  css="button-small button-primary h-12 w-12"
                  onClick={handleSave}
              /> */
                        )}

                        {/*  <div id="save" className="popHover-content">
                            Sauvegarder
                        </div> */}
                    </div>
                )}
                {buttons.publish && (
                    <div className="popHover">
                        <Button
                            label={status?.isPublished ? <EyeIcon className="w-4 h-4" /> : <EyeOffIcon className="w-4 h-4" />}
                            onMouseOver={() => handlePopHover('isPublished', true)}
                            onMouseLeave={() => handlePopHover('isPublished', false)}
                            css={`button-small h-8 w-8 ${status?.isPublished ? 'button-success' : 'button-warning'}`}
                            onClick={handleTogglePublish}
                        />
                        {/* <div id="isPublished" className="popHover-content"> */}
                        {/*   {status?.isPublished ? 'Dépublier' : 'Publier'} */}
                        {/* </div> */}
                    </div>
                )}
                {buttons.seeLink && status?.isPublished && !!status?.seeLink && (
                    <div className="popHover">
                        <Link to={status?.seeLink} onMouseOver={() => handlePopHover('seeLink', true)} onMouseLeave={() => handlePopHover('seeLink', false)} className="button button-small h-8 w-8 button-secondary flex items-center">
                            <ExternalLinkIcon className="w-4 h-4" />
                        </Link>
                        {/* <div id="seeLink" className="popHover-content"> */}
                        {/*   Voir la page */}
                        {/* </div> */}
                    </div>
                )}
            </div>
            {/*  <div className="md:hidden fixed bottom-10 right-5 p-2 z-20 flex gap-2 justify-center items-center rounded-full">
        <>
          {buttons.seeLink && status?.isPublished && !!status?.seeLink && (
            <Link to={status?.seeLink}>
              <button onClick={() => { }} className={`flex justify-center text-secondary items-center w-12 h-12 rounded-full bg-white shadow-xl`}>
                <ExternalLinkIcon className="w-8 h-8" />
              </button>
            </Link>
          )}

          {buttons.publish && (
            <button onClick={handleTogglePublish} className={`flex justify-center items-center w-12 h-12 rounded-full bg-white shadow-xl ${status?.isPublished ? 'bg-success-400 text-white' : 'bg-warning-400 text-white'}`}>
              {status?.isPublished ? <EyeOffIcon className="w-8 h-8" /> : <EyeIcon className="w-8 h-8" />}
            </button>
          )}

          {buttons.add && (
            <button onClick={handleAdd} className={`flex justify-center text-secondary items-center w-12 h-12 rounded-full bg-white shadow-xl`}>
              <PlusCircleIcon className="w-8 h-8" />
            </button>
          )}

          {buttons.save && (
            <>
              {buttons.progress && status.progress > 0 ? (
                <button className={`flex justify-center items-center w-12 h-12 rounded-full shadow-xl bg-white text-secondary`}>
                  <BanIcon className="w-8 h-8" />
                </button>
              ) : (
                <button onClick={handleSave} disabled={status?.isChanged ? false : true} className={`flex justify-center items-center w-12 h-12 rounded-full shadow-xl ${status?.isChanged ? 'bg-primary text-white' : 'bg-white text-secondary'}`}>
                  <CheckIcon className="w-8 h-8" />
                </button>
              )}
            </>
          )}
        </>
      </div> */}
        </>
    );
}

export default ReactiveSidebarForm;
