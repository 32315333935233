import { useEffect } from 'react';
import { createPortal } from 'react-dom';

import { useSpring, animated } from 'react-spring';

function ModalComment(props) {
    const handleClickOverlay = () => {
        props?.onClose?.onClick();
    };

    useEffect(() => {
        if (props.open) document.querySelector('body').style.overflow = 'hidden';
        else document.querySelector('body').style.overflow = 'auto';
    }, [props.open]);

    const openCloseSlideAnimationUserMenu = useSpring({
        transform: props.open ? 'translateY(0%)' : 'translateY(100%)',
        onRest: () => {
            if (!props.open) {
                props?.onClose?.onClick();
            }
        },
        config: {
            duration: 225,
        },
    });

    if (!props.open) return null;

    return createPortal(
        /*  <!-- Modal Container --> */
        <div
            className="modal-comment fixed flex flex-col justify-end top-0 left-0 w-full h-full z-50"
            onClick={(e) => {
                /*    e?.preventDefault(); */
                if (props.onCloseOverlay) {
                    handleClickOverlay();
                }
            }}
        >
            {/* <!-- Modal Background --> */}
            <div className="absolute top-0 left-0 w-full h-full bg-black backdrop-blur-sm opacity-20"></div>

            {/* <!-- Modal Content --> */}
            <animated.div
                style={openCloseSlideAnimationUserMenu}
                className="modal-comment-content flex flex-col justify-start rounded-t-3xl md:rounded-xl p-0 bg-white relative h-[70vh] overflow-hidden"
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                {/*  <!-- Modal Title --> */}
                {props?.title && <div className="modal-comment-title text-lg text-mainText font-bold p-2 border-b border-gray-200">{props?.title}</div>}
                {/*  <!-- Modal Main Content --> */}
                <div className="modal-comment-main flex flex-col flex-grow overflow-y-auto">{props.children}</div>
                {/*  <!-- Modal Actions --> */}
                {props?.actions && <div className="modal-comment-actions flex justify-end px-4 md:px-8 py-4 border-t border-gray-200 md:rounded-b-xl"></div>}
                {/* <!-- Modal Close Button --> */}
                <button className="modal-comment-close absolute cursor-pointer top-[-55px] p-2 text-xl z-99">{/*  <!-- Close Button Icon --> */}</button>
            </animated.div>
        </div>,
        document.body
    );
}

export default ModalComment;
