import { useContext, useRef, useState, useEffect } from 'react';

//UTILS
import config from 'utils/constants';
//import { isSmartphone } from '../../utils/functions';

//STYLES
import 'containers/cards.css';

//LIBS
//import _ from 'lodash';
import ReactPlayer from 'react-player';
import { Link, useLocation } from 'react-router-dom';

//COMPONENTS
import Button from 'components/common/Button';
// eslint-disable-next-line no-unused-vars
import Counter from 'components/Counter';
import Image from 'components/common/Image';
import Modal from 'components/common/Modal';
import ReactiveSidebarForm from 'components/forms/ReactiveSidebarForm';
import FormService from 'components/forms/service';
// eslint-disable-next-line no-unused-vars
import OptionsMenu from '../../components/common/OptionsMenu';
//CONTEXTS
import { AuthContext } from 'context/authContext';

//ICONS
// eslint-disable-next-line no-unused-vars
import { DotsVerticalIcon, PencilIcon, PlayIcon, XIcon } from '@heroicons/react/solid';

const sizes = {
    smallAvatar: '?w=40&h=40&c=true&q=80',
    avatar: '?w=130&h=130&c=true&q=80',
    cover: '?w=1000&h=600&c=true&q=80',
};

function CardFrontService({ service, refetch }) {
    const location = useLocation();
    // eslint-disable-next-line no-unused-vars
    const [liked, setLiked] = useState('HeartIconOutline');

    const [firstMedia, setFirstMedia] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);

    const [buttonModifService, setButtonModifService] = useState(false);
    const [modalModifService, setModalModifService] = useState(false);
    const [userIcon, setUserIcon] = useState(false);
    //CONTEXTS
    const [authState] = useContext(AuthContext);

    const videoRef = useRef(null);

    const [modalVideo, setModalVideo] = useState(false);

    useEffect(() => {
        getLikedService();
        location.pathname.includes('/profil') ? setButtonModifService(true) : setButtonModifService(false);
        location.pathname.includes('/services') && setUserIcon(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    // useEffect(() => {
    //     refetch?.();
    //     // eslint-disable-next-line
    // }, [authState.me]);
    function handleModifService(e) {
        e.stopPropagation();
        e.preventDefault();
        if (typeof window != 'undefined' && window.document) {
            document.body.style.overflow = 'hidden';
        }
        if (authState.isLogged) setModalModifService(true);
        else document.getElementById('button-login')?.click();
    }

    function closeOptionsMenu(service) {
        const els = document.getElementsByClassName('optionsMenu');
        [].forEach.call(els, function (el) {
            if (el.id !== `optionsMenu_${service.id}`) {
                el.classList.add('hidden');
            }
        });
    }

    // eslint-disable-next-line no-unused-vars
    function handleOptionsMenu(service) {
        closeOptionsMenu(service);
        document.getElementById(`optionsMenu_${service.id}`).classList.toggle('hidden');
    }

    function getLikedService() {
        for (let i = 0; i < service?.counters?.length; i++) {
            if (service?.counters[i].type === 'like_service' && service?.counters[i].user === authState.me?.id) {
                setLiked('HeartIcon');
                return;
            }
        }
        setLiked('HeartIconOutline');
    }

    function getFirstMedia() {
        for (let i = 0; i < service?.medias?.length; i++) {
            if (service?.medias[i]?.isFirst === true) {
                setFirstMedia(service?.medias[i]);
                return;
            }
        }
    }

    const handleVideoMouseOver = (e) => {
        if (firstMedia && firstMedia.type.includes('video')) {
            const video = e.currentTarget.querySelector('video');
            if (video && typeof video.play === 'function') {
                video.play().catch((error) => {
                    console.error('La lecture de la vidéo a échoué:', error);
                });
            }
        } else if (service?.video && videoRef.current) {
            const internalPlayer = videoRef.current.getInternalPlayer();
            if (internalPlayer && typeof internalPlayer.playVideo === 'function') {
                internalPlayer.playVideo();
            }
        }
        setIsPlaying(true);
    };

    const handleVideoMouseOut = (e) => {
        if (firstMedia && firstMedia.type.includes('video')) {
            const video = e.currentTarget.querySelector('video');
            if (video && typeof video.pause === 'function') {
                video.pause();
            }
        } else if (service?.video && videoRef.current) {
            const internalPlayer = videoRef.current.getInternalPlayer();
            if (internalPlayer && typeof internalPlayer.pauseVideo === 'function') {
                internalPlayer.pauseVideo();
            }
        }
        setIsPlaying(false);
    };

    useEffect(() => {
        if (service?.medias?.length > 0) {
            getFirstMedia();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [service]);

    useEffect(() => {
        if (firstMedia && firstMedia.type.includes('video')) {
            const video = videoRef.current;
            video.currentTime = 1.5;
        }
    }, [firstMedia]);

    return (
        <>
            <Modal
                open={modalVideo}
                title={false}
                fullScreen={true}
                noActions
                onClose={{
                    onClick: () => setModalVideo(false),
                }}
            >
                <ReactPlayer url={service?.video} width="100%" origin={window.location.origin} controls={true} className="aspect-video" />
            </Modal>
            <Modal
                open={modalModifService}
                overflow={true}
                modalWidth={'container relative mx-auto px-2 sm:px-4'}
                onClose={{
                    onClick: () => {
                        document.body.style.overflow = 'unset';
                        setModalModifService(false);
                    },
                }}
            >
                <FormService service={service} refetch={refetch} />
                <div className="sticky bottom-4 sm:bottom-0 z-[999999]">
                    <div className="flex items-center gap-4 justify-center">
                        <button
                            className="flex flex-row items-center gap-1 rounded-full px-4 py-1 bg-white button-border-gray border-2"
                            onClick={() => {
                                document.body.style.overflow = 'unset';
                                setModalModifService(false);
                            }}
                        >
                            <XIcon className="h-5 w-5 p-0" />
                            <span>Fermer</span>
                        </button>
                        <ReactiveSidebarForm />
                    </div>
                </div>
            </Modal>

            <div
                onMouseOver={handleVideoMouseOver}
                onMouseOut={handleVideoMouseOut}
                className={`bg-white rounded-3xl shadow-sm shadow-lightBlueSkills hover:shadow-xl hover:shadow-lightBlueSkills transition-shadow duration-400`}
            >
                <Link to={'/service/' + service?.slug}>
                    <div className="card_profile_images_cover relative h-auto">
                        <div className="w-full relative">
                            {firstMedia ? (
                                <>
                                    {firstMedia.type.includes('video') ? (
                                        <>
                                            <video
                                                ref={videoRef}
                                                src={`${
                                                    firstMedia?.encoded
                                                        ? `${config.serverUrl + `/stream/${service?.user?._id}/${firstMedia?._id}.mp4`}`
                                                        : `${config.serverUrl}${firstMedia.url}`
                                                }`}
                                                className="w-full object-cover h-32 sm:h-36 relative rounded-3xl max-h-32 sm:max-h-36 bg-black"
                                                /*  muted  */
                                                playsInline={true}
                                                type={firstMedia.type}
                                                controls={false}
                                            />
                                            {(isPlaying === false || isPlaying === null) && (
                                                <div className="absolute top-0 left-0 right-0 bottom-0 text-white text-xs font-bold p-0 rounded-full flex items-center justify-center">
                                                    {/* Ici, ajoutez les propriétés pour centrer */}
                                                    <Button label={<PlayIcon className="w-8 h-8 sm:w-10 sm:h-10" />} justIcon={true} css="p-0" />
                                                </div>
                                            )}
                                        </>
                                    ) : (
                                        <img
                                            className="w-full h-32 sm:h-36 relative rounded-3xl object-cover"
                                            src={`${config.serverUrl}${firstMedia.url}`}
                                            alt={firstMedia.name}
                                        />
                                    )}
                                </>
                            ) : (
                                <>
                                    {service?.video ? (
                                        <ReactPlayer
                                            ref={videoRef}
                                            url={service?.video}
                                            playsinline={true}
                                            width="100%"
                                            /*  height={`${isSmartphone() ? '6rem' : '8rem'}`} */
                                            origin={window.location.origin}
                                            className="w-full object-cover h-32 sm:h-36 relative rounded-3xl max-h-32 sm:max-h-36 bg-black"
                                            controls={false}
                                            playing={isPlaying}
                                            config={{
                                                youtube: {
                                                    playerVars: {
                                                        showinfo: 0,
                                                        controls: 0,
                                                        modestbranding: 1,
                                                        playsinline: 1,
                                                    },
                                                },
                                            }}
                                            style={{ overflow: 'hidden', pointerEvents: 'none' }}
                                        />
                                    ) : (
                                        <>
                                            {service?.cover?.url ? (
                                                <Image
                                                    src={service?.cover?.url}
                                                    dynamic
                                                    alt={service?.data?.title}
                                                    loading="false"
                                                    className="w-full h-32 sm:h-36 relative rounded-3xl object-cover"
                                                />
                                            ) : (
                                                <>
                                                    {service?.user?.profile?.avatar?.url ? (
                                                        <img
                                                            className="w-full h-32 sm:h-36 relative rounded-3xl object-cover"
                                                            src={config.serverUrl + service?.user?.profile?.avatar?.url}
                                                            alt={service?.user?.profile?.pseudo}
                                                        />
                                                    ) : (
                                                        <img
                                                            className="w-full h-32 sm:h-36 relative rounded-3xl object-cover"
                                                            src={`${config.publicDir}skillsLogo.jpg` + sizes.avatar}
                                                            alt={service?.user?.profile?.pseudo}
                                                        />
                                                    )}
                                                </>
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                        </div>

                        <div className="flex flex-col">
                            <div className="relative flex justify-between gap-2 pt-2 pb-1 px-1">
                                <div className="flex flex-row items-center w-full h-auto relative">
                                    <div className=" w-full sm:overflow-hidden text-center">
                                        <p
                                            className="h2 text-purpleSkills text-[14px] hover:text-blueMain h-[29px] w-full leading-none"
                                            style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
                                        >
                                            {service?.title}
                                        </p>
                                    </div>
                                    {buttonModifService && authState?.me?.id === service?.user?.id && (
                                        <>
                                            <Button
                                                label={<PencilIcon className="w-6 h-4 mx-auto" />}
                                                justIcon={true}
                                                css="w-6 h-6 text-white rounded-full bg-blueMain"
                                                onClick={handleModifService}
                                            />
                                        </>
                                    )}
                                </div>
                            </div>

                            <div className="flex items-center justify-center gap-2 bg-white text-purpleSkills text-xs rounded-full p-1">
                                {userIcon && (
                                    <div className="shrink-0 w-8">
                                        {service?.user?.profile?.avatar?.url ? (
                                            <Image
                                                src={service?.user?.profile?.avatar?.url + sizes.avatar}
                                                alt={service?.user?.profile?.pseudo}
                                                loading="false"
                                                dynamic
                                                className="rounded-full"
                                            />
                                        ) : (
                                            <img
                                                className="rounded-full"
                                                src={`${config.publicDir}skillsLogo.jpg` + sizes.avatar}
                                                alt={service?.user?.profile?.pseudo}
                                            />
                                        )}
                                    </div>
                                )}
                                <div className="flex-grow text-right pr-2">
                                    {service?.price > 0 ? (
                                        <p>
                                            Dès <span className="font-bold text-base">{service.price.toLocaleString()} €</span>
                                        </p>
                                    ) : (
                                        <p>Demander prix</p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
        </>
    );
}

export default CardFrontService;
