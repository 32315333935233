import { useContext, useEffect, useState } from 'react';

//LIBS
import _ from 'lodash';

//CONTEXTS
import { HeadlessContext } from '../index.js';

/**
 * React custom Hook
 * @returns {Object}
 */
export default function useHeadlessModule() {
    const store = useContext(HeadlessContext);

    const [isModulesLoaded, setIsModulesLoaded] = useState(false);
    const [hooks, setHooks] = useState({
        onReady: false,
    });

    const [runners, setRunners] = useState({});
    /**
     * Add an Headless module
     * @add
     * @param {string} key - The uniqKey of the module.
     * @param {React.Component} jsx - The jsx of the module.
     * @param {Objet} options - The global options of the module.
     */
    function add(key, jsx, options) {
        store.set({ type: 'addModule', key, jsx, options });
    }

    function onHook(key, callback) {
        setRunners({ ...runners, [key]: callback });
    }

    useEffect(() => {
        if (hooks.onReady) {
            _.forEach(runners, (runner) => {
                runner?.onReady?.func?.(store, runner?.onReady?.params);
            });
        }
        //eslint-disable-next-line
    }, [hooks]);

    useEffect(() => {
        if (isModulesLoaded) setHooks({ ...hooks, onReady: true });
        //eslint-disable-next-line
    }, [isModulesLoaded]);

    useEffect(() => {
        const modulesLoaded = store.get('modulesLoaded');

        if (modulesLoaded?.length > 0 && !isModulesLoaded) {
            setIsModulesLoaded(true);
        }
        //eslint-disable-next-line
    }, [store.get('modulesLoaded')]);

    return { add, onHook };
}
