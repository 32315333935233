import API from './axios.config';
import authHeader from 'services/Auth.Header';

//GET
async function getShoots() {
    const response = await API.get('shoots/_all');
    return response.data;
}

async function getShootsByUser(id, limit) {
    const response = await API.get(`shoots/sample/user/` + id + `?limit=${limit}`);
    return response.data;
}

async function getShoot(slug) {
    const response = await API.get('shoots/' + slug);
    return response.data;
}

async function getShootsP(params, isLogged, isNew) {
    let slug = '';
    if (isLogged) {
        if (isNew) {
            slug = 'shoots/custom/news';
        } else {
            slug = 'shoots/custom';
        }
    } else {
        if (isNew) {
            slug = 'shoots/interest/news';
        } else {
            slug = 'shoots/interest';
        }
    }
    const response = isLogged ? await API.get(slug + params, { headers: authHeader() }) : await API.get(slug + params);
    return response.data;
}

async function getShootsPaginate(params, userId) {
    const response = await API.get('shoots/interest/p?' + params);
    return response.data;
}

async function getShootsNewsPaginate(params, userId) {
    const response = await API.get('shoots/interest/news/p?' + params + `${userId ? `&user=${userId}` : ''}`);
    return response.data;
}

async function getShootsByMe(params) {
    const response = await API.get('shoots/me' + params, { headers: authHeader() });
    return response.data;
}

async function getShootsPaginateByUser(params) {
    const response = await API.get(`shoots/user/p?` + params, { headers: authHeader() });
    return response.data;
}

async function getShootsFav() {
    const response = await API.get('shoots/fav', { headers: authHeader() });
    return response.data;
}

async function getShootsAnswers(params, shootId) {
    const response = await API.get('shoots/answers/p?' + params + `${shootId ? `&id=${shootId}` : ''}`);
    return response.data;
}

//POST
async function postShoot(data, onProgress) {
    const response = await API.post('shoots/shoot', data, {
        headers: authHeader(),
        onUploadProgress: onProgress,
    });
    return response;
}

async function postIncrement(shootId) {
    const response = await API.post('shoots/increment/' + shootId);
    return response.status;
}

async function postAnswerShoot(data) {
    const response = await API.post('shoots/answer', data, {
        headers: authHeader(),
    });
    return response;
}

async function postAnswerToShoot(data) {
    const response = await API.post('shoots/answerTo', data, {
        headers: authHeader(),
    });
    return response;
}

async function postAnswerToAnswerShoot(data) {
    const response = await API.post('shoots/addAnswerToAnswer', data, {
        headers: authHeader(),
    });
    return response;
}

//PUT
async function putShootPublish(id) {
    const response = await API.put(
        'shoots/togglePublish/' + id,
        { publish: true },
        {
            headers: authHeader(),
        }
    );
    return response.data;
}

async function putSelectScreen(id, k) {
    const response = await API.put(
        'shoots/selectScreen/' + id,
        { k: k },
        {
            headers: authHeader(),
        }
    );
    return response.data;
}

async function putSelectAnswer(id, type) {
    const response = await API.put('shoots/selectAnswer/' + id, { type }, { headers: authHeader() });
    return response.data;
}

async function putAnswer(id, type, data) {
    const response = await API.put('shoots/updateAnswer/', { id, type, data }, { headers: authHeader() });
    return response.data;
}

//DELETE
async function deleteShoot(id) {
    const response = await API.delete('shoots/' + id, {
        headers: authHeader(),
    });
    return response;
}

async function deleteAnswer(id, type) {
    const response = await API.delete(`shoots/deleteAnswer/${id}`, {
        data: {
            type: type,
        },
        headers: authHeader(),
    });
    return response.data;
}

export {
    getShoots,
    getShoot,
    getShootsByUser,
    getShootsP,
    getShootsPaginate,
    getShootsByMe,
    getShootsPaginateByUser,
    getShootsFav,
    getShootsAnswers,
    postShoot,
    postIncrement,
    postAnswerShoot,
    postAnswerToShoot,
    postAnswerToAnswerShoot,
    putShootPublish,
    putSelectScreen,
    putSelectAnswer,
    putAnswer,
    deleteShoot,
    deleteAnswer,
    getShootsNewsPaginate,
};
