import API from './axios.config';
import authHeader from 'services/Auth.Header';

async function getServices() {
    const response = await API.get('services');
    return response.data;
}
async function getServicesP(params) {
    const response = await API.get('services' + params);
    return response.data;
}

async function getServicesPByTags(params, tag) {
    const response = await API.get('services/tag' + params + '&tag=' + tag);
    return response.data;
}

async function getServicesByMe() {
    const response = await API.get('services/me', { headers: authHeader() });
    return response.data;
}

async function getServicesByUser(userId, page) {
    const response = await API.get('services/user/' + userId + '?&p=' + page);
    return response.data;
}

async function getServicesByUserForProfile(userId, page) {
    const response = await API.get('services/user/' + userId + '/all');
    return response.data;
}

async function getService(id) {
    const response = await API.get('services/' + id, {
        headers: authHeader(),
    });
    return response.data;
}

async function getSingleService(slug) {
    const response = await API.get('services/slug/' + slug);
    return response.data;
}

async function getServicesBySubCategory(params, category) {
    const response = await API.get('services/categories/sub/' + category + params);
    return response.data;
}

async function getServicesByMainCategory(params, category) {
    const response = await API.get('services/categories/main/' + category + params);
    return response.data;
}

async function postService(data) {
    const response = await API.post('services/create', data, {
        headers: authHeader(),
    });
    return response;
}

async function putService(id, data, onProgress) {
    const response = await API.put('services/' + id, data, {
        headers: authHeader(),
        onUploadProgress: onProgress,
    });
    return response;
}

async function putServicePublish(id) {
    const response = await API.put(
        'services/togglePublish/' + id,
        { publish: true },
        {
            headers: authHeader(),
        }
    );
    return response.data;
}

async function deleteService(id) {
    const response = await API.delete('services/' + id, {
        headers: authHeader(),
    });
    return response.data;
}

export {
    getServices,
    getServicesP,
    getServicesPByTags,
    getServicesByMe,
    getServicesByUser,
    getServicesByUserForProfile,
    getService,
    getSingleService,
    getServicesBySubCategory,
    getServicesByMainCategory,
    postService,
    putService,
    putServicePublish,
    deleteService,
};
