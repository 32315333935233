import React from 'react';
import { Helmet } from 'react-helmet-async';

import config from 'utils/constants';

export default function SEO({title, description, name, type, image}) {
    const sizes = {
        avatar: '?w=1200&h=627'
    };

    if (!image) 
        image = config.publicDir + 'share.png' + sizes.avatar;
    else
        image = config.serverUrl + image + sizes.avatar;

    return (
        <Helmet>
            { /* Standard metadata tags */ }
            <title>{title}</title>
            <meta name='description' content={description} />
            <meta name='image' ccontent={config.serverUrl + image + sizes.avatar} />
            <meta
                name="viewport"
                content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1, user-scalable=no"
                charSet="utf-8"
            />
            { /* End standard metadata tags */ }
            { /* Facebook tags */ }
            <meta property="og:type" content={type} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={image} />
            <meta property="og:site_name" content="SkillsMarket"/>
            { /* End Facebook tags */ }
            { /* Twitter tags */ }
            <meta name="twitter:creator" content={name} />
            <meta name="twitter:card" content={type} />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            { /* End Twitter tags */ }
        </Helmet>
    )
}