import API from "./axios.config";
import authHeader from "services/Auth.Header";

async function getVideos() {
    const response = await API.get("videos");
    return response.data;
}

async function getVideosByMe() {
    const response = await API.get("videos/me", { headers: authHeader() });
    return response.data;
}

async function getVideosByUser(userId) {
    const response = await API.get("videos/user/" + userId, {
        headers: authHeader(),
    });
    return response.data;
}

async function getVideo(id) {
    const response = await API.get("videos/" + id, {
        headers: authHeader(),
    });
    return response.data;
}

async function getSingleVideo(slug) {
    const response = await API.get("videos/slug/" + slug);
    return response.data;
}

async function postVideo(data) {
    const response = await API.post("videos/create", data, {
        headers: authHeader(),
    });
    return response;
}

async function putVideo(id, data, onProgress) {
    const response = await API.put("videos/" + id, data, {
        headers: authHeader(),
        onUploadProgress: onProgress,
    });
    return response;
}

async function putVideoPublish(id) {
    const response = await API.put(
        "videos/togglePublish/" + id,
        { publish: true },
        {
            headers: authHeader(),
        }
    );
    return response.data;
}

async function deleteVideo(id) {
    const response = await API.delete("videos/" + id, {
        headers: authHeader(),
    });
    return response.data;
}

async function deleteMedia(id) {
    const response = await API.delete("videos/media/" + id, {
        headers: authHeader(),
    });
    return response.data;
}

export { getVideos, getVideosByMe, getVideosByUser, getVideo, getSingleVideo, postVideo, putVideo, putVideoPublish, deleteVideo, deleteMedia };
