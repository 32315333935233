import { useEffect, useState, useContext } from 'react';
import { useSpring, animated } from 'react-spring';
//API
import { useQuery } from 'react-query';
import { getAll } from 'api/search';

//STYLES
import 'App.css';

//LAYOUT
import SearchLayout from 'pages/layouts/Search';

//LIBS
import { useSearchParams } from 'react-router-dom';

//CONTEXT
import { AuthContext } from 'context/authContext';
import { StoreContext } from 'context/storeContext';
//COMPONENTS
import SideNav from 'components/common/nav/SideNav';
// import SectionTitle from 'components/common/SectionTitle';

//CONTAINERS
import SearchProfils from 'containers/search/SearchProfils';
import SearchRooms from 'containers/search/SearchRooms';
import SearchJobs from 'containers/search/SearchJobs';
import SearchShoots from 'containers/search/SearchShoots';

import SearchServices from 'containers/search/SearchServices';
//import SearchVideos from 'containers/search/SearchVideos';
// import SearchJobs from 'containers/search/SearchJobs';

//HOOKS
import useSafeState from 'hooks/useSafeState';

import ClipLoader from 'react-spinners/ClipLoader';
import JobDetail from '../containers/rooms/JobDetail';

function Search() {
    const store = useContext(StoreContext);

    const [users, setUsers] = useSafeState([]);
    const [services, setServices] = useSafeState([]);
    // eslint-disable-next-line no-unused-vars
    const [videos, setVideos] = useSafeState([]);
    const [rooms, setRooms] = useSafeState([]);
    const [shoots, setShoots] = useSafeState([]);
    // eslint-disable-next-line no-unused-vars
    const [jobs, setJobs] = useSafeState([]);

    const [place, setPlace] = useSafeState();

    const [filter, setFilter] = useState('all');

    const [range, setRange] = useState(10);

    const [authState] = useContext(AuthContext);

    //API
    let [searchParams] = useSearchParams();
    const q = searchParams.get('q');

    const search = useQuery(['search', q, place, range], (params) => getAll(q, place, authState?.me?.id, range), {
        retry: 2,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
    });

    function handleRefetch(latlng) {
        setPlace(latlng);
    }

    function handleSearchFilter(e, type) {
        var filterContainer = document.querySelectorAll('.filterContainer button');
        if (filterContainer.length > 0) {
            for (var i = 0; i < filterContainer.length; i++) {
                filterContainer[i].classList.remove('bg-graySkills');
                filterContainer[i].classList.remove('text-white');
            }
        }
        e.target.classList.add('bg-graySkills');
        e.target.classList.add('text-white');
        setFilter(type);
    }

    function onRangeChange(range) {
        setRange(range);
    }

    useEffect(() => {
        search.refetch();
        //eslint-disable-next-line
    }, [place, range]);

    useEffect(() => {
        if (search.isSuccess) {
            let tmpUsers = [];
            let tmpServices = [];
            let tmpVideos = [];
            let tmpRooms = [];
            let tmpJobs = [];
            let tmpShoots = [];
            search?.data?.map((item) => {
                if (item?.entity === 'user') {
                    tmpUsers = [...tmpUsers, item];
                }
                if (item?.entity === 'service') {
                    tmpServices = [...tmpServices, item];
                }
                if (item?.entity === 'video') {
                    tmpVideos = [...tmpVideos, item];
                }
                if (item?.entity === 'room' && (item.type === 'news' || item.type === 'question' || item.type === 'post')) {
                    tmpRooms = [...tmpRooms, item];
                }
                if (item?.entity === 'room' && item.type === 'job') {
                    tmpJobs = [...tmpJobs, item];
                }
                if (item?.entity === 'shoot') {
                    tmpShoots = [...tmpShoots, item];
                }
                return void 0;
            });
            tmpUsers.sort((a, b) => (a.points < b.points ? 1 : -1));
            setUsers(tmpUsers);
            setServices(tmpServices);
            setVideos(tmpVideos);
            setRooms(tmpRooms);
            setJobs(tmpJobs);
            setShoots(tmpShoots);
        }
        //eslint-disable-next-line
    }, [search?.data]);

    const closeSlideAnimationSideNav = useSpring({
        transform: store.get('openJobDetail') ? 'translateX(-19%)' : 'translateX(0%)',
        config: {
            duration: 140,
        },
    });

    return (
        <>
            <JobDetail />
            <SearchLayout onRefectch={handleRefetch} onFilter={handleSearchFilter} noContain={store.get('openJobDetail') ? true : false}>
                <animated.div style={closeSlideAnimationSideNav} className="container mb-12">
                    <div className="grid grid-cols-12 gap-7 pb-4 flex-col-reverse md:flex-col">
                        <div className="col-span-12 md:col-span-3 order-last md:order-first">
                            <SideNav isSearchLocation={true} />
                        </div>
                        <div className="col-span-12 md:col-span-9">
                            <main>
                                {search.isSuccess ? (
                                    <>
                                        {users.length > 0
                                            ? (filter === 'all' || filter === 'users') && (
                                                  <SearchProfils users={users} refetch={search.refetch} filter={filter === 'users' ? true : false} />
                                              )
                                            : filter === 'users' && (
                                                  <p className="text-xs text-graySkills mx-0 my-4 mt-8 text-center">
                                                      <span className="h2 mx-0 my-2 mt-4 text-bold text-purpleSkills">Aucun Skiller trouvé</span>
                                                  </p>
                                              )}
                                        {services.length > 0
                                            ? (filter === 'all' || filter === 'services') && (
                                                  <SearchServices
                                                      services={services}
                                                      refetch={search.refetch}
                                                      isSearch={true}
                                                      filter={filter === 'services' ? true : false}
                                                  />
                                              )
                                            : filter === 'services' && (
                                                  <p className="text-xs text-graySkills mx-0 my-4 mt-8 text-center">
                                                      <span className="h2 mx-0 my-2 mt-4 text-bold text-purpleSkills">Aucun Service / produit trouvé</span>
                                                  </p>
                                              )}
                                        {/*
                                    {videos.length > 0 && (filter === "all" || filter === "videos") && (
                                        <SearchVideos videos={videos} refetch={search.refetch} />
                                    )}
                                    */}
                                       {jobs.length > 0
                                            ? (filter === 'all' || filter === 'jobs') && (
                                                <SearchJobs
                                                    jobs={jobs}
                                                    refetch={search.refetch}
                                                    onRangeChange={(range) => onRangeChange(range)}
                                                    filter={filter === 'jobs' ? true : false}
                                                />
                                            )
                                            : filter === 'jobs' && (
                                                <p className="text-xs text-graySkills mx-0 my-4 mt-8 text-center">
                                                    <span className="h2 mx-0 my-2 mt-4 text-bold text-purpleSkills">Aucun Job trouvé</span>
                                                </p>
                                            )}
                                        {shoots.length > 0
                                            ? (filter === 'all' || filter === 'shoots') && (
                                                  <SearchShoots shoots={shoots} refetch={search.refetch} filter={filter === 'shoots' ? true : false} />
                                              )
                                            : filter === 'shoots' && (
                                                  <p className="text-xs text-graySkills mx-0 my-4 mt-8 text-center">
                                                      <span className="h2 mx-0 my-2 mt-4 text-bold text-purpleSkills">Aucun Shoot trouvé</span>
                                                  </p>
                                              )}
                                        {rooms.length > 0
                                            ? (filter === 'all' || filter === 'rooms') && (
                                                  <SearchRooms rooms={rooms} refetch={search.refetch} filter={filter === 'rooms' ? true : false} />
                                              )
                                            : filter === 'rooms' && (
                                                  <p className="text-xs text-graySkills mx-0 my-4 mt-8 text-center">
                                                      <span className="h2 mx-0 my-2 mt-4 text-bold text-purpleSkills">Aucun Post trouvé</span>
                                                  </p>
                                              )}
                                    </>
                                ) : (
                                    <div className="flex justify-center items-center h-64">
                                        <div className="flex flex-col gap-4 items-center">
                                            <ClipLoader color="#9FA5C0" size={25} />
                                        </div>
                                    </div>
                                )}
                            </main>
                        </div>
                    </div>
                </animated.div>
            </SearchLayout>
        </>
    );
}
export default Search;
