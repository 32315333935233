import { Fragment, useContext, useEffect, useRef, useState } from 'react';

//LIBS
import _ from 'lodash';
import { useLocation } from 'react-router-dom';

//STYLES
import './tabs.css';

import useScroll from 'hooks/useScroll';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';

//CONTEXT
import { StoreContext } from 'context/storeContext';
import { isSmartphone } from '../../utils/functions';

function TabsProfil(props) {
    const refTabs = useRef(null);
    const swipeContainerRef = useRef(null);

    const scrollDirection = useScroll();
    const location = useLocation();

    const store = useContext(StoreContext);

    // eslint-disable-next-line no-unused-vars
    const [isHomeLocation, setIsHomeLocation] = useState(false);
    const [allTabs, setAllTabs] = useState(props.tabs);
    const [currentTab, setCurrentTab] = useState(props.initPosition ?? 0);

    // eslint-disable-next-line no-unused-vars
    const [isStickyActive, setIsStickyActive] = useState(false);

    /*  useEffect(() => {
        if (isSmartphone()) {
            const handleScroll = () => {
                const tabTop = refTabs.current.getBoundingClientRect().top;
                // mobile const stickyPosition = 52;
                const stickyPosition = 70;
                setIsStickyActive(window.pageYOffset >= window.scrollY + tabTop - stickyPosition);
            };

            window.addEventListener('scroll', handleScroll);
            return () => window.removeEventListener('scroll', handleScroll);
        }
    }, []); */

    useEffect(() => {
        setIsHomeLocation(location.pathname === '/' || location.pathname === '/home' ? true : false);
    }, [location.pathname]);

    function closeJobDetail() {
        const currentState = window.history.state;

        if (currentState && currentState.originalUrl) {
            window.history.replaceState({}, '', currentState.originalUrl);
        }
        store.set({ type: 'del', key: 'openJobDetail' });
    }

    function handleClick(e, tab) {
        e?.stopPropagation();
        e?.preventDefault();

        if (tab?.key !== 'tab_jobs') {
            closeJobDetail();
        }

        const index = allTabs.findIndex((t) => t.key === tab.key);

        if (swipeContainerRef.current && swipeContainerRef.current.swiper) {
            swipeContainerRef.current.swiper.slideTo(index); // Directly change swiper slide
        }

        // Removing 'active' class from all items
        const items = refTabs.current.getElementsByClassName('Tabs_item');
        Array.from(items).forEach((item) => {
            item.classList.remove('active');
        });

        // Adding 'active' class to current item and moving the slider
        const parentElement = e.currentTarget.parentNode;
        parentElement.classList.add('active');
        moveSlider(parentElement);

        if (typeof props?.refetchFav === 'function') {
            props.refetchFav();
        }

        window.history.replaceState({ tab: tab.key }, '', `#${tab.key}`);

        if (typeof props?.handleSwitchTab === 'function') {
            props.handleSwitchTab(tab.key);
        }
    }

    function moveSlider(elem) {
        elem.classList.add('active');

        requestAnimationFrame(() => {
            const sliderDetails = getChildNodeIndex(elem);
            const width = elem.offsetWidth;

            if (refTabs.current) {
                const sliderElem = refTabs.current.getElementsByClassName('Tabs_slider')[0];
                if (sliderElem) {
                    sliderElem.style.left = `${sliderDetails.left}px`;
                    sliderElem.style.width = `${width}px`;
                }
            }
        });
    }

    function getChildNodeIndex(elem) {
        let left = elem.offsetLeft;
        let position = 0;
        while (elem) {
            elem = elem.previousElementSibling;
            position++;
        }

        setCurrentTab(position - 1);

        return { position: position - 1, left };
    }
    function init(tabPosition) {
        if (refTabs.current) {
            const items = refTabs.current.getElementsByClassName('Tabs_item');

            _.forEach(items, (item, k) => {
                if (k === tabPosition) {
                    const timeout = setTimeout(() => {
                        moveSlider(item);
                        clearTimeout(timeout);
                    }, 300);
                }
            });
        }
    }

    useEffect(() => {
        if (props.tabs) {
            _.remove(props.tabs, (n) => {
                return n.visible === false;
            });
            setAllTabs(props.tabs);
        }
        //eslint-disable-next-line
    }, [props.tabs]);

    useEffect(() => {
        if (allTabs.length !== props?.tabs?.length) init(props.initPosition ?? 0);
        //eslint-disable-next-line
    }, [allTabs]);

    useEffect(() => {
        init(props.initPosition ?? 0);
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        setCurrentTab(0);
        init(props.initPosition ?? 0);
        //eslint-disable-next-line
    }, [location.key]);

    useEffect(() => {
        if (props.onTabChange) {
            props.onTabChange(allTabs[currentTab]?.key);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentTab, allTabs]);

    useEffect(() => {
        const handleResize = () => {
            const activeTab = refTabs.current?.querySelector('.Tabs_item.active');
            if (activeTab) {
                moveSlider(activeTab);
            }
        };

        window.addEventListener('resize', handleResize);

        const tabsElement = refTabs.current;
        if (tabsElement) {
            const resizeObserver = new ResizeObserver(handleResize);
            resizeObserver.observe(tabsElement);

            return () => {
                window.removeEventListener('resize', handleResize);
                resizeObserver.disconnect();
            };
        }

        return () => {
            window.removeEventListener('resize', handleResize);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function onSlideChange(swiper) {
        const activeIndex = swiper.activeIndex;
        const tabsElements = refTabs.current.getElementsByClassName('Tabs_item');

        Array.from(tabsElements).forEach((elem, index) => {
            elem.classList.remove('active'); // Remove active class from all tabs
            if (index === activeIndex) {
                elem.classList.add('active'); // Add active class to the current tab
                moveSlider(elem); // Move slider to the current tab
                setCurrentTab(activeIndex);

                // Update the URL with the current tab's key
                const tabKey = allTabs[activeIndex].key;

                window.history.replaceState({ tab: tabKey }, '', `#${tabKey}`);
                if (typeof props?.handleSwitchTab === 'function') {
                    props.handleSwitchTab(tabKey);
                }
            }
        });
    }

    useEffect(() => {
        const tabKeyToSwitch = props?.switchTab;
        if (tabKeyToSwitch) {
            const tabIndex = allTabs.findIndex((tab) => tab.key === tabKeyToSwitch);
            if (tabIndex !== -1 && tabIndex !== currentTab) {
                setCurrentTab(tabIndex);
                if (swipeContainerRef.current && swipeContainerRef.current.swiper) {
                    swipeContainerRef.current.swiper.slideTo(tabIndex);

                    window.history.replaceState({ tab: tabKeyToSwitch }, '', `#${tabKeyToSwitch}`);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.switchTab]);

    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
            window.requestAnimationFrame(() => {
                if (swipeContainerRef.current && swipeContainerRef.current.swiper) {
                    swipeContainerRef.current.swiper.updateAutoHeight();
                }
            });
        });

        const slides = document.querySelectorAll('.SwiperSlide');
        slides.forEach((slide) => {
            resizeObserver.observe(slide);
        });

        return () => resizeObserver.disconnect();
    }, [allTabs]);

    const stickyTabs = `-mx-2 w-auto sm:mx-0 sticky transition-all duration-300 ease-out ${
        scrollDirection === 'down' ? props.stickyDown || 'top-[52px] sm:top-[64px]' : props.stickyUp || 'top-[52px] sm:top-[64px]'
    }`;

    return (
        <div className="relative w-full flex flex-col" style={{ minHeight: isSmartphone() ? 'calc(100vh - 110px)' : 'none' }}>
            <div
                className={`${stickyTabs} ${props.visibleTabs === false ? 'hidden' : 'hidden sm:block'} bg-opacity-50 backdrop-blur-lg bg-blancSkills z-10`}
                style={{ transform: 'translateZ(0px)' }}
            >
                <ul className="Tabs relative flex items-center justify-center text-center flex-1 overflow-x-auto scrollbar-hide" ref={refTabs}>
                    {allTabs
                        ?.filter((i) => i.visible !== false)
                        ?.map((tab, k) => (
                            <Fragment key={k}>
                                {tab.visible !== false && (
                                    <li className="Tabs_item">
                                        <a
                                            className="relative"
                                            href={`#${tab.key}`}
                                            onClick={(e) => {
                                                handleClick(e, tab);
                                            }}
                                        >
                                            {tab.label}
                                        </a>
                                    </li>
                                )}
                            </Fragment>
                        ))}
                    <li className="Tabs_slider" role="presentation" style={{ left: 'calc(25% - 24px)' }}></li>
                </ul>
            </div>

            <div className="-mx-2">
                <Swiper
                    ref={swipeContainerRef}
                    slidesPerView={1}
                    spaceBetween={30}
                    initialSlide={props.initialSlide}
                    pagination={{ clickable: true }}
                    className={`w-[100vw] sm:w-full h-full`}
                    onSlideChange={onSlideChange}
                    autoHeight={true}
                >
                    {allTabs.map((tab, k) => (
                        <SwiperSlide className="" key={k}>
                            <div
                                className="overflow-x-hidden sm:mb-0 w-full h-full px-2"
                                style={{
                                    overflowY: isStickyActive ? 'auto' : 'hidden',
                                    minHeight: isSmartphone() ? 'calc(100vh - 158px)' : 'calc(100vh - 240px)',
                                    touchAction: 'pan-y',
                                }}
                            >
                                {tab.content}
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    );
}

export default TabsProfil;
