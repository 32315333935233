import { useState, useContext, useEffect, useRef } from 'react';
//API
import { sendTokenToServer } from '../api/users';
//LIBS
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
// import GoogleLogin from 'react-google-login';

//HOOKS
import UseAuth from 'hooks/useAuth';

//FORMS
import schema from 'components/forms/yup/signup';

//CONTEXTS
import { AuthContext } from 'context/authContext';

//COMPONENTS
import Alert from 'components/Alert';

import { EyeIcon, EyeOffIcon, XCircleIcon } from '@heroicons/react/outline';

function Login() {
    const [authState] = useContext(AuthContext);
    const navigate = useNavigate();

    const location = useLocation();
    /* const queryParams = new URLSearchParams(location.search);
    const firebaseToken = queryParams.get('token');
    const sanitizedToken = firebaseToken ? firebaseToken.replace(/['"]+/g, '') : null; */
    const [token, setToken] = useState(null);
    //HOOKS
    // eslint-disable-next-line no-unused-vars
    const {
        isSubmitted,
        isForgotSubmitted,
        hasError,
        errorMsg,
        login,
        forgotPassword,
        // reload,
    } = UseAuth();

    const [inputsConnexion, setInputsConnexion] = useState({
        email: '',
        password: '',
        confirm: '',
        firstname: '',
        lastname: '',
        society: '',
        typeInscription: '',
    });
    const [showPassword, setShowPassword] = useState(false);
    const [isForgot, setIsForgot] = useState(false);
    const [isSignup, setIsSignup] = useState(false);
    const [isGoogleLoaded, setIsGoogleLoaded] = useState(false);

    const ggRef = useRef();
    const connctionRef = useRef();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    /* useEffect(() => {
        if (authState.isLogged) {
            navigate('/');
        }
    }, [authState, navigate]); */

    useEffect(() => {
        if (isSignup) {
            navigate('/register');
        }
    }, [isSignup, navigate]);

    useEffect(() => {
        // Fonction pour vérifier si window.google est défini
        const checkGoogleLoaded = () => {
            if (window.google) {
                setIsGoogleLoaded(true);
            }
        };

        // Vérifier si window.google est déjà disponible lors du montage initial du composant
        checkGoogleLoaded();

        // Si window.google n'est pas encore disponible, on vérifie à intervalles réguliers
        const interval = setInterval(checkGoogleLoaded, 1000);

        // Nettoyer l'intervalle lorsque le composant est démonté
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (!window.google || isForgot) return;

        if (ggRef.current && connctionRef.current) {
            window.google.accounts.id.renderButton(ggRef.current, {
                theme: 'outline',
                size: 'large',
                shape: 'pill',
                width: connctionRef.current.offsetWidth + 'px',
            });
        }
        //eslint-disable-next-line
    }, [isForgot, isGoogleLoaded]);

    function handleChangeFormConnexion(e) {
        setInputsConnexion({
            ...inputsConnexion,
            [e.target.name]: e.target.value,
        });
    }

    function onSubmitLogin() {
        //console.log("onSubmitLogin")
        login(inputsConnexion);
    }

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const firebaseToken = queryParams.get('token');
        // Mise à jour du token dans le state
        setToken(firebaseToken);
    }, [location.search]); // Exécute l'effet chaque fois que location.search change

    useEffect(() => {
        if (authState.isLogged) {
            if (token) {
                sendTokenToServer({ firebaseToken: token })
                    .then((response) => {
                        console.log('Token sent and received response:', response);
                    })
                    .catch((error) => {
                        console.error('Error sending token:', error);
                    });
            }
            navigate('/');
        }
    }, [authState.isLogged, token, navigate]);

    function changePassword() {
        setIsForgot(false);
        navigate('/register');
    }

    function handleGoToHome() {
        navigate('/');
    }

    function handleShowPassword(e) {
        e.preventDefault();
        e.stopPropagation();
        setShowPassword(!showPassword);
    }

    return (
        <div className="container">
            <div className="sm:grid sm:grid-cols-12 sm:gap-8 pb-4">
                <div className="sm:col-span-9 lg:col-span-8">
                    <main className="p-10">
                        {!isForgot && (
                            <>
                                <h1 className="-mx-2 md:-mx-8 -mt-8 text-lg text-mainText font-bold px-2 md:px-8 py-4 border-b border-gray-200;">
                                    Connexion à Skillsmarket
                                    <div className="text-mainText absolute -mt-8 right-4 justify-end" onClick={handleGoToHome}>
                                        <XCircleIcon className="w-7" />
                                    </div>
                                </h1>

                                {hasError && isSubmitted && !authState.isLogged && (
                                    <Alert type="danger" message="Mot de passe ou identifiant incorrect, vous pouvez réinitialiser votre mot de passe." />
                                )}
                                <form className="mt-8 m-0" autoComplete="off">
                                    <div className="relative z-0 w-full mb-6 group">
                                        <input
                                            type="text"
                                            name="email"
                                            value={inputsConnexion.email}
                                            {...register('email')}
                                            onChange={handleChangeFormConnexion}
                                            className="input_floating peer"
                                            placeholder=" "
                                            required
                                        />
                                        <label htmlFor="floating_email" className="input_labelFloating">
                                            Adresse email
                                        </label>
                                        {errors && errors['email'] && <span className={`mt-0 text-sm text-danger-400`}>{errors['email'].message}</span>}
                                    </div>
                                    <div className="relative z-0 w-full mb-6 group">
                                        <input
                                            type={showPassword ? 'text' : 'password'}
                                            name="password"
                                            value={inputsConnexion.password}
                                            {...register('password')}
                                            onChange={handleChangeFormConnexion}
                                            className="input_floating peer"
                                            placeholder=" "
                                            required
                                        />
                                        <label htmlFor="floating_password" className="input_labelFloating">
                                            Mot de passe
                                        </label>
                                        <button className="absolute right-0 top-0 mt-2 mr-2" onClick={(e) => handleShowPassword(e)}>
                                            {showPassword ? (
                                                <EyeIcon className="h-6 w-6 block text-black" />
                                            ) : (
                                                <EyeOffIcon className="h-6 w-6 block text-black" />
                                            )}
                                        </button>
                                        {errors && errors['password'] && (
                                            <span className={`w-full mt-0 text-sm text-danger-400`}>{errors['password'].message}</span>
                                        )}
                                    </div>
                                </form>
                                <p className="mt-2 text-blueMain text-sm text-left md:text-center justify-center flex flex-row md:flex-row gap-4">
                                    <button onClick={() => setIsForgot(true)}>Mot de passe oublié ?</button>
                                    <button className="text-2xl font-bold" onClick={() => setIsSignup(true)}>
                                        S'inscrire
                                    </button>
                                </p>
                                <div className="flex flex-col gap-2 mt-4">
                                    <button
                                        ref={connctionRef}
                                        className="button button-secondary bg-blueMain rounded-full"
                                        onClick={handleSubmit(onSubmitLogin)}
                                    >
                                        Se connecter
                                    </button>
                                </div>
                                <p className="text-purpleSkills text-xl font-bold text-center mt-4">Ou</p>
                                <div className="m-auto mt-4 text-center flex justify-center">
                                    <div ref={ggRef}></div>
                                </div>
                            </>
                        )}
                        {isForgot && (
                            <>
                                <h1 className="-mx-2 md:-mx-8 -mt-8 text-lg text-mainText font-bold px-2 md:px-8 py-4 border-b border-gray-200;">
                                    Mot de passe oublié
                                </h1>
                                {hasError && isForgotSubmitted && !authState.isLogged && <Alert type="danger" message={errorMsg} />}
                                {isForgotSubmitted && !hasError && !authState.isLogged && (
                                    <Alert
                                        type="success"
                                        message="Un email de réinitialisation vous a été envoyé. N'oubliez pas de vérifier vos spams si besoin."
                                    />
                                )}
                                <form className="mt-8 m-0" autoComplete="off">
                                    <div className="relative z-0 w-full mb-6 group">
                                        <input
                                            className="input_floating peer"
                                            placeholder=" "
                                            type="text"
                                            name="email"
                                            value={inputsConnexion.email}
                                            onChange={handleChangeFormConnexion}
                                        />
                                        <label htmlFor="floating_email" className="input_labelFloating">
                                            Adresse email
                                        </label>
                                    </div>
                                </form>
                                <p className="mt-4 text-blueMain text-sm text-center justify-center flex gap-4">
                                    <button onClick={() => setIsForgot(false)}>Me connecter</button>
                                    <button
                                        onClick={() => {
                                            changePassword();
                                        }}
                                    >
                                        S'inscrire !
                                    </button>
                                </p>
                                <div className="flex flex-col gap-2 mt-4">
                                    <button
                                        className="button button-secondary bg-blueMain rounded-full"
                                        onClick={() => {
                                            forgotPassword(inputsConnexion);
                                        }}
                                    >
                                        Réinitialiser
                                    </button>
                                </div>
                            </>
                        )}
                    </main>
                </div>
            </div>
        </div>
    );
}

export default Login;
