//API
import { useQuery } from "react-query";
import { getTransactionsAsked } from "api/transactions";
//COMPONENTS
import CardAskedVisio from "./CardAskedVisio";

function ListAskedVisios() {
    //API
    const visios = useQuery("visiosAsked", () => getTransactionsAsked("visio"));

    return (
        <>
            {visios.isSuccess && (
                <div className="cards">
                    {visios.data.map((visio, index) => (
                        <CardAskedVisio
                            transaction={visio}
                            refetch={visios.refetch}
                            key={index}
                        />
                    ))}
                </div>
            )}
        </>
    );
}

export default ListAskedVisios;
