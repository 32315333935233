const userReportData = [
    {
        key: '0',
        id: 'spam',
        label: 'Il est suspect ou publie du spam.',
        children: [
            {
                id: 'fake-account',
                key: '0-0',
                label: 'Faux compte',
            },
            {
                key: 'phishing-security',
                label: 'Il partage des liens vers des sites potentiellement dangereux, malveillants ou de hameçonnage',
            },
            {
                id: 'spam-functions',
                key: '0-1',
                label: 'Il utilise certaines fonctiannilités pour spammer',
            },
            {
                id: 'something-else',
                key: '0-1',
                label: 'Autre chose',
            },
        ],
    },
    {
        key: '1',
        id: 'impersonation',
        label: "Usurpation d'itentité",
        children: [
            {
                id: 'me',
                key: '1-0',
                label: 'Moi-même',
            },
            {
                id: 'i-represent',
                key: '1-1',
                label: 'Une personne que je représente',
            },
            {
                id: 'company',
                key: '1-2',
                label: 'Ma société ou marque',
            },
            {
                id: 'someone-else',
                key: '1-2',
                label: "Quelqu'un d'autre",
            },
        ],
    },
    {
        key: '2',
        id: 'hacked-account',
        label: 'Le compte semble avoir été piraté.',
    },
    {
        key: '3',
        id: 'sensitive-info',
        label: 'Ses informations et/ou images comportent un contenu inapproprié ou haineux.',
        children: [
            {
                key: 'adult',
                id: '3-0',
                label: 'Contenus destinés aux adultes',
            },
            {
                key: 'hateful',
                id: '3-1',
                label: 'Haineux',
            },
            {
                key: 'other',
                id: '3-1',
                label: 'Autre chose',
            },
        ],
    },
    {
        key: '4',
        id: 'inappropriate-content',
        label: 'Publication de contenus inappropriés',
        children: [
            {
                key: '4-0',
                id: '4-0',
                label: 'Les propos tenus sont irrespectueux ou choquants',
            },
            {
                key: '4-1',
                id: '4-1',
                label: "Publication d'informations privées",
            },
            {
                key: '4-2',
                id: '4-2',
                label: 'Harcèlement ciblé',
            },
            {
                key: '4-3',
                id: '4-3',
                label: 'Incitation à la haine envers un groupe ciblé (origine ethnique, caste, religion, sexe, orientation sexuelle ou handicap)',
            },
            {
                key: '4-4',
                id: '4-4',
                label: "L'utilisateur menace de faire usage de la violence ou de blesser quelqu'un",
            },
            {
                key: '4-4',
                id: '4-4',
                label: "Incitation à l'automutilation ou au suicide",
            },
        ],
    },
    {
        key: '5',
        id: 'scam',
        label: 'Fraude ou Arnaque',
        children: [
            {
                key: '5-0',
                id: '5-0',
                label: 'Pratiques frauduleuses',
            },
            {
                key: '5-1',
                id: '5-1',
                label: 'Arnaque sur un ou des service(s), une ou des demande(s)',
            },
            {
                key: '5-1',
                id: '5-1',
                label: 'Autre chose',
            },
        ],
    },
];

const roomReportData = [
    {
        key: '0',
        id: 'inappropriate-dangerous',
        label: 'Les propos tenus sont inappropriés ou dangereux',
        children: [
            {
                key: '0-0',
                id: '0-0',
                label: 'Les propos tenus sont irrespectueux ou choquants',
            },
            {
                key: '0-1',
                id: '0-1',
                label: 'Incitation à la haine envers un groupe ciblé (origine ethnique, caste, religion, sexe, orientation sexuelle, handicap ou autre)',
            },
            {
                key: '0-2',
                id: '0-2',
                label: 'Harcèlement ciblé',
            },
            {
                key: '0-3',
                id: '0-3',
                label: "L'utilisateur menace de faire usage de la violence ou de blesser quelqu'un",
            },
            {
                key: '0-4',
                id: '0-4',
                label: "Incitation à l'automutilation ou au suicide",
            },
        ],
    },
    {
        key: '1',
        id: 'medias-sensible',
        label: 'Il contient un ou des médias sensible(s)',
        children: [
            {
                key: '1-0',
                id: '1-0',
                label: 'Violent',
            },
            {
                key: '1-1',
                id: '1-1',
                label: 'Haineux',
            },
            {
                key: '1-2',
                id: '1-2',
                label: 'Pour adulte',
            },
            {
                key: '1-3',
                id: '1-3',
                label: 'Publication de média(s) privés',
            },
            {
                key: '1-4',
                id: '1-4',
                label: 'Autre chose',
            },
        ],
    },
    {
        key: '2',
        id: 'private-informations',
        label: "Publication d'informations privées",
        children: [
            {
                id: 'me',
                key: '2-0',
                label: 'Moi-même',
            },
            {
                id: 'i-represent',
                key: '2-1',
                label: 'Une personne que je représente',
            },
            {
                id: 'company',
                key: '2-2',
                label: 'Ma société ou marque',
            },
            {
                id: 'someone-else',
                key: '2-2',
                label: "Quelqu'un d'autre",
            },
        ],
    },
    {
        key: '3',
        id: 'scam',
        label: 'Fraude ou Arnaque',
        children: [
            {
                key: '5-0',
                id: '5-0',
                label: 'Pratiques frauduleuses',
            },
            {
                key: '5-1',
                id: '5-1',
                label: 'Arnaque sur une ou des demande(s)',
            },
            {
                key: '5-1',
                id: '5-1',
                label: 'Autre chose',
            },
        ],
    },
    {
        key: '4',
        id: 'other',
        label: 'Autre chose',
    },
];

const answerReportData = [
    {
        key: '0',
        id: 'inappropriate-dangerous',
        label: 'Les propos tenus sont inappropriés ou dangereux',
        children: [
            {
                key: '0-0',
                id: '0-0',
                label: 'Les propos tenus sont irrespectueux ou choquants',
            },
            {
                key: '0-1',
                id: '0-1',
                label: 'Incitation à la haine envers un groupe ciblé (origine ethnique, caste, religion, sexe, orientation sexuelle, handicap ou autre)',
            },
            {
                key: '0-2',
                id: '0-2',
                label: 'Harcèlement ciblé',
            },
            {
                key: '0-3',
                id: '0-3',
                label: "L'utilisateur menace de faire usage de la violence ou de blesser quelqu'un",
            },
            {
                key: '0-4',
                id: '0-4',
                label: "Incitation à l'automutilation ou au suicide",
            },
        ],
    },
    {
        key: '1',
        id: 'medias-sensible',
        label: 'Il contient un ou des médias sensible(s)',
        children: [
            {
                key: '1-0',
                id: '1-0',
                label: 'Violent',
            },
            {
                key: '1-1',
                id: '1-1',
                label: 'Haineux',
            },
            {
                key: '1-2',
                id: '1-2',
                label: 'Pour adulte',
            },
            {
                key: '1-3',
                id: '0-0',
                label: 'Publication de média(s) privés',
            },
            {
                key: '1-4',
                id: '1-4',
                label: 'Autre chose',
            },
        ],
    },
    {
        key: '2',
        id: 'private-informations',
        label: "Publication d'informations privées",
        children: [
            {
                id: 'me',
                key: '2-0',
                label: 'Moi-même',
            },
            {
                id: 'i-represent',
                key: '2-1',
                label: 'Une personne que je représente',
            },
            {
                id: 'company',
                key: '2-2',
                label: 'Ma société ou marque',
            },
            {
                id: 'someone-else',
                key: '2-3',
                label: "Quelqu'un d'autre",
            },
        ],
    },
    {
        key: '3',
        id: 'other',
        label: 'Autre chose',
    },
];

const shootReportData = [
    {
        key: '0',
        id: 'inappropriate-dangerous',
        label: 'Les propos tenus sont inappropriés ou dangereux',
        children: [
            {
                key: '0-0',
                id: '0-0',
                label: 'Les propos tenus sont irrespectueux ou choquants',
            },
            {
                key: '0-1',
                id: '0-1',
                label: 'Incitation à la haine envers un groupe ciblé (origine ethnique, caste, religion, sexe, orientation sexuelle, handicap ou autre)',
            },
            {
                key: '0-2',
                id: '0-2',
                label: 'Harcèlement ciblé',
            },
            {
                key: '0-3',
                id: '0-3',
                label: "L'utilisateur menace de faire usage de la violence ou de blesser quelqu'un",
            },
            {
                key: '0-4',
                id: '0-4',
                label: "Incitation à l'automutilation ou au suicide",
            },
        ],
    },
    {
        key: '1',
        id: 'shoot-sensible',
        label: 'Le contenu du shoot est sensible',
        children: [
            {
                key: '1-0',
                id: '1-0',
                label: 'Violent',
            },
            {
                key: '1-1',
                id: '1-1',
                label: 'Haineux',
            },
            {
                key: '1-2',
                id: '1-2',
                label: 'Pour adulte',
            },
            {
                key: '1-3',
                id: '0-0',
                label: 'Publication de shoot(s) privés',
            },
            {
                key: '1-4',
                id: '1-4',
                label: 'Autre chose',
            },
        ],
    },
    {
        key: '2',
        id: 'private-informations',
        label: "Publication d'informations privées",
        children: [
            {
                id: 'me',
                key: '2-0',
                label: 'Moi-même',
            },
            {
                id: 'i-represent',
                key: '2-1',
                label: 'Une personne que je représente',
            },
            {
                id: 'company',
                key: '2-2',
                label: 'Ma société ou marque',
            },
            {
                id: 'someone-else',
                key: '2-3',
                label: "Quelqu'un d'autre",
            },
        ],
    },
    {
        key: '3',
        id: 'other',
        label: 'Autre chose',
    },
];

const serviceReportData = [
    {
        key: '0',
        id: 'service-sensible',
        label: 'Le contenu du service est sensible',
        children: [
            {
                key: '1-0',
                id: '1-0',
                label: 'Violent',
            },
            {
                key: '1-1',
                id: '1-1',
                label: 'Haineux',
            },
            {
                key: '1-2',
                id: '1-2',
                label: 'Pour adulte',
            },
            {
                key: '1-3',
                id: '0-0',
                label: 'Publication de service(s) privés',
            },
            {
                key: '1-4',
                id: '1-4',
                label: 'Autre chose',
            },
        ],
    },
    {
        key: '1',
        id: 'private-informations',
        label: "Publication d'informations privées sur le service",
        children: [
            {
                id: 'me',
                key: '2-0',
                label: 'Moi-même',
            },
            {
                id: 'i-represent',
                key: '2-1',
                label: 'Une personne que je représente',
            },
            {
                id: 'company',
                key: '2-2',
                label: 'Ma société ou marque',
            },
            {
                id: 'someone-else',
                key: '2-3',
                label: "Quelqu'un d'autre",
            },
        ],
    },
    {
        key: '2',
        id: 'scam',
        label: 'Fraude ou Arnaque',
        children: [
            {
                key: '5-0',
                id: '5-0',
                label: 'Pratiques frauduleuses',
            },
            {
                key: '5-1',
                id: '5-1',
                label: 'Arnaque sur une ou des demande(s)',
            },
            {
                key: '5-1',
                id: '5-1',
                label: 'Autre chose',
            },
        ],
    },
    {
        key: '3',
        id: 'other',
        label: 'Autre chose',
    },
];

export { userReportData, roomReportData, answerReportData, shootReportData, serviceReportData };
