import { useRef, Fragment, useImperativeHandle, useEffect } from 'react';

//LIBS
import { useInfiniteQuery } from 'react-query';
import Masonry from 'react-masonry-component';

//HOOKS
import UseIntersectionObserver from 'hooks/useIntersectionObserver';

function useInfinite(query, key, options) {
    const page = useRef(0);
    const hasNextPage = useRef(false);
    const loadMoreButtonRef = useRef();

    const { data, fetchNextPage, isFetchingNextPage, refetch } = useInfiniteQuery(
        key,
        ({ pageParam = 0 }) =>
            query(
                `?&p=${pageParam}${options.limit ? `&l=${options.limit}` : ''}${options.user ? `&u=${options.user}` : ''}${
                    options.id ? `&id=${options.id}` : ''
                }${options.slug ? `&s=${options.slug}` : ''}`
            ),
        {
            getNextPageParam: (lastPage) => {
                if (lastPage.hasNextPage) {
                    hasNextPage.current = lastPage.hasNextPage;

                    return (page.current = lastPage.page);
                } else {
                    hasNextPage.current = false;
                    page.current = 0;
                }
                return;
            },
            enabled: options.enabled ?? true,
        }
    );

    useImperativeHandle(options?.ref, () => ({
        refetch: () => {
            refetch();
        },
    }));

    UseIntersectionObserver({
        target: loadMoreButtonRef,
        onIntersect: (isVisible) => {
            if (isVisible) fetchNextPage();
        },
        enabled: !!hasNextPage.current,
    });

    useEffect(() => {
        if (options.onLastIndex) {
            if (options.onLastIndex() === true && hasNextPage.current) {
                fetchNextPage();
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options.onLastIndex]);

    useEffect(() => {
        options?.onData?.(data, { totalDocs: data?.pages?.[0]?.totalDocs });
        //eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        if (options.listen) refetch();
        //eslint-disable-next-line
    }, [options.listen]);

    const masonryOptions = {
        transitionDuration: '0.2s',
        columnWidth: '.grid-sizer',
        itemSelector: '.grid-item',
        percentPosition: true,
    };

    return (
        <>
            {data?.pages && (
                <>
                    {options.masonry && (
                        <Masonry options={masonryOptions}>
                            <div className="grid-sizer"></div>
                            <div className="gutter-sizer"></div>
                            {data?.pages?.map((group, index) => (
                                <Fragment key={index}>
                                    {group.docs.map((data, k) => (
                                        <Fragment key={k}>{options?.onItem(data, refetch)}</Fragment>
                                    ))}
                                </Fragment>
                            ))}
                        </Masonry>
                    )}
                    {options.gridShoots && (
                        <>
                            <div className="grid grid-cols-3 lg:grid-cols-4">
                                {data?.pages?.map((group, index) => (
                                    <Fragment key={index}>
                                        {group.docs.map((data, k) => (
                                            <Fragment key={k}>{options?.onItem(data, refetch)}</Fragment>
                                        ))}
                                    </Fragment>
                                ))}
                            </div>
                        </>
                    )}
                    {!options.masonry && !options.gridShoots && (
                        <>
                            {data?.pages?.map((group, index) => (
                                <Fragment key={index}>
                                    {group.docs.map((data, k) => (
                                        <Fragment key={k}>{options?.onItem(data, refetch)}</Fragment>
                                    ))}
                                </Fragment>
                            ))}
                        </>
                    )}
                    {hasNextPage.current && (
                        <div className="text-center mt-4">
                            <button
                                ref={loadMoreButtonRef}
                                onClick={() => fetchNextPage()}
                                className="button button-secondary"
                                disabled={!hasNextPage.current || isFetchingNextPage}
                            >
                                {isFetchingNextPage ? 'Chargement...' : options.msgToSee ?? 'Voir plus'}
                            </button>
                        </div>
                    )}
                    {data?.pages?.[0]?.docs?.length <= 0 && <>{options?.onNoResult?.()}</>}
                </>
            )}
        </>
    );
}

export default useInfinite;
