// import { useContext, useEffect } from 'react';

//CONTEXTS
// import { HeadlessContext } from '../..';

export default function HeadlessPageConfigFirebase(props) {
    // const store = useContext(HeadlessContext);

    // function init() {
    //     const firebaseConfig = store.get('config')?.firebase;
    //     if (firebaseConfig) {
    //         const firebase = initializeApp(firebaseConfig);
    //         const db = getDatabase();

    //         console.log(firebase, db);
    //     }
    // }

    return <p>Firebase</p>;
}
