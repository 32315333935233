import React, { useCallback, useEffect, useState, useContext, useRef, forwardRef, useImperativeHandle } from 'react';
//API
import { postTransaction, isFirstTransaction } from 'api/transactions';
import { postNotification } from 'api/notifications';
//LIBS
import { Calendar } from '@natscale/react-calendar';
import TimeKeeper from 'react-timekeeper';
import { toast } from 'react-toastify';
//CONTEXT
import { AuthContext } from 'context/authContext';
//COMPONENTS
import Modal from 'components/common/Modal';
//STYLES
import '@natscale/react-calendar/dist/main.css';
import './Reservation.css';
import SimpleCheckout from 'components/stripe/simpleCheckout';

function Reservation(props, ref) {
    //eslint-disable-next-line
    const [authState] = useContext(AuthContext);
    const [value, setValue] = useState(new Date());
    const [time, setTime] = useState('9:00');
    const [choice, setChoice] = useState(new Date());
    const [messageReservation, setMessageReservation] = useState('');
    const [openPayment, setOpenPayment] = useState(false);
    const [checkout, setCheckout] = useState(false);
    const [isFirstVisioTransaction, setIsFirstVisioTransaction] = useState(false);

    //REFS
    const refMessageReservation = useRef(null);
    const refCheckout = useRef(null);

    const onChange = useCallback(
        (val) => {
            setValue(val);
        },
        [setValue]
    );

    useEffect(() => {
        isFirstTransaction({ type: 'visio', user: props.user.id }).then((res) => {
            setIsFirstVisioTransaction(res.length > 0 ? false : true);
        });
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        const date = new Date(value);
        date.setHours(time.split(':')[0]);
        date.setMinutes(time.split(':')[1]);

        setChoice(date);
        props?.onChange?.(date);
        //eslint-disable-next-line
    }, [value, time]);

    useImperativeHandle(ref, () => ({
        submit: () => {
            setOpenPayment(true);
        },
    }));

    function handleBuyCredits() {
        setCheckout(true);
    }

    function handlePayment() {
        if (checkout) {
            refCheckout.current.submit();
        } else {
            postTransaction({
                type: 'visio',
                user: props.user.id,
                infos: JSON.stringify({
                    date: choice,
                    messageReservation: messageReservation,
                    isFirstTransaction: isFirstVisioTransaction,
                }),
            }).then((res) => {
                if (res.status === 201) {
                    setOpenPayment(false);
                    props.onClose();
                    toast.success('Reservation effectuée', { type: 'success' });
                    postNotification({
                        type: 'visioInit',
                        user: props.user.id,
                    });
                }
            });
        }
    }

    function handleSucceedPayment() {
        setCheckout(false);
        setOpenPayment(false);
        props.onClose();
        toast.success('Reservation effectuée', { type: 'success' });
        postNotification({
            type: 'visioInit',
            user: props.user.id,
        });
    }

    return (
        <>
            <Modal
                title={`Objet du RDV`}
                titleCenter={true}
                open={openPayment}
                onClose={{
                    onClick: () => {
                        setOpenPayment(false);
                        setCheckout(false);
                    },
                }}
                onConfirm={{
                    label: 'Demander le RDV',
                    // disabled: authState?.me.bank.credits < props.user.visio.price && !props.user.visio.isFreeFirst && !checkout,
                    onClick:
                        authState?.me.bank.credits < props.user.visio.price && !checkout && (!props.user.visio.isFreeFirst || !isFirstVisioTransaction)
                            ? handleBuyCredits
                            : handlePayment,
                }}
            >
                {props.user.visio.isFreeFirst && isFirstVisioTransaction && (
                    <>
                        <textarea
                            ref={refMessageReservation}
                            name="messageReservation"
                            className="input"
                            rows="1"
                            style={{ height: 'auto' }}
                            placeholder={`Détaillez les raisons de ce RDV à ${props.user.profile.pseudo}`}
                            onChange={() => {
                                setMessageReservation(refMessageReservation.current.value);
                            }}
                            value={messageReservation}
                        >
                            {messageReservation}
                        </textarea>
                        <p className="mt-2 text-blue-800 mb-4 text-center">
                            <strong>{props.user.profile.pseudo}</strong> vous offre ce premier RDV, sous réserve qu'il soit disponible.
                        </p>
                    </>
                )}
                {(!props.user.visio.isFreeFirst || !isFirstVisioTransaction) && authState?.me.bank.credits >= props.user.visio.price && (
                    <>
                        <textarea
                            ref={refMessageReservation}
                            name="messageReservation"
                            className="input"
                            rows="1"
                            style={{ height: 'auto' }}
                            placeholder={`Laissez un message à ${props.user.profile.pseudo}`}
                            onChange={() => {
                                setMessageReservation(refMessageReservation.current.value);
                            }}
                            value={messageReservation}
                        >
                            {messageReservation}
                        </textarea>
                        <p className="text-blue-800 mb-4">
                            <strong> {props.user.visio.price} €</strong> sera/seront déduit(s) de votre solde.
                        </p>
                        <p className="text-blue-800 mb-4">
                            Le montant vous sera restitué <strong>en cas d'annulation</strong> par le Skiller.
                        </p>
                    </>
                )}
                {(!props.user.visio.isFreeFirst || !isFirstVisioTransaction) && authState?.me.bank.credits < props.user.visio.price && (
                    <>
                        {!checkout && (
                            <>
                                <p className="text-blue-800 mb-4">
                                    Vous allez payer un montant de <strong>{props.user?.visio?.price} €</strong>.
                                </p>
                                <p className="text-blue-800 mb-4">
                                    En cas d'annulation par <strong>{props.user?.profile?.pseudo}</strong>, vous serez crédité du même montant sur votre réserve{' '}
                                    <strong>SkillsMarket</strong> qui pourra être dépensé ultérieurement.
                                </p>
                                <textarea
                                    ref={refMessageReservation}
                                    name="messageReservation"
                                    className="input"
                                    rows="2"
                                    style={{ height: 'auto' }}
                                    placeholder={`Laissez un message à ${props.user.profile.pseudo}`}
                                    onChange={() => {
                                        setMessageReservation(refMessageReservation.current.value);
                                    }}
                                    value={messageReservation}
                                >
                                    {messageReservation}
                                </textarea>
                                {/* <div className="mx-auto mt-4 text-center"> */}
                                {/*     <Button */}
                                {/*         label={`Acheter pour ${props.user.visio.price} €`} */}
                                {/*         css="button-secondary" */}
                                {/*         onClick={() => { */}
                                {/*             handleBuyCredits(); */}
                                {/*         }} */}
                                {/*     /> */}
                                {/* </div> */}
                            </>
                        )}
                        {checkout && (
                            <>
                                <SimpleCheckout
                                    refCheckout={refCheckout}
                                    amount={props.user.visio.price}
                                    details={{
                                        title: `Visio-consultation avec ${props.user.profile.pseudo} pour ${props.user.visio.price} €`,
                                        userTo: props.user.id,
                                        userFromEmail: authState.me.auth.email,
                                        type: 'visio',
                                        status: 'init',
                                        date: choice,
                                        message: messageReservation,
                                    }}
                                    handleSucceedPayment={(payload) => handleSucceedPayment(payload)}
                                    type="service"
                                />
                            </>
                        )}
                    </>
                )}
            </Modal>
            {props.user.visio.isFreeFirst && isFirstVisioTransaction && (
                <span className="text-center m-auto w-full inline-block mb-4 font-semibold text-blueMain">
                    <>{props.user.profile.pseudo} vous offre ce premier RDV</>
                </span>
            )}
            <div className="flex flex-col sm:flex-row items-center">
                <div className="">
                    <Calendar startOfWeek={1} disablePast size="250" value={value} onChange={onChange} locale="fr-FR" />
                </div>
                <div>
                    <TimeKeeper
                        time={time}
                        hour24Mode
                        switchToMinuteOnHourSelect
                        switchToHourOnMinuteSelect
                        coarseMinutes={5}
                        forceCoarseMinutes
                        onChange={(newTime) => setTime(newTime.formatted24)}
                    />
                </div>
            </div>
            <div className="mt-3 text-blue-800 text-center">
                <span className="text-blueMain font-bold">
                    Le {choice.toLocaleDateString('fr-FR', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })} à{' '}
                    {choice.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' })}H
                </span>
            </div>
        </>
    );
}

export default forwardRef(Reservation);
