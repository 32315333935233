import { useState, useEffect } from 'react';
import { countJobs } from 'api/forum';
//CONTAINERS
import SectionTitle from 'components/common/SectionTitle';
import CardFrontRoom from 'containers/rooms/CardFrontRoom';

import FieldSelect from 'components/forms/fields/Select';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import schema from 'components/forms/yup/signup';

// eslint-disable-next-line no-unused-vars
import _ from 'lodash';

function SearchJobs(props) {
    const [nb, setNb] = useState(3);
    const [jobs, setJobs] = useState(props?.filter === false ? _.take(props.jobs, nb) : props.jobs);

    const {
        register,
        //handleSubmit,
        //watch,
        formState: { errors },
        //setValue,
        //getValues,
    } = useForm({
        resolver: yupResolver(schema),
    });

    function showMore() {
        // setNb(nb + 3);
        setNb(_.size(props.jobs));
    }
    function showLess() {
        setNb(3);
    }

    useEffect(() => {
        if (nb >= 3 && props?.filter === false) setJobs(_.take(props.jobs, nb));
        else setJobs(props.jobs);
        //eslint-disable-next-line
    }, [nb]);

    const [job_contrat, setJobContrat] = useState('Tous les contrats');
    const [job_durer, setJobDurer] = useState('Toutes les durées');
    //const [job_category, setJobCategory] = useState('Toutes les catégories');

    function filterJobsByContrat(filter) {
        switch (filter) {
            case 'CDI':
                setJobs(props.jobs.filter((job) => job?.jobTypeContrat === 'CDI'));
                setJobContrat('CDI');
                break;
            case 'CDD':
                setJobs(props.jobs.filter((job) => job?.jobTypeContrat === 'CDD'));
                setJobContrat('CDD');
                break;
            case 'MSI':
                setJobs(props.jobs.filter((job) => job?.jobTypeContrat === 'MSI'));
                setJobContrat('MSI / Intérim');
                break;
            case 'Stage':
                setJobs(props.jobs.filter((job) => job?.jobTypeContrat === 'Stage'));
                setJobContrat('Stage');
                break;
            case 'Alternance':
                setJobs(props.jobs.filter((job) => job?.jobTypeContrat === 'Alternance'));
                setJobContrat('Alternance');
                break;
            case 'Freelance':
                setJobs(props.jobs.filter((job) => job?.jobTypeContrat === 'Freelance'));
                setJobContrat('Freelance');
                break;
            default:
                setJobs(props.jobs);
                setJobContrat('Tous les contrats');
                break;
        }
    }

    function filterJobsByDurer(filter) {
        switch (filter) {
            case 'TempsPlein':
                setJobs(props.jobs.filter((job) => job?.jobTypeDurer === 'Temps plein'));
                setJobDurer('Temps plein');
                break;
            case 'TempsPartiel':
                setJobs(props.jobs.filter((job) => job?.jobTypeDurer === 'Temps partiel'));
                setJobDurer('Temps partiel');
                break;
            default:
                setJobs(props.jobs);
                setJobDurer('Toutes les durées');
                break;
        }
    }

    /*
    function filterJobsByCategory(filter) {
        switch (filter) {
            case 'A':
                setJobs(props.jobs.filter(job => job?.poleEmploi?.categorie === 'Agriculture, espaces verts, aménagement paysager'));
                setJobCategory('Agriculture, espaces verts, aménagement paysager');
                break;
            case 'B':
                setJobs(props.jobs.filter(job => job?.poleEmploi?.categorie === "Artisanat d'art"));
                setJobCategory("Artisanat d'art");
                break;
            case 'C':
                setJobs(props.jobs.filter(job => job?.poleEmploi?.categorie === 'Banque, assurance, immobilier'));
                setJobCategory('Banque, assurance, immobilier');
                break;
            case 'D':
                setJobs(props.jobs.filter(job => job?.poleEmploi?.categorie === 'Bâtiment, travaux publics'));
                setJobCategory('Bâtiment, travaux publics');
                break;
            case 'E':
                setJobs(props.jobs.filter(job => job?.poleEmploi?.categorie === 'Commerce, vente'));
                setJobCategory('Commerce, vente');
                break;
            case 'F':
                setJobs(props.jobs.filter(job => job?.poleEmploi?.categorie === 'Communication, média'));
                setJobCategory('Communication, média');
                break;
            case 'G':
                setJobs(props.jobs.filter(job => job?.poleEmploi?.categorie === 'Défense, sécurité, armée'));
                setJobCategory('Défense, sécurité, armée');
                break;
            case 'H':
                setJobs(props.jobs.filter(job => job?.poleEmploi?.categorie === "Direction d'entreprise"));
                setJobCategory('Direction d\'entreprise');
                break;
            case 'I':
                setJobs(props.jobs.filter(job => job?.poleEmploi?.categorie === 'Informatique, télécommunication'));
                setJobCategory('Informatique, télécommunication');
                break;
            case 'J':
                setJobs(props.jobs.filter(job => job?.poleEmploi?.categorie === 'Edition, librairie, imprimerie'));
                setJobCategory('Edition, librairie, imprimerie');
                break;
            case 'K':
                setJobs(props.jobs.filter(job => job?.poleEmploi?.categorie === 'Enseignement'));
                setJobCategory('Enseignement');
                break;
            case 'L':
                setJobs(props.jobs.filter(job => job?.poleEmploi?.categorie === 'Environnement, nature, nettoyage'));
                setJobCategory('Environnement, nature, nettoyage');
                break;
            case 'L14':
                setJobs(props.jobs.filter(job => job?.poleEmploi?.categorie === 'Hôtellerie - Restauration'));
                setJobCategory('Hôtellerie - Restauration');
                break;
            case 'M':
                setJobs(props.jobs.filter(job => job?.poleEmploi?.categorie === 'Industrie'));
                setJobCategory('Industrie');
                break;
            case 'M13':
                setJobs(props.jobs.filter(job => job?.poleEmploi?.categorie === 'Installation, maintenance, service'));
                setJobCategory('Installation, maintenance, service');
                break;
            case 'M14':
                setJobs(props.jobs.filter(job => job?.poleEmploi?.categorie === 'Logistique, transport'));
                setJobCategory('Logistique, transport');
                break;
            case 'M15':
                setJobs(props.jobs.filter(job => job?.poleEmploi?.categorie === 'Spectacle'));
                setJobCategory('Spectacle');
                break;
            case 'M16':
                setJobs(props.jobs.filter(job => job?.poleEmploi?.categorie === 'Sport'));
                setJobCategory('Sport');
                break;
            case 'M17':
                setJobs(props.jobs.filter(job => job?.poleEmploi?.categorie === 'Tourisme'));
                setJobCategory('Tourisme');
                break;
            case 'M18':
                setJobs(props.jobs.filter(job => job?.poleEmploi?.categorie === 'Animation'));
                setJobCategory('Animation');
                break;
            case 'N':
                setJobs(props.jobs.filter(job => job?.poleEmploi?.categorie === 'Santé'));
                setJobCategory('Santé');
                break;
            default:
                setJobs(props.jobs);
                setJobCategory('Toutes les catégories');
                break;
        }
    }
    */

    /* function filterJobsByDistance(filter) {
        switch (filter) {
            case '15':
                props.onRangeChange(15);
                break;
            case '20':
                props.onRangeChange(20);
                break;
            case '50':
                props.onRangeChange(50);
                break;
            default:
                props.onRangeChange(10);
                break;
        }
    } */

    return (
        <>
            <SectionTitle>
                <p className="text-xs text-graySkills mx-0 my-4 mt-8">
                    {/*  {props.rooms.length > 20 && <span>Environs </span>} */}
                    <span className="h2 mx-0 my-2 mt-4 text-bold text-purpleSkills">Jobs :</span>
                </p>
            </SectionTitle>
            <div className="flex flex-row justify-between gap-4 w-full">
                {/*
                <select className="w-1/2 h-10 px-3 mb-4 text-base text-gray-700 placeholder-gray-600 border rounded-lg focus:shadow-outline"
                    onChange={e => filterJobsByDistance(e.target.value)}
                    value={props.search}
                >
                    <option value="">10 Km</option>
                    <option value="15">15 Km</option>
                    <option value="20">20 Km</option>
                    <option value="50">50 Km</option>
                </select>
                <FieldSelect
                    inputForm="rounded"
                    className="px-0 w-1/3"
                    name="job_category"
                    label={false}
                    onChange={e => filterJobsByCategory(e.target.value)}
                    value={job_category}
                    options={[
                        { label: 'Toutes les catégories', value: 'ALL' },
                        { label: 'Agriculture, espaces verts, aménagement paysager', value: 'A' },
                        { label: "Artisanat d'art", value: 'B' },
                        { label: 'Banque, assurance, immobilier', value: 'C' },
                        { label: 'Bâtiment, travaux publics', value: 'D' },
                        { label: 'Commerce, vente', value: 'E' },
                        { label: 'Communication, média', value: 'F' },
                        { label: 'Défense, sécurité, armée', value: 'G' },
                        { label: 'Direction d\'entreprise', value: 'H' },
                        { label: 'Informatique, télécommunication', value: 'I' },
                        { label: 'Edition, librairie, imprimerie', value: 'J' },
                        { label: 'Enseignement', value: 'K' },
                        { label: 'Environnement, nature, nettoyage', value: 'L' },
                        { label: 'Hôtellerie - Restauration', value: 'L14' },
                        { label: 'Industrie', value: 'M' },
                        { label: 'Installation, maintenance, service', value: 'M13' },
                        { label: 'Logistique, transport', value: 'M14' },
                        { label: 'Spectacle', value: 'M15' },
                        { label: 'Sport', value: 'M16' },
                        { label: 'Tourisme', value: 'M17' },
                        { label: 'Animation', value: 'M18' },
                        { label: 'Santé', value: 'N' },
                    ]}
                    register={register}
                    errors={errors}
                />
                */}
                <FieldSelect
                    inputForm="rounded"
                    className="px-0 w-1/3"
                    name="job_contrat"
                    label={false}
                    onChange={(e) => filterJobsByContrat(e.target.value)}
                    value={job_contrat}
                    options={[
                        { label: 'Tous les contrats', value: 'ALL' },
                        { label: 'CDI', value: 'CDI' },
                        { label: 'CDD', value: 'CDD' },
                        { label: 'MSI / Intérimaire', value: 'MSI' },
                        { label: 'Stage', value: 'Stage' },
                        { label: 'Alternance', value: 'Alternance' },
                        { label: 'Freelance', value: 'Freelance' },
                    ]}
                    register={register}
                    errors={errors}
                />
                <FieldSelect
                    inputForm="rounded"
                    className="px-0 w-1/3"
                    name="job_durer"
                    label={false}
                    onChange={(e) => filterJobsByDurer(e.target.value)}
                    value={job_durer}
                    options={[
                        { label: 'Temps plein ou partiel', value: 'ALL' },
                        { label: 'Temps plein', value: 'TempsPlein' },
                        { label: 'Temps Partiel', value: 'TempsPartiel' },
                    ]}
                    register={register}
                    errors={errors}
                />
            </div>
            <div className="container jobs mt-4">
                {jobs.map((job, index) => (
                    <div key={index}>
                        <CardFrontRoom room={job} refetch={props.refetch} profile={job?.user} />
                    </div>
                ))}
            </div>
            {props?.filter === false && nb < props.jobs?.length ? (
                <div className="container jobs">
                    <div className="flex justify-center border-b border-gray-200 pb-4 mb-8 mt-4 text-primary font-semibold text-sm">
                        <button onClick={showMore}>...Voir plus d'offres</button>
                    </div>
                </div>
            ) : (
                props?.filter === false && (
                    <>
                        {props.jobs?.length >= 3 && (
                            <div className="container jobs">
                                <div className="flex justify-center border-b border-gray-200 pb-4 mb-8 mt-4 text-primary font-semibold text-sm">
                                    <button onClick={showLess}>...Replier</button>
                                </div>
                            </div>
                        )}
                    </>
                )
            )}
        </>
    );
}

export default SearchJobs;
