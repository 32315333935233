import { useCallback, useContext, useEffect } from 'react';
//LIBS
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

//API
import { getMatchingNeed } from '../../api/matchingNeeds';

//STYLES
import 'App.css';

//CONTEXTS
import { FormContext } from 'context/formContext';

//LAYOUT
import DashboardLayout from 'pages/layouts/Dashboard';
//COMPONENTS
import Header from 'components/common/Header';
//CONTAINERS
import ListNeeds from '../../containers/needs/ListNeeds';

//UTILS
import { PageContentFadingAnimation } from 'utils/animations';

//ICONS
import { SearchIcon } from '@heroicons/react/solid';

function Needs(props) {
    //CONTEXTS
    const [, formContextDispatch] = useContext(FormContext);
    //HOOKS
    const params = useParams();

    //API
    /*   const currentNeed = useQuery(
        ['product', params.slug],
        (params) => {
            return getProduct(params.queryKey[1]);
        },
        { enabled: false }
    ); */
    //API
    const currentNeed = useQuery(
        ['need', params.id],
        (params) => {
            if (params.queryKey[1] !== undefined) return getMatchingNeed(params.queryKey[1]);
        },
        { enabled: false }
    );

    useEffect(() => {
        if (params.id !== undefined) currentNeed.refetch();
        //eslint-disable-next-line
    }, [params.id]);

    const handleAddNeed = useCallback(() => {
        formContextDispatch({ type: 'addNeed' });
        /*   infiniteMatchMaking?.refetch(); */
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formContextDispatch]);

    return (
        <DashboardLayout>
            <PageContentFadingAnimation duration={225}>
                <div className="App">
                    {!params.id && (
                        <>
                            <Header>
                                <h1 className="h1 text-main pb-3">Mes Besoins</h1>
                            </Header>

                            <div className="pb-3">
                                <button
                                    onClick={(e) => {
                                        e?.preventDefault();
                                        handleAddNeed();
                                    }}
                                    className="text-2xl font-bold linkCardDashboard gap-2 w-full px-6 py-2"
                                    type="button"
                                >
                                    <SearchIcon className="w-5 h-5" />
                                    Poster un besoin
                                </button>
                                {/*  <AddShoot>
                                    <button className="text-2xl font-bold linkCardDashboard gap-2 w-full px-6 py-2" type="button">
                                        <VideoCameraIcon className="w-5 h-5" />
                                        Ajouter un shoot
                                    </button>
                                </AddShoot> */}
                            </div>
                        </>
                    )}

                    <main>
                        {!params.id && <ListNeeds />}
                        {/*  {!!params.id && <FormNeed service={currentNeed.data} refetch={currentNeed.refetch} />} */}
                        {/*  {params.slug && <FormProduct slug={params.slug} product={currentProduct} refetch={currentProduct.refetch} />}
                    <AddProduct /> */}
                        {/*  {!params.slug && <ListProducts />} */}
                        {/*  {params.slug && <FormProduct slug={params.slug} product={currentNeed} refetch={currentNeed.refetch} />}
                    <AddProduct /> */}
                        {/*  <AddNeed /> */}
                    </main>
                </div>
            </PageContentFadingAnimation>
        </DashboardLayout>
    );
}

export default Needs;
