//COMPONENTS
import Counter from 'components/Counter';

import { useState } from 'react';

const reactionsArray = [
    {
        value: 'like',
        status: false,
    },
    {
        value: 'likeMuch',
        status: false,
    },
    {
        value: 'mdr',
        status: false,
    },
    {
        value: 'interesting',
        status: false,
    },
    {
        value: 'dislike',
        status: false,
    },
];

function Reaction(props) {
    const [reactions, setReactions] = useState(reactionsArray);

    function onInit(type, value, status) {
        if (type === 'reactions') {
            setReactions(reactions.map((reaction) => reaction.value === value && { ...reaction, status: status }));
        }
    }

    return (
        <>
            <div
                id={`reactionsMenu_${props.onModal === true ? `&` + props.to : props.to}`}
                className={`reactionsMenu hidden absolute ${
                    props.onModal === true ? `bottom-14` : 'bottom-9'
                } bg-white text-center w-auto h-auto min-w-max rounded-2xl p-2 drop-shadow-md z-10`}
            >
                <ul className="flex flex-row items-center justify-center gap-3">
                    <>
                        <li className="hover:bg-blueSelect">
                            <Counter
                                disabled={false}
                                onInit={onInit}
                                refetch={props.refetch}
                                listingQueryKeys={props.listingQueryKeys}
                                icon={'ThumbUpIcon'}
                                to={props.to}
                                userTo={props.userTo}
                                counters={props.counters}
                                type={`reactions`}
                                value={`like`}
                                activeClass="bg-blueSelect"
                                iconColor={'text-purpleSkills'}
                                labelColor={'text-purpleSkills'}
                                targetType={props.type}
                                onClick={(e) => props?.onClick(e, 'like')}
                                reactions={props.reactions?.like}
                            />
                        </li>
                        <li className="hover:bg-blueSelect">
                            <Counter
                                disabled={false}
                                onInit={onInit}
                                refetch={props.refetch}
                                listingQueryKeys={props.listingQueryKeys}
                                icon={'HeartIcon'}
                                to={props.to}
                                userTo={props.userTo}
                                counters={props.counters}
                                type={`reactions`}
                                value={'likeMuch'}
                                activeClass="bg-blueSelect"
                                iconColor={'text-[#E06C83]'}
                                labelColor={'text-[#E06C83]'}
                                targetType={props.type}
                                onClick={(e) => props?.onClick(e, 'likeMuch')}
                                reactions={props.reactions?.likeMuch}
                            />
                        </li>
                        <li className="hover:bg-blueSelect">
                            <Counter
                                disabled={false}
                                onInit={onInit}
                                refetch={props.refetch}
                                listingQueryKeys={props.listingQueryKeys}
                                emoji={'mdr'}
                                to={props.to}
                                userTo={props.userTo}
                                counters={props.counters}
                                type={`reactions`}
                                value={'mdr'}
                                activeClass="bg-blueSelect"
                                labelColor={'text-[#DCB14A]'}
                                targetType={props.type}
                                onClick={(e) => props?.onClick(e, 'mdr')}
                                reactions={props.reactions?.mdr}
                            />
                        </li>
                        <li className="hover:bg-blueSelect">
                            <Counter
                                disabled={false}
                                onInit={onInit}
                                refetch={props.refetch}
                                listingQueryKeys={props.listingQueryKeys}
                                emoji={'interesting'}
                                to={props.to}
                                userTo={props.userTo}
                                counters={props.counters}
                                type={`reactions`}
                                value={'interesting'}
                                activeClass="bg-blueSelect"
                                labelColor={'text-[#73C37B]'}
                                targetType={props.type}
                                onClick={(e) => props?.onClick(e, 'interesting')}
                                reactions={props.reactions?.interesting}
                            />
                        </li>
                        <li className="hover:bg-blueSelect">
                            <Counter
                                disabled={false}
                                onInit={onInit}
                                refetch={props.refetch}
                                listingQueryKeys={props.listingQueryKeys}
                                icon={'ThumbDownIcon'}
                                to={props.to}
                                userTo={props.userTo}
                                counters={props.counters}
                                type={`reactions`}
                                value={'dislike'}
                                activeClass="bg-blueSelect"
                                iconColor={'text-[#075985]'}
                                labelColor={'text-[#075985]'}
                                targetType={props.type}
                                onClick={(e) => props?.onClick(e, 'dislike')}
                                reactions={props.reactions?.dislike}
                            />
                        </li>
                    </>
                </ul>
            </div>
        </>
    );
}

export default Reaction;
