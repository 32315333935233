import React, { useRef } from 'react';

// LIBS
import { Editor } from '@tinymce/tinymce-react';

// import { ArrowCircleDownIcon } from "@heroicons/react/solid";
function capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

const toolbarBig = 'undo redo | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist | removeformat | fullscreen';

const toolbarSmall = 'undo redo | bold italic';

function Textarea(props) {
    const editorRef = useRef(null);
    const editorId = 'input_' + props.name;
    const initialValue = props.initValue || '';

    const toolbar = props.toolbar === 'small' ? toolbarSmall : toolbarBig;

    // const log = () => {
    //     if (editorRef.current) {
    //         console.log(editorRef.current.getContent());
    //     }
    // };
    return (
        <div className="mt-6 mb-4 w-full p-0 rounded-lg overflow-hidden">
            {props.label !== false && (
                <label className={`input_label text-sm ${props.errors && props.errors[props.name] && 'input_error'}`} htmlFor={`input_${props.name}`}>
                    {props.label ? capitalize(props.label) : capitalize(props.name)}
                </label>
            )}

            <Editor
                className="rounded-3xl"
                apiKey={process.env.REACT_APP_API_TINYMCE}
                onInit={(evt, editor) => {
                    editorRef.current = editor;
                }}
                onEditorChange={(newText) => props.onChange(newText)}
                initialValue={initialValue}
                init={{
                    selector: { editorId },
                    height: 160,
                    max_height: props.max_height ? props.max_height : 400,
                    menubar: false,
                    paste_as_text: true,
                    autoresize_overflow_padding: 0,
                    plugins: [
                        // "powerpaste advlist autolink lists link image charmap print preview anchor",
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table code help wordcount autoresize',
                    ],
                    toolbar: toolbar,
                    content_style: 'body { font-family:nunito,sans-serif; font-size:15px;font-weight:500; color:#46519F }',
                }}
            />
            {props.errors && props.errors[props.name] && <span className="mt-0 text-danger-400 text-sm">{props.errors[props.name].message}</span>}
        </div>
    );
}

export default Textarea;
