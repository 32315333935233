import { useEffect, useState } from 'react';

//LIBS
import _ from 'lodash';

//CONTAINERS
import SectionTitle from 'components/common/SectionTitle';
import CardProfilSearch from 'containers/profils/CardProfilSearch';

function SearchProfils(props) {
    const [nb, setNb] = useState(3);
    const [items, setItems] = useState(props?.filter === false ? _.take(props.users, nb) : props.users);

    function showMore() {
        // setNb(nb + 3);
        setNb(_.size(props.users));
    }
    function showLess() {
        setNb(3);
    }

    useEffect(() => {
        if (nb >= 3 && props?.filter === false)
            setItems(_.take(props.users, nb));
        else
            setItems(props.users);
        //eslint-disable-next-line
    }, [nb, props.users, props?.filter]);

    return (
        <>
            <SectionTitle>
                <p className="text-xs text-graySkills mx-0 my-4">
                    {/* {props.users.length > 20 && <span>Environs </span>} */}
                    <span className="h2 mx-0 my-2 mt-4 text-bold text-purpleSkills">Profils :</span>
                </p>
            </SectionTitle>
            <div className="container">
                <div className="flex flex-col gap-4">
                    {items.map((user, index) => (
                        <div className="card_profile" key={index}>
                            {/* <CardProfil user={user} refetch={props.refetch} /> */}
                            <CardProfilSearch user={user} refetch={props.refetch} />
                        </div>
                    ))}
                </div>
            </div>
            {props?.filter === false && nb < props.users?.length ? (
                <div className="container">
                    <div className="flex justify-center border-b border-gray-200 pb-4 mb-8 mt-4 text-primary font-semibold text-sm">
                        <button onClick={showMore}>...Voir plus de skillers</button>
                    </div>
                </div>
            ) : (
                props?.filter === false &&
                <>
                    {props.users?.length >= 3 && (
                        <div className="container">
                            <div className="flex justify-center border-b border-gray-200 pb-4 mb-8 mt-4 text-primary font-semibold text-sm">
                                <button onClick={showLess}>...Replier</button>
                            </div>
                        </div>
                    )}
                </>
            )}
        </>
    );
}

export default SearchProfils;
