import { useEffect, useState } from 'react';

//LIBS
import { Link } from 'react-router-dom';

//UTILS
import config from 'utils/constants';

//COMPONENTS
import Image from 'components/common/Image';
import PingConnected from 'components/PingConnected';

//CONTAINERS
import PointGamification from 'containers/profils/PointGamification';

const sizes = {
    avatar: '?w=120&h=120&c=true&q=80',
};

function CardProfil({ user, isFocus, onClick, discussionId, notifications }) {
    const [counter, setCounter] = useState(0);

    useEffect(() => {
        if (notifications?.[discussionId] >= 0 && !isFocus) {
            setCounter(notifications[discussionId]);
        }
        //eslint-disable-next-line
    }, [notifications]);

    useEffect(() => {
        if (isFocus) {
            setCounter(() => 0);
        }
        //eslint-disable-next-line
    }, [isFocus]);

    if (!user?.profile) return null;

    return (
        <>
            <div className="relative w-full p-1">
                <Link onClick={onClick ?? null} to={!onClick ? '/profil/' + user?.profile?.slug : false} title={`Discuter avec ${user?.profile?.pseudo}`}>
                    <div className="flex gap-2 flex-row items-center w-full relative">
                        <div className="flex-none relative w-14 h-auto">
                            {user?.profile?.avatar?.url ? (
                                <Image
                                    src={user?.profile?.avatar?.url + sizes.avatar}
                                    alt={user?.profile?.pseudo}
                                    dynamic
                                    loading="false"
                                    className="w-12 bg-white rounded-full"
                                />
                            ) : (
                                <img
                                    className="w-12 bg-white rounded-full"
                                    src={`${config.publicDir}skillsLogo.jpg` + sizes.avatar}
                                    alt={user?.profile?.pseudo}
                                />
                            )}
                            <PointGamification gamification={user?.gamification?.profile} />
                            <PingConnected connexions={user?.connexions} type="global" />
                        </div>
                        <div className="w-auto grow flex flex-wrap flex-col">
                            <span className="text-sm font-bold text-purpleSkills">{user?.profile?.pseudo}</span>
                            <span className="text-xs text-gray-400">{user?.profile?.status}</span>
                        </div>
                        {counter > 0 && (
                            <div className="absolute top-1 right-1 text-white bg-red p-1 rounded-full w-5 h-5 flex justify-center items-center text-xs">
                                {counter}
                            </div>
                        )}
                    </div>
                </Link>
            </div>
        </>
    );
}

export default CardProfil;
