import { useState, useContext, useEffect, useCallback, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
//LIBS
//import _ from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';
// import GoogleLogin from 'react-google-login';
import { passwordStrength } from 'check-password-strength';
//HOOKS
import UseAuth from 'hooks/useAuth';

//FORMS
import schema from 'components/forms/yup/signup';

//CONTEXTS
import { AuthContext } from 'context/authContext';

//COMPONENTS
import Alert from 'components/Alert';
import FieldSelect from 'components/forms/fields/Select';

import { EyeIcon, EyeOffIcon, XCircleIcon } from '@heroicons/react/outline';

function Register() {
    const [authState] = useContext(AuthContext);

    const navigate = useNavigate();

    //HOOKS
    // eslint-disable-next-line no-unused-vars
    const {
        isSubmitted,
        isSignupSubmitted,
        hasError,
        signUp,
        // reload,
    } = UseAuth();

    // eslint-disable-next-line no-unused-vars
    const [isSignupSend, setIsSignupSend] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [strongPwd, setStrongPwd] = useState(null);
    const [typeInscription, setTypeInscription] = useState(null);
    const [isGoogleLoaded, setIsGoogleLoaded] = useState(false);

    const ggRef = useRef();
    const inscriptionRef = useRef();

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        // setValue,
        getValues,
    } = useForm({
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        if (!window.google) return;

        if (ggRef.current && inscriptionRef.current) {
            window.google.accounts?.id.renderButton(ggRef.current, {
                theme: 'outline',
                size: 'large',
                shape: 'pill',
                width: inscriptionRef.current.offsetWidth + 'px',
                text: 'signup_with', // Change this text to your desired text
            });
        }
        //eslint-disable-next-line
    }, [isGoogleLoaded]);

    const onSubmit = useCallback(() => {
        if (isSubmitted && authState.isLogged) {
            console.log('isLogged');
        }
    }, [authState.isLogged, isSubmitted]);

    //EFFECTS
    useEffect(() => {
        onSubmit();
    }, [onSubmit]);

    function onSubmitSignUp(data) {
        if (strongPwd?.allowed === true && !isSignupSubmitted) signUp(data);
    }

    useEffect(() => {
        // Fonction pour vérifier si window.google est défini
        const checkGoogleLoaded = () => {
            if (window.google) {
                setIsGoogleLoaded(true);
            }
        };

        // Vérifier si window.google est déjà disponible lors du montage initial du composant
        checkGoogleLoaded();

        const interval = setInterval(checkGoogleLoaded, 1000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const sPwd = getValues('password');
        const strength = passwordStrength(sPwd);
        // setStrongPwdContains(strength.contains);
        // setStrongPwdLength(strength.length);

        switch (strength.value) {
            default:
                setStrongPwd(null);
                break;
            case 'Too weak':
                setStrongPwd({
                    class: 'bg-red w-1/4',
                    contains: strength.contains,
                    length: strength.length,
                    allowed: true,
                });
                break;
            case 'Weak':
                setStrongPwd({
                    class: 'bg-warning-500 w-2/4',
                    contains: strength.contains,
                    length: strength.length,
                    allowed: true,
                });
                break;
            case 'Medium':
                setStrongPwd({
                    class: 'bg-warning-500 w-3/4',
                    contains: strength.contains,
                    length: strength.length,
                    allowed: true,
                });
                break;
            case 'Strong':
                setStrongPwd({
                    class: 'bg-success-500 w-full',
                    contains: strength.contains,
                    length: strength.length,
                    allowed: true,
                });
                break;
        }
        if (strength.length <= 0) setStrongPwd(null);
        //eslint-disable-next-line
    }, [watch('password')]);

    function handleGoToHome() {
        navigate('/');
    }

    function handleShowPassword(e) {
        e.preventDefault();
        e.stopPropagation();
        setShowPassword(!showPassword);
    }

    return (
        <>
            <div className="container">
                <div className="sm:grid sm:grid-cols-12 sm:gap-8 pb-4">
                    <div className="sm:col-span-9 lg:col-span-8">
                        <main className="p-10">
                            <>
                                <h1 className="-mx-2 md:-mx-8 -mt-8 text-lg text-mainText font-bold pt-2 pb-1 md:px-8 py-4">
                                    Inscription à Skillsmarket
                                    <div className="text-mainText absolute -mt-8 right-4 justify-end" onClick={handleGoToHome}>
                                        <XCircleIcon className="w-7" />
                                    </div>
                                </h1>
                                {isSignupSubmitted && authState.isLogged && <Alert type="success" message="Vous avez créé un compte SkillsMarket !" />}
                                {hasError && isSignupSubmitted && !authState.isLogged && (
                                    <Alert type="danger" message="Un compte existe déjà avec cette adresse email" />
                                )}
                                <form className="m-0 min-w-lg" autoComplete="off">
                                    <div className="w-full mt-2 mb-4">
                                        <label className="text-sm">Inscription en tant que :</label>
                                        <FieldSelect
                                            inputForm="rounded"
                                            name="typeInscription"
                                            label={false}
                                            options={[
                                                { label: 'Personne', value: 'realnames' },
                                                { label: 'Société', value: 'society' },
                                            ]}
                                            onChange={(e) => {
                                                setTypeInscription(e.target.value);
                                            }}
                                            register={register}
                                            errors={errors}
                                        />
                                    </div>
                                    <div className="flex w-full gap-3">
                                        {typeInscription === 'society' ? (
                                            <>
                                                <div className="w-full relative z-0 mb-2 group">
                                                    <input
                                                        className="input_floating peer"
                                                        placeholder=" "
                                                        type="text"
                                                        name="society"
                                                        {...register('society')}
                                                    />
                                                    <label htmlFor="floating_society" className="input_labelFloating">
                                                        Société*
                                                    </label>
                                                    {errors && errors['society'] && (
                                                        <span className={`mt-0 text-sm text-danger-400`}>{errors['society'].message}</span>
                                                    )}
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className="w-1/2 relative z-0 mb-2 group">
                                                    <input
                                                        className="input_floating peer"
                                                        placeholder=" "
                                                        type="text"
                                                        name="firstname"
                                                        {...register('firstname')}
                                                    />
                                                    <label htmlFor="floating_firstname" className="input_labelFloating">
                                                        Prénom*
                                                    </label>
                                                    {errors && errors['firstname'] && (
                                                        <span className={`mt-0 text-sm text-danger-400`}>{errors['firstname'].message}</span>
                                                    )}
                                                </div>
                                                <div className="w-1/2 relative z-0 mb-2 group">
                                                    <input
                                                        className="input_floating peer"
                                                        placeholder=" "
                                                        type="text"
                                                        name="lastname"
                                                        {...register('lastname')}
                                                    />
                                                    <label htmlFor="floating_lastname" className="input_labelFloating">
                                                        Nom*
                                                    </label>
                                                    {errors && errors['lastname'] && (
                                                        <span className={`mt-0 text-sm text-danger-400`}>{errors['lastname'].message}</span>
                                                    )}
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    <div className="relative z-0 w-full mb-2 group">
                                        <input className="input_floating peer" placeholder=" " type="text" name="email" {...register('email')} />
                                        <label htmlFor="floating_email" className="input_labelFloating">
                                            Adresse email*
                                        </label>
                                        {errors && errors['email'] && <span className={`mt-0 text-sm text-danger-400`}>{errors['email'].message}</span>}
                                    </div>
                                    <div className="relative z-0 w-full mb-2 groupMdp">
                                        <input
                                            className="input_floating peer text-black"
                                            placeholder=" "
                                            type={showPassword ? 'text' : 'password'}
                                            name="password"
                                            {...register('password')}
                                            autoComplete="off"
                                        />
                                        <label htmlFor="floating_password" className="input_labelFloating">
                                            Mot de passe
                                        </label>
                                        <button className="absolute right-0 top-0 mt-2 mr-2" onClick={(e) => handleShowPassword(e)}>
                                            {showPassword ? (
                                                <EyeIcon className="h-6 w-6 block text-graySkills" />
                                            ) : (
                                                <EyeOffIcon className="h-6 w-6 block text-graySkills " />
                                            )}
                                        </button>
                                        <div className={`${strongPwd?.class} h-[2px] block absolute bottom-0`}></div>
                                        {errors && errors['password'] && (
                                            <span className={`w-full mt-0 text-xs text-danger-400`}>{errors['password'].message}</span>
                                        )}
                                    </div>
                                    <div className="flex flex-col mt-2">
                                        <div className="flex gap-2">
                                            <input type="checkbox" id="cguConsent" className="w-10 sm:w-6 mr-4" name="cguConcent" {...register('cguConsent')} />
                                            <label className="text-sm" htmlFor="cgu_consent">
                                                J'ai lu et je consens à la{' '}
                                                <Link className="text-blueMain" to="/cgu" title="Consulter notre politique de confidentialité">
                                                    politique de confidentialité
                                                </Link>{' '}
                                                et aux{' '}
                                                <Link className="text-blueMain" to="/cgu" title="Consulter nos conditions d'utilisation">
                                                    conditions d'utilisation
                                                </Link>
                                                .
                                            </label>
                                        </div>

                                        {errors && errors['cguConsent'] && (
                                            <span className={`w-full mt-0 text-sm text-danger-400`}>{errors['cguConsent'].message}</span>
                                        )}
                                    </div>
                                </form>
                                <div className="flex flex-col gap-1 mt-4">
                                    <button
                                        ref={inscriptionRef}
                                        className="button button-secondary bg-blueMain rounded-full font-bold"
                                        onClick={handleSubmit(onSubmitSignUp)}
                                        disabled={isSignupSubmitted || !strongPwd?.allowed}
                                    >
                                        {isSignupSubmitted ? 'Chargement...' : 'Créer mon profil'}
                                    </button>
                                    <p className="text-purpleSkills text-xl font-bold text-center">Ou</p>
                                    <div className="m-auto text-center flex justify-center">
                                        <div ref={ggRef}></div>
                                    </div>
                                </div>
                            </>
                        </main>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Register;
