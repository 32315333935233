//COMPONENTS
import SideNav from 'components/common/nav/SideNav';
import SectionTitle from 'components/common/SectionTitle';
import SEO from 'components/Seo';
//LAYOUT
import ContainerLayout from 'pages/layouts/Container';

//UTILS
import { isMobile } from 'utils/functions';


function Support() {
    return (
        <>
            <SEO
                title={`Support`}
                description={`Page support pour Skillsmarket`}
                type="summary_large_image"
            />
            <ContainerLayout>
                <div className="w-full flex flex-col sm:flex-row sm:gap-8 pb-4">
                    {!isMobile() && (
                        <div className="hidden sm:block w-[18%]">
                            <SideNav />
                        </div>
                    )}

                    <div className={`w-full sm:w-[65%] h-full`}>
                        <main className="">
                            <SectionTitle>
                                <h1 className="h1 mx-0 my-2 mt-4 mb-2">
                                    <span className="text-purpleSkills">Support et assistance de Skillsmarket</span>
                                </h1>
                            </SectionTitle>
                            <div className="container mt-4">
                                <p className="mb-4">En tant qu'utilisateur de Skillsmarket, votre réussite et votre satisfaction sont notre priorité. Nous nous engageons à vous accompagner tout au long de votre utilisation de la plateforme afin de maximiser vos chances de réussite professionnelle. Cette page est conçue pour répondre à vos questions, résoudre vos problèmes et vous offrir une assistance complète pour tirer le meilleur parti de Skillsmarket.</p>
                                <div className="mb-4">
                                    <p className="font-bold mb-2">
                                        Comment fonctionne Skillsmarket ?
                                    </p>
                                    <p>
                                        Skillsmarket est une plateforme professionnelle innovante qui vous permet de vous connecter avec d'autres professionnels, de découvrir de nouvelles opportunités, d'échanger vos compétences et d'élargir votre réseau. Vous pouvez explorer des offres d'emploi exclusives, partager des connaissances dans des salles de discussion, promouvoir vos services, participer à des défis et bien plus encore.
                                    </p>
                                </div>
                                <div className='mb-4'>
                                    <p className='mb-2 font-bold'>
                                        Notre équipe d'assistance dédiée est là pour vous aider !
                                    </p>
                                    <p className="mb-4">Si vous avez des questions, des problèmes techniques ou besoin d'aide pour utiliser certaines fonctionnalités de Skillsmarket, notre équipe d'assistance est à votre disposition.</p>
                                    <p className="mb-4">Vous pouvez nous contacter par e-mail à : </p>
                                    <a className='mb-4 font-bold text-purpleSkills' href='mailto:contact@skillsmarket.fr'>
                                        contact@skillsmarket.fr
                                    </a>
                                </div>
                                <div className="mb-4">
                                    <p className="font-bold mb-2">
                                        Documentation et tutoriels complets
                                    </p>
                                    <p>
                                        Pour vous aider à tirer le meilleur parti de Skillsmarket, nous avons créé une documentation complète et des tutoriels détaillés. Vous y trouverez des explications pas à pas sur chaque fonctionnalité de la plateforme, des guides pratiques sur la création d'un profil percutant, des conseils pour trouver un emploi, des astuces pour promouvoir vos services, et bien plus encore. N'hésitez pas à les consulter pour obtenir des réponses à vos questions
                                    </p>
                                </div>
                                <div className="mt-6">
                                    <h2 className='font-extrabold text-lg text-purpleSkills mb-4'>
                                        Foire aux questions (FAQ)
                                    </h2>
                                    <p className="mb-4">
                                        Vous avez peut-être des questions fréquemment posées sur Skillsmarket. Pour vous faciliter la tâche, nous avons également créé une FAQ complète qui regroupe les réponses aux questions les plus courantes. Vous y trouverez des informations sur la création d'un compte, la confidentialité des données, la gestion des offres d'emploi, l'utilisation des salles de discussion, et bien d'autres sujets importants. N'hésitez pas à la consulter avant de contacter notre équipe d'assistance.
                                    </p>
                                    <div className="mb-4">
                                        <p className="font-bold mb-2">
                                            Q : Comment fonctionne Skillsmarket ?
                                        </p>
                                        <p>
                                            R : Skillsmarket est une plateforme professionnelle innovante qui vous permet de vous connecter avec d'autres professionnels, de découvrir de nouvelles opportunités, d'échanger vos compétences et d'élargir votre réseau. Vous pouvez explorer des offres d'emploi exclusives, partager des connaissances dans des salles de discussion, promouvoir vos services, participer à des défis et bien plus encore.
                                        </p>
                                    </div>
                                    <div className="mb-4">
                                        <p className="font-bold mb-2">
                                            Q : Comment puis-je m'inscrire sur Skillsmarket ?
                                        </p>
                                        <p>
                                            R : Pour vous inscrire sur Skillsmarket, il vous suffit de télécharger l'application sur iOS ou Android et de suivre les instructions d'inscription. Vous devrez fournir quelques informations de base, telles que votre nom, votre adresse e-mail et créer un mot de passe. Une fois votre compte créé, vous pourrez compléter votre profil et commencer à explorer les fonctionnalités de la plateforme.
                                        </p>
                                    </div>
                                    <div className="mb-4">
                                        <p className="font-bold mb-2">
                                            Q : Comment puis-je trouver un emploi sur Skillsmarket ?
                                        </p>
                                        <p>
                                            R : Sur Skillsmarket, vous trouverez des offres d'emploi exclusives qui ne sont pas publiées sur les sites traditionnels d'annonces. Vous pouvez utiliser la fonction de recherche pour trouver des emplois correspondant à vos compétences et à vos intérêts. Vous pouvez également recevoir des recommandations personnalisées en fonction de votre profil et de vos interactions sur la plateforme.
                                        </p>
                                    </div>
                                    <div className="mb-4">
                                        <p className="font-bold mb-2">
                                            Q : Comment puis-je promouvoir mes services sur Skillsmarket ?
                                        </p>
                                        R : Skillsmarket offre une excellente opportunité de promouvoir vos services et de trouver de nouveaux clients. Vous pouvez créer un profil professionnel complet et attractif, partager des témoignages de clients satisfaits, publier des exemples de votre travail et interagir avec d'autres utilisateurs dans les salles de discussion. Cela vous permettra de vous faire connaître et de développer votre réseau professionnel.
                                    </div>
                                    <div className="mb-4">
                                        <p className="font-bold mb-2">
                                            Q : Comment puis-je contacter l'équipe d'assistance de Skillsmarket ?
                                        </p>
                                        <p>
                                            R : Si vous avez des questions, des problèmes techniques ou si vous avez besoin d'aide pour utiliser certaines fonctionnalités de Skillsmarket, notre équipe d'assistance est disponible pour vous aider. Vous pouvez les contacter par e-mail à contact@skillsmarket.fr pour une réponse personnalisée.
                                        </p>
                                    </div>
                                    <div className="mb-4">
                                        <p className="font-bold mb-2">
                                            Q : Comment puis-je gagner des points sur Skillsmarket ?
                                        </p>
                                        <p>
                                            R : Skillsmarket encourage l'entraide grâce à un système de points. En aidant la communauté, en partageant des connaissances et en répondant aux questions des autres utilisateurs, vous pouvez gagner des points et améliorer votre référencement sur la plateforme. Vous pouvez ensuite utiliser ces points pour accéder à des fonctionnalités premium ou pour mettre en valeur vos compétences.
                                        </p>
                                    </div>
                                    <div className="mb-4">
                                        <p className="font-bold mb-2">
                                            Q : Comment puis-je partager mes shoots sur Skillsmarket ?
                                        </p>
                                        <p>
                                            R : Les shoots sont de courtes vidéos que vous pouvez partager sur Skillsmarket pour montrer votre univers professionnel et communiquer vos compétences de manière créative. Pour partager un shoot, il vous suffit d'utiliser la fonction dédiée dans l'application et de suivre les instructions. Vous pouvez ajouter des tags pertinents pour augmenter la visibilité de votre shoot et encourager les interactions des autres utilisateurs.
                                        </p>
                                    </div>
                                    <div className="mb-4">
                                        <p className="font-bold mb-2">
                                            Q : Comment puis-je participer à des défis sur Skillsmarket ?
                                        </p>
                                        <p>
                                            R : Les défis sont une excellente façon de mettre en valeur vos compétences et de montrer ce que vous savez faire. Vous pouvez participer à des défis dans votre domaine d'expertise en soumettant votre travail et en le partageant avec la communauté. Les autres utilisateurs pourront alors voter pour leur projet préféré. C'est une opportunité de vous démarquer et de recevoir des feedbacks constructifs.
                                        </p>
                                    </div>
                                    <div className="mb-4">
                                        <p className="font-bold mb-2">
                                            Q : Comment puis-je trouver des salles de discussion intéressantes sur Skillsmarket ?
                                        </p>
                                        <p>
                                            R : Les salles de discussion sont un excellent moyen de poser des questions, de partager des connaissances et de discuter avec d'autres professionnels dans votre domaine d'expertise. Vous pouvez rechercher des salles de discussion en utilisant des mots clés pertinents ou explorer les salles recommandées en fonction de votre profil et de vos interactions sur la plateforme. N'hésitez pas à participer aux discussions et à poser des questions pour profiter au maximum de cette fonctionnalité.
                                        </p>
                                    </div>
                                </div>
                                <div className="mt-6">
                                    <h2 className='font-extrabold text-lg text-purpleSkills mb-4'>
                                        Votre avis compte !
                                    </h2>
                                    <p className='mb-4'>
                                        Chez Skillsmarket, nous attachons une grande importance à vos retours et suggestions. Si vous rencontrez des problèmes, avez des idées d'amélioration ou souhaitez simplement partager votre expérience avec nous, n'hésitez pas à nous contacter. Votre feedback nous aidera à améliorer continuellement notre plateforme et à mieux répondre à vos besoins.
                                    </p>
                                    <p className='mb-4'>
                                        Nous espérons que cette page de support et d'assistance répondra à toutes vos questions et vous aidera à utiliser Skillsmarket de manière efficace. N'hésitez pas à y revenir régulièrement, car nous la mettrons à jour pour tenir compte de vos besoins et des nouvelles fonctionnalités.
                                    </p>
                                    <p>
                                        Nous vous souhaitons beaucoup de succès dans votre parcours professionnel sur Skillsmarket !
                                    </p>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </ContainerLayout>
        </>
    );
}

export default Support;