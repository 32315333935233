import { useEffect, useState } from 'react';

function UseIntersectionObserver({ root, target, onIntersect, threshold = 0.5, rootMargin = '0px', enabled = false }) {
    const [isVisible, setIsVisible] = useState(false);
    useEffect(() => {
        if (!enabled) return;

        const observer = new IntersectionObserver(
            (entries) =>
                entries.forEach((entry) => {
                    setIsVisible(entry.isIntersecting);
                    onIntersect(entry.isIntersecting);
                }),
            {
                root: root && root.current,
                rootMargin,
                threshold,
            }
        );

        const el = target && target.current;

        if (!el) {
            return;
        }

        observer.observe(el);

        return () => {
            observer.unobserve(el);
        };
        //eslint-disable-next-line
    }, [target.current, enabled]);

    return { isVisible };
}

export default UseIntersectionObserver;
