//ICONS
import CropIcon from '../icons/CropIcon';
import SaturationIcon from '../icons/SaturationIcon';
import BrightnessIcon from '../icons/BrightnessIcon';
import ContrastIcon from '../icons/ContrastIcon';
import HueIcon from '../icons/HueIcon';
import ResetIcon from '../icons/ResetIcon';

function Navigation(props) {
    const setMode = (mode) => {
        props?.onChange(mode);
    };

    return (
        <div className="imageEditor_navigation">
            <div
                className={`imageEditor_navigation__button ${
                    props?.mode === 'crop' ? 'active' : ''
                }`}
                onClick={() => setMode('crop')}
            >
                <CropIcon />
            </div>
            <div
                className={`imageEditor_navigation__button ${
                    props?.mode === 'saturation' ? 'active' : ''
                }`}
                onClick={() => setMode('saturation')}
            >
                <SaturationIcon />
            </div>
            <div
                className={`imageEditor_navigation__button ${
                    props?.mode === 'brightness' ? 'active' : ''
                }`}
                onClick={() => setMode('brightness')}
            >
                <BrightnessIcon />
            </div>
            <div
                className={`imageEditor_navigation__button ${
                    props?.mode === 'contrast' ? 'active' : ''
                }`}
                onClick={() => setMode('contrast')}
            >
                <ContrastIcon />
            </div>
            <div
                className={`imageEditor_navigation__button ${
                    props?.mode === 'hue' ? 'active' : ''
                }`}
                onClick={() => setMode('hue')}
            >
                <HueIcon />
            </div>
            {props.changed && (
                <div className={`imageEditor_navigation__button ml-8`} onClick={props.reset}>
                    <ResetIcon />
                </div>
            )}
        </div>
    );
}

export default Navigation;
