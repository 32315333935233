import { useCallback, useEffect, useRef, useState } from 'react';

function capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

function TextareaCustom(props) {
    const [value, setValue] = useState(props.initValue || '');
    const [isFocused, setIsFocused] = useState(false);
    const textareaRef = useRef(null);
    const { ref, ...register } = props.register(props.name);

    const adjustHeightAndScroll = useCallback(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
            textareaRef.current.scrollTop = textareaRef.current.scrollHeight;
        }
    }, []);

    useEffect(() => {
        setValue(props.initValue || '');
    }, [props.initValue]);

    useEffect(() => {
        adjustHeightAndScroll();
    }, [value, adjustHeightAndScroll]);

    const handleKeyDown = useCallback(
        (event) => {
            if (event.key === 'Enter' && !event.shiftKey) {
                event.preventDefault();
                const start = event.target.selectionStart;
                const end = event.target.selectionEnd;
                const newValue = `${value.substring(0, start)}\n${value.substring(end)}`;
                setValue(newValue);

                props.onChange({ target: { name: props.name, value: newValue } });

                adjustHeightAndScroll();

                setTimeout(() => {
                    if (textareaRef.current) {
                        textareaRef.current.selectionStart = start + 1;
                        textareaRef.current.selectionEnd = start + 1;
                    }
                }, 0);
            }
        },
        [value, adjustHeightAndScroll, props]
    );

    const handleInput = useCallback(
        (event) => {
            const newValue = event.target.value;
            setValue(newValue);
            props.onChange(event);
            if (props.autoresize) {
                adjustHeightAndScroll();
            }
        },
        [adjustHeightAndScroll, props]
    );

    const handleFocus = useCallback(() => setIsFocused(true), []);

    const handleBlur = useCallback(() => setIsFocused(false), []);

    return (
        <div className={`${props.size ? props.size : 'w-full'} mt-4 p-0 overflow-hidden`}>
            {props.label !== false && (
                <label
                    className={`${props.type === 'ai' ? 'inputAi_label' : 'input_label'} text-sm ${
                        isFocused && props.inputForm === 'floating' ? 'text-blueMain' : ''
                    } ${props.errors && props.errors[props.name] ? 'input_error' : ''}`}
                    htmlFor={`input_${props.name}`}
                >
                    {props.label ? capitalize(props.label) : capitalize(props.name)}
                </label>
            )}

            <textarea
                ref={(e) => {
                    ref(e);
                    textareaRef.current = e;
                }}
                {...register}
                name={props.name}
                id={props.id || props.name}
                rows={props.rows || 3}
                value={value}
                className={`${props.className || 'text-mainText p-2'} max-h-40 w-full sm:text-sm`}
                style={props.style || { resize: 'none', outline: 'none' }}
                placeholder={props.placeholder || 'Tapez votre texte ici'}
                //onChange={handleInput}
                onInput={handleInput}
                onKeyDown={handleKeyDown}
                onFocus={handleFocus}
                onBlur={handleBlur}
            />

            {/*  {isFocused && props.tooltip && <div className="tooltip text-xs sm:text-sm text-gray-300 mt-2">{props.tooltip}</div>} */}
            {props.errors && props.errors[props.name] && <span className="mt-0 text-danger-400 text-sm">{props.errors[props.name].message}</span>}
        </div>
    );
}

export default TextareaCustom;
