//CONFIG
import Crm from 'libs/headless-templates/crm';

//LIBS
import HeadlessApp from 'libs/headless';

import { useEffect, useCallback, useContext } from 'react';
import { useLocation } from 'react-router-dom';

import { AuthContext } from 'context/authContext';
import { SocketContext } from 'context/socketContext';

export default function InAllLayouts() {
    const crm = Crm();
    const location = useLocation();

    const socket = useContext(SocketContext);
    const [authState] = useContext(AuthContext);

    useEffect(() => {
        initSocket();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authState]);

    const initSocket = useCallback(() => {
        let data = {
            id: authState?.me?.id,
            location: location.pathname,
        };
        setTimeout(() => {
            socket.send('userActivity', data);
        }, 1000);
        //eslint-disable-next-line
    }, []);

    return (
        <>
            <HeadlessApp config={crm} />
        </>
    );
}
