import { useEffect } from 'react';

//API
import { useQuery } from 'react-query';
import { getTransactionsAsked } from 'api/transactions';

//COMPONENTS
import CardBuy from '../buysell/CardBuy';

function ListBuy() {
    //API
    const buys = useQuery('servicesAsked', () => getTransactionsAsked('service'));

    useEffect(() => {
        // console.log(buys?.data);
        //eslint-disable-next-line
    }, [buys.isSuccess]);

    return (
        <>
            {buys.isSuccess && (
                <div className="cards">
                    {buys.data.map((buy, index) => (
                        <CardBuy transaction={buy} refetch={buy.refetch} key={index} />
                    ))}
                </div>
            )}
        </>
    );
}

export default ListBuy;
