import { useEffect, useRef } from 'react';

function Slider(props) {
    const line = useRef();

    const state = useRef({
        focus: false,
        width: 0,
        onChange: null,
    });

    //eslint-disable-next-line
    const { value = 0, className } = props;
    const handleInsideDot = state.current.width ? Math.abs(value) <= 16 / state.current.width : true;
    const fillWidth = `${Math.abs(value) * 50}%`;
    const fillLeft = `${50 * (1 - Math.abs(Math.min(0, value)))}%`;
    const formattedValue = `${value > 0 ? '+' : ''}${Math.round(100 * value)}`;

    useEffect(() => {
        state.current.onChange = props.onChange;
    }, [props]);

    useEffect(() => {
        window.addEventListener('resize', recalculateWidth);
        window.addEventListener('orientationchange', recalculateWidth);

        window.addEventListener('mouseup', onStop, { passive: false });
        window.addEventListener('mousemove', onDrag, { passive: false });
        window.addEventListener('touchmove', onDrag, { passive: false });
        window.addEventListener('touchend', onStop, { passive: false });

        if (line.current) {
            line.current.addEventListener('mousedown', onStart);
            line.current.addEventListener('touchstart', onStart);
        }

        recalculateWidth();
        return () => {
            window.removeEventListener('mouseup', onStop);
            window.removeEventListener('mousemove', onDrag);
            window.removeEventListener('touchmove', onDrag);
            window.removeEventListener('touchend', onStop);

            window.removeEventListener('resize', recalculateWidth);
            window.removeEventListener('orientationchange', recalculateWidth);

            if (line.current) {
                line.current.removeEventListener('mousedown', onStart);
                //eslint-disable-next-line
                line.current.removeEventListener('touchstart', onStart);
            }
        };

        //eslint-disable-next-line
    }, []);

    function onDrag(e) {
        if (state.current.focus) {
            const position = 'touches' in e ? e.touches[0].clientX : e.clientX;

            if (line.current) {
                const { left, width } = line.current.getBoundingClientRect();

                if (state.current.onChange) {
                    state.current.onChange(Math.max(-1, Math.min(1, (2 * (position - left - width / 2)) / width)));
                }
            }
            if (e.preventDefault) {
                e.preventDefault();
            }
        }
    }

    const onStop = () => {
        state.current.focus = false;
    };

    const onStart = (e) => {
        state.current.focus = true;
        onDrag(e);
    };

    const recalculateWidth = () => {
        if (line.current) {
            state.current.width = line.current.clientWidth;
        }
    };

    // useEffect(() => {
    //     console.log(value, props.mode);
    // }, [value]);

    return (
        <div className={`imageEditor_slider`} ref={line}>
            <div className="imageEditor_slider__line">
                <div
                    className="imageEditor_slider__fill"
                    style={{
                        width: fillWidth,
                        left: fillLeft,
                    }}
                />
                <div className="imageEditor_slider__dot" />
                <div
                    className={`imageEditor_slider__value ${handleInsideDot && 'imageEditor_slider__value--hidden'}`}
                    style={{
                        left: `${Math.abs(value * 50 + 50)}%`,
                    }}
                >
                    {formattedValue}
                </div>
                <div
                    className={`imageEditor_slider__handler ${state.focus && 'imageEditor_slider__handler--focus'} ${
                        handleInsideDot && 'imageEditor_slider__handler--hidden'
                    }`}
                    style={{
                        left: `${value * 50 + 50}%`,
                    }}
                />
            </div>
        </div>
    );
}

export default Slider;
