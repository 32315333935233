// import { useEffect, useRef } from "react";
//LIBS
import { Link, useParams } from "react-router-dom";
import parse from "html-react-parser";
import ReactPlayer from "react-player";
//API
import { useQuery } from "react-query";
import { getUser } from "api/users";
import { getSingleVideo } from "api/videos";
import { getSimilarVideos } from "api/search";
//STYLES
import "App.css";
//LAYOUT
import FullLayout from "pages/layouts/Full";
//CONTAINERS
import SingleCard from "containers/profils/SingleCard";
import SimilarSection from "containers/services/SimilarSection";
//COMPONENTS
import Sidebar from "components/common/Sidebar";
// import SectionCategories from "components/sections/Categories";
//ICONS
import {
    // ClockIcon,
    // TrendingUpIcon,
    // LocationMarkerIcon,
    UserGroupIcon,
    // CurrencyEuroIcon,
} from "@heroicons/react/solid";

function SingleVideo() {
    const params = useParams();

    //API
    const video = useQuery(
        ["video", params.slug],
        (params) => {
            return getSingleVideo(params.queryKey[1]);
        },
        { enabled: params?.slug ? true : false }
    );
    const user = useQuery(
        ["user", video?.data?.user?.profile?.slug],
        (params) => {
            return getUser(params.queryKey[1]);
        },
        { enabled: video?.data?.user?.profile?.slug ? true : false }
    );
    const similar = useQuery(
        ["similarUsers", params.slug],
        () => getSimilarVideos(video.data.id),
        {
            enabled: video?.data?.id ? true : false,
        }
    );

    return (
        <FullLayout>
            <div className="App">
                <main className="container-fluid">
                    <div className="md:flex gap-4 mt-3">
                        <div className="w-full md:w-6/12 lg:w-3/12">
                            {user.isSuccess && (
                                <SingleCard
                                    user={user.data}
                                    refetch={user.refetch}
                                />
                            )}
                        </div>
                        {video.isSuccess && (
                            <div className="w-full lg:w-6/12 mt-2 bg-white p-8 rounded-lg shadow-lg">
                                <h1 className="font-bold text-3xl text-blue-800">
                                    {video.data.title}
                                </h1>
                                <h2 className="font-exo font-bold text-secondary">
                                    {video.data.type}
                                </h2>

                                <div className="flex flex-row flex-wrap gap-2 mt-4">
                                    {video.data?.tags?.map((skill, index) => (
                                        <Link
                                            to={`/search?q=${skill.name}`}
                                            key={index}
                                            className="link link_blue flex flex-row gap-1"
                                        >
                                            #{skill.name}
                                        </Link>
                                    ))}
                                </div>

                                <div className="mt-4 flex flex-row gap-2">
                                    {!!video.data.public && (
                                        <div className="flex flew-row text-success-500">
                                            <UserGroupIcon className="w-4 h-4 relative top-0.5 mr-1" />
                                            <span className="font-mukta text-sm">
                                                <span>{video.data.public}</span>
                                            </span>
                                        </div>
                                    )}
                                </div>

                                {!!video.data?.description && (
                                    <>
                                        <h3 className="text-secondary uppercase mt-4">
                                            Description
                                        </h3>
                                        <div className="content mt-2">
                                            {parse(video.data?.description)}
                                        </div>
                                    </>
                                )}
                            </div>
                        )}

                        <Sidebar position="top">
                            {video.isSuccess && (
                                <>
                                    {!!video.data?.video && (
                                        <>
                                            <h3 className="text-secondary uppercase my-1">
                                                Vidéo de présentation
                                            </h3>
                                            <div
                                                className="py-2 aspect-video relative"
                                                style={{
                                                    paddingTop: "56.25%",
                                                }}
                                            >
                                                <ReactPlayer
                                                    url={video.data?.video}
                                                    width="100%"
                                                    height="100%"
                                                    origin={
                                                        window.location.origin
                                                    }
                                                    className="absolute top-0 left-0 w-full h-full rounded-lg overflow-hidden aspect-video"
                                                    pip
                                                    controls
                                                />
                                            </div>
                                        </>
                                    )}

                                    {!!video.data?.price && (
                                        <div className="p-2 my-4">
                                            <div className="flex flex-row justify-center">
                                                <button className="button button-secondary button-small">
                                                    Acheter la vidéo pour{" "}
                                                    {video.data.price} €
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}

                            <SimilarSection services={similar} />
                        </Sidebar>
                        {/* <div className="w-full lg:w-3/12 mt-2">
                            {similar.isSuccess && (
                                <>
                                    {similar.data.map((user, index) => (
                                        <div
                                            className="slide card card_profile w-full sm:w-6/12 md:w-9/12 lg:w-5/12 xl:w-4/12 2xl:w-3/12 hover:shadow-lg"
                                            key={index}
                                        >
                                            {user.profile?.pseudo}
                                        </div>
                                    ))}
                                </>
                            )}
                        </div> */}
                    </div>
                </main>
            </div>
        </FullLayout>
    );
}

export default SingleVideo;
