import { useState, useImperativeHandle, forwardRef } from 'react';

//LIBS
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
//import { toast } from 'react-toastify';

//SCHEMAS
import schema from 'components/forms/yup/profile';

//HOOKS
import useInfinite from 'hooks/useInfinite';

//API
import { getShootsAnswers } from 'api/shoots';

//COMPONENTS
import FieldInput from 'components/forms/fields/input';

const AnswersShootList = function (props, ref) {
    const [totalDocs, setTotalDocs] = useState(0);
    const [formOpened, setFormOpened] = useState(false);

    //HOOKS
    const formReview = useForm({
        resolver: yupResolver(schema),
    });

    useImperativeHandle(ref, () => ({
        openForm: () => handleOpenForm(),
    }));

    function handleOpenForm() {
        //console.log(props.parent);
        setFormOpened(true);
    }

    const answers = useInfinite(getShootsAnswers, ['infiniteAnswersShoot', props.parent], {
        id: props.parent,
        onItem: (data, refetch) => {
            return <div>Yup</div>;
        },
        onData: (data, gData) => {
            setTotalDocs(gData.totalDocs);
        },
    });

    const form = (
        <div className="relative bottom-0">
            <form>
                <FieldInput
                    inputForm="floating"
                    name="review"
                    label=""
                    size="w-full"
                    placeholder="Votre commentaire"
                    errors={formReview.formState.errors}
                    register={formReview.register}
                />
            </form>
        </div>
    );
    // useEffect(() => {
    //     console.log(answers);
    // }, [answers]);
    return (
        <div className="relative">
            {totalDocs <= 0 && <p className="text-mainText">Aucun commentaire pour le moment</p>}
            {answers}
            {formOpened && <>{form}</>}
        </div>
    );
};

export default forwardRef(AnswersShootList);
