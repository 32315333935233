import { useEffect, useState } from 'react';

//LIBS
//import _ from 'lodash';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

//FORMS
import schema from 'components/forms/yup/qualification';

//ICONS
import { PlusIcon, XCircleIcon, PlusCircleIcon } from '@heroicons/react/solid';

//COMPONENTS
import FieldInput from 'components/forms/fields/input';
import profileOptions from 'components/forms/options/profile';
import useSafeState from 'hooks/useSafeState';
import Modal from 'components/common/Modal';

function FieldQualifications(props) {
    const [items, setItems] = useState(props.items);
    const [modalIsModify, setModalIsModify] = useState(false);
    const [modalIndex, setModalIndex] = useState(0);
    const [modalTitle, setModalTitle] = useState('Ajouter un diplôme');
    const [modalQualification, setModalQualification] = useSafeState(false);
    const [readyToWatch, setReadyToWatch] = useState(false);

    //HOOKS
    const {
        register,
        handleSubmit,
        setValue,
        clearErrors,
        getValues,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const sortQualifications = (qualifications) => {
        return qualifications.sort((a, b) => {
            if (a.currentSchool && !b.currentSchool) {
                return -1;
            } else if (!a.currentSchool && b.currentSchool) {
                return 1;
            } else if (a.yearTo !== b.yearTo) {
                return b.yearTo - a.yearTo;
            } else if (a.yearFrom !== b.yearFrom) {
                return -a.yearFrom - b.yearFrom;
            } else {
                return a.title.localeCompare(b.title);
            }
        });
    };

    function handleDelete(index, _id) {
        let newQualifications;
        if (_id) {
            newQualifications = items.filter((qualification) => qualification._id !== _id);
        } else {
            newQualifications = [...items];
            newQualifications.splice(index, 1);
        }
        setItems(newQualifications);
        props.onChange(newQualifications);
    }

    function submit() {
        /*  e.preventDefault(); */
        handleSubmit(onSubmit)();
    }

    useEffect(() => {
        if (readyToWatch) {
            clearErrors();
        }
        //eslint-disable-next-line
    }, [readyToWatch]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setReadyToWatch(true);
        }, 1000);

        return () => clearTimeout(timer);

        //eslint-disable-next-line
    }, []);

    const onSubmit = (data) => {
        if (errors.length > 0) return false;
        setModalQualification(false);

        const location = [];
        if (data.address && data.point) {
            const points = data.point.split(',');
            if (points.length === 2) {
                location.push(parseFloat(points[0]));
                location.push(parseFloat(points[1]));
            }
        }

        let newQualifications = [];

        if (modalIsModify) {
            newQualifications = items.map((qualification, index) => {
                if (index === modalIndex) {
                    return {
                        title: data.title,
                        yearFrom: data.yearFrom,
                        yearTo: data.yearTo,
                        school: data.school,
                        currentSchool: data.currentSchool,
                        location: {
                            address: data.address,
                            type: 'Point',
                            coordinates: location,
                        },
                    };
                }
                return qualification;
            });
        } else {
           newQualifications = [
                ...items,
                {
                    title: data.title,
                    yearFrom: data.yearFrom,
                    yearTo: data.yearTo,
                    school: data.school,
                    currentSchool: data.currentSchool,
                    location: {
                        address: data.address,
                        type: 'Point',
                        coordinates: location,
                    },
                },
            ];
        }

        const sortedQualifications = sortQualifications(newQualifications);
        setItems(sortedQualifications);
        props.onChange(sortedQualifications);

        setValue('title', '');
        setValue('school', data.school);
        setValue('yearFrom', 1900);
        setValue('yearTo', new Date().getFullYear());
        setValue('currentSchool', false);
        setValue('address', data.address);
        setValue('point', data.location?.coordinates?.join(','));

        const timer = setTimeout(() => {
            setReadyToWatch(true);
        }, 1000);

        return () => clearTimeout(timer);
    };

    useEffect(() => {
        const sortedQualifications = sortQualifications(props.items);
        setItems(sortedQualifications);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.items]);

    //OPTIONS
    function dispatchOptionsActions(action, name, value) {
        switch (action) {
            case 'onValidateOption':
                return onValidateOption(name, value);
            case 'onChangeOptionAddress':
                return onChangeOptionAddress(name, value);
            default:
        }
    }

    //FUNCTIONS OPTIONS
    function onChangeOptionAddress(name, value) {
        setValue(name, value);
    }
    function onValidateOption(name, value) {
        return true;
    }

    function handleQualification(e) {
        e.preventDefault();
        setModalTitle('Ajouter un diplôme');
        setValue('title', '');
        setValue('school', '');
        setValue('yearFrom', new Date().getFullYear());
        setValue('yearTo', new Date().getFullYear());
        setValue('currentSchool', false);
        setValue('address', '');
        setValue('point', '');
        setModalQualification(true);
    }

    function handleModify(index) {
        setModalTitle('Modifier un diplôme');
        setModalIsModify(true);
        setModalIndex(index);
        setValue('title', items[index].title);
        setValue('school', items[index].school);
        setValue('yearFrom', items[index].yearFrom);
        setValue('yearTo', items[index].yearTo);
        setValue('currentSchool', items[index].currentSchool);
        setValue('address', items[index].location?.address);
        setValue('point', items[index].location?.coordinates?.join(','));
        setModalQualification(true);
    }

    return (
        <>
            <Modal
                open={modalQualification}
                title={modalTitle}
                onClose={{
                    onClick: () => setModalQualification(false),
                }}
                onConfirm={{
                    label: 'Valider',

                    onClick: () => {
                        submit();
                        /* append({ date: date.toLocaleDateString('fr-FR'), time: time }); */
                    },
                }}
            >
                <div>
                    <div className="flex gap-3">
                        <FieldInput
                            label={`${props.label ? props.label : 'Titre*'}`}
                            inputForm="floating"
                            name="title"
                            size="w-full"
                            errors={errors}
                            register={register}
                        />
                        {/* <FieldInput
                            label="Début*"
                            name="yearFrom"
                            type="number"
                            min="1900"
                            defaultValue={1900}
                            inputForm="floating"
                            size="w-3/12"
                            errors={errors}
                            register={register}
                        /> */}
                        <FieldInput
                            label="Date d'obtention*"
                            name="yearTo"
                            type="number"
                            max={new Date().getFullYear() + 5}
                            defaultValue={new Date().getFullYear()}
                            inputForm="floating"
                            size="w-8/12 sm:w-4/12"
                            errors={errors}
                            register={register}
                        />
                    </div>
                    {/* autocomplète */}
                    <div className="flex gap-1 pb-2">
                        <FieldInput label="Nom de l'école*" name="school" inputForm="floating" size="w-1/2" errors={errors} register={register} />
                        <FieldInput
                            inputForm="floating"
                            disabled={true}
                            name="address"
                            label="Lieu"
                            size="w-1/2"
                            depends="point"
                            errors={errors}
                            register={register}
                            option={profileOptions.address}
                            dispatchOptionsActions={dispatchOptionsActions}
                            getValues={getValues}
                            readyToWatch={readyToWatch}
                        />
                    </div>
                    <div className="flex gap-1">
                        <div className="w-1/2 flex gap-2 items-center">
                            <input type="checkbox" id="currentSchool" className="w-8 sm:w-4" name="currentSchool" {...register('currentSchool')} />
                            <label className="text-sm" htmlFor="currentSchool">
                                C'est mon école actuel
                            </label>
                        </div>
                    </div>
                </div>
            </Modal>
            <div className="w-full">
                <button
                    className={`flex ${props.className ? props.className : ''} linkCard text-purpleSkills gap-2`}
                    type="button"
                    onClick={(e) => {
                        handleQualification(e);
                    }}
                >
                    <PlusIcon className={`${props.iconClassName ? props.iconClassName : 'w-4 h-4'}`} />
                    <span>Un diplôme</span>
                </button>

                <div className="flex flex-col gap-2 mt-2">
                    {items?.map((qualification, k) => (
                        <div key={k} className="p-2 flex flex-col bg-blancSkills rounded">
                            <div className="flex justify-between w-full">
                                <div className="w-full flex flex-col">
                                    <p className="text-purpleSkills font-bold">{qualification.title}</p>
                                    <p className="text-purpleSkills  font-semibold">{qualification.school}</p>
                                    {/* <p className="text-purpleSkills text-sm">
                                        De {qualification.yearFrom} à {qualification.yearTo}
                                        {qualification.currentSchool ? " - aujourd'hui" : ''}
                                    </p> */}
                                    <p className="text-purpleSkills text-sm">
                                        {qualification.yearTo}
                                        {qualification.currentSchool ? " - aujourd'hui" : ''}
                                    </p>
                                    {qualification.location?.address && <p className="text-purpleSkills text-sm">{qualification.location?.address}</p>}
                                </div>
                                <div className="cursor-pointer">
                                    <PlusCircleIcon onClick={() => handleModify(k)} className="m-2 w-5 h-5 text-graySkills" />
                                </div>
                                <div className="cursor-pointer">
                                    <XCircleIcon onClick={() => handleDelete(k, qualification._id)} className="m-2 w-5 h-5 text-graySkills" />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}

export default FieldQualifications;
