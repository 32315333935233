import API from "./axios.config";
import authHeader from "services/Auth.Header";

//GET
async function getTransactions(data) {
    const response = await API.get("transactions/pull/:type", data, { headers: authHeader() });
    return response.data;
}
//POST
async function postTransaction(data) {
    const response = await API.post("transactions/push", data, {
        headers: authHeader(),
    });
    return response;
}

async function isFirstTransaction(data) {
    const response = await API.post("transactions/isFirst", data, {
        headers: authHeader(),
    });
    return response.data;
}

async function getTransactionsReceived(type) {
    const response = await API.get("transactions/received/" + type, {
        headers: authHeader(),
    });
    return response.data;
}

async function getTransactionsAsked(type) {
    const response = await API.get("transactions/asked/" + type, {
        headers: authHeader(),
    });
    return response.data;
}

async function putCancel(id) {
    const response = await API.put(
        "transactions/cancel/" + id,
        {},
        {
            headers: authHeader(),
        }
    );
    return response.data;
}

async function putAccept(id) {
    const response = await API.put(
        "transactions/accept/" + id,
        {},
        {
            headers: authHeader(),
        }
    );
    return response.data;
}

async function putValidate(id) {
    const response = await API.put(
        "transactions/validate/" + id,
        {},
        {
            headers: authHeader(),
        }
    );
    return response.data;
}

export {
    getTransactions,
    postTransaction,
    isFirstTransaction,
    getTransactionsReceived,
    getTransactionsAsked,
    putCancel,
    putAccept,
    putValidate,
};
